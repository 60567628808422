export const digIdResponse = 'digIdResponse';
export const digIdProcessing = 'digIdProcessing';
export const digIdSuccess = 'digIdSuccess';
export const digIdFailure = 'digIdFailure';

export const careproviderId = 'careproviderId';
export const dataserviceId = 'dataserviceId';
export const authEndpointUrl = 'authEndpointUrl';
export const correlationId = 'correlationId';
export const medMijRequestId = 'medMijRequestId';
