import { Component, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgControlComponentBase } from '../../../ui-abstractions/ng-control-component-base.component';
import { ItemModel } from '@syncfusion/ej2-splitbuttons/src/common/common-model';

@Component({
  selector: 'medrecord-ui-bricks',
  templateUrl: './bricks.component.html',
  styleUrls: ['./bricks.component.scss'],
})
export class BricksComponent extends NgControlComponentBase {
  @Input() items: ItemModel[];
  @Input() multiply = false;

  selectedItems: string[] = [];
  selectedItem: string;

  constructor(@Self() @Optional() public control: NgControl) {
    super(control);
  }

  writeValue(value: any): void {
    super.writeValue(value);

    if (this.multiply) {
      this.selectedItems = value ? [value] : [];
    } else {
      this.selectedItem = value;
    }
  }

  onSelect(item: ItemModel): void {
    if (this.multiply) {
      this.selectedItems.push(item.id);
      this.control?.valueAccessor?.writeValue(this.selectedItems);
    } else {
      this.selectedItem = item.id;
      this.control?.valueAccessor?.writeValue(this.selectedItem);
    }

    this.change.emit({ value: item });
  }
}
