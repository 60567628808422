import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkOrganisationsStateModule } from './state-management/network-organisations-state.module';

@NgModule({
  imports: [
    CommonModule,
    NetworkOrganisationsStateModule
  ],
})
export class OrganisationsManagerModule {}
