import { MyDoctorTableInterface, ResourceField, ResourceFieldGroup } from '@managers/my-doctor';

export class FieldGroupUtil {

  static prepareFieldGroupName(fieldGroup: ResourceFieldGroup, index: number, data: any): string {
    return typeof fieldGroup.name === 'string'
      ? fieldGroup.name
      : fieldGroup.name({ index, resource: data });
  }

  static isFieldEmpty(field: ResourceField | ResourceFieldGroup,
                      prepareDataForField: (field: ResourceField, data?: any) => string | MyDoctorTableInterface,
                      data?: any): boolean {
    if ('fields' in field) {
      // ResourceFieldGroup.fields; field is inferred as ResourceFieldGroup
      return field.fields.every(f => this.isFieldEmpty(f, prepareDataForField, data));
    }
    const preparedData = prepareDataForField(field, data);
    return preparedData === undefined
      || preparedData === null
      || preparedData === ''
      || field.showAsTable && !(preparedData as MyDoctorTableInterface)?.rows?.length;
  }
}