import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  getWeatherDataAction,
  getWeatherDataFailureAction,
  getWeatherDataSuccessAction,
} from './weather-manager.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { WeatherService } from '../services/weather.service';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';

@Injectable()
export class WeatherManagerEffects {
  @Effect()
  loadWeatherData$ = this.actions$.pipe(
    ofType(getWeatherDataAction),
    switchMap(({ queryParams }) =>
      this.weatherService
        .getForecastWeather(queryParams)
        .pipe(switchMap((data) => [getWeatherDataSuccessAction({ data })]))
    ),
    catchError(({ error }) => [
      addErrorToast(getErrorToastBodyUtil('health_plans_weather_fetch_failed', error)),
      getWeatherDataFailureAction({ error }),
    ])
  );

  constructor(private actions$: Actions, private weatherService: WeatherService) {}
}
