import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'medrecord-ui-action-column',
  templateUrl: './action-column.component.html',
})
export class ActionColumnComponent {
  @Input() value;

  @Output() clickHandle = new EventEmitter<any>();
}
