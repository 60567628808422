import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MedicationsManagerService } from '../services/medications-manager.service';
import {
  addMedicationAction,
  addMedicationSuccessAction,
  deleteMedicationAction,
  deleteMedicationSuccessAction,
  editMedicationAction,
  editMedicationSuccessAction,
  loadCareplanMedicationsSuccessAction,
  loadMedicationAction,
  loadMedicationsAction,
  loadMedicationsFailureAction,
  loadMedicationsSuccessAction,
  loadMedicationSuccessAction,
  loadPrescribedMedicationAction,
  loadPrescribedMedicationSuccessAction,
  loadUsedMedicationAction,
  loadUsedMedicationSuccessAction,
} from './medications-manager.actions';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectCurrentID } from '@medrecord/managers-users';
import { Store } from '@ngrx/store';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

@Injectable()
export class MedicationsManagerEffects {
  loadMedications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMedicationsAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      mergeMap(([{ payload }, userId]) => {
        return forkJoin({
          medications: this.service.loadMedications(userId, payload),
          count: this.service.loadMedicationsCount(userId),
        }).pipe(
          switchMap(({ medications, count }) => {
            return payload.carePlanId
              ? [loadCareplanMedicationsSuccessAction({ medications, count })]
              : [loadMedicationsSuccessAction({ medications, count })];
          }),
          catchError((error: any) => [
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_medications_error'), error)),
            loadMedicationsFailureAction({ error }),
          ])
        );
      })
    )
  );

  loadMedication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMedicationAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([payload, userID]) =>
        this.service
          .loadMedication(payload.id, userID)
          .pipe(map((medication) => loadMedicationSuccessAction({ medication })))
      ),
      catchError((error: any) => [
        addErrorToast(getErrorToastBodyUtil('load_medication_error', error)),
        loadMedicationsFailureAction({ error }),
      ])
    )
  );

  loadPrescribedMedication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPrescribedMedicationAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([payload, userID]) =>
        this.service
          .loadMedication(payload.id, userID)
          .pipe(map((medication) => loadPrescribedMedicationSuccessAction({ medication })))
      ),
      catchError((error: any) => [
        addErrorToast(getErrorToastBodyUtil('load_medication_error', error)),
        loadMedicationsFailureAction({ error }),
      ])
    )
  );

  addMedicationAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMedicationAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([payload, userID]) =>
        this.service.createMedication(userID, payload.medication).pipe(
          map((medication) => {       
            this.store.dispatch(
              addSuccessToast({
                title: 'medication_create_success_title',
                content: 'medication_create_success_content',
              })
            );
            return addMedicationSuccessAction({ medication });
          }),
          catchError((error: any) => [
            addErrorToast(getErrorToastBodyUtil('add_medication_error', error)),
            loadMedicationsFailureAction({ error }),
          ])
        )
      )
    )
  );

  editMedication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editMedicationAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([payload, userID]) =>
        this.service.updateMedication(userID, payload.medication.id, payload.medication).pipe(
          map((medication) => {
            this.store.dispatch(
              addSuccessToast({
                title: 'medication_edit_success_title',
                content: 'medication_edit_success_content',
              })
            );
            return editMedicationSuccessAction({ medication });
          })
        )
      ),
      catchError((error: any) => [
        addErrorToast(getErrorToastBodyUtil('edit_medication_error', error)),
        loadMedicationsFailureAction({ error }),
      ])
    )
  );

  deleteMedication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMedicationAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([{ id }, userId]) => {
        return this.service.deleteMedication(userId, id).pipe(
          map(() => {
            this.store.dispatch(
              addSuccessToast({
                title: 'medication_delete_success_title',
                content: 'medication_delete_success_content',
              })
            );
            return deleteMedicationSuccessAction({ id });
          }),
          catchError(({ error }) => [
            addErrorToast(
              getErrorToastBodyUtil(
                this.translateService.instant(error?.error?.message ? error.error.message : 'delete_medications_error'),
                error
              )
            ),
            loadMedicationsFailureAction({ error }),
          ])
        );
      })
    )
  );

  loadUsedMedication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUsedMedicationAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([payload, userID]) =>
        this.service.loadMedication(payload.id, userID).pipe(
          map((medication) => loadUsedMedicationSuccessAction({ medication })),
          catchError((error: any) => [
            addErrorToast(getErrorToastBodyUtil('load_medication_error', error)),
            loadMedicationsFailureAction({ error }),
          ])
        )
      )
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private service: MedicationsManagerService,
    private translateService: TranslateService
  ) {}
}
