import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  getTestCareproviderStatusFetchData,
  getTestCareproviderStatusFetchType,
  getTestCareproviderStatusModalOpenStatus,
  setTestCareproviderStatusModalOpenStatusAction,
  TestCareproviderFetchStatusType
} from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-test-careprovider-status-modal-container',
  templateUrl: 'test-careprovider-status-modal-container.component.html'
})
export class TestCareproviderStatusModalContainerComponent {
  isModalOpen$: Observable<boolean> = this.store.select(getTestCareproviderStatusModalOpenStatus);
  type$: Observable<TestCareproviderFetchStatusType> = this.store.select(getTestCareproviderStatusFetchType);
  status$: Observable<string> = this.store.select(getTestCareproviderStatusFetchData);

  constructor(
    private store: Store
  ) {
  }

  onModalClosed(): void {
    this.store.dispatch(setTestCareproviderStatusModalOpenStatusAction({ isOpen: false }));
  }
}
