import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'medrecord-auth-twofa-verification',
  templateUrl: './twofa-verification.component.html'
})
export class TwofaVerificationComponent {
  @Input() title: string;
  @Input() lostStuffDescription: string;

  @Output() clickHere: EventEmitter<void> = new EventEmitter();

  clickHereClick(): void {
    this.clickHere.emit();
  }
}
