import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'medrecord-ui-action-icon',
  templateUrl: './action-icon.component.html',
  styleUrls: ['./action-icon.component.scss'],
})
export class ActionIconComponent {
  @Input() iconClassFa: string;
  @Input() tooltip = '';
  @Input() actionType: 'delete' | 'add' | 'edit' | 'generic' = 'delete';

  @Output() clicked = new EventEmitter<any>();
}
