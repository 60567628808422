import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  getNetworkOrganisations,
  NetworkOrganisation,
  NetworkOrganisationPractitionerMedicalRole,
  NetworkOrganisationPractitionerRole,
} from '@managers/admin-organisations-manager';
import { Role } from '@medrecord/managers-auth';
import { ValidationService } from '@medrecord/services-form-validation';
import { mapEnum } from '@medrecord/tools-utils';
import { MapFieldType } from '@medrecord/ui';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ItemModel } from '@syncfusion/ej2-angular-navigations';
import { Observable } from 'rxjs';

@Component({
  selector: 'medsafe-organisations-assignment-form-fields',
  templateUrl: './organisations-assignment-form-fields.component.html',
})
export class OrganisationsAssignmentFormFieldsComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() roles: string[];
  @Input() userOrganisations: NetworkOrganisation[];
  @Output() onOrganisationUnassign: EventEmitter<any> = new EventEmitter();

  readonly role = Role;
  readonly mapFieldType = MapFieldType;
  readonly organisationsFormGroupKey = 'organisations';
  readonly valid = 'VALID';

  readonly requiredErrorMsg = this.translateService.instant('validation.required');

  readonly managementRoles: ItemModel[] = mapEnum(NetworkOrganisationPractitionerRole, (value) => ({
    id: value.toUpperCase(),
    text: this.translateService.instant('management_role_' + value.toLowerCase()),
  }));

  readonly medicalRoles: ItemModel[] = mapEnum(NetworkOrganisationPractitionerMedicalRole, (value) => ({
    id: value.toUpperCase(),
    text: this.translateService.instant('medical_role_' + value.toLowerCase()),
  }));

  organisationsOptions: ItemModel[] = [];
  organisations$: Observable<NetworkOrganisation[]> = this.store.select(getNetworkOrganisations);

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.parentForm.registerControl(this.organisationsFormGroupKey, this.formBuilder.array([]));

    this.organisations$.subscribe((data) => {
      this.organisationsOptions = data.map((organisation: NetworkOrganisation): any => ({
        id: organisation.id.toUpperCase(),
        text: organisation.name,
      }));

      this.addInitialOrganisationFormItems();
    });
  }

  get organisationControls(): AbstractControl[] {
    return (this.parentForm?.get(this.organisationsFormGroupKey) as FormArray)?.controls || [];
  }

  addInitialOrganisationFormItems(): void {
    const control = this.parentForm.get(this.organisationsFormGroupKey) as FormArray;

    if ((this.userOrganisations || [])?.length) {
      this.userOrganisations?.forEach((organisation: NetworkOrganisation) => {
        control.push(
          this.createOrganisationFormItemGroup({
            organisationId: organisation.organizationId,
            medicalRoles: organisation.medicalRoles,
            managementRole: organisation.role,
          })
        );
      });
    } else {
      this.addEmptyOrganisationFormItem();
    }
  }

  addEmptyOrganisationFormItem(): void {
    const control = this.parentForm.get(this.organisationsFormGroupKey) as FormArray;
    const valid = !control.length || control?.controls[control.length - 1]?.status === this.valid;

    control?.controls[control.length - 1]?.markAllAsTouched();

    if (valid) {
      control.push(
        this.createOrganisationFormItemGroup({
          organisationId: '',
          medicalRoles: [],
          managementRole: '',
        })
      );
    }
  }

  createOrganisationFormItemGroup(organisationFormModel: any): FormGroup {
    const { organisationId, medicalRoles, managementRole } = organisationFormModel;

    return this.formBuilder.group({
      organisationId: [organisationId, [this.validationService.required(this.requiredErrorMsg)]],
      medicalRoles: [medicalRoles, [this.validationService.required(this.requiredErrorMsg)]],
      managementRole: [managementRole.toUpperCase(), [this.validationService.required(this.requiredErrorMsg)]],
    });
  }

  removeUserOrganisationAssignment(index: number): void {
    const control = this.parentForm.get(this.organisationsFormGroupKey) as FormArray;
    const organisationId = control.value[index]?.organisationId;
    control.removeAt(index);
    this.onOrganisationUnassign.emit(organisationId);
  }
}
