import { APP_INITIALIZER, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { generalRouteNames } from '@medrecord/routes-general';
import { Store } from '@ngrx/store';
import { MEDMIJ_REDIRECT_URI } from '../constants/local_storage';
import { IN_AUTH_URL, IN_MEDMIJ_REDIRECT_URI } from '../constants/redirect-url-params';
import { redirectWithError } from '../utils/redirect.util';
import {
  AuthRouteParams,
  getCodeVerifier,
  requestTokensAction,
  SignInService,
  signInWithTempTokenFinishedAction,
} from '@medrecord/managers-auth';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';

export const redirectionInitializer = (): Provider[] => [
  {
    provide: APP_INITIALIZER,
    useFactory: () => {
      return () =>
        new Promise((resolve) => {
          const medmijRedirectUri = localStorage.getItem(MEDMIJ_REDIRECT_URI);
          if (!medmijRedirectUri) {
            resolve(false);
          } else if (location.pathname !== `/${generalRouteNames.Patient.LegacyMedmijAuthBack}`) {
            console.warn('Used for MedMij redirection, but the auth-back url is wrong.');
            localStorage.removeItem(MEDMIJ_REDIRECT_URI);
            resolve(false);
          } else {
            const error = getParameterByName('error');
            const code = getParameterByName('code');
            const state = getParameterByName('state');
            console.log('Used for MedMij redirection. Redirecting to: ' + medmijRedirectUri);
            redirectWithError(medmijRedirectUri, error, code, state);
          }
        });
    },
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => {
      return () =>
        new Promise((resolve) => {
          const medmijRedirectUri = getParameterByName(IN_MEDMIJ_REDIRECT_URI);

          if (!medmijRedirectUri) {
            resolve(false);
          }
          // TODO Check the valid whitelisted Medmij origins
          // else if (envService.whitelistMedmijLoginOrigin.split(',').indexOf(document.referrer) < 0) {
          //   console.error('Whitelisted:' + envService.whitelistMedmijLoginOrigin);
          //   console.error('Found:' + document.referrer);
          //   redirectWithError(medmijRedirectUri,
          //     'MedSafe: Origin uri for MedMij auth is not whitelisted!');
          // }
          else {
            localStorage.setItem(MEDMIJ_REDIRECT_URI, medmijRedirectUri);

            const authEndpointUrl = getParameterByName(IN_AUTH_URL);

            window.open(authEndpointUrl, '_parent');
          }
        });
    },
    multi: true,
  },
  // Return my-doctor data for mobile
  {
    provide: APP_INITIALIZER,
    useFactory: signInWithTempToken,
    multi: true,
    deps: [Store, Router, SignInService],
  },
];

function getParameterByName(name) {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(window.location.href);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function signInWithTempToken(store: Store, router: Router, signInService: SignInService) {
  return (): Promise<boolean> =>
    new Promise((resolve) => {
      const isMobile = getParameterByName('device') === 'mobile';
      const tempToken = getParameterByName('temp_token');
      // Should decode the base64 auth endpoint url value
      const authEndpointUrl = atob(getParameterByName('auth_endpoint_url'));

      if (!isMobile) {
        resolve(false);
      } else if (!tempToken) {
        store.dispatch(
          addErrorToast({
            title: this.translateService.instant('temp_token_error_title'),
            content: this.translateService.instant('temp_token_error_content'),
          })
        );
        router.navigate(['auth']);
        resolve(false);
      } else if (!authEndpointUrl) {
        store.dispatch(
          addErrorToast({
            title: this.translateService.instant('digi_id_url_error_title'),
            content: this.translateService.instant('digi_id_url_error_content'),
          })
        );
        router.navigate(['auth']);
        resolve(false);
      } else {
        let codeVerifier: string;
        store.select(getCodeVerifier).subscribe((val) => (codeVerifier = val));

        signInService.getOAuthSignInPayload(codeVerifier).then((oAuthConfig) =>
          signInService
            .signInWithTempToken(tempToken, oAuthConfig)
            .toPromise()
            .then((tempTokenResponse) => {
              store.dispatch(signInWithTempTokenFinishedAction({ ...tempTokenResponse, tempToken, success: true }));

              const code = new URL(tempTokenResponse.redirectUri).searchParams.get(AuthRouteParams.Code);
              if (!code) {
                // Show error message
                store.dispatch(
                  addErrorToast({
                    title: this.translateService.instant('code_verifier_error_title'),
                    content: this.translateService.instant('code_verifier_error_content'),
                  })
                );

                router.navigate(['auth']);
                resolve(false);
              }

              store.dispatch(
                requestTokensAction({
                  code,
                })
              );

              // Open the digiId screen
              window.open(authEndpointUrl, '_parent');
            })
            .catch((err) => {
              // Show error message
              store.dispatch(addErrorToast(getErrorToastBodyUtil('', err)));
              signInWithTempTokenFinishedAction({ tempToken, success: false });
              resolve(false);
            })
        );
      }
    });
}
