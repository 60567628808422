import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-basic-medical-data',
  templateUrl: './basic-medical-data.component.svg',
})
export class BasicMedicalDataComponent extends MedrecordIcon {
  viewHeight = 24;
  viewWidth = 24;
}
