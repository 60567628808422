import { Component, Input } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';
import { Colors } from '@medrecord/services-colors';

@Component({
  selector: 'medrecord-svg-question',
  templateUrl: './question.component.svg',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent extends MedrecordIcon {
  viewHeight = 20;
  viewWidth = 20;
  
  questionColor: string;
  
  @Input()
  set questionType(color: Colors) {
    this.questionColor = this.getColorByType(color);
  }
}
