import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-phonendoscope',
  templateUrl: './phonendoscope.component.svg',
  styleUrls: ['./phonendoscope.component.scss'],
})
export class PhonendoscopeComponent extends MedrecordIcon {
  viewHeight = 16;
  viewWidth = 16;
}
