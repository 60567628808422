import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Condition } from '@managers/conditions-manager';
import { EnvService } from '@medrecord/core';
import { ConditionsLoadPayload } from '../models/interfaces/conditions-load-payload.interface';

@Injectable()
export class ConditionsManagerService {
  constructor(
    private http: HttpClient,
    private envService: EnvService
    ) {}

  loadConditions(userId: string, payload: ConditionsLoadPayload): Observable<Condition[]> {
    let params = new HttpParams();

    Object.keys(payload).forEach((key) => {
      params = params.set(key, payload[key]);
    });

    return this.http.get<Condition[]>(this.envService.backend + '/patient/' + userId + '/condition', { params });
  }

  loadConditionsCount(userId: string): Observable<number> {
    return this.http.get<number>(this.envService.backend + '/patient/' + userId + '/condition/count');
  }

  loadCondition(id: string, userId: string): Observable<Condition> {
    return this.http.get<Condition>(this.envService.backend + '/patient/' + userId + '/condition/' + id);
  }

  createCondition(userId: string, condition: Condition): Observable<any> {
    return this.http.post(this.envService.backend + '/patient/' + userId + '/condition', condition);
  }

  updateCondition(userId: string, conditionId: string, conditionForm: Condition): Observable<any> {
    return this.http.put(this.envService.backend + '/patient/' + userId + '/condition/' + conditionId, conditionForm);
  }

  deleteCondition(userId: string, conditionId: string): Observable<any> {
    return this.http.delete(this.envService.backend + '/patient/' + userId + '/condition/' + conditionId);
  }
}
