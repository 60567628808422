import { createAction, props } from '@ngrx/store';
import { ScreenSize } from '../model/enums/screen.enum';

export const setScreenSize = createAction(
  '[App Config Manager Actions] Set Screen Size Action',
  props<{ screenSize: ScreenSize; hideSidebar?: boolean }>()
);

export const setSidebarHidden = createAction(
  '[App Config Manager Actions] Set Sidebar Hidden Action',
  props<{ hideSidebar: boolean }>()
);
