import { Component, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgControlComponentBase } from '../../../ui-abstractions/ng-control-component-base.component';
import { ItemModel } from '@syncfusion/ej2-splitbuttons/src/common/common-model';

@Component({
  selector: 'medrecord-ui-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends NgControlComponentBase {
  @Input() items: ItemModel[] = [];
  @Input() placeholder = '';
  @Input() asterix: boolean = false;

  @Input() allowFiltering: boolean = false;

  constructor(@Self() @Optional() public control: NgControl) {
    super(control);
  }

  get selectedItem(): ItemModel {
    return this.items?.find((item) => item.id === (this.value?.hasOwnProperty('id') ? this.value.id : this.value));
  }

  onSelect(event: any): void {
    if (this.value?.hasOwnProperty('id')) this.value = { id: event.itemData.id, text: event.itemData.text };
    else this.value = event.itemData.id;
  }
}
