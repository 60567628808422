<ng-container [formGroup]="parentForm">
  <ng-container [formArrayName]="fieldName">
    <h3 class="font-weight-bold">
      {{ 'profile_contacts' | translate }}
    </h3>
    <div *ngFor="let contact of controls; let i = index" class="contact px-5 py-3 mb-3">
      <div class="contact-group-header d-flex align-items-center mb-3">
        <h4 class="font-weight-bold mt-3">
          {{ 'profile_contact' | translate }} {{ i + 1 }}
          <a class="text-danger" (click)="removeForm(i)">
            <i class="fa fa-times-circle"></i>
          </a>
        </h4>
      </div>

      <div class="d-flex flex-wrap px-3 pb-3" [formGroupName]="i">
        <ng-container *ngFor="let field of fields[i]">
          <medrecord-ui-map-field
            [parentForm]="field.form"
            [options]="field.options"
            [label]="field.label"
            [secondaryLabel]="field.secondaryLabel"
            [fieldType]="field.type"
            [controlName]="field.controlName"
            class="{{ field.fieldClass }} {{ field.bindVisibility(field.form)() && 'w-50' }}"
            [bindVisibility]="field.bindVisibility(field.form)"
            [hint]="field.hint"
          ></medrecord-ui-map-field>
        </ng-container>

        <br />
      </div>
    </div>

    <button class="btn btn-primary font-weight-bold d-flex align-items-center" (click)="addNewContact()">
      <i class="fas fa-plus-circle text-xl mr-2"></i>
      {{ 'profile_one_more_contact' | translate }}
    </button>
  </ng-container>
</ng-container>
