import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-atom',
  templateUrl: './atom.component.svg',
  styleUrls: ['./atom.component.scss'],
})
export class AtomComponent extends MedrecordIcon {
  viewHeight = 18;
  viewWidth = 17;
}
