import { Component, Input } from '@angular/core';
import { ContactUserData } from '@medrecord/managers-users';

@Component({
  selector: 'medsafe-contacts-view',
  templateUrl: './contacts-view.component.html',
  styleUrls: ['./contacts-view.component.scss']
})
export class ContactsViewComponent {
  @Input() contacts: ContactUserData[] = [];
}
