<div #container class="root-container"></div>
<ejs-dialog
  id="dialog"
  #ejDialog
  [target]="targetElement"
  [width]="width"
  [visible]="visible"
  [isModal]="true"
  #template
  showCloseIcon="true"
  (close)="hideDialog()"
  [header]="header"
  [position]="position"
>
  <ng-template #content>
    <div class="dialogContent">
      <ng-content></ng-content>
    </div>
  </ng-template>
</ejs-dialog>
