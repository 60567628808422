export enum AppointmentStatus {
  None = 0,
  PendingApproval = 1 << 0,
  PendingPatientAcceptance = 1 << 2,
  Approved = 1 << 3,
  Declined = 1 << 4,
  IntakeInProgress = 1 << 5,
  DiagnoseAndTherapy = 1 << 6,
  FollowUp = 1 << 7,
  SelectQuestionnaire = 1 << 8,
  Completed = 1 << 9
}