import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService, ResourceType, StorageKeys } from '@medrecord/core';
import { Observable } from 'rxjs';
import { NetworkOrganisation } from '../models/network-organisation.interface';
import { NetworkOrganisationPractitioner } from '../models/network-organisation-practitioner.interface';
import { NetworkOrganisationPractitionerUpdate } from '../models/network-organisation-practitioner-update.interface';
import { NetworkPatient } from '@coaching/doctor-network';
import { StorageService } from '@medrecord/tools-storage';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkOrganisationsService {
  constructor(private http: HttpClient, private storage: StorageService<StorageKeys>, private envService: EnvService) {}

  getOrganisations(start: number, count: number): Observable<NetworkOrganisation[]> {
    let parameters = new HttpParams();
    parameters = parameters.set('start', String(start ?? 0));
    parameters = parameters.set('count', String(count));
    return this.http.get<NetworkOrganisation[]>(`${this.envService.backend}/network/organization`, {
      params: parameters,
    });
  }

  getOrganisationsCount(): Observable<number> {
    return this.http.get<number>(`${this.envService.backend}/network/organization/count`);
  }

  getOrganisation(id: string): Observable<NetworkOrganisation> {
    return this.http.get<NetworkOrganisation>(`${this.envService.backend}/network/organization/${id}`);
  }

  createOrganisation(organisation: NetworkOrganisation): Observable<NetworkOrganisation> {
    return this.http.post<NetworkOrganisation>(`${this.envService.backend}/network/organization`, organisation);
  }

  editOrganisation(organisation: NetworkOrganisation): Observable<NetworkOrganisation> {
    return this.http.put<NetworkOrganisation>(
      `${this.envService.backend}/network/organization/${organisation.id}`,
      organisation
    );
  }

  deleteOrganisation(id: string): Observable<any> {
    return this.http.delete<NetworkOrganisation>(`${this.envService.backend}/network/organization/${id}`);
  }

  getPractitioners(organisationId: string): Observable<NetworkOrganisationPractitioner[]> {
    return this.http.get<NetworkOrganisationPractitioner[]>(
      `${this.envService.backend}/network/organization/${organisationId}/practitioner`
    );
  }

  getAllPractitioners(name: string): Observable<NetworkPatient[]> {
    const { userId } = this.storage.getItem(StorageKeys.Auth);
    let parameters = new HttpParams();
    parameters = parameters.set('name', name);
    return this.http
      .get<NetworkPatient[]>(`${this.envService.backend}/person/${userId}/search`, { params: parameters })
      .pipe(map((users) => users.filter((u) => u.resourceType === ResourceType.Practitioner)));
  }

  addPractitioner(practitioner: NetworkOrganisationPractitionerUpdate, organisationId: string): Observable<any> {
    return this.http.post<any>(
      `${this.envService.backend}/network/organization/${organisationId}/practitioner`,
      practitioner
    );
  }

  getPractitioner(id: string, organisationId: string): Observable<NetworkOrganisationPractitioner> {
    return this.http.get<NetworkOrganisationPractitioner>(
      `${this.envService.backend}/network/organization/${organisationId}/practitioner/${id}`
    );
  }

  updatePractitionerRoles(
    payload: NetworkOrganisationPractitionerUpdate,
    organisationId: string
  ): Observable<NetworkOrganisationPractitionerUpdate> {
    return this.http.put<NetworkOrganisationPractitionerUpdate>(
      `${this.envService.backend}/network/organization/${organisationId}/practitioner/${payload.practitionerId}`,
      payload
    );
  }

  deletePractitioner(id: string, organisationId: string): Observable<any> {
    return this.http.delete(`${this.envService.backend}/network/organization/${organisationId}/practitioner/${id}`);
  }

  getUserOrganisations(userId: string): Observable<any> {
    return this.http.get(`${this.envService.backend}/network/organization/user/${userId}`);
  }
}
