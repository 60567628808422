import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PrivacyPolicyAbstractComponent } from '../../ui-abstractions';

@Component({
  selector: 'medrecord-ui-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrivacyPolicyComponent extends PrivacyPolicyAbstractComponent {
  @Input() externalHeader: boolean;
  @Input() unlimitedSpace: boolean;
}
