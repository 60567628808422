import { Component } from '@angular/core';
import {
  getAllResourcesKeysSize,
  getFilteredResourcesKeys,
  MappingResource,
  setDeleteDataModalOpenStatusAction,
  setResourcesModalOpenStatusAction
} from '@managers/my-doctor';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'doctor-gui-resources-chips-container',
  templateUrl: './resources-chips-container.component.html'
})
export class ResourcesChipsContainerComponent {
  resourceKeys$: Observable<MappingResource[]> = this.store.select(getFilteredResourcesKeys);
  totalResourceKeys$: Observable<number> = this.store.select(getAllResourcesKeysSize);

  constructor(
    private store: Store
  ) {
  }

  getData(): void {
    this.store.dispatch(setResourcesModalOpenStatusAction({ isOpen: true }));
  }

  deleteResource(resource: MappingResource): void {
    this.store.dispatch(setDeleteDataModalOpenStatusAction({ isOpen: true, resource: resource }));
  }
}
