import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ImmunizationsManagerService } from '../services/immunizations-manager.service';
import {
  addImmunizationAction,
  addImmunizationSuccessAction,
  deleteImmunizationAction,
  deleteImmunizationSuccessAction,
  editImmunizationAction,
  editImmunizationSuccessAction,
  loadCareplanImmunizationsSuccessAction,
  loadImmunizationAction,
  loadImmunizationLocationsAction,
  loadImmunizationLocationsFailureAction,
  loadImmunizationLocationsSuccessAction,
  loadImmunizationsAction,
  loadImmunizationsFailureAction,
  loadImmunizationsSuccessAction,
  loadImmunizationSuccessAction,
} from './immunizations-manager.actions';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectCurrentID } from '@medrecord/managers-users';
import { Store } from '@ngrx/store';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

@Injectable()
export class ImmunizationsManagerEffects {
  @Effect()
  loadImmunizations$ = this.actions$.pipe(
    ofType(loadImmunizationsAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ payload }, userId]) => {
      return forkJoin({
        immunizations: this.service.loadImmunizations(userId, payload),
        count: this.service.loadImmunizationsCount(userId),
      }).pipe(
        switchMap(({ immunizations, count }) => {
          return payload.carePlanId
            ? [loadCareplanImmunizationsSuccessAction({ immunizations, count })]
            : [loadImmunizationsSuccessAction({ immunizations, count })];
        }),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_immunizations_error'), error)),
          loadImmunizationsFailureAction({ error }),
        ])
      );
    })
  );

  @Effect()
  loadImmunization$ = this.actions$.pipe(
    ofType(loadImmunizationAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([action, userId]) => {
      return this.service.loadImmunization(action.id, userId).pipe(
        switchMap((immunization) => [loadImmunizationSuccessAction({ immunization })]),
        catchError(({ error }) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_immunizations_error'), error)),
          loadImmunizationsFailureAction({ error }),
        ])
      );
    })
  );

  @Effect()
  loadImmunizationLocations$ = this.actions$.pipe(
    ofType(loadImmunizationLocationsAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([_, userId]) => {
      return this.service.loadImmunizationLocations(userId).pipe(
        switchMap((locations) => [loadImmunizationLocationsSuccessAction({ locations })]),
        catchError(({ error }) => [
          addErrorToast(
            getErrorToastBodyUtil(this.translateService.instant('load_immunization_locations_error'), error)
          ),
          loadImmunizationLocationsFailureAction({ error }),
        ])
      );
    })
  );

  @Effect()
  addImmunizationAction$ = this.actions$.pipe(
    ofType(addImmunizationAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([payload, userID]) =>
      this.service.createImmunization(userID, payload.immunization).pipe(
        map((immunization) => {
          this.store.dispatch(
            addSuccessToast({
              title: 'immunization_add_success_title',
              content: 'immunization_add_success_content',
            })
          );
          return addImmunizationSuccessAction({ immunization });
        })
      )
    ),
    catchError((error: any) => [
      addErrorToast(getErrorToastBodyUtil('add_immunization_error', error)),
      loadImmunizationsFailureAction({ error }),
    ])
  );

  @Effect()
  editImmunization$ = this.actions$.pipe(
    ofType(editImmunizationAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([payload, userID]) =>
      this.service.updateImmunization(userID, payload.immunization.id, payload.immunization).pipe(
        map((immunization) => {
          this.store.dispatch(
            addSuccessToast({
              title: 'immunization_edit_success_title',
              content: 'immunization_edit_success_content',
            })
          );
          return editImmunizationSuccessAction({ immunization });
        })
      )
    ),
    catchError((error: any) => [
      addErrorToast(getErrorToastBodyUtil('edit_immunization_error', error)),
      loadImmunizationsFailureAction({ error }),
    ])
  );

  @Effect()
  deleteImmunization$ = this.actions$.pipe(
    ofType(deleteImmunizationAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ id }, userId]) => {
      return this.service.deleteImmunization(id, userId).pipe(
        map(() => {
          this.store.dispatch(
            addSuccessToast({
              title: 'immunization_delete_success_title',
              content: 'immunization_delete_success_content',
            })
          );
          return deleteImmunizationSuccessAction({ id });
        }),
        catchError(({ error }) => [
          addErrorToast(
            getErrorToastBodyUtil(
              this.translateService.instant(error?.error?.message ? error.error.message : 'delete_immunization_error'),
              error
            )
          ),
          loadImmunizationsFailureAction({ error }),
        ])
      );
    })
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private service: ImmunizationsManagerService,
    private translateService: TranslateService
  ) {}
}
