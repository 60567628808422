import { allergiesManagerFeatureKey, AllergiesMangerState } from './allergies-manager.reducer';
import { createSelector } from '@ngrx/store';
import { Allergy } from '../models/interfaces';
import { AllergyName } from '../models/interfaces/allergy-name.interface';

export const getAllergiesManagerState = (state: AllergiesMangerState) => state[allergiesManagerFeatureKey];

export const getAllergies = createSelector(
  getAllergiesManagerState,
  (state: AllergiesMangerState): Allergy[] => state.allergies
);

export const getCareplanAllergies = createSelector(
  getAllergiesManagerState,
  (state: AllergiesMangerState): Allergy[] => state.careplan.allergies
);

export const getAllergyCount = createSelector(
  getAllergiesManagerState,
  (state: AllergiesMangerState): number => state.count
);

export const getActiveAllergy = createSelector(
  getAllergiesManagerState,
  (state: AllergiesMangerState): Allergy => state.activeAllergy
);

export const getAutocompleteAllergyNames = createSelector(
  getAllergiesManagerState,
  (state: AllergiesMangerState): AllergyName[] => state.autocompleteAllergyNames
);
