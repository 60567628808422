import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackManagerStateModule } from './state-management/feedback-manager-state.module';
import { FeedbackService } from './services/feedback.service';

@NgModule({
  imports: [CommonModule, FeedbackManagerStateModule],
  providers: [FeedbackService],
})
export class FeedbackManagerModule {}
