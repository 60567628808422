import { Component, HostBinding, Optional, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgControlComponentBase } from '../../../ui-abstractions/ng-control-component-base.component';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { InputValue } from '../../../models/types/input-value.type';

@Component({
  selector: 'medrecord-ui-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PhoneComponent extends NgControlComponentBase {
  @ViewChild('input') input;
  @HostBinding('class') cssClass;

  readonly separateDialCode = true;
  readonly SearchCountryField = SearchCountryField;
  readonly CountryISO = CountryISO;
  readonly PhoneNumberFormat = PhoneNumberFormat;

  preferredCountries: CountryISO[] = [
    CountryISO.Netherlands,
    CountryISO.France,
    CountryISO.Italy,
    CountryISO.Germany,
    CountryISO.Poland,
    CountryISO.BosniaAndHerzegovina,
    CountryISO.Spain,
  ];

  constructor(@Self() @Optional() public control: NgControl) {
    super(control);
  }

  checkIfTouched(): void {
    if (this.input.touched) {
      this.onTouched();
    }
  }

  writeValue(value: InputValue): void {
    this.value = value;
  }
}
