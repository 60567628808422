<button class="button btn btn-outline-primary" type="button" (click)="openDialog($event)">
  {{ 'my_doctor_delete_data' | translate | titlecase }}
</button>

<ejs-dialog #dialogComponent [visible]="isOpen" [isModal]="true" (overlayClick)="isOpen = false" width="30%">
  <ng-template #header>
    <span>{{ 'my_doctor_delete_data' | translate | titlecase }}</span>
  </ng-template>

  <ng-template #content>
    <div class="row">
      <div class="col-12">
        <p>{{ 'my_doctor_delete_all_data' | translate }}</p>
      </div>
    </div>
  </ng-template>

  <ng-template #footerTemplate>
    <div>
      <button (click)="isOpen = false" class="btn btn-outline-black font-weight-bold">
        {{ 'common_no' | translate }}
      </button>
      <button (click)="onSubmit()" class="btn btn-danger ml-2 font-weight-bold">
        {{ 'common_delete' | translate }}
      </button>
    </div>
  </ng-template>
</ejs-dialog>
