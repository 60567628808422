import * as allergyConstants from './allergy.constants';
import { Provider } from '@angular/core';
import { ALLERGIES_MANAGER_CONSTANTS } from '../allergies-manager.tokens';

export const constants = {
  ...allergyConstants
};

export type AllergyManagerConstants = typeof constants;

export const provideAllergyManagerConstants = (): Provider => [
  {
    provide: ALLERGIES_MANAGER_CONSTANTS,
    useValue: constants
  }
];
