import { Component, Input, OnChanges, Optional, Self, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DatePrecision, getDateMaskWithPrecision, MedrecordMoment } from '@medrecord/services-datetime';
import { TranslateService } from '@ngx-translate/core';
import { ChangedEventArgs, CalendarView } from '@syncfusion/ej2-angular-calendars';
import { NgControlComponentBase } from '../../../ui-abstractions/ng-control-component-base.component';

@Component({
  selector: 'medrecord-ui-datepicker-precision',
  templateUrl: './datepicker-precision.component.html',
  styleUrls: ['./datepicker-precision.component.scss'],
})
export class DatepickerPrecisionComponent extends NgControlComponentBase implements OnChanges {
  readonly DatePrecision = DatePrecision;

  @Input() dateFormat = 'dd/MM/yyyy';
  @Input() firstDayOfWeek = 1;
  @Input() startCalendarView: CalendarView = 'Year';
  @Input() calendarViewDepth: CalendarView = 'Month';
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() label: string;
  @Input() isRequired: boolean = false;
  @Input() enablePrecisionChange = true;
  @Input() returnOnlyValue = false;
  @Input() set precision(value: DatePrecision) {
    //since we add datepicker's value as precision, in some using areas it's not being validators.required (because it has value), so whenever you want it to be required just pass isRequired=true
    if (this.isRequired == true) {
      this._precision = value || DatePrecision.day;
    } else {
      this.value = { ...this.value, precision: value || DatePrecision.day };
    }
  }

  private _precision: DatePrecision = DatePrecision.day;

  constructor(@Self() @Optional() public control: NgControl, private translateService: TranslateService) {
    super(control);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('precision') && !this.enablePrecisionChange) {
      this.precision = changes.precision.currentValue;
    }
  }

  get date(): string {
    return this.value?.date || '';
  }

  get precision(): DatePrecision {
    if (this.isRequired == true) {
      return this._precision;
    } else {
      return this.value?.precision || DatePrecision.day;
    }
  }

  get calendarDepth(): CalendarView {
    if (!this.enablePrecisionChange) return this.calendarViewDepth;
    else {
      this.startCalendarView = 'Decade';
      if (this.precision === DatePrecision.year) return 'Decade';
      else if (this.precision === DatePrecision.month) return 'Year';
      return 'Month';
    }
  }

  get step(): number {
    return 15;
  }

  get placeholder(): string {
    if (!this.enablePrecisionChange) return this.dateFormat?.toUpperCase();
    switch (this.precision) {
      case DatePrecision.month:
        return `${this.translateService.instant('common_around')} MM/YYYY`;
      case DatePrecision.year:
        return `${this.translateService.instant('common_around')} YYYY`;
      case DatePrecision.day:
        return 'DD/MM/YYYY';
      default:
        return 'DD/MM/YYYY HH:mm';
    }
  }

  get dateTimeFormat(): string {
    return this.enablePrecisionChange
      ? getDateMaskWithPrecision(this.precision, this.translateService.instant('common_around'))
      : this.dateFormat;
  }

  handleChange(data: ChangedEventArgs): void {
    if (!data.value) {
      this.value = null;
      return;
    }
    const date =
      this.precision !== DatePrecision.second ? MedrecordMoment(data.value).utc(true) : MedrecordMoment(data.value);
    date.set('second', 0);
    if (this.precision === DatePrecision.day) {
      date.set('minute', 0);
      date.set('hour', 0);
    }
    if (this.precision === DatePrecision.month) {
      date.set('minute', 0);
      date.set('hour', 0);
      date.set('date', 1);
    }
    if (this.precision === DatePrecision.year) {
      date.set('minute', 0);
      date.set('hour', 0);
      date.set('date', 1);
      date.set('month', 0);
    }

    this.value = this.returnOnlyValue
      ? date.format(this.dateFormat)
      : { date: date.toISOString(true), precision: this.precision };
  }

  markAsTouched(): void {
    this.control.control.markAsTouched();
  }
}
