import { Component, Input } from '@angular/core';

@Component({
  selector: 'medrecord-ui-intro-text',
  templateUrl: './intro-text.component.html',
  styleUrls: ['./intro-text.component.scss']
})
export class IntroTextComponent {
  @Input() shortTextHtml: string;
  @Input() fullTextHtml: string;
  
  fullIntroText = false;
}
