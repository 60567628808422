export enum MapFieldType {
  Bricks = 'BRICKS',
  Autocomplete = 'AUTOCOMPLETE',
  CheckBox = 'CHECKBOX',
  Radio = 'RADIO',
  ComboBox = 'COMBOBOX',
  DateTime = 'DATE_TIME',
  Input = 'INPUT',
  Numeric = 'NUMERIC',
  Phone = 'PHONE',
  PrecisionDateTime = 'PRECISION_DATE_TIME',
  TextArea = 'TEXTAREA',
  MultiSelect = 'MULTI_SELECT',
  Select = 'SELECT',
}
