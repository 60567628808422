import { ResourceSubType } from '../enums';
import { TranslateService } from '@ngx-translate/core';
import { RouteType } from '@managers/medications-manager';

export class MapperUtil {

  private static readonly observationMapping: {
    [key in ResourceSubType]?: {
      label: string,
      extract: (m) => string
    }[]
  } = {
    [ResourceSubType.bloodPressure]: [
      {
        label: 'systolic_label',
        extract: m => MapperUtil.quantity(m.systolic)
      }, {
        label: 'diastolic_label',
        extract: m => MapperUtil.quantity(m.diastolic)
      }, {
        label: 'average_blood_pressure',
        extract: m => MapperUtil.quantity(m.average_blood_pressure)
      }, {
        label: 'body_site',
        extract: m => m.bodySite
      }, {
        label: 'position',
        extract: m => m.position
      }
    ],
    [ResourceSubType.bodyWeight]: [
      {
        label: 'value_label',
        extract: m => MapperUtil.quantity(m.weight)
      }, {
        label: 'clothing',
        extract: m => m.clothing
      }
    ],
    [ResourceSubType.bodyHeight]: [
      {
        label: 'value_label',
        extract: m => MapperUtil.quantity(m.height)
      }, {
        label: 'position',
        extract: m => m.position
      }
    ],
    [ResourceSubType.bodyTemperature]: [
      {
        label: 'value_label',
        extract: m => MapperUtil.quantity(m.temperature)
      }, {
        label: 'type',
        extract: m => m.method
      }, {
        label: 'method',
        extract: m => m.bodySite?.text
      }
    ],
    [ResourceSubType.heartRate]: [
      {
        label: 'value_label',
        extract: m => MapperUtil.quantity(m.rate)
      }, {
        label: 'regularity',
        extract: m => m.regularity
      }, {
        label: 'method',
        extract: m => m.method
      }
    ],
    [ResourceSubType.pulseRate]: [
      {
        label: 'value_label',
        extract: m => MapperUtil.quantity(m.rate)
      }, {
        label: 'regularity',
        extract: m => m.regularity
      }
    ],
    [ResourceSubType.oxygenSaturation]: [
      {
        label: 'value_label',
        extract: m => MapperUtil.quantity(m.saturation)
      }, {
        label: 'extra_oxygen',
        extract: m => m.extraOxygen
      }
    ],
    [ResourceSubType.bloodGlucose]: [
      {
        label: 'value_label',
        extract: m => MapperUtil.quantity(m.glucose)
      }, {
        label: 'type',
        extract: m => m.type
      },
      {
        label: 'offset',
        extract: m => MapperUtil.quantity(m.offset)
      }, {
        label: 'interpretationCode',
        extract: m => m.interpretation
      }
    ],
    [ResourceSubType.laboratoryTestResult]: [
      {
        label: null,
        extract: m => MapperUtil.quantity(m.testResult)
      }
    ],
    [ResourceSubType.gpJournalEntry]: [
      {
        label: null,
        extract: m => m.value
      }
    ],
    [ResourceSubType.generic]: [
      {
        label: null,
        extract: observation => !observation.value
          ? ''
          : typeof observation.value === 'string'
            ? observation.value
            : observation.value?.coding?.[0]
              ? observation.value.coding[0]?.code + ' - ' + observation.value.coding[0]?.display
              : MapperUtil.quantity(observation.value)
      }
    ]
  };

  public static codeableConcept(value?: {
    coding: [{
      code: string,
      display: string,
      system: string,
      userSelected: boolean,
      version: string,
    }],
    text: string
  }): string {
    return value?.coding?.[0]?.display || value?.text;
  }

  public static quantity(value?: {
    code: string,
    system: string,
    unit: string,
    value: number
  }): string {
    return value?.value !== undefined && value?.value !== null
      ? value?.value + (value.unit ? value.unit + ' ' : '')
      : null;
  }

  public static mapObservationResults(observation: any, translateService: TranslateService): any[] {
    return this.observationMapping[observation.observationType]?.map((o: {
      label: string,
      extract: (m) => string
    }) => {
      const value = o.extract(observation);
      if (value === null || value === undefined || !o.label) {
        return value;
      }
      const label = translateService.instant('measurements_' + observation.observationType.toLowerCase() + '_' + o.label);
      return label + ': ' + value;
    }).filter(Boolean);
  }

  public static mapCodeSystem(system: string): string {
    switch (system) {
      case 'urn:oid:2.16.840.1.113883.2.4.4.7':
        return 'HPK';
      case 'urn:oid:2.16.840.1.113883.2.4.4.10':
        return 'PRK';
      case 'urn:oid:2.16.840.1.113883.2.4.4.1':
        return 'GPK';
      default:
        return null;
    }
  }

  public static routeFromDutch(administrationRoute: string): string {
    switch (administrationRoute?.toLowerCase()) {
      case 'oraal':
        return RouteType.orally;
      case 'rectaal':
        return RouteType.rectally;
      case 'cutaan':
        return RouteType.subcutaneously;
      case 'transdermaal':
        return RouteType.transdermally;
      case 'intraveneus':
        return RouteType.intravenously;
      case 'intramusculair':
        return RouteType.intramuscularly;
      case 'sublinguaal':
        return RouteType.sublingually;
      case 'bucaal':
        return RouteType.bucally;
      case 'vaginaal':
        return RouteType.vaginally;
      case 'nasaal':
        return RouteType.nasally;
      case 'doorinademing':
        return RouteType.byInhalation;
      case 'doorverneveling':
        return RouteType.byNebulization;
      default:
        return administrationRoute;
    }
  }
}
