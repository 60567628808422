import { InjectionToken } from '@angular/core';

export const authConstants = {
  defaultBgLink: 'https://medrecord-logos.s3.eu-north-1.amazonaws.com/auth-ui-bg.png',
};

export type AuthConstants = typeof authConstants;

export const AUTH_CONSTANTS = new InjectionToken('Authorization Constants');

export const provideAuthConstants = () => [
  {
    provide: AUTH_CONSTANTS,
    useValue: authConstants
  }
];