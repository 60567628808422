import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as UsersManagementActions from './users-management.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UsersManagementService } from '../services/users-management.service';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';
import { IUserModel } from '../models/interfaces/user.interface';
import { StorageService } from '@medrecord/tools-storage';
import { StorageKeys } from '@medrecord/core';
import { IUserManagementStatus } from '../models/enums/user-management-status.enum';
import { concat, from } from 'rxjs';
import { NetworkOrganisation } from '@managers/admin-organisations-manager';
import { Store } from '@ngrx/store';

@Injectable()
export class UsersManagementEffects {
  @Effect()
  loadUsers$ = this.actions$.pipe(
    ofType(UsersManagementActions.loadUsersAction),
    switchMap((action) => {
      const { start, count, search } = action;
      return this.service.getUsers(start, count, search).pipe(
        map((res) => {
          return UsersManagementActions.loadUsersSuccessAction({
            users: res,
          });
        }),
        catchError(({ error }) => {
          return [
            UsersManagementActions.loadUsersFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_users_error'), error)),
          ];
        })
      );
    })
  );

  @Effect()
  updateUser$ = this.actions$.pipe(
    ofType(UsersManagementActions.changeUserAction),
    switchMap((action) => {
      const { userId, roles, status, statusChange } = action;
      return this.service.editUser(userId, roles, status, statusChange).pipe(
        map(() => {
          return UsersManagementActions.changeUserSuccessAction({ userId, roles, status });
        }),
        catchError(({ error }) => {
          return [
            UsersManagementActions.changeUserFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('edit_user_error'), error)),
          ];
        })
      );
    })
  );

  @Effect()
  addUser = this.actions$.pipe(
    ofType(UsersManagementActions.addUserAction),
    map((action) => action.user),
    switchMap((userData: IUserModel) => {
      userData = {
        ...userData,
        languageCode: this.storage.getItem(StorageKeys.Language, 'en'),
        status: IUserManagementStatus.UNVERIFIED,
      };

      return from(this.service.addUser(userData)).pipe(
        map((res) =>
          UsersManagementActions.addUserSuccessAction({
            user: { ...userData, ...res?.body },
          })
        ),
        catchError(({ error }) => [
          UsersManagementActions.addUserFailureAction({ error }),
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('add_user_error'), error)),
        ])
      );
    })
  );

  @Effect()
  loadUsersCount$ = this.actions$.pipe(
    ofType(UsersManagementActions.loadUsersCountAction),
    switchMap(({ search }) => {
      return this.service.getUsersCount(search).pipe(
        map((count) => {
          return UsersManagementActions.loadUsersCountActionSuccess({ count });
        }),
        catchError(({ error }) => {
          return [
            UsersManagementActions.loadUsersCountFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_users_count_error'), error)),
          ];
        })
      );
    })
  );

  @Effect()
  editUserPersonData$ = this.actions$.pipe(
    ofType(UsersManagementActions.editUserPersonDataAction),
    switchMap(({ personData, userId }) => {
      return this.service.editUserPersonData(personData, userId).pipe(
        map(() => {
          return UsersManagementActions.editUserPersonDataActionSuccess({ personData, userId });
        }),
        catchError(({ error }) => {
          return [
            UsersManagementActions.editUserPersonDataFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_users_count_error'), error)),
          ];
        })
      );
    })
  );

  @Effect()
  addUserPersonData$ = this.actions$.pipe(
    ofType(UsersManagementActions.addUserPersonDataAction),
    switchMap(({ personData, userId }) => {
      return this.service.addUserPersonData(personData, userId).pipe(
        map(() => {
          return UsersManagementActions.addUserPersonDataActionSuccess({ personData, userId });
        }),
        catchError(({ error }) => {
          return [
            UsersManagementActions.addUserPersonDataFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_users_count_error'), error)),
          ];
        })
      );
    })
  );

  @Effect()
  assignUserToOrganisations = this.actions$.pipe(
    ofType(UsersManagementActions.assignUserToOrganisationsAction),
    switchMap(({ userId, organisations }) => {
      const promises = organisations.map((organisation: NetworkOrganisation) => {
        const { id, medicalRoles, role } = organisation;
        return this.service.assignUserToOrganisation(id, { practitionerId: userId, medicalRoles, role });
      });

      return concat(...promises).pipe(
        map(() => {
          return UsersManagementActions.assignUserToOrganisationsSuccessAction({ organisations, userId });
        }),
        catchError(({ error }) => {
          return [
            UsersManagementActions.addUserPersonDataFailureAction({ error }),
            addErrorToast(
              getErrorToastBodyUtil(this.translateService.instant('assign_user_organisations_error'), error)
            ),
          ];
        })
      );
    })
  );

  @Effect()
  updateUserOrganisations = this.actions$.pipe(
    ofType(UsersManagementActions.updateUserOrganisationsAction),
    switchMap(({ userId, organisations }) => {
      const promises = organisations.map((organisation: NetworkOrganisation) => {
        const { id, medicalRoles, role } = organisation;
        return this.service.updateUserOrganisation(id, { practitionerId: userId, medicalRoles, role });
      });

      return concat(...promises).pipe(
        map(() => {
          return UsersManagementActions.updateUserOrganisationsSuccessAction({ organisations, userId });
        }),
        catchError(({ error }) => {
          return [
            UsersManagementActions.addUserPersonDataFailureAction({ error }),
            addErrorToast(
              getErrorToastBodyUtil(this.translateService.instant('update_user_organisations_error'), error)
            ),
          ];
        })
      );
    })
  );

  @Effect()
  unassignUserFromOrganisation = this.actions$.pipe(
    ofType(UsersManagementActions.unassignUserFromOrganisationAction),
    switchMap(({ userId, organisationId }) => {
      return this.service.unassignUser(userId, organisationId).pipe(
        map(() => {
          return UsersManagementActions.unassignUserFromOrganisationSuccessAction({ userId, organisationId });
        }),
        catchError(({ error }) => {
          return [
            UsersManagementActions.unassignUserFromOrganisationFailure({ error }),
            addErrorToast(
              getErrorToastBodyUtil(this.translateService.instant('unassign_user_organisation_error'), error)
            ),
          ];
        })
      );
    })
  );

  @Effect()
  deleteUser = this.actions$.pipe(
    ofType(UsersManagementActions.deleteUserAction),
    switchMap(({ userId }) => {
      return this.service.deleteUser(userId).pipe(
        map(() => {
          this.store.dispatch(
            addSuccessToast({ title: 'delete_user_success_title', content: 'delete_user_success_content' })
          );
          return UsersManagementActions.deleteUserSuccessAction({ userId });
        }),
        catchError(({ error }) => {
          this.store.dispatch(
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('delete_user_fail_title'), error))
          );
          return [UsersManagementActions.deleteUserSuccessFailure({ error })];
        })
      );
    })
  );

  constructor(
    private storage: StorageService<StorageKeys>,
    private actions$: Actions,
    private translateService: TranslateService,
    private service: UsersManagementService,
    private store: Store
  ) {}
}
