import { Action, createReducer } from '@ngrx/store';
import {
  Careprovider,
  CareproviderDataService,
  CareproviderLog,
  FetchResourceRequest,
  FetchResourceResponse,
  LoadResourceResponse,
  MappingResource,
  ResourceDataAny,
  ResourceParameter
} from '../models/interfaces';
import {
  careproviderReducer,
  dataserviceReducer,
  medmijInteractionReducer,
  modalChainReducer,
  resourceReducer,
  testCareproviderReducer
} from './reducers';
import { PullTransaction, Resource, TestCareproviderFetchStatusType } from '../models/enums';
import { TabularResource } from '../models/interfaces/tabular/tabular-resource.interface';
import { commonReducer } from './reducers/common.reducer';

export const myDoctorManagerFeatureKey = 'my-doctor-manager';

export interface State {
  careproviders: Careprovider[];
  careprovidersCount: number;

  navigatedToCareproviderDetailsFrom: 'favourites' | 'search';

  activeCareprovider: Careprovider;
  activeDataservice: CareproviderDataService;
  activeTransactionId: PullTransaction;

  allResourcesKeys: MappingResource[]; // of an active transaction
  filteredResourcesKeys: MappingResource[]; // visible chips = visible in the UI
  modalSelectedResourcesKeys: MappingResource[];

  resourcesModalOpenStatus: boolean;
  resourceParameters: ResourceParameter[];
  parametersModalOpenStatus: boolean;
  testCareproviderModalOpenStatus: boolean;

  loadResourceResponse: LoadResourceResponse[]; // data !!!
  fetchResourceRequest: FetchResourceRequest;
  afterFetchingResourcesReturnTo: string;
  fetchResourceResponse: FetchResourceResponse[]; // info about what was fetched from medmij
  resourceData: ResourceDataAny[];
  tabularResourceData: { [key in Resource]?: TabularResource<string> };

  testCareproviderStatusModalOpenStatus: boolean;
  testCareproviderStatusFetchType: TestCareproviderFetchStatusType;
  testCareproviderStatusFetchData: string;

  logsModalOpenStatus: boolean;
  logs: CareproviderLog[];

  deleteDataModalOpenStatus: boolean;
  deleteDataModalResource: MappingResource;
  deleteDataModalIsDataservice: boolean;
}

const initialState: State = {
  careproviders: [],
  careprovidersCount: 0,

  navigatedToCareproviderDetailsFrom: 'favourites',

  activeCareprovider: null,
  activeDataservice: null,
  activeTransactionId: null,

  allResourcesKeys: [],
  filteredResourcesKeys: [],
  modalSelectedResourcesKeys: [],

  resourcesModalOpenStatus: false,
  resourceParameters: [],
  parametersModalOpenStatus: false,
  testCareproviderModalOpenStatus: false,

  loadResourceResponse: [],
  fetchResourceRequest: { resources: [] },
  afterFetchingResourcesReturnTo: null,
  fetchResourceResponse: [],
  resourceData: [],
  tabularResourceData: {},

  testCareproviderStatusModalOpenStatus: false,
  testCareproviderStatusFetchType: null,
  testCareproviderStatusFetchData: null,

  logsModalOpenStatus: false,
  logs: [],

  deleteDataModalOpenStatus: false,
  deleteDataModalResource: null,
  deleteDataModalIsDataservice: false
};

const _myDoctorManagerReducer = createReducer(
  initialState,
  ...careproviderReducer,
  ...commonReducer,
  ...dataserviceReducer,
  ...resourceReducer,
  ...modalChainReducer,
  ...medmijInteractionReducer,
  ...testCareproviderReducer
);

export function myDoctorManagerReducer(state: State, action: Action): State {
  return _myDoctorManagerReducer(state, action);
}
