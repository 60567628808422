<ng-container *ngIf="resources?.length; else noData">
  <ng-container *ngFor="let resource of resources">
    <doctor-gui-resource-container
      *ngIf="!resource.group"
      [resource]="resource"
    ></doctor-gui-resource-container>
    <doctor-gui-resource-group-container
      *ngIf="resource.group"
      [resourceGroup]="resource"
    ></doctor-gui-resource-group-container>
  </ng-container>
</ng-container>
<ng-template #noData>
  <div class="row">
    <div class="col-12 my-5 d-flex justify-content-center">{{ 'my_doctor_no_data' | translate }}</div>
  </div>
</ng-template>
