import { Component, Input } from '@angular/core';
import { ResourceDataGroup } from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-resource-group-container',
  templateUrl: './resource-group-container.component.html'
})
export class ResourceGroupContainerComponent {
  @Input() resourceGroup: ResourceDataGroup;
}
