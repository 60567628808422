import { createAction, props } from '@ngrx/store';
import { IQueryParamsPayload } from '../models/query-params-payload.model';
import { IWeatherData } from '../models/weather-data.model';

export const getWeatherDataAction = createAction(
  '[Weather Manager Actions] Get Weather Data Action',
  props<{ queryParams: IQueryParamsPayload }>()
);

export const getWeatherDataSuccessAction = createAction(
  '[Weather Manager Actions] Get Weather Data Success Action',
  props<{ data: IWeatherData }>()
);

export const getWeatherDataFailureAction = createAction(
  '[Weather Manager Actions] Get Weather Data Failure Action',
  props<{ error: any }>()
);

export const setUserLocationAction = createAction(
  '[Weather Manager Actions] Set User Location Action',
  props<{ location: string }>()
);
