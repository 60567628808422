import { Action, createReducer, on } from '@ngrx/store';
import { Post, Tag } from '../models/interfaces';
import {
  clearActivePostAction,
  loadCareplanCategoriesFailureAction,
  loadCareplanCategoriesSuccessAction,
  loadCareplanPostFailureAction,
  loadCareplanPostsFailureAction,
  loadCareplanPostsSuccessAction,
  loadCareplanResourcesPostsFailureAction,
  loadCareplanResourcesPostsSuccessAction,
  loadCategoriesFailureAction,
  loadCategoriesSuccessAction,
  loadFeaturedPostsFailureAction,
  loadFeaturedPostsSuccessAction,
  loadPostFailureAction,
  loadPostsFailureAction,
  loadPostsSuccessAction,
  loadPostSuccessAction,
  loadCareplanPostSuccessAction
} from './news-manager.actions';

export const newsManagerFeatureKey = 'news-manager';

export interface NewsManagerState {
  error: any;
  posts: Post[];
  careplanPosts: Post[];
  careplanResourcesPosts: Post[];
  featuredPosts: Post[];
  categories: Tag[];
  careplanCategories: Tag[];
  activePost: Post;
  careplanActivePost: Post;
}

export const initialState: NewsManagerState = {
  error: null,
  posts: [],
  careplanPosts: [],
  careplanResourcesPosts: [],
  featuredPosts: [],
  categories: [],
  careplanCategories: [],
  activePost: null,
  careplanActivePost: null,
};

export const newsManagerReducer = createReducer(
  initialState,
  on(loadPostsSuccessAction, (state, { posts }) => ({
    ...state,
    posts: posts.posts,
  })),
  on(loadCareplanPostsSuccessAction, (state, { posts }) => ({
    ...state,
    careplanPosts: posts.posts,
  })),
  on(loadCareplanResourcesPostsSuccessAction, (state, { posts }) => ({
    ...state,
    careplanResourcesPosts: posts.posts,
  })),
  on(loadFeaturedPostsSuccessAction, (state, { featuredPosts }) => ({
    ...state,
    featuredPosts,
  })),
  on(loadCategoriesSuccessAction, (state, { categories }) => ({
    ...state,
    categories: categories.tags,
  })),
  on(loadCareplanCategoriesSuccessAction, (state, { categories }) => ({
    ...state,
    careplanCategories: categories.tags,
  })),
  on(loadPostSuccessAction, (state, { post }) => ({
    ...state,
    activePost: post?.posts?.[0],
  })),
  on(loadCareplanPostSuccessAction, (state, { post }) => ({
    ...state,
    careplanActivePost: post?.posts?.[0],
  })),
  on(clearActivePostAction, (state) => ({
    ...state,
    activePost: null,
    careplanActivePost: null,
  })),
  on(loadPostsFailureAction,
    loadCareplanPostsFailureAction,
    loadCareplanResourcesPostsFailureAction,
    loadFeaturedPostsFailureAction,
    loadCategoriesFailureAction,
    loadCareplanCategoriesFailureAction,
    loadPostFailureAction,
    loadCareplanPostFailureAction, (state, { error }) => ({
      ...state,
      error,
    }))
);

export const reducer = (state: NewsManagerState, action: Action): NewsManagerState => {
  return newsManagerReducer(state, action);
};
