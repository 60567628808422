import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService } from '@medrecord/services-form-validation';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { initPhone, savePhone, selectPhone } from '@medrecord/managers-auth';
import { Observable } from 'rxjs';
import { MapFieldType } from '@medrecord/ui';
import { StorageKeys } from '@medrecord/core';
import { StorageService } from '@medrecord/tools-storage';

@Component({
  selector: 'medrecord-auth-phone-save',
  templateUrl: './phone-save.component.html',
  styleUrls: ['./phone-save.component.scss']
})
export class PhoneSaveComponent {
  form: FormGroup;
  phone$: Observable<string> = this.store.select(selectPhone);
  
  readonly mapFieldType = MapFieldType;
  readonly isOAuth = this.storage.getItem<boolean>(StorageKeys.isOAuth, false);
  
  constructor(
    fbBuilder: FormBuilder,
    validationService: ValidationService,
    translateService: TranslateService,
    
    private storage: StorageService<StorageKeys>,
    private store: Store,
  ) {
    this.form = fbBuilder.group({
      code: [null, [
        validationService.required(translateService.instant('auth_validation_error_required')),
        validationService.pattern(
          /^[\d]{4}$/,
          translateService.instant('error_wrong_verification_code')
        )
      ]]
    });
  }
  
  resend(phone: string): void {
    this.store.dispatch(initPhone({ phone }));
  }
  
  onNext(): void {
    if(this.form.valid) {
      this.store.dispatch(savePhone({
        code: this.form.value.code,
      }));
    }
  }
}
