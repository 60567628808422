import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PullTransaction } from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-dataservice-details',
  templateUrl: './dataservice-details.component.html',
  styleUrls: ['./dataservice-details.component.scss']
})
export class DataserviceDetailsComponent {
  @Input() transactionId: PullTransaction;
  @Output() cancelLongTermConsent: EventEmitter<void> = new EventEmitter<void>();

  readonly pullTransaction = PullTransaction;

  public cancelLongTerm(): void {
    this.cancelLongTermConsent.emit();
  }
}
