import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { selectUserId } from '@medrecord/managers-auth';
import { TranslateService } from '@ngx-translate/core';
import {
  createPaymentWithStripeAction,
  loadPaymentPlansAction,
  loadPaymentPlansFailureAction,
  loadPaymentPlansSuccessAction,
} from './payment-manager.actions';
import { PaymentManagerService } from '../services/payment-manager.service';

@Injectable()
export class PaymentManagerEffects {
  loadPaymentPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPaymentPlansAction),
      withLatestFrom(this.store.select(selectUserId)),
      mergeMap(([_, userId]) => {
        return this.paymentManagerService.loadPaymentPlansForUser(userId).pipe(
          switchMap((paymentPlans) => {
            return [loadPaymentPlansSuccessAction({ paymentPlans })];
          }),
          catchError((error: any) => [
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_payment_plans_error'), error)),
            loadPaymentPlansFailureAction({ error }),
          ])
        );
      })
    )
  );

  createPaymentWithStripe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPaymentWithStripeAction),
      withLatestFrom(this.store.select(selectUserId)),
      mergeMap(([payload, userId]) => {
        return this.paymentManagerService
          .createPaymentWithStripe(payload.paymentPlanId, userId)
          .pipe(
            switchMap(checkout => {
              if(checkout?.checkoutUrl) {
                window.location.href = checkout.checkoutUrl;
              }
              else throw new Error("");
              return [];
            }),
            catchError((error: any) => [
              addErrorToast(
                getErrorToastBodyUtil(this.translateService.instant('create_payment_with_stripe_error'), error)
              ),
            ])
          );
      })
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private translateService: TranslateService,
    private paymentManagerService: PaymentManagerService
  ) {}
}
