import { createAction, props } from '@ngrx/store';
import { Allergy, LoadAllergiesPayload } from '../models/interfaces';
import { AllergyName } from '../models/interfaces/allergy-name.interface';

export const loadAllergiesAction = createAction(
  '[Allergies Manager Actions] Load Allergies',
  props<{ loadPayload: LoadAllergiesPayload }>()
);

export const loadAllergiesSuccessAction = createAction(
  '[Allergies Manager Actions] Load Allergies Success',
  props<{ userId: string; allergies: Allergy[]; count: number }>()
);

export const loadCareplanAllergiesSuccessAction = createAction(
  '[Allergies Manager Actions] Load Careplan Allergies Success',
  props<{ userId: string; allergies: Allergy[]; count: number }>()
);

export const loadAllergiesFailureAction = createAction(
  '[Allergies Manager Actions] Load Allergies Failure',
  props<{ error: any }>()
);

export const linkCareplanAllergiesSuccessAction = createAction(
  '[Allergies Manager Actions] Link Care Plan Allergies Success',
  props<{ allergies: Allergy[] }>()
);

export const loadAllergyAction = createAction('[Allergies Manager Actions] Load Allergy', props<{ id: string }>());

export const loadAllergySuccessAction = createAction(
  '[Allergies Manager Actions] Load Allergy Success',
  props<{ allergy: Allergy }>()
);

export const deleteAllergyAction = createAction(
  '[Allergies Manager Actions] Delete Allergy Action',
  props<{ id: string }>()
);

export const deleteAllergySuccessAction = createAction(
  '[Allergies Manager Actions] Delete Allergy Success Action',
  props<{ id: string }>()
);

export const removeActiveAllergy = createAction('[Allergies Manager Actions] Remove Active Allergy Action');

export const loadAutocompleteAllergyNames = createAction(
  '[Allergies Manager Actions] Load Autocomplete Allergy Names',
  props<{ query: string }>()
);

export const loadAutocompleteAllergyNamesSuccess = createAction(
  '[Allergies Manager Actions] Load Autocomplete Allergy Names Success',
  props<{ allergyNames: AllergyName[] }>()
);

export const clearAutocompleteAllergyNames = createAction(
  '[Allergies Manager Actions] Clear Autocomplete Allergy Names'
);

export const addAllergy = createAction('[Allergies Manager Actions] Add Allergy', props<{ allergy: Allergy }>());

export const addAllergySuccess = createAction(
  '[Allergies Manager Actions] Add Allergy Success',
  props<{ allergy: Allergy }>()
);

export const editAllergy = createAction(
  '[Allergies Manager Actions] Edit Allergy',
  props<{ allergy: Allergy; allergyId: string }>()
);

export const editAllergySuccess = createAction(
  '[Allergies Manager Actions] Edit Allergy Success',
  props<{ allergy: Allergy }>()
);

export const removeCareplanAllergyAction = createAction(
  '[Allergies Actions] Remove Care Plan Allergy',
  props<{ id: string }>()
);
