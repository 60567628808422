import { Component, Input } from '@angular/core';

@Component({
  selector: 'medrecord-ui-inline-block-column',
  templateUrl: './inline-block-column.component.html',
})
export class InlineBlockColumnComponent {
  @Input() value: string;
  @Input() color: string;
}
