import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MEDSAFE_ROUTE_NAMES, MedsafeRouteNames } from '@medrecord/routes-medsafe';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'doctor-gui-medsafe-my-doctor-wrapper',
  templateUrl: './my-doctor-wrapper.component.html',
  styleUrls: ['./my-doctor-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyDoctorWrapperComponent implements OnInit, OnDestroy {

  selectedTab = 0;
  hideTabs = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(MEDSAFE_ROUTE_NAMES) public medsafeRouteNames: MedsafeRouteNames) {
  }

  ngOnInit(): void {
    this.analyzeFirstChildRouteUrl();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  tabSelected({ selectedItem, selectedIndex }): void {
    this.selectedTab = selectedIndex;
    this.router.navigate([selectedItem.dataset.id], {
      relativeTo: this.activatedRoute
    });
  }

  private analyzeFirstChildRouteUrl(): void {
    const firstChildRouteUrl = this.activatedRoute.firstChild.snapshot.url[0].path;

    this.toggleTabs(firstChildRouteUrl);
    this.setInitiallySelectedTab(firstChildRouteUrl);
  }

  /**
   * Shows tabs for search and favourites pages, but hides them on careprovider page.
   */
  private toggleTabs(firstChildRouteUrl: string): void {
    // initial visit
    this.hideTabs = firstChildRouteUrl === this.medsafeRouteNames.MyDoctor.Careprovider;
    // every subsequent visit
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const firstChildRoute = this.activatedRoute.firstChild;
        if (firstChildRoute) {
          this.hideTabs = firstChildRoute.snapshot.url[0].path === this.medsafeRouteNames.MyDoctor.Careprovider;
        }
      });
  }

  private setInitiallySelectedTab(firstChildRouteUrl: string): void {
    /**
     * Normally, click on the tab would select it.
     * It would also work in our case, where clicking on tab header navigates to other child components.
     *
     * Method is implemented like this, in case user is not on the default tab, and then refreshes the page.
     * "Selected tab depends on the child routes."
     */
    if (firstChildRouteUrl === this.medsafeRouteNames.MyDoctor.Search) {
      this.selectedTab = 1;
    }
  }
}
