<medrecord-ui-field-label
  *ngIf="label"
  for="datepicker"
  [hint]="hint"
  [secondaryText]="secondaryLabel"
  [text]="label"
  [type]="labelType"
></medrecord-ui-field-label>
<div class="d-flex align-items-center flex-wrap">
  <medrecord-ui-select-precision
    *ngIf="enablePrecisionChange"
    class="mr-2 mb-2"
    [(selectedPrecision)]="precision"
    [disabled]="disabled"
  ></medrecord-ui-select-precision>
  <ejs-datepicker
    *ngIf="precision === DatePrecision.day || precision === DatePrecision.month || precision === DatePrecision.year"
    id="datepicker"
    [placeholder]="placeholder"
    [style.min-width]="'255px'"
    class="mb-2"
    [min]="minDate"
    [max]="maxDate"
    [ngModel]="date"
    [allowEdit]="true"
    [showTodayButton]="false"
    [showClearButton]="true"
    [format]="dateTimeFormat"
    [start]="startCalendarView"
    [depth]="calendarDepth"
    [firstDayOfWeek]="firstDayOfWeek"
    [cssClass]="'e-custom-datepicker'"
    [disabled]="disabled"
    [ngClass]="{
      'e-warning': controlErrorsList.length,
      'e-error': externalErrorsList.length || errorCode
    }"
    (cleared)="value = null"
    (focus)="markAsTouched()"
    (change)="handleChange($event)"
  ></ejs-datepicker>
  <ejs-datetimepicker
    *ngIf="precision === DatePrecision.second"
    id="datepicker"
    class="mb-2"
    [placeholder]="placeholder"
    [style.min-width]="'255px'"
    [min]="minDate"
    [max]="maxDate"
    timeFormat="HH:mm"
    [ngModel]="date"
    [allowEdit]="false"
    [format]="dateTimeFormat"
    [start]="startCalendarView"
    [depth]="calendarViewDepth"
    [showTodayButton]="false"
    [showClearButton]="true"
    [step]="step"
    [cssClass]="'e-custom-datepicker'"
    [disabled]="disabled"
    [ngClass]="{
      'e-warning': controlErrorsList.length,
      'e-error': externalErrorsList.length || errorCode
    }"
    (cleared)="value = null"
    (focus)="markAsTouched()"
    (change)="handleChange($event)"
  >
  </ejs-datetimepicker>
</div>
<medrecord-ui-field-error
  [errors]="controlErrorsList"
  [errorCode]="errorCode"
  [externalErrors]="externalErrorsList"
></medrecord-ui-field-error>
