<ejs-tab [selectedItem]="selectedTab" (selected)="tabSelected($event)" *ngIf="!hideTabs">
  <e-tabitems>
    <e-tabitem
      [id]="medsafeRouteNames.MyDoctor.Favourites"
      [header]="{
          text: 'my_doctor_favourites' | translate | titlecase,
          headerStyle: 'default'
        }"
    ></e-tabitem>

    <e-tabitem
      [id]="medsafeRouteNames.MyDoctor.Search"
      [header]="{
          text: 'my_doctor_search' | translate | titlecase,
          headerStyle: 'default'
        }"
    ></e-tabitem>
  </e-tabitems>
</ejs-tab>

<router-outlet></router-outlet>
