import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-note',
  templateUrl: './note.component.svg',
})
export class NoteComponent extends MedrecordIcon {
  viewHeight = 33;
  viewWidth = 30;
}
