<div class="h-100 sidebar-wrapper">
  <div [ngClass]="[!isSidebarHidden && currentScreenSize !== screenSizes.desktop ? 'mobile-container h-100' : '']">
    <ng-container *ngTemplateOutlet="sidebarTpl"></ng-container>
  </div>

  <div class="main-content h-100">
    <div
      class="sidebar-content"
      [class.full-content]="!isSidebarHidden && currentScreenSize !== screenSizes.desktop"
      [class.wide-content]="!isOpen && currentScreenSize === screenSizes.desktop"
      [class.narrow-content]="isOpen && currentScreenSize === screenSizes.desktop"
      #sidebarContent
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>

<ng-template #sidebarTpl>
  <ejs-sidebar
    *ngIf="!isSidebarHidden"
    [class]="!isSidebarHidden ? 'transition-in' : 'transition-out'"
    [class.is-mobile]="currentScreenSize !== screenSizes.desktop"
    [animate]="false"
    [width]="width"
    [isOpen]="isOpen"
    [dockSize]="dockSize"
    [target]="target"
    [enableDock]="true"
    [enableGestures]="false"
  >
    <div class="main-menu">
      <div
        class="menu-items"
        [style.padding-bottom]="bottomOffset"
        [class.is-mobile]="currentScreenSize !== screenSizes.desktop"
      >
        <medrecord-ui-tree-list
          (select)="selectItem($event)"
          [activeMap]="activeMap"
          [items]="items"
          [isSidebarOpen]="isOpen"
        ></medrecord-ui-tree-list>
      </div>

      <div *onlyForScreen="screenSizes.desktop" class="collapse-btn" (click)="toggleSidebar()">
        <medrecord-svg-collapse [class.rotated]="!isOpen"></medrecord-svg-collapse>
        <span class="text-weight-500 text-lg" [class]="isOpen ? 'transition-in' : 'transition-out'">
          {{ 'common_collapse' | translate }}
        </span>
      </div>
    </div>
  </ejs-sidebar>
</ng-template>
