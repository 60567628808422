<div class="graph-wrapper">
  <div [id]="elementId" #graph></div>

  <div class="percentage" *ngIf="showOnlyScore; else defaultPercentage">
    {{ healthScore }}
  </div>
</div>

<ng-template #defaultPercentage>
  <div class="percentage">
    <span class="percentage_title" [class.is-mobile]="isMobile">{{ 'common_health_score' | translate }}</span>
    <div>
      <span class="percentage_current" [class.is-mobile]="isMobile">{{ healthScore }}</span>
      <span class="percentage_total" [class.is-mobile]="isMobile">/ 100%</span>
    </div>
  </div>
</ng-template>
