<div class="card">
  <h5 class="card-title mt-4 ml-3">{{ title }}</h5>
  <div class="card-body">
    <div *ngFor="let item of items" class="row mb-2">
      <div class="col">
        <h3 class="text-weight-600 text-md text-boulder mb-0">{{
          item.localeLabel ?
          (item.localeLabel | translate) :
          item.label
        }}</h3>
      </div>
      <div class="col">
        <p class="mb-0">{{ item.value }}</p>
      </div>
    </div>
  </div>
</div>
