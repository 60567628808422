<h3 class="text-weight-600 text-primary text-center mt-5">{{ 'auth_welcome_signup_option' | translate }}</h3>
<p class="text-left d-none d-lg-inline-block">{{ 'auth_signup_role_description' | translateOrHide }}</p>

<form (submit)="handleFormSubmit()" [formGroup]="form" class="my-3 text-center">
  <div>
    <medrecord-ui-map-field
      [attr.id]="isOAuth && 'oauth-email-field'"
      [fieldType]="mapFieldType.Input"
      [label]="'common_form_field_email' | translate"
      controlName="email"
      [parentForm]="form"
      [errorCode]="errorCode$ | async"
      [options]="emailOptions"
    >
      <ng-template #leftIcon>
        <i class="far fa-envelope text-black"></i>
      </ng-template>
    </medrecord-ui-map-field>
  </div>

  <div class="mt-3">
    <medrecord-ui-map-field
      [attr.id]="isOAuth && 'oauth-password-field'"
      [fieldType]="mapFieldType.Input"
      [type]="showPassword ? 'text' : 'password'"
      [label]="'auth_credentials_new_password_label' | translate"
      controlName="password"
      autocomplete="new-password"
      [parentForm]="form"
    >
      <ng-template #leftIcon>
        <i class="fas fa-lock text-black"></i>
      </ng-template>

      <ng-template #rightIcon>
        <i
          class="fas fa-eye text-black cursor-pointer"
          [class.fa-eye]="!showPassword"
          [class.fa-eye-slash]="showPassword"
          (click)="showPassword = !showPassword"
        ></i>
      </ng-template>
    </medrecord-ui-map-field>
  </div>

  <div class="mt-3">
    <medrecord-ui-map-field
      [attr.id]="isOAuth && 'oauth-password-repeat-field'"
      [fieldType]="mapFieldType.Input"
      [type]="showRepeatPassword ? 'text' : 'password'"
      [label]="'auth_credentials_repeat_new_password_label' | translate"
      controlName="repeatPassword"
      [parentForm]="form"
    >
      <ng-template #leftIcon>
        <i class="fas fa-lock text-black"></i>
      </ng-template>

      <ng-template #rightIcon>
        <i
          class="fas fa-eye text-black cursor-pointer"
          [class.fa-eye]="!showRepeatPassword"
          [class.fa-eye-slash]="showRepeatPassword"
          (click)="showRepeatPassword = !showRepeatPassword"
        ></i>
      </ng-template>
    </medrecord-ui-map-field>
  </div>

  <div class="mt-3" *ngIf="isOAuth">
    <medrecord-ui-map-field
      [fieldType]="mapFieldType.CheckBox"
      [parentForm]="form"
      [options]="confirmOptions"
      controlName="confirm"
    >
      <div class="text-weight-400">
        {{ 'auth_read_agreed' | translate }}
        <a (click)="openTerms($event)" class="text-weight-600 text-black">
          {{ 'auth_terms_link_text' | translate }}
        </a>
      </div>
    </medrecord-ui-map-field>
  </div>

  <button
    [class.btn-rounded]="isOAuth"
    class="btn btn-primary mt-3 mb-2 text-white font-weight-bold w-100"
    [disabled]="!form.valid"
  >
    {{ 'auth_welcome_signup_option' | translate }}
  </button>
</form>

<div class="d-none d-lg-block text-center">
  <span class="text-lg">{{ 'auth_credentials_have_question' | translate }}</span>
  <a (click)="goToLogin()" class="text-underline text-lg text-center cursor-pointer">
    {{ 'common_click_here' | translate }}
  </a>
</div>

<medrecord-ui-privacy-policy-dialog #privacyPolicy></medrecord-ui-privacy-policy-dialog>
