import { Component, Input } from '@angular/core';
import { TestCareproviderFetchStatusType } from '@managers/my-doctor';
import { AbstractModalComponent } from '../abstract-modal/abstract-modal.component';

@Component({
  selector: 'doctor-gui-test-careprovider-status-modal',
  templateUrl: 'test-careprovider-status-modal.component.html',
})
export class TestCareproviderStatusModalComponent extends AbstractModalComponent {
  @Input() type: TestCareproviderFetchStatusType;
  @Input() status: string;
}
