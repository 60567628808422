import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoodFriendManagerStateModule } from './state-management/food-friend-manager-state.module';
import { NutriciaService } from './services/nutricia.service';

@NgModule({
  imports: [CommonModule, FoodFriendManagerStateModule],
  providers: [NutriciaService],
})
export class FoodFriendManagerModule {}
