import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const medicationUseBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.medicationStatement,
  fieldGroups: [
    {
      name: ({ resource }) => resource?.content?.productUsed?.display || 'my_doctor_medication',
      fields: [
        {
          label: 'my_doctor_identification',
          fieldName: 'identifier',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_registration_date',
          fieldName: 'medicationUseDateTime',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LL') : ''),
        },
        {
          label: 'my_doctor_use_indicator',
          fieldName: 'useIndicator',
          translate: true,
          prepareData: (data) => {
            if (data === true) return 'common_yes';
            else if (data === false) return 'common_no';
            return null;
          },
        },
        {
          label: 'my_doctor_as_agreed',
          fieldName: 'asAgreedIndicator',
          translate: true,
          prepareData: (data) => {
            if (data === true) return 'common_yes';
            else if (data === false) return 'common_no';
            return null;
          },
        },
        {
          label: 'my_doctor_start_date',
          fieldName: 'periodOfUse',
          prepareData: (data) => (data?.start ? MedrecordMoment(data?.start).format('LL') : ''),
        },
        {
          label: 'my_doctor_end_date',
          fieldName: 'periodOfUse',
          prepareData: (data) => (data?.end ? MedrecordMoment(data?.end).format('LL') : ''),
        },
        {
          label: 'my_doctor_duration',
          fieldName: 'duration',
          prepareData: (data) => [data?.value, data?.unit].filter(Boolean).join(' '),
        },
        {
          label: 'my_doctor_care_provider',
          fieldName: 'provider',
          prepareData: (data, resources) => {
            const practitionerRole = resources.find((r) => {
              const [referenceType, referenceId] = (data?.reference ?? '').split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return [
              practitionerRole?.content?.practitionerReference?.display,
              practitionerRole?.content?.organizationReference?.display,
            ]
              .filter(Boolean)
              .join(', ');
          },
        },
        {
          label: 'my_doctor_dosage',
          fieldName: 'dosage',
          prepareData: (data) => data?.text,
        },

        {
          label: 'my_doctor_administration_route',
          fieldName: 'dosage',
          prepareData: (data) => data?.[0]?.route?.coding?.[0]?.display,
        },
      ],
    },
  ],
});
