import { Component } from '@angular/core';
import { initPhone, navigateToLoginPage } from '@medrecord/managers-auth';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService } from '@medrecord/services-form-validation';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { MapFieldType } from '@medrecord/ui';
import { StorageKeys } from '@medrecord/core';
import { StorageService } from '@medrecord/tools-storage';

@Component({
  selector: 'medrecord-auth-phone-init',
  templateUrl: './phone-init.component.html',
  styleUrls: ['./phone-init.component.scss'],
})
export class PhoneInitComponent {
  form: FormGroup;

  readonly mapFieldType = MapFieldType;
  readonly isOAuth = this.storage.getItem<boolean>(StorageKeys.isOAuth, false);

  constructor(
    fbBuilder: FormBuilder,
    validationService: ValidationService,
    translateService: TranslateService,

    private storage: StorageService<StorageKeys>,
    private store: Store
  ) {
    this.form = fbBuilder.group({
      phone: [null, [validationService.required(translateService.instant('auth_validation_error_required'))]],
    });
  }

  backToLogin(): void {
    this.store.dispatch(navigateToLoginPage());
  }

  sendCode(): void {
    if (!this.form.valid) {
      return;
    }

    this.store.dispatch(
      initPhone({
        phone: this.form.value.phone.e164Number,
      })
    );
  }
}
