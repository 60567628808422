import { Component, Input } from '@angular/core';

@Component({
  selector: 'medrecord-ui-inline-block-column-tag',
  templateUrl: './inline-block-column-tag.component.html',
  styleUrls: ['./inline-block-column-tag.component.scss']
})
export class InlineBlockColumnTagComponent {
  @Input() value: string;
  @Input() color: string;
}