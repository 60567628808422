import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { HealthplansManagerService } from '../services/healthplans-manager.service';
import {
  addGoalAction,
  addGoalCommentAction,
  addGoalCommentSuccessAction,
  addGoalSuccessAction,
  addHealthplanAction,
  addHealthplanSuccessAction,
  addTaskAction,
  addTaskCommentAction,
  addTaskCommentSuccessAction,
  addTaskSuccessAction,
  addUserToHealthplanAction,
  deleteGoalAction,
  deleteGoalCommentAction,
  deleteGoalCommentSuccessAction,
  deleteGoalSuccessAction,
  deleteHealthplanAction,
  deleteHealthplanSuccessAction,
  deleteTaskCommentAction,
  deleteTaskCommentSuccessAction,
  editGoalAction,
  editGoalSuccessAction,
  editHealthplanAction,
  editHealthplanSuccessAction,
  editTaskAction,
  editTaskSuccessAction,
  loadGoalAction,
  loadGoalsAction,
  loadGoalsFailureAction,
  loadGoalsSuccessAction,
  loadGoalSuccessAction,
  loadHealthplanAction,
  loadHealthplansAction,
  loadHealthplansFailureAction,
  loadHealthplansSuccessAction,
  loadHealthplanSuccessAction,
  loadTaskAction,
  loadTasksAction,
  loadTasksFailureAction,
  loadTasksSuccessAction,
  loadTaskSuccessAction,
  loadUserNetworkForHealthplanAction,
  loadUserNetworkForHealthplanSuccessAction,
  removeUserFromHealthplanAction,
  updateGoalCommentAction,
  updateGoalCommentSuccessAction,
  updateTaskCommentAction,
  updateTaskCommentSuccessAction,
} from './healthplans-manager.actions';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { getMainUserTabData, selectCurrentID } from '@medrecord/managers-users';
import { NetworkManagerService } from '@managers/network';
import { Store } from '@ngrx/store';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, forkJoin } from 'rxjs';

@Injectable()
export class HealthplansManagerEffects {
  @Effect()
  loadHealthplans$ = this.actions$.pipe(
    ofType(loadHealthplansAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ payload }, userId]) => {
      return forkJoin({
        healthplans: this.service.loadHealthplans(userId, payload),
        count: this.service.loadHealthplansCount(userId, payload.status),
      }).pipe(
        switchMap(({ healthplans, count: { count } }) => {
          const apiCalls = healthplans.map((h) => this.service.loadHealthPlanParticipants(userId, h.id));
          if (apiCalls.length === 0) return [loadHealthplansSuccessAction({ healthplans, count })];

          return combineLatest(apiCalls).pipe(
            switchMap((participants) => {
              healthplans = healthplans.map((h, i) => ({
                ...h,
                participants: participants?.[i]?.filter((p) => p.userId !== userId) || [],
              }));

              return [loadHealthplansSuccessAction({ healthplans, count })];
            }),
            catchError((error) => [
              addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_healthplans_error'), error)),
              loadHealthplansFailureAction({ error }),
            ])
          );
        }),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_healthplans_error'), error)),
          loadHealthplansFailureAction({ error }),
        ])
      );
    })
  );

  @Effect()
  loadHealthplan$ = this.actions$.pipe(
    ofType(loadHealthplanAction),
    withLatestFrom(this.store.select(selectCurrentID), this.store.select(getMainUserTabData)),
    switchMap(([action, userId, mainUserTabData]) => {
      return this.service.loadHealthplan(action.id, userId).pipe(
        switchMap((healthplan) =>
          this.service.loadHealthPlanParticipants(userId, healthplan.id).pipe(
            switchMap((participants) => {
              healthplan = {
                ...healthplan,
                participants: [
                  {
                    id: mainUserTabData?.user?.patientId || mainUserTabData?.user?.practitionerId,
                    resourceType: mainUserTabData?.user?.patientId ? 'Patient' : 'Practitioner',
                    userId,
                    name: mainUserTabData?.user?.name,
                    photoBase64Data: mainUserTabData?.user?.photoBase64Data,
                    photoContentType: mainUserTabData?.user?.photoContentType,
                  },
                  ...(participants?.filter((p) => p.userId !== userId) || []),
                ],
              };

              return [loadHealthplanSuccessAction({ healthplan })];
            }),
            catchError((error) => [
              addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_healthplans_error'), error)),
              loadHealthplansFailureAction({ error }),
            ])
          )
        ),
        catchError(({ error }) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_healthplans_error'), error)),
          loadHealthplansFailureAction({ error }),
        ])
      );
    })
  );

  @Effect()
  addHealthplan$ = this.actions$.pipe(
    ofType(addHealthplanAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([payload, userID]) =>
      this.service.createHealthplan(userID, payload.healthplan).pipe(
        switchMap((healthplan) => {
          const apiCalls = payload.healthplan.participants?.map((p) =>
            this.service.addUserToHealthplan(
              userID,
              healthplan.id,
              p.resourceType === 'RelatedPerson' ? { fhirId: 'RelatedPerson/' + p.id } : { userId: p.userId }
            )
          );
          if (apiCalls?.length)
            return combineLatest(apiCalls).pipe(
              switchMap(() => {
                this.store.dispatch(
                  addSuccessToast({
                    title: 'careplan_create_success_title',
                    content: 'careplan_create_success_content',
                  })
                );
                return [addHealthplanSuccessAction({ healthplan: { ...payload.healthplan, ...healthplan } })];
              }),
              catchError((error) => [
                addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_healthplans_error'), error)),
                loadHealthplansFailureAction({ error }),
              ])
            );
          this.store.dispatch(
            addSuccessToast({
              title: 'careplan_create_success_title',
              content: 'careplan_create_success_content',
            })
          );
          return [addHealthplanSuccessAction({ healthplan: { ...payload.healthplan, ...healthplan } })];
        })
      )
    ),
    catchError((error: any) => [
      addErrorToast(getErrorToastBodyUtil('add_healthplan_error', error)),
      loadHealthplansFailureAction({ error }),
    ])
  );

  @Effect()
  editHealthplan$ = this.actions$.pipe(
    ofType(editHealthplanAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([payload, userID]) =>
      this.service.updateHealthplan(userID, payload.healthplan.id, payload.healthplan).pipe(
        map((healthplan) => {
          this.store.dispatch(
            addSuccessToast({ title: 'careplan_edit_success_title', content: 'careplan_edit_success_content' })
          );
          return editHealthplanSuccessAction({ healthplan: { ...healthplan, ...payload.healthplan } });
        })
      )
    ),
    catchError((error: any) => [
      addErrorToast(getErrorToastBodyUtil('edit_healthplan_error', error)),
      loadHealthplansFailureAction({ error }),
    ])
  );

  @Effect()
  deleteHealthplan$ = this.actions$.pipe(
    ofType(deleteHealthplanAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ id }, userId]) => {
      return this.service.deleteHealthplan(id, userId).pipe(
        map(() => deleteHealthplanSuccessAction({ id })),
        catchError(({ error }) => [
          addErrorToast(
            getErrorToastBodyUtil(
              this.translateService.instant(error?.error?.message ? error.error.message : 'delete_healthplan_error'),
              error
            )
          ),
          loadHealthplansFailureAction({ error }),
        ])
      );
    })
  );

  @Effect()
  loadGoals$ = this.actions$.pipe(
    ofType(loadGoalsAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ id, status }, userId]) => {
      return this.service.loadGoals(id, userId, status).pipe(
        switchMap((goals) => {
          const actions = goals.map((goal) => loadTasksAction({ goalId: goal.id, id, payload: { start: 0 } }));
          return [loadGoalsSuccessAction({ goals }), ...actions];
        }),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_goals_error'), error)),
        ])
      );
    })
  );

  @Effect()
  loadGoal$ = this.actions$.pipe(
    ofType(loadGoalAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([action, userId]) => {
      return this.service.loadGoal(action.id, userId, action.healthplanId).pipe(
        switchMap((goal) => [loadGoalSuccessAction({ goal })]),
        catchError(({ error }) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_goals_error'), error)),
          loadGoalsFailureAction({ error }),
        ])
      );
    })
  );

  @Effect()
  addGoal$ = this.actions$.pipe(
    ofType(addGoalAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([payload, userID]) =>
      this.service.createGoal(userID, payload.healthplanId, payload.goal).pipe(
        map((goal) => {
          this.store.dispatch(
            addSuccessToast({ title: 'careplan_goal_add_success_title', content: 'careplan_goal_add_success_content' })
          );
          return addGoalSuccessAction({ goal: { ...goal, ...payload.goal }, selectedStatus: payload.selectedStatus });
        })
      )
    ),
    catchError((error: any) => [
      addErrorToast(getErrorToastBodyUtil('add_goal_error', error)),
      loadGoalsFailureAction({ error }),
    ])
  );

  @Effect()
  editGoal$ = this.actions$.pipe(
    ofType(editGoalAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([payload, userID]) =>
      this.service.updateGoal(userID, payload.healthplanId, payload.goal.id, payload.goal).pipe(
        map(() => {
          this.store.dispatch(
            addSuccessToast({
              title: 'careplan_goal_edit_success_title',
              content: 'careplan_goal_edit_success_content',
            })
          );
          return editGoalSuccessAction({ goal: payload.goal });
        })
      )
    ),
    catchError((error: any) => [
      addErrorToast(getErrorToastBodyUtil('edit_goal_error', error)),
      loadGoalsFailureAction({ error }),
    ])
  );

  @Effect()
  deleteGoal$ = this.actions$.pipe(
    ofType(deleteGoalAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ id, healthplanId }, userId]) => {
      return this.service.deleteGoal(id, healthplanId, userId).pipe(
        map(() => deleteGoalSuccessAction({ id })),
        catchError(({ error }) => [
          addErrorToast(
            getErrorToastBodyUtil(
              this.translateService.instant(error?.error?.message ? error.error.message : 'delete_goal_error'),
              error
            )
          ),
          loadGoalsFailureAction({ error }),
        ])
      );
    })
  );

  @Effect()
  loadTasks$ = this.actions$.pipe(
    ofType(loadTasksAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ id, goalId, payload }, userId]) => {
      return this.service.loadTasks(goalId, id, userId, payload).pipe(
        switchMap((tasks) => {
          return [loadTasksSuccessAction({ tasks, goalId })];
        }),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_tasks_error'), error)),
        ])
      );
    })
  );

  @Effect()
  addTask$ = this.actions$.pipe(
    ofType(addTaskAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([payload, userID]) =>
      this.service.createTask(userID, payload.healthplanId, payload.goalId, payload.task).pipe(
        map((task) => {
          this.store.dispatch(
            addSuccessToast({ title: 'careplan_task_add_success_title', content: 'careplan_task_add_success_content' })
          );
          return addTaskSuccessAction({ task: { ...payload.task, ...task }, goalId: payload.goalId });
        })
      )
    ),
    catchError((error: any) => [
      addErrorToast(getErrorToastBodyUtil('add_task_error', error)),
      loadTasksFailureAction({ error }),
    ])
  );

  @Effect()
  editTask$ = this.actions$.pipe(
    ofType(editTaskAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([payload, userID]) =>
      this.service.updateTask(userID, payload.healthplanId, payload.goalId, payload.task.id, payload.task).pipe(
        map(() => {
          this.store.dispatch(
            addSuccessToast({
              title: 'careplan_task_edit_success_title',
              content: 'careplan_task_edit_success_content',
            })
          );
          return editTaskSuccessAction({ task: payload.task, goalId: payload.goalId });
        })
      )
    ),
    catchError((error: any) => [
      addErrorToast(getErrorToastBodyUtil('edit_task_error', error)),
      loadTasksFailureAction({ error }),
    ])
  );

  @Effect()
  loadUserNetworkForHealthplan$ = this.actions$.pipe(
    ofType(loadUserNetworkForHealthplanAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([, userId]) => {
      return forkJoin({
        userNetwork: this.networkService.getUserNetwork(userId),
        relatedPeople: this.networkService.getRelatedPeople(userId),
      }).pipe(
        switchMap(({ userNetwork, relatedPeople }) => {
          return [
            loadUserNetworkForHealthplanSuccessAction({
              userNetwork: userNetwork.filter((u) => u.resourceType !== 'RelatedPerson'),
              relatedPeople,
            }),
          ];
        })
      );
    }),
    catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('load_user_network_error', error))])
  );

  @Effect()
  addUserToHealthplan$ = this.actions$.pipe(
    ofType(addUserToHealthplanAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ healthplanId, fhirId, userId }, selectedUserId]) =>
      this.service.addUserToHealthplan(selectedUserId, healthplanId, fhirId ? { fhirId } : { userId }).pipe(
        switchMap(() => {
          this.store.dispatch(addSuccessToast({ title: 'add_user_to_healthplan_success', content: '' }));
          return [];
        })
      )
    ),
    catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('add_user_to_healthplan_error', error))])
  );

  @Effect()
  removeUserFromHealthPlan$ = this.actions$.pipe(
    ofType(removeUserFromHealthplanAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ healthplanId, fhirId, userId }, selectedUserId]) =>
      (fhirId
        ? this.service.deleteNonRegisteredUserFromHealthplan(selectedUserId, healthplanId, fhirId)
        : this.service.deleteNetworkUserFromHealthplan(selectedUserId, healthplanId, userId)
      ).pipe(
        switchMap(() => {
          this.store.dispatch(addSuccessToast({ title: 'remove_user_from_healthplan_success', content: '' }));
          return [];
        })
      )
    ),
    catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('remove_user_from_healthplan_error', error))])
  );

  @Effect()
  addGoalComment$ = this.actions$.pipe(
    ofType(addGoalCommentAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ text, healthplanId, goalId }, selectedUserId]) =>
      this.service.createGoalComment(selectedUserId, healthplanId, goalId, text).pipe(
        switchMap((comment) => {
          this.store.dispatch(
            addSuccessToast({
              title: 'add_comment_to_goal_success_title',
              content: 'add_comment_to_goal_success_content',
            })
          );
          return [addGoalCommentSuccessAction({ goalId, comment })];
        }),
        catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('add_comment_to_goal_failed', error))])
      )
    )
  );

  @Effect()
  updateGoalComment$ = this.actions$.pipe(
    ofType(updateGoalCommentAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ comment, healthplanId, goalId }, selectedUserId]) =>
      this.service.updateGoalComment(selectedUserId, healthplanId, goalId, comment.id, comment.text).pipe(
        switchMap(() => {
          this.store.dispatch(
            addSuccessToast({
              title: 'goal_update_comment_to_goal_success_title',
              content: 'goal_update_comment_to_goal_success_content',
            })
          );
          return [updateGoalCommentSuccessAction({ goalId, comment })];
        }),
        catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('goal_update_comment_to_goal_failed', error))])
      )
    )
  );

  @Effect()
  deleteGoalComment$ = this.actions$.pipe(
    ofType(deleteGoalCommentAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ commentId, healthplanId, goalId }, selectedUserId]) =>
      this.service.deleteGoalComment(selectedUserId, healthplanId, goalId, commentId).pipe(
        switchMap(() => {
          this.store.dispatch(
            addSuccessToast({
              title: 'goal_delete_comment_to_goal_success_title',
              content: 'goal_delete_comment_to_goal_success_content',
            })
          );
          return [deleteGoalCommentSuccessAction({ goalId, commentId })];
        }),
        catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('goal_delete_comment_to_goal_failed', error))])
      )
    )
  );

  @Effect()
  loadTask$ = this.actions$.pipe(
    ofType(loadTaskAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ healthplanId, goalId, taskId }, selectedUserId]) =>
      this.service.getTask(selectedUserId, healthplanId, goalId, taskId).pipe(
        switchMap((task) => [loadTaskSuccessAction({ goalId, task })]),
        catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('common_error', error))])
      )
    )
  );

  @Effect()
  addTaskComment$ = this.actions$.pipe(
    ofType(addTaskCommentAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ healthplanId, goalId, taskId, text }, selectedUserId]) =>
      this.service.createTaskComment(selectedUserId, healthplanId, goalId, taskId, text).pipe(
        switchMap((comment) => {
          this.store.dispatch(
            addSuccessToast({
              title: 'add_comment_to_goal_success_title',
              content: 'add_comment_to_goal_success_content',
            })
          );
          return [addTaskCommentSuccessAction({ goalId, comment, taskId })];
        }),
        catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('goal_add_comment_to_task_failed', error))])
      )
    )
  );

  @Effect()
  updateTaskComment$ = this.actions$.pipe(
    ofType(updateTaskCommentAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ comment, healthplanId, goalId, taskId }, selectedUserId]) =>
      this.service.updateTaskComment(selectedUserId, healthplanId, goalId, taskId, comment.id, comment.text).pipe(
        switchMap(() => {
          this.store.dispatch(
            addSuccessToast({
              title: 'goal_update_comment_to_goal_success_title',
              content: 'goal_update_comment_to_goal_success_content',
            })
          );
          return [updateTaskCommentSuccessAction({ comment })];
        }),
        catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('goal_update_comment_to_goal_failed', error))])
      )
    )
  );

  @Effect()
  deleteTaskComment$ = this.actions$.pipe(
    ofType(deleteTaskCommentAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ commentId, healthplanId, goalId, taskId }, selectedUserId]) =>
      this.service.deleteTaskComment(selectedUserId, healthplanId, goalId, taskId, commentId).pipe(
        switchMap(() => {
          this.store.dispatch(
            addSuccessToast({
              title: 'goal_delete_comment_to_goal_success_title',
              content: 'goal_delete_comment_to_goal_success_content',
            })
          );
          return [deleteTaskCommentSuccessAction({ commentId })];
        }),
        catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('goal_delete_comment_to_goal_failed', error))])
      )
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private service: HealthplansManagerService,
    private translateService: TranslateService,
    private networkService: NetworkManagerService
  ) {}
}
