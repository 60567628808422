import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectThresholds } from '../state-management/measurements-manager.selectors';
import { filter, map } from 'rxjs/operators';
import { loadAllowedObservationTypes, loadUserThresholds } from '../state-management/measurements-manager.actions';
import { loadUserMetadataAction } from '@managers/middleware';

@Injectable()
export class GetThresholdsGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.store.dispatch(
      loadAllowedObservationTypes({
        successActions: [
          loadUserMetadataAction({
            successActions: [loadUserThresholds({})],
          }),
        ],
      })
    );

    return this.store.select(selectThresholds).pipe(
      filter((thresholds) => !!thresholds?.length),
      map(() => true)
    );
  }
}
