import { Action, createReducer, on } from '@ngrx/store';
import { ScreenSize } from '../model/enums/screen.enum';
import { setScreenSize, setSidebarHidden } from './app-config-manager.actions';

export const appConfigManagerFeatureKey = 'app-config-manager';

export interface AppConfigManagerState {
  screenSize: ScreenSize;
  hideSidebar: boolean;
}

export const initialState = {
  screenSize: ScreenSize.desktop,
  hideSidebar: false,
};

export const appConfigManagerReducer = createReducer(
  initialState,
  on(setScreenSize, (state, { screenSize, hideSidebar }) => ({ ...state, screenSize, hideSidebar })),
  on(setSidebarHidden, (state, { hideSidebar }) => ({ ...state, hideSidebar }))
);

export const reducer = (state: AppConfigManagerState, action: Action): AppConfigManagerState => {
  return appConfigManagerReducer(state, action);
};
