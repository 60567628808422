import { CareplanCategories } from '@managers/careplans-manager';
import { ObservationType } from '@medrecord/core';

export const positiveScaledMeasurements = [
  ObservationType.activeMinutes,
  ObservationType.heartMinutes,
  ObservationType.sleeping,
  ObservationType.stepCounter,
  ObservationType.bodyFatPercentage,
];

export const readyObservationTypes: ObservationType[] = [
  ObservationType.activeMinutes,
  ObservationType.attention,
  ObservationType.balance,
  ObservationType.bloodGlucose,
  ObservationType.bloodPressure,
  ObservationType.bodyFatPercentage,
  ObservationType.bodyMassIndex,
  ObservationType.bodyTemperature,
  ObservationType.cognitiveMotor,
  ObservationType.episodicMemory,
  ObservationType.executiveFunction,
  ObservationType.gaitSpeed,
  ObservationType.hba1c,
  ObservationType.heartMinutes,
  ObservationType.longTermStress,
  ObservationType.oxygenSaturation,
  ObservationType.processingSpeed,
  ObservationType.pulseRate,
  ObservationType.respiratoryRate,
  ObservationType.shortTermStress,
  ObservationType.sixMinutesWalking,
  ObservationType.sleeping,
  ObservationType.timedUpAndGo,
  ObservationType.waistHeightRatio,
  ObservationType.workingMemory,
  ObservationType.heartRate,
  ObservationType.hemoglobin,
  ObservationType.cPeptide,
  ObservationType.stepCounter,
  ObservationType.basalMetabolicRate,
];

export const nonCreatableObservationTypes: ObservationType[] = [
  ObservationType.bodyMassIndex,
  ObservationType.ecg,
  ObservationType.waistHeightRatio,
];

export const nonEditableObservationTypes: ObservationType[] = [ObservationType.bodyMassIndex];

export const nonReadableObservationTypes: ObservationType[] = [
  ObservationType.bodyMassIndex,
  ObservationType.waistHeightRatio,
];

export const visualizedObservationTypes: ObservationType[] = [
  ObservationType.bodyHeight,
  ObservationType.bodyWeight,
  ObservationType.waistCircumference,
];

export const quantityObservations = {
  [ObservationType.sixMinutesWalking]: 'sixMinutesWalking',
  [ObservationType.timedUpAndGo]: 'timedUpAndGoScore',
  [ObservationType.cognitiveMotor]: 'cognitiveMotorScore',
  [ObservationType.gaitSpeed]: 'gaitSpeedScore',
  [ObservationType.balance]: 'balanceScore',
  [ObservationType.executiveFunction]: 'executiveFunctionScore',
  [ObservationType.processingSpeed]: 'processingSpeedScore',
  [ObservationType.attention]: 'attentionScore',
  [ObservationType.workingMemory]: 'workingMemoryScore',
  [ObservationType.episodicMemory]: 'episodicMemoryScore',
  [ObservationType.longTermStress]: 'longTermStressScore',
  [ObservationType.shortTermStress]: 'shortTermStressScore',
  [ObservationType.hba1c]: 'hba1cScore',
  [ObservationType.waterIntake]: 'waterIntake',
};

export const careplanVisualizedObservationTypes = {
  [CareplanCategories.FOOD_FRIEND]: [ObservationType.waterIntake, ObservationType.bodyWeight],
};
