import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { FormFactory, MapFieldType } from '@medrecord/ui';
import { mapEnum } from '@medrecord/tools-utils';
import { AddressType, AddressUse, AddressUserData } from '@medrecord/managers-users';
import { ProfileFormType } from '../typings/enums';
import { ValidationService } from '@medrecord/services-form-validation';

@Injectable()
export class ProfileAddressFormFactory extends FormFactory<AddressUserData> {
  
  constructor(
    private translateService: TranslateService,
    private fb: FormBuilder,
    private formValidatorsService: ValidationService
  ) {
    super();
  }
  fields = [
    {
      name: 'type',
      calculate: () => ({
        type: MapFieldType.Bricks,
        label: this.translateService.instant('common_form_field_type'),
        hint: this.translateService.instant('common_form_field_type'),
        fieldClass: 'w-100',
        options: {
          items: mapEnum<{ id: string, text: string }>(
            AddressType,
            (id) => ({
              id,
              text: this.translateService.instant('profile_' + id.toLowerCase())
            })
          )
        }
      })
    },
    {
      name: 'use',
      calculate: () => ({
        type: MapFieldType.Bricks,
        label: this.translateService.instant('common_form_field_use'),
        hint: this.translateService.instant('common_form_field_use'),
        fieldClass: 'w-100',
        options: {
          items: mapEnum<{ id: string, text: string }>(
            AddressUse,
            (id) => ({
              id,
              text: this.translateService.instant('profile_' + id.toLowerCase())
            })
          )
        }
      })
    },
    {
      name: 'country',
      calculate: () => ({
        type: MapFieldType.Input,
        label: this.translateService.instant('common_form_field_country'),
        hint: this.translateService.instant('common_form_field_country'),
        fieldClass: 'w-50 pr-1',
      })
    },
    {
      name: 'city',
      calculate: () => ({
        type: MapFieldType.Input,
        label: this.translateService.instant('common_form_field_city'),
        hint: this.translateService.instant('common_form_field_city'),
        cssClass: 'pl-1',
        fieldClass: 'w-50',
      })
    },
    {
      name: 'streetName',
      calculate: () => ({
        type: MapFieldType.Input,
        label: this.translateService.instant('common_form_field_street'),
        hint: this.translateService.instant('common_form_field_street'),
        fieldClass: "w-100",
      })
    },
    {
      name: 'houseNumber',
      calculate: () => ({
        type: MapFieldType.Input,
        label: this.translateService.instant('common_form_field_house_number'),
        hint: this.translateService.instant('common_form_field_house_number'),
        fieldClass: "w-50 pr-1",
      })
    },
    {
      name: 'buildingNumberSuffix',
      calculate: () => ({
        type: MapFieldType.Input,
        label: this.translateService.instant('healthcare_providers_resource_house_number_letter'),
        hint: this.translateService.instant('healthcare_providers_resource_house_number_letter'),
        secondaryLabel: this.translateService.instant('common_optional'),
        fieldClass: "w-50 pl-1",
      })
    },
    {
      name: 'additionalLocator',
      calculate: () => ({
        type: MapFieldType.Input,
        label: this.translateService.instant('common_form_field_additional_locator'),
        hint: this.translateService.instant('common_form_field_additional_locator'),
        secondaryLabel: this.translateService.instant('common_optional'),
        fieldClass: 'w-100',
      })
    },
    {
      name: 'district',
      calculate: () => ({
        type: MapFieldType.Input,
        label: this.translateService.instant('common_form_field_district'),
        hint: this.translateService.instant('common_form_field_district'),
        secondaryLabel: this.translateService.instant('common_optional'),
        fieldClass: 'w-50 pr-1',
      })
    },
    {
      name: 'postalCode',
      calculate: () => ({
        type: MapFieldType.Input,
        label: this.translateService.instant('common_form_field_postal_code'),
        hint: this.translateService.instant('common_form_field_postal_code'),
        fieldClass: 'w-50 pl-1',
      })
    }
  ];
  
  forms = {
    [ProfileFormType.User]: (model) => this.fb.group({
      type: [model?.type || AddressType.physical, [this.formValidatorsService.required(
        this.translateService.instant("common_validation_required")
      )]],
      use: [model?.use || AddressUse.home, [this.formValidatorsService.required(
        this.translateService.instant("common_validation_required")
      )]],
      country: [model?.country || null, [this.formValidatorsService.required(
        this.translateService.instant("common_validation_required")
      )]],
      city: [model?.city || null, [this.formValidatorsService.required(
        this.translateService.instant("common_validation_required")
      )]],
      streetName: [model?.streetName || null, [
        this.formValidatorsService.required(
          this.translateService.instant("common_validation_required")
        )
      ]],
      houseNumber: [model?.houseNumber || null, [
        this.formValidatorsService.required(
          this.translateService.instant("common_validation_required")
        )
      ]],
      buildingNumberSuffix: [model?.buildingNumberSuffix || null],
      additionalLocator: [model?.additionalLocator || null],
      district: [model?.district || null],
      postalCode: [model?.postalCode || null, [this.formValidatorsService.required(
        this.translateService.instant("common_validation_required")
      )]],
    }),
    
    [ProfileFormType.Organization]: (model) => this.fb.group({
      type: [model?.type || AddressType.physical, [this.formValidatorsService.required(
        this.translateService.instant("common_validation_required")
      )]],
      use: [model?.use || AddressUse.home, [this.formValidatorsService.required(
        this.translateService.instant("common_validation_required")
      )]],
      country: [model?.country || null, [this.formValidatorsService.required(
        this.translateService.instant("common_validation_required")
      )]],
      city: [model?.city || null, [this.formValidatorsService.required(
        this.translateService.instant("common_validation_required")
      )]],
      district: [model?.district || null],
      postalCode: [model?.postalCode || null, [this.formValidatorsService.required(
        this.translateService.instant("common_validation_required")
      )]],
    })
  };
  
}