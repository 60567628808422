import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConditionsManagerStateModule } from './state-management/conditions-manager-state.module';
import { ConditionsManagerService } from './services/conditions-manager.service';

@NgModule({
  imports: [CommonModule, ConditionsManagerStateModule],
  providers: [ConditionsManagerService]
})
export class ConditionsManagerModule {}
