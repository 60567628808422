import * as conditionConstants from './conditions.constants';
import { Provider } from '@angular/core';
import { CONDITIONS_MANAGER_CONSTANTS } from '../conditions-manager.tokens';

export const constants = {
  ...conditionConstants
};

export type ConditionManagerConstants = typeof constants;

export const provideConditionsManagerConstants = (): Provider => [
  {
    provide: CONDITIONS_MANAGER_CONSTANTS,
    useValue: constants
  }
];