import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Careprovider, FetchResourceResponse } from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-fetch-resource-response',
  templateUrl: './fetch-resource-response.component.html'
})
export class FetchResourceResponseComponent {
  @Input() careprovider: Careprovider;
  @Input() resources: FetchResourceResponse[];
  @Output() redirect: EventEmitter<void> = new EventEmitter<void>();
  @Output() goBack: EventEmitter<void> = new EventEmitter<void>();

  onGoBack(): void {
    this.goBack.emit();
  }

  getDisplayName(careprovider: Careprovider): string {
    return careprovider?.displayName || careprovider?.id || careprovider?.email || '';
  }

  onRedirect(): void {
    this.redirect.emit();
  }
}
