import { Injectable } from '@angular/core';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { catchError, switchMap } from 'rxjs/operators';
import { NewsManagerService } from '../services/news-manager.service';
import {
  loadCareplanCategoriesAction,
  loadCareplanCategoriesFailureAction,
  loadCareplanCategoriesSuccessAction,
  loadCareplanPostAction,
  loadCareplanPostFailureAction,
  loadCareplanPostsAction,
  loadCareplanPostsFailureAction,
  loadCareplanPostsSuccessAction,
  loadCareplanPostSuccessAction,
  loadCareplanResourcesPostsAction,
  loadCareplanResourcesPostsFailureAction,
  loadCareplanResourcesPostsSuccessAction,
  loadCategoriesAction,
  loadCategoriesFailureAction,
  loadCategoriesSuccessAction,
  loadFeaturedPostsAction,
  loadFeaturedPostsFailureAction,
  loadFeaturedPostsSuccessAction,
  loadPostAction,
  loadPostFailureAction,
  loadPostsAction,
  loadPostsFailureAction,
  loadPostsSuccessAction,
  loadPostSuccessAction,
} from './news-manager.actions';
import { FilterBuilder } from '../utils/filter-builder.util';

@Injectable()
export class NewsManagerEffects {
  loadPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPostsAction),
      switchMap(({ title, categoryName }) => {
        const filter = FilterBuilder.medsafeFilter()
          .addTag(categoryName)
          .add(title ? `title:~'${title}'` : null)
          .build();

        return this.newsManagerService.getPosts(filter).pipe(
          switchMap((posts) => [loadPostsSuccessAction({ posts })]),
          catchError((error: any) => [loadPostsFailureAction({ error })])
        );
      })
    )
  );

  loadCareplanPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCareplanPostsAction),
      switchMap(({ categoryNames, healthPlanCategory }) => {
        if (!healthPlanCategory) {
          return [];
        }
        const filter = FilterBuilder.defaultFilter().addTags(categoryNames).excludeTag('information').build();

        return this.newsManagerService.getCareplanPosts(filter, healthPlanCategory).pipe(
          switchMap((posts) => [loadCareplanPostsSuccessAction({ posts })]),
          catchError((error: any) => [loadCareplanPostsFailureAction({ error })])
        );
      })
    )
  );

  loadCareplanResourcesPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCareplanResourcesPostsAction),
      switchMap(({ healthPlanCategory }) => {
        if (!healthPlanCategory) {
          return [];
        }
        const filter = FilterBuilder.defaultFilter().addHashTag('information').build();

        return this.newsManagerService.getCareplanPosts(filter, healthPlanCategory, true).pipe(
          switchMap((posts) => [loadCareplanResourcesPostsSuccessAction({ posts })]),
          catchError((error: any) => [loadCareplanResourcesPostsFailureAction({ error })])
        );
      })
    )
  );

  loadFeaturedPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFeaturedPostsAction),
      switchMap(() => {
        const featuredFilter = FilterBuilder.defaultFilter().add('featured:true').build();

        return this.newsManagerService.getFeaturedPosts(featuredFilter).pipe(
          switchMap(({ posts }) => [loadFeaturedPostsSuccessAction({ featuredPosts: posts })]),
          catchError((error: any) => [loadFeaturedPostsFailureAction({ error })])
        );
      })
    )
  );

  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCategoriesAction),
      switchMap(() =>
        this.newsManagerService.getTags().pipe(
          switchMap((categories) => [loadCategoriesSuccessAction({ categories })]),
          catchError((error: any) => [loadCategoriesFailureAction({ error })])
        )
      )
    )
  );

  loadCareplanCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCareplanCategoriesAction),
      switchMap(({ healthPlanCategory }) => {
        if (!healthPlanCategory) {
          return [];
        }
        return this.newsManagerService.getCareplanTags(healthPlanCategory).pipe(
          switchMap((categories) => [loadCareplanCategoriesSuccessAction({ categories })]),
          catchError((error: any) => [loadCareplanCategoriesFailureAction({ error })])
        );
      })
    )
  );

  loadPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPostAction),
      switchMap(({ id }) =>
        this.newsManagerService.getPost(id).pipe(
          switchMap((post) => [loadPostSuccessAction({ post })]),
          catchError((error: any) => [loadPostFailureAction({ error })])
        )
      )
    )
  );

  loadCareplanPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCareplanPostAction),
      switchMap(({ id, healthPlanCategory }) => {
        if (!healthPlanCategory) {
          return [];
        }
        return this.newsManagerService.getCareplanPost(id, healthPlanCategory).pipe(
          switchMap((post) => [loadCareplanPostSuccessAction({ post })]),
          catchError((error: any) => [loadCareplanPostFailureAction({ error })])
        );
      })
    )
  );

  toastError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        loadPostsFailureAction,
        loadCareplanPostsFailureAction,
        loadCareplanResourcesPostsFailureAction,
        loadFeaturedPostsFailureAction,
        loadCategoriesFailureAction,
        loadCareplanCategoriesFailureAction,
        loadPostFailureAction,
        loadCareplanPostFailureAction
      ),
      switchMap(({ error }) => [
        addErrorToast(
          getErrorToastBodyUtil(this.translateService.instant('news_load_posts_and_categories_error'), error)
        ),
      ])
    )
  );

  constructor(
    private actions$: Actions,
    private newsManagerService: NewsManagerService,
    private translateService: TranslateService
  ) {}
}
