<div *ngIf="profileData$ | async as profileData">
  <button
    ejs-dropdownbutton
    [items]="items"
    class="current-user-dropdown e-caret-hide"
    cssClass="user-menu-dropdown-class"
    (select)="selectItem($event)"
  >
    <medsafe-current-user [userData]="profileData.user"></medsafe-current-user>
  </button>
</div>
