import { Pipe, PipeTransform } from '@angular/core';
import { AddressUserData } from '@medrecord/managers-users';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getAddressLabel',
})
export class GetAddressLabelPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(address: AddressUserData): unknown {
    return this.getTranslation(address.use) + ' / ' + this.getTranslation(address.type);
  }

  getTranslation(value: string): string {
    return value ? this.translateService.instant('profile_' + value.toLowerCase()) : value;
  }
}
