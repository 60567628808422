<div class="h-100 w-100 flex-column slider-wrapper py-3">
  <div class="line-graph" *ngIf="threshold.value">
    <div class="value" [style.marginLeft.%]="getOffset()">
      {{ isNumber(threshold.value) ? (threshold.value | number: '1.1-2' | dotToComma) : threshold.value }}
    </div>
  </div>

  <div class="d-flex justify-content-between text-sm text-weight-500 py-2" *ngIf="threshold.value">
    <div>{{ 'measurements_bad' | translate }}</div>
    <div>{{ 'measurements_concerning' | translate }}</div>
    <div>{{ 'measurements_good' | translate }}</div>
  </div>

  <div *ngIf="!threshold.value" class="line-graph mb-3">
    <div class="no-data text-sm">{{ 'no_data' | translate }}</div>
  </div>
</div>
