import { QuestionnaireScore } from '../../models/interfaces/questionnaire-score.interface';
import { createAction, props } from '@ngrx/store';
import { featurePrefix } from './feature-prefix';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { QuestionnairesScoreListItem } from '../../models/interfaces';

export const loadQuestionnaireScores = createAction(
  `${ featurePrefix } Load Questionnaire Scores`,
  props<{
    pageSettings: PageSettingsModel;
  }>()
);

export const loadQuestionnaireScoresSuccess = createAction(
  `${ featurePrefix } Questionnaire Scores Loaded Successfully`,
  props<{
    questionnairesScores: QuestionnairesScoreListItem[];
    questionnairesScoresCount: number;
  }>()
);

export const loadQuestionnaireScoresFailure = createAction(
  `${ featurePrefix } Questionnaire Scores Load Failure`,
  props<{
    error: any;
  }>()
);

export const changeQuestionnaireScoresPage = createAction(
  `${ featurePrefix } Change Questionnaire Scores Page`,
  props<{
    pageSettings: PageSettingsModel;
  }>()
);

export const loadQuestionnaireScore = createAction(
  `${ featurePrefix } Load Questionnaire Score`,
  props<{
    taskId: string;
  }>()
);

export const loadQuestionnaireScoreSuccess = createAction(
  `${ featurePrefix } Load Questionnaire Score Success`,
  props<{
    questionnaireScore: QuestionnaireScore[];
  }>()
);

export const loadQuestionnaireScoreFailure = createAction(
  `${ featurePrefix } Load Questionnaire Score Failure`,
  props<{
    error: any;
  }>()
);
