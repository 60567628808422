import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-photo',
  templateUrl: './photo.component.svg',
})
export class PhotoComponent extends MedrecordIcon {
  viewHeight = 24;
  viewWidth = 24;
}
