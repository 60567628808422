import { createAction, props } from '@ngrx/store';
import { PaymentPlan } from '../models/interfaces/payment-plan.interface';

export const loadPaymentPlansAction = createAction('[Payment Manager Actions] Load Payment Plans');

export const loadPaymentPlansSuccessAction = createAction(
  '[Payment Manager Actions] Load Payment Plans Success',
  props<{ paymentPlans: PaymentPlan[] }>()
);

export const loadPaymentPlansFailureAction = createAction(
  '[Payment Manager Actions] Load Payment Plans Failure',
  props<{ error: any }>()
);

export const createPaymentWithStripeAction = createAction(
  '[Payment Manager Actions] Create Payment with Stripe',
  props<{ paymentPlanId: number }>()
);
