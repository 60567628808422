import { Component, Input } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';
import { Colors } from '@medrecord/services-colors';

@Component({
  selector: 'medrecord-svg-upload-avatar',
  templateUrl: './upload-avatar.component.svg',
  styleUrls: ['./upload-avatar.component.scss'],
})
export class UploadAvatarComponent extends MedrecordIcon {
  viewHeight = 160;
  viewWidth = 160;
  
  userColor: string;
  
  @Input()
  set userType(color: Colors) {
    this.userColor = this.getColorByType(color);
  }
}
