import { Inject, Injectable } from '@angular/core';
import { QuestionnairesScoresService } from '../services/questionnaires-scores.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import * as questionnaireActions from './questionnaires.actions';
import { catchError, concatMap, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { QuestionnaireTemplatesService } from '../services/questionnaire-templates.service';
import { combineLatest, Observable, of } from 'rxjs';
import { QuestionnairesInUseService } from '../services/questionnaires-in-use.service';
import { QuestionnairesService } from '../services/questionnaires.service';
import { Router } from '@angular/router';
import { Language, StorageKeys } from '@medrecord/core';
import { StorageService } from '@medrecord/tools-storage';
import { selectCurrentID } from '@medrecord/managers-users';
import { MEDSAFE_ROUTE_NAMES, MedsafeRouteNames } from '@medrecord/routes-medsafe';
import { selectQuestionnaire, selectScope } from './questionnaires.selectors';
import {
  autoSaveQuestionnaireTask,
  autoSaveQuestionnaireTaskSuccess,
  saveQuestionnaireTask,
  saveQuestionnaireTaskFailure,
  saveQuestionnaireTaskSuccess,
} from './questionnaires.actions';
import { selectUserId } from '@medrecord/managers-auth';
import { prepareQuestionnaireForSave } from '../utils';
import { GeneralRouteNames, GENERAL_ROUTE_NAMES } from '@medrecord/routes-general';

@Injectable()
export class QuestionnaireEffects {
  @Effect()
  loadQuestionnaireTemplates$ = this.actions$.pipe(
    ofType(questionnaireActions.loadQuestionnaireTemplates),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ pageSettings }, id]) =>
      combineLatest([
        this.questionnaireTemplatesService.loadTemplates(
          id,
          pageSettings,
          this.storage.getItem(StorageKeys.Language, Language.EN)
        ),
        this.questionnaireTemplatesService.countTemplates(id, this.storage.getItem(StorageKeys.Language, Language.EN)),
      ]).pipe(
        mergeMap(([questionnaireTemplates, totalCount]) => [
          questionnaireActions.loadQuestionnaireTemplatesSuccess({
            questionnaireTemplates,
            questionnaireTemplatesCount: totalCount,
          }),
        ]),
        catchError((error) =>
          of(
            questionnaireActions.loadQuestionnaireTemplatesFailure({
              error,
            })
          )
        )
      )
    )
  );

  @Effect()
  loadQuestionnairesInUse$ = this.actions$.pipe(
    ofType(questionnaireActions.loadQuestionnairesInUse),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ pageSettings }, id]) =>
      combineLatest([
        this.questionnairesInUseService.loadQuestionnaireTasks(id, pageSettings),
        this.questionnairesInUseService.countQuestionnaireTasks(id),
      ]).pipe(
        mergeMap(([questionnairesInUse, totalCount]) => [
          questionnaireActions.loadQuestionnairesInUseSuccess({
            questionnairesInUse,
            questionnairesInUseCount: totalCount,
          }),
        ]),
        catchError((error) =>
          of(
            questionnaireActions.loadQuestionnairesInUseFailure({
              error,
            })
          )
        )
      )
    )
  );

  @Effect()
  loadQuestionnaireScores$ = this.actions$.pipe(
    ofType(questionnaireActions.loadQuestionnaireScores),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ pageSettings }, id]) =>
      combineLatest([
        this.questionnairesScoresService.loadQuestionnaireTasks(id, pageSettings),
        this.questionnairesScoresService.countQuestionnaireTasks(id),
      ]).pipe(
        mergeMap(([questionnairesScores, totalCount]) => [
          questionnaireActions.loadQuestionnaireScoresSuccess({
            questionnairesScores,
            questionnairesScoresCount: totalCount,
          }),
        ]),
        catchError((error) =>
          of(
            questionnaireActions.loadQuestionnaireScoresFailure({
              error,
            })
          )
        )
      )
    )
  );

  @Effect()
  loadQuestionnaireScore$ = this.actions$.pipe(
    ofType(questionnaireActions.loadQuestionnaireScore),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ taskId }, id]) =>
      this.questionnairesScoresService.loadQuestionnaireScore(id, taskId).pipe(
        map((questionnaireScore) => {
          return questionnaireActions.loadQuestionnaireScoreSuccess({
            questionnaireScore,
          });
        }),
        catchError((error) =>
          of(
            questionnaireActions.loadQuestionnaireScoreFailure({
              error,
            })
          )
        )
      )
    )
  );

  @Effect()
  loadQuestionnaire$ = this.actions$.pipe(
    ofType(questionnaireActions.loadQuestionnaire),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ questionnaireId, taskId, loadAnswers }, id]) => {
      const apiCalls = [this.questionnairesService.loadQuestionnaire(id, questionnaireId)];

      if (loadAnswers) {
        apiCalls.push(this.questionnairesService.getQuestionnaireTaskResponse(id, taskId));
      }

      return combineLatest(apiCalls).pipe(
        map(([questionnaireData, responseData]) => {
          return questionnaireActions.loadQuestionnaireSuccess({
            questionnaireId,
            questionnaireData,
            answers: responseData,
          });
        }),
        catchError((error) =>
          of(
            questionnaireActions.loadQuestionnaireFailure({
              questionnaireId,
              error,
            })
          )
        )
      );
    })
  );

  @Effect({ dispatch: false })
  scheduleQuestionnaire$ = this.actions$.pipe(
    ofType(questionnaireActions.scheduleQuestionnaireAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ value }, id]) => this.questionnaireTemplatesService.schedule(id, value)),
    map(() => this.store.dispatch(questionnaireActions.scheduleQuestionnaireSuccessAction()))
  );

  @Effect()
  afterSavedQuestionnaireTask$ = this.actions$.pipe(
    ofType(questionnaireActions.saveQuestionnaireTaskSuccess),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([action, userId]) => {
      this.router.navigate(
        [
          this.generalRouteNames.Patient.Entry,
          userId,
          this.medsafeRouteNames.Questionnaires.Entry,
          this.medsafeRouteNames.Questionnaires.Scores.Entry,
          action.taskId,
        ],
        { skipLocationChange: true }
      );
      return [];
    })
  );

  @Effect({ dispatch: false })
  autoSavedQuestionnaireTask$ = this.actions$.pipe(
    ofType(questionnaireActions.autoSaveQuestionnaireTaskSuccess),
    tap((action) => {
      console.log('Questionnaire auto-saved! (taskId:' + action.taskId + ')');
    })
  );

  @Effect()
  saveQuestionAnswer$ = this.actions$.pipe(
    ofType(questionnaireActions.saveQuestionAnswer),
    mergeMap((data) =>
      of(data).pipe(
        withLatestFrom(this.store.pipe(select(selectScope, data.questionnaireId))),
        map(([, scope]) =>
          questionnaireActions.autoSaveQuestionnaire({
            questionnaireId: data.questionnaireId,
            scope: scope,
            routeSnapshot: data.routeSnapshot,
          })
        )
      )
    )
  );

  @Effect()
  handleAutoSaveQuestionnaire$ = this.actions$.pipe(
    ofType(questionnaireActions.autoSaveQuestionnaire),
    filter(({ scope }) => scope === 'Medrecord'),
    map(({ questionnaireId, routeSnapshot }) =>
      autoSaveQuestionnaireTask({
        questionnaireId,
        routeSnapshot,
      })
    )
  );

  @Effect()
  autoSaveQuestionnaire$ = this.actions$.pipe(
    ofType(questionnaireActions.autoSaveQuestionnaireTask),
    withLatestFrom(this.store.select(selectUserId)),
    concatMap(([action, patientId]) =>
      of({}).pipe(
        withLatestFrom(this.store.pipe(select(selectQuestionnaire, action.questionnaireId))),
        map(([, questionnaire]) => [action, patientId, questionnaire])
      )
    ),
    concatMap(([action, patientId, questionnaire]) => {
      const taskId = (action as any).routeSnapshot.params?.taskId;
      const questionnaireResponse = prepareQuestionnaireForSave(questionnaire as any);
      return this.autoSaveQuestionnaireResponse(patientId, taskId, questionnaireResponse, questionnaire);
    })
  );

  @Effect()
  handleSaveQuestionnaire$ = this.actions$.pipe(
    ofType(questionnaireActions.saveQuestionnaire),
    filter(({ scope }) => scope === 'Medrecord'),
    map(({ questionnaireId, routeSnapshot }) =>
      saveQuestionnaireTask({
        questionnaireId,
        routeSnapshot,
      })
    )
  );

  @Effect()
  saveQuestionnaire$ = this.actions$.pipe(
    ofType(questionnaireActions.saveQuestionnaireTask),
    withLatestFrom(this.store.select(selectUserId)),
    concatMap(([action, patientId]) =>
      of({}).pipe(
        withLatestFrom(this.store.pipe(select(selectQuestionnaire, action.questionnaireId))),
        map(([, questionnaire]) => [action, patientId, questionnaire])
      )
    ),
    concatMap(([action, patientId, questionnaire]) => {
      const taskId = (action as any).routeSnapshot.params?.taskId;
      const questionnaireResponse = prepareQuestionnaireForSave(questionnaire as any);
      return this.saveQuestionnaireResponse(patientId, taskId, questionnaireResponse, questionnaire);
    })
  );

  private saveQuestionnaireResponse(
    patientId,
    taskId,
    questionnaireResponse: { patientName: string; items: any },
    questionnaire
  ): Observable<any> {
    return this.questionnairesService.saveQuestionnaireResponse(patientId, taskId, questionnaireResponse).pipe(
      mergeMap(() => [
        saveQuestionnaireTaskSuccess({
          taskId,
          questionnaireId: questionnaire.id,
        }),
      ]),
      catchError((error) =>
        of(
          saveQuestionnaireTaskFailure({
            taskId,
            questionnaireId: questionnaire.id,
            error,
          })
        )
      )
    );
  }

  private autoSaveQuestionnaireResponse(
    patientId,
    taskId,
    questionnaireResponse: { patientName: string; items: any },
    questionnaire
  ): Observable<any> {
    return this.questionnairesService.autoSaveQuestionnaireResponse(patientId, taskId, questionnaireResponse).pipe(
      map(() =>
        autoSaveQuestionnaireTaskSuccess({
          taskId,
          questionnaireId: questionnaire.id,
        })
      ),
      catchError((error) =>
        of(
          saveQuestionnaireTaskFailure({
            taskId,
            questionnaireId: questionnaire.id,
            error,
          })
        )
      )
    );
  }

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private questionnaireTemplatesService: QuestionnaireTemplatesService,
    private questionnairesInUseService: QuestionnairesInUseService,
    private questionnairesScoresService: QuestionnairesScoresService,
    private questionnairesService: QuestionnairesService,
    private storage: StorageService<StorageKeys>,

    @Inject(MEDSAFE_ROUTE_NAMES) private medsafeRouteNames: MedsafeRouteNames,
    @Inject(GENERAL_ROUTE_NAMES) private generalRouteNames: GeneralRouteNames
  ) {}
}
