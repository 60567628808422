import { NgModule } from '@angular/core';
import { UsersManagerService } from './services/users-manager.service';
import { UsersManagerStateModule } from './state-management/users-manager-state.module';
import { GetUserMetadataGuard } from './guards/get-user-metadata.guard';
import { GetUserGuard } from './guards/get-user.guard';
import { provideSelectedUser } from './providers/provide-selected-user';
import { CurrentPatientGuard } from './guards/current-patient.guard';
import { CurrentPatientPermissionGuard } from './guards/current-patient-permission.guard';

@NgModule({
  imports: [
    UsersManagerStateModule
  ],
  providers: [
    GetUserMetadataGuard,
    GetUserGuard,
    UsersManagerService,
    CurrentPatientGuard,
    CurrentPatientPermissionGuard,
    provideSelectedUser(),
  ]
})
export class UsersManagerModule {}
