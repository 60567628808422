import { Component, Input } from '@angular/core';

@Component({
  selector: 'doctor-gui-expandable-container',
  templateUrl: './expandable-container.component.html',
  styleUrls: ['./expandable-container.component.scss']
})
export class ExpandableContainerComponent {
  @Input() title: string;
  @Input() active = false;
  @Input() hideBottomBorder = false;
  @Input() hideTitle = false;

  expanded = true;
}
