import { Injectable } from '@angular/core';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppointmentService } from '../services/appointment.service';
import { failureAction, loadCarePlanAppointmentsAction, loadCarePlanAppointmentsSuccessAction, loadQualificationsAction, loadQualificationsSuccessAction, requestAppointmentAction } from './calendar-manager.actions';
import { QualificationService } from '../services/qualification.service';
import { getCarePlanId } from './calendar-manager.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class CalendarManagerEffects {

  constructor(
    private actions$: Actions,
    private appointmentService: AppointmentService,
    private translateService: TranslateService,
    private qualificationService: QualificationService,
    private store: Store
  ) { }

  loadCarePlanAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCarePlanAppointmentsAction),
      withLatestFrom(this.store.select(getCarePlanId)),
      switchMap(([payload, carePlanId]) => {
        return this.appointmentService.getAllCarePlanAppointments(payload.carePlanId || carePlanId).pipe(
          map((appointments) => {
            return loadCarePlanAppointmentsSuccessAction({ appointments });
          }),
          catchError(({ error }) => [
            addErrorToast(
              getErrorToastBodyUtil(
                this.translateService.instant('errors.load_careplan_appointments_failed'),
                error
              )
            ),
            failureAction({ error })
          ])
        );
      })
    )
  );

  loadQualifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadQualificationsAction),
      switchMap(() =>
        this.qualificationService.getQualifications().pipe(
          map((qualifications) => loadQualificationsSuccessAction({ qualifications })),
          catchError(({ error }) => [
            failureAction({ error })
          ])
        )
      )
    )
  );

  requestAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestAppointmentAction),
      switchMap((payload) =>
        this.appointmentService.requestAppointment(payload.requestData).pipe(
          mergeMap(() => [
            addSuccessToast({
              title: 'health_talk_appointment_requested'
            }),
            loadCarePlanAppointmentsAction({})
          ]),
          catchError(({ error }) => [
            addErrorToast(
              getErrorToastBodyUtil(
                this.translateService.instant('errors.health_talk_appointment_request_failed'),
                error
              )
            ),
            failureAction({ error })
          ])
        )
      )
    )
  );
}