<div class="d-flex flex-column align-items-center">
  <p class="w-100 text-center">{{ ('tfa_text_' + step) | translate }}</p>

  <medrecord-auth-qr-code-container
    *ngIf="step === 3"
  ></medrecord-auth-qr-code-container>

  <medrecord-auth-twofa-enter-code-container
    *ngIf="step === 4"
  ></medrecord-auth-twofa-enter-code-container>

  <ul *ngIf="step === 5" class="bulletless-list">
    <li *ngFor="let code of codes">{{ code }}</li>
    <button
      class="btn btn-primary-button mt-4 mb-2 text-uppercase font-weight-bold w-100"
      (click)="copyClipboard()"
    >
      {{ 'copy' | translate }}
    </button>
  </ul>

  <medrecord-auth-twofa-action-buttons-container
    *ngIf="step !== 4"
    [primaryButtonLabel]="getPrimaryButtonLabel() | translate"
    [showCancelButton]="step !== 5"
    (primaryButton)="primaryButtonClick()"
  ></medrecord-auth-twofa-action-buttons-container>
</div>