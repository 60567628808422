import { Component } from '@angular/core';
import { deleteDataAction } from '@managers/my-doctor';
import { Store } from '@ngrx/store';

@Component({
  selector: 'doctor-gui-delete-all-data-button-modal',
  templateUrl: './delete-all-data-button-modal.component.html'
})
export class DeleteAllDataButtonModalComponent {

  isOpen = false;

  constructor(private store: Store) {
  }

  onSubmit(): void {
    this.store.dispatch(deleteDataAction({}));

    this.isOpen = false;
  }

  openDialog(event): void {
    event.stopPropagation();
    this.isOpen = true;
  }
}
