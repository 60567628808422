import { Component, OnInit } from '@angular/core';
import { fetchTwofaQrCodeAction, getTwofaQrCodeUri } from '@medrecord/managers-auth';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'medrecord-auth-qr-code-container',
  templateUrl: './qr-code-container.component.html'
})
export class QrCodeContainerComponent implements OnInit {
  otpUri$: Observable<string> = this.store.select(getTwofaQrCodeUri);

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(fetchTwofaQrCodeAction());
  }
}
