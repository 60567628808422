<ejs-dialog [visible]="modalOpen" [isModal]="true" (overlayClick)="closeModal()" width="40%">
  <ng-template #header>
    <div class="px-2">
      <span>{{ transactionId | translate }}</span>
    </div>
  </ng-template>

  <ng-template #content>
    <div class="px-2 mx-0">
      <form [formGroup]="form">
        <div *ngFor="let group of parameterGroups" [formGroupName]="group.key">
          <div>
            <label>{{ group.key | translate }}</label>
          </div>
          <div
            class="pl-2 pb-2"
            *ngFor="let parameter of group.parameters"
          >
            <medrecord-ui-select
              *ngIf="parameter.type === transactionParameterType.dropdown"
              [id]="parameter.name"
              [formControlName]="parameter.name"
              [label]="parameter.label | translate"
              [placeholder]="parameter.placeholder | translate"
              [items]="parameter?.options"
            ></medrecord-ui-select>
            <medrecord-ui-input
              *ngIf="parameter.type === transactionParameterType.text"
              [id]="parameter.name"
              [formControlName]="parameter.name"
              [label]="parameter.label | translate"
              [placeholder]="parameter.placeholder | translate"
            ></medrecord-ui-input>
            <medrecord-ui-datepicker-precision
              *ngIf="parameter.type === transactionParameterType.date"
              [id]="parameter.name"
              [formControlName]="parameter.name"
              [label]="parameter.label | translate"
              [enablePrecisionChange]="false"
              class="w-100"
              dateFormat="yyyy-MM-dd"
            ></medrecord-ui-datepicker-precision>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #footerTemplate>
    <div class="px-2 d-flex align-items-center justify-content-end">
      <button (click)="closeModal()" type="button" class="btn btn-outline-black mr-3">
        {{ 'common_cancel' | translate }}
      </button>
      <button (click)="baseFormSubmit()" type="button" class="btn btn-secondary">
        {{ 'common_form_submit' | translate }}
      </button>
    </div>
  </ng-template>
</ejs-dialog>
