import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';
import {
  addUserToNetworkAction,
  addUserToNetworkFailureAction,
  addUserToNetworkSuccessAction,
  deleteUserFromNetworkAction,
  deleteUserFromNetworkFailureAction,
  deleteUserFromNetworkSuccessAction,
  loadUserNetworkAction,
  loadUserNetworkFailureAction,
  loadUserNetworkSuccessAction,
  searchUsersAction,
  searchUsersFailureAction,
  searchUsersSuccessAction,
  updateUserInNetworkAction,
  updateUserInNetworkFailureAction,
  updateUserInNetworkSuccessAction,
} from './network-manager.actions';
import { NetworkManagerService } from '../services/network-manager.service';
import { selectCurrentID } from '@medrecord/managers-users';

@Injectable()
export class NetworkManagerEffects {
  loadUserNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserNetworkAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([_, userId]) => {
        return this.networkManagerService.getUserNetwork(userId).pipe(
          switchMap((users) => {
            return [loadUserNetworkSuccessAction({ users })];
          }),
          catchError((error: any) => [
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_user_network_error'), error)),
            loadUserNetworkFailureAction({ error }),
          ])
        );
      })
    )
  );

  searchUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchUsersAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([{ searchQuery }, userId]) => {
        return this.networkManagerService.searchUsers(userId, searchQuery).pipe(
          switchMap((users) => {
            return [searchUsersSuccessAction({ users })];
          }),
          catchError((error: any) => [
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('search_users_error'), error)),
            searchUsersFailureAction({ error }),
          ])
        );
      })
    )
  );

  addUserToNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addUserToNetworkAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      mergeMap(([{ networkUserId, permission, searchQuery }, userId]) => {
        return this.networkManagerService.addUserToNetwork(userId, networkUserId, permission).pipe(
          switchMap(() => {
            return [
              addSuccessToast({ title: this.translateService.instant('added_user_to_network_success') }),
              addUserToNetworkSuccessAction(),
              searchUsersAction({ searchQuery }),
              loadUserNetworkAction()
            ];
          }),
          catchError((error: any) => [
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('added_user_to_network_error'), error)),
            addUserToNetworkFailureAction({ error }),
          ])
        );
      })
    )
  );

  updateUserInNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserInNetworkAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      mergeMap(([{ networkUserId, permission }, userId]) => {
        return this.networkManagerService.updateUserInNetwork(userId, networkUserId, permission).pipe(
          switchMap(() => {
            return [
              addSuccessToast({ title: this.translateService.instant('update_user_permission_in_network_success') }),
              updateUserInNetworkSuccessAction(),
              loadUserNetworkAction(),
            ];
          }),
          catchError((error: any) => [
            addErrorToast(
              getErrorToastBodyUtil(this.translateService.instant('update_user_permission_in_network_error'), error)
            ),
            updateUserInNetworkFailureAction({ error }),
          ])
        );
      })
    )
  );

  deleteUserFromNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUserFromNetworkAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      mergeMap(([{ networkUserId }, userId]) => {
        return this.networkManagerService.deleteUserFromNetwork(userId, networkUserId).pipe(
          switchMap(() => {
            return [
              addSuccessToast({ title: this.translateService.instant('delete_user_from_network_success') }),
              deleteUserFromNetworkSuccessAction(),
              loadUserNetworkAction(),
            ];
          }),
          catchError((error: any) => [
            addErrorToast(
              getErrorToastBodyUtil(this.translateService.instant('delete_user_from_network_error'), error)
            ),
            deleteUserFromNetworkFailureAction({ error }),
          ])
        );
      })
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private translateService: TranslateService,
    private networkManagerService: NetworkManagerService
  ) {}
}
