import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as UserStatusActions from './user-status.actions';
import { catchError, concatMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserStatusService } from '../services/user-status.service';

@Injectable()
export class UserStatusEffects {
  loadWaitingPatients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStatusActions.loadUserStatusAction),
      concatMap(({ userId }) =>
        this.userStatusService.getUserStatus(userId).pipe(
          map(({ data }) => UserStatusActions.loadUserStatusSuccessAction({ userStatus: data })),
          catchError(({error}) => of(UserStatusActions.loadUserStatusFailureAction({ error })))
        )
      )
    )
  );
  
  constructor(private actions$: Actions, private userStatusService: UserStatusService) {
  }
}
