import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from '../../../models/interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'medrecord-ui-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent {
  @Input() tabs: MenuItem[] = [];
  
  @Output() clickMenuItem = new EventEmitter<MenuItem>();
  
  constructor(
    public router: Router
  ) {}
  
  navigateTo(route: MenuItem): Promise<boolean> {
    this.clickMenuItem.emit(route);
    
    return this.router.navigate([route.route]);
  }
}
