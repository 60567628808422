<ng-container [formGroup]="parentForm">
  <div class="d-flex">
    <medrecord-ui-map-field [fieldType]="mapFieldType.PrecisionDateTime"
                            [parentForm]="parentForm"
                            [options]="dateTimeOptions"
                            controlName="birthDate"
                            [label]="'common_form_field_date_of_birth' | translate"
                            [hint]="'common_form_field_date_of_birth' | translate"
                            [isRequired]="isBirthDateRequired"
                            class="w-50"
                            dateFormat="yyyy-MM-dd"
    ></medrecord-ui-map-field>
  </div>

  <div class="mt-4">
    <medrecord-ui-map-field [fieldType]="mapFieldType.Bricks"
                            [parentForm]="parentForm"
                            controlName="gender"
                            [label]="'common_form_field_gender' | translate"
                            [hint]="'common_form_field_gender' | translate"
                            [options]="genderOptions"
    ></medrecord-ui-map-field>
  </div>
</ng-container>