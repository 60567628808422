<div *ngFor="let item of data; let index = index">
  <div
    class="title pr-4 d-flex align-items-center pb-3"
    (click)="toggleExpand(index)"
  >
    <div class="chevron">
      <medrecord-svg-chevron-right [type]="'black'" *ngIf="!isExpanded(index)" icon width="7"></medrecord-svg-chevron-right>
      <medrecord-svg-chevron-down [type]="'black'" *ngIf="isExpanded(index)" icon width="12"></medrecord-svg-chevron-down>
    </div>
    <span class="text-boulder text-weight-600 ml-2"
    >{{ getFieldGroupName(item, index) | translate }} - {{ index + 1 }}</span>
  </div>

  <ng-container *ngIf="isExpanded(index)">
    <div class="row mx-2">
      <ng-container *ngFor="let field of fieldGroup.fields">
        <div class="col-3 my-0 py-0" *ngIf="!isFieldEmpty(item, field)">
          <p class="text-boulder text-weight-600 mb-2">
            {{ field.label | translate }}
          </p>
        </div>
        <ng-container *ngIf="!field.showAsDownload; else showAsDownload">
          <div class="col-9 my-0 py-0" *ngIf="!isFieldEmpty(item, field)">
            <p
              class="mb-2"
              [class]="field.cssClass"
              [innerHTML]="prepareDataForField(item, field)"
            ></p>
          </div>
        </ng-container>
        <ng-template #showAsDownload>
          <div class="col-9 my-0 py-0">
            <a
              type="button"
              class="btn-sm btn-outline-black text-weight-600"
              [href]="prepareDataForField(item, field)"
              target="_blank"
            >
              {{ 'my_doctor_download_pdf' | translate }}
            </a>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
</div>
