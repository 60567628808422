import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MyDoctorTableInterface, ResourceField, ResourceFieldGroup, ResourceResponseData } from '@managers/my-doctor';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { AttachmentsService } from 'libs/medsafe/admin/reports/src/lib/services/attachments.service';
import { FieldGroupUtil } from '../../../utils/field-group.util';

@Component({
  selector: 'doctor-gui-field-group',
  templateUrl: './field-group.component.html',
  styleUrls: ['./field-group.component.scss']
})
export class FieldGroupComponent {
  @Input() fieldGroup: ResourceFieldGroup;
  @Input() mainResourceData: ResourceResponseData;
  @Input() allResourcesData: ResourceResponseData[];
  @Input() resourceIndex: number;
  @Output() getDetailsData: EventEmitter<string> = new EventEmitter<string>();

  isExpanded = false;

  constructor(private attachmentService: AttachmentsService) {
  }

  isFieldEmpty(field: ResourceField | ResourceFieldGroup): boolean {
    return FieldGroupUtil.isFieldEmpty(field, this.prepareDataForField.bind(this));
  }

  prepareFieldGroupName(): string {
    return FieldGroupUtil.prepareFieldGroupName(this.fieldGroup, this.resourceIndex, this.mainResourceData);
  }

  getDetails(event: MouseEvent): void {
    event.preventDefault();
    const id = this.fieldGroup.onDetailsLink(this.mainResourceData);

    this.getDetailsData.emit(id);
  }

  prepareDataForField(field: ResourceField): string | MyDoctorTableInterface {
    let rawData = field.useResource
      ? this.allResourcesData.find(r => r.resourceType === field.useResource)
      : this.mainResourceData;

    if (field.fieldName) {
      rawData = rawData?.content?.[field.fieldName];
    }

    if (rawData === undefined || rawData === null || (typeof rawData === 'string' && rawData === '')) {
      return null;
    }
    return field.prepareData(rawData, this.allResourcesData, this.mainResourceData);
  }

  onPdfaButtonClick(base64Data: string): void {
    this.attachmentService.openFile(base64Data, 'Document');
  }

  castPreparedDataToTable(data: string | MyDoctorTableInterface): MyDoctorTableInterface {
    return data as MyDoctorTableInterface;
  }

  castPreparedDataToString(data: string | MyDoctorTableInterface): string {
    return data as string;
  }
}
