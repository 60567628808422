import { Component, ContentChildren, Input, Optional, Self, TemplateRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ItemModel } from '@syncfusion/ej2-angular-navigations';
import { SelectFields } from '../../../models/interfaces';
import { NgControlComponentBase } from '../../../ui-abstractions/ng-control-component-base.component';

@Component({
  selector: 'medrecord-multi-select',
  templateUrl: './multi-select.component.html',
})
export class MultiSelectComponent extends NgControlComponentBase {
  @Input() items: ItemModel[];
  @Input() placeholder = '';
  @Input() allowCustomValue: boolean = false;
  @Input() asterix: boolean = false;

  @Input() fields: SelectFields = { text: 'text', value: 'id' };

  @ContentChildren('noRecordsTemplate') noRecordsTemplates: TemplateRef<any>[];

  constructor(@Self() @Optional() public control: NgControl) {
    super(control);
  }
}
