import { createAction, props } from '@ngrx/store';
import { TestCareproviderFetchStatusType } from '../../models/enums';

export const setTestCareproviderStatusModalOpenStatusAction = createAction(
  '[My Doctor Manager Actions] Set Test Careprovider Status Modal Open Status',
  props<{ isOpen: boolean }>()
);

export const loadTestCareproviderStatusAction = createAction(
  '[My Doctor Manager Actions] Load Test Careprovider Status',
  props<{ statusType: TestCareproviderFetchStatusType }>()
);

export const loadTestCareproviderStatusSuccessAction = createAction(
  '[My Doctor Manager Actions] Load Test Careprovider Status Success',
  props<{ data: string }>()
);
