import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-body-fat-percentage',
  templateUrl: './body-fat-percentage.component.svg',
  styleUrls: [],
})
export class BodyFatPercentageComponent extends MedrecordIcon {
  viewHeight: number = 24;
  viewWidth: number = 24;
}
