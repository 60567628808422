import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { cleanSelectedOrganisationAction } from '../state-management/network-organisations.actions';

@Injectable({
  providedIn: 'root'
})
export class AddOrganisationGuard implements CanActivate {
  constructor(
    private store: Store
  ) {}
  
  canActivate(): Observable<boolean> {
    this.store.dispatch(cleanSelectedOrganisationAction());
    return of(true);
  }
}