import { paymentManagerFeatureKey, PaymentMangerState } from './payment-manager.reducer';
import { createSelector } from '@ngrx/store';
import { PaymentPlan } from '../models/interfaces/payment-plan.interface';

export const getPaymentManagerState = (state: PaymentMangerState) => state[paymentManagerFeatureKey];

export const getPaymentPlans = createSelector(
  getPaymentManagerState, (state: PaymentMangerState): PaymentPlan[] => state.paymentPlans
);


