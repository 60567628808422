import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EjsMedrecordUploaderComponent } from './customization/ejs-medrecord-uploader/ejs-medrecord-uploader.component';
import { EjsMedrecordMultiselectComponent } from './customization/ejs-medrecord-multiselect/ejs-medrecord-multiselect.component';

import { MedrecordDialogComponent } from './containers/medrecord-dialog/medrecord-dialog.component';
import { SelectedBoxComponent } from './containers/selected-box/selected-box.component';

import { MedrecordSearchComponent } from './components/medrecord-search/medrecord-search.component';

import { MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { UploaderAllModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DialogAllModule } from '@syncfusion/ej2-angular-popups';

import { MedrecordIconsModule } from '@medrecord/icons';
import { MedrecordEssentialJsModuleConfig } from './typings';
import { getUrlInjectorProviders } from './provide-url-injectors';

export const components = [
  EjsMedrecordMultiselectComponent,
  EjsMedrecordUploaderComponent,
  MedrecordSearchComponent,
  MedrecordDialogComponent,
  SelectedBoxComponent
];

@NgModule({
  imports: [
    CommonModule,
    MultiSelectAllModule,
    UploaderAllModule,
    ButtonModule,
    MedrecordIconsModule,
    DialogAllModule
  ],
  declarations: [...components],
  exports: [...components],
})
export class MedrecordEssentialJsModule {
  static forRoot(config: MedrecordEssentialJsModuleConfig): ModuleWithProviders<MedrecordEssentialJsModule> {
    return {
      ngModule: MedrecordEssentialJsModule,
      providers: getUrlInjectorProviders(config.urls)
    };
  }
}
