<!-- Chips of resources -->
<doctor-gui-resources-chips-container></doctor-gui-resources-chips-container>

<div class="d-flex flex-wrap mt-2">
  <div class="ml-auto">
    <button class="btn btn-outline-primary btn-no-outline" (click)="cancelLongTerm()">
      {{ 'my_doctor_cancel_long_term_consent' | translate | titlecase }}
    </button>
  </div>
</div>

<div class="mt-2">
  <ng-container *ngIf="transactionId === pullTransaction.gp2020">
    <doctor-gui-gp-data-tables-container></doctor-gui-gp-data-tables-container>
  </ng-container>
  <ng-container *ngIf="transactionId !== pullTransaction.gp2020">
    <doctor-gui-resources-container></doctor-gui-resources-container>
  </ng-container>
</div>
