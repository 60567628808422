import { medicationsManagerFeatureKey, MedicationsManagerState } from './medications-manager.reducer';
import { createSelector } from '@ngrx/store';
import { Medication } from '../models/interfaces/medication.interface';

export const getMedicationsManagerState: (state: MedicationsManagerState) => any = (state: MedicationsManagerState) =>
  state[medicationsManagerFeatureKey];

export const getMedications = createSelector(
  getMedicationsManagerState,
  (state: MedicationsManagerState): Medication[] => state.medications
);

export const getCareplanMedications = createSelector(
  getMedicationsManagerState,
  (state: MedicationsManagerState): Medication[] => state.careplan.medications
);

export const getMedicationsCount = createSelector(
  getMedicationsManagerState,
  (state: MedicationsManagerState): number => state.count
);

export const getCareplanMedicationsCount = createSelector(
  getMedicationsManagerState,
  (state: MedicationsManagerState): number => state.careplan.count
);

export const getActiveMedication = createSelector(
  getMedicationsManagerState,
  (state: MedicationsManagerState): Medication => state.activeMedication
);

export const getPrescribedMedication = createSelector(
  getMedicationsManagerState,
  (state: MedicationsManagerState): Medication => state.careplan?.prescribedMedication
);
