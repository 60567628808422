import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';

@Injectable()
export class UrlUtils {
  public updateUrlWithoutReload(queryParams: PageSettingsModel): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}
}
