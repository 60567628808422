import { Injectable } from '@angular/core';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { CareplanService } from '../services/careplan.service';
import { selectCurrentID } from '@medrecord/managers-users';
import {
  linkCareplanAction,
  linkCareplanSuccessAction,
  unlinkCareplanAction,
  unlinkCareplanSuccessAction,
} from './careplans-manager.actions';

@Injectable()
export class CareplansManagerEffects {
  @Effect()
  linkCareplanObservation$ = this.actions$.pipe(
    ofType(linkCareplanAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ ids, category, careplanId }, userId]) => {
      return this.careplanService.linkCareplan(userId, category, ids, careplanId).pipe(
        switchMap(() => {
          this.store.dispatch(
            addSuccessToast({
              title: 'careplan_' + category?.toLowerCase() + '_link_title',
              content: 'careplan_' + category?.toLowerCase() + '_link_content',
            })
          );
          return [linkCareplanSuccessAction({ category, careplanId, ids })];
        }),
        catchError(({ error }) => {
          return [addErrorToast(getErrorToastBodyUtil('careplan_' + category?.toLowerCase() + '_link_error', error))];
        })
      );
    })
  );

  @Effect()
  unlinkCareplanObservation$ = this.actions$.pipe(
    ofType(unlinkCareplanAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ ids, category, careplanId }, userId]) => {
      return this.careplanService.unlinkCareplan(userId, category, ids, careplanId).pipe(
        switchMap(() => {
          this.store.dispatch(
            addSuccessToast({
              title: 'careplan_' + category?.toLowerCase() + '_unlink_title',
              content: 'careplan_' + category?.toLowerCase() + '_unlink_content',
            })
          );
          return [unlinkCareplanSuccessAction({ category, careplanId, ids })];
        }),
        catchError(({ error }) => {
          return [addErrorToast(getErrorToastBodyUtil('careplan_' + category?.toLowerCase() + '_unlink_error', error))];
        })
      );
    })
  );

  constructor(private actions$: Actions, private store: Store, private careplanService: CareplanService) {}
}
