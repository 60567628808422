import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const medicationIntoleranceBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.allergyIntolerance,
  fieldGroups: [
    {
      name: ({ resource }) => resource.content?.code?.coding?.[0]?.display || 'my_doctor_medication_intolerance',
      fields: [
        {
          label: 'my_doctor_allergy_category',
          fieldName: 'category',
          translate: true,
          prepareData: (data) => data?.category?.toLowerCase(),
        },
        {
          label: 'my_doctor_allergy_status',
          fieldName: 'clinicalStatus',
          translate: true,
          prepareData: (data) => data?.toLowerCase(),
        },
        {
          label: 'my_doctor_start_date',
          fieldName: 'onset',
          prepareData: (data) => (data?.date ? MedrecordMoment(data?.date).format('LL') : ''),
        },
        {
          label: 'my_doctor_allergy_criticality',
          fieldName: 'criticality',
          translate: true,
          prepareData: (data) => data?.toLowerCase(),
        },
        {
          label: 'my_doctor_last_occurence',
          fieldName: 'lastOccurrence',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LT') : ''),
        },
        {
          label: 'my_doctor_explanation',
          fieldName: 'note',
          prepareData: (data) => data.map(n => n.text).join('\n'),
        },
        {
          label: 'my_doctor_careprovider',
          useResource: ResourceType.practitionerRole,
          fieldName: 'practitionerReference',
          prepareData: (data, resources) => {
            const [resourceType, resourceId] = (data?.reference ?? '').split('/');
            return [
              resources.find(
                (r) =>
                  r.resourceType === resourceType &&
                  r.resourceType === ResourceType.practitioner &&
                  resourceId === r.resourceId
              )?.content?.identifiers?.[0].value,
              data?.display,
            ]
              .filter(Boolean)
              .join(', ');
          },
        },
      ],
    },
  ],
});
