import { Component, Input, Output, EventEmitter, AfterContentInit, ChangeDetectorRef } from '@angular/core';
import { SelectEventArgs } from '@syncfusion/ej2-dropdowns';

@Component({
  selector: 'medrecord-ui-dropdown-column',
  templateUrl: './dropdown-column.component.html',
  styleUrls: ['./dropdown-column.component.scss'],
})
export class DropdownColumnComponent implements AfterContentInit {
  @Input() placeholder: string;
  @Input() dataSource: any[];
  @Input() value: any;
  @Input() disabled: boolean;
  @Output() selected = new EventEmitter<string>();

  constructor(private ref: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    this.ref.detectChanges();
  }

  onSelect(item: SelectEventArgs): void {
    this.selected.emit(item.itemData['text']);
  }
}
