<ejs-dialog #dialogComponent [visible]="isModalOpen" [isModal]="true" (overlayClick)="closeModal()" width="40%">
  <ng-template #header>
    <div class="px-2">
      <span>{{ 'my_doctor_delete_data' | translate | titlecase }}</span>
    </div>
  </ng-template>

  <ng-template #content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <div class="py-3">
            <span>{{ 'my_doctor_select_delete_whole_careprovider' | translate }}</span>
          </div>
          <div class="py-3 care-provider-divider">
            <ejs-radiobutton
              [label]="careprovider.displayName || careprovider.id"
              [name]="radioProperty"
              [formControlName]="radioProperty"
              [value]="careprovider.id"
              (change)="radioChange()"
            ></ejs-radiobutton>
          </div>
        </div>
      </div>
      <div class="row py-3">
        <div class="col-6 care-provider-data-service-divider">
          <div>
            <span>{{ 'my_doctor_select_delete_specific_careprovider' | translate }}</span>
          </div>
          <div>
            <ejs-radiobutton
              *ngFor="let dataservice of dataservicesId"
              [label]="dataservice | translate"
              [name]="radioProperty"
              [formControlName]="radioProperty"
              [value]="dataservice"
              (change)="radioChange()"
            ></ejs-radiobutton>
          </div>
        </div>
        <div class="col-6"
             *ngIf="form.value[radioProperty] && form.value[radioProperty] !== careprovider.id"
             [formGroupName]="form.value[radioProperty]">
          <div>
            <span>{{ 'my_doctor_select_delete_specific_resource' | translate }}</span>
          </div>
          <div *ngFor="let resource of dataservices[form.value[radioProperty]]">
            <ejs-checkbox
              [label]="resource | translate"
              [name]="resource"
              [formControlName]="resource">
            </ejs-checkbox>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-sm text-warning">
          <p>{{ 'my_doctor_delete_all_data' | translate }}</p>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #footerTemplate>
    <div class="px-2 d-flex align-items-center justify-content-end">
      <button (click)="closeModal()" type="button" class="btn btn-outline-black mr-3">
        {{ 'common_no' | translate }}
      </button>
      <button (click)="baseFormSubmit()" [disabled]="!form.value[radioProperty]" type="button" class="btn btn-secondary">
        {{ 'common_delete' | translate }}
      </button>
    </div>
  </ng-template>
</ejs-dialog>
