export enum CPeptideType {
  FASTING = 'FASTING',
  GENERAL = 'GENERAL',
  URINE = 'URINE',
  URINE_24_H = 'URINE_24_H',
  PRE_GLUCOSE = 'PRE_GLUCOSE',
  PRE_GLUCAGON = 'PRE_GLUCAGON',
  PRE_XXX_CHALLENGE = 'PRE_XXX_CHALLENGE',
  POST_MEAL = 'POST_MEAL',
  POST_GLUCOSE = 'POST_GLUCOSE',
  POST_GLUCAGON = 'POST_GLUCAGON',
  POST_1_MG_GLUCAGON = 'POST_1_MG_GLUCAGON',
  POST_XXX_CHALLENGE = 'POST_XXX_CHALLENGE',
  BASELINE = 'BASELINE',
}
