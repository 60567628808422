
<ejs-dialog
  #dialogComponent
  [visible]="false"
  [isModal]="true"
  [target]='dialogContainer'
  (overlayClick)="closing.emit($event)"
  width='60%'
>
  <ng-template #header>
    <div class="d-flex justify-content-between">
      <span class="font-weight-bold text-xl">
        {{ headerText }}
      </span>

      <medrecord-svg-cross
        [width]="10"
        [type]="'cape-cod'"
        (click)="closing.emit($event)"
      ></medrecord-svg-cross>
    </div>

  </ng-template>

  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>

  <ng-template #footerTemplate>
    <div>
      <button
        ejs-button
        (click)="closing.emit($event)"
        class="e-pill e-outline font-weight-bold">
        {{ cancelText }}
      </button>
      <button
        ejs-button
        (click)="submitting.emit($event)"
        class="e-pill e-warning text-white font-weight-bold">
        {{ submitText }}
      </button>
    </div>
  </ng-template>
</ejs-dialog>
