import { Inject, Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  fetchResourcesAction,
  loadRecordAction,
  setAfterFetchingResourcesReturnToAction,
  setFetchResourcesRequestPayloadAction,
  setTestCareproviderModalOpenStatusAction
} from '../actions';
import { getActiveCareproviderId, getActiveTransactionId } from '../selectors';
import { ResourceParameterRequest } from '../../models/interfaces';
import { MyDoctorManagerService } from '../../services/my-doctor-manager.service';
import { TransactionParameterName } from '../../models/enums';
import { MY_DOCTOR_MANAGER_CONSTANTS } from '../../my-doctor-manager.tokens';
import { MyDoctorManagerConstants } from '../../constants';

@Injectable()
export class RecordEffects {

  @Effect()
  loadRecord$ = this.actions$.pipe(
    ofType(loadRecordAction),
    withLatestFrom(
      this.store.select(getActiveCareproviderId),
      this.store.select(getActiveTransactionId),
    ),
    mergeMap(([{ recordId }, careproviderId, transactionId]) => {
      const pullMapping = this.myDoctorConstants.pullTransactionMappings.get(transactionId);
      const resourceParameterRequest: ResourceParameterRequest[] = pullMapping.detailResourceKeys.map(resource => ({
        key: resource,
        parameters: { [TransactionParameterName.recordId]: recordId }
      }));

      const actions: Action[] = [
        setAfterFetchingResourcesReturnToAction({ detailsRoute: recordId }),
        setFetchResourcesRequestPayloadAction({ fetchResourceRequest: { resources: resourceParameterRequest } })
      ];

      if (this.service.isTest(careproviderId)) {
        actions.push(
          setTestCareproviderModalOpenStatusAction({ isOpen: true })
        );
      } else {
        actions.push(
          fetchResourcesAction()
        );
      }
      return actions;
    })
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private service: MyDoctorManagerService,
    @Inject(MY_DOCTOR_MANAGER_CONSTANTS) private myDoctorConstants: MyDoctorManagerConstants
  ) {
  }
}
