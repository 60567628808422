import { InjectionToken, Provider } from '@angular/core';

export const itemsPerPage = 5;

export const questionnaireConstants = {
  QUESTIONNAIRE_LIST_PAGE_SIZE: itemsPerPage,
  PAGE_SIZE: 'pageSize',
  PAGE_COUNT: 'pageCount',
  CURRENT_PAGE: 'currentPage',
};

export type QuestionnaireConstants = typeof questionnaireConstants;

export const QUESTIONNAIRES_CONSTANTS = new InjectionToken('Questionnaire constants');

export const provideQuestionnaireConstants = (): Provider => [
  {
    provide: QUESTIONNAIRES_CONSTANTS,
    useValue: questionnaireConstants,
  },
];
