import { Component, Input } from '@angular/core';

@Component({
  selector: 'medrecord-ui-link-column',
  templateUrl: './link-column.component.html',
})
export class LinkColumnComponent {
  @Input() link = '#';
  @Input() value;
  @Input() disabled = false;
}
