import { QuestionnaireScore } from '../models/interfaces/questionnaire-score.interface';
import { Action, createReducer } from '@ngrx/store';
import { QuestionnaireTemplateListItem } from '../models/interfaces/questionnaire-template-list-item.interface';
import { QuestionnaireTemplateInterface } from '../models/interfaces/questionnaire-template.interface';
import { templatesReducers } from './reducers/templates.reducers';
import { inUseReducers } from './reducers/in-use.reducers';
import { scoresReducers } from './reducers/scores.reducers';
import { questionnaireReducers } from './reducers/questionnaire.reducers';
import { QuestionnairesInUseListItem } from '../models/interfaces/questionnaire-in-use-list-item.interface';
import { Questionnaire, QuestionnairesScoreListItem } from '../models/interfaces';

export const questionnairesFeatureName = 'bmd_questionnaires';

export interface State {

  // Q templates

  questionnaireTemplates: QuestionnaireTemplateListItem[];
  questionnaireTemplatesCount: number;
  questionnaireTemplatesLoadingError: any;
  questionnairesData: {
    [questionnaireId: string]: QuestionnaireTemplateInterface
  };

  // Q in use

  questionnairesInUse: QuestionnairesInUseListItem[];
  questionnairesInUseCount: number;
  questionnairesInUseLoadingError: any;

  // Q scores

  questionnairesScores: QuestionnairesScoreListItem[];
  questionnairesScoresCount: number;
  questionnairesScoresLoadingError: any;

  questionnaireScore: QuestionnaireScore[];
  questionnaireScoreLoadingError: any;

  // Questionnaire

  questionnairesMap: {
    [key: string]: Questionnaire
  };
}

export const initialState = {
  questionnaireTemplates: [],
  questionnaireTemplatesCount: 0,
  questionnaireTemplatesLoadingError: null,
  questionnairesData: {},

  questionnairesInUse: [],
  questionnairesInUseCount: 0,
  questionnairesInUseLoadingError: null,

  questionnairesScores: [],
  questionnairesScoresCount: 0,
  questionnairesScoresLoadingError: null,

  questionnaireScore: [],
  questionnaireScoreLoadingError: null,
  
  questionnairesMap: {}
};

const _questionnairesReducer = createReducer<State>(
  initialState,
  ...templatesReducers,
  ...inUseReducers,
  ...scoresReducers,
  ...questionnaireReducers
);

export function questionnairesReducer(state: State, action: Action): State {
  return _questionnairesReducer(state, action);
}
