import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { paymentManagerFeatureKey, reducer } from './payment-manager.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PaymentManagerEffects } from './payment-manager.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(paymentManagerFeatureKey, reducer),
    EffectsModule.forFeature([ PaymentManagerEffects ])
  ]
})
export class PaymentManagerStateModule {}