import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-calendar',
  templateUrl: './calendar.component.svg',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent extends MedrecordIcon {
  viewHeight = 20;
  viewWidth = 20;
}
