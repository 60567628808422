<h2 class="text-weight-600 text-primary mb-3 text-center">
  {{ 'auth_welcome_signin_option' | translate }}
</h2>

<p class="text-justify text-black">
  {{ 'auth_signin_credentials_intro' | translateOrHide }}
</p>

<form (submit)="handleFormSubmit()" [formGroup]="form" class="my-4 text-center text-lg">
  <div class="pt-3">
    <medrecord-ui-map-field
      [attr.id]="isOAuth && 'oauth-email-field'"
      [fieldType]="mapFieldType.Input"
      [label]="'auth_enter_your_email' | translate"
      controlName="email"
      [parentForm]="form"
    >
      <ng-template #leftIcon>
        <i class="far fa-envelope text-black"></i>
      </ng-template>
    </medrecord-ui-map-field>
  </div>

  <div class="pt-3">
    <medrecord-ui-map-field
      [attr.id]="isOAuth && 'oauth-password-field'"
      [fieldType]="mapFieldType.Input"
      [type]="showPassword ? 'text' : 'password'"
      [label]="'auth_credentials_password_label' | translate"
      controlName="password"
      [parentForm]="form"
    >
      <ng-template #leftIcon>
        <i class="fas fa-lock text-black"></i>
      </ng-template>

      <ng-template #rightIcon>
        <i
          class="fas fa-eye text-black cursor-pointer"
          [class.fa-eye]="!showPassword"
          [class.fa-eye-slash]="showPassword"
          (click)="showPassword = !showPassword"
        ></i>
      </ng-template>
    </medrecord-ui-map-field>
  </div>

  <div class="pt-3" *ngIf="!disableRemember">
    <medrecord-ui-map-field
      [fieldType]="mapFieldType.CheckBox"
      [label]="'auth_remember_me' | translate"
      [parentForm]="form"
      [fieldCss]="'text-weight-400 text-lg'"
      controlName="rememberMe"
    ></medrecord-ui-map-field>
  </div>
  <button
    [class.btn-rounded]="isOAuth"
    [ngClass]="[
      'btn',
      'btn-primary',
      'text-uppercase',
      'text-white',
      'font-weight-bold',
      'w-100',
      disableRemember ? 'mt-4' : ''
    ]"
    type="submit"
  >
    {{ 'auth_log_in' | translate }}
  </button>

  <ng-container *ngIf="!disableRemember && rememberedUser">
    <div class="d-flex align-items-center my-4">
      <hr class="line" />
      <div class="mx-2 text-black-300 text-weigh-500 text-xl">{{ 'auth_or_log_in_with' | translate }}</div>
      <hr class="line" />
    </div>

    <button
      [class.btn-rounded]="isOAuth"
      class="btn btn-outline-black font-weight-bold w-100 remembered-user-btn"
      (click)="logInRememberedUser()"
      type="button"
    >
      <div class="remembered-user">{{ rememberedUser }}</div>
    </button>
  </ng-container>
</form>

<div class="d-flex w-100 justify-content-center align-items-center">
  <span class="text-weight-400 text-lg mr-2">{{ 'auth_forgot_password' | translate }}</span>
  <a class="font-weight-bold text-lg cursor-pointer" (click)="openForgotPasswordPage()">
    {{ 'common_click_here' | translate }}
  </a>
</div>

<div class="d-flex w-100 justify-content-center align-items-center mt-5" *ngIf="!disableRegister">
  <span class="text-weight-400 text-lg mr-2">{{ 'auth_dont_have_account' | translate }}</span>
  <a class="font-weight-bold text-lg cursor-pointer" (click)="openSignUpPage()">
    {{ 'common_click_here' | translate }}
  </a>
</div>
