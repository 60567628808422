<div [formGroup]="parentForm" class="{{ cssClass || '' }}" *ngIf="!bindVisibility || bindVisibility()">
  <medrecord-ui-field-label
    [text]="label"
    [secondaryText]="secondaryLabel"
    [labelContentTemplate]="labelContent"
    [hint]="hint"
    [type]="'black'"
    *ngIf="label && fieldType !== fieldTypeVariants.CheckBox"
    [asterix]="asterix"
  ></medrecord-ui-field-label>

  <medrecord-ui-datepicker-precision
    *ngIf="fieldType === fieldTypeVariants.PrecisionDateTime"
    [formControlName]="controlName"
    [maxDate]="options?.maxDate"
    [minDate]="options?.minDate"
    [precision]="options?.precision"
    [startCalendarView]="options.startCalendarView"
    [calendarViewDepth]="options.calendarViewDepth"
    [enablePrecisionChange]="getEnablePrecisionValue()"
    [cssClass]="fieldCss"
    (change)="onChange($event)"
    [dateFormat]="dateFormat"
    [isRequired]="isRequired"
  >
    <ng-content></ng-content>
  </medrecord-ui-datepicker-precision>

  <medrecord-ui-input
    *ngIf="fieldType === fieldTypeVariants.Input"
    [formControlName]="controlName"
    [placeholder]="placeholder"
    [type]="type"
    [mask]="options?.mask"
    [name]="name"
    [autocomplete]="autocomplete"
    [errorCode]="errorCode"
    (change)="onChange($event)"
    [cssClass]="fieldCss"
  >
    <ng-template #rightIcon *ngFor="let rightIcon of rightIcons">
      <ng-container *ngTemplateOutlet="rightIcon"></ng-container>
    </ng-template>

    <ng-template #leftIcon *ngFor="let leftIcon of leftIcons">
      <ng-container *ngTemplateOutlet="leftIcon"></ng-container>
    </ng-template>

    <ng-template #rightButton *ngFor="let rightButton of rightButtons">
      <ng-container *ngTemplateOutlet="rightButton"></ng-container>
    </ng-template>

    <ng-template #leftButton *ngFor="let leftButton of leftButtons">
      <ng-container *ngTemplateOutlet="leftButton"></ng-container>
    </ng-template>
  </medrecord-ui-input>

  <medrecord-ui-input
    *ngIf="fieldType === fieldTypeVariants.Numeric"
    [formControlName]="controlName"
    [placeholder]="placeholder"
    type="number"
    [mask]="options?.mask"
    [name]="name"
    [defaultValue]="defaultValue"
    [min]="options?.min"
    [max]="options?.max"
    [errorCode]="errorCode"
    (change)="onChange($event)"
    [cssClass]="fieldCss"
  >
    <ng-template #rightIcon *ngFor="let rightIcon of rightIcons">
      <ng-container *ngTemplateOutlet="rightIcon"></ng-container>
    </ng-template>

    <ng-template #leftIcon *ngFor="let leftIcon of leftIcons">
      <ng-container *ngTemplateOutlet="leftIcon"></ng-container>
    </ng-template>

    <ng-template #rightButton *ngFor="let rightButton of rightButtons">
      <ng-container *ngTemplateOutlet="rightButton"></ng-container>
    </ng-template>

    <ng-template #leftButton *ngFor="let leftButton of leftButtons">
      <ng-container *ngTemplateOutlet="leftButton"></ng-container>
    </ng-template>
  </medrecord-ui-input>

  <medrecord-ui-textarea
    *ngIf="fieldType === fieldTypeVariants.TextArea"
    [formControlName]="controlName"
    [errorCode]="errorCode"
    (change)="onChange($event)"
    [cssClass]="fieldCss"
  >
    <ng-content></ng-content>
  </medrecord-ui-textarea>

  <ejs-combobox
    [dataSource]="options?.items"
    class="e-small"
    *ngIf="fieldType === fieldTypeVariants.ComboBox"
    [formControlName]="controlName"
    [placeholder]="placeholder"
    (change)="onChange($event)"
    [cssClass]="fieldCss"
  >
    <ng-content></ng-content>
  </ejs-combobox>

  <medrecord-ui-bricks
    *ngIf="fieldType === fieldTypeVariants.Bricks"
    [items]="options?.items"
    [multiply]="options?.multiply"
    [formControlName]="controlName"
    [cssClass]="fieldCss"
  ></medrecord-ui-bricks>

  <medrecord-ui-phone
    [formControlName]="controlName"
    *ngIf="fieldType === fieldTypeVariants.Phone"
    (change)="onChange($event)"
  ></medrecord-ui-phone>

  <medrecord-ui-checkbox
    [formControlName]="controlName"
    [label]="label"
    [cssClass]="fieldCss"
    *ngIf="fieldType === fieldTypeVariants.CheckBox"
    (change)="onChange($event)"
  >
    <ng-content></ng-content>
  </medrecord-ui-checkbox>

  <medrecord-ui-select
    *ngIf="fieldType === fieldTypeVariants.Select"
    [formControlName]="controlName"
    [name]="name"
    [items]="options?.items"
    [cssClass]="fieldCss"
    (change)="onChange($event)"
  >
  </medrecord-ui-select>

  <ng-container *ngIf="fieldType === fieldTypeVariants.Radio">
    <div class="form-check w-100 mr-4 {{ fieldCss }}" *ngFor="let field of options.items">
      <input
        class="form-check-input"
        type="radio"
        [value]="field.id"
        [attr.id]="field.id"
        [formControlName]="controlName"
        (change)="onChange($event)"
      />

      <label class="form-check-label text-md text-weight-400" [attr.for]="field.id">
        <span class="text-weight-400">
          {{ field.text | translate }}
        </span>
      </label>
    </div>
  </ng-container>

  <ejs-multiselect
    *ngIf="fieldType === fieldTypeVariants.MultiSelect"
    [dataSource]="options?.items"
    [formControlName]="controlName"
    (change)="onChange($event)"
    [allowCustomValue]="allowCustomValue"
    [fields]="fields"
  >
    <!-- Hide 'No records found text' -->
    <ng-template #noRecordsTemplate *ngFor="let noRecordsTemplate of noRecordsTemplates">
      <ng-container *ngTemplateOutlet="noRecordsTemplate"></ng-container>
    </ng-template>
  </ejs-multiselect>
</div>
