import { Action, createAction, props } from '@ngrx/store';
import { Permission } from '../models/enums';
import { BuddyOfUsers, User } from '../models/interfaces';
import { UserMetaAllergy } from '../models/interfaces/user-meta-allergy.interface';

export const selectUser = createAction(
  '[Users Manager Actions] Select User',
  props<{ id: string }>()
);

export const closeUserAction = createAction(
  '[Users Manager Actions] Close User',
  props<{ index: number }>() 
);

export const dragUserAction = createAction(
  '[Users Manager Actions] Drag User',
  props<{ startIndex: number, endIndex: number }>() 
);

export const clearUsersStateAction = createAction(
  '[Users Manager Actions] Clear State'
);

export const loadUserProfileAction = createAction(
  '[Users Manager Actions] Load User Profile',
  props<{ id: string }>()
);

export const loadUsersSuccessAction = createAction(
  '[Users Manager Actions] Load User Success',
  props<{ id: string, user: User }>()
);

export const loadUsersFailureAction = createAction(
  '[Users Manager Actions] Load User Failure Action',
  props<{ error: any }>()
);

export const loadUserNetworksAction = createAction(
  '[Users Manager Actions] Load User Networks'
);

export const loadUserNetworksSuccessAction = createAction(
  '[Users Manager Actions] Load User Networks Success',
  props<{ users: BuddyOfUsers[] }>()
);

export const loadUserNetworksFailureAction = createAction(
  '[Users Manager Actions] Load User Networks Failure',
  props<{ error: any }>()
);

export const loadUserMetaData = createAction(
  '[Users Manager Actions] Load User Metadata',
  props<{ id: string, permission: Permission }>()
);

export const loadUserMetaAllergiesAction = createAction(
  '[Users Manager Actions] Load User Metadata Allergies',
  props<{ id: string }>()
);

export const loadUserMetadataSuccessAction = createAction(
  '[Users Manager Actions] Load User Metadata Success',
  props<{ id: string, user: User, allergies: UserMetaAllergy[], permission: Permission }>()
);

export const loadUserMetadataFailureAction = createAction(
  '[Users Manager Actions] Load User Metadata Failure',
  props<{ error: any }>()
);

export const editUserAction = createAction(
  '[Users Manager Actions] Edit User',
  props<{ user: User, successActions?: Action[] }>()
);

export const editUserSuccessAction = createAction(
  '[Users Manager Actions] Edit User Success',
  props<{ user: User, id: string }>()
);

export const editUserFailureAction = createAction(
  '[Users Manager Actions] Edit User Failure Success',
  props<{ error: any }>()
);


export const createUserAction = createAction(
  '[Users Manager Actions] Create User',
  props<{ user: User, successActions?: Action[] }>()
);

export const createUserSuccessAction = createAction(
  '[Users Manager Actions] Create User Success',
  props<{ user: User }>()
);

export const createUserFailureAction = createAction(
  '[Users Manager Actions] Create User Failure Success',
  props<{ error: any }>()
);

export const sendChangePhoneCodeAction = createAction(
  '[Users Manager Actions] Send Change Phone Code',
  props<{ code: string }>()
);

export const sendChangePhoneCodeSuccessAction = createAction(
  '[Users Manager Actions] Send Change Phone Code Success',
  props<{ code: string }>()
);

export const sendChangePhoneCodeFailureAction = createAction(
  '[Users Manager Actions] Send Change Phone Code Failure',
  props<{ error: any }>()
);

export const verifyPhoneAction = createAction(
  '[Users Manager Actions] Verify Phone',
  props<{ phone: string }>()
);

export const verifyPhoneSuccessAction = createAction(
  '[Users Manager Actions] Verify Phone Success',
  props<{ phone: string }>()
);

export const verifyPhoneFailureAction = createAction(
  '[Users Manager Actions] Verify Phone Failure',
  props<{ error: any }>()
);

export const restorePinnedUsers = createAction(
  '[Users Manager Actions] Restore Pined User'
);

export const pinUserAction = createAction(
  '[Users Manager Actions] Pin User',
  props<{ userId: string, restoring?: boolean }>()
);

export const pinUserSuccessAction = createAction(
  '[Users Manager Actions] User Pined Successfully ',
  props<{ userId: string }>()
);

export const pinUserFailureAction = createAction(
  '[Users Manager Actions] Failure Pin User',
  props<{ error: any }>()
);

export const hidePinedUserAction = createAction(
  '[Users Manager Actions] Hide Pinned User',
  props<{ userId: string }>()
);

export const setActivePinnedUserId = createAction(
  '[Users Manager Actions] Set Active Pinned User',
  props<{ userId: string }>()
);
