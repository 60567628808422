import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MappingResource } from '@managers/my-doctor';
import { AbstractFormModalComponent } from '../abstract-form-modal/abstract-form-modal.component';

@Component({
  selector: 'doctor-gui-resources-modal',
  templateUrl: 'resources-modal.component.html'
})
export class ResourcesModalComponent extends AbstractFormModalComponent<MappingResource[]> {
  @Input() careproviderName: string;
  @Input() dataserviceName: string;

  @Input() set resources(resources: MappingResource[]) {
    this.initializeForm(resources);
    this.modalResources = resources;
  }

  @Input() set preselectedResources(preselectedResources: MappingResource[]) {
    this.modalPreselectedResources = preselectedResources;
    this.initializeForm(this.modalResources);
  }

  modalResources: MappingResource[] = [];
  modalPreselectedResources: MappingResource[] = [];

  constructor(
    private formBuilder: FormBuilder
  ) {
    super();
  }

  formSubmit(): void {
    const resources: MappingResource[] = Object.entries(this.form.value)
      .filter((r: [string, boolean]) => r[1])
      .map((r: [string, boolean]) => r[0] as MappingResource);
    this.modalSubmitted.emit(resources);
  }

  setAll(value: boolean): void {
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key)?.setValue(value);
    });
  }

  private initializeForm(resources: MappingResource[]): void {
    const formControls = {};
    resources.forEach(resource => {
      formControls[resource] = this.modalPreselectedResources.indexOf(resource) >= 0;
    });
    this.form = this.formBuilder.group(formControls);
  }

}
