import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../../enums/resource-type.enum';
import { ResourceBlockFunction } from '../../interfaces/resource-block-function.interface';

export const recordsBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.episodeOfCare,
  fieldGroups: [
    {
      name: ({ resource }) =>
        `${resource.content.period?.start ? MedrecordMoment(resource.content.period?.start).format('LL') : ''} ${
          resource.content.period?.end ? ' - ' + MedrecordMoment(resource.content.period?.end).format('LL') : ''
        } ${resource.content.title ? ' - ' + resource.content.title : ''}`,
      fields: [
        {
          label: 'my_doctor_care_provider',
          fieldName: 'careManager',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_icpc_description',
          fieldName: 'diagnosisConditions',
          prepareData: (data) => (data ? data?.[0]?.display : null),
        },
        {
          label: 'my_doctor_organization_name',
          fieldName: 'managingOrganization',
          prepareData: (data) => (data ? data.display : null),
        },
        {
          label: 'my_doctor_status',
          fieldName: 'status',
          prepareData: (data) => (data ? `common_status_${data.toLowerCase()}` : null),
          translate: true,
        },
      ],
      onDetailsLink: (data) => data?.resourceId,
      detailsTitle: (data) =>
        `${data.content.period?.start ? MedrecordMoment(data.content.period?.start).format('LL') : ''} ${
          data.content.period?.end ? ' - ' + MedrecordMoment(data.content.period?.end).format('LL') : ''
        } ${data.content.title ? ' - ' + data.content.title : ''}`,
    },
  ],
});
