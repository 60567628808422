import { Action, createReducer, on } from '@ngrx/store';
import {
  clearCurrentDeviceAction,
  loadCurrentDeviceFailureAction,
  loadCurrentDeviceSuccessAction,
  loadDeviceHistorySuccessAction,
  loadDevicesActionSuccess,
  saveCurrentFeedPackSuccessAction,
} from './food-friend-manager.actions';
import { Device } from '../models/device.model';
import { CurrentFeedPack } from '../models/current-feed-pack.model';
import { Sim00009, Sim00010, Sim00011, devices, latestStatus } from '../constants/devices';
import { IDeviceListItem } from '../models/device-list-item.model';

export const foodFriendManagerFeatureKey = 'food-friend-manager';

export interface FoodFriendManagerState {
  devices: IDeviceListItem[];
  currentDevice: Device;
  _currentFeedPack: CurrentFeedPack;
  history?: {
    [key: string]: Device[];
  };
}

export const initialState: FoodFriendManagerState = {
  // TODO Remove these mock values once we have real data
  devices: devices,
  currentDevice: latestStatus,
  _currentFeedPack: {
    date: new Date().toISOString(),
    dose: 500,
    rate: 150,
    volume: 2000,
    fromH: 12,
    fromM: 0,
    toH: 23,
    toM: 0,
  },
  history: {
    'Sim-00009': Sim00009,
    'Sim-100010': Sim00010,
    'Sim-100011': Sim00011,
  },
};

export const foodFriendManagerReducer = createReducer(
  initialState,
  on(loadDevicesActionSuccess, (state, { devices }) => ({
    ...state,
    devices,
  })),
  on(loadCurrentDeviceSuccessAction, (state, { currentDevice }) => ({
    ...state,
    currentDevice,
    currentFeedPack: currentDevice,
  })),
  on(loadCurrentDeviceFailureAction, (state, { error }) => ({
    ...state,
    currentDevice: null,
    error,
  })),
  on(clearCurrentDeviceAction, (state) => ({
    ...state,
    currentDevice: null,
  })),
  on(saveCurrentFeedPackSuccessAction, (state, { device }) => ({
    ...state,
    currentFeedPack: device,
    currentDevice: device,
  })),
  on(loadDeviceHistorySuccessAction, (state, { history, deviceId }) => ({
    ...state,
    history: {
      ...state.history,
      [deviceId]: history,
    },
  }))
);

export const reducer = (state: FoodFriendManagerState, action: Action): FoodFriendManagerState => {
  return foodFriendManagerReducer(state, action);
};
