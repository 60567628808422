import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService } from '@medrecord/services-form-validation';
import { TranslateService } from '@ngx-translate/core';
import { FORM_RULES, FormRules } from '@medrecord/managers-auth';
import { MapFieldType } from '@medrecord/ui';
import { StorageService } from '@medrecord/tools-storage';
import { StorageKeys } from '@medrecord/core';

@Component({
  selector: 'medrecord-auth-confirm-password-form',
  styleUrls: ['./confirm-password-form.component.scss'],
  templateUrl: './confirm-password-form.component.html',
})
export class ConfirmPasswordFormComponent {
  @Input()
  title = '';

  @Input()
  description = '';

  @Output()
  passwordSubmit = new EventEmitter<string>();

  form: FormGroup;
  showPassword = false;
  showRepeatPassword = false;

  readonly mapFieldType = MapFieldType;
  readonly isOAuth = this.storage.getItem<boolean>(StorageKeys.isOAuth, false);

  constructor(
    fb: FormBuilder,
    translateService: TranslateService,
    validationService: ValidationService,
    private storage: StorageService<StorageKeys>,

    @Inject(FORM_RULES) formRules: FormRules
  ) {
    this.form = fb.group(
      {
        newPassword: [
          null,
          [
            validationService.required(translateService.instant('auth_validation_error_required')),
            validationService.maxLength(
              formRules.passwordMaxLength,
              translateService.instant('auth_validation_error_required')
            ),
            validationService.passwordComplexity(translateService.instant('auth_validation_password_complexity')),
          ],
        ],
        repeatPassword: [
          null,
          [validationService.required(translateService.instant('auth_validation_error_required'))],
        ],
      },
      {
        validators: validationService.shouldBeEqual(
          'newPassword',
          'repeatPassword',
          translateService.instant('auth_validation_password_not_equal')
        ),
      }
    );
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.passwordSubmit.emit(this.form.value.newPassword);
    }
  }
}
