import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HealthplansManagerEffects } from './healthplans-manager.effects';
import { healthplansManagerFeatureKey, reducer } from './healthplans-manager.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(healthplansManagerFeatureKey, reducer),
    EffectsModule.forFeature([ HealthplansManagerEffects ])
  ]
})
export class HealthplansManagerStateModule {}