import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-chat',
  templateUrl: './chat.component.svg',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent extends MedrecordIcon {
  viewHeight = 16;
  viewWidth = 18;
}
