import { Healthplan } from '../models/interfaces/healthplan.interface';
import { Action, createReducer, on } from '@ngrx/store';
import {
  addGoalCommentSuccessAction,
  addGoalSuccessAction,
  addHealthplanSuccessAction,
  addTaskCommentSuccessAction,
  addTaskSuccessAction,
  clearActiveGoal,
  clearActiveHealthplan,
  clearActiveTaskAction,
  deleteGoalCommentSuccessAction,
  deleteGoalSuccessAction,
  deleteHealthplanSuccessAction,
  deleteTaskCommentSuccessAction,
  editGoalSuccessAction,
  editHealthplanSuccessAction,
  editTaskSuccessAction,
  loadGoalsFailureAction,
  loadGoalsSuccessAction,
  loadGoalSuccessAction,
  loadHealthplansFailureAction,
  loadHealthplansSuccessAction,
  loadHealthplanSuccessAction,
  loadTasksFailureAction,
  loadTasksSuccessAction,
  loadTaskSuccessAction,
  loadUserNetworkForHealthplanSuccessAction,
  updateGoalCommentSuccessAction,
  updateTaskCommentSuccessAction,
} from './healthplans-manager.actions';
import { Goal } from '../models/interfaces/goal.interface';
import { Task } from '../models/interfaces/task.interface';
import { User } from '@medrecord/managers-users';

export const healthplansManagerFeatureKey = 'healthplans-manager';

export interface HealthplansManagerState {
  error: any;
  healthplans: Healthplan[];
  count: number;
  activeHealthplan: Healthplan;
  goals: Goal[];
  activeGoal: Goal;
  activeTask: Task;
  tasks: { [key: string]: Task[] };
  userNetwork: User[];
  relatedPeople: User[];
}

export const initialState: HealthplansManagerState = {
  error: null,
  healthplans: [],
  goals: [],
  tasks: {},
  activeHealthplan: null,
  activeGoal: null,
  activeTask: null,
  userNetwork: null,
  relatedPeople: null,
  count: 0,
};

export const healthplansManagerReducer = createReducer(
  initialState,
  on(loadHealthplansSuccessAction, (state, { healthplans, count }) => ({
    ...state,
    healthplans,
    count,
  })),
  on(loadUserNetworkForHealthplanSuccessAction, (state, { userNetwork, relatedPeople }) => ({
    ...state,
    userNetwork,
    relatedPeople,
  })),
  on(deleteHealthplanSuccessAction, (state, { id }) => ({
    ...state,
    healthplans: state.healthplans.filter((i) => i.id !== id),
    count: state.count - 1,
  })),
  on(addHealthplanSuccessAction, (state, { healthplan }) => ({
    ...state,
    healthplans: [...state.healthplans, healthplan],
    count: state.count + 1,
  })),
  on(editHealthplanSuccessAction, (state, { healthplan }) => ({
    ...state,
    healthplans: state.healthplans.map((hp) => (hp.id === healthplan.id ? healthplan : hp)),
    activeHealthplan: healthplan,
  })),
  on(loadHealthplanSuccessAction, (state, { healthplan }) => ({
    ...state,
    activeHealthplan: healthplan,
  })),

  on(clearActiveHealthplan, (state) => ({
    ...state,
    activeHealthplan: null,
  })),
  on(loadGoalsSuccessAction, (state, { goals }) => ({
    ...state,
    goals: goals,
  })),
  on(deleteGoalSuccessAction, (state, { id }) => ({
    ...state,
    goals: state.goals.filter((i) => i.id !== id),
  })),
  on(addGoalSuccessAction, (state, { goal, selectedStatus }) => ({
    ...state,
    activeGoal: goal,
    goals: selectedStatus === goal.status ? [goal, ...state.goals] : state.goals,
  })),
  on(editGoalSuccessAction, (state, { goal }) => ({
    ...state,
    activeGoal: goal,
    goals: state.goals.map((g) => (g.id !== goal.id ? g : g.status === goal.status && goal)).filter(Boolean),
  })),
  on(loadGoalSuccessAction, (state, { goal }) => ({
    ...state,
    activeGoal: goal,
  })),
  on(clearActiveGoal, (state) => ({
    ...state,
    activeGoal: null,
  })),
  on(loadTasksSuccessAction, (state, { tasks, goalId }) => ({
    ...state,
    tasks: { ...state.tasks, [goalId]: tasks },
  })),
  on(addTaskSuccessAction, (state, { task, goalId }) => ({
    ...state,
    tasks: { ...state.tasks, [goalId]: [task, ...(state.tasks?.[goalId] || [])] },
  })),
  on(editTaskSuccessAction, (state, { task, goalId }) => ({
    ...state,
    tasks: { ...state.tasks, [goalId]: state.tasks[goalId].map((t) => (t.id === task.id ? task : t)) },
    activeTask: state.activeTask?.id
      ? {
          ...state.activeTask,
          ...task,
        }
      : state.activeTask,
  })),
  on(loadHealthplansFailureAction, loadGoalsFailureAction, loadTasksFailureAction, (state, { error }) => ({
    ...state,
    error,
  })),
  on(addGoalCommentSuccessAction, (state, { goalId, comment }) => ({
    ...state,
    goals: state.goals.map((goal) =>
      goal.id === goalId ? { ...goal, comments: [...(goal.comments || []), comment] } : goal
    ),
    activeGoal: state.activeGoal?.id
      ? {
          ...state.activeGoal,
          comments: [...(state.activeGoal.comments || []), comment],
        }
      : state.activeGoal,
  })),
  on(updateGoalCommentSuccessAction, (state, { goalId, comment }) => ({
    ...state,
    goals: state.goals.map((goal) =>
      goal.id === goalId
        ? { ...goal, comments: (goal.comments || []).map((c) => (c.id === comment.id ? comment : c)) }
        : goal
    ),
    activeGoal: state.activeGoal?.id
      ? {
          ...state.activeGoal,
          comments: (state.activeGoal?.comments || []).map((c) => (c.id === comment.id ? comment : c)),
        }
      : state.activeGoal,
  })),
  on(deleteGoalCommentSuccessAction, (state, { goalId, commentId }) => ({
    ...state,
    goals: state.goals.map((goal) =>
      goal.id === goalId ? { ...goal, comments: goal?.comments?.filter((c) => c.id !== commentId) } : goal
    ),
    activeGoal: state.activeGoal?.id
      ? {
          ...state.activeGoal,
          comments: state.activeGoal?.comments?.filter((c) => c.id !== commentId),
        }
      : state.activeGoal,
  })),
  on(loadTaskSuccessAction, (state, { task }) => ({
    ...state,
    activeTask: task,
  })),
  on(clearActiveTaskAction, (state) => ({
    ...state,
    activeTask: null,
  })),
  on(addTaskCommentSuccessAction, (state, { comment }) => ({
    ...state,
    activeTask: {
      ...state.activeTask,
      comments: [...(state.activeTask?.comments || []), comment],
    },
  })),
  on(updateTaskCommentSuccessAction, (state, { comment }) => ({
    ...state,
    activeTask: {
      ...state.activeTask,
      comments: (state.activeTask?.comments || []).map((c) => (c.id === comment.id ? comment : c)),
    },
  })),
  on(deleteTaskCommentSuccessAction, (state, { commentId }) => ({
    ...state,
    activeTask: {
      ...state.activeTask,
      comments: state.activeTask?.comments?.filter((c) => c.id !== commentId),
    },
  }))
);

export const reducer = (state: HealthplansManagerState, action: Action): HealthplansManagerState => {
  return healthplansManagerReducer(state, action);
};
