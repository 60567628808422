import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-cross',
  templateUrl: './cross.component.svg',
})
export class CrossComponent extends MedrecordIcon {
  viewHeight = 8;
  viewWidth = 8;
}
