import { myDoctorManagerFeatureKey, State } from '../my-doctor-manager.reducer';
import { createSelector } from '@ngrx/store';
import { ResourceParameter, MappingResource } from '../../models/interfaces';

const getState = (state: State) => state[myDoctorManagerFeatureKey];

export const getResourcesModalOpenStatus = createSelector(
  getState,
  (state: State): boolean => state.resourcesModalOpenStatus
);

export const getModalSelectedResourcesKeys = createSelector(
  getState,
  (state: State): MappingResource[] => state.modalSelectedResourcesKeys
);

export const getResourceParameters = createSelector(
  getState,
  (state: State): ResourceParameter[] => state.resourceParameters
);

export const getParametersModalOpenStatus = createSelector(
  getState,
  (state: State): boolean => state.parametersModalOpenStatus
);

export const getTestCareproviderModalOpenStatus = createSelector(
  getState,
  (state: State): boolean => state.testCareproviderModalOpenStatus
);
