import { on } from '@ngrx/store';
import { deleteDataSuccessAction, setDeleteDataModalOpenStatusAction } from '../actions';
import { State } from '../my-doctor-manager.reducer';

export const commonReducer = [
  on(setDeleteDataModalOpenStatusAction, (state: State, { isOpen, isDataservice, resource }) => ({
    ...state,
    deleteDataModalOpenStatus: isOpen,
    deleteDataModalResource: resource,
    deleteDataModalIsDataservice: isDataservice,
  })),

  on(deleteDataSuccessAction, (state: State, { resourceKeys }) => ({
    ...state,
    filteredResourcesKeys: state.filteredResourcesKeys.filter(r => !resourceKeys.includes(r))
  }))
];
