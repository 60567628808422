import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-dashboard',
  templateUrl: './dashboard.component.svg',
})
export class DashboardComponent extends MedrecordIcon {
  viewHeight = 24;
  viewWidth = 24;
}
