export enum HealthplanStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  Completed = 'COMPLETED',
  Enteredinerror = 'ENTEREDINERROR',
  Cancelled = 'CANCELLED',
  Unknown = 'UNKNOWN',
  Null = 'NULL',
  Favorited = 'FAVORITED',
  All = 'ALL'
}
