<ejs-dialog [visible]="isModalOpen" [isModal]="true" (overlayClick)="closeModal()" width="60%">
  <ng-template #header>
    <div class="px-2">
      <span>{{ 'healthcare_providers_list' | translate }}</span>
    </div>
  </ng-template>

  <ng-template #content>
    <div class="px-2 mx-0">
      <span class="text-weight-600">{{ 'healthcare_providers_list_status_' + type | translate }}: &nbsp;</span>
      {{ status }}
    </div>
  </ng-template>

  <ng-template #footerTemplate>
    <div class="px-2 d-flex align-items-center justify-content-end">
      <button class="btn btn-secondary" (click)="closeModal()">
        {{ 'common_ok' | translate }}
      </button>
    </div>
  </ng-template>
</ejs-dialog>
