import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  PullTransaction,
  Resource,
  ResourceParameter,
  TransactionParameterName,
  TransactionParameterType
} from '@managers/my-doctor';
import { MedrecordMoment } from '@medrecord/services-datetime';
import { AbstractFormModalComponent } from '../abstract-form-modal/abstract-form-modal.component';

@Component({
  selector: 'doctor-gui-parameters-modal',
  templateUrl: './parameters-modal.component.html',
  styleUrls: ['./parameters-modal.component.scss']
})
export class ParametersModalComponent extends AbstractFormModalComponent<{ [key in Resource]?: { [name in TransactionParameterName]?: string } }> {
  @Input() set isModalOpen(isModalOpen: boolean) {
    this.modalOpen = isModalOpen;
    if (!this.modalOpen) {
      this.parameterGroups = [];
    }
  }

  @Input() transactionId: PullTransaction;

  @Input() set resourceParameters(resourceParameters: ResourceParameter[]) {
    this.initializeForm(resourceParameters);
    this.parameterGroups = resourceParameters;
  }

  transactionParameterType = TransactionParameterType;

  modalOpen = false;
  parameterGroups: ResourceParameter[] = [];

  constructor(
    private formBuilder: FormBuilder
  ) {
    super();
  }

  formSubmit(): void {
    this.modalSubmitted.emit(this.generateParametersPayload());
  }

  /**
   * Form is initialized with an object which:
   * has Resource keys as properties, and values are FormGroup where:
   * property in that FormGroup is TransactionParameterName and values are
   * FormControl, where one FormControl corresponds to one parameter
   */
  private initializeForm(resourceParameters: ResourceParameter[]): void {
    const formGroupsByResourceKeys: { [r in Resource]?: FormGroup } = {};
    resourceParameters
      .forEach(({ key, parameters }) => {
        // for this resourceKey convert all parameters to FormControls
        // they belong to the same group
        const formControlGroup: { [n in TransactionParameterName]?: FormControl } = {};
        parameters.forEach(param => {
          formControlGroup[param.name] = new FormControl(
            param.type === TransactionParameterType.date
              ? { date: param.defaultValue } // when type is date, default value is an object
              : param.defaultValue
          );
        });

        formGroupsByResourceKeys[key] = this.formBuilder.group(formControlGroup);
      });

    this.form = this.formBuilder.group(formGroupsByResourceKeys);
  }

  private generateParametersPayload(): { [key in Resource]?: { [name in TransactionParameterName]?: string } } {
    const result: { [key in Resource]?: { [name in TransactionParameterName]?: string } } = {};
    Object.entries(this.form.value).forEach(([key, value]) => {
      result[key] = this.formatParameterValues(value);
    });
    return result;
  }

  private formatParameterValues(parameters: { [n in TransactionParameterName]?: any } = {}): { [n in TransactionParameterName]?: string } {
    const result = { ...parameters };

    Object.entries(parameters).forEach(([key, value]) => {
      if (value?.date) {
        result[key] = MedrecordMoment(value?.date).format('YYYY-MM-DD');
      }
    });

    return result;
  }
}
