import { createAction, props } from '@ngrx/store';
import { CareproviderDataService } from '../../models/interfaces';
import { PullTransaction } from '../../models/enums';

export const setActiveDataserviceAction = createAction(
  '[My Doctor Manager Actions] Set Active Dataservice',
  props<{ dataservice: CareproviderDataService }>()
);

export const setActiveTransactionIdAction = createAction(
  '[My Doctor Manager Actions] Set Active Transaction Id',
  props<{ transactionId: PullTransaction }>()
);

export const redirectToAvailableDataserviceAction = createAction(
  '[My Doctor Manager Actions] Redirect To Available Dataservice'
);
