<form [formGroup]="codeForm" class="w-100">

  <medrecord-ui-input
    *ngIf="!isRecovery"
    formControlName="code"
    [type]="'text'"
    [max]="6"
    class="mt-3"
    type="text"
    [label]="'' | translate"
    [placeholder]="'tfa_enter_code_1' | translate">
  </medrecord-ui-input>

  <medrecord-ui-input
    *ngIf="isRecovery"
    formControlName="recoveryCode"
    [type]="'text'"
    [max]="17"
    class="mt-3"
    type="text"
    [label]="'' | translate"
    [placeholder]="'tfa_enter_code_2' | translate">
  </medrecord-ui-input>

  <medrecord-auth-twofa-action-buttons-container
    [primaryButtonLabel]="'common_next'"
    [primaryButtonType]="'submit'"
    (primaryButton)="submit()"
  >
  </medrecord-auth-twofa-action-buttons-container>
</form>
