<ng-container *ngIf="clientName$ | async as customerName">
  <div class="text-center">
    <h2 class="text-weight-600 text-primary mb-3">
      {{ 'auth_welcome_header' | translate }}
    </h2>

    <p class="text-left text-black">
      {{ 'auth_welcome_intro' | translateOrHide }}
    </p>

    <h3 class="mt-5 text-weight-500 mb-3 text-black text-lg">
      {{ 'auth_welcome_signin_description' | translate }}
    </h3>

    <button
      [class.btn-rounded]="isOAuth"
      class="btn btn-primary btn-lg btn-block text-weight-600"
      [routerLink]="!isOAuth && googleAuthDisabled ? ['login', 'credentials'] : ['login']"
      queryParamsHandling="merge"
    >
      {{ 'auth_welcome_signin_option' | translate }}
    </button>

    <ng-container *ngIf="!disableRegister">
      <h3 class="mt-5 text-black text-weight-500 mb-3 text-lg">
        {{ 'auth_welcome_signup_description' | translate: { customerName: customerName } }}
      </h3>
      <button
        [class.btn-rounded]="isOAuth"
        class="btn btn-outline-black btn-lg btn-block text-weight-600"
        [routerLink]="isOAuth ? ['signup'] : ['signup', 'role']"
        queryParamsHandling="merge"
      >
        {{ 'auth_welcome_signup_option' | translate }}
      </button>
    </ng-container>
  </div>
</ng-container>
