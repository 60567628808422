import { Component, Input, OnInit } from '@angular/core';
import {
  getTwofaCodesToRemember,
  getTwofaStep,
  handleTwofaNextStepAction,
  setTwofaFlowAction,
  TwofaFlow
} from '@medrecord/managers-auth';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'medrecord-auth-twofa-steps-container',
  templateUrl: './twofa-steps-container.component.html'
})
export class TwofaStepsContainerComponent implements OnInit {
  @Input() flow: TwofaFlow;

  step$: Observable<number> = this.store.select(getTwofaStep);
  codes$: Observable<string[]> = this.store.select(getTwofaCodesToRemember);

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(setTwofaFlowAction({ flow: this.flow }));
  }

  nextStep(): void {
    this.store.dispatch(handleTwofaNextStepAction());
  }
}
