import { NgModule } from '@angular/core';
import { provideQuestionnaireConstants } from './questionnaire.constants';
import { QuestionnaireTemplatesService } from './services/questionnaire-templates.service';
import { QuestionnairesScoresService } from './services/questionnaires-scores.service';
import { QuestionnairesService } from './services/questionnaires.service';
import { QuestionnairesInUseService } from './services/questionnaires-in-use.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { questionnairesFeatureName, questionnairesReducer } from './state-management/questionnaires.reducer';
import { QuestionnaireEffects } from './state-management/questionnaire.effects';
import { EnabledQuestionsPipe } from './models/pipes';

@NgModule({
  imports: [
    StoreModule.forFeature(questionnairesFeatureName, questionnairesReducer),
    EffectsModule.forFeature([QuestionnaireEffects]),
  ],
  declarations: [EnabledQuestionsPipe],
  providers: [
    provideQuestionnaireConstants(),
    QuestionnaireTemplatesService,
    QuestionnairesInUseService,
    QuestionnairesScoresService,
    QuestionnairesService,
  ],
  exports: [EnabledQuestionsPipe],
})
export class QuestionnaireManagerModule {}
