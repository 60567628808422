import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeatherService } from './services/weather.service';
import { WeatherManagerStateModule } from './state-management/weather-manager-state.module';

@NgModule({
  imports: [CommonModule, WeatherManagerStateModule],
  providers: [WeatherService],
})
export class WeatherManagerModule {}
