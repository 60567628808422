import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTranslations from './translations-management.reducer';
import { TranslationsManagementEffects } from './translations-management.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromTranslations.translationsFeatureKey, fromTranslations.reducer),
    EffectsModule.forFeature([TranslationsManagementEffects]),
  ],
})
export class TranslationsManagementStateModule {
}
