import { Component } from '@angular/core';
import { getTwofaFlow, submitTwofaCodeAction, TwofaFlow } from '@medrecord/managers-auth';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'medrecord-auth-twofa-enter-code-container',
  templateUrl: './twofa-enter-code-container.component.html',
  styleUrls: ['./twofa-enter-code-container.component.scss']
})
export class TwofaEnterCodeContainerComponent {
  flow$: Observable<TwofaFlow> = this.store.select(getTwofaFlow);

  constructor(private store: Store) {
  }

  submitCode(code: string): void {
    this.store.dispatch(submitTwofaCodeAction({ code }));
  }
}
