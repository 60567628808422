import { Inject, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectCurrentID } from '@medrecord/managers-users';
import { Action, Store } from '@ngrx/store';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';
import {
  fetchResourcesAction,
  fetchResourcesCompletedAction,
  loadResourcesAction,
  navigateToCareproviderAction,
  performAuthorizationForCareproviderAction,
  setFilteredResourcesKeysAction,
  setParametersModalOpenStatusAction,
  setResourcesModalOpenStatusAction,
  setTestCareproviderModalOpenStatusAction
} from '../actions';
import { MY_DOCTOR_MANAGER_CONSTANTS } from '../../my-doctor-manager.tokens';
import { MyDoctorManagerConstants } from '../../constants';
import {
  getActiveCareproviderId,
  getActiveDataserviceId,
  getAfterFetchingResourcesReturnTo,
  getFetchResourcesRequestPayload,
  getModalSelectedResourcesKeys
} from '../selectors';
import { MyDoctorManagerService } from '../../services/my-doctor-manager.service';
import { ToastModel } from '@syncfusion/ej2-angular-notifications';

@Injectable()
export class MedmijInteractionEffects {

  @Effect()
  fetchResources$ = this.actions$.pipe(
    ofType(fetchResourcesAction),
    withLatestFrom(
      this.store.select(selectCurrentID),
      this.store.select(getActiveCareproviderId),
      this.store.select(getFetchResourcesRequestPayload),
      this.store.select(getActiveDataserviceId),
      this.store.select(getModalSelectedResourcesKeys),
      this.store.select(getAfterFetchingResourcesReturnTo)
    ),
    mergeMap(([, userId, careproviderId, fetchResourceRequest, dataserviceId, resourceKeys, afterFetchingResourcesReturnTo]) =>
      this.service.fetchResources(userId, careproviderId, fetchResourceRequest).pipe(
        switchMap((resourcesResponse) => {
          const actions: Action[] = [
            setResourcesModalOpenStatusAction({ isOpen: false }),
            setParametersModalOpenStatusAction({ isOpen: false }),
            setTestCareproviderModalOpenStatusAction({ isOpen: false }),
            fetchResourcesCompletedAction({ responsePayload: resourcesResponse.resources }),
          ];
          const error = resourcesResponse.resources.find((r) => r.error && r.errorHttpCode === 401);

          if (error) {
            if (!this.service.performedAuthorizationOnce && !this.service.isTest(careproviderId)) {
              // if it is 401 error, and happens for the first time, try to authorize
              actions.push(
                performAuthorizationForCareproviderAction()
              );
            } else {
              this.service.performedAuthorizationOnce = false;
              actions.push(
                addErrorToast(getErrorToastBodyUtil(this.translateService.instant('fetch_data_error'), error as any))
              );
            }
          } else {
            this.service.performedAuthorizationOnce = false;
            actions.push(
              setFilteredResourcesKeysAction({ resourceKeys }),
              loadResourcesAction(),
              navigateToCareproviderAction({
                careproviderId: careproviderId,
                dataserviceId: dataserviceId,
                detailsRoute: afterFetchingResourcesReturnTo
              })
            );
          }
          return actions;
        })
      ))
  );

  @Effect()
  performAuthorizationForCareprovider$ = this.actions$.pipe(
    ofType(performAuthorizationForCareproviderAction),
    withLatestFrom(
      this.store.select(selectCurrentID),
      this.store.select(getActiveCareproviderId),
      this.store.select(getActiveDataserviceId)
    ),
    mergeMap(([, userId, careproviderId, dataserviceId]) => {
      const successCallBack = () => {
        this.service.performedAuthorizationOnce = true;
        this.store.dispatch(
          addSuccessToast({
            title: 'common_success',
            content: ''
          })
        );
        this.store.dispatch(fetchResourcesAction());
      };
      const failureCallBack = () => {
        this.store.dispatch(
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('fetch_data_error'), null))
        );
      };

      window.addEventListener(this.myDoctorConstants.digIdSuccess, successCallBack);
      window.addEventListener(this.myDoctorConstants.digIdFailure, failureCallBack);
      return this.service.loadDigIdUrl(userId, careproviderId, dataserviceId).pipe(
        switchMap(data => {
          localStorage.setItem('careproviderId', careproviderId);
          localStorage.setItem('dataserviceId', dataserviceId);
          localStorage.setItem('authEndpointUrl', data.authEndpointUrl);
          localStorage.setItem('correlationId', data.correlationId);
          localStorage.setItem('medMijRequestId', data.medMijRequestId);
          const newWin = window.open(data.authEndpointUrl, 'digIdCheck', 'width=900,height=700');
          const interval = setInterval(() => {
            if (newWin.closed) {
              window.removeEventListener(this.myDoctorConstants.digIdSuccess, successCallBack);
              window.removeEventListener(this.myDoctorConstants.digIdFailure, failureCallBack);
              clearInterval(interval);
            }
          }, 100);
          return [];
        }),
        catchError((error: any) => {
          this.service.performedAuthorizationOnce = false;
          const errorToastTitle = this.translateService.instant('fetch_data_error');
          const toastErrorBody: ToastModel = error?.status === 451
            ? { title: errorToastTitle, content: this.translateService.instant('my_doctor_didnt_pass_check_error') }
            : getErrorToastBodyUtil(errorToastTitle, error?.error || error);
          return [
            addErrorToast(toastErrorBody)
          ];
        })
      );
    })
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private service: MyDoctorManagerService,
    private translateService: TranslateService,
    @Inject(MY_DOCTOR_MANAGER_CONSTANTS) private myDoctorConstants: MyDoctorManagerConstants
  ) {
  }
}
