import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-height',
  templateUrl: './height.component.svg',
  styleUrls: [],
})
export class HeightComponent extends MedrecordIcon {
  viewHeight = 24;
  viewWidth = 24;
}
