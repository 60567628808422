import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';

@Injectable()
export class CoachingErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any): void {
    const store = this.injector.get(Store);
    const logger = this.injector.get(NGXLogger);

    logger.error(error);

    if (error.status !== 401) return store.dispatch(addErrorToast(getErrorToastBodyUtil(error.name || 'Error', error)));
  }
}
