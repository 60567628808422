<div class="form-group">
  <medrecord-ui-field-label
    for="autocomplete"
    [text]="label"
    type="black"
    [asterix]="asterix"
  ></medrecord-ui-field-label>
  <ejs-autocomplete
    [allowCustom]="true"
    [value]="autocompleteValue"
    [showPopupButton]="showPopupButton"
    #autocomplete
    [dataSource]="dataSource"
    (filtering)="onFiltering($event)"
    [placeholder]="placeholder"
    (select)="onSelect($event)"
    (change)="onValueChange($event)"
    [fields]="fields"
    (open)="onOpenChange($event)"
    sortOrder="Ascending"
  ></ejs-autocomplete>

  <medrecord-ui-field-error
    [errors]="controlErrorsList"
    [errorCode]="errorCode"
    [externalErrors]="externalErrorsList"
  ></medrecord-ui-field-error>
</div>
