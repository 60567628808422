import { createAction, props } from '@ngrx/store';
import { featurePrefix } from './feature-prefix';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { QuestionnaireScheduleRequestModel } from '../../models/interfaces';

export const loadQuestionnaireTemplates = createAction(
  `${featurePrefix} Load Questionnaire Templates`,
  props<{ pageSettings: PageSettingsModel }>()
);

export const loadQuestionnaireTemplatesSuccess = createAction(
  `${featurePrefix} Questionnaire Templates Loaded Successfully`,
  props<{
    questionnaireTemplates: any[];
    questionnaireTemplatesCount: number;
  }>()
);

export const loadQuestionnaireTemplatesFailure = createAction(
  `${featurePrefix} Questionnaire Templates Load Failure`,
  props<{
    error: any;
  }>()
);

export const scheduleQuestionnaireAction = createAction(
  `${featurePrefix} Schedule Questionnaire`,
  props<{
    value: QuestionnaireScheduleRequestModel;
  }>()
);

export const scheduleQuestionnaireSuccessAction = createAction(`${featurePrefix} Schedule Questionnaire Success`);
