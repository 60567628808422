import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-list',
  templateUrl: './list.component.svg',
})
export class ListComponent extends MedrecordIcon {
  viewHeight = 18;
  viewWidth = 15;
}
