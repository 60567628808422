import { Component, Input, Type } from '@angular/core';
import { CellType, ColumnDefinition, TableCell } from '@managers/my-doctor';
import { AllergyHandComponent, MedicationAlertComponent, MedrecordIcon } from '@medrecord/icons';
import { EffectiveDate } from '@medrecord/services-datetime';

@Component({
  selector: 'doctor-gui-table-cell',
  templateUrl: './table-cell.component.html'
})
export class TableCellComponent {
  @Input() column: ColumnDefinition<string>;
  @Input() data: TableCell<string>;

  asString(val: CellType | TableCell<string>): string {
    return val as string;
  }

  asDate(val: CellType | TableCell<string>): EffectiveDate {
    return val as EffectiveDate;
  }

  asRange(val: CellType | TableCell<string>): { start: Date, end: Date } {
    return val as { start: Date, end: Date };
  }

  asDuration(val: CellType | TableCell<string>): { period: string, unit: string } {
    return val as { period: string, unit: string };
  }

  getIconComponent(component: string): Type<MedrecordIcon> {
    switch (component) {
      case 'MedicationAlertComponent':
        return MedicationAlertComponent;
      case 'AllergyHandComponent':
        return AllergyHandComponent;
      default:
        throw Error('Icon component: "' + component + '" not supported!');
    }
  }
}
