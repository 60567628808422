import { Component } from '@angular/core';
import { StorageKeys } from '@medrecord/core';
import { StorageService } from '@medrecord/tools-storage';

@Component({
  selector: 'medrecord-auth-success-restored',
  templateUrl: './success-restored.component.html',
})
export class SuccessRestoredComponent {
  readonly isOAuth = this.storage.getItem<boolean>(StorageKeys.isOAuth, false);

  constructor(private storage: StorageService<StorageKeys>) {}
}
