import * as Core from '@angular/core';

@Core.Component({
  selector: 'medrecord-selected-box',
  templateUrl: './selected-box.component.html',
  styleUrls: ['./selected-box.component.scss']
})
export class SelectedBoxComponent {
  @Core.Input() data: any[];
  @Core.ContentChild('item') itemTemplate: Core.TemplateRef<any>;
  
  @Core.Output() dataChanged = new Core.EventEmitter();
  
  hoveredItemIndex = -1;
  
  deleteItem(itemIndex: number): void {
    this.data.splice(itemIndex, 1);
  }
  
  onHover(itemIndex: number): void {
    this.hoveredItemIndex = itemIndex;
  }
  
  onBlur(): void {
    this.hoveredItemIndex = -1;
  }
}
