import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MedrecordIconsModule } from '@medrecord/icons';
import { MedrecordLabelComponent } from './components/medrecord-label/medrecord-label.component';
import { MedrecordBricksComponent } from './components/medrecord-bricks/medrecord-bricks.component';
import { MedrecordCheckboxComponent } from './components/medrecord-checkbox/medrecord-checkbox.component';
import { MedrecordHintComponent } from './components/medrecord-hint/medrecord-hint.component';
import { MedrecordInputNgControlComponentBase } from './base/medrecord-input-ng-control-component-base.component';
import { MedrecordRepeatableFormComponent } from './components/medrecord-repeatable-form/medrecord-repeatable-form.component';
import { MedrecordDatepickerComponent } from './components/medrecord-datepicker/medrecord-datepicker.component';
import { MedrecordAvatarUploadComponent } from './components/medrecord-avatar-upload/medrecord-avatar-upload.component';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import { ValidationService } from '@medrecord/services-form-validation';
import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { MedrecordDatepickerPrecisionComponent } from './components/medrecord-datepicker-precision/medrecord-datepicker-precision.component';
import { MedrecordDatetimeModule } from '../../../../services/datetime/src';

const exportedComponents = [
  MedrecordLabelComponent,
  MedrecordBricksComponent,
  MedrecordCheckboxComponent,
  MedrecordHintComponent,
  MedrecordRepeatableFormComponent,
  MedrecordInputNgControlComponentBase,
  MedrecordDatepickerComponent,
  MedrecordAvatarUploadComponent,
  MedrecordDatepickerPrecisionComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MedrecordIconsModule,
    AlifeFileToBase64Module,
    DatePickerAllModule,
    TooltipAllModule,
    MedrecordDatetimeModule,
  ],
  declarations: [...exportedComponents],
  exports: [...exportedComponents],
  providers: [ValidationService],
})
export class MedrecordFormsModule {
  static forRoot(): ModuleWithProviders<MedrecordFormsModule> {
    return {
      ngModule: MedrecordFormsModule,
      providers: [ValidationService],
    };
  }
}
