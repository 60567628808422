import { createFeatureSelector, createSelector } from '@ngrx/store';
import { usersManagementFeatureKey, UsersManagementState } from './users-management.reducer';
import { IUserItemModel } from '../models/interfaces/user-item.interface';

export const getUsersManagementState = createFeatureSelector<UsersManagementState>(usersManagementFeatureKey);

export const getUsersData = createSelector(
  getUsersManagementState, (state: UsersManagementState): IUserItemModel[] => state.users || []
);

export const getUsersCount = createSelector(
  getUsersManagementState, (state: UsersManagementState): number => state.usersCount
);

export const getUsersDataLoading = createSelector(
  getUsersManagementState, (state: UsersManagementState): boolean => state.usersLoading
);

export const getUserActionLoading = createSelector(
  getUsersManagementState, (state: UsersManagementState): boolean => state.userActionLoading
);
