<doctor-gui-delete-data-modal
  [isModalOpen]="isModalOpen$ | async"
  [deleteModalData]="{
    careprovider: careprovider$ | async,
    isDeleteDataservice: isDeleteDataservice$ | async,
    dataserviceId: dataserviceId$ | async,
    resource: resource$ | async
  }"
  (modalSubmitted)="onModalSubmit($event)"
  (modalClosed)="setModalClosed()"
></doctor-gui-delete-data-modal>