import { on } from '@ngrx/store';
import {
  loadCareprovidersSuccessAction, loadLogsSuccessAction,
  setActiveCareproviderAction, setLogsModalOpenStatusAction,
  setNavigatedToCareproviderDetailsFromAction
} from '../actions';
import { State } from '../my-doctor-manager.reducer';

export const careproviderReducer = [
  on(loadCareprovidersSuccessAction, (state: State, { careproviders, count }) => ({
    ...state,
    careproviders,
    careprovidersCount: count
  })),

  on(setActiveCareproviderAction, (state: State, { careprovider }) => ({
    ...state,
    activeCareprovider: careprovider
  })),

  on(setNavigatedToCareproviderDetailsFromAction, (state: State, { from }) => ({
    ...state,
    navigatedToCareproviderDetailsFrom: from
  })),

  on(loadLogsSuccessAction, (state: State, { logs }) => ({
    ...state,
    logs
  })),

  on(setLogsModalOpenStatusAction, (state: State, { isOpen }) => ({
    ...state,
    logsModalOpenStatus: isOpen
  })),
];
