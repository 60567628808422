import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-success',
  templateUrl: './success.component.svg',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent extends MedrecordIcon {
  viewHeight = 32;
  viewWidth = 32;
}
