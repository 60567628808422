import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns/src/drop-down-base/drop-down-base-model';
import { FloatLabelType } from '@syncfusion/ej2-inputs';
import * as ejData from '@syncfusion/ej2-data';

@Component({
  selector: 'medrecord-ejs-medrecord-multiselect',
  templateUrl: './ejs-medrecord-multiselect.component.html',
})
export class EjsMedrecordMultiselectComponent {
  @Input() cssClass: string;
  @Input() placeholder: string;
  @Input() floatLabelType: FloatLabelType;
  @Input() fields: FieldSettingsModel;
  @Input() dataSource: ejData.DataManager;
  @Input() value: string[];

  @Output() valueChange = new EventEmitter();
}
