export class AddressUtil {

  /**
   * Concatenates:
   * A) street name and street number with space (if both exist)
   * B) postal code and city with space (if both exist)
   * Lastly, concatenates A, B and country with comma space
   */
  static toOneLine(streetName: string, streetNumber: string, postalCode: string, city: string, country: string): string {
    return [
      [streetName, streetNumber],
      [postalCode, city],
      [country]
    ].map(addressLine => addressLine.filter(a => a).join(' '))
      .filter(addressLine => addressLine)
      .join(', ');
  }
}