import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvService, ObservationType } from '@medrecord/core';
import { ObservationPayload, SettingsResponse } from '@managers/measurements';
import { camelCase } from 'lodash';
import { Observation } from '../models/interfaces/obseration.interface';

@Injectable()
export class ObservationsService {
  constructor(private http: HttpClient, private envService: EnvService) {}

  getObservations(userId: string, observationType: ObservationType, carePlanId?: string): Observable<Observation[]> {
    const queryParams = carePlanId ? `?carePlanId=${carePlanId}` : '';
    const endpointUrl = `${this.envService.backend}/patient/${userId}/observation/${
      observationType === ObservationType.cPeptide ? 'cpeptide' : camelCase(observationType)
    }${queryParams}`;
    return this.http.get<Observation[]>(`${endpointUrl}`);
  }

  getQuantityObservations(
    userId: string,
    observationType: ObservationType,
    carePlanId?: string
  ): Observable<Observation[]> {
    const queryParams = carePlanId ? `?carePlanId=${carePlanId}` : '';
    return this.http.get<Observation[]>(
      `${this.envService.backend}/patient/${userId}/observation/quantity/${observationType}${queryParams}`
    );
  }

  createObservation(userId: string, observationType: ObservationType, payload: ObservationPayload): Observable<any> {
    // Only `cPeptide` observation type has different endpoint url setup
    const endpointUrl = `${this.envService.backend}/patient/${userId}/observation/${
      observationType === ObservationType.cPeptide ? 'cpeptide' : camelCase(observationType)
    }`;
    return this.http.post<any>(endpointUrl, {
      ...payload,
    });
  }

  createQuantityObservation(userId: string, payload: ObservationPayload): Observable<any> {
    return this.http.post<any>(`${this.envService.backend}/patient/${userId}/observation/quantity`, payload);
  }

  deleteObservations(userId: string, observationId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.backend}/patient/${userId}/observation/${observationId}`);
  }

  getAllowedObservationTypes(userId: string, careplanId?: string): Observable<SettingsResponse> {
    const queryParams = careplanId ? `?carePlanId=${careplanId}` : '';
    return this.http.get<SettingsResponse>(`${this.envService.backend}/patient/${userId}/settings${queryParams}`);
  }

  setAllowedObservationTypes(
    userId: string,
    observationTypes: ObservationType[],
    carePlanId?: string
  ): Observable<any> {
    return this.http.put<any>(`${this.envService.backend}/patient/${userId}/settings`, {
      settings: { observationTypes, carePlanId },
    });
  }

  setCareplanObservationTypes(
    userId: string,
    observationTypes: ObservationType[],
    carePlanId: string
  ): Observable<any> {
    return this.http.post<any>(`${this.envService.backend}/patient/${userId}/settings`, {
      settings: { observationTypes, carePlanId },
    });
  }

  updateQuantityObservation(userId: string, payload: ObservationPayload): Observable<any> {
    return this.http.put<any>(
      `${this.envService.backend}/patient/${userId}/observation/${payload.id}/quantity`,
      payload
    );
  }
}
