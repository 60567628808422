export enum BodyPart {
  general = 'GENERAL',
  head = 'HEAD',
  neck = 'NECK',
  chest = 'CHEST',
  armLeft = 'ARM_LEFT',
  armRight = 'ARM_RIGHT',
  abdomen = 'ABDOMEN',
  handLeft = 'HAND_LEFT',
  handRight = 'HAND_RIGHT',
  pelvis = 'PELVIS',
  legLeft = 'LEG_LEFT',
  legRight = 'LEG_RIGHT',
  feetLeft = 'FEET_LEFT',
  feetRight = 'FEET_RIGHT',
  back = 'BACK',
  buttocks = 'BUTTOCKS',
}
