<div class="d-flex flex-column align-items-center">
  <p class="text-center">{{ title | translate }}</p>

  <medrecord-auth-twofa-enter-code-container></medrecord-auth-twofa-enter-code-container>

  <p class="text-center">{{ lostStuffDescription | translate }}
    <a
      (click)="clickHereClick()"
      class="font-weight-bold text-lg cursor-pointer"
    >
      {{ 'common_click_here' | translate }}
    </a>
  </p>
</div>
