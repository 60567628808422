import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { newsManagerFeatureKey, reducer } from './news-manager.reducer';
import { EffectsModule } from '@ngrx/effects';
import { NewsManagerEffects } from './news-manager.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(newsManagerFeatureKey, reducer),
    EffectsModule.forFeature([ NewsManagerEffects ])
  ]
})
export class NewsManagerStateModule {}