import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-step-counter',
  templateUrl: './step-counter.component.svg',
  styleUrls: [],
})
export class StepCounterComponent extends MedrecordIcon {
  viewHeight: number = 24;
  viewWidth: number = 24;
}
