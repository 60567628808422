import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-blood-glucose',
  templateUrl: './blood-glucose.component.svg',
  styleUrls: [],
})
export class BloodGlucoseComponent extends MedrecordIcon {
  viewHeight: number = 24;
  viewWidth: number = 24;
}
