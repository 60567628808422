import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { ErrorCode } from '@medrecord/core';
import { TranslateService } from '@ngx-translate/core';
import { Item } from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'medrecord-ui-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class TimepickerComponent implements OnInit {

  @Input() controlName: string;
  @Input() externalErrors: ValidationErrors = {};
  @Input() errorCode: ErrorCode;
  @Input() set minTime(value: Date) {
    this.populateTimepickerList(value);
  }

  control;
  readonly watermark = this.translateService.instant('common_select_time');
  readonly formatString: string = 'HH:mm';
  readonly interval: number = 5;
  timepickerList: Item[] = [];

  constructor(
    private translateService: TranslateService,
    private controlContainer: ControlContainer,
    private parentForm: FormGroupDirective,
    private datePipe: DatePipe)
  { }

  ngOnInit() {
    this.control = this.parentForm.form.get(this.controlName);

    if (!this.controlContainer.control.get(this.controlName)) {
      throw new Error(`Control '${this.controlName}' is not found in the parent form group`);
    }

  }

  get controlErrorsList(): string[] {
    return ((this.control?.dirty || this.control?.touched) && this.errors) || [];
  }

  get externalErrorsList(): string[] {
    return ((this.control?.dirty || this.control?.touched) && Object.values((this.externalErrors || {}))) || [];
  }

  get errors(): string[] {
    return Object.values(this.control?.errors || {}) || [];
  }

  private populateTimepickerList(minTime: Date): void {
    this.timepickerList = [];
    let currentTime = new Date(minTime.getTime());

    if (currentTime.getMinutes() % this.interval !== 0) {
      currentTime.setMinutes(currentTime.getMinutes() + (this.interval - currentTime.getMinutes() % this.interval));
    }

    while (currentTime < new Date(minTime.getFullYear(), minTime.getMonth(), minTime.getDate(), 23, 59)) {
      this.timepickerList.push({ id: this.datePipe.transform(currentTime, 'HH:mm'), text: this.datePipe.transform(currentTime, 'HH:mm') } as Item);
      currentTime = new Date(currentTime.getTime() + this.interval * 60000); // Add interval minutes
    }
  }
}
