import { createAction, props } from '@ngrx/store';
import { UserStatusEntity } from '../models/user-status-entity.interface';

export const loadUserStatusAction = createAction(
  '[WaitingPatients] Load User Status',
  props<{ userId: string }>()
);

export const loadUserStatusSuccessAction = createAction(
  '[WaitingPatients] Load User Status Success',
  props<{ userStatus: UserStatusEntity }>()
);

export const loadUserStatusFailureAction = createAction(
  '[WaitingPatients]  Load User Status Failure',
  props<{ error: any }>()
);
