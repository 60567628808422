import { createAction, props } from '@ngrx/store';
import { NetworkOrganisation } from '../models/network-organisation.interface';
import { NetworkOrganisationPractitioner } from '../models/network-organisation-practitioner.interface';
import { NetworkOrganisationPractitionerUpdate } from '../models/network-organisation-practitioner-update.interface';
import { NetworkPatient } from '@coaching/doctor-network';

export const loadNetworkOrganisationsAction = createAction(
  '[Network Organisations] Load Organisations',
  props<{ start?: number, count?: number }>()
);

export const networkOrganisationsActionLoading = createAction(
  '[Network Organisations] Organisations Loading',
  props<{ loading: boolean }>()
);

export const loadNetworkOrganisationsSuccessAction = createAction(
  '[Network Organisations] Load Organisations Success',
  props<{ organisations: NetworkOrganisation[] }>()
);

export const networkOrganisationsFailureAction = createAction(
  '[Network Organisations] Load Organisations Failure',
  props<{ error: any }>()
);

export const getNetworkOrganisationAction = createAction(
  '[Network Organisations] Get Organisation',
  props<{ id: string }>()
);

export const getNetworkOrganisationSuccessAction = createAction(
  '[Network Organisations] Get Organisation Success',
  props<{ organisation: NetworkOrganisation }>()
);

export const addNetworkOrganisationAction = createAction(
  '[Network Organisations] Add Organisation',
  props<{ organisation: NetworkOrganisation }>()
);

export const addNetworkOrganisationSuccessAction = createAction(
  '[Network Organisations] Add Organisation Success',
  props<{ organisation: NetworkOrganisation }>()
);

export const editNetworkOrganisationAction = createAction(
  '[Network Organisations] Edit Organisation',
  props<{ organisation: NetworkOrganisation }>()
);

export const editNetworkOrganisationSuccessAction = createAction(
  '[Network Organisations] Edit Organisation Success',
  props<{ organisation: NetworkOrganisation }>()
);

export const deleteNetworkOrganisationAction = createAction(
  '[Network Organisations] Delete Organisation',
  props<{ id: string }>()
);

export const deleteNetworkOrganisationSuccessAction = createAction(
  '[Network Organisations] Delete Organisation Success',
  props<{ id: string }>()
);

export const getPractitionersAction = createAction(
  '[Network Organisations] Get Practitioners',
  props<{ id: string }>()
);

export const getPractitionersSuccessAction = createAction(
  '[Network Organisations] Get Practitioners Success',
  props<{ practitioners: NetworkOrganisationPractitioner[] }>()
);

export const deletePractitionerAction = createAction(
  '[Network Organisations] Delete Practitioner',
  props<{ id: string, organisationId: string }>()
);

export const deletePractitionerSuccessAction = createAction(
  '[Network Organisations] Delete Practitioner Success',
  props<{ id: string }>()
);

export const cleanSelectedOrganisationAction = createAction(
  '[Network Organisations] Clean Selected Organisation'
);

export const updatePractitionerRoleInOrganisationAction = createAction(
  '[Network Organisations] Update Practitioner Roles',
  props<{ payload: NetworkOrganisationPractitionerUpdate, organisationId: string }>()
);

export const updatePractitionerRoleInOrganisationSuccessAction = createAction(
  '[Network Organisations] Update Practitioner Roles Success',
  props<{ payload: NetworkOrganisationPractitionerUpdate }>()
);

export const bulkUpdatePractitionerRoleInOrganisationAction = createAction(
  '[Network Organisations] Bulk Update Practitioner Roles',
  props<{ payload: NetworkOrganisationPractitionerUpdate[], organisationId: string }>()
);

export const bulkUpdatePractitionerRoleInOrganisationSuccessAction = createAction(
  '[Network Organisations] Bulk Update Practitioner Roles Success',
  props<{ payload: any[] }>()
);

export const getAllPractitionersAction = createAction(
  '[Network Organisations] Get All Practitioners',
  props<{ name: string }>()
);

export const getAllPractitionersSuccessAction = createAction(
  '[Network Organisations] Get All Practitioners Success',
  props<{ practitioners: NetworkPatient[] }>()
);

export const addParticipantToOrganisationAction = createAction(
  '[Network Organisations] Add Participant to Organisation',
  props<{ participant: NetworkOrganisationPractitionerUpdate, organisationId: string }>()
);

export const addParticipantToOrganisationSuccessAction = createAction(
  '[Network Organisations] Add Participant to Organisation Success',
  props<{ participant: NetworkOrganisationPractitionerUpdate }>()
);

export const clearPractitionersResultsAction = createAction(
  '[Network Organisations] Clear Participants'
);

export const getCurrentUserOrganisations = createAction(
  '[Network Organisations] Current User Organisation',
  props<{ userId: string, start?: number, count?: number }>()
);

export const getCurrentUserOrganisationsSuccess = createAction(
  '[Network Organisations] Current User Organisation Success',
  props<{ organisations: any }>()
);

export const loadAllOrganisationsCountAction = createAction(
  '[User Management Actions] Load All Organisations Count'
);

export const loadAllOrganisationsCountActionSuccess = createAction(
  '[User Management Actions] Load All Organisations Count Success',
  props<{ count:number }>()
);