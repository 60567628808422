import { Directive, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FieldsSettingsModel, NodeSelectEventArgs } from '@syncfusion/ej2-angular-navigations';
import { StorageKeys } from '@medrecord/core';
import { getLevenshteinMatch } from '@medrecord/tools-utils';
import { SidebarItem, SimpleSidebarItem } from '../models/interfaces/sidebar-item.interface';
import { Observable } from 'rxjs';
import { getSelectedUser, getUserDataById, User } from '@medrecord/managers-users';
import { getCustomerInnerLogo, getCustomerLogoMinimal } from '@medrecord/services-customer-metadata';
import { Store } from '@ngrx/store';
import { StorageService } from '@medrecord/tools-storage';
import { logoutEverywhere } from '@medrecord/managers-auth';
import { GeneralRouteNames } from '@medrecord/routes-general';

@Directive()
export abstract class WrapperWithSidebar implements OnInit {
  user$: Observable<User>;
  profileData$: Observable<User>;
  logotype$: Observable<string>;
  logotypeMinimal$: Observable<string>;
  
  private _selectedNodeID: string;
  private _focusedItem: SidebarItem;
  
  expandedNodeID: string;
  disabledNodes: string[];
  
  abstract menuItems: SidebarItem[];
  
  get nodeId(): string {
    return this._selectedNodeID;
  }
  
  field: FieldsSettingsModel;
  
  constructor(
    protected router: Router,
    protected store: Store,
    private entryRoute: string,
    private generalRouteNames: GeneralRouteNames,
    private storage: StorageService<StorageKeys>
  ) {}
  
  ngOnInit(): void {
    this.user$ = this.store.select(getSelectedUser);
    this.profileData$ = this.store.select(getUserDataById(this.storage.getItem(StorageKeys.Auth).userId));
    this.logotype$ = this.store.select(getCustomerInnerLogo);
    this.logotypeMinimal$ = this.store.select(getCustomerLogoMinimal);
    
    this.field = {
      dataSource: this.menuItems as any
    };
  
    this.defineFocusedItem();
    this.defineInitialSelectedItem();
    this.defineDisabledNodes();
  
    this.expandedNodeID = this._focusedItem.id;
  }
  
  getId(...routeParts: string[]): string {
    return [this.entryRoute, ...routeParts].join('/');
  }
  
  onNodeIdChanged(item: NodeSelectEventArgs): Promise<boolean> {
    return this.router.navigateByUrl(item.nodeData.id as string);
  }
  
  didSelectDropdownItem(action: string): void {
    switch (action) {
      case "goToProfile":
        this.router.navigate([
          this.entryRoute,
          this.generalRouteNames.Profile.Entry
        ]);
        break;
      case "goToPatient":
        this.router.navigate([
          this.generalRouteNames.Patient.Entry
        ]);
        break;
      case "goToDoctor":
        this.router.navigate([
          this.generalRouteNames.Doctor.Entry
        ]);
        break;
      case "goToSettings":
        this.router.navigate([
          this.entryRoute,
          this.generalRouteNames.Settings.Entry
        ]);
        break;
      case "provideFeedback":
        break;
      case "logout":
        this.store.dispatch(logoutEverywhere());
        break;
      default:
        break;
    }
  }
  private defineFocusedItem(): void {
    const match = getLevenshteinMatch(this.router.url, this.menuItems.map((item) => item.id));
    
    this._focusedItem = this.menuItems.find((item) => match === item.id);
  }
  
  private defineDisabledNodes(): void {
    const getDisabledItems = (items: Array<SimpleSidebarItem|SidebarItem>) => {
      return items.reduce((acc, item) => {
        if (item.disabled) {
          acc.push(item.id);
        }
    
        if (!item.disabled && (item as SidebarItem).child) {
          acc.push(...getDisabledItems((item as SidebarItem).child));
        }
    
        return acc;
      }, []);
    };
    
    this.disabledNodes = getDisabledItems(this.menuItems);
  }
  
  private defineInitialSelectedItem(): void {
    this._selectedNodeID = getLevenshteinMatch(
      this.router.url,
      [this._focusedItem.id, ...(this._focusedItem.child || []).map((item) => item.id)]
    );
  }
}