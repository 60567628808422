<div *ngIf="addresses && addresses.length">
  <h3 class="text-black text-weight-600 mb-3">{{ 'profile_addresses' | translate }}</h3>

  <div class="w-100 d-flex flex-column ml-5">
    <div class="d-flex w-100 mb-2" *ngFor="let address of addresses">
      <div class="address-label text-weight-600">{{ address | getAddressLabel }}</div>
      <div class="address-value text-weight-500">
        {{ address.streetName ? address.streetName + ' ' : ''}} 
        {{ address.houseNumber ? address.houseNumber + ' ' : ''}}
        {{ address.buildingNumberSuffix ? address.buildingNumberSuffix + ' ' : ''}}
        {{ address.city ? address.city + ' ' : ''}}
        {{ address.postalCode ? address.postalCode + ' ' : ''}}
      </div>
    </div>
  </div>
</div>