import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { MeasurementsManagerEffects } from './measurements-manager.effects';
import { StoreModule } from '@ngrx/store';
import { measurementsManagerFeatureKey, reducer } from './measurements-manager.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(measurementsManagerFeatureKey, reducer),
    EffectsModule.forFeature([
      MeasurementsManagerEffects
    ])
  ]
})
export class MeasurementsManagerStateModule {}