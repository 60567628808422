<div class="py-3 px-2 section-title">
  <h4 class="text-black m-0">{{ table.title | translate }}</h4>
</div>
<ng-container *ngIf="table; else noData">
  <ng-container *ngFor="let group of table.groups; let isLast=last">
    <doctor-gui-expandable-container [title]="group.groupName" [active]="group.active" [hideBottomBorder]="!isLast" [hideTitle]="table.noGroups">
      <ejs-grid #grid [dataSource]="group.rows" [id]="'grid-' + group.groupName" [allowResizing]="false">
        <e-columns>
          <e-column
            *ngFor="let column of table.columns"
            [field]="column.property"
            [headerText]="column.title | translate"
            [minWidth]="column.width + '%'"
            [width]="column.width + '%'"
          >
            <ng-template #template let-data>
              <doctor-gui-table-cell [data]="data" [column]="column"></doctor-gui-table-cell>
            </ng-template>
          </e-column>
        </e-columns>
        <ng-container *ngIf="table.collapsibleTables">
          <ng-template #detailTemplate let-data>
            <ng-container *ngFor="let collapsibleTable of table.collapsibleTables">
              <ng-container *ngIf="data[collapsibleTable.property] && data[collapsibleTable.property].length > 0">
                <div>
                  <h5 class="text-sm m-0 font-weight-bold">{{ collapsibleTable.title | translate }}</h5>
                </div>
                <div class="mt-1">
                  <ejs-grid #grid [dataSource]="data[collapsibleTable.property]" [allowResizing]="false">
                    <e-columns>
                      <e-column
                        *ngFor="let column of collapsibleTable.columns"
                        [field]="column.property"
                        [headerText]="column.title | translate"
                        [minWidth]="column.width + '%'"
                        [width]="column.width + '%'"
                      >
                        <ng-template #template let-cData>
                          <doctor-gui-table-cell [data]="cData" [column]="column"></doctor-gui-table-cell>
                        </ng-template>
                      </e-column>
                    </e-columns>
                  </ejs-grid>
                </div>
              </ng-container>
            </ng-container>
          </ng-template>
        </ng-container>
      </ejs-grid>
    </doctor-gui-expandable-container>
  </ng-container>
</ng-container>
<ng-template #noData>
  <div class="py-3 d-flex justify-content-center no-data">{{ 'my_doctor_no_data' | translate }}</div>
</ng-template>
