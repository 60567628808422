import { Action, createReducer, on } from '@ngrx/store';
import * as UsersManagementActions from './users-management.actions';
import { IUserItemModel } from '../models/interfaces/user-item.interface';
import { filter, find } from 'lodash';

export const usersManagementFeatureKey = 'users-management';

export interface UsersManagementState {
  users: IUserItemModel[];
  usersCount: number;
  error: any;
  usersLoading: boolean;
  userActionLoading: boolean;
}

export const initialState: UsersManagementState = {
  users: [],
  error: undefined,
  usersCount: 0,
  usersLoading: false,
  userActionLoading: false,
};

const usersManagementReducer = createReducer<UsersManagementState>(
  initialState,
  on(UsersManagementActions.loadUsersAction, (state) => ({
    ...state,
    error: null,
  })),
  on(UsersManagementActions.loadUsersActionLoading, (state) => ({
    ...state,
    usersLoading: true,
  })),
  on(UsersManagementActions.loadUsersSuccessAction, (state, { users }) => ({
    ...state,
    users,
    usersLoading: false,
  })),
  on(
    UsersManagementActions.loadUsersFailureAction,
    UsersManagementActions.changeUserFailureAction,
    UsersManagementActions.addUserFailureAction,
    (state, { error }) => ({
      ...state,
      error,
      usersLoading: false,
    })
  ),
  on(UsersManagementActions.addUserAction, (state) => ({
    ...state,
  })),
  on(UsersManagementActions.addUserSuccessAction, (state, { user }) => ({
    ...state,
    users: {
      ...state.users,
      data: [user, ...(state?.users ?? [])],
    },
  })),
  on(UsersManagementActions.changeUserSuccessAction, (state, { userId, roles, status }) => ({
    ...state,
    users: state.users.map((user) => (user.id === userId ? { ...user, roles, status } : { ...user })),
  })),
  on(UsersManagementActions.loadUsersCountActionSuccess, (state, { count }) => ({
    ...state,
    usersCount: count,
  })),
  on(
    UsersManagementActions.editUserPersonDataActionLoading,
    UsersManagementActions.addUserPersonDataActionLoading,
    (state) => ({
      ...state,
      userActionLoading: true,
    })
  ),
  on(
    UsersManagementActions.editUserPersonDataActionSuccess,
    UsersManagementActions.addUserPersonDataActionSuccess,
    (state, { personData, userId }) => {
      const users = state.users.map((user) =>
        user.id === userId ? { ...user, personData: { ...user.personData, ...personData } } : { ...user }
      );
      return {
        ...state,
        userActionLoading: false,
        users,
      };
    }
  ),
  on(
    UsersManagementActions.editUserPersonDataFailureAction,
    UsersManagementActions.addUserPersonDataFailureAction,
    (state, { error }) => ({
      ...state,
      userActionLoading: false,
      error,
    })
  ),
  on(UsersManagementActions.unassignUserFromOrganisationSuccessAction, (state, { organisationId, userId }) => {
    const users = state.users.map((user) =>
      user.id === userId
        ? {
            ...user,
            networkOrganizations: filter(
              user?.networkOrganizations || [],
              (org) => org.organizationId !== organisationId
            ),
          }
        : { ...user }
    );
    return {
      ...state,
      userActionLoading: false,
      users,
    };
  }),
  on(UsersManagementActions.assignUserToOrganisationsSuccessAction, (state, { organisations, userId }) => {
    const users = state.users.map((user) =>
      user.id === userId
        ? { ...user, networkOrganizations: [...(user?.networkOrganizations || []), ...organisations] }
        : { ...user }
    );
    return {
      ...state,
      userActionLoading: false,
      users,
    };
  }),
  on(
    UsersManagementActions.updateUserOrganisationsSuccessAction,
    (state, { organisations: updatedOrganisations, userId }) => {
      const users = state.users.map((user) =>
        user.id === userId
          ? {
              ...user,
              networkOrganizations: user.networkOrganizations.map((org) => {
                const updatedOrganisation = find(updatedOrganisations, (o) => org.organizationId === o.id);
                return { ...org, ...updatedOrganisation };
              }),
            }
          : {
              ...user,
            }
      );
      return {
        ...state,
        userActionLoading: false,
        users,
      };
    }
  ),
  on(UsersManagementActions.deleteUserAction, (state) => {
    return {
      ...state,
      userActionLoading: true,
    };
  }),
  on(UsersManagementActions.deleteUserSuccessAction, (state, { userId }) => {
    const users = state.users.map((user) => (user.id === userId ? null : { ...user })).filter(Boolean);
    return {
      ...state,
      userActionLoading: false,
      users,
    };
  }),
  on(UsersManagementActions.deleteUserSuccessFailure, (state) => {
    return {
      ...state,
      userActionLoading: false,
    };
  })
);

export function reducer(state: UsersManagementState, action: Action): UsersManagementState {
  return usersManagementReducer(state, action);
}
