import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectCurrentID } from '@medrecord/managers-users';
import { Store } from '@ngrx/store';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';
import {
  deleteDataAction,
  deleteDataSuccessAction,
  downloadPortabilityReportAction,
  setDeleteDataModalOpenStatusAction
} from '../actions';
import { MyDoctorManagerService } from '../../services/my-doctor-manager.service';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { AttachmentsService } from 'libs/medsafe/admin/reports/src/lib/services/attachments.service';
import { Resource } from '../../models/enums';
import { getActiveTransactionId } from '../selectors';

@Injectable()
export class CommonEffects {


  @Effect({ dispatch: false })
  downloadPortabilityReport$ = this.actions$.pipe(
    ofType(downloadPortabilityReportAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ startDate, endDate }, userId]) =>
      this.service.getPortabilityReport(userId, startDate, endDate).pipe(
        switchMap(({ content }) => {
          this.attachmentsService.downloadFile('data:application/xml,' + content, 'report.xml');
          return [];
        }),
        catchError((error: any) => {
          this.store.dispatch(
            addErrorToast(
              getErrorToastBodyUtil(this.translateService.instant('report_download_error'), error?.error || error)
            )
          );
          return [];
        })
      )
    )
  );

  @Effect()
  deleteData$ = this.actions$.pipe(
    ofType(deleteDataAction),
    withLatestFrom(
      this.store.select(selectCurrentID),
      this.store.select(getActiveTransactionId)
    ),
    mergeMap(([{ careProviderId, resources = [] }, userId, transactionId]) => {
      const resourceKeys: Resource[] = (careProviderId && resources?.length > 0)
        ? this.service.toFlatResources(resources, transactionId)
        : [];
      return this.service.deleteData(userId, careProviderId, resourceKeys).pipe(
        switchMap(() => [
          addSuccessToast({
            title: 'common_success',
            content: ''
          }),
          deleteDataSuccessAction({ careProviderId, resourceKeys: resources || [] }),
          setDeleteDataModalOpenStatusAction({ isOpen: false })
        ]),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(
            this.translateService.instant('common_error'),
            error?.error || error
          ))
        ])
      );
    })
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private service: MyDoctorManagerService,
    private translateService: TranslateService,
    private attachmentsService: AttachmentsService
  ) {
  }
}
