import { createAction, props } from '@ngrx/store';
import { TranslationsCategory, TranslationItem } from '../models';
import { Language } from '@medrecord/core';

export const setLanguageAction = createAction(
  '[Translations Actions] Set Language',
  props<{ language: Language }>()
);

export const setTranslationsPage = createAction(
  '[Translations Actions] Set Translation Page',
  props<{ page: number }>()
);

export const setTranslationByCategoryAndLang = createAction(
  '[Translations Actions] Set Translations with category and language',
  props<{
    language: string,
    category: TranslationsCategory,
    translations: TranslationItem[],
    total: number,
    pages: number
  }>()
);

export const loadTranslationsAction = createAction(
  '[Translations Actions] Load Translations',
  props<{
    category: TranslationsCategory,
    start: number,
    language?: string
  }>()
);

export const translationsSuccessLoadingAction = createAction(
  '[Translations Actions] Translations Success Loading',
  props<{
    category: TranslationsCategory,
    translations: TranslationItem[],
    pages: number,
    total: number
  }>()
);

export const translationsFailureLoadingAction = createAction(
  '[Translations Actions] Translations Failure Loading',
  props<{
    error: any
  }>()
);


export const loadAllTranslationsByCategoryAction = createAction(
  '[Translations Actions] Load All Translations By Category',
  props<{
    category: TranslationsCategory,
    start: number
  }>()
);

export const allTranslationsSuccessLoadingByCategoryAction = createAction(
  '[Translations Actions] All Translations Success Loading By Category'
);

export const allTranslationsFailureLoadingByCategoryAction = createAction(
  '[Translations Actions] All Translations Failure Loading By Category',
  props<{ error: any }>()
);

export const loadTranslationCategories = createAction(
  '[Translations Actions] Load Categories'
);

export const categoriesSuccessLoadingAction = createAction(
  '[Translations Actions] Categories Success Loading',
  props<{ categories: TranslationsCategory[] }>()
);

export const categoriesFailureLoadingAction = createAction(
  '[Translations Actions] Categories Failure Loading',
  props<{ error: any }>()
);

export const updateTranslation = createAction(
  '[Translations Actions] Update Translation',
  props<{ translation: TranslationItem }>()
);

export const translationUpdatingSuccess = createAction(
  '[Translations Actions] Translation Updating Success',
  props<{ translation: TranslationItem }>()
);

export const translationUpdatingFailure = createAction(
  '[Translations Actions] Translation Updating Failure',
  props<{ error: any }>()
);
