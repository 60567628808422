import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyDoctorManagerStateModule } from './state-management/my-doctor-manager-state.module';
import { MyDoctorManagerService } from './services/my-doctor-manager.service';
import { provideMyDoctorManagerConstants } from './constants';
import { MedrecordIconsModule } from '@medrecord/icons';
import { PullTransactionTabularMappingService } from './services/pull-transaction-tabular-mapping.service';

@NgModule({
  imports: [CommonModule, MyDoctorManagerStateModule, MedrecordIconsModule],
  providers: [MyDoctorManagerService, PullTransactionTabularMappingService, provideMyDoctorManagerConstants()]
})
export class MyDoctorManagerModule {
}
