import { getUnitsOfMeasure } from './questions-helper.util';
import { TranslateService } from '@ngx-translate/core';
import { QuestionType } from '../models/enum';
import {
  GroupQuestion,
  RegularQuestion,
  NumberQuestion,
  QuantityQuestion,
  ChoiceQuestion,
  Question
} from '../models/interfaces';

export const getQuestions = (items: any, translateService: TranslateService): Question[] => {
  return items.map((item: any) => {
    switch (item.type) {
      case QuestionType.display:
        return getDisplayQuestion(item, translateService);
      case QuestionType.group:
        return getGroupQuestion(item, translateService);
      case QuestionType.date:
      case QuestionType.time:
      case QuestionType.datetime:

      case QuestionType.text:
      case QuestionType.string:

      case QuestionType.boolean:
        return getRegularQuestion(item, translateService);

      case QuestionType.integer:
      case QuestionType.decimal:
        return getNumberQuestion(item, translateService);

      case QuestionType.quantity:
        return getQuantityQuestion(item, translateService);

      case QuestionType.singleChoice:
        return getChoiceQuestion(item, translateService);

      default:
        return getUnknownQuestion(item, translateService);
    }
  });
};

export const getCommonQuestionFields = (item: any, translateService: TranslateService): Question => ({
  id: item.linkId,
  questionText: item.text,
  required: item.required,
  type: item.type,
  enableWhen: item.enableWhen,
  unitOfMeasure: getUnitsOfMeasure(item, translateService),
  unitOfMeasureCoding: item.unitOfMeasure
});

export const getDisplayQuestion = (item: any, translateService: TranslateService): Question => ({
  ...getCommonQuestionFields(item, translateService)
});

export const getGroupQuestion = (item: any, translateService: TranslateService): GroupQuestion => ({
  ...getCommonQuestionFields(item, translateService),
  subQuestions: getQuestions(item.items, translateService)
});

export const getRegularQuestion = (item: any, translateService: TranslateService): RegularQuestion => ({
  ...getCommonQuestionFields(item, translateService),
  options: {
    minLength: item.minLength,
    maxLength: item.maxLength
  }
});

export const getNumberQuestion = (item: any, translateService: TranslateService): NumberQuestion => ({
  ...getCommonQuestionFields(item, translateService),
  options: {
    maxDecimalPlaces: item.maxDecimalPlaces ?? 2,
    minValue: item.minValue ?? -Infinity,
    maxValue: item.maxValue ?? Infinity
  }
});

export const getQuantityQuestion = (item: any, translateService: TranslateService): QuantityQuestion => ({
  ...getCommonQuestionFields(item, translateService),
  options: {
    minValue: item.minValue ?? 0,
    maxValue: item.maxValue ?? Infinity
  }
});

export const getChoiceQuestion = (item: any, translateService: TranslateService): ChoiceQuestion => ({
  ...getCommonQuestionFields(item, translateService),
  options: {
    answers: item.option.map(({ valueCoding: answerCoding }) =>
      ({
        label: answerCoding.display,
        value: answerCoding.code,
        answerCoding
      }))
  }
});

export const getUnknownQuestion = (item: any, translateService: TranslateService): Question => ({
  ...getCommonQuestionFields(item, translateService)
});
