import { Component, Input } from '@angular/core';
import { TabularResource } from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-results-table',
  templateUrl: './results-table.component.html',
  styleUrls: ['./results-table.component.scss']
})
export class ResultsTableComponent {
  @Input() table: TabularResource<string>;
}
