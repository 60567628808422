import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-info',
  templateUrl: './info.component.svg',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent extends MedrecordIcon {
  viewHeight = 32;
  viewWidth = 32;
}
