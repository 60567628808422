import { Component, Input } from '@angular/core';
import { ScreenSize } from '@managers/app-config-manager';

@Component({
  selector: 'medsafe-current-user',
  templateUrl: './current-user.component.html',
})
export class CurrentUserComponent {
  @Input() userData: any = {};

  screenSizes = ScreenSize;
}
