import { Component } from '@angular/core';
import { TwofaFlow } from '@medrecord/managers-auth';

@Component({
  selector: 'medrecord-auth-twofa-reconfigure-container',
  templateUrl: './twofa-reconfigure-container.component.html'
})
export class TwofaReconfigureContainerComponent {
  readonly flow = TwofaFlow.Reconfiguration;
}
