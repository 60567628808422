<div *ngIf="errorCode" class="pt-1">
    <span class="text text-danger text-weight-500">
      {{ 'error_' + errorCode | translate }}
    </span>
</div>

<div *ngIf="errors.length && !errorCode && !externalErrors.length" class="pt-1">
    <span *ngFor="let error of errors" class="text text-warning text-weight-500">
      {{ error }}
    </span>
</div>

<div *ngIf="externalErrors.length && !errorCode" class="pt-1">
    <span *ngFor="let error of externalErrors" class="text text-danger text-weight-500">
      {{ error }}
    </span>
</div>