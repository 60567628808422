import { Component, EventEmitter, Output } from '@angular/core';
import { TestCareproviderFetchStatusType } from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-test-careprovider-status-buttons',
  templateUrl: './test-careprovider-status-buttons.component.html'
})
export class TestCareproviderStatusButtonsComponent {
  @Output() loadStatus: EventEmitter<TestCareproviderFetchStatusType> = new EventEmitter<TestCareproviderFetchStatusType>();

  readonly fetchStatusTypes: TestCareproviderFetchStatusType[] = Object.values(TestCareproviderFetchStatusType);

  loadStatusOfType(type: TestCareproviderFetchStatusType): void {
    this.loadStatus.emit(type);
  }
}
