import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { User } from '@medrecord/managers-users';

@Component({
  selector: 'medsafe-avatar-form',
  templateUrl: './avatar-form.component.html',
})
export class AvatarFormComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() user: User;

  uploadingNewAvatar: boolean;

  readonly maxSizeKb = 200;
  readonly maxSizeInBytes = this.maxSizeKb * 1000;

  photoBaseControl: FormControl;
  contentTypeControl: FormControl;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.photoBaseControl = this.fb.control(this.user?.photoBase64Data || '');
    this.contentTypeControl = this.fb.control(this.user?.photoContentType || '');

    this.parentForm.registerControl('photoBase64Data', this.photoBaseControl);
    this.parentForm.registerControl('photoContentType', this.contentTypeControl);
  }

  fileValidate(file: File): boolean {
    return file.size <= this.maxSizeInBytes;
  }

  setBase64Image(image: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      const [contentType, base64] = `${reader.result || ''}`.split(';');

      this.contentTypeControl.setValue(contentType.replace('data:', ''));
      this.photoBaseControl.setValue(base64.replace('base64,', ''));
    };
  }

  onUploadPreparation(event): void {
    const file = event.files[0].rawFile;

    if (event.removing) {
      this.removeAvatar();
    } else if (this.fileValidate(file)) {
      this.uploadingNewAvatar = true;
      this.setBase64Image(file);
    }
  }

  removeAvatar(): void {
    this.contentTypeControl.setValue('');
    this.photoBaseControl.setValue('');
  }
}
