import { Component, Input, OnInit } from '@angular/core';
import { ProfileNameFormFactory } from '../../../factories/profile-name-form.factory';
import { FormGroup } from '@angular/forms';
import { ProfileFormType } from '../../../typings/enums';
import { userDataFormKeys, UserName } from '@medrecord/managers-users';
import { MapField } from '@medrecord/ui';

@Component({
  selector: 'medsafe-name-form',
  templateUrl: './name-form.component.html',
})
export class NameFormComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() name: UserName;

  formGroupKey = userDataFormKeys.name;

  form: FormGroup;
  fields: MapField[];

  constructor(private profileNameFormFactory: ProfileNameFormFactory) {}

  ngOnInit(): void {
    const { form, fields } = this.profileNameFormFactory.getFormConfig(ProfileFormType.User, this.name);

    this.form = form;
    this.fields = fields;
    this.parentForm.registerControl(this.formGroupKey, form);
  }
}
