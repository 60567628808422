<form [formGroup]="form" (ngSubmit)="handleSubmit()">
  <section class="section-container">
    <div class="row section-title">
      {{ 'user_information' | translate }}
    </div>
    <div class="row section-content">
      <div class="col-12 col-md-4 col-lg-4" [formGroup]="form">
        <medrecord-ui-map-field
          attr.id="user-first-name"
          [fieldType]="mapFieldType.Input"
          [label]="'common_form_field_first_name' | translate"
          controlName="firstName"
          [asterix]="true"
          [parentForm]="form"
        >
        </medrecord-ui-map-field>
      </div>
      <div class="col-6 col-md-2 col-lg-2" [formGroup]="form">
        <medrecord-ui-map-field
          attr.id="user-suffix"
          [fieldType]="mapFieldType.Input"
          [label]="'common_form_field_suffix' | translate"
          controlName="suffix"
          [parentForm]="form"
        >
        </medrecord-ui-map-field>
      </div>
      <div class="col-6 col-md-2 col-lg-2" [formGroup]="form">
        <medrecord-ui-map-field
          attr.id="user-last-name-prefix"
          [fieldType]="mapFieldType.Input"
          [label]="'common_form_field_last_name_prefix' | translate"
          controlName="lastNamePrefix"
          [parentForm]="form"
        >
        </medrecord-ui-map-field>
      </div>
      <div class="col-12 col-md-4 col-lg-4" [formGroup]="form">
        <medrecord-ui-map-field
          attr.id="user-last-name"
          [fieldType]="mapFieldType.Input"
          [label]="'common_form_field_last_name' | translate"
          controlName="lastName"
          [parentForm]="form"
          [asterix]="true"
        >
        </medrecord-ui-map-field>
      </div>
    </div>
    <div class="row section-content">
      <div class="col-6 mt-3">
        <medrecord-ui-map-field
          attr.id="user-last-name"
          [fieldType]="mapFieldType.CheckBox"
          [label]="'common_form_field_married' | translate"
          controlName="married"
          [parentForm]="form"
        ></medrecord-ui-map-field>
      </div>
    </div>
    <div class="row section-content" *ngIf="form?.value?.married">
      <div class="col-12 col-md-4 col-lg-4">
        <medrecord-ui-map-field
          attr.id="user-partner-last-name"
          [fieldType]="mapFieldType.Input"
          [label]="'common_form_field_partner_last_name' | translate"
          controlName="partnerLastName"
          [parentForm]="form"
        ></medrecord-ui-map-field>
      </div>
      <div class="col-12 col-md-4 col-lg-4">
        <medrecord-ui-map-field
          attr.id="user-partner-last-name-prefix"
          [fieldType]="mapFieldType.Input"
          [label]="'common_form_field_partner_last_name_prefix' | translate"
          controlName="partnerLastNamePrefix"
          [parentForm]="form"
        ></medrecord-ui-map-field>
      </div>
    </div>
    <div class="row section-content mt-3">
      <div class="col-12 col-md-6 col-lg-6" [formGroup]="form">
        <medrecord-ui-map-field
          attr.id="user-email"
          [fieldType]="mapFieldType.Input"
          [label]="'Email' | translate"
          controlName="email"
          [asterix]="true"
          [parentForm]="form"
        >
        </medrecord-ui-map-field>
      </div>
      <div class="col-12 col-md-3 col-lg-3" [formGroup]="form">
        <medrecord-ui-map-field
          attr.id="user-gender"
          [fieldType]="mapFieldType.Select"
          [label]="'common_form_field_gender' | translate"
          controlName="gender"
          [asterix]="true"
          [parentForm]="form"
          [options]="genderOptions"
        >
        </medrecord-ui-map-field>
      </div>
      <div class="col-12 col-md-3 col-lg-3 pl-md-0" [formGroup]="form">
        <medrecord-datepicker
          [label]="'common_form_field_date_of_birth' | translate"
          [maxDate]="maxSelectableBirthday"
          [calendarViewDepth]="'Month'"
          [startCalendarView]="'Decade'"
          class="d-block form-group"
          formControlName="birthDate"
          dateFormat="YYYY-MM-DD"
        ></medrecord-datepicker>
      </div>
    </div>
  </section>
  <section class="section-container">
    <div class="row section-title">
      {{ 'profile_configuration' | translate }}
    </div>
    <div class="row section-content">
      <div class="col-12 col-md-4 col-lg-4" [formGroup]="form">
        <medrecord-ui-map-field
          attr.id="user-status"
          [fieldType]="mapFieldType.Select"
          [label]="'allergies_status' | translate"
          controlName="status"
          [asterix]="true"
          [parentForm]="form"
          [options]="userStatuses"
        >
        </medrecord-ui-map-field>
      </div>
      <div class="col-12 col-md-4 col-lg-4" [formGroup]="form">
        <medrecord-multi-select
          formControlName="roles"
          [label]="'common_form_field_role' | translate"
          [items]="rolesOptions"
        ></medrecord-multi-select>
      </div>
      <div class="col-12 col-md-4 col-lg-4 resend-validation-container" *ngIf="!userDetails?.personData">
        <button class="button btn btn-primary font-weight-bold mt-0 px-5" type="button" (click)="resendValidation()">
          {{ 'resend_validation' | translate }}
        </button>
      </div>
    </div>
  </section>

  <medsafe-organisations-assignment-form-fields
    *ngIf="userDetails.roles.includes(role.Doctor) && userDetails.personData"
    [userOrganisations]="userDetails.networkOrganizations || []"
    [roles]="userDetails?.roles"
    [parentForm]="form"
    (onOrganisationUnassign)="handleOrganisationUnassign($event)"
  ></medsafe-organisations-assignment-form-fields>

  <div class="text-center">
    <button class.btn-rounded="true" class="btn btn-secondary mt-3" type="submit">
      {{ 'common_save' | translate }}
    </button>
  </div>
</form>
