import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  CareproviderEffects,
  CommonEffects,
  DataserviceEffects,
  MedmijInteractionEffects,
  ModalChainEffects,
  RecordEffects,
  ResourceEffects,
  TestCareproviderEffects
} from './effects';
import { myDoctorManagerFeatureKey, myDoctorManagerReducer } from './my-doctor-manager.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(myDoctorManagerFeatureKey, myDoctorManagerReducer),
    EffectsModule.forFeature([
      CareproviderEffects,
      DataserviceEffects,
      ModalChainEffects,
      MedmijInteractionEffects,
      ResourceEffects,
      CommonEffects,
      TestCareproviderEffects,
      RecordEffects
    ])
  ]
})
export class MyDoctorManagerStateModule {
}