import { createAction, props } from '@ngrx/store';
import { IUserModel } from '../models/interfaces/user.interface';
import { IUserItemModel } from '../models/interfaces/user-item.interface';
import { User } from '@medrecord/managers-users';
import { NetworkOrganisation } from '@managers/admin-organisations-manager';

export const loadUsersAction = createAction(
  '[User Management Actions] Load Users',
  props<{ start?: number, count?: number, search?: string }>()
);

export const loadUsersActionLoading = createAction(
  '[User Management Actions] Load Users Loading',
  props<{ loading: boolean }>()
);

export const loadUsersSuccessAction = createAction(
  '[User Management Actions] Load Users Success',
  props<{ users: IUserItemModel[] }>()
);

export const loadUsersFailureAction = createAction(
  '[User Management Actions] Load Users Failure',
  props<{ error: any }>()
);

export const changeUserAction = createAction(
  '[User Management Actions] Change User',
  props<{ userId: string, roles: string[], status: string, statusChange: boolean }>()
);

export const changeUserSuccessAction = createAction(
  '[User Management Actions] Change User Success',
  props<{ userId: string, roles: string[], status: string }>()
);

export const changeUserFailureAction = createAction(
  '[User Management Actions] Change User Failure',
  props<{ error: any }>()
);

export const addUserAction = createAction(
  '[User Management Actions] Add User',
  props<{ user: IUserModel }>()
);

export const addUserSuccessAction = createAction(
  '[User Management Actions] Add User Success',
  props<{ user: IUserModel }>()
);

export const addUserFailureAction = createAction(
  '[User Management Actions] Add User Failure',
  props<{ error: any }>()
);

export const loadUsersCountAction = createAction(
  '[User Management Actions] Load Users Count',
  props<{ search: string }>()
);

export const loadUsersCountActionSuccess = createAction(
  '[User Management Actions] Load Users Count Success',
  props<{ count:number }>()
);

export const loadUsersCountFailureAction = createAction(
  '[User Management Actions] Load Users Count Failure',
  props<{ error: any }>()
);

export const editUserPersonDataAction = createAction(
  '[User Management Actions] Edit User Person Data',
  props<{ personData: User, userId: string }>()
);

export const addUserPersonDataAction = createAction(
  '[User Management Actions] Add User Person Data',
  props<{ personData: User, userId: string }>()
);

export const addUserPersonDataActionSuccess = createAction(
  '[User Management Actions] Add User Person Data Success',
  props<{ personData: User, userId: string }>()
);

export const addUserPersonDataFailureAction = createAction(
  '[User Management Actions] Add User Person Data Failure',
  props<{ error: any }>()
);

export const addUserPersonDataActionLoading = createAction(
  '[User Management Actions] Add User Person Data Loading',
  props<{ loading: boolean }>()
);

export const editUserPersonDataActionSuccess = createAction(
  '[User Management Actions] Edit User Person Data Success',
  props<{ personData: User, userId: string }>()
);

export const editUserPersonDataFailureAction = createAction(
  '[User Management Actions] Edit User Person Data Failure',
  props<{ error: any }>()
);

export const editUserPersonDataActionLoading = createAction(
  '[User Management Actions] Edit User Person Data Loading',
  props<{ loading: boolean }>()
);

export const assignUserToOrganisationsAction = createAction(
  '[Network Organisations] Assign User To Organisations',
  props<{ userId: string, organisations: NetworkOrganisation[] }>()
);

export const assignUserToOrganisationsSuccessAction = createAction(
  '[Network Organisations] Assign User To Organisations Success',
  props<{ userId: string, organisations: NetworkOrganisation[] }>()
);

export const assignUserToOrganisationsFailure = createAction(
  '[Network Organisations] Assign User To Organisations Failure',
  props<{ error: any }>()
);

export const updateUserOrganisationsAction = createAction(
  '[Network Organisations] Update User Organisations',
  props<{ userId: string, organisations: NetworkOrganisation[] }>()
);

export const updateUserOrganisationsSuccessAction = createAction(
  '[Network Organisations] Update User Organisations Success',
  props<{ userId: string, organisations: NetworkOrganisation[] }>()
);

export const updateUserOrganisationsFailure = createAction(
  '[Network Organisations] Update User Organisations Failure',
  props<{ error: any }>()
);

export const unassignUserFromOrganisationAction = createAction(
  '[Network Organisations] Unassign User From Organisation',
  props<{ userId: string, organisationId: string }>()
);

export const unassignUserFromOrganisationSuccessAction = createAction(
  '[Network Organisations] Unassign User From Organisation Success',
  props<{ userId: string, organisationId: string }>()
);

export const unassignUserFromOrganisationFailure = createAction(
  '[Network Organisations] Unassign User From Organisation Failure',
  props<{ error: any }>()
);

export const deleteUserAction = createAction(
  '[User Management Actions] Delete User Action',
  props<{ userId: string }>()
);

export const deleteUserSuccessAction = createAction(
  '[User Management Actions] Delete User Action Success',
  props<{ userId: string }>()
);

export const deleteUserSuccessFailure = createAction(
  '[User Management Actions] Delete User Action Failure',
  props<{ error: any }>()
);