import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Careprovider, getActiveCareprovider } from '@managers/my-doctor';
import { Store } from '@ngrx/store';

@Component({
  selector: 'doctor-gui-careprovider-short-details-container',
  templateUrl: './careprovider-short-details-container.component.html',
})
export class CareproviderShortDetailsContainerComponent {
  careprovider$: Observable<Careprovider> = this.store.select(getActiveCareprovider);

  constructor(
    private store: Store
  ) {
  }
}
