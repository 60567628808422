import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { clearFeedbackAction, sendFeedbackAction } from './feedback-manager.actions';
import { Store } from '@ngrx/store';
import { getSelectedUser } from '@medrecord/managers-users';
import { FeedbackService } from '../services/feedback.service';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';

@Injectable()
export class FeedbackManagerEffects {
  activateFeedback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendFeedbackAction),
      withLatestFrom(this.store.select(getSelectedUser)),
      switchMap(([{ payload }, user]) =>
        this.feedbackService
          .sendFeedback({
            ...payload,
            email: user.contactList?.find((contact) => contact.system === 'EMAIL')?.value || 'No value',
          })
          .pipe(
            map(() => {
              this.store.dispatch(
                addSuccessToast({
                  title: 'feedback_add_success_title',
                  content: 'feedback_add_success_content',
                })
              );
              return clearFeedbackAction();
            }),
            catchError((err) => {
              return [addErrorToast(getErrorToastBodyUtil('feedback_add_failure_content', err))];
            })
          )
      )
    )
  );

  constructor(private actions$: Actions, private store: Store, private feedbackService: FeedbackService) {}
}
