import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ValidationService } from '@medrecord/services-form-validation';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthStorageItem,
  FORM_RULES,
  FormRules,
  signInAction,
  getRememberedUser,
  signInRememberedUserAction,
} from '@medrecord/managers-auth';
import { Router } from '@angular/router';
import { AUTH_ROUTE_NAMES, AuthRouteNames } from '@medrecord/routes-auth';
import { EnvService, Language, StorageKeys } from '@medrecord/core';
import { MapFieldType } from '@medrecord/ui';
import { StorageService } from '@medrecord/tools-storage';

@Component({
  selector: 'medrecord-auth-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  form: FormGroup;
  showPassword = false;
  disableRemember = true;
  rememberedUser = null;
  disableRegister: boolean;

  readonly isOAuth = this.storage.getItem<boolean>(StorageKeys.isOAuth, false);
  readonly mapFieldType = MapFieldType;
  readonly defaultLanguage;

  private rememberedUser$ = this.store.select(getRememberedUser);

  constructor(
    private storage: StorageService<StorageKeys>,
    private store: Store,
    private router: Router,
    private translateService: TranslateService,

    fb: FormBuilder,
    validationService: ValidationService,

    @Inject(AUTH_ROUTE_NAMES) private authRouteNames: AuthRouteNames,
    @Inject(FORM_RULES) formRules: FormRules,
    private envService: EnvService
  ) {
    this.defaultLanguage = this.envService.defaultLanguage;

    this.form = fb.group({
      email: [
        null,
        [
          validationService.required(translateService.instant('auth_validation_error_required')),
          validationService.email(translateService.instant('auth_validation_error_email')),
        ],
      ],
      password: [
        null,
        [
          validationService.required(translateService.instant('auth_validation_error_required')),
          validationService.maxLength(
            formRules.passwordMaxLength,
            translateService.instant('auth_validation_error_required')
          ),
          validationService.passwordComplexity(translateService.instant('auth_validation_password_complexity')),
        ],
      ],
    });

    this.disableRemember = this.envService.disableRemember;

    if (this.envService.disableRemember) {
      return;
    }

    const rememberMeValue = storage.getItem<AuthStorageItem>(StorageKeys.Auth)?.rememberMe;
    const rememberMeControl = fb.control(!!rememberMeValue);

    this.form.registerControl('rememberMe', rememberMeControl);

    this.rememberedUser$.subscribe((user) => (this.rememberedUser = !!rememberMeValue && user));
  }

  ngOnInit(): void {
    this.disableRegister = this.envService.disableRegister;
  }

  handleFormSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      if (!this.isOAuth && this.storage.getItem(StorageKeys.Language) === Language.DE) {
        this.storage.setItem(StorageKeys.Language, this.defaultLanguage);
        this.translateService.use(this.defaultLanguage);
      }

      this.store.dispatch(signInAction({ ...this.form.value }));
    }
  }

  logInRememberedUser(): void {
    this.store.dispatch(signInRememberedUserAction());
  }

  openSignUpPage(): void {
    let signUpPath = '';
    if (this.storage.getItem<boolean>(StorageKeys.isOAuth, false))
      signUpPath = `/${this.authRouteNames.Entry}/${this.authRouteNames.SignUp.Entry}`;
    else
      signUpPath = `/${this.authRouteNames.Entry}/${this.authRouteNames.SignUp.Entry}/${this.authRouteNames.SignUp.Role}`;

    this.router.navigate([signUpPath], { queryParamsHandling: 'merge' });
  }

  openForgotPasswordPage(): Promise<boolean> {
    return this.router.navigate([this.authRouteNames.Entry, this.authRouteNames.Password.Forgot], {
      queryParamsHandling: 'merge',
    });
  }
}
