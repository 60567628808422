import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@medrecord/core';
import { Observable } from 'rxjs';
import { QualificationListItem } from '../models/interfaces/qualification-list-item.interface';

@Injectable()
export class QualificationService {

  constructor(
    private httpClient: HttpClient,
    private env: EnvService
  ) { }

  getQualifications(): Observable<QualificationListItem[]> {
    return this.httpClient.get<QualificationListItem[]>(`${this.env.healthTalkConsultationManager}/api/qualification`);
  }
}
