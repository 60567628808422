import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-measurements',
  templateUrl: './measurements.component.svg',
})
export class MeasurementsComponent extends MedrecordIcon {
  viewHeight = 24;
  viewWidth = 24;
}
