import { Component } from '@angular/core';
import { TwofaFlow } from '@medrecord/managers-auth';

@Component({
  selector: 'medrecord-auth-twofa-setup-container',
  templateUrl: './twofa-setup-container.component.html'
})
export class TwofaSetupContainerComponent {
  readonly flow = TwofaFlow.Setup;
}
