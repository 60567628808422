import { Medication } from '../models/interfaces/medication.interface';
import { Action, createReducer, on } from '@ngrx/store';
import {
  addMedicationSuccessAction,
  clearActiveMedication,
  clearPrescribedMedication,
  deleteMedicationSuccessAction,
  editMedicationSuccessAction,
  linkCareplanMedicationsSuccessAction,
  loadCareplanMedicationsSuccessAction,
  loadMedicationsFailureAction,
  loadMedicationsSuccessAction,
  loadMedicationSuccessAction,
  loadPrescribedMedicationSuccessAction,
  loadUsedMedicationSuccessAction,
  removeCareplanMedicationAction,
} from './medications-manager.actions';

export const medicationsManagerFeatureKey = 'medications-manager';

export interface MedicationsManagerState {
  error: any;
  medications: Medication[];
  activeMedication: Medication;
  count: number;
  careplan: {
    prescribedMedication?: Medication;
    medications: Medication[];
    count: number;
    usedMedications: Medication[];
  };
}

export const initialState: MedicationsManagerState = {
  error: null,
  medications: [],
  activeMedication: null,
  count: 0,
  careplan: {
    prescribedMedication: null,
    medications: [],
    count: 0,
    usedMedications: [],
  },
};

export const medicationsManagerReducer = createReducer(
  initialState,
  on(loadMedicationsSuccessAction, (state, { medications, count }) => ({
    ...state,
    count,
    medications,
  })),
  on(loadCareplanMedicationsSuccessAction, (state, { medications, count }) => ({
    ...state,
    careplan: {
      ...state?.careplan,
      medications,
      count,
    },
  })),
  on(linkCareplanMedicationsSuccessAction, (state, { medications }) => ({
    ...state,
    careplan: {
      ...state?.careplan,
      medications: [...medications, ...state.careplan.medications],
      count: state.careplan.count + medications.length,
    },
  })),
  on(loadMedicationsFailureAction, (state, { error }) => ({
    ...state,
    error,
  })),
  on(loadMedicationSuccessAction, (state, { medication }) => ({
    ...state,
    activeMedication: medication,
    medications: state.medications.map((med) => (med.id === medication.id ? medication : med)),
    careplan: {
      ...state.careplan,
      medications: state.careplan.medications.map((med) => (med.id === medication.id ? medication : med)),
    },
  })),
  on(loadPrescribedMedicationSuccessAction, (state, { medication }) => ({
    ...state,
    careplan: {
      ...state.careplan,
      prescribedMedication: medication,
    },
  })),
  on(addMedicationSuccessAction, (state, { medication }) => ({
    ...state,
    count: state.count + 1,
    medications: [medication, ...state.medications],
    careplan: {
      ...state.careplan,
      medications: [medication, ...state.careplan.medications],
      count: state.careplan.count + 1,
    },
  })),
  on(editMedicationSuccessAction, (state, { medication }) => ({
    ...state,
    medications: state.medications.map((med) => (med.id === medication.id ? medication : med)),
    careplan: {
      ...state.careplan,
      medications: state.careplan.medications.map((med) => (med.id === medication.id ? medication : med)),
      prescribedMedication:
        medication?.id === state.careplan?.prescribedMedication?.id ? medication : state.careplan?.prescribedMedication,
    },
  })),
  on(deleteMedicationSuccessAction, (state, { id }) => ({
    ...state,
    count: state.count - 1,
    medications: state.medications.filter((med) => med.id !== id),
    careplan: {
      ...state?.careplan,
      medications: state.careplan.medications.filter((med) => med.id !== id),
      count: state.careplan.count - 1,
    },
  })),
  on(clearActiveMedication, (state) => ({
    ...state,
    activeMedication: null,
  })),
  on(removeCareplanMedicationAction, (state, { id }) => ({
    ...state,
    careplan: {
      ...state?.careplan,
      medications: state.careplan.medications.filter((med) => med.id !== id),
      count: state.careplan.count - 1,
    },
  })),
  on(clearPrescribedMedication, (state) => ({
    ...state,
    careplan: {
      ...state.careplan,
      prescribedMedication: null,
    },
  })),
  on(loadUsedMedicationSuccessAction, (state, { medication }) => ({
    ...state,
    careplan: {
      ...state.careplan,
      medications: state.careplan.medications.map((m) => (m.id === medication.id ? medication : m)),
    },
  }))
);

export const reducer = (state: MedicationsManagerState, action: Action): MedicationsManagerState => {
  return medicationsManagerReducer(state, action);
};
