import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import {
  createUserAction,
  createUserFailureAction,
  createUserSuccessAction,
  editUserAction,
  editUserFailureAction,
  editUserSuccessAction,
  hidePinedUserAction,
  loadUserMetadataFailureAction,
  loadUserMetadataSuccessAction,
  loadUserMetaData,
  loadUserProfileAction,
  loadUsersFailureAction,
  loadUsersSuccessAction,
  pinUserAction,
  pinUserSuccessAction,
  restorePinnedUsers,
  selectUser,
  loadUserNetworksAction,
  loadUserNetworksSuccessAction,
  loadUserNetworksFailureAction,
  clearUsersStateAction,
} from './users-manager.actions';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { UsersManagerService } from '../services/users-manager.service';
import { StorageKeys } from '@medrecord/core';
import { StorageService } from '@medrecord/tools-storage';
import { Router } from '@angular/router';
import { GENERAL_ROUTE_NAMES, GeneralRouteNames } from '@medrecord/routes-general';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { MEDSAFE_ROUTE_NAMES, MedsafeRouteNames } from '@medrecord/routes-medsafe';
import { forkJoin, of } from 'rxjs';
import { logoutFinished, selectUserId } from '@medrecord/managers-auth';
import { Store } from '@ngrx/store';
import { Permission } from '../models/enums';

@Injectable()
export class UsersManagerEffects {
  @Effect()
  loadUserProfile$ = this.actions$.pipe(
    ofType(loadUserProfileAction),
    switchMap(({ id }) =>
      forkJoin({
        user: this.userService.getUser(id),
      }).pipe(
        switchMap(({ user }) => [loadUsersSuccessAction({ user, id })]),
        catchError((error) => {
          // If user is not found, it should redirects to the onboarding screen
          if (error.status === 404) {
            this.router.navigate([this.medsafeRouteNames.Onboarding.Entry]);
            return [];
          }
          return [loadUsersFailureAction({ error })];
        })
      )
    )
  );

  @Effect()
  loadUserMetaData$ = this.actions$.pipe(
    ofType(loadUserMetaData),
    switchMap(({ id, permission }) =>      
      forkJoin({
        user: this.userService.getUser(id),
        allergies: permission !== Permission.ReadProfile ? this.userService.getUserMetaAllergies(id) : of([]),
      }).pipe(        
        switchMap(({ user, allergies }) => [loadUserMetadataSuccessAction({ user, allergies, id, permission })]),
        catchError((error) => {
          
          // TODO: Fix this dirty fix
          if (error.status === 404) {
            this.router.navigate([this.medsafeRouteNames.Onboarding.Entry]);
            return [];
          }
          return [
            loadUserMetadataFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil('load_user_metadata_error', error)),
          ];
        })
      )
    )
  );

  @Effect()
  loadUserNetworks$ = this.actions$.pipe(
    ofType(loadUserNetworksAction),
    withLatestFrom(this.store.select(selectUserId)),
    switchMap(([, userId]) =>
      this.userService.getUserNetworks(userId).pipe(switchMap((users) => [loadUserNetworksSuccessAction({ users })]))
    ),
    catchError(({ error }) => [loadUserNetworksFailureAction({ error })])
  );

  @Effect()
  editUser = this.actions$.pipe(
    ofType(editUserAction),
    switchMap(({ user, successActions }) => {
      const userId = this.storage.getItem(StorageKeys.Auth)?.userId;

      return this.userService.editUser(user, userId).pipe(
        switchMap(() => {
          return this.userService.getUser(userId).pipe(
            switchMap((newUser) => {
              return [editUserSuccessAction({ user: newUser, id: userId }), ...(successActions || [])];
            })
          );
        })
      );
    }),
    catchError(({ error }) => [editUserFailureAction({ error })])
  );

  createUser = createEffect(() =>
    this.actions$.pipe(
      ofType(createUserAction),
      switchMap(({ user, successActions }) =>
        this.userService.createUser(user).pipe(
          switchMap(() => {
            return [createUserSuccessAction({ user }), ...(successActions || [])];
          })
        )
      ),
      catchError(({ error }) => [
        createUserFailureAction({ error }),
        addErrorToast(getErrorToastBodyUtil('create_user_error', error)),
      ])
    )
  );

  @Effect()
  restorePinnedUsers$ = this.actions$.pipe(
    ofType(restorePinnedUsers),
    switchMap(() => {
      return this.storage.getItem<string[]>(StorageKeys.PinnedUsers, []).reduce((acc, userId) => {
        if (this.router.url.includes(userId)) {
          acc.push(selectUser({ id: userId }));
        }

        acc.push(pinUserAction({ userId, restoring: true }));

        return acc;
      }, []);
    })
  );

  @Effect()
  pinUser$ = this.actions$.pipe(
    ofType(pinUserAction),
    mergeMap(({ userId, restoring }) =>
      this.userService.getUser(userId).pipe(
        switchMap((user) => {
          if (!restoring) {
            this.storage.setItem(StorageKeys.PinnedUsers, [
              ...this.storage.getItem(StorageKeys.PinnedUsers, []),
              userId,
            ]);
          }

          return [loadUsersSuccessAction({ user, id: userId }), pinUserSuccessAction({ userId })];
        }),
        catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('pin_user_error', error))])
      )
    )
  );

  @Effect()
  hidePinnedUser$ = this.actions$.pipe(
    ofType(hidePinedUserAction),
    switchMap(({ userId }) => {
      this.storage.setItem(
        StorageKeys.PinnedUsers,
        this.storage.getItem<string[]>(StorageKeys.PinnedUsers, []).filter((id) => id !== userId)
      );

      if (this.router.url.includes(userId)) {
        this.router.navigateByUrl(
          `/${this.generalRouteNames.Medsafe.Entry}/${this.medsafeRouteNames.Network.Entry}/${this.medsafeRouteNames.Network.List}`
        );
      }

      return [];
    })
  );

  @Effect()
  clearStateOnLogout$ = this.actions$.pipe(
    ofType(logoutFinished),
    switchMap(() => [clearUsersStateAction()])
  );

  constructor(
    private userService: UsersManagerService,
    private actions$: Actions,
    private router: Router,
    private storage: StorageService<StorageKeys>,
    private store: Store,
    @Inject(GENERAL_ROUTE_NAMES) private generalRouteNames: GeneralRouteNames,
    @Inject(MEDSAFE_ROUTE_NAMES) private medsafeRouteNames: MedsafeRouteNames
  ) {}
}
