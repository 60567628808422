import { createAction, props } from '@ngrx/store';
import { LoadResourceResponse, MappingResource, ResourceDataAny } from '../../models/interfaces';
import { TabularResource } from '../../models/interfaces/tabular/tabular-resource.interface';
import { Resource } from '../../models/enums';

export const loadResourcesAction = createAction(
  '[My Doctor Manager Actions] Load Resources'
);

export const loadResourcesSuccessAction = createAction(
  '[My Doctor Manager Actions] Load Resources Success',
  props<{ responsePayload: LoadResourceResponse[] }>()
);

export const setAllResourceKeysAction = createAction(
  '[My Doctor Manager Actions] Set All Resource Keys',
  props<{ resourceKeys: MappingResource[] }>()
);

export const setFilteredResourcesKeysAction = createAction(
  '[My Doctor Manager Actions] Set Filtered Resources Keys',
  props<{ resourceKeys: MappingResource[] }>()
);

export const mergeDataWithMetadataAction = createAction(
  '[My Doctor Manager Actions] Merge Data With Metadata'
);

export const setResourceDataAction = createAction(
  '[My Doctor Manager Actions] Set Resource Data',
  props<{ resourceData: ResourceDataAny[] }>()
);

export const setTabularResourceDataAction = createAction(
  '[My Doctor Manager Actions] Set Tabular Resource Data',
  props<{ tabularResourceData: { [key in Resource]?: TabularResource<string> } }>()
);
