import { ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { InputValue } from '../models/types/input-value.type';
import { Colors } from '@medrecord/services-colors';
import { v4 as uuidv4 } from 'uuid';
import { MedrecordEvent } from '../models/interfaces/medrecord-event.interface';
import { ErrorCode } from '@medrecord/core';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive()
export class NgControlComponentBase implements ControlValueAccessor {
  @Input() id = uuidv4();
  @Input() name;
  @Input() cssClass = '';
  @Input()
  set autocomplete(value: string) {
    if (value) {
      this._autocomplete = value;
    }
  }
  get autocomplete(): string {
    return this._autocomplete;
  }
  
  @Input() externalErrors: ValidationErrors = {};
  @Input() disabled = false;
  
  @Input() label = '';
  @Input() secondaryLabel = '';
  @Input() hint: string;
  @Input() errorCode: ErrorCode;
  @Input() labelType: Colors = 'black';
  
  @Output() change = new EventEmitter<MedrecordEvent>();
  
  private _autocomplete = "on";
  
  constructor(
    public control: NgControl
  ) {
    if (control) {
      control.valueAccessor = this;
    }
  }
  
  protected _value: any = null;
  
  get value(): any {
    return this._value;
  }
  
  @Input()
  set value(value: any) {
    this._value = value;
    this.onChange(this._value);
    this.change.emit({ value });
  }
  
  get controlErrorsList(): string[] {
    return ((this.control?.dirty || this.control?.touched) && this.errors) || [];
  }
  
  get externalErrorsList(): string[] {
    return ((this.control?.dirty || this.control?.touched) && Object.values((this.externalErrors || {}))) || [];
  }
  
  get errors(): string[] {
    return Object.values(this.control?.errors || {}) || [];
  }
  
  // Control Value Accessor methods
  
  writeValue(value: InputValue): void {
    this.value = value;
  }
  
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  
  onChange: any = (): any => null;
  
  onTouched: any = (): any => null;
}
