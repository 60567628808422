import { Component, Inject, Input, OnInit } from '@angular/core';
import { StorageService } from '@medrecord/tools-storage';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES_COLLECTION, LanguagesCollection, Language, StorageKeys } from '@medrecord/core';
import { MenuEventArgs } from '@syncfusion/ej2-splitbuttons';
import { ItemModel } from '@syncfusion/ej2-splitbuttons/src/common/common-model';

@Component({
  selector: 'medrecord-ui-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
})
export class SelectLanguageComponent implements OnInit {
  @Input() enableGermanLanguage = false;
  selectedLanguage: ItemModel;
  languages: ItemModel[];
  readonly languagesCollection: LanguagesCollection;

  constructor(
    private storage: StorageService<StorageKeys>,
    private translateService: TranslateService,
    @Inject(LANGUAGES_COLLECTION) languagesCollection: LanguagesCollection
  ) {
    this.languagesCollection = languagesCollection;

    translateService.onLangChange.subscribe((lang) => {
      this.setLanguages(languagesCollection);
      this.selectedLanguage = {
        id: languagesCollection[lang.lang],
        text: languagesCollection[lang.lang].label,
      };
    });
  }

  ngOnInit(): void {
    const currentLanguage = this.storage.getItem<string>(StorageKeys.Language, Language.EN);
    this.setLanguages(this.languagesCollection);

    this.selectedLanguage = {
      id: this.languagesCollection[currentLanguage],
      text: this.languagesCollection[currentLanguage].label,
    };
  }

  setLanguages(languagesCollection: LanguagesCollection): void {
    this.languages = Object.values(languagesCollection)
      .map((lang) => ({
        id: lang.value,
        text: this.translateService.instant(lang.label),
      }))
      .filter((lang) => lang.id !== Language.DE || (lang.id === Language.DE && this.enableGermanLanguage));
  }

  selectLanguage(language: MenuEventArgs): void {
    this.storage.setItem(StorageKeys.Language, language.item.id);
    this.translateService.use(language.item.id);
  }
}
