import { Component, Input, Optional, Self, ViewChild } from '@angular/core';
import { NgControlComponentBase } from '../../../ui-abstractions/ng-control-component-base.component';
import { NgControl } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'medrecord-ui-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends NgControlComponentBase {
  @ViewChild('input') input;
  
  @Input() id = uuidv4();
  @Input() placeholder = '';
  
  constructor(@Self() @Optional() public control: NgControl) {
    super(control);
  }
  
  checkIfTouched(): void {
    if (this.input.touched) {
      this.onTouched();
    }
  }
}