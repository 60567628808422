<div class="form-group">
  <medrecord-label [for]="id"
                   [hint]="hint"
                   [secondaryText]="secondaryLabel"
                   [text]="label"
                   [type]="labelType"
  ></medrecord-label>

  <div class="d-flex align-items-center">

    <medrecord-svg-upload-avatar *ngIf="!value; else avatar"
                                 [width]="160"
                                 class="mr-2"
                                 type="mystic"
                                 userType="secondary"
    ></medrecord-svg-upload-avatar>

    <div class="d-flex flex-column ml-1 align-items-start">
      <ng-container *ngIf="!value; else removeAvatar">
        <button (click)="selectFile($event)"
                class="btn btn-success font-weight-bold pt-2 pb-2 pl-3 pr-3 d-flex align-items-center"
        >
          <span class="font-weight-bold text-sm mt-1 mb-1">
            {{ uploadAvatarButtonText }}
          </span>
        </button>

        <div class="mt-2 text-boulder text-sm">
          {{ avatarSizeLimitText }}
        </div>
      </ng-container>
    </div>

    <input
      #fileInput
      (onFileChanged)="onFileChange($event)"
      [id]="id"
      [name]="control?.name"
      [accept]="allowedTypes"
      alife-file-to-base64
      class="form-control"
      type="file"
    />
  </div>

  <ng-container *ngIf="hasErrors">
    <span *ngFor="let error of errors" class="text text-warning">
      {{ error }}
    </span>
  </ng-container>
</div>

<ng-template #avatar>
  <div [style.backgroundImage]="'url(' + value + ')'"
       class="avatar mr-2"
  ></div>
</ng-template>

<ng-template #removeAvatar>
  <button (click)="removeFile($event)"
          class="btn btn-outline-danger font-weight-bold pt-2 pb-2 pl-3 pr-3 d-flex align-items-center"
  >
    <medrecord-svg-cross-rounded [width]="24"
                                 class="mr-2"
                                 type="error"
                                 bgType="error"
                                 crossType="white"
    ></medrecord-svg-cross-rounded>

    <span class="font-weight-bold text-sm">
      {{ removeAvatarButtonText }}
    </span>
  </button>
</ng-template>