import { Component, Inject } from '@angular/core';
import { AuthRouteParams, restorePasswordAction } from '@medrecord/managers-auth';
import { Store } from '@ngrx/store';
import { navigateAction } from '@medrecord/tools-navigation';
import { AUTH_ROUTE_NAMES, AuthRouteNames } from '@medrecord/routes-auth';
import { EnvService, Language, StorageKeys } from '@medrecord/core';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '@medrecord/tools-storage';

@Component({
  selector: 'medrecord-auth-restore-password-form',
  templateUrl: './restore-password-form.component.html',
})
export class RestorePasswordFormComponent {
  languageCode: Language;
  readonly mobileRestorePassword = ['mhealthinx', 'mhealthinx-test'];
  constructor(
    @Inject(AUTH_ROUTE_NAMES) private authRouteNames: AuthRouteNames,
    private envService: EnvService,
    private storage: StorageService<StorageKeys>,
    private route: ActivatedRoute,
    private store: Store
  ) {
    this.languageCode = (this.route.snapshot.queryParamMap.get(AuthRouteParams.Language) ||
      this.envService.defaultLanguage) as Language;
  }

  onSubmit(password: string): void {
    this.store.dispatch(
      restorePasswordAction({
        newPassword: password,
        successActions: [
          navigateAction({
            path: [
              this.authRouteNames.Entry,
              this.authRouteNames.Password.Forgot,
              ...(!this.storage.getItem(StorageKeys.isOAuth, false) &&
              this.mobileRestorePassword.includes(this.envService.environment)
                ? [this.authRouteNames.Password.SuccessMobile]
                : [this.authRouteNames.Password.Restore, this.authRouteNames.Password.Success]),
            ],
            extras: {
              queryParamsHandling: 'merge',
            },
          }),
        ],
        languageCode: this.languageCode,
      })
    );
  }
}
