import { Component, Input, OnInit } from '@angular/core';
import { getCustomerTopMenuLogo } from '@medrecord/services-customer-metadata';
import { Store } from '@ngrx/store';
import { TopMenuItem } from '../../typings/interfaces';
import { Observable } from 'rxjs';
import { ScreenSize, getScreenSize, getSidebarHidden, setSidebarHidden } from '@managers/app-config-manager';
import { MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import { Router } from '@angular/router';

@Component({
  selector: 'medsafe-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent implements OnInit {
  @Input() activeItem: TopMenuItem;
  @Input() menuItems: TopMenuItem[] = [];
  @Input() customClass = '';

  logotype$ = this.store.select(getCustomerTopMenuLogo);
  isSidebarHidden$: Observable<boolean> = this.store.select(getSidebarHidden);
  currentScreenSize$: Observable<ScreenSize> = this.store.select(getScreenSize);

  readonly screenSizes = ScreenSize;
  public isSidebarHidden: boolean;

  ngOnInit(): void {
    this.isSidebarHidden$.subscribe((isSidebarHidden) => (this.isSidebarHidden = isSidebarHidden));
  }

  public hideSidebar(): void {
    this.store.dispatch(setSidebarHidden({ hideSidebar: !this.isSidebarHidden }));
  }

  public onItemSelect(event: MenuEventArgs | any): void {
    if (event?.item) {
      this.router.navigateByUrl(event?.item?.path);
    }
  }

  constructor(private store: Store, private router: Router) {}
}
