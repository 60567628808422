import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-pagination-arrow-right',
  templateUrl: './pagination-arrow-right.component.svg',
  styleUrls: ['./pagination-arrow-right.component.scss'],
})
export class PaginationArrowRightComponent extends MedrecordIcon {
  viewHeight = 14;
  viewWidth = 18;
}
