import { translationsFeatureKey, TranslationsState } from './translations-management.reducer';
import { createSelector } from '@ngrx/store';
import { TranslationsCategory } from '../models';
import { FieldSettingsModel } from '@syncfusion/ej2-dropdowns';
import { chain } from 'lodash';
import { Language } from '@medrecord/core';

export const getTranslationsState = (state: any): TranslationsState => state[translationsFeatureKey];

export const getAllTranslations = createSelector(
  getTranslationsState,
  (state: TranslationsState) => state.translations
);

export const getTranslationsByCategory = (category: TranslationsCategory) => {
  return createSelector(
    getTranslationsState,
    (state: TranslationsState) => {
      return state.translations[state.language][category].translations;
    }
  );
};

export const getCategories =  createSelector(
  getTranslationsState,
  (state: TranslationsState): FieldSettingsModel[] => state.categories
    .map(
      (category) => ({
        id: category,
        text: chain(category).words().map((word) => chain(word).toLower().upperFirst().value()).join(' ').value()
      })
    )
);

export const selectCurrentCategory = createSelector(
  getTranslationsState,
  (state) => state.selectedCategory
);

export const selectCurrentLanguage = createSelector(
  getTranslationsState,
  (state): Language => state.language
);

export const selectPagerData = createSelector(
  getTranslationsState,
  (state): { pages, total } => ({
    pages: state[state.language][state.selectedCategory].pages,
    total: state[state.language][state.selectedCategory].total
  })
);
