import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { getCustomerMetadata } from '@medrecord/services-customer-metadata';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'medrecord-auth-confirmation-info',
  templateUrl: './confirmation-info.component.html',
  styleUrls: ['./confirmation-info.component.scss']
})
export class ConfirmationInfoComponent implements OnDestroy {
  @Input()
  title: string;

  @Input()
  description: string;

  success: boolean;
  logoType: SafeUrl;
  clientName: string;
  private unsubscribe$ = new Subject<void>();
  
  constructor(
    private santitizer: DomSanitizer,
    private store: Store
  ) {
    this.observeCustomerMetadata();
  }
  
  private observeCustomerMetadata(): void {
    this.store.select(getCustomerMetadata).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((res) => {
      this.logoType = this.santitizer.bypassSecurityTrustUrl(res.general.logoInner);
      this.clientName = res.general.shortName;
    });
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}