import { TranslateService } from '@ngx-translate/core';
import { Directive } from '@angular/core';

@Directive()
export class PrivacyPolicyAbstractComponent {
  statements: { header: string, content: string }[] = [];
  
  constructor(
    public translateService: TranslateService,
  ) {
    this.addStatement();
  }
  
  private addStatement(statementIndex: number = 1): void {
    const headerStatementKey = 'privacy_policy_statement_header_' + statementIndex;
    const contentStatementKey = 'privacy_policy_statement_content_' + statementIndex;
    
    const headerTranslation = this.translateService.instant(headerStatementKey);
    const contentTranslation = this.translateService.instant(contentStatementKey);
    
    if ((headerTranslation !== headerStatementKey) || (contentTranslation !== contentStatementKey)) {
      this.statements.push({
        header: headerTranslation,
        content: contentTranslation,
      });
      
      this.addStatement(statementIndex + 1);
    }
  }
}