import { Component, Input, Output, ViewChild, EventEmitter, AfterContentInit } from '@angular/core';
import * as ejPopups from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'medrecord-dialog',
  templateUrl: './medrecord-dialog.component.html',
})
export class MedrecordDialogComponent implements AfterContentInit {
  @ViewChild('dialogComponent') dialogComponent: ejPopups.DialogComponent;

  @Input() headerText: string;
  @Input() cancelText: string;
  @Input() submitText: string;

  @Input()
  set open(isOpen: boolean) {
    this._open = isOpen;

    if (this.dialogComponent) {
      this.switchDialogState();
    }
  }

  @Output() closing = new EventEmitter();
  @Output() submitting = new EventEmitter();

  dialogContainer = document.body;

  private _open = false;

  ngAfterContentInit(): void {
    this.switchDialogState();
  }

  switchDialogState(): void {
    if (this._open) {
      this.dialogComponent.show();
    } else if (!!this.dialogComponent) {
      this.dialogComponent.hide();
    }
  }
}
