import { Component, ElementRef, Inject, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { HGraphCircle, UserDashboardGraphModel } from '../../models/interfaces';
import { ObservationThresholdItem } from '@managers/measurements';
import { GraphEnterElement, GraphTooltip, ObservationType } from '@medrecord/core';
import { MEDSAFE_ROUTE_NAMES, MedsafeRouteNames } from '@medrecord/routes-medsafe';
import { ActivatedRoute, Router } from '@angular/router';
import { HGraphUtils } from '../../utils/h-graph.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'medsafe-h-graph',
  templateUrl: './h-graph.component.html',
  styleUrls: ['./h-graph.component.scss'],
})
export class HGraphComponent implements OnChanges, OnDestroy {
  @ViewChild('graph', { static: true }) graphElement: ElementRef;
  @Input() statisticItems: ObservationThresholdItem[] = [];
  @Input() healthScore: string;
  @Input() config = {};
  @Input() showOnlyScore = false;
  @Input() screenSize;
  @Input() isMobile: boolean;

  private statisticsGraphModel: UserDashboardGraphModel[];

  readonly elementId = 'h-graph';
  readonly tooltipWrapperCssClass = 'd3-tooltip';

  graph: d3.Selection<any, any, any, any>;
  totalPoints: number;

  hideTooltip = (tooltip): void => {
    tooltip.style('display', 'none');
  };

  /**
   * Translations for measurements have a structure: measurements_{{ some middle path }}_{{ measurement type }}
   * */
  getMeasurementKey = (type: ObservationType, ...middlePath: string[]): string => {
    if (middlePath.length) {
      middlePath.unshift('');
    }

    return type + middlePath.join('_');
  };

  /**
   * Translation of the measurement` description.
   * */
  getTranslation = (type: ObservationType): string => {
    return this.translateService.instant(this.getMeasurementKey(type, 'title'));
  };

  /**
   * Short name of the measurement type stored in translations.
   * @note: Meddle path for a measurement abbreviations is "abbrev"
   * */
  getAbbreviation = (type: ObservationType): string => {
    return this.translateService.instant(this.getMeasurementKey(type, 'abbrev'));
  };

  constructor(
    private router: Router,
    private hGraphUtils: HGraphUtils,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,

    @Inject(MEDSAFE_ROUTE_NAMES) private medsafeRouteNames: MedsafeRouteNames
  ) {}

  ngOnChanges(): void {
    this.hGraphUtils.configure({
      initialRadius: 135,
      gapSize: 8,
      strokeWidth: 52,
      circleClasses: ['circle_abnormal', 'circle_normal', 'circle_abnormal'],
      radarRadius: 16,
      ...this.config,
    });

    this.initHGraph();
  }

  ngOnDestroy(): void {
    // destroy all created tooltips
    d3.selectAll(`.${this.tooltipWrapperCssClass}`).remove();
  }

  displayHGraph(): void {
    this.totalPoints = this.statisticsGraphModel.length;

    this.createHGraph();

    this.hGraphUtils.hGraphModel.circles.forEach((circle) => this.drawCircle(circle));

    this.loadData();
  }

  createHGraph(): void {
    d3.select(this.graphElement.nativeElement).select('svg').remove();

    this.graph = d3
      .select(this.graphElement.nativeElement)
      .append('svg')
      .attr('width', this.hGraphUtils.hGraphModel.graphDiameter)
      .attr('height', this.hGraphUtils.hGraphModel.graphDiameter)
      .append('g')
      .attr(
        'transform',
        `translate(${this.hGraphUtils.hGraphModel.translate.x},${this.hGraphUtils.hGraphModel.translate.y})`
      );
  }

  drawCircle(circle: HGraphCircle): void {
    const { transitionDuration, centerCoords, circleMinValue } = this.hGraphUtils.hGraphModel;

    this.graph
      .append('svg:circle')
      .attr('r', circle.radius + 'px')
      .attr('cx', centerCoords.x)
      .attr('cy', centerCoords.y)
      .attr('class', ['circle', circle.className].join(' '))
      .style('stroke-width', circleMinValue)
      .transition()
      .duration(transitionDuration)
      .style('stroke-width', `${circle.strokeWidth}px`);
  }

  loadData(): void {
    const tooltip = this.createTooltip();

    const elementEnter = this.graph
      .selectAll('.nodes')
      .data(this.statisticsGraphModel)
      .enter()
      .append('g')
      .attr('class', 'circle-holder');

    this.addTransformStatisticItemListener(elementEnter);
    this.addClickStatisticItemListener(elementEnter, tooltip);
    this.addHoverStatisticItemListener(elementEnter, tooltip);
    this.addBlurStatisticItemListener(elementEnter, tooltip);
    this.appendSeriesCircleToStatisticItem(elementEnter);
    this.appendSeriesTextToStatisticItem(elementEnter);
  }

  protected initHGraph(): void {
    this.statisticsGraphModel = this.statisticItems.reduce((acc: UserDashboardGraphModel[], item) => {
      if (item.value) {
        acc.push({
          ...item,
          offset: this.hGraphUtils.getOffset(item),
        });
      }

      return acc;
    }, []);

    this.displayHGraph();
  }

  private calcX(item: UserDashboardGraphModel, iterator): number {
    const { centerCoords, circleMaxValue, circleMinValue } = this.hGraphUtils.hGraphModel;

    return (
      centerCoords.x *
      (1 -
        (Math.max(item.offset, circleMinValue) / circleMaxValue) *
          Math.sin((iterator * 2 * Math.PI) / this.totalPoints))
    );
  }

  private calcY(item: UserDashboardGraphModel, iterator): number {
    const { centerCoords, circleMaxValue, circleMinValue } = this.hGraphUtils.hGraphModel;

    return (
      centerCoords.y *
      (1 -
        (Math.max(item.offset, circleMinValue) / circleMaxValue) *
          Math.cos((iterator * 2 * Math.PI) / this.totalPoints))
    );
  }

  private createTooltip(): GraphTooltip {
    return d3.select(`body`).append('div').attr('class', this.tooltipWrapperCssClass);
  }

  private appendSeriesCircleToStatisticItem(elementEnter: GraphEnterElement<UserDashboardGraphModel>): void {
    elementEnter
      .append('svg:circle')
      .attr('class', 'radar-chart-series')
      .attr('r', this.hGraphUtils.hGraphModel.radar.radius);
  }

  private appendSeriesTextToStatisticItem(elementEnter: GraphEnterElement<UserDashboardGraphModel>): void {
    elementEnter
      .append('text')
      .attr('class', 'series-text')
      .text((item: UserDashboardGraphModel) => {
        return this.getAbbreviation(item.observationType);
      });
  }

  private addTransformStatisticItemListener(elementEnter: GraphEnterElement<UserDashboardGraphModel>): void {
    elementEnter.attr('transform', (item: UserDashboardGraphModel, iterator) => {
      const x = this.calcX(item, iterator);
      const y = this.calcY(item, iterator);
      return `translate(${x}, ${y})`;
    });
  }

  private addClickStatisticItemListener(
    elementEnter: GraphEnterElement<UserDashboardGraphModel>,
    tooltip: GraphTooltip
  ): void {
    elementEnter.on('click', () => {
      this.hideTooltip(tooltip);

      this.router.navigate([this.medsafeRouteNames.Measurements.Entry], {
        relativeTo: this.activatedRoute.parent,
      });
    });
  }

  private addHoverStatisticItemListener(
    elementEnter: GraphEnterElement<UserDashboardGraphModel>,
    tooltip: GraphTooltip
  ): void {
    const hGraph = this;

    elementEnter.on('mouseover', function (_, item: UserDashboardGraphModel): void {
      const { left, top } = this.getBoundingClientRect();
      const { height } = this.getBBox();
      const leftOffset = -8;
      const topOffset = 9;

      tooltip
        .style('position', 'absolute')
        .style('z-index', '500')
        .style('display', 'block')
        .style('left', `${left + leftOffset}px`)
        .style('top', `${top + height + topOffset}px`)
        .html(() => {
          return `
              <div class="d3-tooltip__title">${hGraph.getTranslation(item.observationType)}</div>
              <div class="d3-tooltip__content">${item.helpText || ''}</div>
            `;
        });
    });
  }

  private addBlurStatisticItemListener(
    elementEnter: GraphEnterElement<UserDashboardGraphModel>,
    tooltip: GraphTooltip
  ): void {
    elementEnter.on('mouseleave', () => {
      this.hideTooltip(tooltip);
    });
  }
}
