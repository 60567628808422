import { GenericMapper } from './generic.mapper';
import { CollapsibleTable, ColumnDefinition } from '../interfaces';
import { Resource, ResourceType } from '../enums';
import { ClinicalStatus } from '@medrecord/core';
import { MapperUtil } from './mapper.util';

export class EpisodesMapper extends GenericMapper<EpisodeColumn> {

  protected get title(): string {
    return 'gp-episode-2020';
  }

  protected get mainKey(): Resource {
    return Resource.gpEpisode2020;
  }

  protected get mainType(): ResourceType {
    return ResourceType.episodeOfCare;
  }

  protected get sortBy(): EpisodeColumn[] {
    return [
      EpisodeColumn.stopDate,
      EpisodeColumn.lastContact,
      EpisodeColumn.firstContact,
      EpisodeColumn.startDate
    ];
  }

  protected get groupBy(): EpisodeColumn {
    return EpisodeColumn.status;
  }

  protected get groupSortOrder(): { [key: string]: number } {
    return {
      [ClinicalStatus.Active]: 0,
      [ClinicalStatus.Inactive]: 1
    };
  }

  protected get activeGroups(): string[] {
    return [ClinicalStatus.Active];
  }

  protected get columns(): ColumnDefinition<EpisodeColumn>[] {
    return [
      {
        property: EpisodeColumn.episode,
        title: 'my_doctor_episode',
        width: 11,
        lines: [{
          type: 'string'
        }]
      },
      {
        property: EpisodeColumn.lastContact,
        title: 'my_doctor_last_contact_date',
        width: 13,
        lines: [{
          type: 'date'
        }]
      },
      {
        property: EpisodeColumn.firstContact,
        title: 'my_doctor_first_contact_date',
        width: 12,
        lines: [{
          type: 'date'
        }]
      },
      {
        property: EpisodeColumn.icpc,
        title: 'healthcare_providers_icpc',
        width: 13,
        lines: [{
          type: 'string'
        }]
      },
      {
        property: EpisodeColumn.icpcDescription,
        title: 'my_doctor_icpc_description',
        width: 13,
        lines: [{
          type: 'string'
        }]
      },
      {
        property: EpisodeColumn.startDate,
        title: 'my_doctor_start_date',
        width: 13,
        lines: [{
          type: 'date'
        }]
      },
      {
        property: EpisodeColumn.stopDate,
        title: 'my_doctor_stop_date',
        width: 24,
        lines: [{
          type: 'date'
        }]
      }
    ];
  }

  protected get collapsibleTables(): CollapsibleTable<EpisodeColumn>[] {
    return null;
  }

  protected getAllRows(): { [key in EpisodeColumn]?: any[] }[] {
    return this.mainResources.map(e => { // e = episode
      return {
        [EpisodeColumn.status]: [e.status === ClinicalStatus.Active ? ClinicalStatus.Active : ClinicalStatus.Inactive],
        [EpisodeColumn.episode]: [e.title],
        [EpisodeColumn.lastContact]: [e.dateLastEncounter],
        [EpisodeColumn.firstContact]: [e.dateFirstEncounter],
        [EpisodeColumn.icpc]: [this.getReference(ResourceType.condition, e.diagnosisConditions?.[0])?.name?.coding?.[0]?.code],
        [EpisodeColumn.icpcDescription]: [MapperUtil.codeableConcept(this.getReference(ResourceType.condition, e.diagnosisConditions?.[0])?.name)],
        [EpisodeColumn.startDate]: [e.period.start],
        [EpisodeColumn.stopDate]: [e.period.end]
      };
    });
  }
}

enum EpisodeColumn {
  status = 'status', // grouping
  episode = 'episode',
  lastContact = 'lastContact',
  firstContact = 'firstContact',
  icpc = 'icpc',
  icpcDescription = 'icpcDescription',
  startDate = 'startDate',
  stopDate = 'stopDate',
}
