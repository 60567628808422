import { createSelector } from '@ngrx/store';
import { userStatusFeatureKey, UserStatusState } from './user-status.reducer';

export const getUserStatusesState = (state: UserStatusState) => state[userStatusFeatureKey];

export const getUserStatuses = createSelector(
  getUserStatusesState,
  (state: UserStatusState) => {
    return state.userStatuses;
  }
);