import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CareproviderDataService, TransactionMode } from '@managers/my-doctor';
import { TabItemModel } from '@syncfusion/ej2-angular-navigations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'doctor-gui-dataservices-tabs',
  templateUrl: './dataservices-tabs.component.html',
  styleUrls: ['./dataservices-tabs.component.scss']
})
export class DataservicesTabsComponent {
  @Input() set dataServices(data: {
    dataservices: CareproviderDataService[],
    selectedDataserviceId: string
  }) {
    this.dataServicesTabs = data.dataservices.map(dataservice => {
      const transaction = dataservice.transactions.find(t => t.mode === TransactionMode.pull);
      return ({
        header: {
          id: dataservice.id,
          text: this.translateService.instant(transaction.id)
        }
      });
    });
    this.selectedTabIndex = this.dataServicesTabs
      .findIndex(tab => tab.header.id === data.selectedDataserviceId);
  }

  @Output() tabSelected: EventEmitter<string> = new EventEmitter<string>();

  dataServicesTabs: (TabItemModel & { header: { id: string } })[] = [];
  selectedTabIndex = 0;

  constructor(
    private translateService: TranslateService
  ) {
  }

  selectTab({ selectedIndex }): void {
    const selectedHeader = this.dataServicesTabs[selectedIndex].header;
    this.tabSelected.emit(selectedHeader.id);
  }
}
