import { Component, Input } from '@angular/core';
import { MedrecordInputNgControlComponentBase } from '../../base/medrecord-input-ng-control-component-base.component';
import { MedrecordMoment } from '@medrecord/services-datetime';
import { ChangedEventArgs } from '@syncfusion/ej2-angular-calendars';

export declare type CalendarView = 'Month' | 'Year' | 'Decade';

@Component({
  selector: 'medrecord-datepicker',
  templateUrl: './medrecord-datepicker.component.html',
  styleUrls: ['./medrecord-datepicker.component.scss']
})
export class MedrecordDatepickerComponent extends MedrecordInputNgControlComponentBase {
  
  @Input() dateFormat: string = "DD/MM/YYYY";
  @Input() firstDayOfWeek = 1;
  @Input() startCalendarView: CalendarView = 'Year';
  @Input() calendarViewDepth: CalendarView = 'Month';
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() label: string;
  
  handleChange(data: ChangedEventArgs): void {
    if (!data?.value) {
      return;
    }

    this.value = MedrecordMoment(data.value).format(this.dateFormat);
  }
  
  markAsTouched(): void {
    this.control.control.markAsTouched();
  }
  
}
