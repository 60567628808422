import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-lock',
  templateUrl: './lock.component.svg',
  styleUrls: ['./lock.component.scss'],
})
export class LockComponent extends MedrecordIcon {
  viewHeight = 16;
  viewWidth = 12;
}
