import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-body-temperature',
  templateUrl: './body-temperature.component.svg',
  styleUrls: [],
})
export class BodyTemperatureComponent extends MedrecordIcon {
  viewHeight: number = 24;
  viewWidth: number = 24;
}
