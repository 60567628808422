import { TransactionMode } from '../models/enums/transaction-mode.enum';
import { Careprovider } from '../models/interfaces/dtos/careprovider.interface';
import { PullTransaction } from '../models/enums/pull-transaction.enum';

export const TEST_PROVIDER_ID = 'test@medrecord';

export const TEST_PROVIDER: Careprovider = {
  id: TEST_PROVIDER_ID,
  addresses: [
    {
      address: 'Marconistraat 16 \r\n 3029 AK Rotterdam',
      city: 'Rotterdam',
      country: null,
      firstLine: null,
      geolocation: null,
      postalCode: '3029 AK',
      state: null,
      streetName: 'Marconistraat',
      streetNumber: '16',
      type: 'Practice',
    },
  ],
  agb: null,
  displayName: 'Medrecord Test',
  email: TEST_PROVIDER_ID,
  hrn: null,
  oin: null,
  phone: '',
  speciality: '',
  ura: null,
  dataServices: [
    {
      id: '28',
      name: 'Huisartsgegevens',
      transactions: [
        {
          id: PullTransaction.gp2020,
          code: 'HGB',
          version: '1.1',
          mode: TransactionMode.pull,
          informationStandard: 'gp-data',
        },
      ],
    },
    {
      id: '48',
      name: 'Basisgegevens zorg',
      transactions: [
        {
          id: PullTransaction.bgz2020,
          code: 'HGB',
          version: '1.1',
          mode: TransactionMode.pull,
          informationStandard: 'bgz',
        },
      ],
    },
    {
      id: 'unknown-3',
      name: 'PDF-A',
      transactions: [
        {
          id: PullTransaction.pdfA2020,
          code: 'HGB',
          version: '1.1',
          mode: TransactionMode.pull,
          informationStandard: 'pdf-a',
        },
      ],
    },
    {
      id: '61',
      name: 'BGLZ',
      transactions: [
        {
          id: PullTransaction.bglz2020,
          code: 'HGB',
          version: '1.1',
          mode: TransactionMode.pull,
          informationStandard: 'bglz',
        },
      ],
    },
    {
      id: 'ggz2020',
      name: 'Bg GGZ',
      transactions: [
        {
          id: PullTransaction.ggz2020,
          code: 'HGB',
          version: '1.1',
          mode: TransactionMode.pull,
          informationStandard: 'ggz',
        },
      ],
    },
    {
      id: '64',
      name: 'Maternity Care',
      transactions: [
        {
          id: PullTransaction.maternityCare2020,
          code: 'HGB',
          version: '1.1',
          mode: TransactionMode.pull,
          informationStandard: 'DONT KNOW',
        },
      ],
    },
  ],
  userScope: true,
};
