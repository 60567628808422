export enum BloodPressureBodySite {
  ANKLE_REGION = "ANKLE_REGION",
  FINGER = "FINGER",
  THIGH = "THIGH",
  UPPER_ARM = "UPPER_ARM",
  WRIST_REGION = "WRIST_REGION",
  LEFT_ANKLE = "LEFT_ANKLE",
  LEFT_THIGH = "LEFT_THIGH",
  LEFT_UPPER_ARM = "LEFT_UPPER_ARM",
  LEFT_WRIST = "LEFT_WRIST",
  RIGHT_ANKLE = "RIGHT_ANKLE",
  RIGHT_THIGH = "RIGHT_THIGH",
  RIGHT_UPPER_ARM = "RIGHT_UPPER_ARM",
  RIGHT_WRIST = "RIGHT_WRIST",
}