import { Action, createReducer, on } from '@ngrx/store';
import { activateFeedbackAction, clearFeedbackAction } from './feedback-manager.actions';

export const feedbackManagerFeatureKey = 'feedback-manager';

export interface FeedbackManagerState {
  active: boolean;
}

export const initialState: FeedbackManagerState = {
  active: false,
};

export const feedbackManagerReducer = createReducer(
  initialState,
  on(activateFeedbackAction, (state) => ({
    ...state,
    active: true,
  })),
  on(clearFeedbackAction, (state) => ({
    ...state,
    active: false,
  }))
);

export const reducer = (state: FeedbackManagerState, action: Action): FeedbackManagerState => {
  return feedbackManagerReducer(state, action);
};
