import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-medication-alert',
  templateUrl: './medication-alert.component.svg',
})
export class MedicationAlertComponent extends MedrecordIcon {
  viewHeight = 16;
  viewWidth = 16;
}
