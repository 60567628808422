import { Component } from '@angular/core';
import {
  getActiveTransactionId,
  performAuthorizationForCareproviderAction,
  PullTransaction
} from '@managers/my-doctor';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'doctor-gui-dataservice-details-container',
  templateUrl: './dataservice-details-container.component.html'
})
export class DataserviceDetailsContainerComponent {
  transactionId$: Observable<PullTransaction> = this.store.select(getActiveTransactionId);

  constructor(
    private store: Store
  ) {
  }

  public cancelLongTerm(): void {
    this.store.dispatch(performAuthorizationForCareproviderAction());
  }
}
