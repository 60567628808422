import { createSelector } from '@ngrx/store';
import { FeedbackManagerState, feedbackManagerFeatureKey } from './feedback-manager.reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFeedbackManagerState: (state: FeedbackManagerState) => any = (state: FeedbackManagerState) =>
  state[feedbackManagerFeatureKey];

export const isFeedbackActive = createSelector(
  getFeedbackManagerState,
  (state: FeedbackManagerState): boolean => state.active
);
