import { createSelector } from '@ngrx/store';
import { CalendarAppointment } from '../models/interfaces/calendar-appointment.interface';
import { calendarManagerFeatureKey, CalendarManagerState } from './calendar-manager.reducer';
import { QualificationListItem } from '../models/interfaces/qualification-list-item.interface';

export const getCalendarManagerState = (state: CalendarManagerState) => state[calendarManagerFeatureKey];

export const getCarePlanAppointments = createSelector(
  getCalendarManagerState,
  (state: CalendarManagerState): CalendarAppointment[] => state.appointments
);

export const getQualifications = createSelector(
  getCalendarManagerState,
  (state: CalendarManagerState): QualificationListItem[] => state.qualifications
);

export const getCarePlanId = createSelector(
  getCalendarManagerState,
  (state: CalendarManagerState): string => state.carePlanId
);
