import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  createObservationAction,
  createObservationFailtureAction,
  createObservationSuccessAction,
  createQuantityObservationAction,
  deleteObservationAction,
  deleteObservationSuccessAction,
  loadAllowedObservationTypes,
  loadAllowedObservationTypesFailure,
  loadAllowedObservationTypesSuccess,
  loadUserNetworkForMeasurementsAction,
  loadUserNetworkForMeasurementsSuccessAction,
  loadUserObservations,
  loadUserObservationsSuccess,
  loadUserQuantityObservations,
  loadUserThresholds,
  loadUserThresholdsFailure,
  loadUserThresholdsSuccess,
  setAllowedObservationTypesAction,
  setAllowedObservationTypesSuccessAction,
  setCareplanObservationTypesAction,
  updateObservationFailtureAction,
  updateObservationSuccessAction,
  updateQuantityObservationAction,
} from './measurements-manager.actions';
import { selectAllowedObservationTypes } from './measurements-manager.selectors';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { ObservationsService } from '../services/observations.service';
import { ThresholdsService } from '../services/thresholds.service';
import { getMainUserTabData, selectCurrentID } from '@medrecord/managers-users';
import { Store } from '@ngrx/store';
import { uniq, uniqBy } from 'lodash';
import { ObservationType } from '@medrecord/core';
import { selectDefaultObservationTypes } from '@managers/middleware';
import { ObservationThresholdItem } from '@managers/measurements';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { forkJoin } from 'rxjs';
import { NetworkManagerService } from '@managers/network';

@Injectable()
export class MeasurementsManagerEffects {
  @Effect()
  loadObservations$ = this.actions$.pipe(
    ofType(loadUserObservations),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([payload, userId]) => {
      return this.observationService.getObservations(userId, payload.observationType, payload.careplanId).pipe(
        switchMap((observations) => {
          return [
            loadUserObservationsSuccess({
              observations,
              careplanId: payload.careplanId,
              observationType: payload.observationType,
            }),
          ];
        })
      );
    })
  );

  @Effect()
  loadQuantityObservations$ = this.actions$.pipe(
    ofType(loadUserQuantityObservations),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([payload, userId]) => {
      return this.observationService.getQuantityObservations(userId, payload.observationType, payload.careplanId).pipe(
        switchMap((observations) => {
          return [
            loadUserObservationsSuccess({
              observations,
              careplanId: payload.careplanId,
              observationType: payload.observationType,
            }),
          ];
        })
      );
    })
  );

  @Effect()
  loadAllowedObservationTypes$ = this.actions$.pipe(
    ofType(loadAllowedObservationTypes),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([payload, userId]) => {
      return this.observationService.getAllowedObservationTypes(userId, payload.careplanId).pipe(
        switchMap(({ settings }) => {
          return [
            ...(payload.successActions || []),
            loadAllowedObservationTypesSuccess({ allowedObservationTypes: settings.observationTypes }),
          ];
        }),
        // When it returns status `Not Found` which means there are no settings existing
        // In that case we are applying error actions from payload to handle that part
        catchError(({ error }) =>
          error.status === 'Not Found'
            ? payload.errorActions
            : [
                loadAllowedObservationTypesFailure({ error }),
                addErrorToast(getErrorToastBodyUtil('load_observation_error', error)),
              ]
        )
      );
    })
  );

  @Effect()
  loadThresholds$ = this.actions$.pipe(
    ofType(loadUserThresholds),
    withLatestFrom(
      this.store.select(selectCurrentID),
      this.store.select(selectAllowedObservationTypes),
      this.store.select(selectDefaultObservationTypes)
    ),
    switchMap(([{ carePlanId, observationTypes }, userId, allowedObservationTypes, defaultObservations]) => {
      const types = uniq<ObservationType>([
        ...(!carePlanId ? defaultObservations || [] : []),
        ...(observationTypes || []),
        ...allowedObservationTypes,
      ]);

      return this.thresholdsService.getThresholds(userId, types, carePlanId).pipe(
        switchMap(({ data, healthScore }) => {
          if (!carePlanId) {
            defaultObservations.forEach((type) => {
              data.push({ observationType: type, value: null, score: null });
            });
          } else {
            types.forEach((type) => {
              data.push({ observationType: type, value: null, score: null });
            });
          }

          return [
            loadUserThresholdsSuccess({
              thresholds: uniqBy<ObservationThresholdItem>(data, 'observationType'),
              healthScore,
            }),
          ];
        }),
        catchError(({ error }) => [
          addErrorToast(getErrorToastBodyUtil('common_error', error)),
          loadUserThresholdsFailure({ error }),
        ])
      );
    })
  );

  @Effect()
  createObservation$ = this.actions$.pipe(
    ofType(createObservationAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ observationType, payload }, userId]) => {
      return this.observationService.createObservation(userId, observationType, payload).pipe(
        switchMap((response) => {
          this.store.dispatch(
            addSuccessToast({
              title: 'create_measurement_success_title',
              content: 'create_measurement_success_content',
            })
          );

          return [createObservationSuccessAction({ observationType, observation: { ...payload, ...response } })];
        }),
        catchError(({ error }) => [
          createObservationFailtureAction(),
          addErrorToast(getErrorToastBodyUtil('create_observation_error', error)),
        ])
      );
    })
  );

  @Effect()
  createQuantityObservation$ = this.actions$.pipe(
    ofType(createQuantityObservationAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ observationType, payload }, userId]) => {
      return this.observationService.createQuantityObservation(userId, payload).pipe(
        switchMap((response) => {
          this.store.dispatch(
            addSuccessToast({
              title: 'create_measurement_success_title',
              content: 'create_measurement_success_content',
            })
          );
          return [createObservationSuccessAction({ observationType, observation: { ...payload, ...response } })];
        }),
        catchError(({ error }) => [
          createObservationFailtureAction(),
          addErrorToast(getErrorToastBodyUtil('create_observation_error', error)),
        ])
      );
    })
  );

  @Effect()
  updateQuantityObservation$ = this.actions$.pipe(
    ofType(updateQuantityObservationAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ observationType, payload }, userId]) => {
      return this.observationService.updateQuantityObservation(userId, payload).pipe(
        switchMap((response) => {
          this.store.dispatch(
            addSuccessToast({
              title: 'measurements_update_measurement_success_title',
              content: 'measurements_update_measurement_success_content',
            })
          );
          return [updateObservationSuccessAction({ observationType, observation: { ...payload, ...response } })];
        }),
        catchError(({ error }) => [
          updateObservationFailtureAction(),
          addErrorToast(getErrorToastBodyUtil('update_observation_error', error)),
        ])
      );
    })
  );

  @Effect()
  deleteObservation$ = this.actions$.pipe(
    ofType(deleteObservationAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ observationId }, userId]) => {
      return this.observationService.deleteObservations(userId, observationId).pipe(
        switchMap(() => {
          this.store.dispatch(
            addSuccessToast({
              title: 'measurements_delete_measurement_title',
              content: 'measurements_delete_measurement_content',
            })
          );
          return [deleteObservationSuccessAction()];
        }),
        catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('delete_observation_error', error))])
      );
    })
  );

  @Effect()
  loadUserNetworkForMeasurements$ = this.actions$.pipe(
    ofType(loadUserNetworkForMeasurementsAction),
    withLatestFrom(this.store.select(getMainUserTabData), this.store.select(selectCurrentID)),
    switchMap(([, mainUserTabData, userId]) => {
      return forkJoin({
        userNetwork: this.networkService.getUserNetwork(userId),
        relatedPeople: this.networkService.getRelatedPeople(userId),
      }).pipe(
        switchMap(({ userNetwork, relatedPeople }) => {
          return [
            loadUserNetworkForMeasurementsSuccessAction({
              userNetwork: [
                ...(mainUserTabData.id === userId
                  ? [
                      {
                        id: mainUserTabData.user.patientId || mainUserTabData.user.practitionerId,
                        resourceType: mainUserTabData.user.patientId ? 'Patient' : 'Practitioner',
                        userId,
                        ...mainUserTabData.user,
                      },
                    ]
                  : []),
                ...userNetwork,
                ...relatedPeople,
              ],
            }),
          ];
        })
      );
    }),
    catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('load_user_network_error', error))])
  );

  @Effect()
  setAllowedObservationTypes$ = this.actions$.pipe(
    ofType(setAllowedObservationTypesAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ observationTypes, carePlanId }, userId]) => {
      return this.observationService.setAllowedObservationTypes(userId, observationTypes, carePlanId).pipe(
        switchMap(() => {
          this.store.dispatch(
            addSuccessToast({
              title: 'measurements_update_settings_title',
              content: 'measurements_update_settings_content',
            })
          );
          return [setAllowedObservationTypesSuccessAction()];
        })
      );
    }),
    catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('set_settings_error', error))])
  );

  @Effect()
  setCareplanObservationTypes$ = this.actions$.pipe(
    ofType(setCareplanObservationTypesAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ observationTypes, carePlanId }, userId]) => {
      return this.observationService
        .setCareplanObservationTypes(userId, observationTypes, carePlanId)
        .pipe(switchMap(() => []));
    }),
    catchError(({ error }) => [addErrorToast(getErrorToastBodyUtil('set_settings_error', error))])
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private observationService: ObservationsService,
    private thresholdsService: ThresholdsService,
    private networkService: NetworkManagerService
  ) {}
}
