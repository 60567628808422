import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MedicationsManagerEffects } from './medications-manager.effects';
import { medicationsManagerFeatureKey, reducer } from './medications-manager.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(medicationsManagerFeatureKey, reducer),
    EffectsModule.forFeature([ MedicationsManagerEffects ])
  ]
})
export class MedicationsManagerStateModule {}