import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';
import * as faker from 'faker';
import { mapEnum } from '@medrecord/tools-utils';
import { EnvService } from '@medrecord/core';
import { UserStatus } from '../models/user-status.enum';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  constructor(
    private envService: EnvService
    ) {
  }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const handleRoute = (): Observable<HttpEvent<any>> => {
      switch (true) {
        case (request.url === this.envService.backend + '/status'):
          return this.ok<{ data: { id: string, status: UserStatus }}>({
            data: {
              id: request.params.get('userId'),
              status: faker.random.arrayElement(mapEnum(UserStatus))
            }
          });
        default:
          return next.handle(request);
      }
    };
    
    return of(null).pipe(mergeMap(handleRoute)).pipe(materialize()).pipe(delay(500)).pipe(dematerialize());
  }
  
  private ok = <BodyType>(body?: BodyType): Observable<HttpEvent<BodyType>> => {
    return of(new HttpResponse({ status: 200, body }));
  }
}