import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCustomerShortName } from '@medrecord/services-customer-metadata';
import { resetSignUpData } from '@medrecord/managers-auth';
import { StorageService } from '@medrecord/tools-storage';
import { EnvService, StorageKeys } from '@medrecord/core';

@Component({
  selector: 'medrecord-auth-welcome',
  templateUrl: './welcome.component.html',
})
export class WelcomeComponent implements OnInit {
  clientName$ = this.store.select(getCustomerShortName);
  readonly isOAuth = this.storage.getItem<boolean>(StorageKeys.isOAuth, false);
  readonly googleAuthDisabled = this.envService.googleAuthDisabled;

  public disableRegister: boolean;

  constructor(private store: Store, private storage: StorageService<StorageKeys>, private envService: EnvService) {}

  ngOnInit(): void {
    this.store.dispatch(resetSignUpData());

    this.disableRegister = this.envService.disableRegister;
  }
}
