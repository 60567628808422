import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CareplansManagerStateModule } from './state-management/careplans-manager-state.module';
import { CareplanService } from './services/careplan.service';

@NgModule({
  imports: [CommonModule, CareplansManagerStateModule],
  providers: [CareplanService],
})
export class CareplansManagerModule {}
