import { Component, OnInit } from '@angular/core';
import { navigateToTwofaRecovery, setTwofaFlowAction, TwofaFlow } from '@medrecord/managers-auth';
import { Store } from '@ngrx/store';

@Component({
  selector: 'medrecord-auth-twofa-verification-container',
  templateUrl: './twofa-verification-container.component.html'
})
export class TwofaVerificationContainerComponent implements OnInit {

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(setTwofaFlowAction({ flow: TwofaFlow.Verification }));
  }

  redirectToRecovery(): void {
    this.store.dispatch(navigateToTwofaRecovery());
  }
}
