export class FilterBuilder {

  private filterString: string;

  private constructor(init: string) {
    this.filterString = init;
  }

  static defaultFilter(): FilterBuilder {
    return new FilterBuilder(
      `published_at:<=${new Date().toISOString()}`
      + `+status:published`
    );
  }

  static medsafeFilter(): FilterBuilder {
    return new FilterBuilder(
      `tag:-hash-veelgesteldevragen`
      + `+tag:-hash-algemeen`
      + `+tag:-hash-downloaden`
      + `+tag:-hash-medmij`
      + `+tag:-hash-blog`
      + `+published_at:<=${new Date().toISOString()}`
      + `+status:published`
    );
  }

  static empty(): FilterBuilder {
    return new FilterBuilder('');
  }

  static existing(filter: string): FilterBuilder {
    return new FilterBuilder(filter);
  }

  addHashTag(tag: string): FilterBuilder {
    if (tag) {
      return this.addTag(`hash-${tag}`);
    }
    return this;
  }

  addTag(tag: string): FilterBuilder {
    if (tag) {
      this.filterString = [this.filterString, `tag:${tag}`].join('+');
    }
    return this;
  }

  excludeTag(tag: string): FilterBuilder {
    if (tag) {
      return this.addTag(`-${tag}`);
    }
    return this;
  }

  addTags(tags: string[]): FilterBuilder {
    if (tags?.length) {
      this.filterString = [this.filterString, `tags:[${tags.join(',')}]`].join('+');
    }
    return this;
  }

  add(item: string): FilterBuilder {
    if (item) {
      this.filterString = [this.filterString, item].join('+');
    }
    return this;
  }

  build(): string {
    return this.filterString;
  }

}