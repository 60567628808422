import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@medrecord/core';
import { IFeedbackPayload } from '../model/IFeedbackPayload';
import { detect } from 'detect-browser';
import { IFeedbackServerModel } from '../model/IFeedbackServerModel';
import { parseBrowserEncodedBase64 } from '@medrecord/tools-utils';

@Injectable()
export class FeedbackService {
  readonly browserInfo = detect();

  sendFeedback(data: IFeedbackPayload) {
    return this.http.post(`${this.envService.backend}/feedback`, this.prepareFeedbackForServer(data));
  }

  private prepareFeedbackForServer(data: IFeedbackPayload): IFeedbackServerModel {
    const parsedBase64 = parseBrowserEncodedBase64(data.imageDataUrl);

    return {
      note: data.description,
      upload: {
        imageBase64Data: parsedBase64.base64String,
        imageContentType: parsedBase64.mimeType,
      },
      browser: {
        appCodeName: this.browserInfo.name,
        appName: this.browserInfo.name,
        appVersion: this.browserInfo.version,
        cookieEnabled: navigator.cookieEnabled,
        onLine: navigator.onLine,
        platform: this.browserInfo.os,
        plugins: [],
        userAgent: navigator.userAgent,
        consoleErrors: data.errors,
      },
      url: window.location.href,
      email: data.email,
    };
  }
  constructor(private http: HttpClient, private envService: EnvService) {}
}
