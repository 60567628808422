<div class="d-flex align-items-center pagination-wrapper" *ngIf="showPagination()">
  <div class="items-select d-flex align-items-center">
    <span class="mx-3 text-black">{{ 'common_page_items_label' | translate }}</span>
    <ejs-dropdownlist
      [dataSource]="pageItemsCount"
      placeholder="Items"
      [value]="settings.pageSize.toString() || pageItemsCount[0]"
      (change)="changeItemsPerPage($event)"
    ></ejs-dropdownlist>
  </div>

  <div class="ml-5">
    <button
      class="btn btn-primary-ultra-light mx-2 text-white-300"
      (click)="prevPage()"
      [disabled]="settings.currentPage === 1"
    >
      <i class="fas fa-chevron-left text-primary"></i>
    </button>
    <span class="text-black">
      {{ startRange }} - {{ endRange }} {{ 'common_of' | translate }} {{ settings.totalRecordsCount }}
    </span>
    <button
      class="btn btn-primary-ultra-light mx-2 text-white-300"
      (click)="nextPage()"
      [disabled]="settings.currentPage >= totalPages"
    >
      <i class="fas fa-chevron-right text-primary"></i>
    </button>
  </div>
</div>
