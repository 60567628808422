<!-- Back button -->
<div class="row">
  <div class="col-12">
    <div class="d-flex align-items-center text-primary text-xl font-weight-bold">
      <medrecord-ui-back-icon-button
        tooltip="my_doctor_back_to_careproviders"
        (onBack)="navigateBackToCareproviders()"
      ></medrecord-ui-back-icon-button>
      {{ 'common_back' | translate }}
    </div>
  </div>
</div>

<!-- "My data" label-->
<div class="row mt-2">
  <div class="col-12">
    <h3 class="font-weight-bold">{{ 'my_doctor_my_data' | translate }}</h3>
  </div>
</div>

<!-- Details of the selected careprovider -->
<div class="row mt-3">
  <div class="col-12">
    <doctor-gui-careprovider-short-details-container></doctor-gui-careprovider-short-details-container>
  </div>
</div>

<!-- Careprovider name again, and delete button-->
<div class="row align-items-center mt-3">
  <div class="col-12">
    <div class="d-flex flex-wrap">
      <div class="d-flex align-items-center">
        <h3 class="font-weight-bold">{{ careproviderName }}</h3>
      </div>

      <div class="d-flex flex-wrap ml-auto">
        <div class="d-flex">
          <doctor-gui-test-careprovider-status-buttons-container></doctor-gui-test-careprovider-status-buttons-container>
          <div>
            <button (click)="getLogsAndOpenModal()" class="btn btn-outline-primary btn-no-outline">
              {{ 'healthcare_providers_view_logs' | translate | titlecase }}
            </button>
          </div>
          <div>
            <button class="button btn btn-outline-primary mr-3" type="button" (click)="deleteData()">
              {{ 'my_doctor_delete_data' | translate | titlecase }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Divider -->
<div class="row">
  <div class="col-12">
    <hr class="divider my-2" />
  </div>
</div>

<!-- Tabs of data services, and chips of resources -->
<doctor-gui-dataservices
  *ngIf="dataserviceId; else noData"
></doctor-gui-dataservices>
<ng-template #noData>
  <div class="row">
    <div class="col-12 my-5 d-flex justify-content-center">{{ 'my_doctor_no_data' | translate }}</div>
  </div>
</ng-template>

<doctor-gui-delete-data-modal-container></doctor-gui-delete-data-modal-container>
<doctor-gui-logs-modal-container></doctor-gui-logs-modal-container>
<doctor-gui-resources-modal-container></doctor-gui-resources-modal-container>
<doctor-gui-parameters-modal-container></doctor-gui-parameters-modal-container>
<doctor-gui-test-careprovider-modal-container></doctor-gui-test-careprovider-modal-container>
