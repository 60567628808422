import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyDoctorRoutingModule } from './my-doctor-routing.module';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { TranslateModule } from '@ngx-translate/core';
import { MyDoctorWrapperComponent } from './containers/common/my-doctor-wrapper/my-doctor-wrapper.component';
import { CareprovidersPageComponent } from './components/careprovider/careproviders-page/careproviders-page.component';
import {
  DetailRowService,
  GridModule,
  PageService,
  ResizeService,
  VirtualScrollService
} from '@syncfusion/ej2-angular-grids';
import { MedsafeSharedModule } from '@medsafe/shared';
import { MedrecordUiModule } from '@medrecord/ui';
import { ResourcesComponent } from './components/resource/resources/resources.component';
import { ResourceComponent } from './components/resource/resource/resource.component';
import { MedrecordIconsModule } from '@medrecord/icons';
import { FieldGroupComponent } from './components/field/field-group/field-group.component';
import { MyDoctorResourceMappingService } from '@managers/my-doctor';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import {
  TestCareproviderModalComponent
} from './components/modals/test-careprovider-modal/test-careprovider-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import {
  FetchResourceResponseComponent
} from './components/common/fetch-resource-response/fetch-resource-response.component';
import { MedmijAuthBackComponent } from './components/common/medmij-auth-back/medmij-auth-back.component';
import { LogsModalComponent } from './components/modals/logs-modal/logs-modal.component';
import {
  TestCareproviderStatusModalComponent
} from './components/modals/test-careprovider-status-modal/test-careprovider-status-modal.component';
import { ResourceGroupComponent } from './components/resource/resource-group/resource-group.component';
import {
  PortabilityReportButtonModalComponent
} from './components/portability-report-button-modal/portability-report-button-modal.component';
import { ParametersModalComponent } from './components/modals/parameters-modal/parameters-modal.component';
import { FieldMultipleComponent } from './components/field/resource-field-multiple/field-multiple.component';
import {
  DeleteAllDataButtonModalComponent
} from './components/modals/delete-all-data-button-modal/delete-all-data-button-modal.component';
import {
  CareproviderDetailsPageContainerComponent
} from './containers/careprovider/careprovider-details-page-container/careprovider-details-page-container.component';
import { PersonalDetailsComponent } from './components/gp-data/personal-details/personal-details.component';
import { CheckBoxModule, ChipListModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { CoreModule } from '@medrecord/core';
import {
  GpDataTablesContainerComponent
} from './containers/gp-data/gp-data-tables-container/gp-data-tables-container.component';
import { BadgeStatusComponent } from './components/gp-data/badge-status/badge-status.component';
import {
  CareprovidersPageContainerComponent
} from './containers/careprovider/careproviders-page-container/careproviders-page-container.component';
import {
  CareproviderDetailsPageComponent
} from './components/careprovider/careprovider-details-page/careprovider-details-page.component';
import {
  DataservicesTabsContainerComponent
} from './containers/dataservice/dataservices-tabs-container/dataservices-tabs-container.component';
import { DataservicesTabsComponent } from './components/dataservice/dataservices-tabs/dataservices-tabs.component';
import {
  ResourcesChipsContainerComponent
} from './containers/dataservice/resources-chips-container/resources-chips-container.component';
import { ResourcesChipsComponent } from './components/dataservice/resources-chips/resources-chips.component';
import { RecordContainerComponent } from './containers/dataservice/dataservice-wrapper/record-container.component';
import { ResourcesModalComponent } from './components/modals/resources-modal/resources-modal.component';
import {
  ResourcesModalContainerComponent
} from './containers/modals/resources-modal-container/resources-modal-container.component';
import {
  ParametersModalContainerComponent
} from './containers/modals/parameters-modal-container/parameters-modal-container.component';
import {
  FetchResourceResponseContainerComponent
} from './containers/common/fetch-resource-response-container/fetch-resource-response-container.component';
import {
  TestCareproviderModalContainerComponent
} from './containers/modals/test-careprovider-modal-container/test-careprovider-modal-container.component';
import { ResourcesContainerComponent } from './containers/resource/resources-container/resources-container.component';
import { ResourceContainerComponent } from './containers/resource/resource-container/resource-container.component';
import {
  ResourceGroupContainerComponent
} from './containers/resource/resource-group-container/resource-group-container.component';
import { AbstractModalComponent } from './components/modals/abstract-modal/abstract-modal.component';
import {
  CareproviderShortDetailsComponent
} from './components/careprovider/careprovider-short-details/careprovider-short-details.component';
import {
  CareproviderShortDetailsContainerComponent
} from './containers/careprovider/careprovider-short-details-container/careprovider-short-details-container.component';
import { DataservicesComponent } from './components/dataservice/dataservices/dataservices.component';
import {
  DataserviceDetailsContainerComponent
} from './containers/dataservice/dataservice-details-container/dataservice-details-container.component';
import {
  DataserviceDetailsComponent
} from './components/dataservice/dataservice-details/dataservice-details.component';
import { ResourceKeyTableComponent } from './components/shared/resource-key-table/resource-key-table.component';
import { ExpandableContainerComponent } from './components/shared/expandable-container/expandable-container.component';
import { AbstractFormModalComponent } from './components/modals/abstract-form-modal/abstract-form-modal.component';
import {
  TestCareproviderStatusModalContainerComponent
} from './containers/modals/test-careprovider-status-modal-container/test-careprovider-status-modal-container.component';
import {
  TestCareproviderStatusButtonsContainerComponent
} from './containers/careprovider/test-careprovider-status-buttons-container/test-careprovider-status-buttons-container.component';
import {
  TestCareproviderStatusButtonsComponent
} from './components/careprovider/test-careprovider-status-buttons/test-careprovider-status-buttons.component';
import { LogsModalContainerComponent } from './containers/modals/logs-modal-container/logs-modal-container.component';
import { GpDataTablesComponent } from './components/gp-data/gp-data-tables/gp-data-tables.component';
import { ResultsTableComponent } from './components/gp-data/results-table/results-table.component';
import { MedrecordDatetimeModule } from '@medrecord/services-datetime';
import { TableCellComponent } from './components/gp-data/table-cell/table-cell.component';
import { DeleteDataModalComponent } from './components/modals/delete-data-modal/delete-data-modal.component';
import {
  DeleteDataModalContainerComponent
} from './containers/modals/delete-data-modal-container/delete-data-modal-container.component';

const components = [
  //region Pages
  MyDoctorWrapperComponent,
  CareprovidersPageContainerComponent,
  CareprovidersPageComponent,
  RecordContainerComponent,
  CareproviderDetailsPageContainerComponent,
  CareproviderDetailsPageComponent,
  FetchResourceResponseContainerComponent,
  FetchResourceResponseComponent,
  //endregion

  //region Dataservice
  DataservicesComponent,
  DataserviceDetailsContainerComponent,
  DataserviceDetailsComponent,
  //endregion

  //region Old MedMij pages
  ResourcesContainerComponent,
  ResourcesComponent, // dataservice
  ResourceContainerComponent,
  ResourceComponent, // resource key
  ResourceGroupContainerComponent,
  ResourceGroupComponent,
  FieldGroupComponent, // block
  FieldMultipleComponent,
  //endregion

  //region New GP Data pages
  PersonalDetailsComponent,
  GpDataTablesContainerComponent,
  GpDataTablesComponent,
  ResultsTableComponent,
  TableCellComponent,
  //endregion

  //region Supporting components
  ResourceKeyTableComponent,
  CareproviderShortDetailsContainerComponent,
  CareproviderShortDetailsComponent,
  DataservicesTabsContainerComponent,
  DataservicesTabsComponent,
  ResourcesChipsContainerComponent,
  ResourcesChipsComponent,
  ExpandableContainerComponent,
  BadgeStatusComponent,
  TestCareproviderStatusButtonsContainerComponent,
  TestCareproviderStatusButtonsComponent,
  //endregion

  //region Modals
  AbstractModalComponent,
  AbstractFormModalComponent,
  DeleteAllDataButtonModalComponent,
  DeleteDataModalContainerComponent,
  DeleteDataModalComponent,
  PortabilityReportButtonModalComponent,
  ResourcesModalContainerComponent,
  ResourcesModalComponent,
  ParametersModalContainerComponent,
  ParametersModalComponent,
  TestCareproviderModalContainerComponent,
  TestCareproviderModalComponent,
  TestCareproviderStatusModalContainerComponent,
  TestCareproviderStatusModalComponent,
  LogsModalContainerComponent,
  LogsModalComponent,
  //endregion

  //region Standalone component/page
  MedmijAuthBackComponent
  //endregion
];

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    TabModule,
    GridModule,
    TranslateModule,
    MyDoctorRoutingModule,
    MedsafeSharedModule,
    MedrecordUiModule,
    MedrecordIconsModule,
    DialogModule,
    ReactiveFormsModule,
    ChipListModule,
    CheckBoxModule,
    RadioButtonModule,
    MedrecordDatetimeModule
  ],
  declarations: [...components],
  exports: [MedmijAuthBackComponent],
  providers: [
    MyDoctorResourceMappingService,
    // Table services
    PageService,
    DetailRowService,
    ResizeService,
    VirtualScrollService
  ]
})
export class MyDoctorModule {
}
