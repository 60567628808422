import { ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { AbstractFormModalComponent } from '../abstract-form-modal/abstract-form-modal.component';
import {
  Careprovider,
  MappingResource,
  MY_DOCTOR_MANAGER_CONSTANTS,
  MyDoctorManagerConstants,
  PullTransaction,
  TransactionMode
} from '@managers/my-doctor';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'doctor-gui-delete-data-modal',
  templateUrl: './delete-data-modal.component.html',
  styleUrls: ['./delete-data-modal.component.scss']
})
export class DeleteDataModalComponent extends AbstractFormModalComponent<{
  careproviderId: string,
  resourceKeys?: MappingResource[]
}> {
  @Input() set deleteModalData(data: {
    careprovider: Careprovider,
    isDeleteDataservice: boolean,
    dataserviceId: string,
    resource: MappingResource,
  }) {
    let preselectedDataserviceTransaction;
    const dataservices = data.careprovider.dataServices.reduce((result, dataservice) => {
      const transactionDto = dataservice.transactions.find(t => t.mode === TransactionMode.pull);
      if(dataservice.id === data.dataserviceId) {
        preselectedDataserviceTransaction = transactionDto.id;
      }
      const pullMapping = this.myDoctorConstants.pullTransactionMappings.get(transactionDto.id);
      result[transactionDto.id] = pullMapping.resourceKeys;
      return result;
    }, {});
    this.initializeForm(dataservices, data.isDeleteDataservice, preselectedDataserviceTransaction, data.resource);
    this.dataservices = dataservices;
    this.careprovider = data.careprovider;
  }

  dataservices: { [id in PullTransaction]?: MappingResource[] };
  careprovider: Careprovider;
  readonly radioProperty = 'radios';

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MY_DOCTOR_MANAGER_CONSTANTS) private myDoctorConstants: MyDoctorManagerConstants,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  formSubmit(): void {
    if (this.form.value[this.radioProperty] === this.careprovider.id) {
      this.modalSubmitted.emit({
        careproviderId: this.careprovider.id
      });
      return;
    }
    const resources: MappingResource[] = Object.entries(this.form.value[this.form.value[this.radioProperty]])
      .filter((r: [string, boolean]) => r[1])
      .map((r: [string, boolean]) => r[0] as MappingResource);
    if (resources.length === 0) {
      this.closeModal();
    } else {
      this.modalSubmitted.emit({
        careproviderId: this.careprovider.id,
        resourceKeys: resources
      });
    }
  }

  public get dataservicesId(): PullTransaction[] {
    return Object.keys(this.dataservices) as PullTransaction[];
  }

  public radioChange(): void {
    this.cdRef.markForCheck();
  }

  private initializeForm(dataservices: { [id in PullTransaction]?: MappingResource[] },
                         isDeleteDataservice: boolean,
                         preselectedDataserviceId: string,
                         preselectedResource: MappingResource
  ): void {
    const formControls = {};
    Object.entries(dataservices).forEach(([id, resources]) => {
      const dataserviceFormControls = {};
      resources.forEach(resource => {
        dataserviceFormControls[resource] = false;
      });
      if(preselectedResource && preselectedDataserviceId === id && dataserviceFormControls[preselectedResource] === false) {
        dataserviceFormControls[preselectedResource] = true;
      }
      formControls[id] = this.formBuilder.group(dataserviceFormControls);
    });
    formControls[this.radioProperty] = preselectedResource || isDeleteDataservice ? preselectedDataserviceId : '';
    this.form = this.formBuilder.group(formControls);
  }

}
