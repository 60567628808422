import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { downloadPortabilityReportAction } from '@managers/my-doctor';
import { ValidationService } from '@medrecord/services-form-validation';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DatePrecision } from '@medrecord/services-datetime';

@Component({
  selector: 'doctor-gui-portability-report-button-modal',
  templateUrl: 'portability-report-button-modal.component.html'
})
export class PortabilityReportButtonModalComponent implements OnInit {
  isOpen = false;
  form: FormGroup;
  datePrecision = DatePrecision;

  private readonly requiredErrorMsg = this.translateService.instant('validation.required');

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private validationService: ValidationService
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  formSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.store.dispatch(
        downloadPortabilityReportAction({
          startDate: this.form.value.startDate?.date,
          endDate: this.form.value.endDate?.date
        })
      );

      this.isOpen = false;
    }
  }

  openDialog(event): void {
    event.stopPropagation();
    this.isOpen = true;
  }

  private initializeForm(): void {
    this.form = this.formBuilder.group({
      startDate: [null, this.validationService.required(this.requiredErrorMsg)],
      endDate: [null, this.validationService.required(this.requiredErrorMsg)]
    });
  }
}
