import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
import { loadUserProfileAction, loadUsersSuccessAction } from '../state-management/users-manager.actions';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class GetUserGuard implements CanActivate {
  constructor(
    private store: Store,
    private actions: Actions
  ) {}
  
  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const id = route.params.userId;

    if (!id) {
      return of(true);
    }

    this.store.dispatch(loadUserProfileAction({ id }));
    
    return this.actions.pipe(
      ofType(loadUsersSuccessAction),
      switchMap(() => of(true)),
    );
  }
}