import { Component, Input } from '@angular/core';
import { AddressUserData } from '@medrecord/managers-users';
import { ProfileAddressFormFactory } from '../../../factories/profile-address-form.factory';
import { provideFormFactory, RepeatableForm } from '@medrecord/ui';
import { ProfileFormType } from '../../../typings/enums';

@Component({
  selector: 'medsafe-addresses-form',
  templateUrl: './addresses-form.component.html',
  styleUrls: ['./addresses-form.component.scss'],
  providers: [...provideFormFactory(ProfileAddressFormFactory)]
})
export class AddressesFormComponent extends RepeatableForm<AddressUserData> {
  @Input() formType = ProfileFormType.User;
  
  fieldName = 'addressList';
}
