import { Inject, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectCurrentID } from '@medrecord/managers-users';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';
import {
  loadResourcesAction,
  loadResourcesSuccessAction,
  mergeDataWithMetadataAction,
  setResourceDataAction,
  setTabularResourceDataAction
} from '../actions';
import {
  getActiveCareprovider,
  getActiveTransactionId,
  getFilteredResourcesKeys,
  getResourceLoadResponse
} from '../selectors';
import { MyDoctorManagerService } from '../../services/my-doctor-manager.service';
import { ResourceData, ResourceDataAny, ResourceDataGroup, ResourceResponseData } from '../../models/interfaces';
import { isResourceGroup, Resource, ResourceGroup } from '../../models/enums';
import { MY_DOCTOR_MANAGER_CONSTANTS } from '../../my-doctor-manager.tokens';
import { MyDoctorManagerConstants } from '../../constants';
import { TabularResource } from '../../models/interfaces/tabular/tabular-resource.interface';
import { GenericMapper } from '../../models/mappers/generic.mapper';
import { PullTransactionTabularMappingService } from '../../services/pull-transaction-tabular-mapping.service';

@Injectable()
export class ResourceEffects {

  @Effect()
  loadResources$ = this.actions$.pipe(
    ofType(loadResourcesAction),
    withLatestFrom(
      this.store.select(selectCurrentID),
      this.store.select(getActiveCareprovider),
      this.store.select(getActiveTransactionId),
      this.store.select(getFilteredResourcesKeys)
    ),
    mergeMap(([, userId, careprovider, transactionId, filteredResourceKeys]) => {
      if (filteredResourceKeys.length === 0) { // when there is issue with the url
        return [];
      }
      const resourceKeys: Resource[] = this.service.toFlatResources(filteredResourceKeys, transactionId);

      return this.service.loadResources(userId, careprovider.id, resourceKeys).pipe(
        switchMap((resources) => [
          loadResourcesSuccessAction({ responsePayload: resources.data }),
          mergeDataWithMetadataAction()
        ]),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_resources_error'), error))
        ])
      );
    })
  );

  @Effect()
  mergeDataWithMetadata$ = this.actions$.pipe(
    ofType(mergeDataWithMetadataAction),
    withLatestFrom(
      this.store.select(getResourceLoadResponse),
      this.store.select(getFilteredResourcesKeys),
      this.store.select(getActiveTransactionId)
    ),
    mergeMap(([, resourcesData, resourceKeys, transactionId]) => {
      const pullTabularMapping = this.mappingService.getMappers(transactionId);
      const tabularResourceData: { [key in Resource]?: TabularResource<string> } = {};
      if (pullTabularMapping) {
        resourceKeys.forEach(resourceKey => {
          const mapper: GenericMapper<string> = pullTabularMapping.mappers[resourceKey];
          if (mapper) {
            tabularResourceData[resourceKey] = mapper.mapData(resourcesData);
          }
        });
      }

      const pullMapping = this.myDoctorConstants.pullTransactionMappings.get(transactionId);
      // filteredResourceKeys are used to obtain resourceLoadResponse
      // however, keys with no data are not included in response
      const resourceData: ResourceDataAny[] = resourceKeys.map(resourceKey => {
        if (isResourceGroup(resourceKey)) {
          const groupResources: ResourceData[] = pullMapping.resourceGroups[resourceKey].map((groupResource: Resource) => ({
            key: groupResource,
            resources: resourcesData.find(({ key }) => key === groupResource)?.resources ?? []
          }));
          return {
            group: resourceKey as ResourceGroup,
            resources: groupResources
          } as ResourceDataGroup;
        }

        const additionalResources: ResourceResponseData[] = [];
        // Add additional resources from other resources
        if (pullMapping.additionalResources?.[resourceKey]) {
          pullMapping.additionalResources?.[resourceKey].forEach((additionalResource: Resource) => {
            const foundResources = resourcesData.find(({ key }) => key === additionalResource)?.resources ?? [];
            additionalResources.push(...foundResources);
          });
        }

        const sort = pullMapping.sort?.find(s => s.resourceKey === resourceKey);

        return {
          key: resourceKey as Resource,
          resources: [
            ...(resourcesData.find(({ key }) => key === resourceKey)?.resources ?? []),
            ...additionalResources
          ],
          ...(sort && { sort })
        } as ResourceData;
      });

      return [
        setResourceDataAction({ resourceData: resourceData }),
        setTabularResourceDataAction({ tabularResourceData: tabularResourceData })
      ];
    })
  );


  constructor(
    private store: Store,
    private actions$: Actions,
    private service: MyDoctorManagerService,
    private translateService: TranslateService,
    private mappingService: PullTransactionTabularMappingService,
    @Inject(MY_DOCTOR_MANAGER_CONSTANTS) private myDoctorConstants: MyDoctorManagerConstants
  ) {
  }
}
