import { on } from '@ngrx/store';
import {
  loadTestCareproviderStatusAction,
  loadTestCareproviderStatusSuccessAction,
  setTestCareproviderStatusModalOpenStatusAction
} from '../actions';
import { State } from '../my-doctor-manager.reducer';

export const testCareproviderReducer = [
  on(setTestCareproviderStatusModalOpenStatusAction, (state: State, { isOpen }) => ({
    ...state,
    testCareproviderStatusModalOpenStatus: isOpen
  })),

  on(loadTestCareproviderStatusAction, (state: State, { statusType }) => ({
    ...state,
    testCareproviderStatusFetchType: statusType
  })),

  on(loadTestCareproviderStatusSuccessAction, (state: State, { data }) => ({
    ...state,
    testCareproviderStatusFetchData: data
  }))
];
