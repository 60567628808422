import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'useTranslation'
})
export class UseTranslationPipe implements PipeTransform {
  transform(value: string, params: Record<string, any>): unknown {
    console.log(value, params);
    
    return null;
  }
}
