import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'medrecord-ui-name-column',
  templateUrl: './name-column.component.html',
})
export class NameColumnComponent {
  @Input() name: string;
  @Input() avatar: string;
  @Input() userId: string;

  @Output() cellClick: EventEmitter<string> = new EventEmitter<string>();
}
