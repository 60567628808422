import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-mixture',
  templateUrl: './mixture.component.svg',
})
export class MixtureComponent extends MedrecordIcon {
  viewHeight = 18;
  viewWidth = 14;
}
