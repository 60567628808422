import { Component } from '@angular/core';
import { getResourceData, ResourceDataAny } from '@managers/my-doctor';
import { getSelectedUserPermission, Permission } from '@medrecord/managers-users';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'doctor-gui-resources-container',
  templateUrl: './resources-container.component.html'
})
export class ResourcesContainerComponent {
  permission$: Observable<Permission> = this.store.select(getSelectedUserPermission);
  resourceData$: Observable<ResourceDataAny[]> = this.store.select(getResourceData);

  constructor(
    private store: Store
  ) {
  }

}
