import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { NavigationEffects } from './navigation.effects';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule,
    EffectsModule.forFeature([NavigationEffects])
  ],
})
export class MedrecordNavigationLibModule {}
