import { Component, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { AutoCompleteComponent } from '@syncfusion/ej2-angular-dropdowns';
import { debounceTime } from 'rxjs/operators';
import { NgControlComponentBase } from '../../../ui-abstractions/ng-control-component-base.component';

@Component({
  selector: 'medrecord-ui-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent extends NgControlComponentBase implements OnInit {
  @ViewChild('autocomplete') autoComplete: AutoCompleteComponent;

  private filteringTimeout = null;
  private filteringValue = '';

  @Input() label: string;
  @Input() showPopupButton = true;
  @Input() dataSource: any = [];
  @Input() placeholder: string;
  @Input() observeAction: any;
  @Input() fields;
  @Input() asterix: boolean;

  @Output() select = new EventEmitter<any>();
  @Output() filtering = new EventEmitter<any>();

  constructor(private actions$: Actions, @Self() @Optional() public control: NgControl) {
    super(control);
  }

  ngOnInit(): void {
    this.observeAutocompleteLoaded();
  }

  private observeAutocompleteLoaded(): void {
    this.actions$.pipe(ofType(this.observeAction), debounceTime(400)).subscribe(() => {
      this.autoComplete.hideSpinner();
      this.autoComplete.showPopup();
    });
  }

  get autocompleteValue(): string {
    return this.value?.text || '';
  }

  onSelect(event): void {
    this.value = event.itemData;
    this.select.emit(event);
  }

  onValueChange(event): void {
    this.value = event.itemData;

    // Reset all dropdown values
    if (!event?.item) {
      this.filtering.emit(event);
    }
  }

  onFiltering(event): void {
    this.filteringValue = event?.text || '';
    if (this.filteringTimeout) {
      clearTimeout(this.filteringTimeout);
      this.filteringTimeout = null;
    }

    this.filteringTimeout = setTimeout(() => this.filtering.emit(event), 700);
  }

  onOpenChange(event) {
    // Edge case to trigger data refresh on dropdown visibile change
    if (!this.value && !this.filteringValue && !this.dataSource.length) {
      this.filtering.emit(event);
    }
  }
}
