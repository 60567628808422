import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersManagementStateModule } from './state-management/users-management-state.module';

@NgModule({
  imports: [
    CommonModule,
    UsersManagementStateModule
  ],
})
export class UsersManagerModule {}
