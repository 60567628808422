import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-pagination-arrow-left',
  templateUrl: './pagination-arrow-left.component.svg',
  styleUrls: ['./pagination-arrow-left.component.scss'],
})
export class PaginationArrowLeftComponent extends MedrecordIcon {
  viewHeight = 14;
  viewWidth = 18;
}
