import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from '@medrecord/core';
import { UserMetadata } from '../models/interfaces';

@Injectable()
export class UserMetadataService {
  constructor(
    private http: HttpClient,
    private envService: EnvService
  ) {}
  
  getUserMedata(userId: string): Observable<{ data: UserMetadata }> {
    return this.http.get<{ data: UserMetadata }>(`${this.envService.middlewareBackend}/user-metadata/${userId}`);
  }
}