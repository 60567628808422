<ejs-multiselect
  [placeholder]="placeholder"
  [dataSource]="dataSource"
  [valueTemplate]="valueTemplate"
  [itemTemplate]="itemTemplate"
  [value]="value"
  [readonly]="disabled"
  (removed)="onRemove($event)"
  (select)="onSelect($event)"
  [mode]="mode"
>
  <ng-template #valueTemplate let-data>
    <div>
      {{ data?.text | translate | lowercase }}
    </div>
  </ng-template>
  <ng-template #itemTemplate let-data>
    {{ data?.text | translate | lowercase }}
  </ng-template>
</ejs-multiselect>
