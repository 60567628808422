import { Component, Inject } from '@angular/core';
import { AuthRouteParams, setPasswordAction } from '@medrecord/managers-auth';
import { Store } from '@ngrx/store';
import { navigateAction } from '@medrecord/tools-navigation';
import { AUTH_ROUTE_NAMES, AuthRouteNames } from '@medrecord/routes-auth';
import { EnvService, Language } from '@medrecord/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'medrecord-auth-set-password-form',
  templateUrl: './set-password-form.component.html',
})
export class SetPasswordFormComponent {
  languageCode: Language;
  
  constructor(
    @Inject(AUTH_ROUTE_NAMES) private authRouteNames: AuthRouteNames,
    private envService: EnvService,
    private route: ActivatedRoute,
    private store: Store,
  ) {
    this.languageCode = (this.route.snapshot.queryParamMap.get(AuthRouteParams.Language) || this.envService.defaultLanguage) as Language;
  }
  
  onSubmit(password: string): void {
    this.store.dispatch(
      setPasswordAction({
        newPassword: password,
        successActions: [
          navigateAction({
            path: [
              this.authRouteNames.Entry,
              this.authRouteNames.Invitation.Accepted,
            ]
          })
        ],
        languageCode: this.languageCode
      })
    );
  }
}
