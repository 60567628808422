<h2 class="text-weight-600 text-primary mb-3 text-center">
  {{ 'auth_success_restored_title' | translate }}
</h2>

<p class="text-lg my-5 text-justify">{{ 'auth_success_restored_description' | translate }}</p>

<button
  [class.btn-rounded]="isOAuth"
  class="btn btn-primary btn-block text-white text-lg text-weight-600"
  routerLink="/"
  queryParamsHandling="merge"
>
  {{ 'auth_log_in' | translate }}
</button>