import { User } from '@medrecord/managers-users';
import { Action, createReducer, on } from '@ngrx/store';
import {
  deleteUserFromNetworkFailureAction,
  loadUserNetworkFailureAction,
  loadUserNetworkSuccessAction,
  searchUsersFailureAction,
  searchUsersSuccessAction,
  updateUserInNetworkFailureAction,
} from './network-manager.actions';

export const networkManagerFeatureKey = 'network-manager';

export interface NetworkManagerState {
  error: any;
  users: User[];
  searchedUsers: User[];
}

export const initialState: NetworkManagerState = {
  error: null,
  users: [],
  searchedUsers: [],
};

export const networkManagerReducer = createReducer(
  initialState,
  on(loadUserNetworkSuccessAction, (state, { users }) => ({ ...state, users })),
  on(searchUsersSuccessAction, (state, { users }) => ({ ...state, searchedUsers: users })),
  on(
    loadUserNetworkFailureAction,
    deleteUserFromNetworkFailureAction,
    updateUserInNetworkFailureAction,
    searchUsersFailureAction,
    (state, { error }) => ({ ...state, error })
  )
);

export const reducer = (state: NetworkManagerState, action: Action): NetworkManagerState => {
  return networkManagerReducer(state, action);
};
