import { Inject, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { selectCurrentID } from '@medrecord/managers-users';
import { Store } from '@ngrx/store';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { EnvService } from '@medrecord/core';
import { forkJoin, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  addCareproviderToUserScopeAction,
  deleteCareproviderFromUserScopeAction,
  loadCareprovidersAction,
  loadCareprovidersSuccessAction,
  loadLogsAction,
  loadLogsSuccessAction,
  loadResourcesAction,
  navigateBackToCareprovidersAction,
  navigateToCareproviderAction,
  prepareCareproviderDataAction,
  redirectToAvailableDataserviceAction,
  selectCareproviderAction,
  setActiveCareproviderAction,
  setActiveDataserviceAction,
  setActiveTransactionIdAction,
  setAllResourceKeysAction,
  setFilteredResourcesKeysAction,
  setLogsModalOpenStatusAction,
  setNavigatedToCareproviderDetailsFromAction,
  validateCareproviderLoadedAction
} from '../actions';
import { MY_DOCTOR_MANAGER_CONSTANTS } from '../../my-doctor-manager.tokens';
import { MyDoctorManagerConstants } from '../../constants';
import {
  getActiveCareprovider,
  getActiveCareproviderId,
  getActiveDataserviceId,
  getNavigatedToCareproviderDetailsFrom
} from '../selectors';
import { MyDoctorManagerService } from '../../services/my-doctor-manager.service';
import { GENERAL_ROUTE_NAMES, GeneralRouteNames } from '@medrecord/routes-general';
import { MEDSAFE_ROUTE_NAMES, MedsafeRouteNames } from '@medrecord/routes-medsafe';
import { Router } from '@angular/router';
import { PullTransaction, TransactionMode } from '../../models/enums';
import { CareproviderDataService, CareproviderLog, MappingResource } from '../../models/interfaces';
import { MedrecordMoment } from '@medrecord/services-datetime';

@Injectable()
export class CareproviderEffects {
  @Effect()
  loadCareproviders$ = this.actions$.pipe(
    ofType(loadCareprovidersAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ requestPayload }, userId]) => {
      const includeTestCareprovider = this.envService.enableTestHealthcareProvider
        && requestPayload.startPage === 0
        && this.myDoctorConstants.TEST_PROVIDER_ID.includes(requestPayload.search);
      return forkJoin({
        careproviders: this.service.loadCareproviders(
          userId,
          {
            ...requestPayload,
            ...(includeTestCareprovider ? { count: requestPayload.count - 1 } : {})
          }
        ),
        count: this.service.loadCareprovidersCount(userId, requestPayload.search, requestPayload.userScope)
      }).pipe(
        switchMap(({ careproviders, count }) => [
          loadCareprovidersSuccessAction({
            careproviders: [
              ...(includeTestCareprovider ? [this.myDoctorConstants.TEST_PROVIDER] : []),
              ...careproviders.careProviders
            ],
            count: count + +includeTestCareprovider // convert boolean to number (1 or 0)
          })
        ]),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_careproviders_error'), error))
        ])
      );
    })
  );

  @Effect()
  selectCareprovider$ = this.actions$.pipe(
    ofType(selectCareproviderAction),
    switchMap(({ careprovider }) => {
      const route = this.router.url.split('/');
      const firstDataservice = careprovider.dataServices[0];
      return [
        setNavigatedToCareproviderDetailsFromAction({ from: route[route.length - 1] as 'favourites' | 'search' }),
        setActiveCareproviderAction({ careprovider }),
        navigateToCareproviderAction({
          careproviderId: careprovider.id,
          dataserviceId: firstDataservice?.id ?? this.medsafeRouteNames.MyDoctor.Unknown,
          detailsRoute: null
        })
      ];
    })
  );

  @Effect({ dispatch: false })
  navigateToCareprovider$ = this.actions$.pipe(
    ofType(navigateToCareproviderAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    tap(([{ careproviderId, dataserviceId, detailsRoute }, userId]) => {
      this.router.navigate(
        [
          this.generalRouteNames.Patient.Entry,
          userId,
          this.medsafeRouteNames.MyDoctor.Entry,
          this.medsafeRouteNames.MyDoctor.Careprovider,
          careproviderId,
          dataserviceId,
          detailsRoute
        ].filter(Boolean) // remove falsy items from array; in this case potentially last one=null
      );
    })
  );

  @Effect()
  validateCareproviderLoaded$ = this.actions$.pipe(
    ofType(validateCareproviderLoadedAction),
    withLatestFrom(
      this.store.select(selectCurrentID),
      this.store.select(getActiveCareprovider),
      this.store.select(getActiveDataserviceId),
    ),
    mergeMap(([{ careproviderId, dataserviceId, recordId }, userId, activeCareprovider, activeDataserviceId]) => {
      const actions = [
        prepareCareproviderDataAction({ dataserviceId, recordId })
      ];
      if (!activeCareprovider?.id || activeCareprovider.id !== careproviderId) {
        return (this.envService.enableTestHealthcareProvider && this.service.isTest(careproviderId)
            ? of(this.myDoctorConstants.TEST_PROVIDER)
            : this.service.loadCareprovider(userId, careproviderId)
        ).pipe(
          switchMap(careprovider => [
            setActiveCareproviderAction({ careprovider }),
            ...actions
          ]),
          catchError((error: any) => [
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_careproviders_error'), error))
          ])
        );
      }

      if(!activeDataserviceId || activeDataserviceId !== dataserviceId) {
        return [
          ...actions
        ];
      }
      return [];
    })
  );

  @Effect()
  prepareCareproviderData$ = this.actions$.pipe(
    ofType(prepareCareproviderDataAction),
    withLatestFrom(this.store.select(getActiveCareprovider)),
    mergeMap(([{ dataserviceId, recordId }, activeCareprovider]) => {
      let dataservice: CareproviderDataService = null;
      let transactionId: PullTransaction = null;
      let allResourceKeys: MappingResource[] = [];
      if (dataserviceId !== this.medsafeRouteNames.MyDoctor.Unknown) {
        dataservice = activeCareprovider.dataServices.find(ds => ds.id === dataserviceId);
        if (!dataservice) {
          return [redirectToAvailableDataserviceAction()];
        }
        const transactionDto = dataservice.transactions.find(t => t.mode === TransactionMode.pull);
        const pullMapping = this.myDoctorConstants.pullTransactionMappings.get(transactionDto.id);
        transactionId = transactionDto.id;

        allResourceKeys = recordId ? pullMapping.detailResourceKeys : pullMapping.resourceKeys;
      } // else extremely rare case, explained in redirectToAvailableDataserviceAction
      return [
        setActiveDataserviceAction({ dataservice }),
        setActiveTransactionIdAction({ transactionId }),
        setAllResourceKeysAction({ resourceKeys: allResourceKeys }),
        setFilteredResourcesKeysAction({ resourceKeys: allResourceKeys }),
        loadResourcesAction()
      ];
    })
  );

  @Effect()
  loadLogs$ = this.actions$.pipe(
    ofType(loadLogsAction),
    withLatestFrom(
      this.store.select(selectCurrentID),
      this.store.select(getActiveCareproviderId)
    ),
    mergeMap(([, userId, careproviderId]) =>
      this.service.loadLogs(userId, careproviderId).pipe(
        switchMap((logs) => {
          const errorSubstringMap: { [key: string]: string } = {
            'http 400': 'healthcare_providers_error_400',
            'http 401': 'healthcare_providers_no_information_provided_error',
            'http 500': 'healthcare_providers_no_information_provided_error',
            sockettimeoutexception: 'healthcare_providers_no_information_provided_error',
            dataformatexception: 'healthcare_providers_no_information_provided_error'
          };

          const resultLogs: CareproviderLog[] = logs.map(responseItem => ({
            date: MedrecordMoment(new Date(responseItem.requestTime).toISOString()).format('LT'),
            items: responseItem.results.map(log => {
              const errorMessageKey = Object.keys(errorSubstringMap).find(substring =>
                (log.errorMessage ?? '').toLowerCase().includes(substring)
              );
              const errorMessage = errorMessageKey
                ? errorSubstringMap[errorMessageKey]
                : this.translateService.instant('healthcare_providers_no_information_provided_error');

              return {
                name: this.translateService.instant(log.key),
                count: log.countMain,
                error: log.error,
                errorHttpCode: log.errorHttpCode,
                errorMessage,
                issues: log.errorIssues
              };
            })
          }));

          return [
            loadLogsSuccessAction({ logs: resultLogs }),
            setLogsModalOpenStatusAction({ isOpen: true })
          ];
        }),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_logs_error'), error))
        ])
      )
    )
  );

  @Effect({ dispatch: false })
  navigateBackToCareproviders$ = this.actions$.pipe(
    ofType(navigateBackToCareprovidersAction),
    withLatestFrom(
      this.store.select(selectCurrentID),
      this.store.select(getNavigatedToCareproviderDetailsFrom)
    ),
    tap(([, userId, navigatedFrom]) => {
      this.router.navigate([
        this.generalRouteNames.Patient.Entry,
        userId,
        this.medsafeRouteNames.MyDoctor.Entry,
        navigatedFrom === 'favourites'
          ? this.medsafeRouteNames.MyDoctor.Favourites
          : this.medsafeRouteNames.MyDoctor.Search
      ]);
    })
  );

  @Effect()
  addCareProviderToUserScope$ = this.actions$.pipe(
    ofType(addCareproviderToUserScopeAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ careproviderId, requestPayload }, userId]) =>
      this.service.addCareproviderToUserScope(careproviderId, userId).pipe(
        switchMap(() => [
          loadCareprovidersAction({ requestPayload })
        ]),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(
            this.translateService.instant('careprovider_highlight_error'),
            error?.error || error
          ))
        ])
      )
    )
  );
  // todo test if error works for action above
  // todo they are inconsistent (copy the logic to other actions)
  // todo test if error works for action below
  @Effect()
  deleteCareProviderFromUserScope$ = this.actions$.pipe(
    ofType(deleteCareproviderFromUserScopeAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ careproviderId, requestPayload }, userId]) =>
      this.service.deleteCareproviderFromUserScope(careproviderId, userId).pipe(
        switchMap(() => [
          loadCareprovidersAction({ requestPayload })
        ])
      )
    ),
    catchError((error: any) => [
      addErrorToast(
        getErrorToastBodyUtil(this.translateService.instant('careprovider_unhighlight_error'), error?.error || error)
      )
    ])
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private service: MyDoctorManagerService,
    private translateService: TranslateService,
    private envService: EnvService,
    private router: Router,
    @Inject(MY_DOCTOR_MANAGER_CONSTANTS) private myDoctorConstants: MyDoctorManagerConstants,
    @Inject(MEDSAFE_ROUTE_NAMES) private medsafeRouteNames: MedsafeRouteNames,
    @Inject(GENERAL_ROUTE_NAMES) private generalRouteNames: GeneralRouteNames
  ) {
  }
}
