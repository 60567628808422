<ng-container *ngTemplateOutlet="labelContentTemplate"></ng-container>

<label [for]="for"
       *ngIf="(text || secondaryText || labelContentTemplate)"
       class="{{ 'text-' + type }}"
>

  <span class="main-text" *ngIf="text">
    {{ text }}
    <span class="required" *ngIf="asterix">*</span>
  </span>
  <span *ngIf="secondaryText" class="font-weight-normal">
    ({{ secondaryText }})
  </span>

  <medrecord-ui-hint *ngIf="hint" [hint]="hint"></medrecord-ui-hint>
</label>
