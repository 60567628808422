import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-collapse',
  templateUrl: './collapse.component.svg',
})
export class CollapseComponent extends MedrecordIcon {
  viewHeight = 24;
  viewWidth = 24;
}
