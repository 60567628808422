import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { generalRouteNames, provideGeneralRouteNames } from '@medrecord/routes-general';
import { authRouteNames, provideAuthRouteNames } from '@medrecord/routes-auth';
import { provideAdminRouteNames } from '@routes/admin';
import { provideMedsafeRouteNames } from '@medrecord/routes-medsafe';

import { AuthorizedGuard, Role } from '@medrecord/managers-auth';
import { GetUserMetadataGuard } from '@medrecord/managers-users';

import { RoleAccessGuard } from './guards/role-access.guard';
import { PaymentModuleGuard } from './guards/payment-module.guard';
import { MedmijAuthBackComponent } from '@medsafe/user-my-doctor';

export const APP_MEDSAFE_ROUTES: Route[] = [
  {
    path: generalRouteNames.Auth.Entry,
    children: [
      {
        path: authRouteNames.Base,
        loadChildren: () => import('@medrecord/local-auth-view').then((m) => m.LocalAuthViewModule),
      },
    ],
  },
  {
    path: generalRouteNames.PaymentStatus.Entry,
    canActivate: [PaymentModuleGuard],
    loadChildren: () => import('@payment/status').then((m) => m.PaymentStatusModule),
  },
  {
    path: generalRouteNames.Base,
    canActivate: [AuthorizedGuard],
    children: [
      {
        path: generalRouteNames.Patient.MedmijAuthBack,
        component: MedmijAuthBackComponent,
      },
      {
        path: generalRouteNames.PaymentPlans.Entry,
        canActivate: [PaymentModuleGuard],
        loadChildren: () => import('@payment/plans').then((m) => m.PaymentPlansModule),
      },
      {
        path: generalRouteNames.Onboarding.Entry,
        loadChildren: () => import('@medsafe/onboarding').then((m) => m.OnboardingModule),
      },
      {
        path: generalRouteNames.Chat.Entry,
        canActivate: [GetUserMetadataGuard],
        loadChildren: () => import('@medsafe/chat').then((m) => m.ChatModule),
      },
      {
        path: generalRouteNames.News.Entry,
        canActivate: [GetUserMetadataGuard],
        loadChildren: () => import('@medsafe/news').then((m) => m.NewsModule),
      },
      {
        path: generalRouteNames.Settings.Entry,
        canActivate: [GetUserMetadataGuard],
        loadChildren: () => import('@medsafe/settings').then((m) => m.SettingsModule),
      },
      {
        path: generalRouteNames.Doctor.Entry,
        canActivate: [RoleAccessGuard, GetUserMetadataGuard],
        data: {
          roles: [Role.Doctor],
        },
        loadChildren: () => import('@medsafe/shell').then((m) => m.MedsafeShellModule),
      },
      {
        path: `${generalRouteNames.Patient.Entry}/:id`,
        canActivate: [RoleAccessGuard, GetUserMetadataGuard],
        data: {
          roles: [Role.Buddy,Role.Patient],
        },
        loadChildren: () => import('@medsafe/user-shell').then((m) => m.UserModule),
      },
      {
        path: generalRouteNames.Admin.Entry,
        canActivate: [RoleAccessGuard, GetUserMetadataGuard],
        data: {
          roles: [Role.Administrator],
        },
        loadChildren: () => import('@medsafe/admin-shell').then((m) => m.AdminShellModule),
      },
      {
        path: generalRouteNames.Redirect,
        canActivate: [RoleAccessGuard],
        children: [],
      },
      {
        path: generalRouteNames.Base,
        redirectTo: generalRouteNames.Redirect,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: generalRouteNames.Patient.LegacyMedmijAuthBack,
    redirectTo: generalRouteNames.Patient.MedmijAuthBack,
    pathMatch: 'full',
  },
  {
    path: generalRouteNames.Any,
    redirectTo: generalRouteNames.Base,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_MEDSAFE_ROUTES, {
      initialNavigation: 'enabled',
      // enableTracing: true
    }),
  ],
  providers: [
    provideGeneralRouteNames(),
    provideMedsafeRouteNames(generalRouteNames.Medsafe.Entry),
    provideAuthRouteNames(generalRouteNames.Auth.Entry),
    provideAdminRouteNames(generalRouteNames.Admin.Entry),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
