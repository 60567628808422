import { Component, Input } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';
import { Colors } from '@medrecord/services-colors';

@Component({
  selector: 'medrecord-svg-plus-rounded',
  templateUrl: './plus-rounded.component.svg',
  styleUrls: ['./plus-rounded.component.scss'],
})
export class PlusRoundedComponent extends MedrecordIcon {
  viewHeight = 16;
  viewWidth = 16;
  
  plusColor: string;
  
  @Input()
  set plusType(color: Colors) {
    this.plusColor = this.getColorByType(color);
  }
}
