import { loadUserMetadataSuccessAction } from './middleware-manager.actions';
import { UserMetadataMeasurementGoal } from '../models/interfaces';
import { Action, createReducer, on } from '@ngrx/store';

export const middlewareManagerFeatureKey = 'middleware-manager';

export interface MiddlewareManagerState {
  goalsDefaultMeasurements: UserMetadataMeasurementGoal[];
  goalsMeasurements: UserMetadataMeasurementGoal[];
}

export const initialState: MiddlewareManagerState = {
  goalsDefaultMeasurements: [],
  goalsMeasurements: []
};

export const middlewareManagerReducer = createReducer(
  initialState,
  on(loadUserMetadataSuccessAction,
    (state, { userMetadata }) => ({
      ...state,
      goalsDefaultMeasurements: userMetadata.goals.defaultMeasurements,
      goalsMeasurements: userMetadata.goals.measurements
    }))
);

export const reducer = (state: MiddlewareManagerState, action: Action): MiddlewareManagerState => {
  return  middlewareManagerReducer(state, action);
};