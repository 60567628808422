import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { allergiesManagerFeatureKey, reducer } from './allergies-manager.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AllergiesManagerEffects } from './allergies-manager.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(allergiesManagerFeatureKey, reducer),
    EffectsModule.forFeature([ AllergiesManagerEffects ])
  ]
})
export class AllergiesManagerStateModule {}