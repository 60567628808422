import { Component, Input } from '@angular/core';
import { UserInfo } from '@medrecord/managers-users';

@Component({
  selector: 'medrecord-ui-person-column',
  templateUrl: './person-column.component.html',
})
export class PersonColumnComponent {
  @Input() person: UserInfo;

  getLabelKey(): string {
    return this.person.speciality ? 'common_speciality_' + this.person.speciality : 'common_role_' + this.person.role;
  }
}
