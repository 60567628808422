import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  getActiveTransactionId,
  getTestCareproviderModalOpenStatus, PullTransaction,
  setTestCareproviderModalOpenStatusAction,
  submitTestCareproviderModalAction,
  TestCareproviderModalData
} from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-test-careprovider-modal-container',
  templateUrl: 'test-careprovider-modal-container.component.html'
})
export class TestCareproviderModalContainerComponent {
  isModalOpen$: Observable<boolean> = this.store.select(getTestCareproviderModalOpenStatus);
  transactionId$: Observable<PullTransaction> = this.store.select(getActiveTransactionId);

  constructor(
    private store: Store
  ) {
  }

  onModalSubmitted(testCareproviderModalData: TestCareproviderModalData): void {
    this.store.dispatch(submitTestCareproviderModalAction({ testCareproviderModalData }));
  }

  onModalClosed(): void {
    this.store.dispatch(setTestCareproviderModalOpenStatusAction({ isOpen: false }));
  }
}
