import { createAction, props } from '@ngrx/store';
import { MappingResource, ResourceParameter, TestCareproviderModalData } from '../../models/interfaces';
import { Resource, TransactionParameterName } from '../../models/enums';

export const setResourcesModalOpenStatusAction = createAction(
  '[My Doctor Manager Actions] Set Resources Modal Open Status',
  props<{ isOpen: boolean }>()
);

export const submitResourcesModalAction = createAction(
  '[My Doctor Manager Actions] Submit Resources Modal',
  props<{ resources: MappingResource[] }>()
);

export const setResourceParametersAction = createAction(
  '[My Doctor Manager Actions] Set Resource Parameters',
  props<{ resourceParameters: ResourceParameter[] }>()
);

export const setParametersModalOpenStatusAction = createAction(
  '[My Doctor Manager Actions] Set Parameters Modal Open Status',
  props<{ isOpen: boolean }>()
);

export const submitParametersModalAction = createAction(
  '[My Doctor Manager Actions] Submit Parameters Modal',
  props<{ resourceParameters: { [key in Resource]?: { [name in TransactionParameterName]?: string } } }>()
);

export const setTestCareproviderModalOpenStatusAction = createAction(
  '[My Doctor Manager Actions] Set Test Careprovider Modal Open Status',
  props<{ isOpen: boolean }>()
);

export const submitTestCareproviderModalAction = createAction(
  '[My Doctor Manager Actions] Submit Test Careprovider Modal',
  props<{ testCareproviderModalData: TestCareproviderModalData }>()
);
