import { NgModule } from '@angular/core';
import { MeasurementsManagerStateModule } from './state-management/measurements-manager-state.module';
import { ThresholdsService } from './services/thresholds.service';
import { ObservationsService } from './services/observations.service';
import { GetThresholdsGuard } from './guards/get-thresholds.guard';
import { provideMeasurementManagerConstants } from './constants';
import { GetSelectedObservationTypeGuard } from './guards/get-selected-observation-type.guard';

@NgModule({
  imports: [MeasurementsManagerStateModule],
  providers: [
    ThresholdsService,
    ObservationsService,
    GetThresholdsGuard,
    GetSelectedObservationTypeGuard,
    provideMeasurementManagerConstants(),
  ],
})
export class MeasurementsManagerModule {}
