import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ConditionsManagerEffects } from './conditions-manager.effects';
import { conditionsManagerFeatureKey, reducer } from './conditions-manager.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(conditionsManagerFeatureKey, reducer),
    EffectsModule.forFeature([ ConditionsManagerEffects ])
  ]
})
export class ConditionsManagerStateModule {}