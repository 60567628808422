import { Component, Input } from '@angular/core';
import { ContactType, ContactUse, ContactUserData } from '@medrecord/managers-users';
import { provideFormFactory, RepeatableForm } from '@medrecord/ui';
import { ProfileFormType } from '../../../typings/enums';
import { ProfileContactsFormFactory } from '../../../factories/profile-contacts-form.factory';

@Component({
  selector: 'medsafe-contacts-form',
  templateUrl: './contacts-form.component.html',
  styleUrls: ['./contacts-form.component.scss'],
  providers: [...provideFormFactory(ProfileContactsFormFactory)]
})
export class ContactsFormComponent extends RepeatableForm<ContactUserData> {
  @Input() formType = ProfileFormType.User;
  fieldName = 'contactList';
  
  initialFormData = {
    rank: 1,
    system: ContactType.phone,
    use: ContactUse.home,
    value: null
  };
  
  removeContact(index: number): void {
    this.removeForm(index);
    
    this.controls.forEach((control, prevRank ) => {
      control.patchValue({ rank: prevRank + 1 });
    });
  }
  
  addNewContact(): void {
    this.addForm({ ...this.initialFormData, rank: this.controls.length + 1 });
  }
}