import { Component, Input, OnInit } from '@angular/core';
import {
  MyDoctorTableInterface,
  ResourceField,
  ResourceFieldGroup,
  ResourceResponseData
} from '@managers/my-doctor';
import { FieldGroupUtil } from '../../../utils/field-group.util';

@Component({
  selector: 'doctor-gui-field-multiple',
  templateUrl: './field-multiple.component.html',
  styleUrls: ['./field-multiple.component.scss']
})
export class FieldMultipleComponent implements OnInit {
  // fieldGroup is currently only 'my_doctor_content' in doctor-manifest.ts block
  @Input() fieldGroup: ResourceFieldGroup;
  @Input() allResourcesData: ResourceResponseData[];
  @Input() mainResourceData: ResourceResponseData;

  public data = [];
  public expandedDataItemIndexes: number[] = [];

  ngOnInit(): void {
    this.prepareData();
  }

  toggleExpand(index: number): void {
    const existingIndex = this.expandedDataItemIndexes.indexOf(index);
    if (existingIndex > -1) {
      this.expandedDataItemIndexes.splice(existingIndex, 1);
    } else {
      this.expandedDataItemIndexes.push(index);
    }
  }

  isExpanded(index: number): boolean {
    return this.expandedDataItemIndexes.includes(index);
  }

  getFieldGroupName(dataItem: any, index: number): string {
    return FieldGroupUtil.prepareFieldGroupName(this.fieldGroup, index, dataItem);
  }

  isFieldEmpty(data: any, field: ResourceField | ResourceFieldGroup): boolean {
    return FieldGroupUtil.isFieldEmpty(field, this.prepareDataForField, data);
  }

  prepareDataForField(data: any, field: ResourceField): string | MyDoctorTableInterface {
    return !data[field.fieldName]
      ? null
      : field.prepareData(data[field.fieldName], this.allResourcesData);
  }

  private prepareData(): void {
    // fieldGroup is currently only 'my_doctor_content' in doctor-manifest.ts block
    this.data = this.fieldGroup.getDataSource(this.mainResourceData);
  }

}
