import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-alcohol-use',
  templateUrl: './alcohol-use.component.svg',
  styleUrls: [],
})
export class AlcoholUseComponent extends MedrecordIcon {
  viewHeight: number = 24;
  viewWidth: number = 24;
}
