import { createSelector } from '@ngrx/store';
import { middlewareManagerFeatureKey, MiddlewareManagerState } from './middleware-manager.reducers';
import { UserMetadataMeasurementGoal } from '../models/interfaces';
import { ObservationType } from '@medrecord/core';

export const getMiddlewareManagerState = (state): MiddlewareManagerState => state[middlewareManagerFeatureKey];

export const selectDefaultMeasurements = createSelector(
  getMiddlewareManagerState,
  (state): UserMetadataMeasurementGoal[] => state.goalsDefaultMeasurements
);

export const selectDefaultObservationTypes = createSelector(
  getMiddlewareManagerState,
  (state): ObservationType[] => state.goalsDefaultMeasurements.map(
    (measurement) => measurement.type
  )
);

export const selectMeasurements = createSelector(
  getMiddlewareManagerState,
  (state): UserMetadataMeasurementGoal[] => state.goalsMeasurements
);