import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvService } from '@medrecord/core';
import { Observable } from 'rxjs';
import { IUserModel } from '../models/interfaces/user.interface';
import { IUserItemModel } from '../models/interfaces/user-item.interface';
import { User } from '@medrecord/managers-users';
import { NetworkOrganisationPractitionerUpdate } from '@managers/admin-organisations-manager';

@Injectable({
  providedIn: 'root',
})
export class UsersManagementService {
  constructor(private http: HttpClient, private envService: EnvService) {}

  getUsers(start: number = 0, count: number = 20, search = ''): Observable<IUserItemModel[]> {
    let parameters = new HttpParams();
    parameters = parameters.set('start', String(start));
    parameters = parameters.set('count', String(count));
    parameters = parameters.set('search', String(search));
    return this.http.get<IUserItemModel[]>(`${this.envService.backend}/person/list`, { params: parameters });
  }

  editUser(userId: string, rolesArr: string[], status: string, statusChange: boolean): Observable<any> {
    return this.http.patch(
      this.envService.auth + '/api/users/' + userId + '/' + (statusChange ? 'status' : 'roles'),
      {
        roles: rolesArr,
        status: status.toUpperCase(),
      },
      { observe: 'response' }
    );
  }

  addUser(payload: IUserModel): Observable<any> {
    return this.http.post(`${this.envService.auth}/api/users`, payload, { observe: 'response' });
  }

  getUsersCount(search: string): Observable<number> {
    return this.http.get<number>(`${this.envService.backend}/person/count?search=${search}`);
  }

  addUserPersonData(personData: User, id: string): Observable<any> {
    return this.http.post<any>(`${this.envService.backend}/person/${id}`, personData);
  }

  editUserPersonData(personData: User, id: string): Observable<User> {
    return this.http.patch<User>(`${this.envService.backend}/person/${id}`, personData);
  }

  assignUserToOrganisation(organisationId: string, payload: NetworkOrganisationPractitionerUpdate): Observable<any> {
    return this.http.post(`${this.envService.backend}/network/organization/${organisationId}/practitioner`, payload);
  }

  updateUserOrganisation(organisationId: string, payload: NetworkOrganisationPractitionerUpdate): Observable<any> {
    return this.http.put<NetworkOrganisationPractitionerUpdate>(
      `${this.envService.backend}/network/organization/${organisationId}/practitioner/${payload.practitionerId}`,
      payload
    );
  }

  unassignUser(userId: string, organisationId: string): Observable<any> {
    return this.http.delete(`${this.envService.backend}/network/organization/${organisationId}/practitioner/${userId}`);
  }

  deleteUser(userId: string): Observable<any> {
    return this.http.delete(this.envService.backend + '/person/' + userId);
  }
}
