import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CareproviderLog, getLogs, getLogsModalOpenStatus, setLogsModalOpenStatusAction } from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-logs-modal-container',
  templateUrl: 'logs-modal-container.component.html'
})
export class LogsModalContainerComponent {
  isModalOpen$: Observable<boolean> = this.store.select(getLogsModalOpenStatus);
  logs$: Observable<CareproviderLog[]> = this.store.select(getLogs);

  constructor(private store: Store) {
  }

  onModalClosed(): void {
    this.store.dispatch(setLogsModalOpenStatusAction({ isOpen: false }));
  }
}
