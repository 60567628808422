import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import html2canvas from 'html2canvas';
import { clearFeedbackAction, isFeedbackActive, sendFeedbackAction } from '@managers/feedback-manager';
import { DrawableCanvasComponent } from '../drawable-canvas/drawable-canvas.component';
import { ScreenSize, getScreenSize } from '@managers/app-config-manager';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService } from '@medrecord/services-form-validation';

@Component({
  selector: 'medsafe-feedback-container',
  templateUrl: './feedback-container.component.html',
  styleUrls: ['./feedback-container.component.scss'],
})
export class FeedbackContainerComponent implements OnInit {
  visible$: Observable<boolean> = this.store.select(isFeedbackActive);
  screenSize$: Observable<ScreenSize> = this.store.select(getScreenSize);
  canvas: HTMLCanvasElement = null;
  visible = false;

  @ViewChild(DrawableCanvasComponent, { static: false }) drawableCanvas: DrawableCanvasComponent;

  form: FormGroup = this.formBuilder.group({
    description: ['', [this.validatorService.required]],
    imageDataUrl: [null, [this.validatorService.required]],
  });

  public isNextStep = false;
  public screenSizes = ScreenSize;

  ngOnInit(): void {
    this.visible$.subscribe((visible) => {
      if (visible) {
        (html2canvas as any)(document.body, { svgRendering: true }).then((canvas: any) => {
          this.canvas = canvas;
          this.visible = visible;
        });
      }
    });
  }

  public closeDialog(): void {
    this.store.dispatch(clearFeedbackAction());
    this.canvas = null;
    this.visible = false;
    this.isNextStep = false;
  }

  public onNext(): void {
    if (this.isNextStep) {
      this.form.markAllAsTouched();

      if (this.form.valid) {
        this.store.dispatch(sendFeedbackAction({ payload: this.form.value }));
      }
      return;
    }
    const imageDataUrl = this.drawableCanvas.getImageDataURL();
    this.form.get('imageDataUrl').setValue(imageDataUrl);
    this.isNextStep = true;
  }

  onCancel(): void {
    if (this.isNextStep) this.isNextStep = false;
    else this.closeDialog();
  }

  constructor(private store: Store, private formBuilder: FormBuilder, private validatorService: ValidationService) {}
}
