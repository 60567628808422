import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  addCareproviderToUserScopeAction,
  Careprovider,
  deleteCareproviderFromUserScopeAction,
  FetchCareproviderRequest,
  getCareproviders,
  getCareprovidersCount,
  loadCareprovidersAction,
  selectCareproviderAction
} from '@managers/my-doctor';
import { getSelectedUserPermission, Permission } from '@medrecord/managers-users';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PAGING_DEFAULT_COUNT, PAGING_DEFAULT_START_PAGE } from '../../../constants/careproviders-list';

@Component({
  selector: 'doctor-gui-careproviders-page-container',
  templateUrl: './careproviders-page-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CareprovidersPageContainerComponent implements OnInit, OnDestroy {
  careproviders$: Observable<Careprovider[]> = this.store.select(getCareproviders);
  count$: Observable<number> = this.store.select(getCareprovidersCount);
  permission$: Observable<Permission> = this.store.select(getSelectedUserPermission);

  private userScope: boolean;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.userScope = data?.userScope ?? false;

        this.reloadCareproviders({
          count: PAGING_DEFAULT_COUNT,
          startPage: PAGING_DEFAULT_START_PAGE,
          search: '',
          userScope: this.userScope
        });
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  careproviderSelected(careprovider: Careprovider): void {
    this.store.dispatch(selectCareproviderAction({ careprovider }));
  }

  updatePaging(fetchCareproviderRequest: FetchCareproviderRequest): void {
    this.reloadCareproviders(fetchCareproviderRequest);
  }

  onSearch(fetchCareproviderRequest: FetchCareproviderRequest): void {
    this.reloadCareproviders(fetchCareproviderRequest);
  }

  /**
   * favourite == userScope
   */
  toggleFavourite(data: {
    careproviderRequestPayload: FetchCareproviderRequest,
    careproviderId: string,
    wasFavourite: boolean
  }): void {
    const action = data.wasFavourite
      ? deleteCareproviderFromUserScopeAction
      : addCareproviderToUserScopeAction;
    this.store.dispatch(action({
      careproviderId: data.careproviderId,
      requestPayload: {
        ...data.careproviderRequestPayload,
        userScope: this.userScope
      }
    }));
  }

  private reloadCareproviders(fetchCareproviderRequest: FetchCareproviderRequest): void {
    fetchCareproviderRequest.userScope = this.userScope;
    this.store.dispatch(loadCareprovidersAction({ requestPayload: fetchCareproviderRequest }));
  }
}
