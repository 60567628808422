import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-eye',
  templateUrl: './eye.component.svg',
  styleUrls: ['./eye.component.scss'],
})
export class EyeComponent extends MedrecordIcon {
  viewHeight = 14;
  viewWidth = 24;
}
