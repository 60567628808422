<!-- Desktop Layout -->
<div class="container-fluid pt-5 desktop-background d-none d-sm-block">
  <div class="row text-center h-100">
    <div class="col-8 offset-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
      <ng-container *ngTemplateOutlet="logoImage"></ng-container>
      <div class="confirm-message">
        <ng-container *ngTemplateOutlet="infoText"></ng-container>
      </div>
    </div>
  </div>
</div>

<!-- Mobile Layout -->
<div class="container-fluid pt-5 h-100 mobile-background d-sm-none">
  <div class="row text-center h-100 mb-5">
    <div class="col-12">
      <ng-container *ngTemplateOutlet="logoImage"></ng-container>
    </div>
    <div class="col-12">
      <ng-container *ngTemplateOutlet="infoText"></ng-container>
    </div>
    <div class="col-12">
      <img class="w-75" src="assets/local-auth/confirm-email-mobile-background.png" alt="Account Activated" />
    </div>
  </div>
</div>

<ng-template #logoImage>
  <img class="logo-image" [src]="logoType" [alt]="clientName" height="70" />
</ng-template>

<ng-template #infoText>
  <h3 class="text-primary text-weight-600 my-3">{{ title }}</h3>
  <p class="text-black">{{ description }}</p>
</ng-template>
