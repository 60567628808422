import * as immunizationConstatnts from './immunization.constants';
import { Provider } from '@angular/core';
import { IMMUNIZATIONS_MANAGER_CONSTANTS } from '../immunizations-manager.tokens';

export const constants = {
  ...immunizationConstatnts
};

export type ImmunizationsManagerConstants = typeof constants;

export const provideImmunizationsManagerConstants = (): Provider => [
  {
    provide: IMMUNIZATIONS_MANAGER_CONSTANTS,
    useValue: constants
  }
];