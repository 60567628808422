import { Component, Input } from '@angular/core';

@Component({
  selector: 'medrecord-ui-block-truncated-column',
  templateUrl: './block-truncated-column.component.html',
  styleUrls: ['./block-truncated-column.component.scss']
})
export class BlockTruncatedColumnComponent {
  @Input() value: string;
}
