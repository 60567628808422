import { myDoctorManagerFeatureKey, State } from '../my-doctor-manager.reducer';
import { createSelector } from '@ngrx/store';
import { TestCareproviderFetchStatusType } from '../../models/enums';

const getState = (state: State) => state[myDoctorManagerFeatureKey];

export const getTestCareproviderStatusModalOpenStatus = createSelector(
  getState,
  (state: State): boolean => state.testCareproviderStatusModalOpenStatus
);

export const getTestCareproviderStatusFetchType = createSelector(
  getState,
  (state: State): TestCareproviderFetchStatusType => state.testCareproviderStatusFetchType
);

export const getTestCareproviderStatusFetchData = createSelector(
  getState,
  (state: State): string => state.testCareproviderStatusFetchData
);
