import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from '@medrecord/core';
import {
  Careprovider,
  CareproviderLogResponse,
  FetchCareproviderRequest,
  FetchResourceRequest,
  FetchResourceResponse,
  LoadResourceResponse,
  MappingResource
} from '../models/interfaces';
import { generalRouteNames } from '@medrecord/routes-general';
import { MY_DOCTOR_MANAGER_CONSTANTS } from '../my-doctor-manager.tokens';
import { MyDoctorManagerConstants } from '../constants';
import { map } from 'rxjs/operators';
import {
  TestCareproviderFetchStatusType,
  isResourceGroup,
  PullTransaction,
  Resource,
  TransactionMode
} from '../models/enums';

@Injectable()
export class MyDoctorManagerService {
  public performedAuthorizationOnce = false;

  constructor(
    private http: HttpClient,
    private envService: EnvService,
    @Inject(MY_DOCTOR_MANAGER_CONSTANTS) private myDoctorConstants: MyDoctorManagerConstants
  ) {
  }

  loadCareproviders(userId: string, payload: FetchCareproviderRequest): Observable<{ careProviders: Careprovider[] }> {
    let params = new HttpParams();

    Object.keys(payload).forEach((key) => {
      params = params.set(key, payload[key]);
    });

    return this.http.get<{ careProviders: Careprovider[] }>(
      `${this.backendController(userId)}/careprovider`,
      { params }
    )
      .pipe(map(data => ({
        careProviders: data.careProviders.map(careprovider => this.cleanCareprovider(careprovider))
      })));
  }

  loadCareprovidersCount(userId: string, search: string, userScope?: boolean): Observable<number> {
    let params = new HttpParams();
    params = params.set('search', search);
    if (userScope === true || userScope === false) {
      params = params.set('userScope', userScope.toString());
    }
    return this.http.get<number>(`${this.backendController(userId)}/careprovider/count`, { params });
  }

  loadCareprovider(userId: string, careproviderId: string): Observable<Careprovider> {
    return this.http.get<Careprovider>(`${this.backendController(userId)}/careprovider/info/${careproviderId}`)
      .pipe(map(careprovider => ({
        ...this.cleanCareprovider(careprovider)
      })));
  }

  loadResources(userId: string, careproviderId: string, resourceKeys: string[]): Observable<{
    data: LoadResourceResponse[]
  }> {
    let params = new HttpParams();

    params = params.set('resourceKeys', resourceKeys.join(','));

    return this.http.get<{ data: LoadResourceResponse[] }>(
      `${this.backendController(userId)}/careprovider/${careproviderId}`,
      { params }
    );
  }

  loadLogs(userId: string, careproviderId: string): Observable<CareproviderLogResponse[]> {
    return this.http.get<CareproviderLogResponse[]>(
      `${this.backendController(userId)}/careprovider/${careproviderId}/log`
    );
  }

  loadList(userId: string, type: TestCareproviderFetchStatusType): Observable<{ status: string }> {
    return this.http.post<{ status: string }>(
      `${this.backendController(userId)}/list/${type}`,
      { body: {} }
    );
  }

  fetchResources(
    userId: string,
    careproviderId: string,
    payload: FetchResourceRequest
  ): Observable<{ resources: FetchResourceResponse[] }> {
    return this.http.post<{ resources: FetchResourceResponse[] }>(
      `${this.backendController(userId)}/careprovider/${careproviderId}`,
      { ...payload }
    );
  }

  loadDigIdUrl(
    userId: string,
    careproviderId: string,
    dataServiceId: string
  ): Observable<{ authEndpointUrl: string; correlationId: string; medMijRequestId: string }> {
    return this.http.post<{ authEndpointUrl: string; correlationId: string; medMijRequestId: string }>(
      `${this.backendController(userId)}/careprovider/${careproviderId}/auth/${dataServiceId}/url`,
      {
        redirectUrl: `${window.location.origin}/${generalRouteNames.Patient.LegacyMedmijAuthBack}`
      }
    );
  }

  checkAuthorizationToken(
    userId: string,
    authorizationCode: string,
    state: string,
    correlationId: string,
    medMijRequestId: string
  ): Observable<void> {
    const params = new HttpParams().append(
      'errors_notification_models',
      JSON.stringify([{ hideError: true }])
    );

    return this.http.post<void>(
      `${this.backendController(userId)}/careprovider/auth/token`,
      {
        authorizationCode,
        redirectUrl: `${window.location.origin}/${generalRouteNames.Patient.LegacyMedmijAuthBack}`,
        state,
        correlationId,
        medMijRequestId,
      },
      { params }
    );
  }

  logAuthError(
    userId: string,
    careproviderId: string,
    dataServiceId: string,
    authEndpointUrl: string,
    correlationId: string,
    medMijRequestId: string,
    error: string,
    errorDescription: string,
  ): Observable<any> {
    return this.http.post<{ authEndpointUrl: string; correlationId: string; medMijRequestId: string }>(
      this.envService.backend +
      '/extdata/' +
      userId +
      '/careprovider/' +
      careproviderId +
      '/auth/' +
      dataServiceId +
      '/error',
      {
        authEndpointUrl,
        correlationId,
        environment: '',
        error,
        errorDescription,
        medMijRequestId,
        redirectUrl: `${window.location.origin}/external-services/healthcare-providers/auth-back`,
      }
    );
  }

  getPortabilityReport(userId: string, startDate: string, endDate: string): Observable<{ content: string }> {
    return this.http.post<{ content: string }>(`${this.backendController(userId)}/medmij/portability`, {
      startDate,
      endDate
    });
  }

  addCareproviderToUserScope(careproviderId: string, userId: string): Observable<void> {
    return this.http.post<void>(
      `${this.backendController(userId)}/user/careprovider/${careproviderId}`,
      { body: {} }
    );
  }

  deleteCareproviderFromUserScope(careproviderId: string, userId: string): Observable<void> {
    return this.http.delete<void>(`${this.backendController(userId)}/user/careprovider/${careproviderId}`);
  }

  deleteData(userId: string, careProviderId: string, resourceKeys: string[]): Observable<void> {
    const queryParams = resourceKeys?.length ? '?resourceKeys=' + resourceKeys.join(',') : '';
    const endpointUrl = careProviderId
      ? `${this.backendController(userId)}/careprovider/${careProviderId}${queryParams}`
      : `${this.backendController(userId)}`;
    return this.http.delete<void>(endpointUrl);
  }

  toFlatResources(mappingResources: MappingResource[], transactionId: PullTransaction): Resource[] {
    const pullMapping = this.myDoctorConstants.pullTransactionMappings.get(transactionId);
    const resources = mappingResources.reduce((prev, curr) => [
      ...prev,
      ...(isResourceGroup(curr) ? pullMapping.resourceGroups[curr] : [curr as Resource]),
      ...(pullMapping.additionalResources?.[curr] ? pullMapping.additionalResources[curr] : [])
    ], [] as Resource[]);
    return Array.from(new Set(resources));
  }

  private cleanCareprovider(careprovider: Careprovider): Careprovider {
    careprovider.dataServices = careprovider.dataServices.filter(dataservice => {
      const transaction = dataservice.transactions.find(t => t.mode === TransactionMode.pull);
      return this.myDoctorConstants.pullTransactionMappings.get(transaction?.id);
    });
    return careprovider;
  }

  isTest(careproviderId: string): boolean {
    return careproviderId === this.myDoctorConstants.TEST_PROVIDER_ID;
  }

  // BE: DataExchangeController
  private backendController(userId: string): string {
    return `${this.envService.backend}/extdata/${userId}`;
  }
}
