<ng-container *ngTemplateOutlet="list; context: { listItems: items, parentIds: [] }"></ng-container>

<ng-template #list let-listItems="listItems" let-parentIds="parentIds">
  <ng-container *ngIf="typeListItems(listItems) as items">
    <ul *ngIf="listItems" class="side-bar-list">
      <li
        *ngFor="let item of items; let i = index"
        [class.child-list-item]="parentIds.length"
        [class.active]="item.active || activeMap.includes(item.id) || (parentIds.length === activeMap.length && !i)"
        (click)="clickItem($event, item, parentIds)"
      >
        <div [class.text-lg]="!parentIds.length" [class.text-sm]="parentIds.length">
          <div>
            <medrecord-ui-dynamic-icon
              *ngIf="item.iconComponent"
              class="icon"
              [width]="24"
              [iconComponent]="item.iconComponent"
            ></medrecord-ui-dynamic-icon>
            <i *ngIf="item.iconClass && !item.iconComponent" [class]="item.iconClass" class="icon fa"></i>
            <span
              @itemTextIconDisplay
              *ngIf="!item.iconClass && !item.iconComponent && !isSidebarOpen"
              class="icon text-weight-600 text-lg"
            >
              {{ (item.text | translate)[0] | uppercase }}
            </span>
            <span
              *ngIf="isSidebarOpen"
              @itemDisplay
              [class.ml-3]="isSidebarOpen"
              [class.ml-4]="!item.iconClass && !item.iconComponent"
              [class.text-weight-600]="
                !parentIds.length &&
                (item.active || activeMap.includes(item.id) || (parentIds.length === activeMap.length && !i))
              "
              >{{ item.text | translate }}</span
            >
          </div>
          <i
            *ngIf="item.children"
            class="fas fa-chevron-right ml-auto transition"
            [@chevronDisplay]="!isSidebarOpen ? 'none' : item.active || activeMap.includes(item.id) ? 'down' : 'right'"
          ></i>
        </div>

        <ng-container *ngIf="isSidebarOpen && (item.active || activeMap.includes(item.id))">
          <ng-container *ngTemplateOutlet="list; context: getContext(item, parentIds)"></ng-container>
        </ng-container>
      </li>
    </ul>
  </ng-container>
</ng-template>
