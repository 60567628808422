<h2 class="text-weight-600 text-primary mb-3 text-center">
  {{ 'auth_forgot_password' | translate }}
</h2>

<p class="text-lg my-5">{{ 'auth_forgot_password_please_enter_email' | translate }}</p>

<form [formGroup]="form" (ngSubmit)="handleSubmit()" class="text-center">
  <medrecord-ui-map-field
    [attr.id]="isOAuth && 'oauth-email-field'"
    [fieldType]="mapFieldType.Input"
    [label]="'auth_enter_your_email' | translate"
    controlName="email"
    [parentForm]="form"
  >
    <ng-template #leftIcon>
      <i class="far fa-envelope text-black"></i>
    </ng-template>
  </medrecord-ui-map-field>

  <button
    [class.btn-rounded]="isOAuth"
    class="btn btn-block btn-primary font-weight-bold mt-5 text-lg"
    type="submit"
    [disabled]="!form.valid || hasDelay"
  >
    <ng-container *ngIf="!time">{{ 'auth_forgot_password_button' | translate }}</ng-container>
    <ng-container *ngIf="time">{{ time }}</ng-container>
  </button>
</form>
