import { myDoctorManagerFeatureKey, State } from '../my-doctor-manager.reducer';
import { createSelector } from '@ngrx/store';
import { CareproviderLog, MappingResource } from '../../models/interfaces';

const getState = (state: State) => state[myDoctorManagerFeatureKey];

export const getLogs = createSelector(
  getState,
  (state: State): CareproviderLog[] => state.logs
);

export const getDeleteDataModalOpenStatus = createSelector(
  getState,
  (state: State): boolean => state.deleteDataModalOpenStatus
);

export const getDeleteDataModalResource = createSelector(
  getState,
  (state: State): MappingResource => state.deleteDataModalResource
);

export const getDeleteDataModalIsDataservice = createSelector(
  getState,
  (state: State): boolean => state.deleteDataModalIsDataservice
);
