import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';
import { BlocksMapperHelperUtil } from '../../utils/blocks-mapper-helper.util';

export const gpPractitionerBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.practitioner,
  fieldGroups: [
    {
      name: 'my_doctor_practitioner_role_resp',
      fields: [
        {
          label: 'my_doctor_name',
          fieldName: 'name',
          prepareData: (data) => BlocksMapperHelperUtil.mapName(data)
        },
        {
          label: 'my_doctor_specialism',
          fieldName: 'specialties',
          useResource: ResourceType.practitionerRole,
          prepareData: (data) => data?.[0]?.coding?.[0]?.code
        },
        {
          label: 'my_doctor_identification',
          fieldName: 'identifiers',
          prepareData: (data, resources) => {
            const practitionerRole = resources.find((v) => v.resourceType === ResourceType.practitionerRole);
            return [practitionerRole?.content?.practitionerReference?.display, data?.[0]?.value]
              .filter(Boolean)
              .join(', ');
          }
        },
        {
          label: 'my_doctor_address',
          fieldName: 'addressList',
          prepareData: (data) => [
            [
              data?.[0]?.streetName,
              data?.[0]?.houseNumber
            ].filter(part => part).join(' '),
            [
              data?.[0]?.postalCode,
              data?.[0]?.city
            ].filter(part => part).join(' '),
            data?.[0]?.landGbaCode?.coding?.[0]?.display
          ].filter(part => part).join(', ')
        },
        {
          label: 'my_doctor_address_type',
          fieldName: 'addressList',
          translate: true,
          prepareData: (data) => data?.[0]?.use
        },
        {
          label: 'my_doctor_phone_number',
          fieldName: 'telecoms',
          useResource: ResourceType.practitionerRole,
          prepareData: (data, _, resource) =>
            (data?.length ? data : resource?.content?.contactList)?.find((t) => t.system === 'PHONE')?.value
        },
        {
          label: 'my_doctor_phone_type',
          fieldName: 'telecoms',
          useResource: ResourceType.practitionerRole,
          translate: true,
          prepareData: (data, _, resource) => {
            const phone = (data?.length ? data : resource?.content?.contactList)?.find((t) => t.system === 'PHONE');
            return phone?.use ? 'common_contact_use_' + phone?.use?.toLowerCase() : '';
          }
        },
        {
          label: 'my_doctor_email',
          fieldName: 'telecoms',
          useResource: ResourceType.practitionerRole,
          prepareData: (data, _, resource) =>
            (data?.length ? data : resource?.content?.contactList)?.find((t) => t.system === 'EMAIL')?.value
        },
        {
          label: 'my_doctor_email_type',
          fieldName: 'telecoms',
          useResource: ResourceType.practitionerRole,
          translate: true,
          prepareData: (data, _, resource) => {
            const email = (data?.length ? data : resource?.content?.contactList)?.find((t) => t.system === 'EMAIL');
            return email?.use ? 'common_contact_use_' + email?.use?.toLowerCase() : '';
          }
        },
        {
          label: 'my_doctor_gender',
          fieldName: 'gender',
          translate: true,
          prepareData: (data) => (data ? 'common_gender_' + data?.toLowerCase() : '')
        }
      ]
    },
    {
      name: 'my_doctor_healthcare_provider',
      fields: [
        {
          label: 'my_doctor_organization_name',
          fieldName: 'name',
          useResource: ResourceType.organization,
          prepareData: (data) => data
        },
        {
          label: 'my_doctor_organization_type',
          fieldName: 'types',
          useResource: ResourceType.organization,
          prepareData: (data) => data?.[0]?.coding[0]?.display
        },
        {
          label: 'my_doctor_address',
          fieldName: 'addresses',
          useResource: ResourceType.organization,
          prepareData: (data) => [
            [
              data?.[0]?.streetName,
              data?.[0]?.houseNumber,
              data?.[0]?.postalCode,
              data?.[0]?.city
            ].filter(part => part).join(' '),
            data?.[0]?.landGbaCode?.coding?.[0]?.display
          ].filter(part => part).join(', ')
        },
        {
          label: 'my_doctor_address_type',
          fieldName: 'addresses',
          useResource: ResourceType.organization,
          translate: true,
          prepareData: (data) => data?.[0]?.use
        },
        {
          label: 'my_doctor_identification',
          fieldName: 'identifiers',
          useResource: ResourceType.organization,
          prepareData: (data) => data?.[0]?.value
        }
      ]
    }
  ]
});
