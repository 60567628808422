import { Component, Input } from '@angular/core';
import { TableBlock, TableField, TableResourceKey } from '../../../models/table-resource-key.interface';

@Component({
  selector: 'doctor-gui-resource-key-table',
  templateUrl: './resource-key-table.component.html',
  styleUrls: ['./resource-key-table.component.scss']
})
export class ResourceKeyTableComponent {
  @Input() set tableResourceKey(tableResourceKey: TableResourceKey) {
    this.templateTableResourceKey = {
      blocks: tableResourceKey.blocks
        .map(block => this.prepareForTemplate(block))
    };
  }

  templateTableResourceKey: TemplateTableResourceKey;

  prepareForTemplate(block: TableBlock): TemplateTableBlock {
    const fields = block.fields.filter(f => f.value);
    let leftColumn: TableField[];
    let rightColumn: TableField[];
    if (fields.length <= 2) {
      leftColumn = fields;
      rightColumn = [];
    } else {
      const middleIndex = Math.ceil(fields.length / 2);
      leftColumn = fields.slice(0, middleIndex);
      rightColumn = fields.slice(middleIndex);
    }
    return { title: block.title, leftColumn, rightColumn };
  }
}

interface TemplateTableResourceKey {
  blocks: TemplateTableBlock[];
}

interface TemplateTableBlock {
  title: string;
  leftColumn: TableField[];
  rightColumn: TableField[];
}
