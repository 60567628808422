import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidationService } from '@medrecord/services-form-validation';
import { FORM_RULES, FormRules, TwofaFlow } from '@medrecord/managers-auth';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'medrecord-auth-twofa-enter-code',
  templateUrl: './twofa-enter-code.component.html'
})
export class TwofaEnterCodeComponent implements OnInit {
  @Input() flow: TwofaFlow;

  @Output() submitCode: EventEmitter<string> = new EventEmitter();

  codeForm: FormGroup;

  constructor(
    private validationService: ValidationService,
    private translateService: TranslateService,
    @Inject(FORM_RULES) private formRules: FormRules
  ) {
  }

  ngOnInit(): void {
    const recoveryCodeLength = this.formRules.twofaRecoveryCodeExactLength;
    const codeLength = this.formRules.twofaCodeExactLength;
    this.codeForm = new FormGroup({
      code: new FormControl('', [
        this.validationService.number(this.translateService.instant('common_validation_only_numeric')),
        this.validationService.exactLength(codeLength,
          this.translateService.instant('common_validation_exact_length_digits', { number: codeLength })),
        this.validationService.required(this.translateService.instant('auth_validation_error_required'))
      ]),
      recoveryCode: new FormControl('', [
        this.validationService.exactLength(recoveryCodeLength,
          this.translateService.instant('common_validation_exact_length', { number: recoveryCodeLength })),
        this.validationService.required(this.translateService.instant('auth_validation_error_required'))
      ])
    });
  }

  get isRecovery(): boolean {
    return this.flow === TwofaFlow.Recovery;
  }

  submit(): void {
    let controlName: string;
    let requiredLength: number;
    let expectedNumeric: boolean;
    if (this.isRecovery) {
      controlName = 'recoveryCode';
      requiredLength = 17;
      expectedNumeric = false;
    } else {
      controlName = 'code';
      requiredLength = 6;
      expectedNumeric = true;
    }
    const code = this.codeForm.get(controlName)?.value;
    if (code && code.length === requiredLength && (!expectedNumeric || this.isNumeric(code))) {
      this.submitCode.emit(code);
    }
  }

  private isNumeric(value: string): boolean {
    return /^[0-9]+$/.test(value);
  }
}
