import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResourceBlock, ResourceData, ResourceResponseData } from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss']
})
export class ResourceComponent implements OnInit {
  @Input() resource: ResourceData;
  @Input() isGroupedResource: boolean;
  @Input() block: ResourceBlock;
  @Input() recordId: string;
  @Output() getDetailsData: EventEmitter<string> = new EventEmitter<string>();

  isExpanded = false;
  mainResources: ResourceResponseData[] = [];
  title: string;

  ngOnInit(): void {
    this.title = this.resource.key;

    this.mainResources = this.resource.resources
      .filter(r =>
        r.resourceType === this.block.resourceType
        || (this.block.additionalResourceTypes ?? []).includes(r.resourceType)
      )
      .sort((r1, r2) => this.resource.sort?.format(r1.content, r2.content));
    // if sort doesn't exist null is returned which =0, which means they are "the same", which means "keep the order"

    if (this.block.isResourceFromField) {
      this.mainResources = this.mainResources.reduce((prev, current, i) => {
        let newResources = [current];
        const extractedData = current.content?.[this.block.fieldForResources];
        if (Array.isArray(extractedData)) {
          newResources = extractedData.map(newContent => ({
            ...current,
            content: newContent,
            resourceId: current.resourceId + '-' + i
          }));
        } else if (extractedData && typeof extractedData === 'object') {
          newResources = [{
            ...current,
            content: extractedData,
            resourceId: current.resourceId + '-' + i
          }];
        }

        return [...prev, ...newResources];
      }, [] as ResourceResponseData[]);
    }
  }
}
