import { createAction, props } from '@ngrx/store';
import { Careprovider, CareproviderLog, FetchCareproviderRequest } from '../../models/interfaces';

export const loadCareprovidersAction = createAction(
  '[My Doctor Manager Actions] Load Careproviders',
  props<{ requestPayload: FetchCareproviderRequest }>()
);

export const loadCareprovidersSuccessAction = createAction(
  '[My Doctor Manager Actions] Load Careproviders Success',
  props<{ careproviders: Careprovider[]; count: number }>()
);

export const selectCareproviderAction = createAction(
  '[My Doctor Manager Actions] Select Careprovider',
  props<{ careprovider: Careprovider }>()
);

export const setNavigatedToCareproviderDetailsFromAction = createAction(
  '[My Doctor Manager Actions] Set Navigated To Careprovider Details From',
  props<{ from: 'favourites' | 'search' }>()
);

export const navigateToCareproviderAction = createAction(
  '[My Doctor Manager Actions] Navigate To Careprovider',
  props<{
    careproviderId: string,
    dataserviceId: string,
    detailsRoute: string
  }>()
);

export const setActiveCareproviderAction = createAction(
  '[My Doctor Manager Actions] Set Active Careprovider',
  props<{ careprovider: Careprovider }>()
);

export const validateCareproviderLoadedAction = createAction(
  '[My Doctor Manager Actions] Validate Careprovider Loaded',
  props<{ careproviderId: string; dataserviceId: string; recordId?: string }>()
);

export const prepareCareproviderDataAction = createAction(
  '[My Doctor Manager Actions] Prepare Careprovider Data',
  props<{ dataserviceId: string; recordId: string }>()
);

export const loadLogsAction = createAction(
  '[My Doctor Manager Actions] Load Logs'
);

export const loadLogsSuccessAction = createAction(
  '[My Doctor Manager Actions] Load Logs Success',
  props<{ logs: CareproviderLog[] }>()
);

export const setLogsModalOpenStatusAction = createAction(
  '[My Doctor Manager Actions] Set Logs Modal Open Status',
  props<{ isOpen: boolean }>()
);

export const navigateBackToCareprovidersAction = createAction(
  '[My Doctor Manager Actions] Navigate Back To Careproviders'
);

export const addCareproviderToUserScopeAction = createAction(
  '[My Doctor Manager Actions] Add Careprovider To User Scope',
  props<{ careproviderId: string; requestPayload: FetchCareproviderRequest }>()
);

export const deleteCareproviderFromUserScopeAction = createAction(
  '[My Doctor Manager Actions] Delete Careprovider From User Scope',
  props<{ careproviderId: string; requestPayload: FetchCareproviderRequest }>()
);
