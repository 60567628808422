import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MedrecordSpinnerToolModule } from '@tools/spinner';
import { MedrecordStorageModule } from '@medrecord/tools-storage';
import { MedrecordCustomerMetadataModule } from '@medrecord/services-customer-metadata';
import { MedrecordToastToolModule } from '@medrecord/tools-toast';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { EnvService, provideConstants, provideDefaultLang, provideStorageKey } from '@medrecord/core';
import { createTranslateLoader } from './factories/create-translation-loader.factory';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AuthSharedModule } from '@medrecord/auth-shared';
import {
  AuthManagerModule,
  provideAuthorizationInterceptor,
  provideLogoutOnIdle,
  provideTokenInitialization,
} from '@medrecord/managers-auth';
import { MedrecordDatetimeModule } from '@medrecord/services-datetime';
import { UsersManagerModule } from '@medrecord/managers-users';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { CoachingErrorHandler } from './utils/coaching-error-handler';
import { RoleAccessGuard } from './guards/role-access.guard';
import { PaymentModuleGuard } from './guards/payment-module.guard';
import { MedrecordSocialLoginModule } from '@medrecord/services-social-login';
import { provideGoogleAuthConfig } from './providers/provide-google-auth-config';
import { TranslationsManagementModule } from '@medrecord/translations-management';
import { AllergiesManagerModule } from '@managers/allergies';
import { MeasurementsManagerModule } from '@managers/measurements';
import { QuestionnaireManagerModule } from '@managers/questionnaires';
import { MiddlewareManagerModule } from '@managers/middleware';
import { ImmunizationsManagerModule } from '@managers/immunizations-manager';
import { MedicationsManagerModule } from '@managers/medications-manager';
import { ConditionsManagerModule } from '@managers/conditions-manager';
import { PaymentManagerModule } from '@managers/payment';
import { MedrecordCommonModule } from '@medrecord/common';
import { MedrecordNavigationModule } from '@medrecord/tools-navigation';
import { MedrecordSubscriptionsModule } from '@tools/subscriptions';
import { NetworkManagerModule } from '@managers/network';
import { HealthplansManagerModule } from '@managers/healthplans-manager';
import { MyDoctorManagerModule } from '@managers/my-doctor';
import { NewsManagerModule } from '@managers/news-manager';
import { FoodFriendManagerModule } from '@medsafe/food-friend-manager';
import { redirectionInitializer } from '@medsafe/user-my-doctor';
import { CareplansManagerModule } from '@managers/careplans-manager';
import { WeatherManagerModule } from '@managers/weather-manager';
import { AppConfigManagerModule } from '@managers/app-config-manager';
import { FeedbackManagerModule } from '@managers/feedback-manager';
import { CalendarManagerModule } from '@managers/calendar-manager';

@NgModule({
  declarations: [AppComponent],
  imports: [
    MedrecordCommonModule,
    BrowserModule,
    BrowserAnimationsModule,

    AppRoutingModule,
    HttpClientModule,

    /** Tools */
    MedrecordSpinnerToolModule,
    MedrecordCustomerMetadataModule,
    MedrecordToastToolModule,
    MedrecordDatetimeModule,
    MedrecordNavigationModule,
    MedrecordSubscriptionsModule,
    /** Tools End */

    MedrecordSocialLoginModule.forRoot(),
    MedrecordStorageModule.forRoot(),

    AuthSharedModule,

    /** Managers */
    AllergiesManagerModule,
    ImmunizationsManagerModule,
    MedicationsManagerModule,
    ConditionsManagerModule,
    AuthManagerModule,
    MeasurementsManagerModule,
    MiddlewareManagerModule,
    QuestionnaireManagerModule,
    UsersManagerModule,
    PaymentManagerModule,
    NetworkManagerModule,
    HealthplansManagerModule,
    MyDoctorManagerModule,
    NewsManagerModule,
    FoodFriendManagerModule,
    CareplansManagerModule,
    WeatherManagerModule,
    AppConfigManagerModule,
    FeedbackManagerModule,
    CalendarManagerModule,
    /** Managers End */

    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      enableSourceMaps: true,
      colorScheme: ['purple', 'teal', 'blue', 'gray', 'red', 'red', 'red'],
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, EnvService],
      },
    }),

    TranslationsManagementModule,

    StoreModule.forRoot(
      {},
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),

    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    redirectionInitializer(),
    provideGoogleAuthConfig(),
    provideAuthorizationInterceptor(),

    provideDefaultLang(),
    provideStorageKey(),
    provideConstants(),

    provideTokenInitialization(),

    environment.production ? provideLogoutOnIdle() : [],

    RoleAccessGuard,
    PaymentModuleGuard,
    {
      provide: ErrorHandler,
      useClass: CoachingErrorHandler,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
