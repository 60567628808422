import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Language, EnvService } from '@medrecord/core';
import { Store } from '@ngrx/store';
import { mergeMap, switchMap } from 'rxjs/operators';
import { TranslationsCategory, TranslationItem, TranslationsByLanguage } from '../models';
import * as selectFromTranslations from '../state-management/translations-management.selectors';

@Injectable({
  providedIn: 'root'
})
export class TranslationsManagementService {
  language$: Observable<string> = this.store.select(selectFromTranslations.selectCurrentLanguage);
  translations$: Observable<TranslationsByLanguage> = this.store.select(selectFromTranslations.getAllTranslations);
  
  constructor(
    private envService: EnvService,
    private store: Store,
    private httpClient: HttpClient,
  ) {}
  
  getTranslationsByCategory(
    category: TranslationsCategory,
    start: number,
    language?: string
  ): Observable<{ data: TranslationItem[], totalPages: number, total: number }> {
    const params = new HttpParams()
      .append('start', start.toString())
      .append('count', '999');
    
    return this.language$.pipe(
      mergeMap((selectedLang) =>
        this.httpClient.get<{ data: TranslationItem[], totalPages: number, total: number }>(
          this.envService.backend + `/translation/lang/${language || selectedLang}/category/${category}`,
          {params}
        )
      )
    );
  }
  
  getCategories(): Observable<TranslationsCategory[]> {
    return this.httpClient.get<TranslationsCategory[]>(
      this.envService.backend + '/translation/categories'
    );
  }
  
  updateTranslation(translation: TranslationItem): Observable<any> {
    return this.language$.pipe(
      switchMap((language) => {
        const languages = [];
  
        for (const languageKey in Language) {
          if(Language.hasOwnProperty(languageKey) && (Language[languageKey] !== language)) {
            languages.push(Language[languageKey]);
          }
        }
        
        return this.translations$.pipe(
          switchMap((translations) =>
            this.httpClient.put(
              this.envService.backend + `/translation/${translation.keyId}`,
              {
                id: translation.keyId,
                key: translation.key,
                category: translation.category,
                translationLocalizations: [
                  {
                    id: translation.id,
                    lang: language,
                    description: translation.description,
                  },
                  ...languages.map((unchangedLang: string) => {
                    const translationForCurrentLang = translations[unchangedLang][translation.category]
                      .translations
                      .find(
                        t => t.key === translation.key
                      );
    
                    return {
                      description: translationForCurrentLang.description,
                      id: translationForCurrentLang.id,
                      lang: unchangedLang,
                    };
                  })
                ]
              }
            )
          ));
      }));
    }
}