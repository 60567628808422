<button
  ejs-dropdownbutton
  [style.min-width]="'255px'"
  [style.min-height]="'47px'"
  cssClass="e-flat e-secondary"
  [items]="precisions"
  [disabled]="disabled"
  (select)="selectPrecision($event)"
  [content]="'common_precision_' + selectedPrecision?.toLowerCase() | translate"
></button>
