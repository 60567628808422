import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { completeSubjectAction, unsubscribeAction } from './subscriptions.actions';

@Injectable()
export class SubscriptionsEffects {
  unsubscribe$ = createEffect(
    () => this.actions$.pipe(
      ofType(unsubscribeAction),
      switchMap(({ subscriptions }) => {
        
        subscriptions.forEach((sub) => sub.unsubscribe());
        
        return [];
      })
    )
  );
  
  completeSubject = createEffect(
    () => this.actions$.pipe(
      ofType(completeSubjectAction),
      switchMap(({ subject }) => {
        subject.next(0);
        subject.complete();
        
        return [];
      })
    )
  );
  
  constructor(
    private actions$: Actions,
  ) {}
}