import { Component } from '@angular/core';
import {
  getActiveCareproviderName,
  getActiveDataserviceName,
  getAllResourcesKeys,
  getFilteredResourcesKeys,
  getResourcesModalOpenStatus,
  MappingResource,
  setResourcesModalOpenStatusAction,
  submitResourcesModalAction
} from '@managers/my-doctor';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'doctor-gui-resources-modal-container',
  templateUrl: 'resources-modal-container.component.html'
})
export class ResourcesModalContainerComponent {
  isModalOpen$: Observable<boolean> = this.store.select(getResourcesModalOpenStatus);
  careproviderName$: Observable<string> = this.store.select(getActiveCareproviderName);
  dataserviceName$: Observable<string> = this.store.select(getActiveDataserviceName);
  resources$: Observable<MappingResource[]> = this.store.select(getAllResourcesKeys);
  preselectedResources$: Observable<MappingResource[]> = this.store.select(getFilteredResourcesKeys);

  constructor(
    private store: Store
  ) {
  }

  onModalSubmit(resources: MappingResource[]): void {
    this.store.dispatch(submitResourcesModalAction({ resources }));
  }

  setModalClosed(): void {
    this.store.dispatch(setResourcesModalOpenStatusAction({ isOpen: false }));
  }
}
