import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppConfigManagerEffects } from './app-config-manager.effects';
import { appConfigManagerFeatureKey, reducer } from './app-config-manager.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(appConfigManagerFeatureKey, reducer),
    EffectsModule.forFeature([AppConfigManagerEffects]),
  ],
})
export class AppConfigManagerStateModule {}
