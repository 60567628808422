import { Directive, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { Breakpoints, ScreenSize } from '@managers/app-config-manager';

@Directive({
  selector: '[onlyForScreen]',
})
export class ResizeDirective implements OnInit, OnDestroy {
  @Input('onlyForScreen') screenSize: ScreenSize;

  // listenFunc will hold the function returned by "renderer.listen"
  listenFunc: Function;

  ngOnInit() {
    this.displayElement(window.innerWidth);
    this.listenFunc = this.render.listen('window', 'resize', (event) => {
      this.displayElement(event?.target?.visualViewport?.width);
    });
  }

  ngOnDestroy() {
    // Removes "listen" listener
    this.listenFunc();
  }

  private displayElement(visualViewportWidth: number) {
    if (this.isValidSize(visualViewportWidth)) {
      if (!this.viewContainer.get(0)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewContainer.clear();
    }
  }

  private isValidSize(viewportWidth: number) {
    switch (this.screenSize) {
      case ScreenSize.mobile:
        return viewportWidth < Breakpoints.mobile;
      case ScreenSize.tablet:
        return viewportWidth < Breakpoints.desktop;
      default:
        return viewportWidth > Breakpoints[this.screenSize];
    }
  }

  constructor(
    private render: Renderer2,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}
}
