import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'doctor-gui-abstract-modal',
  template: '<p>implementation is not needed</p>'
})
export class AbstractModalComponent { // abstract
  @Input() isModalOpen: boolean;
  @Output() modalClosed: EventEmitter<void> = new EventEmitter<void>();

  closeModal(): void {
    this.modalClosed.emit();
  }
}
