import { on } from '@ngrx/store';
import { setActiveDataserviceAction, setActiveTransactionIdAction } from '../actions';
import { State } from '../my-doctor-manager.reducer';

export const dataserviceReducer = [
  on(setActiveDataserviceAction, (state: State, { dataservice }) => ({
    ...state,
    activeDataservice: dataservice
  })),

  on(setActiveTransactionIdAction, (state: State, { transactionId }) => ({
    ...state,
    activeTransactionId: transactionId
  }))
];
