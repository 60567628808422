import * as sideBarConstants from './sidebar.constants';
import { InjectionToken, Provider } from '@angular/core';

export const SIDEBAR_CONSTANTS = new InjectionToken('Constants for configuring sidebar view');
export type SideBarConstants = typeof sideBarConstants;

export const UiConstantsProviders = {
  provideSidebarConstants(): Provider {
    return [
      {
        provide: SIDEBAR_CONSTANTS,
        useValue: sideBarConstants
      }
    ];
  }
};