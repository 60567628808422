import { Component, Input, EventEmitter, Output } from '@angular/core';
import { TreeViewItem } from '../../models/interfaces/tree-view-item.interface';
import { animate, group, state, style, transition, trigger } from '@angular/animations';

const showAnimations = [
  animate(
    '0.2s 0.3s ease',
    style({
      height: 'inherit',
    })
  ),
  animate(
    '0.3s 0.5s ease',
    style({
      color: 'inherit',
    })
  ),
];

const hideAnimations = [
  animate(
    '0.3s 0s ease',
    style({
      color: 'transparent',
    })
  ),
  animate(
    '0.2s 0.3s ease',
    style({
      height: 0,
    })
  ),
];

export const animations = [
  trigger('itemDisplay', [
    transition('* => void', group(hideAnimations)),
    transition('void => *', [
      style({
        color: 'transparent',
        height: 0,
      }),
      group(showAnimations),
    ]),
  ]),

  trigger('itemTextIconDisplay', [
    transition(
      '* => void',
      group([
        animate(
          '0.3s 0s ease',
          style({
            color: 'transparent',
          })
        ),
        animate(
          '0s 0.8s',
          style({
            height: 0,
          })
        ),
      ])
    ),
    transition('void => *', [
      style({
        color: 'transparent',
        height: 0,
        width: 0,
      }),
      group([
        animate(
          '0s 0s',
          style({
            height: 'inherit',
          })
        ),
        animate(
          '0.3s 0s ease',
          style({
            color: 'inherit',
          })
        ),
        animate('0s 0.3s', style({ width: 'inherit' })),
      ]),
    ]),
  ]),

  trigger('chevronDisplay', [
    state(
      'none',
      style({
        color: 'transparent',
        height: 0,
      })
    ),
    state(
      'down',
      style({
        color: 'var(--primary-color)',
        transform: 'rotate(90deg)',
      })
    ),
    state(
      'right',
      style({
        color: 'inherit',
        transform: 'rotate(0deg)',
      })
    ),

    transition('none => *', group(showAnimations)),
    transition('* => none', group(hideAnimations)),
    transition(
      'down => right',
      animate(
        '0.2s ease',
        style({
          transform: 'rotate(-90deg)',
        })
      )
    ),
    transition(
      'right => down',
      animate(
        '0.2s ease',
        style({
          transform: 'rotate(90deg)',
        })
      )
    ),
  ]),
];


@Component({
  selector: 'medrecord-ui-tree-list',
  templateUrl: './tree-list.component.html',
  styleUrls: ['./tree-list.component.scss'],
  animations: animations
})
export class TreeListComponent {
  @Input() items: TreeViewItem[] = [];
  @Input() activeMap: string[] = [];
  @Input() isSidebarOpen = true;
  @Output() select = new EventEmitter();

  typeListItems(items: TreeViewItem[]): TreeViewItem[] {
    return items;
  }

  getContext(item: TreeViewItem, ids: string[]): { listItems: TreeViewItem[]; parentIds: string[] } {
    return {
      listItems: item.children,
      parentIds: [...ids, item.id],
    };
  }

  clickItem(event: MouseEvent, item: TreeViewItem, parentIds: string[]): void {
    event.stopPropagation();

    this.activeMap = [...parentIds, item.id];
    this.select.emit({ item, treeMap: this.activeMap });
  }
}
