import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-first-aid-kit',
  templateUrl: './first-aid-kit.component.svg',
})
export class FirstAidKitComponent extends MedrecordIcon {
  viewHeight = 18;
  viewWidth = 18;
}
