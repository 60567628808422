import * as Core from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as ejInputs from '@syncfusion/ej2-angular-inputs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Core.Component({
  selector: 'medrecord-ejs-medrecord-uploader',
  templateUrl: './ejs-medrecord-uploader.component.html',
  styleUrls: ['./ejs-medrecord-uploader.component.scss']
})
export class EjsMedrecordUploaderComponent implements Core.AfterViewInit, Core.OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @Core.ContentChild('buttonContent') buttonContent: Core.TemplateRef<any>;
  
  @Core.ViewChild('uploader') uploader: ejInputs.UploaderComponent;
  
  dropArea: HTMLElement = this.document.createElement('div');
  dropAreaId = new Date().toDateString();
  
  @Core.Input() autoUpload = true;
  @Core.Input() asyncSettings: ejInputs.AsyncSettingsModel;
  @Core.Input() noDataMessage = 'No Selected files';
  @Core.Input() maxSizeInBytes: number;
  @Core.Input() multiple = true;
  @Core.Input() allowedFileTypes: string;
// @Core.Input() clearAll?: Observable<boolean>;
// the reason why clearAll?: Observable<boolean>;  method throws an error is that; at the begining it doesn't implemented so it becomes null and thats why it throws error
  @Core.Input() clearAll?=new Subject<boolean>(); // what i did is basicly implemented the default subject
  @Core.Output() uploadPrepared = new Core.EventEmitter();
  
  isEmpty = true;
  
  constructor(
    @Core.Inject(DOCUMENT) private document,
  ) {}
  
  ngAfterViewInit(): void {
    this.dropArea = this.document.getElementById('drop-area-' + this.dropAreaId);
    
    this.document.getElementById('browse').onclick = (event) => {
      event.preventDefault();//it prevents default events, i don't know why it routes as default but i prevented it.
      this.dropArea = this.document.getElementById('drop-area-' + this.dropAreaId);
      this.document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click();
    };
  
    this.clearAll.pipe(takeUntil(this.destroy$)).subscribe((e: boolean) => {
      if (e) {
        this.uploader.clearAll();
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  
  onFileSelected({ filesData }: ejInputs.SelectedEventArgs): void {
    this.isEmpty = !filesData.length;
    this.uploadPrepared.emit({ files: filesData, removing: false });
  }
  
  onFilesRemoving({ filesData }: ejInputs.RemovingEventArgs): void {
    this.isEmpty = !(filesData.length - 1);
    this.uploadPrepared.emit({ files: filesData, removing: true });
  }
}
