import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-shield',
  templateUrl: './shield.component.svg',
})
export class ShieldComponent extends MedrecordIcon {
  viewHeight = 18;
  viewWidth = 15;
}
