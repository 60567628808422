import { Resource, ResourceType } from '../enums';
import {
  CollapsibleTable,
  ColumnDefinition,
  LoadResourceResponse,
  TableCell,
  TableGroup,
  TabularResource
} from '../interfaces';
import { TranslateService } from '@ngx-translate/core';

export abstract class GenericMapper<C extends string> {

  private allData: LoadResourceResponse[];
  protected allResources: { [type in ResourceType]?: { [id: string]: any } };
  protected mainResources: any[];

  constructor(protected translateService: TranslateService) {
  }

  protected abstract get title(): string;

  protected abstract get mainKey(): Resource;

  protected abstract get mainType(): ResourceType;

  protected abstract get sortBy(): C[];

  protected abstract get groupBy(): C;

  protected abstract get groupSortOrder(): { [key: string]: number };

  protected abstract get activeGroups(): string[];

  protected abstract get columns(): ColumnDefinition<C>[];

  protected abstract get collapsibleTables(): CollapsibleTable<C>[];

  protected get noGroups(): boolean {
    return false;
  }

  mapData(loadResourceResponse: LoadResourceResponse[]): TabularResource<C> {
    this.allData = loadResourceResponse;
    this.allResources = this.extractAllResources(this.mainKey);
    let groups: TableGroup<C>[];
    if(this.allResources) {
      this.mainResources = Object.values(this.allResources[this.mainType]);

      const allRows = this.getAllRows();
      this.sort(allRows);
      // this.sortCollapsibles(allRows);
      if (this.groupBy) {
        groups = this.toTableGroups(allRows);
      } else {
        groups = [{
          groupName: null,
          active: null,
          rows: allRows
        }];
      }
    } else {
      groups = [];
    }
    return {
      title: this.title,
      groups,
      columns: this.columns,
      collapsibleTables: this.collapsibleTables,
      noGroups: this.noGroups
    };
  }

  protected extractAllResources(forKey: Resource): { [type in ResourceType]?: { [id: string]: any } } {
    const mainResourceKeyGroup = this.allData.find(response => response.key === forKey);
    if (!mainResourceKeyGroup) {
      return null;
    }
    const allResources = {};
    mainResourceKeyGroup.resources.forEach(resource => {
      if (!allResources[resource.resourceType]) {
        allResources[resource.resourceType] = {};
      }
      allResources[resource.resourceType][resource.resourceId] = resource.content;
    });
    return allResources;
  }

  protected abstract getAllRows(): TableCell<C>[];

  private sort(rows: TableCell<C>[]): void {
    if (!this.sortBy) {
      return;
    }
    rows.sort(this.compare);
  }

  protected compare = (a: TableCell<C>, b: TableCell<C>): number => {
    let aVal: any, bVal: any;
    for (const key of this.sortBy) {
      if (aVal === undefined && a[key]?.[0] !== undefined && a[key]?.[0] !== null) {
        aVal = a[key][0];
      }
      if (bVal === undefined && b[key]?.[0] !== undefined && b[key]?.[0] !== null) {
        bVal = b[key][0];
      }
      if (aVal !== undefined && bVal !== undefined) {
        return bVal - aVal;
      }
    }
    if (aVal === undefined && bVal === undefined) {
      return 0;
    }
    return aVal === undefined ? 1 : -1;
  }

  // private sortCollapsibles(rows: TableCell<C>[]): void {
  // }

  private toTableGroups(allRows: TableCell<C>[]): TableGroup<C>[] {
    const obj = this.group(allRows, this.groupBy);
    const tableGroups: TableGroup<C>[] = Object.entries(obj).map(([k, v]) => ({
      groupName: k,
      active: this.activeGroups.includes(k),
      rows: v
    }));
    this.sortGroups(tableGroups);
    return tableGroups;
  }

  private group(array: TableCell<C>[], key: C): { [p: string]: TableCell<C>[] } {
    return array.reduce((result, curr) => {
      const groupKey = curr[key][0] as string;
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(curr);
      return result;
    }, {} as { [key: string]: TableCell<C>[] });
  }

  private sortGroups(groups: TableGroup<C>[]): void {
    groups.sort((a, b) => {
      if(this.groupSortOrder[a.groupName] !== undefined && this.groupSortOrder[b.groupName] !== undefined) {
        return this.groupSortOrder[a.groupName] - this.groupSortOrder[b.groupName];
      }
      if(this.groupSortOrder[a.groupName] === undefined && this.groupSortOrder[b.groupName] === undefined) {
        return 0;
      }
      return this.groupSortOrder[a.groupName] === undefined ? 1 : -1;
    });
  }

  protected getReference(type: ResourceType, reference?: {
    display: string,
    identifier: string,
    reference: string,
  }, allResources = this.allResources): any {
    return allResources[type]?.[this.idFromReference(reference)];
  }

  private idFromReference(reference?: {
    display: string,
    identifier: string,
    reference: string,
  }): string {
    return reference?.reference.split('/')[1];
  }

}