import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureUsersManagerKey, UsersManagerState } from './users-manager.reducer';
import { User, UserTabData } from '../models/interfaces';
import { UserMetadata } from '../models/interfaces/user-metadata.interface';
import { Permission } from '../models/enums';

export const getUsersManagerState = createFeatureSelector<UsersManagerState>(featureUsersManagerKey);

export const getMainUserTabData = createSelector(
  getUsersManagerState,
  (state: UsersManagerState): UserTabData => state.users.find((user) => user.permission === Permission.MainAccount)
);

export const getUserDataById = (id: string) =>
  createSelector(
    getUsersManagerState,
    (state: UsersManagerState): User => state.users.find((user) => user.id === id)?.user
  );

export const getUserTabDataById = (id: string) =>
  createSelector(getUsersManagerState, (state: UsersManagerState): UserTabData => state.users.find((user) => user.id === id));

export const selectCurrentID = createSelector(
  getUsersManagerState,
  (state: UsersManagerState): string => state.selectedUserId
);

export const getSelectedUser = createSelector(
  getUsersManagerState,
  (state: UsersManagerState): User => state.users.find((user) => user.id === state.selectedUserId)?.user
);

export const getSelectedUserMetadata = createSelector(
  getUsersManagerState,
  (state: UsersManagerState): UserMetadata => state.users.find((user) => user.id === state.selectedUserId)
);

export const getSelectedUserTabData = createSelector(
  getUsersManagerState,
  (state: UsersManagerState): UserTabData  => state.users.find((user) => user.id === state.selectedUserId)
);

export const getSelectedUserPermission = createSelector(
  getUsersManagerState,
  (state: UsersManagerState): Permission  => state.users.find((user) => user.id === state.selectedUserId)?.permission
);

export const getOpenedUsers = createSelector(
  getUsersManagerState,
  (state: UsersManagerState): UserTabData[] => state.users
);

export const getPinnedUserIds = createSelector(
  getUsersManagerState,
  (state: UsersManagerState): string[] => state.pinnedUsers
);

export const getPinnedUsers = createSelector(getUsersManagerState, (state: UsersManagerState): User[] =>
  state.pinnedUsers.map((id) => ({ id, ...state.users[id].user }))
);
