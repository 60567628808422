import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from '@medrecord/core';
import { Immunization } from '../models/interfaces/immunization.interface';
import { ImmunizationsLoadPayload } from '../models/interfaces/immunizations-load-payload.interface';
import { ImmunizationLocation } from '../models/interfaces/immunization-location.interface';

@Injectable()
export class ImmunizationsManagerService {
  constructor(
    private http: HttpClient,
    private envService: EnvService
  ) {
  }
  
  loadImmunizations(userId: string, payload: ImmunizationsLoadPayload): Observable<Immunization[]> {
    let params = new HttpParams();
    
    Object.keys(payload).forEach((key) => {
      params = params.set(key, payload[key]);
    });
    
    return this.http.get<Immunization[]>(
      this.envService.backend + '/patient/' + userId + '/immunization',
      { params }
    );
  }

  loadImmunizationsCount(userId: string): Observable<number> {
    return this.http.get<number>(this.envService.backend + '/patient/' + userId + '/immunization/count');
  }

  loadImmunization(id: string, userId: string): Observable<Immunization> {
    return this.http.get<Immunization>(this.envService.backend + '/patient/' + userId + '/immunization/' + id);
  }

  loadImmunizationLocations(userId: string): Observable<ImmunizationLocation[]> {
    return this.http.get<ImmunizationLocation[]>(this.envService.backend + '/patient/' + userId + '/immunization/location');
  }

  createImmunization(userId: string, immunization: Immunization): Observable<any> {
    return this.http.post(this.envService.backend + '/patient/' + userId + '/immunization', immunization);
  }

  updateImmunization(userId: string, immunizationId: string, immunizationForm: Immunization): Observable<any> {
    return this.http.put(
      this.envService.backend + '/patient/' + userId + '/immunization/' + immunizationId,
      immunizationForm
    );
  }

  deleteImmunization(id: string, userId: string): Observable<any> {
    return this.http.delete(this.envService.backend + '/patient/' + userId + '/immunization/' + id);
  }
}