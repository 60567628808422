import { Component, Input } from '@angular/core';
import { MedrecordInputNgControlComponentBase } from '../../base/medrecord-input-ng-control-component-base.component';
import { DatePrecision, MedrecordMoment } from '@medrecord/services-datetime';
import { ChangedEventArgs } from '@syncfusion/ej2-angular-calendars';

export declare type CalendarView = 'Month' | 'Year' | 'Decade';

@Component({
  selector: 'medrecord-datepicker-precision',
  templateUrl: './medrecord-datepicker-precision.component.html',
  styleUrls: ['./medrecord-datepicker-precision.component.scss']
})
export class MedrecordDatepickerPrecisionComponent extends MedrecordInputNgControlComponentBase {
  
  @Input() firstDayOfWeek = 1;
  @Input() startCalendarView: CalendarView = 'Year';
  @Input() calendarViewDepth: CalendarView = 'Month';
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() defaultPrecision: DatePrecision = DatePrecision.second;
  @Input() precision: DatePrecision = DatePrecision.day;
  
  handleChange(data: ChangedEventArgs): void {
    if (!data.value) {
      return;
    }
  
    this.value = {
      date: MedrecordMoment(data.value).toISOString(),
      precision: this.defaultPrecision
    };
  }
  
  markAsTouched(): void {
    this.control.control.markAsTouched();
  }
  
}
