import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Dto, EnvService } from '@medrecord/core';
import { Observable } from 'rxjs';
import { UserStatusEntity } from '../models/user-status-entity.interface';

@Injectable({
  providedIn: 'root',
})
export class UserStatusService {
  constructor(
    private http: HttpClient,
    private envService: EnvService
  ) {
  }
  
  getUserStatus(userId: string): Observable<Dto<UserStatusEntity>> {
    return this.http.get<Dto<UserStatusEntity>>(`${ this.envService.backend}/status`, { params: { userId } });
  }
}
