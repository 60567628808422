import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-oxygen-saturation',
  templateUrl: './oxygen-saturation.component.svg',
  styleUrls: [],
})
export class OxygenSaturationComponent extends MedrecordIcon {
  viewHeight: number = 24;
  viewWidth: number = 24;
}
