import { Inject, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { navigateToCareproviderAction, redirectToAvailableDataserviceAction } from '../actions';
import { getActiveCareprovider } from '../selectors';
import { MEDSAFE_ROUTE_NAMES, MedsafeRouteNames } from '@medrecord/routes-medsafe';

@Injectable()
export class DataserviceEffects {

  @Effect()
  redirectToAvailableDataservice$ = this.actions$.pipe(
    ofType(redirectToAvailableDataserviceAction),
    withLatestFrom(this.store.select(getActiveCareprovider)),
    mergeMap(([, activeCareprovider]) => {
      // if dataservice with the id given in url doesn't exist in the careprovider
      if (activeCareprovider.dataServices.length > 0) {
        // that can happen if someone tries to manually type the url to reach the page
        // then select first available data service
        const dataservice = activeCareprovider.dataServices[0];
        return [
          navigateToCareproviderAction({
            careproviderId: activeCareprovider.id,
            dataserviceId: dataservice.id,
            detailsRoute: null
          })
        ];
      }
      // if careprovider doesn't have any dataservices
      // this can happen when:
      // 1. BE returns careprovider without dataservice (should not happen)
      // 2. BE returns careprovider with only dataservices to which we don't have mapping on FE
      // and this can happen if we are in process of getting certified, so BE added them first
      // these dataservices are removed (cleaned) in the service.ts right after we fetch the data
      return [
        navigateToCareproviderAction({
          careproviderId: activeCareprovider.id,
          dataserviceId: this.medsafeRouteNames.MyDoctor.Unknown,
          detailsRoute: null
        })
      ];
    })
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    @Inject(MEDSAFE_ROUTE_NAMES) private medsafeRouteNames: MedsafeRouteNames
  ) {
  }
}
