import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { first, map, withLatestFrom } from 'rxjs/operators';
import { generalRouteNames } from '@medrecord/routes-general';
import { Role, selectRoles, selectUserId } from '@medrecord/managers-auth';
import { selectCurrentID } from '@medrecord/managers-users';

@Injectable()
export class RoleAccessGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectRoles).pipe(
      withLatestFrom(this.store.select(selectCurrentID), this.store.select(selectUserId)),
      first(),
      map(([roles, selectedUserId, userId]) => {
        if (!roles?.length) {
          return this.router.parseUrl(`/${generalRouteNames.Auth.Entry}`);
        }

        if (route.url?.[0].path !== 'redirect'
          && (route.routeConfig.data?.roles as Role[])?.some(r => roles.includes(r))) {
          return true;
        }

        if (roles.includes(Role.Patient) || roles.includes(Role.Buddy)) {
          return this.router.parseUrl(`/${generalRouteNames.Patient.Entry}/${selectedUserId || userId}`);
        }

        if (roles.includes(Role.Doctor)) {
          return this.router.parseUrl(`/${generalRouteNames.Doctor.Entry}`);
        }

        if (roles.includes(Role.Administrator)) {
          return this.router.parseUrl(`/${generalRouteNames.Admin.Entry}`);
        }

        return this.router.parseUrl(`/${generalRouteNames.Auth.Entry}`);
      })
    );
  }
}
