import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { NavigationEnd, Router } from '@angular/router';
import { TreeViewItem } from '@medrecord/ui';
import { NavigationUtils } from '@medrecord/core';
import { ScreenSize, getScreenSize, getSidebarHidden, setSidebarHidden } from '@managers/app-config-manager';
import { Observable } from 'rxjs';

@Component({
  selector: 'medsafe-sidebar-wrapper',
  templateUrl: './sidebar-wrapper.component.html',
  styleUrls: ['./sidebar-wrapper.component.scss'],
})
export class SidebarWrapperComponent implements OnInit {
  @Input()
  items: TreeViewItem[] = [];

  @ViewChild('sidebarContent', { static: true }) sidebarContent: ElementRef;

  screenSize$: Observable<ScreenSize> = this.store.select(getScreenSize);
  isSidebarHidden$: Observable<boolean> = this.store.select(getSidebarHidden);

  public isSidebarHidden: boolean;
  public currentScreenSize: ScreenSize;

  public width = '280px';
  public dockSize = '64px';
  public bottomOffset = '57px';
  public isOpen = true;

  target = '.main-content';

  activeMap: string[] = [];

  screenSizes = ScreenSize;

  private rootPath: string[];

  ngOnInit(): void {
    const items = this.items.map((item) => item.id);
    this.screenSize$.subscribe((screenSize) => {
      this.currentScreenSize = screenSize;
      this.handleSidebar();
    });

    this.isSidebarHidden$.subscribe((isSidebarHidden) => (this.isSidebarHidden = isSidebarHidden));

    [this.rootPath, this.activeMap] = this.navigationUtils.getRootPath(items);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        [this.rootPath, this.activeMap] = this.navigationUtils.getRootPath(items);

        this.sidebarContent.nativeElement.scrollTo(0, 0);
      }
    });
  }

  public selectItem(value: any): Promise<boolean> {
    this.handleSidebar();
    return this.router.navigate([...this.rootPath, ...value.treeMap]);
  }

  public toggleSidebar(): void {
    this.isOpen = !this.isOpen;
  }

  private handleSidebar() {
    if (
      (this.currentScreenSize === this.screenSizes.tablet || this.currentScreenSize === this.screenSizes.mobile) &&
      !this.isSidebarHidden
    ) {
      this.store.dispatch(setSidebarHidden({ hideSidebar: true }));
      this.isOpen = true;
    } else if (this.currentScreenSize === this.screenSizes.desktop && this.isSidebarHidden) {
      this.store.dispatch(setSidebarHidden({ hideSidebar: false }));
      this.isOpen = true;
    }
  }

  constructor(private router: Router, private navigationUtils: NavigationUtils, private store: Store) {}
}
