import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { MedsafeRouteNames, MedsafeRouteParams, MEDSAFE_ROUTE_NAMES } from '@medrecord/routes-medsafe';
import { selectUserId } from '@medrecord/managers-auth';
import { selectUser } from '../state-management/users-manager.actions';
import { GeneralRouteNames, GENERAL_ROUTE_NAMES } from '@medrecord/routes-general';
import { map, withLatestFrom } from 'rxjs/operators';
import { getOpenedUsers, selectCurrentID } from '../state-management/users-manager.selectors';

@Injectable()
export class CurrentPatientGuard implements CanActivate {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(GENERAL_ROUTE_NAMES) private generalRouteNames: GeneralRouteNames,
    @Inject(MEDSAFE_ROUTE_NAMES) private medsafeRouteNames: MedsafeRouteNames
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(selectUserId).pipe(
      withLatestFrom(this.store.select(getOpenedUsers), this.store.select(selectCurrentID)),
      map(([userId, openedUsers, selectedUserId]) => {
        if (
          route.params[MedsafeRouteParams.id] !== selectedUserId &&
          openedUsers.find((user) => user.id === route.params[MedsafeRouteParams.id])
        ) {
          this.store.dispatch(selectUser({ id: route.params[MedsafeRouteParams.id] }));
        }

        if (
          !route.params[MedsafeRouteParams.id] ||
          (route.params[MedsafeRouteParams.id] &&
            route.params[MedsafeRouteParams.id] !== userId &&
            !openedUsers.find((user) => user.id === route.params[MedsafeRouteParams.id]))
        ) {
          return this.router.parseUrl(
            `/${this.generalRouteNames.Patient.Entry}/${userId}/${this.medsafeRouteNames.Dashboard.Entry}`
          );
        }

        return true;
      })
    );
  }
}
