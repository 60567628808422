import { Component, Input } from '@angular/core';
import { Position } from '@syncfusion/ej2-angular-popups';
@Component({
  selector: 'medrecord-ui-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss']
})
export class HintComponent {
  @Input() hint: string;
  @Input() placement: Position = 'BottomCenter';
}