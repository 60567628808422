import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-circle-arrow-right',
  templateUrl: './circle-arrow-right.component.svg',
})
export class CircleArrowRightComponent extends MedrecordIcon {
  viewHeight = 16;
  viewWidth = 16;
}
