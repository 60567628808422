<div [class.opened-container]="sidebar.isOpen">
  <ejs-sidebar class="sidebar"
               #sidebar
               [enableDock]='sidebarConstants.enableDock'
               [width]='sidebarConstants.width'
               [dockSize]='sidebarConstants.dockSize'
               [type]="sidebarConstants.type"
               (created)="onCreated()"
               [animate]="sidebarConstants.animate"
               [mediaQuery]='sidebarConstants.mediaQuery'
               [target]='sidebarConstants.target'
               (close)="onClose()"
  >
    <div class="d-flex flex-column h-100">
      <div class="p-3 text-center logotype">
        <ng-container *ngTemplateOutlet="logotype;context: { isOpen: sidebar.isOpen }"></ng-container>
      </div>

      <div class="p-2 text-center">
        <ng-container *ngTemplateOutlet="userInfo;context: { isOpen: sidebar.isOpen }"></ng-container>
      </div>

      <div class="d-flex flex-column justify-content-between flex-1 h-100">
        <div class="main-menu">
          <div>
            <ejs-treeview class="menu-items"
                          [selectedNodes]="[selectedNodeID]"
                          [expandedNodes]="[expandedNodeID]"
                          #treeView
                          [fields]='menuItems'
                          expandOn='Click'
                          [class.hide-text]="!sidebar.isOpen"
                          (nodeSelected)="nodeIdChanged.emit($event)"
            ></ejs-treeview>

          </div>
        </div>

        <button
          ejs-button
          class="e-flat btn-block bg-athens d-flex align-items-center mt-auto py-3 font-weight-normal text-md"
          (click)="sidebar.toggle()"
        >
          <i class="fas fa-chevron-circle-left text-lg mr-3" *ngIf="sidebar.isOpen"></i>
          <i class="fas fa-chevron-circle-right text-lg" *ngIf="!sidebar.isOpen"></i>

          <span class="text-lg" *ngIf="sidebar.isOpen">Collapse</span>
        </button>
      </div>

    </div>
  </ejs-sidebar>
</div>