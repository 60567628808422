export enum Unit {
  Mg = 'MG',
  G = 'G',
  Percent = 'PERCENT',
  Hours = 'h',
  Mcg = 'MCG',
  Units = 'UNITS',
  Ml = 'ml',
  MlH = 'ml/h',
}
