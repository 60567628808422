import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-move-minutes',
  templateUrl: './move-minutes.component.svg',
  styleUrls: [],
})
export class MoveMinutesComponent extends MedrecordIcon {
  viewHeight: number = 24;
  viewWidth: number = 24;
}
