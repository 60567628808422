import { createSelector } from '@ngrx/store';
import { AppConfigManagerState, appConfigManagerFeatureKey } from './app-config-manager.reducer';
import { ScreenSize } from '../model/enums/screen.enum';

export const getAppConfigManagerState: (state: AppConfigManagerState) => any = (state: AppConfigManagerState) =>
  state[appConfigManagerFeatureKey];

export const getScreenSize = createSelector(
  getAppConfigManagerState,
  (state: AppConfigManagerState): ScreenSize => state.screenSize
);

export const getSidebarHidden = createSelector(
  getAppConfigManagerState,
  (state: AppConfigManagerState): boolean => state.hideSidebar
);
