import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as NetworkOrganisationsActions from './network-organisations.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NetworkOrganisationsService } from '../services/network-organisations.service';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NetworkOrganisationPractitionerUpdate } from '../models/network-organisation-practitioner-update.interface';
import { combineLatest } from 'rxjs';

@Injectable()
export class NetworkOrganisationsEffects {
  @Effect()
  loadOrganisations$ = this.actions$.pipe(
    ofType(NetworkOrganisationsActions.loadNetworkOrganisationsAction),
    switchMap((action) => {
      this.store.dispatch(NetworkOrganisationsActions.networkOrganisationsActionLoading({ loading: true }));
      return this.service.getOrganisations(action.start, action.count).pipe(
        map((res) => {
          return NetworkOrganisationsActions.loadNetworkOrganisationsSuccessAction({
            organisations: res,
          });
        }),
        catchError(({ error }) => {
          return [
            NetworkOrganisationsActions.networkOrganisationsFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_organisations_error'), error)),
          ];
        })
      );
    })
  );

  @Effect()
  loadOrganisationsCount$ = this.actions$.pipe(
    ofType(NetworkOrganisationsActions.loadAllOrganisationsCountAction),
    switchMap(() => {
      return this.service.getOrganisationsCount().pipe(
        map((count) => {
          return NetworkOrganisationsActions.loadAllOrganisationsCountActionSuccess({
            count,
          });
        }),
        catchError(({ error }) => {
          return [
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_organisations_error'), error)),
          ];
        })
      );
    })
  );

  @Effect()
  getOrganisation$ = this.actions$.pipe(
    ofType(NetworkOrganisationsActions.getNetworkOrganisationAction),
    switchMap((action) => {
      return this.service.getOrganisation(action.id).pipe(
        map((res) => {
          return NetworkOrganisationsActions.getNetworkOrganisationSuccessAction({
            organisation: res,
          });
        }),
        catchError(({ error }) => {
          return [
            NetworkOrganisationsActions.networkOrganisationsFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('get_organisations_error'), error)),
          ];
        })
      );
    })
  );

  @Effect()
  addOrganisation$ = this.actions$.pipe(
    ofType(NetworkOrganisationsActions.addNetworkOrganisationAction),
    switchMap((action) => {
      return this.service.createOrganisation(action.organisation).pipe(
        map((res) => {
          this.store.dispatch(
            addSuccessToast({ title: 'organisation_add_success_title', content: 'organisation_add_success_content' })
          );
          return NetworkOrganisationsActions.addNetworkOrganisationSuccessAction({
            organisation: res,
          });
        }),
        catchError(({ error }) => {
          return [
            NetworkOrganisationsActions.networkOrganisationsFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('add_organisation_error'), error)),
          ];
        })
      );
    })
  );

  @Effect()
  editOrganisation$ = this.actions$.pipe(
    ofType(NetworkOrganisationsActions.editNetworkOrganisationAction),
    switchMap((action) => {
      return this.service.editOrganisation(action.organisation).pipe(
        map((res) => {
          this.store.dispatch(
            addSuccessToast({
              title: 'organisation_update_success_title',
              content: 'organisation_update_success_content',
            })
          );
          return NetworkOrganisationsActions.editNetworkOrganisationSuccessAction({
            organisation: res,
          });
        }),
        catchError(({ error }) => {
          return [
            NetworkOrganisationsActions.networkOrganisationsFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('edit_organisation_error'), error)),
          ];
        })
      );
    })
  );

  @Effect()
  deleteOrganisation$ = this.actions$.pipe(
    ofType(NetworkOrganisationsActions.deleteNetworkOrganisationAction),
    switchMap((action) => {
      return this.service.deleteOrganisation(action.id).pipe(
        map(() => {
          this.store.dispatch(
            addSuccessToast({
              title: 'organisation_delete_success_title',
              content: 'organisation_delete_success_content',
            })
          );
          return NetworkOrganisationsActions.deleteNetworkOrganisationSuccessAction({
            id: action.id,
          });
        }),
        catchError(({ error }) => {
          return [
            NetworkOrganisationsActions.networkOrganisationsFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('delete_organisation_error'), error)),
          ];
        })
      );
    })
  );

  @Effect()
  getPractitioners$ = this.actions$.pipe(
    ofType(NetworkOrganisationsActions.getPractitionersAction),
    switchMap((action) => {
      return this.service.getPractitioners(action.id).pipe(
        map((res) => {
          return NetworkOrganisationsActions.getPractitionersSuccessAction({
            practitioners: res,
          });
        }),
        catchError(({ error }) => {
          return [
            NetworkOrganisationsActions.networkOrganisationsFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('get_practitioner_error'), error)),
          ];
        })
      );
    })
  );

  @Effect()
  getAllPractitioners$ = this.actions$.pipe(
    ofType(NetworkOrganisationsActions.getAllPractitionersAction),
    switchMap((action) => {
      return this.service.getAllPractitioners(action.name).pipe(
        map((res) => {
          return NetworkOrganisationsActions.getAllPractitionersSuccessAction({
            practitioners: res,
          });
        }),
        catchError(({ error }) => {
          return [
            NetworkOrganisationsActions.networkOrganisationsFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('get_all_practitioners_error'), error)),
          ];
        })
      );
    })
  );

  @Effect()
  deletePractitioner$ = this.actions$.pipe(
    ofType(NetworkOrganisationsActions.deletePractitionerAction),
    switchMap((action) => {
      return this.service.deletePractitioner(action.id, action.organisationId).pipe(
        map(() => {
          this.store.dispatch(
            addSuccessToast({
              title: 'practitioner_delete_success_title',
              content: 'practitioner_delete_success_content',
            })
          );
          return NetworkOrganisationsActions.deletePractitionerSuccessAction({
            id: action.id,
          });
        }),
        catchError(({ error }) => {
          return [
            NetworkOrganisationsActions.networkOrganisationsFailureAction({ error }),
            addErrorToast(getErrorToastBodyUtil(this.translateService.instant('delete_practitioner_error'), error)),
          ];
        })
      );
    })
  );

  @Effect()
  updatePractitionerRoles$ = this.actions$.pipe(
    ofType(NetworkOrganisationsActions.updatePractitionerRoleInOrganisationAction),
    switchMap((action) => {
      return this.service.updatePractitionerRoles(action.payload, action.organisationId).pipe(
        map((res) => {
          this.store.dispatch(
            addSuccessToast({ title: 'saving_data_title', content: 'practitioners_saving_data_content' })
          );
          return NetworkOrganisationsActions.updatePractitionerRoleInOrganisationSuccessAction({
            payload: res,
          });
        }),
        catchError(({ error }) => {
          return [
            NetworkOrganisationsActions.networkOrganisationsFailureAction({ error }),
            addErrorToast(
              getErrorToastBodyUtil(this.translateService.instant('update_practitioner_roles_error'), error)
            ),
          ];
        })
      );
    })
  );

  @Effect()
  bulkUpdatePractitionerRoles$ = this.actions$.pipe(
    ofType(NetworkOrganisationsActions.bulkUpdatePractitionerRoleInOrganisationAction),
    switchMap(({ payload, organisationId }) => {
      const promises = payload.map((practitioner: NetworkOrganisationPractitionerUpdate) => {
        const { practitionerId, role, medicalRoles } = practitioner;
        return this.service.updatePractitionerRoles({ practitionerId, medicalRoles, role }, organisationId);
      });

      return combineLatest(promises).pipe(
        map(() => {
          this.store.dispatch(
            addSuccessToast({ title: 'saving_data_title', content: 'practitioners_saving_data_content' })
          );
          return NetworkOrganisationsActions.bulkUpdatePractitionerRoleInOrganisationSuccessAction({ payload });
        }),
        catchError(({ error }) => {
          return [
            addErrorToast(
              getErrorToastBodyUtil(this.translateService.instant('update_user_organisations_error'), error)
            ),
          ];
        })
      );
    })
  );

  @Effect()
  addParticipantToOrganisation = this.actions$.pipe(
    ofType(NetworkOrganisationsActions.addParticipantToOrganisationAction),
    switchMap((action) => {
      return this.service.addPractitioner(action.participant, action.organisationId).pipe(
        map((res) => {
          this.store.dispatch(
            addSuccessToast({ title: 'practitioner_add_success_title', content: 'practitioner_add_success_content' })
          );
          return NetworkOrganisationsActions.addParticipantToOrganisationSuccessAction({
            participant: {
              ...action.participant,
              ...res,
            },
          });
        }),
        catchError(({ error }) => {
          return [
            NetworkOrganisationsActions.networkOrganisationsFailureAction({ error }),
            addErrorToast(
              getErrorToastBodyUtil(this.translateService.instant('add_participant_to_organization_error'), error)
            ),
          ];
        })
      );
    })
  );

  @Effect()
  getUserOrganisations = this.actions$.pipe(
    ofType(NetworkOrganisationsActions.getCurrentUserOrganisations),
    switchMap(({ userId }) => {
      this.store.dispatch(NetworkOrganisationsActions.networkOrganisationsActionLoading({ loading: true }));
      return this.service.getUserOrganisations(userId).pipe(
        map((organisations) => {
          return NetworkOrganisationsActions.getCurrentUserOrganisationsSuccess({
            organisations,
          });
        }),
        catchError(({ error }) => {
          return [
            // NetworkOrganisationsActions.clear({ error }),
            addErrorToast(
              getErrorToastBodyUtil(this.translateService.instant('add_participant_to_organization_error'), error)
            ),
          ];
        })
      );
    })
  );

  constructor(
    private actions$: Actions,
    private translateService: TranslateService,
    private service: NetworkOrganisationsService,
    private store: Store
  ) {}
}
