import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';

declare let fabric: any;

@Component({
  selector: 'medsafe-drawable-canvas',
  template: ``,
  styleUrls: ['./drawable-canvas.component.scss'],
})
export class DrawableCanvasComponent implements AfterViewInit {
  @Input() width: number = 800;
  @Input() height: number = 600;
  @Input() bgImage: any;

  private fabricCanvas: any;

  private rect: any;
  private isDown: boolean;
  private origX: number;
  private origY: number;

  ngAfterViewInit(): void {
    const fabricCanvas = document.createElement('canvas');
    fabricCanvas.width = this.width;
    fabricCanvas.height = this.height;
    fabricCanvas.id = 'screenshot-canvas';

    this.host.nativeElement.appendChild(fabricCanvas);

    this.fabricCanvas = new fabric.Canvas('screenshot-canvas', { selection: false });

    fabric.Image.fromURL(this.bgImage, (img) => {
      img.scaleToWidth(this.width);
      img.scaleToHeight(this.height);

      this.fabricCanvas.setBackgroundImage(img, this.fabricCanvas.renderAll.bind(this.fabricCanvas), {
        backgroundImageOpacity: 0.5,
        backgroundImageStretch: true,
      });

      this.setDrawListeners();
    });
  }

  public getImageDataURL() {
    return this.fabricCanvas.toDataURL();
  }

  private setDrawListeners() {
    let pointer;

    this.fabricCanvas.on('mouse:down', (o) => {
      this.isDown = true;

      pointer = this.fabricCanvas.getPointer(o.e);
      this.origX = pointer.x;
      this.origY = pointer.y;

      pointer = this.fabricCanvas.getPointer(o.e);
      this.rect = new fabric.Rect({
        left: this.origX,
        top: this.origY,
        originX: 'left',
        originY: 'top',
        width: pointer.x - this.origX,
        height: pointer.y - this.origY,
        angle: 0,
        fill: 'rgba(252, 158, 0, 0.5)',
        transparentCorners: false,
      });

      this.fabricCanvas.add(this.rect);
    });

    this.fabricCanvas.on('mouse:move', (o) => {
      if (!this.isDown) {
        return;
      }

      pointer = this.fabricCanvas.getPointer(o.e);

      if (this.origX > pointer.x) {
        this.rect.set({ left: Math.abs(pointer.x) });
      }
      if (this.origY > pointer.y) {
        this.rect.set({ top: Math.abs(pointer.y) });
      }

      this.rect.set({ width: Math.abs(this.origX - pointer.x) });
      this.rect.set({ height: Math.abs(this.origY - pointer.y) });

      this.fabricCanvas.renderAll();
    });

    this.fabricCanvas.on('mouse:up', () => {
      this.isDown = false;
    });
  }

  constructor(private host: ElementRef) {}
}
