import { createAction, props } from '@ngrx/store';
import { MappingResource } from '../../models/interfaces';

export const downloadPortabilityReportAction = createAction(
  '[My Doctor Manager Actions] Download MedMij Maintenance Report',
  props<{ startDate: string; endDate: string }>()
);

export const setDeleteDataModalOpenStatusAction = createAction(
  '[My Doctor Manager Actions] Set Delete Data Modal Open Status',
  props<{ isOpen: boolean; isDataservice?: boolean; resource?: MappingResource }>()
);

export const deleteDataAction = createAction(
  '[My Doctor Manager Actions] Delete Data',
  props<{ careProviderId?: string; resources?: MappingResource[] }>()
);

export const deleteDataSuccessAction = createAction(
  '[My Doctor Manager Actions] Delete Data Success',
  props<{ careProviderId?: string; resourceKeys?: MappingResource[] }>()
);
