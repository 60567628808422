import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'medsafe-back-bar',
  templateUrl: './back-bar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class BackBarComponent {
  @Input() backTextKey = 'common_back';
  @Input() titleKey = '';
  @Input() backPath: string[] = ['..'];
  
  @Output() back = new EventEmitter();
  
  constructor(private router: Router) {}
  
  goBack(): void {
    this.router.navigate(this.backPath)
      .then((navigated) => {
        if (navigated) {
          this.back.emit();
        }
      });
  }
}
