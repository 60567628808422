import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AlifeFileBase64 } from '@medrecord/core';
import { Colors } from '@medrecord/services-colors';
import { MedrecordInputNgControlComponentBase } from '../../base/medrecord-input-ng-control-component-base.component';

@Component({
  selector: 'medrecord-avatar-upload',
  templateUrl: './medrecord-avatar-upload.component.html',
  styleUrls: ['./medrecord-avatar-upload.component.scss']
})
export class MedrecordAvatarUploadComponent extends MedrecordInputNgControlComponentBase {
  @Input() labelType: Colors = 'boulder';
  @Input() maxFileSizeKb = 200;
  @Input() allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  
  @Input() uploadAvatarButtonText: string;
  @Input() removeAvatarButtonText: string;
  @Input() avatarSizeLimitText: string;
  
  
  @Output() maxFileSizeExceeded = new EventEmitter<any>();
  @Output() fileTypeNotAllowed = new EventEmitter<any>();
  
  @ViewChild('fileInput') fileInput;
  
  selectFile(event): void {
    event.preventDefault();
    
    this.fileInput.nativeElement.click();
  }
  
  removeFile(event): void {
    event.preventDefault();
    
    this.value = null;
    this.fileInput.nativeElement.value = null;
  }
  
  onFileChange(files: AlifeFileBase64[]): void {
    if (files.length === 0) {
      return;
    }
    
    const file = files[0];
    
    if (file.size > this.maxFileSizeKb * 1000) {
      return this.maxFileSizeExceeded.emit();
    }
    
    if ( !this.allowedTypes.includes(file.type) ) {
      return this.fileTypeNotAllowed.emit();
    }
    
    this.value = file.base64;
    this.onTouched();
  }
}