import { on } from '@ngrx/store';
import {
  loadQuestionnaireTemplates,
  loadQuestionnaireTemplatesFailure,
  loadQuestionnaireTemplatesSuccess,
} from '../questionnaires.actions';
import { State } from '../questionnaires.reducer';

export const templatesReducers = [
  on(loadQuestionnaireTemplates, (state: State) => ({
    ...state,
    questionnaireTemplatesLoadingError: null,
  })),

  on(loadQuestionnaireTemplatesSuccess, (state: State, { questionnaireTemplates, questionnaireTemplatesCount }) => ({
    ...state,
    questionnaireTemplates,
    questionnaireTemplatesCount,
    questionnaireTemplatesLoadingError: null,
  })),

  on(loadQuestionnaireTemplatesFailure, (state: State, { error }) => ({
    ...state,
    questionnaireTemplatesLoadingError: error,
  })),
];
