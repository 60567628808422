import { createSelector } from '@ngrx/store';
import { WeatherManagerState, weatherManagerKey } from './weather-manager.reducer';

export const getWeatherManagerState: (state: WeatherManagerState) => any = (state: WeatherManagerState) =>
  state[weatherManagerKey];

export const getCurrentData = createSelector(
  getWeatherManagerState,
  (state: WeatherManagerState): any => state.data?.current || {}
);

export const getForecastData = createSelector(
  getWeatherManagerState,
  (state: WeatherManagerState): any => state.data?.forecast?.forecastday || []
);

export const getLocation = createSelector(
  getWeatherManagerState,
  (state: WeatherManagerState): any => state.data?.location || {}
);

export const getUserLocation = createSelector(
  getWeatherManagerState,
  (state: WeatherManagerState): any => state.userLocation || ''
);
