import * as medicationConstants from './medication.constants';
import { Provider } from '@angular/core';
import { MEDICATIONS_MANAGER_CONSTANTS } from '../medication-manager.tokens';

export const constants = {
  ...medicationConstants
};

export type MedicationsManagerConstants = typeof constants;

export const provideMedicationsManagerConstants = (): Provider => [
  {
    provide: MEDICATIONS_MANAGER_CONSTANTS,
    useValue: constants
  }
];