import { Action, createReducer, on } from '@ngrx/store';
import {
  getWeatherDataFailureAction,
  getWeatherDataSuccessAction,
  setUserLocationAction,
} from './weather-manager.actions';
import { IWeatherData } from '../models/weather-data.model';

export const weatherManagerKey = 'weather-manager';

export interface WeatherManagerState {
  data: IWeatherData;
  userLocation: string;
  error: any;
}

export const initialState: WeatherManagerState = {
  data: {},
  userLocation: '',
  error: null,
};

export const weatherManagerReducer = createReducer(
  initialState,
  on(getWeatherDataSuccessAction, (state, { data }) => ({ ...state, data: data })),
  on(getWeatherDataFailureAction, (state, { error }) => ({ ...state, data: {}, error })),
  on(setUserLocationAction, (state, { location }) => ({ ...state, userLocation: location }))
);

export function creatWeatherManagerReducer(state: WeatherManagerState, action: Action): WeatherManagerState {
  return weatherManagerReducer(state, action);
}
