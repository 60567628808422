import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectFocusedObservation } from '../state-management/measurements-manager.selectors';
import { filter, map } from 'rxjs/operators';
import { setFocusedObservationTypeAction } from '../state-management/measurements-manager.actions';
import { MedsafeRouteParams } from '@medrecord/routes-medsafe';

@Injectable()
export class GetSelectedObservationTypeGuard implements CanActivate {
  constructor(private store: Store) {}
  
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    this.store.dispatch(
      setFocusedObservationTypeAction({
        observationType: route
          .params[MedsafeRouteParams.measurementId]?.replace('-', '_')
          .toUpperCase()
      })
    );
    
    return this.store.select(selectFocusedObservation).pipe(
      filter((type) => !!type),
      map(() => true)
    );
  }
}