import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService } from '@medrecord/services-form-validation';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AuthStorageItem, forgotPasswordAction, removePassedDelayAction } from '@medrecord/managers-auth';
import { AUTH_ROUTE_NAMES, AuthRouteNames } from '@medrecord/routes-auth';
import { BehaviorSubject } from 'rxjs';
import { MapFieldType } from '@medrecord/ui';
import { StorageService } from '@medrecord/tools-storage';
import { StorageKeys } from '@medrecord/core';
import { MedrecordMoment } from '@medrecord/services-datetime';
import { navigateAction } from '@medrecord/tools-navigation';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'medrecord-auth-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
})
export class ForgotPasswordFormComponent implements OnDestroy {
  form: FormGroup;

  readonly mapFieldType = MapFieldType;
  readonly isOAuth = this.storage.getItem<boolean>(StorageKeys.isOAuth, false);

  get time(): string {
    return this._calculatedTime;
  }

  hasDelay = false;

  private unsubscribe$: BehaviorSubject<number> = new BehaviorSubject(1);

  private _calculatedTime = '';
  private interval: any;

  constructor(
    private store: Store,
    private storage: StorageService<StorageKeys>,
    private translateService: TranslateService,

    fb: FormBuilder,
    validationService: ValidationService,

    @Inject(AUTH_ROUTE_NAMES) private authRouteNames: AuthRouteNames
  ) {
    const emailControl = fb.control(null, [
      validationService.required(translateService.instant('auth_validation_error_required')),
      validationService.email(translateService.instant('auth_validation_error_email')),
    ]);

    this.form = fb.group({
      email: emailControl,
    });

    emailControl.valueChanges.pipe(debounceTime(300)).subscribe((email: string) => {
      this.calculateLeftTime(email);
    });
  }

  handleSubmit(): void {
    if (this.form.valid) {
      this.hasDelay = true;

      this.store.dispatch(
        forgotPasswordAction({
          email: this.form.value.email.toLowerCase(),
          successActions: [
            navigateAction({
              path: [
                this.authRouteNames.Entry,
                this.authRouteNames.Password.Forgot,
                this.authRouteNames.Password.Success,
              ],
              extras: { queryParamsHandling: 'merge' },
            }),
          ],
        })
      );
    }
  }

  private calculateLeftTime(email: string): void {
    const forgotPasswordDelay = this.storage.getItem<AuthStorageItem>(StorageKeys.Auth)?.forgotPasswordDelay;

    if (!forgotPasswordDelay || !forgotPasswordDelay[email.toLowerCase()]) {
      this.resetCalculations(false);
      return;
    }

    const waitingTimeMs = MedrecordMoment(forgotPasswordDelay[email.toLowerCase()]).unix();
    const nowMs = MedrecordMoment(new Date()).unix();
    const interval = 1000;

    if (waitingTimeMs > nowMs) {
      const leftMs = waitingTimeMs - nowMs;

      let ms = 0;

      this.setCalculatedText(forgotPasswordDelay[this.form.value.email.toLowerCase()]);
      this.hasDelay = true;

      this.interval = setInterval(() => {
        this.setCalculatedText(forgotPasswordDelay[this.form.value.email.toLowerCase()]);

        ms += interval;

        if (ms >= leftMs * interval) {
          this.resetCalculations();
        }
      }, interval);
    } else {
      this.resetCalculations();
    }
  }

  private setCalculatedText(delay: string): void {
    const endDate = MedrecordMoment(delay);
    const startDate = MedrecordMoment(new Date());
    const time = MedrecordMoment(endDate.diff(startDate)).format('mm:ss');

    this._calculatedTime = this.translateService.instant('try_in', { time });
  }

  private resetCalculations(removePassed = true): void {
    this.hasDelay = false;
    this._calculatedTime = '';

    clearInterval(this.interval);

    if (removePassed) {
      this.store.dispatch(removePassedDelayAction({ email: this.form.value.email }));
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(0);
    this.unsubscribe$.complete();
  }
}
