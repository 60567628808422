import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { EmitType } from '@syncfusion/ej2-base';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'medsafe-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class MedrecordDialogComponent implements OnInit {
  @ViewChild('ejDialog') ejDialog: DialogComponent;
  // Create element reference for dialog target element.
  @ViewChild('container', { read: ElementRef, static: true }) container: ElementRef;
  // The Dialog shows within the target element.
  public targetElement: HTMLElement;

  @Input() position;
  @Input() width: string = '250px';
  @Input() visible: boolean;
  @Input() header: string;
  @Input() useTarget: boolean = true;
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  // To get all element of the dialog component after component get initialized.
  ngOnInit() {
    if (this.useTarget) this.initilaizeTarget();
  }

  // Hide the Dialog when click the footer button.
  public hideDialog: EmitType<object> = () => {
    this.onClose.emit(false);
  };

  // Initialize the Dialog component's target element.
  public initilaizeTarget: EmitType<object> = () => {
    this.targetElement = this.container.nativeElement.parentElement;
  };
}
