<div class="pb-3 pt-3">
  <div
    *ngFor="let item of data; let itemIndex = index"
    class="selected-box-item mb-1"
    (mouseenter)="onHover(itemIndex)" (mouseleave)="onBlur()"
  >
    <ng-container *ngTemplateOutlet="itemTemplate; context:{item: item}"></ng-container>

    <div
      class="remove-button"
      *ngIf="hoveredItemIndex === itemIndex">
      <medrecord-svg-cross
        type="white"
        width="10"
        (click)="deleteItem(itemIndex)"
      ></medrecord-svg-cross>
    </div>
  </div>
</div>

