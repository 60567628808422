import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { NavigationEffects } from './navigation.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([NavigationEffects])
  ],
})
export class MedrecordNavigationModule {}
