import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemModel, MenuEventArgs } from '@syncfusion/ej2-splitbuttons';
import { TranslateService } from '@ngx-translate/core';
import { AuthStorageItem, Role } from '@medrecord/managers-auth';
import { StorageKeys } from '@medrecord/core';
import { StorageService } from '@medrecord/tools-storage';

@Component({
  selector: 'medrecord-ui-avatar-dropdown',
  templateUrl: './avatar-dropdown.component.html',
  styleUrls: ['./avatar-dropdown.component.scss'],
})
export class AvatarDropdownComponent {
  @Input() userData: any;
  @Output() selectDropdownItem = new EventEmitter<string>();
  
  authInfo: AuthStorageItem = this.storage.getItem<AuthStorageItem>(StorageKeys.Auth);
  
  constructor(
    private translateService: TranslateService,
    private storage: StorageService<StorageKeys>
  ) {}
  
  public items: ItemModel[] = [
    {
      text: this.translateService.instant('profile_title'),
      iconCss: 'fas fa-fw fa-user text-primary',
      id: 'goToProfile'
    },
    {
      text: this.translateService.instant('settings_title'),
      iconCss: 'fas fa-fw fa-cog text-primary',
      id: 'goToSettings'
    },
    
    ...(
      this.authInfo?.roles.includes(Role.Patient)
      ? [{
          text: this.translateService.instant(
            'go_to',
            {
              role: this.translateService.instant('role_patient')
            }
          ),
          iconCss: 'fas fa-fw fa-random text-primary',
          id: 'goToPatient'
        },]
      : []
    ),
    
    ...(
      this.authInfo?.roles.includes(Role.Doctor)
      ? [{
          text: this.translateService.instant(
            'go_to',
            {
              role: this.translateService.instant('role_doctor')
            }
          ),
          iconCss: 'fas fa-fw fa-random text-primary',
          id: 'goToDoctor'
        }]
      : []
    ),
    
    /** TODO Add Feedback functionality https://gitlab.medvision360.org/medvision/frontend-developers/doctor-gui/-/issues/177 */
    // {
    //   text: this.translateService.instant('feedback_feedback'),
    //   iconCss: 'fas fa-fw fa-edit text-primary',
    //   id: 'provideFeedback'
    // },
    {
      text: this.translateService.instant('profile_logout'),
      iconCss: 'fas fa-fw fa-sign-out-alt text-primary',
      id: 'logout'
    }
  ];
  
  select(args: MenuEventArgs): void {
    this.selectDropdownItem.emit(args.element.id);
  }
}