import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { FormFactory, MapFieldType } from '@medrecord/ui';
import { mapEnum } from '@medrecord/tools-utils';
import { ContactType, ContactUse, ContactUserData } from '@medrecord/managers-users';
import { ProfileFormType } from '../typings/enums';
import { ValidationService } from '@medrecord/services-form-validation';

@Injectable()
export class ProfileContactsFormFactory extends FormFactory<ContactUserData> {
  fields = [
    {
      name: 'system',
      calculate: () => ({
        type: MapFieldType.Bricks,
        label: this.translateService.instant('common_form_field_type'),
        hint: this.translateService.instant('common_form_field_type'),
        fieldClass: 'w-100',
        options: {
          items: mapEnum<{ id: string, text: string }>(
            ContactType,
            (id) => ({
              id,
              text: this.translateService.instant('profile_' + id.toLowerCase())
            })
          )
        }
      })
    },
    {
      name: 'use',
      calculate: () => ({
        type: MapFieldType.Bricks,
        label: this.translateService.instant('common_form_field_use'),
        hint: this.translateService.instant('common_form_field_use'),
        fieldClass: 'w-100',
        options: {
          items: mapEnum<{ id: string, text: string }>(
            ContactUse,
            (id) => ({
              id,
              text: this.translateService.instant('profile_' + id.toLowerCase())
            })
          )
        }
      })
    },
    {
      name: 'value',
      calculate: () => ({
        type: MapFieldType.Input,
        bindVisibility: (form) => () => form.controls['system'].value === ContactType.url,
        label: this.translateService.instant('common_form_field_value'),
        placeholder: this.translateService.instant('profile_specify_website_address'),
        hint: this.translateService.instant('profile_specify_website_address'),
      })
    },
    {
      name: 'value',
      calculate: () => ({
        type: MapFieldType.Input,
        bindVisibility: (form) => () => form.controls['system'].value === ContactType.email,
        label: this.translateService.instant('common_form_field_email'),
        placeholder: this.translateService.instant('profile_specify_email'),
        hint: this.translateService.instant('profile_specify_email'),
      })
    },
    {
      name: 'value',
      calculate: () => ({
        type: MapFieldType.Phone,
        bindVisibility: (form) => () => form.controls['system'].value === ContactType.phone,
        label: this.translateService.instant('profile_phone'),
        placeholder: this.translateService.instant('profile_phone'),
        hint: this.translateService.instant('profile_phone')
      })
    }
  ];
  
  forms = {
    [ProfileFormType.User]: (model) => this.defaultForm(model),
    [ProfileFormType.Organization]: (model) => this.defaultForm(model),
  };
  
  defaultForm = (model) => this.fb.group({
    system: [model?.system || ContactType.phone, [
      this.formValidatorsService.required(
        this.translateService.instant("common_validation_required")
      )
    ]],
    use: [model?.use, [this.formValidatorsService.required(
      this.translateService.instant("common_validation_required")
    )]],
    value: [model?.value, [this.formValidatorsService.required(
      this.translateService.instant("common_validation_required")
    )]],
    rank: [model?.rank]
  })
  
  constructor(
    private translateService: TranslateService,
    private fb: FormBuilder,
    private formValidatorsService: ValidationService
  ) {
    super();
  }
}