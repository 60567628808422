import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ImmunizationsManagerEffects } from './immunizations-manager.effects';
import { immunizationsManagerFeatureKey, reducer } from './immunizations-manager.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(immunizationsManagerFeatureKey, reducer),
    EffectsModule.forFeature([ ImmunizationsManagerEffects ])
  ]
})
export class ImmunizationsManagerStateModule {}