import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImmunizationsManagerService } from './services/immunizations-manager.service';
import { ImmunizationsManagerStateModule } from './state-management/immunizations-manager-state.module';

@NgModule({
  imports: [CommonModule, ImmunizationsManagerStateModule],
  providers: [ImmunizationsManagerService]
})
export class ImmunizationsManagerModule {}
