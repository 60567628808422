<div
  *ngFor="let block of templateTableResourceKey.blocks"
  class="resource-key-table-container p-2 mb-1"
>
  <doctor-gui-expandable-container [title]="block.title | translate">
    <div class="row text-md">
      <div class="col-lg-6">
        <div *ngFor="let field of block.leftColumn" class="d-flex mt-1 item">
          <div class="item-label">{{ field.label | translate }}:</div>
          <div>{{ field.value }}</div>
        </div>
      </div>
      <div class="col-lg-6">
        <div *ngFor="let field of block.rightColumn" class="d-flex mt-1 item">
          <div class="item-label">{{ field.label | translate }}:</div>
          <div>{{ field.value }}</div>
        </div>
      </div>
    </div>
  </doctor-gui-expandable-container>
</div>
