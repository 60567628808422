import { NgModule } from '@angular/core';
import { UseTranslationPipe } from './pipe/use-translation.pipe';
import { TranslationsManagementStateModule } from './state-management/translations-management-state.module';

@NgModule({
  imports: [
    TranslationsManagementStateModule
  ],
  declarations: [
    UseTranslationPipe
  ],
})
export class TranslationsManagementModule {}
