import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { loadUserMetadataAction, loadUserMetadataSuccessAction } from '../actions/user-metadata.actions';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectCurrentID } from '@medrecord/managers-users';
import { UserMetadataService } from '../../services/user-metadata.service';

@Injectable()
export class UserMetadataEffects {
  @Effect()
  loadUserMetadata$ = this.actions$.pipe(
    ofType(loadUserMetadataAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([payload, userId]) => {
      return this.userMetadataService.getUserMedata(userId).pipe(
        switchMap(({ data }) => [
          loadUserMetadataSuccessAction({ userMetadata: data }),
          ...(payload.successActions || [])
        ])
      );
    })
  );
  
  constructor(
    private actions$: Actions,
    private store: Store,
    private userMetadataService: UserMetadataService
  ) {}
}