import { Action, createReducer, on } from '@ngrx/store';
import {
  failureAction,
  loadCarePlanAppointmentsAction,
  loadCarePlanAppointmentsSuccessAction,
  loadQualificationsSuccessAction
} from './calendar-manager.actions';
import { CalendarAppointment } from '../models/interfaces/calendar-appointment.interface';
import { QualificationListItem } from '../models/interfaces/qualification-list-item.interface';

export const calendarManagerFeatureKey = 'calendar-manager';

export interface CalendarManagerState {
  error: any;
  appointments: CalendarAppointment[];
  carePlanId: string;
  startDate: Date;
  endDate: Date;
  qualifications: QualificationListItem[];
}

export const initialState: CalendarManagerState = {
  error: null,
  appointments: [],
  carePlanId: null,
  startDate: null,
  endDate: null,
  qualifications: []
};

export const calendarManagerReducer = createReducer(
  initialState,
  on(loadCarePlanAppointmentsAction, (state, { carePlanId, startDate, endDate }) => ({
    ...state,
    carePlanId: carePlanId || state.carePlanId,
    startDate: startDate || state.startDate,
    endDate: endDate || state.endDate
  })),
  on(loadCarePlanAppointmentsSuccessAction, (state, { appointments }) => ({
    ...state,
    appointments: appointments.filter(item => new Date(item.startTime) >= state.startDate && new Date(item.startTime) <= state.endDate)
  })),
  on(loadQualificationsSuccessAction, (state, { qualifications }) => ({
    ...state,
    qualifications: qualifications.map(item => ({ ...item, text: `${item.name} ${item.code || ''}` }))
  })),
  on(failureAction, (state, { error }) => ({
    ...state,
    error,
  }))
);

export const reducer = (state: CalendarManagerState, action: Action): CalendarManagerState => {
  return calendarManagerReducer(state, action);
};
