import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Input,
  OnChanges,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'medrecord-ui-dynamic-icon',
  templateUrl: './dynamic-icon.component.html',
})
export class DynamicIconComponent implements AfterViewInit, OnChanges {
  @Input() iconComponent: Type<any> = null;
  @Input() width = 20;
  @ViewChild('icon', { read: ViewContainerRef }) iconRef: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnChanges(): void {
    if (this.iconRef) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.iconComponent);
      this.iconRef.clear();

      const newComponent = this.iconRef.createComponent(componentFactory);
      newComponent.instance.width = this.width;
      newComponent.changeDetectorRef.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.iconComponent);
    this.iconRef.clear();

    const newComponent = this.iconRef.createComponent(componentFactory);
    newComponent.instance.width = 24;
    newComponent.changeDetectorRef.detectChanges();
  }
}
