import { createSelector } from '@ngrx/store';
import { Post, Tag } from '../models/interfaces';
import { newsManagerFeatureKey, NewsManagerState } from './news-manager.reducer';

export const getNewsManagerState = (state: NewsManagerState) => state[newsManagerFeatureKey];

export const getNews = createSelector(
  getNewsManagerState,
  (state: NewsManagerState): Post[] => state.posts.map(post => ({
    ...post,
    tags: (post?.tags || []).filter(tag => !tag.name.includes('#'))
  }))
);

export const getCareplanPosts = createSelector(
  getNewsManagerState,
  (state: NewsManagerState): Post[] => state.careplanPosts.map(post => ({
    ...post,
    tags: (post?.tags || []).filter(tag => !tag.name.includes('#'))
  }))
);

export const getCareplanResourcesPosts = createSelector(
  getNewsManagerState,
  (state: NewsManagerState): Post[] => state.careplanResourcesPosts.map(post => ({
    ...post,
    tags: (post?.tags || []).filter(tag => !tag.name.includes('#'))
  }))
);

export const getFeaturedNews = createSelector(
  getNewsManagerState,
  (state: NewsManagerState): Post[] => state.featuredPosts
);

export const getNewsCategories = createSelector(
  getNewsManagerState,
  (state: NewsManagerState): Tag[] => state.categories
);

export const getCareplanCategories = createSelector(
  getNewsManagerState,
  (state: NewsManagerState): Tag[] => state.careplanCategories
);

export const getCurrentPost = createSelector(
  getNewsManagerState,
  (state: NewsManagerState): Post => state.activePost
);

export const getCareplanCurrentPost = createSelector(
  getNewsManagerState,
  (state: NewsManagerState): Post => state.careplanActivePost
);
