import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Colors } from '@medrecord/services-colors';

@Component({
  selector: 'medrecord-ui-field-label',
  templateUrl: './field-label.component.html',
  styleUrls: ['./field-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FieldLabelComponent {
  @Input() text: string;
  @Input() secondaryText: string;
  @Input() labelContentTemplate: TemplateRef<any>;
  @Input() for: string;
  @Input() type: Colors = 'boulder';
  @Input() hint: string;
  @Input() asterix: boolean = false;
}
