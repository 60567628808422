<div class="row">
  <div class="col-12 col-lg-auto">
    <medrecord-ui-avatar
      [large]="true"
      [link]="photoBaseControl.value | base64String: contentTypeControl.value"
    ></medrecord-ui-avatar>
  </div>

  <div class="col-auto align-self-center">
    <medrecord-ejs-medrecord-uploader
      (uploadPrepared)="onUploadPreparation($event)"
      [noDataMessage]="'common.form.avatar_size_limit' | translate"
      [multiple]="false"
      [maxSizeInBytes]="maxSizeInBytes"
      [allowedFileTypes]="'.png,.jpeg,.jpg,.svg'"
    >
      <ng-template #buttonContent>
        <span class="ml-2 font-weight-bold d-flex align-items-center justify-content-center text-white">
          <i class="fas text-lg fa-plus-circle mr-1"></i>
          <span style="line-height: 1rem;">{{ 'common.form.upload_avatar' | translate }}</span>
        </span>
      </ng-template>
    </medrecord-ejs-medrecord-uploader>

    <button
      *ngIf="!uploadingNewAvatar && (photoBaseControl.value | base64String: contentTypeControl.value)"
      (click)="removeAvatar()"
      class="btn btn-outline-black mt-3"
      type="button">
        <i class="fas fa-times text-danger"></i>
        <span class="ml-2 font-weight-bold">{{ 'common.form.remove_avatar' | translate }}</span>
    </button>
  </div>
</div>