import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectCurrentID } from '@medrecord/managers-users';
import { Store } from '@ngrx/store';
import { addErrorToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';
import {
  loadTestCareproviderStatusAction,
  loadTestCareproviderStatusSuccessAction,
  setTestCareproviderStatusModalOpenStatusAction
} from '../actions';
import { MyDoctorManagerService } from '../../services/my-doctor-manager.service';

@Injectable()
export class TestCareproviderEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(loadTestCareproviderStatusAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ statusType }, userId]) =>
      this.service.loadList(userId, statusType).pipe(
        switchMap((data) => [
          loadTestCareproviderStatusSuccessAction({ data: data.status }),
          setTestCareproviderStatusModalOpenStatusAction({ isOpen: true })
        ]),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_list_error'), error))
        ])
      )
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private service: MyDoctorManagerService,
    private translateService: TranslateService
  ) {
  }
}
