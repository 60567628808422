import { Component, Input } from '@angular/core';
import { Position } from '@syncfusion/ej2-angular-popups';
@Component({
  selector: 'medrecord-hint',
  templateUrl: './medrecord-hint.component.html',
})
export class MedrecordHintComponent {
  @Input() hint: string;
  @Input() placement: Position = 'BottomCenter';
}
