import { immunizationsManagerFeatureKey, ImmunizationsManagerState } from './immunizations-manager.reducer';
import { createSelector } from '@ngrx/store';
import { Immunization } from '../models/interfaces/immunization.interface';

export const getImmunizationsManagerState: (state: ImmunizationsManagerState) => any = (
  state: ImmunizationsManagerState
) => state[immunizationsManagerFeatureKey];

export const getImmunizations = createSelector(
  getImmunizationsManagerState,
  (state: ImmunizationsManagerState): Immunization[] => state.immunizations
);

export const getCareplanImmunizations = createSelector(
  getImmunizationsManagerState,
  (state: ImmunizationsManagerState): Immunization[] => state.careplan.immunizations
);

export const getImmunizationsCount = createSelector(
  getImmunizationsManagerState,
  (state: ImmunizationsManagerState): number => state.count
);

export const getActiveImmunization = createSelector(
  getImmunizationsManagerState,
  (state: ImmunizationsManagerState): Immunization => state.activeImmunization
);
