<div class="form-group">
  <medrecord-label [for]="id"
                   [hint]="hint"
                   [secondaryText]="secondaryLabel"
                   [text]="label"
                   [type]="labelType"
  ></medrecord-label>

  <div class="d-flex bricks-list mb-1">
    <div (click)="handleItemSelect(item)"
         *ngFor="let item of selectItems"
         [class.selected]="isItemSelected(item)"
         class="brick d-flex align-items-center justify-content-center">
      <span>{{ item.label }}</span>
    </div>
  </div>

  <ng-container *ngIf="hasErrors">
    <span *ngFor="let error of errors" class="text text-warning">
      {{ error }}
    </span>
  </ng-container>
</div>