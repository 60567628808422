import { Component, Input } from '@angular/core';
import { ResourceDataAny } from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-resources',
  templateUrl: './resources.component.html'
})
export class ResourcesComponent {
  @Input() resources: ResourceDataAny[];
}
