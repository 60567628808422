<div class="form-group {{cssClass}}">
  <label class="checkbox d-flex align-items-start">
    <input #input="ngModel"
           (blur)="checkIfTouched()"
           [(ngModel)]="value"
           [name]="control?.name"
           type="checkbox"
    >
    <span class="checkmark d-flex align-items-center justify-content-center"
         [class.checkmark-warning]="controlErrorsList.length"
         [class.checkmark-error]="errorCode || externalErrorsList.length"
    >
      <i class="fas fa-check text-black"></i>
    </span>
    <span #labelContent><ng-content></ng-content></span>
    <span *ngIf="labelContent.childNodes.length === 0">{{ label }}</span>
  </label>

  <medrecord-ui-field-error [errors]="controlErrorsList"
                            [errorCode]="errorCode"
                            [externalErrors]="externalErrorsList"
                            *ngIf="controlErrorsList.length || errorCode || externalErrorsList.length"
  ></medrecord-ui-field-error>
</div>
