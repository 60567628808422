<label class="text-md font-weight-bold text-boulder" for="datepicker">
  {{ label }}
</label>

<ejs-datepicker
  id="datepicker"
  placeholder="DD/MM/YYYY"
  class="pl-2"
  [min]="minDate"
  [max]="maxDate"
  [ngModel]="value?.date | precisionFormat: precision"
  [allowEdit]="true"
  [showTodayButton]="false"
  [start]="startCalendarView"
  [format]="'DD/MM/YYYY'"
  [depth]="calendarViewDepth"
  [firstDayOfWeek]="firstDayOfWeek"
  [cssClass]="'e-custom-datepicker'"
  [ngClass]="{ 'e-error': hasErrors }"
  (cleared)="value = null"
  (change)="handleChange($event)"
></ejs-datepicker>

<ng-container *ngIf="hasErrors">
  <span *ngFor="let error of errors" class="text text-warning">
    {{ error }}
  </span>
</ng-container>
