<div class="container p-4">
  <div class="row">
    <div class="col-auto">
      <medrecord-ui-avatar [middle]="true"
                          [link]="profileImage"
      ></medrecord-ui-avatar>
    </div>
    <div class="col" *ngIf="userData">
      <p class="text-primary font-weight-bold">
        {{ userData.name?.text }}
      </p>
      <p class="text-secondary mb-0">
        {{ userData.birthDate | yearsOld }}, {{ userData.birthDate | birthate }}
      </p>
    </div>
  </div>
</div>