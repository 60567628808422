import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Medication, MedicationsLoadPayload } from '@managers/medications-manager';
import { EnvService } from '@medrecord/core';

@Injectable()
export class MedicationsManagerService {
  constructor(
    private http: HttpClient,
    private envService: EnvService
    ) {}

  loadMedications(userId: string, payload: MedicationsLoadPayload): Observable<Medication[]> {
    let params = new HttpParams();

    Object.keys(payload).forEach((key) => {
      params = params.set(key, payload[key]);
    });

    return this.http.get<Medication[]>(this.envService.backend + '/patient/' + userId + '/medication', { params });
  }

  loadMedicationsCount(userId: string): Observable<number> {
    return this.http.get<number>(this.envService.backend + '/patient/' + userId + '/medication/count');
  }

  loadMedication(id: string, userId: string): Observable<Medication> {
    return this.http.get<Medication>(this.envService.backend + '/patient/' + userId + '/medication/' + id);
  }

  createMedication(userId: string, medication: Medication): Observable<any> {
    return this.http.post(this.envService.backend + '/patient/' + userId + '/medication', medication);
  }

  deleteMedication(userId: string, medicationId: string): Observable<any> {
    return this.http.delete(this.envService.backend + '/patient/' + userId + '/medication/' + medicationId);
  }

  updateMedication(userId: string, medicationId: string, medicationForm: Medication): Observable<any> {
    return this.http.put(
      this.envService.backend + '/patient/' + userId + '/medication/' + medicationId,
      medicationForm
    );
  }
}
