import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-healthplans',
  templateUrl: './healthplans.component.svg',
})
export class HealthplansComponent extends MedrecordIcon {
  viewHeight = 24;
  viewWidth = 24;
}
