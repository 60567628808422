import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, first, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { selectUserId } from '@medrecord/managers-auth';
import { MyDoctorManagerConstants, MyDoctorManagerService, MY_DOCTOR_MANAGER_CONSTANTS } from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-medmij-auth-back',
  styleUrls: ['./medmij-auth-back.component.scss'],
  templateUrl: './medmij-auth-back.component.html',
})
export class MedmijAuthBackComponent implements OnInit {

  isOpenedProperly: boolean;
  viewState = {
    title: null,
    description: null,
    descriptionClass: 'null',
    buttonReplacementText: null,
  };

  private error = null;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private myDoctorManagerService: MyDoctorManagerService,

    @Inject(MY_DOCTOR_MANAGER_CONSTANTS) private myDoctorConstants: MyDoctorManagerConstants
  ) {}

  ngOnInit(): void {
    this.isOpenedProperly = localStorage.getItem(this.myDoctorConstants.digIdResponse) === this.myDoctorConstants.digIdProcessing;

    const { error, code, state, errorDescription } = this.activatedRoute.snapshot.queryParams;
    const careproviderId = localStorage.getItem(this.myDoctorConstants.careproviderId);
    const dataserviceId = localStorage.getItem(this.myDoctorConstants.dataserviceId);
    const authEndpointUrl = localStorage.getItem(this.myDoctorConstants.authEndpointUrl);
    const correlationId = localStorage.getItem(this.myDoctorConstants.correlationId);
    const medMijRequestId = localStorage.getItem(this.myDoctorConstants.medMijRequestId);

    if (error) {
      this.myDoctorManagerService.performedAuthorizationOnce = false;
      this.error = error === 'access_denied'
        ? this.translateService.instant('healthcare_providers_access_denied_error')
        : this.translateService.instant('healthcare_providers_auth_denied_error');

      this.viewState = {
        title: 'healthcare_providers_auth_back_error',
        description: this.error,
        descriptionClass: 'error-text',
        buttonReplacementText: 'healthcare_providers_auth_back_close_window_error_text'
      };

      this.store
        .select(selectUserId)
        .pipe(first())
        .subscribe((userId) => {
          this.myDoctorManagerService
            .logAuthError(userId, careproviderId, dataserviceId, authEndpointUrl, correlationId, medMijRequestId, error, errorDescription)
            .subscribe(() => {
              return;
            });
        });
      return;
    }

    this.viewState = {
      title: 'healthcare_providers_auth_back_success',
      description: this.translateService.instant('healthcare_providers_auth_back_success_text'),
      descriptionClass: 'success-text',
      buttonReplacementText: 'healthcare_providers_auth_back_close_window_success_text'
    };

    this.store
      .select(selectUserId)
      .pipe(first())
      .subscribe((userId) => {
        this.myDoctorManagerService
          .checkAuthorizationToken(userId, code, state, correlationId, medMijRequestId)
          .pipe(
            catchError((err) => {
              const messageString = err.error.message;

              const substringMap = {
                revoked: this.translateService.instant('healthcare_providers_auth_denied_error'),
                invalid: this.translateService.instant('healthcare_providers_auth_denied_error'),
                access_denied: this.translateService.instant('healthcare_providers_auth_denied_error'),
                'check against ssl': this.translateService.instant(
                  'healthcare_providers_no_information_provided_error'
                ),
              };

              const result = Object.entries(substringMap).find(([substring]) => {
                return messageString.toLowerCase().includes(substring.toLowerCase());
              });

              this.myDoctorManagerService.performedAuthorizationOnce = false;
              this.error = result ? result[1] : messageString;
              this.viewState.description = this.error;
              return of(err);
            }),
            tap(() => {
              if (!this.error) {
                if (this.isOpenedProperly) {
                  localStorage.setItem(this.myDoctorConstants.digIdResponse, this.myDoctorConstants.digIdSuccess);
                  this.closeCurrentWindow();
                } else {
                  this.myDoctorManagerService.performedAuthorizationOnce = true;
                }
              }
            })
          )
          .subscribe(() => {
            return;
          });
      });
  }

  closeCurrentWindow(): void {
    if (this.error) {
      localStorage.setItem(this.myDoctorConstants.digIdResponse, this.myDoctorConstants.digIdFailure);
    }
    window.close();
  }
}
