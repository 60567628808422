import { Component } from '@angular/core';

@Component({
  selector: 'medrecord-auth-twofa-recovery',
  templateUrl: './twofa-recovery.component.html'
})
export class TwofaRecoveryComponent {
  step = 1;

  readonly supportEmail = 'support@medrecord.io';
}
