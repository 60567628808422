<div class="form-group">
  <medrecord-ui-field-label
    *ngIf="label"
    for="datepicker"
    [hint]="hint"
    [secondaryText]="secondaryLabel"
    [text]="label"
    [type]="labelType"
  ></medrecord-ui-field-label>
  <div
    [class.warning]="controlErrorsList.length"
    [class.error]="errorCode || externalErrorsList.length"
    class="input-group"
  >
    <textarea
      #input="ngModel"
      (blur)="checkIfTouched()"
      [(ngModel)]="value"
      [id]="id"
      [name]="control?.name"
      [placeholder]="placeholder"
      [disabled]="disabled"
      class="form-control"
      cols="30"
      [rows]="rows"
    ></textarea>
  </div>

  <medrecord-ui-field-error
    [errors]="controlErrorsList"
    [errorCode]="errorCode"
    [externalErrors]="externalErrorsList"
  ></medrecord-ui-field-error>
</div>
