import { Component, Input, Optional, Self, ViewChild } from '@angular/core';
import { NgControlComponentBase } from '../../../ui-abstractions/ng-control-component-base.component';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'medrecord-ui-textarea',
  templateUrl: 'textarea.component.html',
  styleUrls: ['textarea.component.scss'],
})
export class TextareaComponent extends NgControlComponentBase {
  @ViewChild('input') input;

  @Input() placeholder = '';
  @Input() rows = 5;

  constructor(@Self() @Optional() public control: NgControl) {
    super(control);
  }

  checkIfTouched(): void {
    if (this.input.touched) {
      this.onTouched();
    }
  }
}
