<div *ngIf="!isFieldEmpty(fieldGroup)" [class.pl-5]="!fieldGroup.fieldAsSubgroup">
  <div [class]="fieldGroup.fieldAsSubgroup ? 'pb-3' : 'py-3'" class="title pr-4 d-flex align-items-center">
    <div class="chevron">
      <medrecord-svg-chevron-right [type]="'black'" *ngIf="!isExpanded" icon width="7"></medrecord-svg-chevron-right>
      <medrecord-svg-chevron-down [type]="'black'" *ngIf="isExpanded" icon width="12"></medrecord-svg-chevron-down>
    </div>
    <span class="text-black text-lg text-weight-600 ml-2" (click)="isExpanded = !isExpanded">{{
      prepareFieldGroupName() | translate
    }}</span>

    <a
      class="btn btn-outline-black ml-auto align-items-center py-2"
      *ngIf="fieldGroup.onDetailsLink"
      (click)="getDetails($event)"
      >{{ 'my_doctor_get_details' | translate }}</a
    >
  </div>

    <div *ngIf="isExpanded" class="row ml-2" [class.mr-2]="fieldGroup.fieldAsSubgroup">
      <ng-container *ngFor="let field of fieldGroup.fields">

        <ng-container *ngIf="field.multiple; else singleFieldBlock">
          <div class="col-12">
            <doctor-gui-field-multiple
              [fieldGroup]="field"
              [mainResourceData]="mainResourceData"
              [allResourcesData]="allResourcesData"
            ></doctor-gui-field-multiple>
          </div>
        </ng-container>

        <ng-template #singleFieldBlock>
          <ng-container *ngIf="field.fieldAsSubgroup; else nonSubgroupBlock">
            <div class="col-12">
              <doctor-gui-field-group
                *ngIf="!isFieldEmpty(field)"
                [fieldGroup]="field"
                [mainResourceData]="mainResourceData"
                [allResourcesData]="allResourcesData"
                [resourceIndex]="resourceIndex"
              ></doctor-gui-field-group>
            </div>
          </ng-container>
        </ng-template>

        <ng-template #nonSubgroupBlock>
          <ng-container *ngIf="field.showAsTable; else notTable">
            <div class="col-12 my-0 py-0 mb-3" *ngIf="!isFieldEmpty(field)">
              <table class="w-100">
                <caption>
                  Resource field table
                </caption>
                <tr>
                  <th
                    class="text-primary table-header table-cell p-2"
                    *ngFor="let column of castPreparedDataToTable(prepareDataForField(field))?.columns"
                  >
                    {{ column | translate }}
                  </th>
                </tr>
                <tr *ngFor="let row of castPreparedDataToTable(prepareDataForField(field))?.rows">
                  <td class="p-2 table-cell" *ngFor="let data of row">{{ data }}</td>
                </tr>
              </table>
            </div>
          </ng-container>
        </ng-template>

        <ng-template #notTable>
          <div class="col-3 my-0 py-0" *ngIf="!isFieldEmpty(field)">
            <p class="text-boulder text-weight-600 mb-2">
              {{ field.label | translate }}
            </p>
          </div>

          <ng-container *ngIf="field.showAsDownload; else notDownload">
            <div class="col-9 my-0 py-0">
              <a
                type="button"
                class="btn-sm btn-outline-black text-weight-600"
                [href]="prepareDataForField(field)"
                target="_blank"
              >
                {{ 'my_doctor_download_pdf' | translate }}
              </a>
            </div>
          </ng-container>

          <ng-template #notDownload>
            <ng-container *ngIf="field.showAsPdfADownload; else regularData">
              <div class="col-9 my-0 py-0">
                <a
                  type="button"
                  class="btn-sm btn-outline-black text-weight-600"
                  (click)="onPdfaButtonClick(castPreparedDataToString(prepareDataForField(field)))"
                >
                  {{ 'Download PDF/A' | translate }}
                </a>
              </div>
            </ng-container>
          </ng-template>

          <ng-template #regularData>
            <div class="col-9 my-0 py-0" *ngIf="!isFieldEmpty(field)">
              <p
                class="mb-2"
                [class]="field.cssClass"
                [innerHTML]="
                        field.translate
                          ? (castPreparedDataToString(prepareDataForField(field)) | translate)
                          : prepareDataForField(field)
                      "
              ></p>
            </div>
          </ng-template>
        </ng-template>

      </ng-container>
    </div>
</div>
