import { Component, Input, EventEmitter, Output, Inject, ContentChild, TemplateRef, ViewChild } from '@angular/core';
import { SidebarItem } from '../../../models/interfaces/sidebar-item.interface';
import { SIDEBAR_CONSTANTS, SideBarConstants } from '../../../constants';
import {
  TreeViewComponent,
  SidebarComponent as EjsSidebarComponent,
  FieldsSettingsModel,
  NodeSelectEventArgs,
} from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'medrecord-ui-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @ViewChild('sidebar') sidebar: EjsSidebarComponent;
  @ViewChild('treeView') treeView: TreeViewComponent;

  @ContentChild('logotype') logotype: TemplateRef<any>;
  @ContentChild('userInfo') userInfo: TemplateRef<any>;

  @Input() menuItems: FieldsSettingsModel;
  @Input() selectedNodeID: string;
  @Input() expandedNodeID: string;
  @Input() disabledNodes: string[];

  @Output() nodeIdChanged = new EventEmitter<NodeSelectEventArgs>();

  constructor(@Inject(SIDEBAR_CONSTANTS) public sidebarConstants: SideBarConstants) {}

  public onCreated(): void {
    this.sidebar.element.style.visibility = '';
    this.treeView.disableNodes(this.disabledNodes);
  }

  public onClose(): void {
    this.treeView.collapseAll();
  }

  asSidebarItem(item: SidebarItem): SidebarItem {
    return item;
  }
}
