import { Action, createAction, props } from '@ngrx/store';
import { UserMetadata } from '../../models/interfaces';

export const loadUserMetadataAction = createAction(
  "[MIDDLEWARE MANAGER] Load User Metadata",
  props<{ successActions?: Action[] }>()
);

export const loadUserMetadataSuccessAction = createAction(
  "[MIDDLEWARE MANAGER] Load User Metadata Success",
  props<{ userMetadata: UserMetadata }>()
);

export const loadUserMetadataFailureAction = createAction(
  "[MIDDLEWARE MANAGER] Load User Metadata Failure",
  props<{ error: any }>()
);