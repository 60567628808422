<div class="h-100 w-100 row justify-content-center align-items-center">
  <div class="error d-flex flex-column justify-content-center align-items-center">
    <h1 class="font-weight-bold">{{ viewState.title | translate }}:</h1>
    <div class="mb-4" [ngClass]="viewState.descriptionClass">{{ viewState.description }}</div>

    <button
      *ngIf="isOpenedProperly"
      class="btn btn-primary font-weight-bold"
      (click)="closeCurrentWindow()"
      type="button"
    >
      {{ 'healthcare_providers_auth_back_close_window' | translate | titlecase }}
    </button>
    <p *ngIf="!isOpenedProperly">
      {{ viewState.buttonReplacementText | translate }}
    </p>
  </div>
</div>
