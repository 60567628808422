import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { RemoveEventArgs, SelectEventArgs } from '@syncfusion/ej2-dropdowns';

@Component({
  selector: 'medrecord-ui-multiselect-column',
  templateUrl: './multiselect-column.component.html',
  styleUrls: ['./multiselect-column.component.scss'],
})
export class MultiselectColumnComponent implements AfterContentInit {
  @Input() placeholder: string;
  @Input() dataSource: any[];
  @Input() value: any;
  @Input() disabled: boolean;
  @Input() mode: string = 'Delimiter';
  @Output() selected = new EventEmitter<string>();
  @Output() removed = new EventEmitter<string>();

  constructor(private ref: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    this.ref.detectChanges();
  }

  onSelect(item: SelectEventArgs): void {
    this.selected.emit(item.itemData['text']);
  }

  onRemove(item: RemoveEventArgs): void {
    this.removed.emit(item.itemData['text']);
  }
}
