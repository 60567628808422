import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-blood-pressure',
  templateUrl: './blood-pressure.component.svg',
  styleUrls: [],
})
export class BloodPressureComponent extends MedrecordIcon {
  viewHeight: number = 24;
  viewWidth: number = 24;
}
