import { GenericMapper } from './generic.mapper';
import { CollapsibleTable, ColumnDefinition } from '../interfaces';
import { Resource, ResourceType } from '../enums';
import { ClinicalStatus } from '@medrecord/core';
import { UsageStatus } from '@managers/medications-manager';
import { MapperUtil } from './mapper.util';

export class CurrentMedicationsMapper extends GenericMapper<MedicationColumn> {

  protected get title(): string {
    return 'gp-current-medication-2020';
  }

  protected get mainKey(): Resource {
    return Resource.gpCurrentMedication2020;
  }

  protected get mainType(): ResourceType {
    return ResourceType.medicationRequest;
  }

  protected get sortBy(): MedicationColumn[] {
    return [MedicationColumn.forComparison];
  }

  protected get groupBy(): MedicationColumn {
    return MedicationColumn.status;
  }

  protected get groupSortOrder(): { [key: string]: number } {
    return {
      [UsageStatus.Active]: 0,
      [ClinicalStatus.Inactive]: 1
    };
  }

  protected get activeGroups(): string[] {
    return [UsageStatus.Active];
  }

  protected get columns(): ColumnDefinition<MedicationColumn>[] {
    return [
      {
        property: MedicationColumn.medication,
        title: 'my_doctor_medication',
        width: 11,
        lines: [{
          type: 'string',
          bold: true
        }]
      },
      {
        property: MedicationColumn.dosage,
        title: 'my_doctor_dosage',
        width: 13,
        lines: [{
          type: 'string'
        }]
      },
      {
        property: MedicationColumn.date,
        title: 'my_doctor_date',
        width: 12,
        lines: [{
          type: 'range',
          textXs: true
        }, {
          type: 'duration',
          textXs: true
        }]
      },
      {
        property: MedicationColumn.healthcareProvider,
        title: 'my_doctor_careprovider',
        width: 13,
        lines: [{
          type: 'string'
        }]
      },
      {
        property: MedicationColumn.administrationRoute,
        title: 'my_doctor_administration_route',
        width: 6,
        lines: [{
          type: 'translate',
          translationPrefix: 'medications_route_type_'
        }]
      },
      {
        property: MedicationColumn.code,
        title: 'my_doctor_code',
        width: 6,
        lines: [{
          type: 'string'
        }]
      },
      {
        property: MedicationColumn.mbhId,
        title: 'healthcare_providers_resource_mbh_identification',
        width: 13,
        lines: [{
          type: 'string'
        }]
      },
      {
        property: MedicationColumn.prescriptionReason,
        title: 'healthcare_providers_resource_reason_prescribe',
        width: 24,
        lines: [{
          type: 'string'
        }]
      }
    ];
  }

  protected get collapsibleTables(): CollapsibleTable<MedicationColumn>[] {
    return null;
  }

  protected getAllRows(): { [key in MedicationColumn]?: any[] }[] {
    return this.mainResources.map(m => { // m = medication
      return {
        [MedicationColumn.status]: [(m.cancelIndicator || m.stopType) ? ClinicalStatus.Inactive : UsageStatus.Active],
        [MedicationColumn.medication]: [m.agreedMedicine?.display],
        [MedicationColumn.dosage]: [m.dosage?.[0].text],
        [MedicationColumn.date]: [{
          start: m.periodOfUse?.start,
          end: m.periodOfUse?.end
        }, m.duration ? {
          period: m.duration.value,
          unit: m.duration.unit
        } : null],
        [MedicationColumn.healthcareProvider]: [m.provider?.display],
        [MedicationColumn.administrationRoute]: [MapperUtil.routeFromDutch(MapperUtil.codeableConcept(m.dosage?.[0].route))?.toLowerCase()],
        [MedicationColumn.code]: [[
          MapperUtil.mapCodeSystem(this.getReference(ResourceType.medication, m.agreedMedicine).medicationCode.coding[0].system),
          this.getReference(ResourceType.medication, m.agreedMedicine).medicationCode.coding[0].code
        ].filter(Boolean).join(': ')],
        [MedicationColumn.mbhId]: [m.medicationTreatment?.value],
        [MedicationColumn.prescriptionReason]: [m.prescriptionReason],
        [MedicationColumn.forComparison]: [m.periodOfUse?.end ? m.periodOfUse?.end : m.periodOfUse?.start]
      };
    });
  }

}

enum MedicationColumn {
  status = 'status', // grouping
  medication = 'medication',
  dosage = 'dosage',
  date = 'date',
  healthcareProvider = 'healthcareProvider',
  administrationRoute = 'administrationRoute',
  code = 'code',
  mbhId = 'mbhId',
  prescriptionReason = 'prescriptionReason',
  forComparison = 'forComparison',
}
