<div *ngIf="person" class="d-inline-flex align-items-center">
  <medrecord-ui-avatar
    [link]="person.avatar"
    medrecordAppendUserStatus
  ></medrecord-ui-avatar>

  <div class="d-flex flex-column">

    <span class="font-weight-bold">{{ person.name }}</span>

    <medrecord-ui-inline-block-column [value]="getLabelKey() | translate"></medrecord-ui-inline-block-column>
  </div>
</div>