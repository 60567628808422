import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { CoreModule } from '@medrecord/core';
import { RouterModule } from '@angular/router';
import { CurrentUserComponent } from './components/current-user/current-user.component';
import { MedrecordUiModule } from '@medrecord/ui';
import { SelectedUsersComponent } from './components/selected-users/selected-users.component';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ComboBoxModule, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ContactsViewComponent } from './components/personal-info/contacts-view/contacts-view.component';
import { AddressesViewComponent } from './components/personal-info/addresses-view/addresses-view.component';
import { AddressesFormComponent } from './components/personal-info/addresses-form/addresses-form.component';
import { ContactsFormComponent } from './components/personal-info/contacts-form/contacts-form.component';
import { GetAddressLabelPipe } from './pipes/get-address-label.pipe';
import { GetContactLabelPipe } from './pipes/get-contact-label.pipe';
import { GenderViewComponent } from './components/personal-info/gender-view/gender-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuDropdownComponent } from './components/menu-dropdown/menu-dropdown.component';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { BackBarComponent } from './components/back-bar/back-bar.component';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ProfileAddressFormFactory } from './factories/profile-address-form.factory';
import { ProfileContactsFormFactory } from './factories/profile-contacts-form.factory';
import { NameFormComponent } from './components/personal-info/name-form/name-form.component';
import { ProfileNameFormFactory } from './factories/profile-name-form.factory';
import { CommonFormComponent } from './components/personal-info/common-form/common-form.component';
import { AvatarFormComponent } from './components/personal-info/avatar-form/avatar-form.component';
import { MedrecordEssentialJsModule } from '@medrecord/essential-js-components';
import { SidebarWrapperComponent } from './components/sidebar-wrapper/sidebar-wrapper.component';
import { SidebarAllModule, TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { MedrecordIconsModule } from '@medrecord/icons';
import { MedrecordDialogComponent } from './components/dialog/dialog.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { UserFormComponent } from './components/admin/user-form/user-form.component';
import { MedrecordFormsModule } from '@medrecord/components/forms';
import { OrganisationsAssignmentFormFieldsComponent } from './components/admin/organisations-assignment-form-fields/organisations-assignment-form-fields.component';
import { HGraphComponent } from './components/h-graph/h-graph.component';
import { HGraphUtils } from './utils/h-graph.utils';
import { SliderComponent } from './components/measurements/slider/slider.component';
import { UrlUtils } from './utils/url.utils';
import { ResizeDirective } from './directives/resize.directive';
import { DrawableCanvasComponent } from './components/drawable-canvas/drawable-canvas.component';
import { FeedbackContainerComponent } from './components/feedback-container/feedback-container.component';

const components = [
  GetAddressLabelPipe,
  GetContactLabelPipe,
  TopMenuComponent,
  CurrentUserComponent,
  SelectedUsersComponent,
  PaginationComponent,
  ContactsViewComponent,
  AddressesViewComponent,
  AddressesFormComponent,
  ContactsFormComponent,
  GenderViewComponent,
  MenuDropdownComponent,
  BackBarComponent,
  NameFormComponent,
  CommonFormComponent,
  AvatarFormComponent,
  SidebarWrapperComponent,
  MedrecordDialogComponent,
  UserFormComponent,
  OrganisationsAssignmentFormFieldsComponent,
  HGraphComponent,
  SliderComponent,
  DrawableCanvasComponent,
  FeedbackContainerComponent,
];

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    MedrecordUiModule,
    TranslateModule,
    ComboBoxModule,
    ReactiveFormsModule,
    FormsModule,
    DropDownButtonModule,
    DropDownListModule,
    ButtonModule,
    SidebarAllModule,
    TreeViewModule,
    MedrecordEssentialJsModule,
    MedrecordIconsModule,
    DialogModule,
    MedrecordFormsModule,
  ],
  providers: [ProfileNameFormFactory, ProfileAddressFormFactory, ProfileContactsFormFactory, HGraphUtils, UrlUtils],
  declarations: [...components, ResizeDirective],
  exports: [...components, ResizeDirective],
})
export class MedsafeSharedModule {}
