import { Component, Input } from '@angular/core';
import { Resource, ResourceDataAny, TabularResource } from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-gp-data-tables',
  templateUrl: './gp-data-tables.component.html'
})
export class GpDataTablesComponent {
  @Input() resourceData: ResourceDataAny[];

  @Input() set tabularResourceData(tables: { [key in Resource]?: TabularResource<string> }) {
    this.tables = Object.values(tables);
  }

  public tables: TabularResource<string>[];

}
