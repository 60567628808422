<div class="form-group">
  <medrecord-ui-field-label
    [for]="id"
    *ngIf="label"
    [hint]="hint"
    [secondaryText]="secondaryLabel"
    [text]="label"
    [type]="labelType"
  ></medrecord-ui-field-label>

  <div class="d-flex flex-column flex-sm-row">
    <button
      *ngFor="let item of items"
      class="btn mr-2 font-weight-bold"
      type="button"
      [class.btn-outline-black]="!selectedItems.includes(item.id) && selectedItem !== item.id"
      [class.text-black]="!selectedItems.includes(item.id) && selectedItem !== item.id"
      [class.btn-primary]="selectedItems.includes(item.id) || selectedItem === item.id"
      (click)="onSelect(item)"
    >
      {{ item.text }}
    </button>
  </div>

  <medrecord-ui-field-error
    [errors]="controlErrorsList"
    [errorCode]="errorCode"
    [externalErrors]="externalErrorsList"
  ></medrecord-ui-field-error>
</div>
