import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-respiratory-rate',
  templateUrl: './respiratory-rate.component.svg',
  styleUrls: [],
})
export class RespiratoryRateComponent extends MedrecordIcon {
  viewHeight: number = 24;
  viewWidth: number = 24;
}
