import { InjectionToken, Provider } from '@angular/core';
import * as thresholdConstants from './threshold.constants';

export const THRESHOLDS_CONSTANTS = new InjectionToken('Measurements Manager Threshold constants');
export type ThresholdConstants = typeof thresholdConstants;

export const provideMeasurementManagerConstants = (): Provider => [
  {
    provide: THRESHOLDS_CONSTANTS,
    useValue: thresholdConstants
  }
];