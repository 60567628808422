import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const procedureBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.procedure,
  fieldGroups: [
    {
      name: ({ resource }) => resource?.content?.type?.coding?.[0]?.display || 'my_doctor_procedure',
      fields: [
        {
          label: 'my_doctor_start_date',
          fieldName: 'period',
          prepareData: (data) => (data?.start ? MedrecordMoment(data?.start).format('LL') : ''),
        },
        {
          label: 'my_doctor_end_date',
          fieldName: 'period',
          prepareData: (data) => (data?.end ? MedrecordMoment(data?.end).format('LL') : ''),
        },
        {
          label: 'my_doctor_anatomical_location',
          fieldName: 'anatomicalLocation',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_laterality',
          fieldName: 'laterality',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_organzation_name',
          fieldName: 'location',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_type',
          fieldName: 'type',
          prepareData: (data) => [data?.coding?.[0]?.code, data?.coding?.[0]?.display].filter(Boolean).join(' - '),
        },
        {
          label: 'my_doctor_method',
          fieldName: 'method',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_concern',
          fieldName: 'problem',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_device',
          fieldName: 'medicalDevice',
          prepareData: (data, resources) => {
            if (data?.display) return data?.display;

            const device = resources.find((r) => {
              const [referenceType, referenceId] = (data?.reference ?? '').split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return [device?.content?.carrierHrf, device?.content?.productType?.coding?.[0]?.display]
              .filter(Boolean)
              .join(' - ');
          },
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'comment',
          prepareData: (data, resources, resource) => {
            if (data) return data;

            const condition = resources.find((r) => {
              const [referenceType, referenceId] = (resource?.content?.problem?.reference ?? '').split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return condition?.content?.comment;
          },
        },
        {
          label: 'my_doctor_performer',
          fieldName: 'performer',
          prepareData: (data) => data?.display,
        },
      ],
    },
  ],
});
