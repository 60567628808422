import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { generalRouteNames } from '@medrecord/routes-general';
import { medsafeRouteNames } from '@medrecord/routes-medsafe';
import { MyDoctorWrapperComponent } from './containers/common/my-doctor-wrapper/my-doctor-wrapper.component';
import {
  CareproviderDetailsPageContainerComponent
} from './containers/careprovider/careprovider-details-page-container/careprovider-details-page-container.component';
import {
  CareprovidersPageContainerComponent
} from './containers/careprovider/careproviders-page-container/careproviders-page-container.component';
import { RecordContainerComponent } from './containers/dataservice/dataservice-wrapper/record-container.component';
import {
  FetchResourceResponseContainerComponent
} from './containers/common/fetch-resource-response-container/fetch-resource-response-container.component';

export const MY_DOCTOR_ROUTES = [
  {
    path: generalRouteNames.Base,
    component: MyDoctorWrapperComponent,
    children: [
      {
        path: medsafeRouteNames.MyDoctor.Favourites,
        component: CareprovidersPageContainerComponent,
        data: { userScope: true }
      },
      {
        path: medsafeRouteNames.MyDoctor.Search,
        component: CareprovidersPageContainerComponent
      },
      {
        path: medsafeRouteNames.MyDoctor.Careprovider
          + '/' + medsafeRouteNames.MyDoctor.CareproviderId
          + '/' + medsafeRouteNames.MyDoctor.ServiceId,
        children: [
          {
            path: generalRouteNames.Base,
            component: CareproviderDetailsPageContainerComponent
          },
          {
            path: medsafeRouteNames.MyDoctor.Fetch,
            component: FetchResourceResponseContainerComponent
          },
          {
            path: medsafeRouteNames.MyDoctor.RecordId,
            component: RecordContainerComponent
          }
        ]
      },
      {
        path: generalRouteNames.Base,
        redirectTo: medsafeRouteNames.MyDoctor.Favourites,
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(MY_DOCTOR_ROUTES)],
  exports: [RouterModule]
})
export class MyDoctorRoutingModule {
}
