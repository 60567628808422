<div class="wrapper bg-primary d-flex">
  <ng-container *ngIf="(pinnedUsers$ | async) as pinnedUsers">
    <div *ngIf="pinnedUsers.length && show" class="d-flex align-items-end">
      <div *ngFor="let pinnedUser of pinnedUsers"
           [class.active]="url.includes(pinnedUser.id)"
           [routerLink]="[medsafeRouteNames.Network.Entry, pinnedUser.id]"
           (click)="selectUser(pinnedUser)"
           class="tab user-tab position-relative"
      >

        {{ pinnedUser.name?.text }}

        <button class="btn remove-button p-0"
                (click)="removePinnedUser(pinnedUser.id)">
          <i class="fas fa-times-circle"></i>
        </button>
      </div>
      <div [routerLink]="[medsafeRouteNames.Network.Entry, medsafeRouteNames.Network.List]"
           class="tab"
           [class.active]="this.url.includes(medsafeRouteNames.Network.List)"
      >
        <i class="fas fa-plus"></i>
      </div>
    </div>
  </ng-container>
  <span class="ml-auto my-auto mr-5 text-lg text-light text-weight-500">{{ currentDate }}</span>
</div>