<nav
  class="navbar navbar-expand-lg shadow-sm"
  [class.is-mobile]="(currentScreenSize$ | async) !== screenSizes.desktop"
  [ngClass]="customClass"
>
  <div>
    <a class="navbar-brand" [class.is-mobile]="(currentScreenSize$ | async) === screenSizes.mobile" routerLink="/">
      <img [attr.src]="logotype$ | async | safeUrl" alt="Logotype" />
    </a>
  </div>

  <!-- Mobile and tablet menu -->
  <div class="mobile-menu-items d-flex align-items-center" *onlyForScreen="screenSizes.tablet">
    <div class="d-flex align-items-center">
      <button
        ejs-dropdownbutton
        class="btn btn-outline-black mt-0"
        [items]="menuItems"
        [content]="activeItem?.text || menuItems[0]?.text"
        (select)="onItemSelect($event)"
        cssClass="custom-main-menu"
      ></button>
    </div>
    <div class="d-flex align-items-center">
      <button class="btn btn-black-outline" (click)="hideSidebar()">
        <span class="fas fa-bars"></span>
      </button>
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </div>
  </div>

  <!-- Desktop menu -->
  <div *onlyForScreen="screenSizes.desktop" class="collapse navbar-collapse" id="navbarText">
    <ul class="navbar-nav mr-auto ml-5">
      <li class="nav-item" *ngFor="let item of menuItems">
        <a
          class="nav-link text-xl text-weight-500 text-black d-flex align-items-center"
          [class.active]="item.path === activeItem?.path"
          [class.text-black]="item.path === activeItem?.path"
          [class.text-weight-600]="item.path === activeItem?.path"
          [routerLink]="item.path"
        >
          <medrecord-ui-dynamic-icon
            *ngIf="item.iconComponent"
            class="icon"
            [width]="24"
            [iconComponent]="item.iconComponent"
          ></medrecord-ui-dynamic-icon>
          <i
            *ngIf="item.iconClass && !item.iconComponent"
            [class.active]="item.path === activeItem?.path"
            class="{{ item.iconClass }}"
          ></i>
          <span class="ml-2" style="line-height: 0;">{{ item.text }}</span>
        </a>
      </li>
    </ul>

    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </div>
</nav>

<ng-template #contentTpl><ng-content></ng-content></ng-template>
