<ng-container *ngFor="let line of column.lines; let i = index">
  <div [ngSwitch]="line.type"
       [class.font-weight-bold]="line.bold"
       [class.text-xs]="line.textXs">
    <span>{{ data[column.property][i] && line.prefix ? ((line.prefix | translate) + ': ') : '' }}</span>
    <ng-container *ngSwitchCase="'string'">{{ data[column.property][i] }}</ng-container>
    <ng-container *ngSwitchCase="'translate'">
      {{
        (
          (line.translationPrefix && data[column.property][i])
            ? line.translationPrefix + data[column.property][i]
            : data[column.property][i]
        ) | translate
      }}
    </ng-container>
    <ng-container *ngSwitchCase="'date'">
      {{ data[column.property][i] ? (asString(data[column.property][i]) | formatDateTime: 'L') : '' }}
    </ng-container>
    <ng-container *ngSwitchCase="'precisionDate'">
      {{ asDate(data[column.property][i])?.date | precisionFormat: asDate(data[column.property][i])?.precision }}
    </ng-container>
    <ng-container *ngSwitchCase="'range'">
      {{ asRange(data[column.property][i]).start ? (asRange(data[column.property][i]).start | formatDateTime: 'L') : '' }}
      {{ ' - ' }}
      {{ asRange(data[column.property][i]).end ? (asRange(data[column.property][i]).end | formatDateTime: 'L') : '' }}
    </ng-container>
    <ng-container *ngSwitchCase="'duration'">
      {{
        data[column.property][i]
          ? ('healthcare_providers_resource_repeat_period_cyclical_value' | translate:{
            period: asDuration(data[column.property][i]).period,
            unit: ('healthcare_providers_resource_duration_unit_' + asDuration(data[column.property][i]).unit) | translate
          })
          : ''
      }}
    </ng-container>
    <ng-container *ngSwitchCase="'multiline'">
      <div *ngFor="let value of data[column.property][i]">{{value}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'icon'">
      <ng-container *ngIf="data[column.property][i]">
        <ng-container *ngComponentOutlet="getIconComponent(asString(data[column.property][i]))"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'chip'">
      <doctor-gui-badge-status
        [status]="data[column.property][i]"
        [translationPrefix]="line.translationPrefix">
      </doctor-gui-badge-status>
    </ng-container>
  </div>
</ng-container>
