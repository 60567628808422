import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvService } from '@medrecord/core';
import { BuddyOfUsers, CreateUserResponse, User } from '../models/interfaces';
import { UserMetaAllergy } from '../models/interfaces/user-meta-allergy.interface';

@Injectable()
export class UsersManagerService {
  constructor(private http: HttpClient, private envService: EnvService) {}

  getUser(id: string): Observable<User> {
    return this.http.get<User>(this.envService.backend + '/person/' + id);
  }

  getUserMetaAllergies(userId: string): Observable<UserMetaAllergy[]> {
    let params = new HttpParams();

    params = params.set('count', '6');
    params = params.set('start', '0');

    return this.http.get<UserMetaAllergy[]>(this.envService.backend + '/patient/' + userId + '/allergy', { params });
  }

  getUserNetworks(userId: string): Observable<BuddyOfUsers[]> {
    return this.http.get<BuddyOfUsers[]>(this.envService.backend + '/person/' + userId + '/network/other');
  }

  createUser(user: User): Observable<CreateUserResponse> {
    return this.http.post<CreateUserResponse>(`${this.envService.backend}/person`, user);
  }

  editUser(user: User, id: string): Observable<User> {
    return this.http.patch<User>(this.envService.backend + '/person/' + id, user);
  }
}
