import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicationsManagerService } from './services/medications-manager.service';
import { MedicationsManagerStateModule } from './state-management/medications-manager-state.module';

@NgModule({
  imports: [CommonModule, MedicationsManagerStateModule],
  providers: [MedicationsManagerService]
})
export class MedicationsManagerModule {}
