import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './components/avatar/avatar.component';
import { AvatarSidebarComponent } from './components/avatar-sidebar/avatar-sidebar.component';
import { DividerComponent } from './components/divider/divider.component';
import { ActionIconComponent } from './components/action-icon/action-icon.component';
import { SidebarModule, TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { ButtonAllModule, ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { MedrecordDirectivesUserStatusModule } from '@medrecord/directives-user-status';
import { MedrecordDatetimeModule } from '@medrecord/services-datetime';
import { DialogModule, TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import {
  AutoCompleteModule,
  ComboBoxModule,
  DropDownListModule,
  MultiSelectModule,
} from '@syncfusion/ej2-angular-dropdowns';
import { UiConstantsProviders } from './constants';
import { CoreModule } from '@medrecord/core';
import { SelectLanguageComponent } from './components/select-language/select-language.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TreeListComponent } from './components/tree-list/tree-list.component';
import { DatePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { IntroTextComponent } from './components/intro-text/intro-text.component';
import { FieldErrorComponent } from './components/form/field-error/field-error.component';
import { PrivacyPolicyDialogComponent } from './components/privacy-policy-dialog/privacy-policy-dialog.component';
import { NgxMaskModule } from 'ngx-mask';
import { BricksComponent } from './components/form/bricks/bricks.component';
import { CheckboxComponent } from './components/form/checkbox/checkbox.component';
import { DatepickerPrecisionComponent } from './components/form/datepicker-precision/datepicker-precision.component';
import { FieldLabelComponent } from './components/form/field-label/field-label.component';
import { HintComponent } from './components/form/hint/hint.component';
import { InputComponent } from './components/form/input/input.component';
import { MapFieldComponent } from './components/form/map-field/map-field.component';
import { PhoneComponent } from './components/form/phone/phone.component';
import { SelectComponent } from './components/form/select/select.component';
import { TextareaComponent } from './components/form/textarea/textarea.component';
import { NameColumnComponent } from './components/columns/name-column/name-column.component';
import { PersonColumnComponent } from './components/columns/contact-person-column/person-column.component';
import { BlockColumnComponent } from './components/columns/block-column/block-column.component';
import { InlineBlockColumnComponent } from './components/columns/inline-block-column/inline-block-column.component';
import { LabeledColumnComponent } from './components/columns/labeled-column/labeled-column.component';
import { BlockTruncatedColumnComponent } from './components/columns/block-truncated-column/block-truncated-column.component';
import { QuoteColumnComponent } from './components/columns/quote-column/quote-column.component';
import { LinkColumnComponent } from './components/columns/link-column/link-column.component';
import { ActionColumnComponent } from './components/columns/action-column/action-column.component';
import { DropdownColumnComponent } from './components/columns/dropdown-column/dropdown-column.component';
import { MultiselectColumnComponent } from './components/columns/multiselect-column/multiselect-column.component';
import { AvatarDropdownComponent } from './components/navigations/avatar-dropdown/avatar-dropdown.component';
import { MainMenuComponent } from './components/navigations/main-menu/main-menu.component';
import { SidebarComponent } from './components/navigations/sidebar/sidebar.component';
import { WillBeSoonPageComponent } from './components/will-be-soon/will-be-soon-page.component';
import { WillBeSoonFeatureComponent } from './components/will-be-soon/feature.component';
import { DynamicIconComponent } from './components/dynamic-icon/dynamic-icon.component';
import { MedrecordIconsModule } from '@medrecord/icons';
import { BlockColumnTagComponent } from './components/columns/block-column-tag/block-column-tag.component';
import { InlineBlockColumnTagComponent } from './components/columns/inline-block-column-tag/inline-block-column-tag.component';
import { BackIconButtonComponent } from './components/back-icon-button/back-icon-button.component';
import { SelectPrecisionComponent } from './components/select-precision/select-precision.component';
import { AutocompleteComponent } from './components/form/autocomplete/autocomplete.component';
import { OneLineBlockColumnComponent } from './components/columns/one-line-block-column/one-line-block-column.component';
import { MultiSelectComponent } from './components/form/multi-select/multi-select.component';
import { BadgeComponent } from './components/badge/badge.component';
import { CardInfoComponent } from './components/form/card-info/card-info.component';
import { DatepickerComponent } from './components/form/datepicker/datepicker.component';
import { TimepickerComponent } from './components/form/timepicker/timepicker.component';

export const components = [
  ActionColumnComponent,
  ActionIconComponent,
  AvatarComponent,
  AvatarDropdownComponent,
  AvatarSidebarComponent,
  BlockColumnComponent,
  BlockTruncatedColumnComponent,
  BricksComponent,
  CheckboxComponent,
  DatepickerPrecisionComponent,
  DividerComponent,
  DropdownColumnComponent,
  FieldLabelComponent,
  HintComponent,
  InlineBlockColumnComponent,
  InputComponent,
  IntroTextComponent,
  LabeledColumnComponent,
  LinkColumnComponent,
  MainMenuComponent,
  MapFieldComponent,
  MultiselectColumnComponent,
  NameColumnComponent,
  PaginationComponent,
  PersonColumnComponent,
  PhoneComponent,
  PrivacyPolicyComponent,
  PrivacyPolicyDialogComponent,
  QuoteColumnComponent,
  SelectComponent,
  SelectLanguageComponent,
  SidebarComponent,
  TextareaComponent,
  TreeListComponent,
  WillBeSoonFeatureComponent,
  WillBeSoonPageComponent,
  FieldErrorComponent,
  DynamicIconComponent,
  BlockColumnTagComponent,
  InlineBlockColumnTagComponent,
  BackIconButtonComponent,
  SelectPrecisionComponent,
  AutocompleteComponent,
  OneLineBlockColumnComponent,
  MultiSelectComponent,
  BadgeComponent,
  CardInfoComponent,
  DatepickerComponent,
  TimepickerComponent
];

@NgModule({
  imports: [
    CommonModule,
    ListViewModule,
    ButtonAllModule,
    TreeViewModule,
    SidebarModule,
    TranslateModule,
    DropDownButtonModule,
    DropDownListModule,
    ButtonModule,
    MedrecordDirectivesUserStatusModule,
    MedrecordDatetimeModule,
    MedrecordIconsModule,
    TooltipAllModule,
    DropDownListModule,
    MultiSelectModule,
    CoreModule,
    FormsModule,
    NgxIntlTelInputModule,
    NgxPaginationModule,
    DatePickerModule,
    DateTimePickerModule,
    ComboBoxModule,
    ReactiveFormsModule,
    DialogModule,
    AutoCompleteModule,
    NgxMaskModule.forRoot(),
  ],

  declarations: [...components],

  providers: [UiConstantsProviders.provideSidebarConstants()],

  exports: [...components],
})
export class MedrecordUiModule {}
