import { myDoctorManagerFeatureKey, State } from '../my-doctor-manager.reducer';
import { createSelector } from '@ngrx/store';
import { FetchResourceResponse, LoadResourceResponse, MappingResource, ResourceDataAny } from '../../models/interfaces';
import { Resource } from '../../models/enums';
import { TabularResource } from '../../models/interfaces/tabular/tabular-resource.interface';

const getState = (state: State) => state[myDoctorManagerFeatureKey];

export const getAllResourcesKeys = createSelector(
  getState,
  (state: State): MappingResource[] => state.allResourcesKeys
);

export const getAllResourcesKeysSize = createSelector(
  getState,
  (state: State): number => state.allResourcesKeys.length
);

export const getFilteredResourcesKeys = createSelector(
  getState,
  (state: State): MappingResource[] => state.filteredResourcesKeys
);

export const getResourceLoadResponse = createSelector(
  getState,
  (state: State): LoadResourceResponse[] => state.loadResourceResponse
);

export const getFetchResourceResponse = createSelector(
  getState,
  (state: State): FetchResourceResponse[] => state.fetchResourceResponse
);

export const getResourceData = createSelector(
  getState,
  (state: State): ResourceDataAny[] => state.resourceData
);

export const getTabularResourceData = createSelector(
  getState,
  (state: State): { [key in Resource]?: TabularResource<string> } => state.tabularResourceData
);
