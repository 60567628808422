import { GenericMapper } from './generic.mapper';
import { CollapsibleTable, ColumnDefinition } from '../interfaces';
import { Resource, ResourceType } from '../enums';
import { ClinicalStatus } from '@medrecord/core';
import { AllergyIntoleranceStatus } from '@managers/allergies';
import { MapperUtil } from './mapper.util';

export class MedicationIntolerancesMapper extends GenericMapper<AllergyColumn> {

  protected get title(): string {
    return 'my_doctor_allergies_intolerances';
  }

  protected get mainKey(): Resource {
    return Resource.gpMedicationIntolerance2020;
  }

  protected get mainType(): ResourceType {
    return ResourceType.allergyIntolerance;
  }

  protected get sortBy(): AllergyColumn[] {
    return [AllergyColumn.forComparison];
  }

  protected get groupBy(): AllergyColumn {
    return AllergyColumn.status;
  }

  protected get groupSortOrder(): { [key: string]: number } {
    return {
      [ClinicalStatus.Active]: 0,
      [ClinicalStatus.Inactive]: 1,
      [ClinicalStatus.Resolved]: 2
    };
  }

  protected get activeGroups(): string[] {
    return [ClinicalStatus.Active];
  }

  protected get columns(): ColumnDefinition<AllergyColumn>[] {
    return [
      {
        property: AllergyColumn.type,
        title: 'my_doctor_type',
        width: 11,
        lines: [{
          type: 'icon'
        }]
      },
      {
        property: AllergyColumn.allergyIntolerance,
        title: 'my_doctor_allergy',
        width: 13,
        lines: [{
          type: 'string',
          bold: true
        }]
      },
      {
        property: AllergyColumn.date,
        title: 'my_doctor_date',
        width: 12,
        lines: [{
          type: 'precisionDate',
          textXs: true,
          prefix: 'allergies_onset_date'
        }, {
          type: 'precisionDate',
          textXs: true,
          prefix: 'allergies_last_occurrence'
        }]
      },
      {
        property: AllergyColumn.criticality,
        title: 'my_doctor_allergy_criticality',
        width: 13,
        lines: [{
          type: 'chip',
          translationPrefix: 'allergies_'
        }]
      },
      {
        property: AllergyColumn.category,
        title: 'my_doctor_allergy_category',
        width: 13,
        lines: [{
          type: 'translate'
        }]
      },
      {
        property: AllergyColumn.healthcareProvider,
        title: 'my_doctor_healthcare_provider',
        width: 13,
        lines: [{
          type: 'string'
        }]
      },
      {
        property: AllergyColumn.comments,
        title: 'my_doctor_comment',
        width: 13,
        lines: [{
          type: 'string'
        }]
      },
      {
        property: AllergyColumn.verification,
        title: 'my_doctor_verification_status',
        width: 11,
        lines: [{
          type: 'chip',
          translationPrefix: 'allergies_'
        }]
      }
    ];
  }

  protected get collapsibleTables(): CollapsibleTable<AllergyColumn>[] {
    return [{
      property: AllergyColumn.reactions,
      title: 'my_doctor_occurrences_reactions',
      columns: [
        {
          property: AllergyColumn.severity,
          title: 'my_doctor_severity',
          width: 11,
          lines: [{
            type: 'chip',
            translationPrefix: 'allergies_'
          }]
        },
        {
          property: AllergyColumn.substance,
          title: 'group-substance-use',
          width: 13,
          lines: [{
            type: 'string'
          }]
        },
        {
          property: AllergyColumn.dateAndTime,
          title: 'my_doctor_date',
          width: 12,
          lines: [{
            type: 'date'
          }]
        },
        {
          property: AllergyColumn.description,
          title: 'my_doctor_description',
          width: 26,
          lines: [{
            type: 'string'
          }]
        },
        {
          property: AllergyColumn.symptoms,
          title: 'my_doctor_symptom',
          width: 37,
          lines: [{
            type: 'string'
          }]
        }
      ]
    }];
  }

  protected getAllRows(): { [key in AllergyColumn]?: any[] }[] {
    return this.mainResources.map(a => { // a = allergy
      return {
        [AllergyColumn.status]: [a.clinicalStatus],
        [AllergyColumn.type]: [a.type === AllergyIntoleranceStatus.allergy
          ? 'AllergyHandComponent'
          : a.type === AllergyIntoleranceStatus.intolerance
            ? 'MedicationAlertComponent'
            : null],
        [AllergyColumn.allergyIntolerance]: [MapperUtil.codeableConcept(a.code)],
        [AllergyColumn.date]: [a.onset, a.lastOccurrence],
        [AllergyColumn.criticality]: [a.criticality],
        [AllergyColumn.category]: [a.category?.category?.toLowerCase()],
        [AllergyColumn.healthcareProvider]: [a.recorder?.display],
        [AllergyColumn.comments]: [a.note?.map(n => [n.authorName, n.text].filter(Boolean).join(' - ')).filter(Boolean).join(', ')],
        [AllergyColumn.verification]: [a.verificationStatus],
        [AllergyColumn.forComparison]: [a.lastOccurrence?.date ? a.lastOccurrence.date : a.onset?.date],

        [AllergyColumn.reactions]: a.reactionList.map(this.mapReaction)
      };
    });
  }

  private mapReaction = (r): { [key in AllergyColumn]?: any[] } => ({
    [AllergyColumn.severity]: [r.severity],
    [AllergyColumn.substance]: [MapperUtil.codeableConcept(r.substance)],
    [AllergyColumn.dateAndTime]: [r.onset],
    [AllergyColumn.description]: [r.description],
    [AllergyColumn.symptoms]: [r.manifestation?.map(m => MapperUtil.codeableConcept(m)).join(', ')]
  })
}

enum AllergyColumn {
  status = 'status', // grouping
  type = 'type',
  allergyIntolerance = 'allergyIntolerance',
  date = 'date',
  criticality = 'criticality',
  category = 'category',
  healthcareProvider = 'healthcareProvider',
  comments = 'comments',
  verification = 'verification',
  forComparison = 'forComparison',

  reactions = 'reactions', // collapsible table

  severity = 'severity',
  substance = 'substance',
  dateAndTime = 'dateAndTime',
  description = 'description',
  symptoms = 'symptoms',
}
