import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { getNetworkOrganisations, NetworkOrganisation } from '@managers/admin-organisations-manager';
import { IUserItemModel, IUserManagementStatus } from '@managers/admin-users-manager';
import { resendVerificationEmailAction, Role } from '@medrecord/managers-auth';
import { GenderEnum, User } from '@medrecord/managers-users';
import { DatePrecision } from '@medrecord/services-datetime';
import { ValidationService } from '@medrecord/services-form-validation';
import { mapEnum, scrollToFirstInvalidField } from '@medrecord/tools-utils';
import { MapFieldOptions, MapFieldType } from '@medrecord/ui';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ItemModel } from '@syncfusion/ej2-angular-navigations';
import { find } from 'lodash';
import { Observable } from 'rxjs';

@Component({
  selector: 'medsafe-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  form: FormGroup;
  readonly mapFieldType = MapFieldType;
  readonly role = Role;
  readonly requiredErrorMsg = this.translateService.instant('validation.required');
  readonly emailErrorMsg = this.translateService.instant('validation.email');

  @Input() userDetails: IUserItemModel;
  @Input() reset: boolean = false;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  @Output() onOrganisationUnassign: EventEmitter<any> = new EventEmitter();

  organisations$: Observable<NetworkOrganisation[]> = this.store.select(getNetworkOrganisations);

  readonly maxSelectableBirthday = new Date();

  readonly rolesOptions: ItemModel[] = mapEnum(Role, (value) => ({
    id: value,
    text: this.translateService.instant('zdb_role_' + value.toLowerCase()),
  }));

  readonly genderOptions: MapFieldOptions = {
    items: mapEnum(GenderEnum, (value) => ({
      id: value.toUpperCase(),
      text: this.translateService.instant('profile_gender_' + value.toLowerCase()),
    })),
  };

  readonly dateTimeOptions: MapFieldOptions = {
    maxDate: new Date(),
    precision: DatePrecision.day,
  };

  readonly userStatuses: MapFieldOptions = {
    items: mapEnum(IUserManagementStatus, (value) => ({
      id: value,
      text: this.translateService.instant('zdb_status_' + value.toLowerCase()),
    })),
  };

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    private translateService: TranslateService
  ) {}

  // To get all element of the form component after component get initialized.
  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    const married = !!(
      this.userDetails?.personData?.name?.partnerLastName ||
      this.userDetails?.personData?.name?.partnerLastNamePrefix ||
      this.userDetails?.personData?.name?.suffix
    );
    this.form = this.formBuilder.group({
      firstName: [
        this.userDetails?.personData?.name?.firstName || '',
        [this.validationService.required(this.requiredErrorMsg)],
      ],
      lastName: [
        this.userDetails?.personData?.name?.lastName || '',
        [this.validationService.required(this.requiredErrorMsg)],
      ],
      suffix: [this.userDetails?.personData?.name?.suffix || ''],
      lastNamePrefix: [this.userDetails?.personData?.name?.lastNamePrefix || ''],
      married: [married],
      email: [
        this.userDetails?.email,
        [this.validationService.required(this.requiredErrorMsg), this.validationService.email(this.emailErrorMsg)],
      ],
      gender: [
        this.userDetails?.personData?.gender?.toUpperCase(),
        [this.validationService.required(this.requiredErrorMsg)],
      ],
      birthDate: [
        this.userDetails?.personData?.birthDate || null,
        [this.validationService.required(this.requiredErrorMsg)],
      ],
      status: [this.userDetails?.status?.toLowerCase(), [this.validationService.required(this.requiredErrorMsg)]],
      roles: [this.userDetails?.roles, [this.validationService.required(this.requiredErrorMsg)]],
      partnerLastName: [this.userDetails?.personData?.name?.partnerLastName || null],
      partnerLastNamePrefix: [this.userDetails?.personData?.name?.partnerLastNamePrefix || null],
    });
  }

  private getPreparedPersonDataModel(): User {
    const {
      firstName,
      lastName,
      suffix,
      lastNamePrefix,
      partnerLastName,
      partnerLastNamePrefix,
      gender,
      birthDate,
      married,
    } = this.form.value;

    const name = {
      ...(this.userDetails?.personData?.name || {}),
      firstName,
      lastName,
      lastNamePrefix,
      suffix,
      partnerLastName: married ? partnerLastName : null,
      partnerLastNamePrefix: married ? partnerLastNamePrefix : null,
    };

    return <User>{
      birthDate,
      gender: gender.toUpperCase(),
      name: name,
    };
  }

  private getPreparedOrganisationsAssignments(): NetworkOrganisation[] {
    const { organisations = [] } = this.form.value;
    let assignments: NetworkOrganisation[] = [];

    this.organisations$.subscribe((allOrganisations) => {
      assignments = organisations.map(
        (organisationValue): NetworkOrganisation => {
          const organisation = find(allOrganisations, (org) => org.id === organisationValue.organisationId);
          return {
            id: organisationValue.organisationId,
            medicalRoles: organisationValue.medicalRoles,
            role: organisationValue.managementRole,
            name: organisation.name,
            organizationId: organisation.id,
            organizationName: organisation.name,
          };
        }
      );
    });

    return assignments;
  }

  public handleSubmit(): void {
    const { roles, status } = this.form.value;
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const preparedUserItemModel: IUserItemModel = {
        ...this.userDetails,
        roles,
        status,
        personData: this.getPreparedPersonDataModel(),
        networkOrganizations: this.getPreparedOrganisationsAssignments(),
      };
      this.onSubmit.emit(preparedUserItemModel);
    } else {
      scrollToFirstInvalidField();
    }
  }

  handleOrganisationUnassign(organisationId: string): void {
    if (organisationId) {
      this.onOrganisationUnassign.emit(organisationId);
    }
  }

  resendValidation(): void {
    this.store.dispatch(resendVerificationEmailAction({ email: this.userDetails?.email }));
  }
}
