import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Permission, User } from '@medrecord/managers-users';
import { EnvService } from '@medrecord/core';

@Injectable()
export class NetworkManagerService {
  constructor(
    private http: HttpClient,
    private envService: EnvService
    ) {}

  getUserNetwork(userId: string): Observable<User[]> {
    return this.http.get<User[]>(this.envService.backend + '/person/' + userId + '/network');
  }

  getRelatedPeople(userId: string): Observable<User[]> {
    return this.http.get<User[]>(this.envService.backend + '/patient/' + userId + '/relatedperson');
  }

  searchUsers(userId: string, searchQuery: string): Observable<User[]> {
    let params = new HttpParams();
    params = params.set('name', searchQuery);

    return this.http.get<User[]>(this.envService.backend + '/person/' + userId + '/search', { params });
  }

  addUserToNetwork(userId: string, networkUserId: string, permission: Permission): Observable<any> {
    return this.http.post(this.envService.backend + '/person/' + userId + '/network/user', {
      id: networkUserId,
      permission,
    });
  }

  updateUserInNetwork(userId: string, networkUserId: string, permission: Permission): Observable<any> {
    return this.http.put(this.envService.backend + '/person/' + userId + '/network/user/' + networkUserId, {
      permission,
    });
  }

  deleteUserFromNetwork(userId: string, networkUserId: string): Observable<any> {
    return this.http.delete(this.envService.backend + '/person/' + userId + '/network/user/' + networkUserId);
  }
}
