import { createAction, props } from '@ngrx/store';
import { FetchResourceRequest, FetchResourceResponse } from '../../models/interfaces';

export const setAfterFetchingResourcesReturnToAction = createAction(
  '[My Doctor Manager Actions] Set After Fetching Resources Return To',
  props<{ detailsRoute: string }>()
);

export const setFetchResourcesRequestPayloadAction = createAction(
  '[My Doctor Manager Actions] Set Fetch Resources Request',
  props<{ fetchResourceRequest: FetchResourceRequest }>()
);

export const fetchResourcesAction = createAction(
  '[My Doctor Manager Actions] Fetch Resources'
);

export const fetchResourcesCompletedAction = createAction(
  '[My Doctor Manager Actions] Fetch Resources Completed',
  props<{ responsePayload: FetchResourceResponse[] }>()
);

export const performAuthorizationForCareproviderAction = createAction(
  '[My Doctor Manager Actions] Perform Authorization For Careprovider'
);
