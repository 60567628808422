export class BlocksMapperHelperUtil {
  static mapName(data:any, considerFamily = false): string {
    return [
      data.firstName,
      data.initials,
      data.givenName,
      data.partnerLastNamePrefix,
      data.partnerLastName,
      data.lastNamePrefix,
      considerFamily ? (data.lastName || data.family || null) : data.lastName
    ].filter(part => part).join(' ');
  }
}