import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-external-services',
  templateUrl: './external-services.component.svg',
})
export class ExternalServicesComponent extends MedrecordIcon {
  viewHeight = 24;
  viewWidth = 24;
}
