export enum TranslationsCategory {
  PublicWebsite = 'public_website',
  Common = "common",
  Settings = "settings",
  HealthPlans = "health_plans",
  BaseMedicalData = "base_medical_data",
  Immunizations = "immunizations",
  HealthcareProviders = "healthcare_providers",
  Feedback = "feedback",
  Auth = "auth",
  Network = "network",
  Measurements = "measurements",
  Errors = "errors",
  TOS = "tos",
  Allergies = "allergies",
  Profile = "profile",
  Payment = "payment",
}