<div class="form-group">
  <medrecord-ui-field-label
    for="select"
    [hint]="hint"
    [secondaryText]="secondaryLabel"
    [text]="label"
    [type]="labelType"
    [asterix]="asterix"
  ></medrecord-ui-field-label>

  <ejs-dropdownlist
    [class.warning]="controlErrorsList.length"
    [class.error]="errorCode || externalErrorsList.length"
    id="select"
    [dataSource]="items"
    [placeholder]="placeholder"
    (select)="onSelect($event)"
    [text]="selectedItem?.text"
    [value]="selectedItem?.id"
    [htmlAttributes]="{ name: name || control?.name }"
    [readonly]="disabled"
    [allowFiltering]="allowFiltering"
  ></ejs-dropdownlist>

  <medrecord-ui-field-error
    [errors]="controlErrorsList"
    [errorCode]="errorCode"
    [externalErrors]="externalErrorsList"
  ></medrecord-ui-field-error>
</div>
