import { Component, Input } from '@angular/core';

@Component({
  selector: 'medrecord-ui-user-metadata-sidebar',
  templateUrl: './avatar-sidebar.component.html',
})
export class AvatarSidebarComponent {
  @Input() userData: any;
  @Input() profileImage: string;
}
