import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-error',
  templateUrl: './error.component.svg',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent extends MedrecordIcon {
  viewHeight = 32;
  viewWidth = 32;
}
