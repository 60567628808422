import { CreateUrl, QueryUrl, RemoveUrl, UpdateUrl } from './data-manager-urls.tokens';
import { Provider } from '@angular/core';
import { MedrecordEssentialJSActionUrls } from './typings';

export const getUrlInjectorProviders = (urls: MedrecordEssentialJSActionUrls): Provider[] => {
  return [
    {
      provide: QueryUrl,
      useValue: urls.QueryUrl,
    },
    {
      provide: CreateUrl,
      useValue: urls.CreateUrl,
    },
    {
      provide: UpdateUrl,
      useValue: urls.UpdateUrl,
    },
    {
      provide: RemoveUrl,
      useValue: urls.RemoveUrl,
    },
  ]
};
