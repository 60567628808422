<nav class="navbar bg-athens-darken justify-content-center d-none d-lg-block">
  <ul class="nav d-flex justify-content-center">
    <li
      (click)="navigateTo(tab)"
      *ngFor="let tab of tabs"
      [class.active]="router.url.includes(tab.route)"
      [class.disabled]="tab.disabled"
      [class.shadow-sm-inset]="router.url.includes(tab.route)"
      class="nav-item menu-item"
    >
      <ng-container *ngTemplateOutlet="tab.template; context: { route: tab.route }"></ng-container>
    </li>
  </ul>

  <div class="right-item">
    <ng-content></ng-content>
  </div>
</nav>