import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'medrecord-ui-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() maxSize = 20;
  @Input() responsive = true;
  @Output() pageChange = new EventEmitter<number>();
}
