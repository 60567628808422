import { Component } from '@angular/core';
import {
  Careprovider,
  deleteDataAction,
  getActiveCareprovider,
  getActiveDataserviceId,
  getDeleteDataModalIsDataservice,
  getDeleteDataModalOpenStatus,
  getDeleteDataModalResource,
  MappingResource,
  setDeleteDataModalOpenStatusAction
} from '@managers/my-doctor';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'doctor-gui-delete-data-modal-container',
  templateUrl: 'delete-data-modal-container.component.html'
})
export class DeleteDataModalContainerComponent {
  isModalOpen$: Observable<boolean> = this.store.select(getDeleteDataModalOpenStatus);
  isDeleteDataservice$: Observable<boolean> = this.store.select(getDeleteDataModalIsDataservice);
  dataserviceId$: Observable<string> = this.store.select(getActiveDataserviceId);
  resource$: Observable<MappingResource> = this.store.select(getDeleteDataModalResource);
  careprovider$: Observable<Careprovider> = this.store.select(getActiveCareprovider);

  constructor(
    private store: Store
  ) {
  }

  onModalSubmit(deleteData: {
    careproviderId: string,
    resourceKeys?: MappingResource[]
  }): void {
    this.store.dispatch(deleteDataAction({
      careProviderId: deleteData.careproviderId,
      resources: deleteData.resourceKeys
    }));
  }

  setModalClosed(): void {
    this.store.dispatch(setDeleteDataModalOpenStatusAction({ isOpen: false }));
  }
}
