import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-mail',
  templateUrl: './mail.component.svg',
  styleUrls: ['./mail.component.scss'],
})
export class MailComponent extends MedrecordIcon {
  viewHeight = 16;
  viewWidth = 16;
}
