<div class="row">
  <div class="col-9 mb-4 d-flex align-items-center">
    <medrecord-ui-back-icon-button
      [tooltip]="('common_back_to' | translate) + ' ' + getDisplayName(careprovider)"
      (onBack)="onGoBack()"
    ></medrecord-ui-back-icon-button>
    <h3 class="text-weight-600 text-black mb-0 mr-2">
      {{ getDisplayName(careprovider) }}
    </h3>
    <medrecord-svg-medmij-verified [width]="22"></medrecord-svg-medmij-verified>
  </div>
  <div class="col-3 mb-4 d-flex align-items-center justify-content-end">
    <button class="btn btn-secondary font-weight-bold" (click)="onRedirect()" type="button">
      {{ 'my_doctor_view_data' | translate | titlecase }}
    </button>
  </div>
</div>

<div class="ml-4 pl-3">
  <ejs-grid
    #grid
    [rowHeight]="80"
    class="table-card"
    [dataSource]="resources"
    [height]="resources?.length ? 'auto' : '50vh'"
  >
    <e-columns>
      <e-column [width]="400" field="name" [headerText]="'ZIB' | translate">
        <ng-template #template let-data>
          <span class="font-weight-bold text-primary">{{ data?.key | translate }}</span>
        </ng-template>
      </e-column>

      <e-column field="getData" [headerText]="'my_doctor_resources' | translate">
        <ng-template #template let-data>
          <div class="d-flex flex-column">
            <div>
              {{ data?.key | translate }}:
              <span class="text-primary text-weight-600">{{ data?.countMain }}</span>
            </div>
            <div>{{ data?.errorMessage }}</div>
          </div>
        </ng-template>
      </e-column>
    </e-columns>
  </ejs-grid>
</div>
