import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { SubscriptionsEffects } from './subscriptions.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([SubscriptionsEffects])
  ],
})
export class MedrecordSubscriptionsModule {}
