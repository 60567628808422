import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllergiesManagerStateModule } from './state-management/allergies-manager-state.module';
import { AllergiesManagerService } from './services/allergies-manager.service';

@NgModule({
  imports: [
    CommonModule,
    AllergiesManagerStateModule
  ],
  providers: [
    AllergiesManagerService
  ]
})
export class AllergiesManagerModule {}
