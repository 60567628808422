import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { getMainUserTabData, UserTabData } from '@medrecord/managers-users';
import { StorageKeys } from '@medrecord/core';
import { ItemModel, MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { MEDSAFE_ROUTE_NAMES, MedsafeRouteNames } from '@medrecord/routes-medsafe';
import { StorageService } from '@medrecord/tools-storage';
import { AuthStorageItem, logout, Role } from '@medrecord/managers-auth';
import { GENERAL_ROUTE_NAMES, GeneralRouteNames } from '@medrecord/routes-general';
import { TranslateService } from '@ngx-translate/core';
import { activateFeedbackAction } from '@managers/feedback-manager';

@Component({
  selector: 'medsafe-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
})
export class MenuDropdownComponent implements OnInit {
  @Input() switchRoles: Role[] = [Role.Administrator];
  @Input() customItems: ItemModel[] = [];
  @Output() onCustomItemClick = new EventEmitter<ItemModel>();

  profileData$ = this.store.select(getMainUserTabData);
  profileData: UserTabData = null;

  authInfo: AuthStorageItem = this.storage.getItem<AuthStorageItem>(StorageKeys.Auth);

  items: ItemModel[] = [];

  constructor(
    private store: Store,
    private router: Router,
    private translateService: TranslateService,
    @Inject(MEDSAFE_ROUTE_NAMES) private medsafeRouteNames: MedsafeRouteNames,
    @Inject(GENERAL_ROUTE_NAMES) private generalRouteNames: GeneralRouteNames,
    private storage: StorageService<StorageKeys>
  ) {
    this.profileData$.subscribe((profile) => (this.profileData = profile));

    translateService.onLangChange.subscribe(() => {
      this.setItems();
    });
  }

  ngOnInit(): void {
    this.setItems();
  }

  setItems(): void {
    this.items = this.customItems.length
      ? this.customItems
      : [
          ...(!this.authInfo?.roles.includes(Role.Patient)
            ? [{ text: this.translateService.instant('profile_title'), id: 'PROFILE' }]
            : []),
          ...(this.authInfo?.roles.includes(Role.Doctor)
            ? [
                {
                  text: 'Pin user',
                  id: 'PIN_USER',
                },
              ]
            : []),
          ...(this.authInfo?.roles.includes(Role.Administrator) && this.switchRoles.includes(Role.Administrator)
            ? [
                {
                  text: this.translateService.instant('go_to', {
                    role: this.translateService.instant('role_admin'),
                  }),
                  id: 'OPEN_ADMIN_PANEL',
                },
              ]
            : []),
          ...(this.authInfo?.roles.includes(Role.Patient) && this.switchRoles.includes(Role.Patient)
            ? [
                {
                  text: this.translateService.instant('go_to', {
                    role: this.translateService.instant('role_patient'),
                  }),
                  id: 'OPEN_PATIENT',
                },
              ]
            : []),
          ...(this.authInfo?.roles.includes(Role.Buddy) && this.switchRoles.includes(Role.Buddy)
            ? [
                {
                  text: this.translateService.instant('go_to', {
                    role: this.translateService.instant('role_buddy'),
                  }),
                  id: 'OPEN_BUDDY',
                },
              ]
            : []),
          {
            text: this.translateService.instant('feedback_feedback'),
            id: 'FEEDBACK',
          },
          {
            text: this.translateService.instant('settings_title'),
            id: 'SETTINGS',
          },
          {
            separator: true,
          },
          {
            text: this.translateService.instant('profile_logout'),
            id: 'LOGOUT',
          },
        ];
  }

  selectItem(event: MenuEventArgs): void {
    switch (event.item.id) {
      case 'PROFILE':
        this.router.navigate([
          this.entryRoute,
          ...(this.authInfo?.roles.includes(Role.Patient) ? [this.profileData.id] : []),
          this.medsafeRouteNames.Profile.Entry,
        ]);
        break;

      case 'OPEN_ADMIN_PANEL':
        this.router.navigate([this.generalRouteNames.Admin.Entry]);
        break;
      case 'OPEN_PATIENT':
        this.router.navigate([this.generalRouteNames.Patient.Entry]);
        break;
      case 'OPEN_BUDDY':
        this.router.navigate([this.generalRouteNames.Patient.Entry]);
        break;
      case 'FEEDBACK':
        this.store.dispatch(activateFeedbackAction());
        break;
      case 'SETTINGS':
        this.router.navigate([this.generalRouteNames.Settings.Entry]);
        break;
      case 'LOGOUT':
        this.store.dispatch(logout());
        break;
      default:
        this.onCustomItemClick.emit(event.item);
    }
  }

  get entryRoute(): string {
    switch (this.router.url.split('/')[1]) {
      case this.generalRouteNames.Medsafe.Entry:
        return this.medsafeRouteNames.Entry;
      case this.generalRouteNames.Patient.Entry:
        return this.generalRouteNames.Patient.Entry;
      case this.generalRouteNames.Doctor.Entry:
        return this.generalRouteNames.Doctor.Entry;
      default:
        return this.generalRouteNames.Admin.Entry;
    }
  }
}