import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-chevron-down',
  templateUrl: './chevron-down.component.svg',
  styleUrls: ['./chevron-down.component.scss'],
})
export class ChevronDownComponent extends MedrecordIcon {
  viewHeight = 8;
  viewWidth = 13;
}
