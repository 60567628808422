import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CareplanLinkCategories } from '@managers/careplans-manager';
import { EnvService } from '@medrecord/core';
import { Observable } from 'rxjs';

@Injectable()
export class CareplanService {
  linkCareplan(userId: string, category: CareplanLinkCategories, ids: string[], careplanId: string): Observable<any> {
    return this.http.post<any>(`${this.envService.backend}/patient/${userId}/careplan/${careplanId}/link`, {
      ids: ids,
      category: category,
    });
  }

  unlinkCareplan(userId: string, category: CareplanLinkCategories, ids: string[], careplanId: string): Observable<any> {
    return this.http.post<any>(`${this.envService.backend}/patient/${userId}/careplan/${careplanId}/unlink`, {
      ids: ids,
      category: category,
    });
  }

  constructor(private http: HttpClient, private envService: EnvService) {}
}
