import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'medrecord-ui-back-icon-button',
  templateUrl: './back-icon-button.component.html',
})
export class BackIconButtonComponent {
  @Input() tooltip = 'common_back';
  @Output() onBack: EventEmitter<void> = new EventEmitter<void>();

  constructor(private location: Location) {}

  goBack(): void {
    if (this.onBack.observers.length) this.onBack.emit();
    else this.location.back();
  }
}
