import { createAction, props } from '@ngrx/store';
import { MedicationsLoadPayload } from '../models/interfaces/medications-load-payload.interface';
import { Medication } from '../models/interfaces/medication.interface';

export const loadMedicationsAction = createAction(
  '[Medications Actions] Load Medications',
  props<{ payload: MedicationsLoadPayload }>()
);

export const loadMedicationsSuccessAction = createAction(
  '[Medications Actions] Load Medications Success',
  props<{ medications: Medication[]; count: number }>()
);

export const loadCareplanMedicationsSuccessAction = createAction(
  '[Medications Actions] Load Careplan Medications Success',
  props<{ medications: Medication[]; count: number }>()
);

export const linkCareplanMedicationsSuccessAction = createAction(
  '[Medications Actions] Link Careplan Medications Success Action',
  props<{ medications: Medication[] }>()
);

export const loadMedicationsFailureAction = createAction(
  '[Medications Actions] Load Medications Failure',
  props<{ error: any }>()
);

export const loadMedicationAction = createAction('[Medications Actions] Load Medication', props<{ id: string }>());

export const loadMedicationSuccessAction = createAction(
  '[Medications Actions] Load Medication Success',
  props<{ medication: Medication }>()
);

export const loadPrescribedMedicationAction = createAction(
  '[Medications Actions] Load Prescribed Medication Action',
  props<{ id: string }>()
);

export const loadPrescribedMedicationSuccessAction = createAction(
  '[Medications Actions] Load Prescribed Medication Success Action',
  props<{ medication: Medication }>()
);

export const addMedicationAction = createAction(
  '[Medications Actions] Add Medication',
  props<{ medication: Medication }>()
);

export const addMedicationSuccessAction = createAction(
  '[Medications Actions] Add Medication Success',
  props<{ medication: Medication }>()
);

export const deleteMedicationAction = createAction('[Medications Actions] Delete Medication', props<{ id: string }>());

export const deleteMedicationSuccessAction = createAction(
  '[Medications Actions] Delete Medication Success',
  props<{ id: string }>()
);

export const editMedicationAction = createAction(
  '[Medications Actions] Edit Medication',
  props<{ medication: Medication }>()
);

export const editMedicationSuccessAction = createAction(
  '[Medications Actions] Edit Medication Success',
  props<{ medication: Medication }>()
);

export const clearActiveMedication = createAction('[Medications Actions] Clear active medication');

export const removeCareplanMedicationAction = createAction(
  '[Medications Actions] Remove Care Plan Medication',
  props<{ id: string }>()
);

export const clearPrescribedMedication = createAction('[Medications Actions] Clear Prescribed Medication');

export const loadUsedMedicationAction = createAction(
  '[Medications Actions] Load Used Medication Action',
  props<{ id: string }>()
);

export const loadUsedMedicationSuccessAction = createAction(
  '[Medications Actions] Load Used Medication Success Action',
  props<{ medication: Medication }>()
);
