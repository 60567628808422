import { Component, Input, OnInit } from '@angular/core';
import { PullTransaction, TestCareproviderModalData } from '@managers/my-doctor';
import { FormBuilder } from '@angular/forms';
import { ValidationService } from '@medrecord/services-form-validation';
import { TranslateService } from '@ngx-translate/core';
import { AbstractFormModalComponent } from '../abstract-form-modal/abstract-form-modal.component';

@Component({
  selector: 'doctor-gui-test-careprovider-modal',
  templateUrl: 'test-careprovider-modal.component.html'
})
export class TestCareproviderModalComponent extends AbstractFormModalComponent<TestCareproviderModalData> implements OnInit {
  @Input() transactionId: PullTransaction;

  private readonly requiredMsg = this.translateService.instant('common_validation_required');

  constructor(
    private formBuilder: FormBuilder,
    private formValidatorsService: ValidationService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  formSubmit(): void {
    this.modalSubmitted.emit({ ...this.form.value });
  }

  private initializeForm(): void {
    this.form = this.formBuilder.group({
      token: [null, [this.formValidatorsService.required(this.requiredMsg)]],
      userKey: [null, [this.formValidatorsService.required(this.requiredMsg)]],
      careProviderUrl: [null, [this.formValidatorsService.required(this.requiredMsg)]]
    });
  }
}
