import { CareproviderDataService } from '..';

// BE: GetCareProviderPayload
export interface Careprovider {
  id: string;
  displayName: string;
  speciality: string;
  phone: string;
  email: string;
  agb: string;
  ura: string;
  oin: string;
  hrn: string;
  addresses: CareproviderAddress[];
  dataServices: CareproviderDataService[];
  userScope: boolean;
}

// BE: GetCareProviderAddress
interface CareproviderAddress {
  address: string;
  city: string;
  country: string;
  firstLine: string;
  geolocation: string;
  postalCode: string;
  state: string;
  streetName: string;
  streetNumber: string;
  type: string;
}

export function getCareproviderName(careprovider: Careprovider): string {
  return careprovider?.displayName || careprovider?.id || careprovider?.email;
}
