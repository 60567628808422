import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { navigateAction } from './navigation.actions';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class NavigationEffects {
  navigate$ = createEffect(
    () => this.actions$.pipe(
      ofType(navigateAction),
      switchMap((action) => {
        const { path, extras } = action;
        
        this.router.navigate(path, extras);
        
        return [];
      })
    )
  );
  
  constructor(
    private actions$: Actions,
    private router: Router
  ) {}
}