import { on } from '@ngrx/store';
import {
  fetchResourcesCompletedAction,
  setAfterFetchingResourcesReturnToAction,
  setFetchResourcesRequestPayloadAction
} from '../actions';
import { State } from '../my-doctor-manager.reducer';

export const medmijInteractionReducer = [
  on(setAfterFetchingResourcesReturnToAction, (state: State, { detailsRoute }) => ({
    ...state,
    afterFetchingResourcesReturnTo: detailsRoute
  })),

  on(setFetchResourcesRequestPayloadAction, (state: State, { fetchResourceRequest }) => ({
    ...state,
    fetchResourceRequest
  })),

  on(fetchResourcesCompletedAction, (state: State, { responsePayload }) => ({
    ...state,
    fetchResourceResponse: responsePayload
  }))
];
