import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { ConditionsManagerService } from '../services/conditions-manager.service';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectCurrentID } from '@medrecord/managers-users';
import { Store } from '@ngrx/store';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import {
  loadConditionsAction,
  loadConditionsSuccessAction,
  loadConditionsFailureAction,
  loadConditionSuccessAction,
  addConditionAction,
  addConditionSuccessAction,
  editConditionAction,
  editConditionSuccessAction,
  loadConditionAction,
  deleteConditionAction,
  deleteConditionSuccessAction,
  loadCareplanConditionsSuccessAction,
} from './conditions-manager.actions';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ConditionsManagerEffects {
  @Effect()
  loadConditions$ = this.actions$.pipe(
    ofType(loadConditionsAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([{ payload }, userId]) => {
      return forkJoin({
        conditions: this.service.loadConditions(userId, { ...payload }),
        count: this.service.loadConditionsCount(userId),
      }).pipe(
        switchMap(({ conditions, count }) => {
          return payload.carePlanId
            ? [loadCareplanConditionsSuccessAction({ conditions, count })]
            : [loadConditionsSuccessAction({ conditions, count })];
        }),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_conditions_error'), error)),
          loadConditionsFailureAction({ error }),
        ])
      );
    })
  );

  loadCondition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadConditionAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([payload, userID]) =>
        this.service
          .loadCondition(payload.id, userID)
          .pipe(map((condition) => loadConditionSuccessAction({ condition })))
      ),
      catchError((error: any) => [
        addErrorToast(getErrorToastBodyUtil('load_conditions_error', error)),
        loadConditionsFailureAction({ error }),
      ])
    )
  );

  addCondition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addConditionAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([payload, userID]) =>
        this.service.createCondition(userID, payload.condition).pipe(
          map((condition) => {
            this.store.dispatch(
              addSuccessToast({
                title: 'condition_add_success_title',
                content: 'condition_add_success_content',
              })
            );
            return addConditionSuccessAction({ condition });
          })
        )
      ),
      catchError((error: any) => [
        addErrorToast(getErrorToastBodyUtil('add_condition_error', error)),
        loadConditionsFailureAction({ error }),
      ])
    )
  );

  editCondition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editConditionAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([payload, userID]) =>
        this.service.updateCondition(userID, payload.condition.id, payload.condition).pipe(
          map((condition) => {
            this.store.dispatch(
              addSuccessToast({
                title: 'condition_edit_success_title',
                content: 'condition_edit_success_content',
              })
            );
            return editConditionSuccessAction({ condition });
          })
        )
      ),
      catchError((error: any) => [
        addErrorToast(getErrorToastBodyUtil('edit_condition_error', error)),
        loadConditionsFailureAction({ error }),
      ])
    )
  );

  deleteCondition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteConditionAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([{ id }, userId]) =>
        this.service.deleteCondition(userId, id).pipe(
          map(() => {
            this.store.dispatch(
              addSuccessToast({
                title: 'condition_delete_success_title',
                content: 'condition_delete_success_content',
              })
            );
            return deleteConditionSuccessAction({ id });
          })
        )
      ),
      catchError((error: any) => [
        addErrorToast(getErrorToastBodyUtil('delete_condition_error', error)),
        loadConditionsFailureAction({ error }),
      ])
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private service: ConditionsManagerService,
    private translateService: TranslateService
  ) {}
}
