import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealthplansManagerStateModule } from './state-management/healthplans-manager-state.module';
import { HealthplansManagerService } from './services/healthplans-manager.service';

@NgModule({
  imports: [CommonModule, HealthplansManagerStateModule],
  providers: [HealthplansManagerService],
})
export class HealthplansManagerModule {}
