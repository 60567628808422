import { Provider } from '@angular/core';

export * from './user-data-keys.constant';
import * as pinConstants from './pin.constant';
import { USER_MANAGEMENT_CONSTANTS } from '../users-manager.tokens';

export const constants = {
  ...pinConstants
};

export type UserManagementConstants = typeof constants;

export const provideUserManagementConstants = (): Provider => [
  {
    provide: USER_MANAGEMENT_CONSTANTS,
    useValue: constants
  }
];