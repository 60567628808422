import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService, Language, StorageKeys } from '@medrecord/core';
import { Observable } from 'rxjs';
import { LoadPostsPayload, LoadTagsPayload, PostResponse, TagResponse } from '../models/interfaces';
import { StorageService } from '@medrecord/tools-storage';
import { FilterBuilder } from '../utils/filter-builder.util';

@Injectable()
export class NewsManagerService {
  private readonly _baseUrl: string = `${this.envService.apiGateway}/cms-proxy`;
  private readonly _healthPlanCategoryParam: string = 'healthPlanCategory';

  constructor(private http: HttpClient, private envService: EnvService, private storage: StorageService<StorageKeys>) {}

  getCareplanPosts(filter: string, healthPlanCategory: string, isFormatHtml: boolean = true): Observable<PostResponse> {
    const payload: LoadPostsPayload = {
      filter,
      include: `tags,authors`,
      page: 1,
      ...(isFormatHtml ? { formats: 'html' } : {}),
    };
    return this.getPostsFromBe(payload, healthPlanCategory);
  }

  getPosts(filter: string): Observable<PostResponse> {
    const payload: LoadPostsPayload = {
      filter,
      include: `tags,authors`,
      page: 1,
      formats: 'html',
    };
    return this.getPostsFromBe(payload, this.envService.cmsCategory);
  }

  getFeaturedPosts(filter: string): Observable<PostResponse> {
    const payload: LoadPostsPayload = {
      filter,
      fields: `id,published_at,title,feature_image,featured`,
      limit: 5,
      page: 1,
    };
    return this.getPostsFromBe(payload, this.envService.cmsCategory);
  }

  private getPostsFromBe(payload: LoadPostsPayload, healthPlanCategory: string): Observable<PostResponse> {
    const lang = this.storage.getItem(StorageKeys.Language, Language.EN);
    payload.filter = FilterBuilder.existing(payload.filter).addHashTag(lang).build();

    const ghostParams = [];

    ghostParams.push(this._healthPlanCategoryParam + '=' + encodeURIComponent(healthPlanCategory));
    Object.keys(payload).forEach((key) => {
      ghostParams.push(key + '=' + encodeURIComponent(payload[key]));
    });

    return this.http.get<PostResponse>(this._baseUrl + '/posts/?' + ghostParams.join('&'));
  }

  getPost(id: string): Observable<PostResponse> {
    return this.getPostFromBe(id, this.envService.cmsCategory);
  }

  getCareplanPost(id: string, healthPlanCategory: string): Observable<PostResponse> {
    return this.getPostFromBe(id, healthPlanCategory);
  }

  getPostFromBe(id: string, healthPlanCategory: string): Observable<PostResponse> {
    const payload: LoadPostsPayload = {
      fields: `id,published_at,title,feature_image,featured`,
      include: `tags,authors`,
      formats: 'html',
    };

    const ghostParams = [];

    ghostParams.push(this._healthPlanCategoryParam + '=' + encodeURIComponent(healthPlanCategory));
    Object.keys(payload).forEach((key) => {
      ghostParams.push(key + '=' + encodeURIComponent(payload[key]));
    });

    return this.http.get<PostResponse>(this._baseUrl + '/posts/' + id + '/?' + ghostParams.join('&'));
  }

  getTags(): Observable<TagResponse> {
    return this.getTagsFromBe(this.envService.cmsCategory);
  }

  getCareplanTags(healthPlanCategory: string): Observable<TagResponse> {
    return this.getTagsFromBe(healthPlanCategory);
  }

  private getTagsFromBe(healthPlanCategory: string): Observable<TagResponse> {
    let payload: LoadTagsPayload = {
      filter: `visibility:public`,
      include: `count.posts,accent_color`,
    };

    const BLACKLIST_TAGS = ['android', 'ios', 'test'];

    const ghostParams = [];

    ghostParams.push(this._healthPlanCategoryParam + '=' + encodeURIComponent(healthPlanCategory));
    Object.keys(payload).forEach((key) => {
      if (key === 'filter') {
        payload = {
          ...payload,
          [key]: `${payload[key]}+slug:-${BLACKLIST_TAGS.join('+slug:-')}`,
        };
      }
      ghostParams.push(key + '=' + encodeURIComponent(payload[key]));
    });

    return this.http.get<TagResponse>(this._baseUrl + '/tags/?' + ghostParams.join('&'));
  }
}
