import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MailComponent } from './icons/mail/mail.component';
import { LockComponent } from './icons/lock/lock.component';
import { EyeComponent } from './icons/eye/eye.component';
import { EyeCrossedComponent } from './icons/eye-crossed/eye-crossed.component';
import { WarningComponent } from './icons/warning/warning.component';
import { SuccessComponent } from './icons/success/success.component';
import { InfoComponent } from './icons/info/info.component';
import { ErrorComponent } from './icons/error/error.component';
import { ChevronUpComponent } from './icons/chevron-up/chevron-up.component';
import { ChevronDownComponent } from './icons/chevron-down/chevron-down.component';
import { ArrowRightComponent } from './icons/arrow-right/arrow-right.component';
import { ArrowLeftComponent } from './icons/arrow-left/arrow-left.component';
import { PaginationArrowLeftComponent } from './icons/pagination-arrow-left/pagination-arrow-left.component';
import { PaginationArrowRightComponent } from './icons/pagination-arrow-right/pagination-arrow-right.component';
import { ShieldComponent } from './icons/shield/shield.component';
import { AtomComponent } from './icons/atom/atom.component';
import { CalendarComponent } from './icons/calendar/calendar.component';
import { PhonendoscopeComponent } from './icons/phonendoscope/phonendoscope.component';
import { ChatComponent } from './icons/chat/chat.component';
import { CheckComponent } from './icons/check/check.component';
import { QuestionComponent } from './icons/question/question.component';
import { CrossComponent } from './icons/cross/cross.component';
import { ChevronLeftComponent } from './icons/chevron-left/chevron-left.component';
import { ChevronRightComponent } from './icons/chevron-right/chevron-right.component';
import { PlusComponent } from './icons/plus/plus.component';
import { PlusRoundedComponent } from './icons/plus-rounded/plus-rounded.component';
import { CrossRoundedComponent } from './icons/cross-rounded/cross-rounded.component';
import { UploadAvatarComponent } from './icons/upload-avatar/upload-avatar.component';
import { CircleArrowRightComponent } from './icons/circle-arrow-right/circle-arrow-right.component';
import { CircleArrowLeftComponent } from './icons/circle-arrow-left/circle-arrow-left.component';
import { DeviceComponent } from './icons/device/device.component';
import { ListComponent } from './icons/list/list.component';
import { MixtureComponent } from './icons/mixture/mixture.component';
import { PersonComponent } from './icons/person/person.component';
import { FirstAidKitComponent } from './icons/first-aid-kit/first-aid-kit.component';
import { PenComponent } from './icons/pen/pen.component';
import { PenRoundedComponent } from './icons/pen-rounded/pen-rounded.component';
import { FacebookComponent } from './icons/clubdiabetes/facebook/facebook.component';
import { CommunityComponent } from './icons/clubdiabetes/community/community.component';
import { InstaComponent } from './icons/clubdiabetes/insta/insta.component';
import { LocationComponent } from './icons/clubdiabetes/location/location.component';
import { EmailComponent } from './icons/clubdiabetes/email/email.component';
import { PhoneComponent } from './icons/clubdiabetes/phone/phone.component';
import { WhatsappComponent } from './icons/clubdiabetes/whatsapp/whatsapp.component';
import { YoutubeComponent } from './icons/clubdiabetes/youtube/youtube.component';
import { GlobeComponent } from './icons/clubdiabetes/globe/globe.component';
import { NoteComponent } from './icons/clubdiabetes/note/note.component';
import { BasicMedicalDataComponent } from './icons/medsafe/basic-medical-data/basic-medical-data.component';
import { CollapseComponent } from './icons/medsafe/collapse/collapse.component';
import { ExternalServicesComponent } from './icons/medsafe/external-services/external-services.component';
import { HealthplansComponent } from './icons/medsafe/healthplans/healthplans.component';
import { MeasurementsComponent } from './icons/medsafe/measurements/measurements.component';
import { NetworkComponent } from './icons/medsafe/network/network.component';
import { QuestionnairesComponent } from './icons/medsafe/questionnaires/questionnaires.component';
import { DashboardComponent } from './icons/medsafe/dashboard/dashboard.component';
import { NewsComponent } from './icons/medsafe/news/news.component';
import { MedsafeChatComponent } from './icons/medsafe/chat/chat.component';
import { AddComponent } from './icons/add/add.component';
import { MedmijVerifiedComponent, MyDoctorComponent, PhotoComponent } from './icons';
import { WeightComponent } from './icons/measurements/weight/weight.component';
import { HeightComponent } from './icons/measurements/height/height.component';
import { RespiratoryRateComponent } from './icons/measurements/respiratory-rate/respiratory-rate.component';
import { ActiveMinutesComponent } from './icons/measurements/active-minutes/active-minutes.component';
import { AlcoholUseComponent } from './icons/measurements/alcohol-use/alcohol-use.component';
import { PulseRateComponent } from './icons/measurements/pulse-rate/pulse-rate.component';
import { BloodGlucoseComponent } from './icons/measurements/blood-glucose/blood-glucose.component';
import { HeartMinutesComponent } from './icons/measurements/heart-minutes/heart-minutes.component';
import { WaistCircumferenceComponent } from './icons/measurements/waist-circumference/waist-circumference.component';
import { MoveMinutesComponent } from './icons/measurements/move-minutes/move-minutes.component';
import { WaistHeightRatioComponent } from './icons/measurements/waist-height-ratio/waist-height-ratio.component';
import { OxygenSaturationComponent } from './icons/measurements/oxygen-saturation/oxygen-saturation.component';
import { BmiComponent } from './icons/measurements/bmi/bmi.component';
import { BodyTemperatureComponent } from './icons/measurements/body-temperature/body-temperature.component';
import { BloodPressureComponent } from './icons/measurements/blood-pressure/blood-pressure.component';
import { StepCounterComponent } from './icons/measurements/step-counter/step-counter.component';
import { BodyFatPercentageComponent } from './icons/measurements/body-fat-percentage/body-fat-percentage.component';
import { SleepingComponent } from './icons/measurements/sleeping/sleeping.component';
import { MedicationAlertComponent } from './icons/medication-alert/medication-alert.component';
import { AllergyHandComponent } from './icons/allergy-hand/allergy-hand.component';

const icons = [
  MailComponent,
  LockComponent,
  EyeComponent,
  EyeCrossedComponent,
  WarningComponent,
  SuccessComponent,
  InfoComponent,
  ErrorComponent,
  ChevronUpComponent,
  ChevronDownComponent,
  ArrowRightComponent,
  ArrowLeftComponent,
  PaginationArrowLeftComponent,
  PaginationArrowRightComponent,
  ShieldComponent,
  AtomComponent,
  CalendarComponent,
  PhonendoscopeComponent,
  ChatComponent,
  CheckComponent,
  QuestionComponent,
  CrossComponent,
  ChevronLeftComponent,
  ChevronRightComponent,
  PlusComponent,
  PlusRoundedComponent,
  CrossRoundedComponent,
  CircleArrowRightComponent,
  CircleArrowLeftComponent,
  DeviceComponent,
  ListComponent,
  MixtureComponent,
  PersonComponent,
  FirstAidKitComponent,
  UploadAvatarComponent,
  PenRoundedComponent,
  PenComponent,
  FacebookComponent,
  CommunityComponent,
  InstaComponent,
  LocationComponent,
  EmailComponent,
  PhoneComponent,
  WhatsappComponent,
  YoutubeComponent,
  GlobeComponent,
  NoteComponent,
  BasicMedicalDataComponent,
  CollapseComponent,
  ExternalServicesComponent,
  HealthplansComponent,
  MeasurementsComponent,
  NetworkComponent,
  QuestionnairesComponent,
  DashboardComponent,
  NewsComponent,
  MedsafeChatComponent,
  AddComponent,
  MyDoctorComponent,
  MedmijVerifiedComponent,
  PhotoComponent,
  BmiComponent,
  BodyTemperatureComponent,
  BloodPressureComponent,
  StepCounterComponent,
  BodyFatPercentageComponent,
  WeightComponent,
  HeightComponent,
  RespiratoryRateComponent,
  ActiveMinutesComponent,
  AlcoholUseComponent,
  PulseRateComponent,
  BloodGlucoseComponent,
  HeartMinutesComponent,
  WaistCircumferenceComponent,
  MoveMinutesComponent,
  WaistHeightRatioComponent,
  OxygenSaturationComponent,
  SleepingComponent,
  MedicationAlertComponent,
  AllergyHandComponent
];

@NgModule({
  imports: [CommonModule],
  declarations: [...icons],
  exports: [...icons],
})
export class MedrecordIconsModule {}
