import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { networkManagerFeatureKey, reducer } from './network-manager.reducer';
import { EffectsModule } from '@ngrx/effects';
import { NetworkManagerEffects } from './network-manager.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(networkManagerFeatureKey, reducer),
    EffectsModule.forFeature([ NetworkManagerEffects ])
  ]
})
export class NetworkManagerStateModule {}