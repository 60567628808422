import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import {
  addAllergy,
  addAllergySuccess,
  deleteAllergyAction,
  deleteAllergySuccessAction,
  editAllergy,
  editAllergySuccess,
  loadAllergiesAction,
  loadAllergiesFailureAction,
  loadAllergiesSuccessAction,
  loadAllergyAction,
  loadAllergySuccessAction,
  loadAutocompleteAllergyNames,
  loadAutocompleteAllergyNamesSuccess,
  loadCareplanAllergiesSuccessAction,
} from './allergies-manager.actions';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AllergiesManagerService } from '../services/allergies-manager.service';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { selectCurrentID } from '@medrecord/managers-users';
import { ValueSet } from '../models/enums/value-set.enum';
import { TerminologyService } from '../services/terminology.service';
import { forkJoin } from 'rxjs';

@Injectable()
export class AllergiesManagerEffects {
  @Effect()
  loadAllergies$ = this.actions$.pipe(
    ofType(loadAllergiesAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    mergeMap(([payload, userId]) => {
      return forkJoin({
        allergies: this.allergiesMangerService.loadAllergies({ ...payload.loadPayload }, userId),
        count: this.allergiesMangerService.loadAllergiesCount(userId),
      }).pipe(
        switchMap(({ allergies, count }) => {
          return payload.loadPayload.carePlanId
            ? [loadCareplanAllergiesSuccessAction({ userId: userId, allergies, count })]
            : [loadAllergiesSuccessAction({ userId: userId, allergies, count })];
        }),
        catchError((error: any) => [
          addErrorToast(getErrorToastBodyUtil(this.translateService.instant('load_allergies_error'), error)),
          loadAllergiesFailureAction({ error }),
        ])
      );
    })
  );

  loadAllergy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllergyAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([action, userId]) => {
        return this.allergiesMangerService.loadAllergy(action.id, userId).pipe(
          map((response) => loadAllergySuccessAction({ allergy: response })),
          catchError(({ error }) => [loadAllergiesFailureAction({ error })])
        );
      })
    )
  );

  deleteAllergy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAllergyAction),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([action, userId]) => {
        return this.allergiesMangerService.deleteAllergy(action.id, userId).pipe(
          map(() => {
            this.store.dispatch(
              addSuccessToast({
                title: 'allergy_delete_success_title',
                content: 'allergy_delete_success_content',
              })
            );
            return deleteAllergySuccessAction({ id: action.id });
          }),
          catchError(({ error }) => [
            addErrorToast(
              getErrorToastBodyUtil(
                this.translateService.instant(error?.error?.message ? error.error.message : 'load_allergies_error'),
                error
              )
            ),
            loadAllergiesFailureAction({ error }),
          ])
        );
      })
    )
  );

  loadAutocompleteAllergyNames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAutocompleteAllergyNames),
      switchMap(({ query }) => {
        return this.terminologyService.searchByValueSet(ValueSet.allergySubstanceCodeList, query).pipe(
          map((allergyNames) => loadAutocompleteAllergyNamesSuccess({ allergyNames })),
          catchError(({ error }) => [loadAllergiesFailureAction({ error })])
        );
      })
    )
  );

  addAllergy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAllergy),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([action, userId]) => {
        return this.allergiesMangerService.createAllergy(userId, action.allergy).pipe(
          map((allergy) => {
            this.store.dispatch(
              addSuccessToast({
                title: 'allergy_create_success_title',
                content: 'allergy_create_success_content',
              })
            );
            return addAllergySuccess({ allergy });
          }),
          catchError(({ error }) => [
            addErrorToast(
              getErrorToastBodyUtil(
                this.translateService.instant(error?.error?.message ? error.error.message : 'load_allergies_error'),
                error
              )
            ),
            loadAllergiesFailureAction({ error }),
          ])
        );
      })
    )
  );

  editAllergy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editAllergy),
      withLatestFrom(this.store.select(selectCurrentID)),
      switchMap(([action, userId]) => {
        return this.allergiesMangerService.updateAllergy(userId, action.allergyId, action.allergy).pipe(
          map((allergy) => {
            this.store.dispatch(
              addSuccessToast({
                title: 'allergy_edit_success_title',
                content: 'allergy_edit_success_content',
              })
            );
            return editAllergySuccess({ allergy });
          }),
          catchError(({ error }) => [
            addErrorToast(
              getErrorToastBodyUtil(
                this.translateService.instant(error?.error?.message ? error.error.message : 'load_allergies_error'),
                error
              )
            ),
            loadAllergiesFailureAction({ error }),
          ])
        );
      })
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private translateService: TranslateService,
    private terminologyService: TerminologyService,
    private allergiesMangerService: AllergiesManagerService
  ) {}
}
