import { Component, ViewEncapsulation } from '@angular/core';
import { AnimationSettingsModel, Dialog } from '@syncfusion/ej2-angular-popups';
import { TranslateService } from '@ngx-translate/core';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { PrivacyPolicyAbstractComponent } from '../../ui-abstractions';

@Component({
  selector: 'medrecord-ui-privacy-policy-dialog',
  template: '',
  encapsulation: ViewEncapsulation.None
})
export class PrivacyPolicyDialogComponent extends PrivacyPolicyAbstractComponent {
  declare dialogUtility: Dialog
  constructor(
    public translateService: TranslateService,
  ) {
    super(translateService);
  }
  
  readonly animationSettings: AnimationSettingsModel = {
    effect: 'Zoom',
    duration: 400,
    delay: 0
  };
  
  openDialog(){
    this.dialogUtility=DialogUtility.alert({
      title: this.translateService.instant('auth_terms_link_text'),
      content: this.getDialogHTML(),
      showCloseIcon: false,
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' },
    })
  }

  
  private getDialogHTML(): HTMLElement {
    const wrapper = document.createElement('div');
    
    wrapper.classList.add('terms-and-conditions');
    
    this.statements.forEach((statement) => {
      const statementDiv = document.createElement('div');
      const statementH5 = document.createElement('h5');
      const statementParagraph = document.createElement('p');
  
      statementParagraph.classList.add('text-sm');
  
      statementH5.innerHTML = statement.header;
      statementParagraph.innerHTML = statement.content;
      
      statementDiv.appendChild(statementH5);
      statementDiv.appendChild(statementParagraph);
      
      wrapper.appendChild(statementDiv);
    });
    
    return wrapper;
  }
}