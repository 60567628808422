import { on } from '@ngrx/store';
import { getInitialEnabledState, getQuestionCompletionStatus, removeAnswersForDisabledQuestions } from '../../utils';
import {
  clearQuestionnaire,
  loadQuestionnaire,
  loadQuestionnaireFailure,
  loadQuestionnaireSuccess,
  questionnaireLoaded,
  saveQuestionAnswer,
  selectNextQuestion,
  selectPrevQuestion,
  selectQuestion,
  setIDKForQuestion
} from '../actions/questionnaire.actions';
import { State } from '../questionnaires.reducer';
import { Question } from '@managers/questionnaires';

export const questionnaireReducers = [
  on(loadQuestionnaire, (state: State, { questionnaireId }) => ({
    ...state,
    questionnairesData: {
      ...state.questionnairesData,
      [questionnaireId]: {
        isLoading: true,
        isLoaded: false,
        error: null,
        resource: null,
        answers: null,
      },
    },
  })),
  on(loadQuestionnaireSuccess, (state: State, { questionnaireId, questionnaireData, answers }) => ({
    ...state,
    questionnairesData: {
      ...state.questionnairesData,
      [questionnaireId]: {
        isLoading: false,
        isLoaded: true,
        error: null,
        resource: questionnaireData,
        answers: answers,
      },
    },
  })),
  on(loadQuestionnaireFailure, (state: State, { questionnaireId, error }) => ({
    ...state,
    questionnairesData: {
      ...state.questionnairesData,
      [questionnaireId]: {
        isLoading: false,
        isLoaded: false,
        error,
        resource: null,
        answers: null,
      },
    },
  })),
  on(questionnaireLoaded, (state: State, { id, questionnaire }) => {
    const { questions, answers } = questionnaire;
    let isFinished = true;

    const questionsWithEnabledState = getInitialEnabledState(questions, answers);
    const preparedQuestions: Question[] = questionsWithEnabledState.map((question) => {
      const { allAnswered: isCompleted, requiredAnswered } = getQuestionCompletionStatus(question, answers);
      const hasIdkAnswer = answers?.[question.id]?.[0]?.value === 'idk';

      isFinished = isFinished && requiredAnswered;

      const result: Question = {
        ...question,
        isCompleted,
        hasIdkAnswer,
      };

      return result;
    });

    const selectedQuestionIndexInOrder = 0;

    return {
      ...state,
      questionnairesMap: {
        ...state.questionnairesMap,
        [id]: {
          ...questionnaire,
          selectedQuestionIndex: 0,
          selectedQuestionIndexInOrder,
          answers: answers,
          isFinished: isFinished,
          questions: preparedQuestions,
        },
      },
    };
  }),
  on(clearQuestionnaire, (state: State, { questionnaireId }) => ({
    ...state,
    questionnairesMap: {
      ...state.questionnairesMap,
      [questionnaireId]: undefined,
    },
  })),
  on(selectQuestion, (state: State, { questionnaireId, questionId }) => {
    const { questions, selectedQuestionIndexInOrder: oldSelectedIndexInOrder } = state.questionnairesMap[
      questionnaireId
    ];
    const questionIndex = questions.findIndex(({ id }) => id === questionId);
    const selectedQuestionIndexInOrder = questionIndex === -1 ? 0 : questionIndex;
    const preparedQuestions = questions.map((question) => handleIdkAnswers(state, questionnaireId, question));
    preparedQuestions[oldSelectedIndexInOrder] = {
      ...preparedQuestions[oldSelectedIndexInOrder],
      isVisited: true,
    };

    return {
      ...state,
      questionnairesMap: {
        ...state.questionnairesMap,
        [questionnaireId]: {
          ...state.questionnairesMap[questionnaireId],
          selectedQuestionIndexInOrder,
          questions: preparedQuestions,
        },
      },
    };
  }),
  on(selectPrevQuestion, (state: State, { questionnaireId }) => {
    const { questions, selectedQuestionIndexInOrder: oldSelectedIndexInOrder } = state.questionnairesMap[
      questionnaireId
    ];
    const selectedQuestionIndexInOrder = Math.max(0, oldSelectedIndexInOrder - 1);
    const preparedQuestions = questions.map((question) => handleIdkAnswers(state, questionnaireId, question));
    preparedQuestions[oldSelectedIndexInOrder] = {
      ...preparedQuestions[oldSelectedIndexInOrder],
      isVisited: true,
    };

    return {
      ...state,
      questionnairesMap: {
        ...state.questionnairesMap,
        [questionnaireId]: {
          ...state.questionnairesMap[questionnaireId],
          selectedQuestionIndexInOrder,
          questions: preparedQuestions,
        },
      },
    };
  }),
  on(selectNextQuestion, (state: State, { questionnaireId }) => {
    const { questions, selectedQuestionIndexInOrder: oldSelectedIndexInOrder } = state.questionnairesMap[
      questionnaireId
    ];
    const selectedQuestionIndexInOrder = Math.min(questions.length - 1, oldSelectedIndexInOrder + 1);
    const preparedQuestions = questions.map((question) => handleIdkAnswers(state, questionnaireId, question));
    preparedQuestions[oldSelectedIndexInOrder] = {
      ...preparedQuestions[oldSelectedIndexInOrder],
      isVisited: true,
    };

    return {
      ...state,
      questionnairesMap: {
        ...state.questionnairesMap,
        [questionnaireId]: {
          ...state.questionnairesMap[questionnaireId],
          selectedQuestionIndexInOrder,
          questions: preparedQuestions,
        },
      },
    };
  }),
  on(saveQuestionAnswer, (state: State, { questionnaireId, answer, questionId }) => {
    const { questions: rawQuestions, answers: rawAnswers } = state.questionnairesMap[questionnaireId];
    const answers = {
      ...rawAnswers,
      [questionId]: answer,
    };

    let isFinished = true;

    const questionsWithEnabledState = getInitialEnabledState(rawQuestions, answers);

    const questions = questionsWithEnabledState.map((question) => {
      const { allAnswered: isCompleted, requiredAnswered } = getQuestionCompletionStatus(question, answers);
      const hasIdkAnswer = answers?.[question.id]?.[0]?.value === 'idk';

      isFinished = isFinished && requiredAnswered;

      const result: Question = {
        ...question,
        isCompleted,
        hasIdkAnswer,
      };

      return result;
    });

    const cleanedAnswers = removeAnswersForDisabledQuestions(questions, answers);

    return {
      ...state,
      questionnairesMap: {
        ...state.questionnairesMap,
        [questionnaireId]: {
          ...state.questionnairesMap[questionnaireId],
          questions,
          answers: cleanedAnswers,
          isFinished,
        },
      },
    };
  }),
  on(setIDKForQuestion, (state: State, { questionnaireId, answer, questionId }) => ({
    ...state,
    questionnairesMap: {
      ...state.questionnairesMap,
      [questionnaireId]: {
        ...state.questionnairesMap[questionnaireId],
        questions: [
          ...state.questionnairesMap[questionnaireId].questions.map((question) => {
            if (question.id !== questionId) {
              return question;
            }

            return {
              ...question,
              options: {
                ...(question as any).options,
                answers: [
                  ...(question as any).options.answers.filter(filterNonIDK),
                  {
                    label: answer,
                    value: 'idk',
                  },
                ],
              },
            };
          }),
        ],
        answers: {
          ...state.questionnairesMap[questionnaireId].answers,
          [questionId]: [
            {
              label: answer,
              value: 'idk',
            },
          ],
        },
      },
    },
  })),
];

const handleIdkAnswers = (state, questionnaireId, question): Question => {
  if (!(state.questionnairesMap[questionnaireId].answers[question.id] instanceof Array)) {
    return question;
  }

  const hasIdkAnswer = state.questionnairesMap[questionnaireId].answers[question.id].some(
    ({ value }) => value === 'idk'
  );

  return {
    ...question,
    options: {
      ...question.options,
      answers: hasIdkAnswer
        ? question.options.answers
        : question.options.answers.filter(({ value }) => value !== 'idk'),
    },
  };
};

const filterNonIDK = ({ value }) => {
  return value !== 'idk';
};
