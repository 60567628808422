<div class="form-group">
  <medrecord-ui-field-label
    for="select"
    [hint]="hint"
    [secondaryText]="secondaryLabel"
    [text]="label"
    [type]="labelType"
    [asterix]="asterix"
  ></medrecord-ui-field-label>

  <ejs-multiselect
    id="multi-select"
    [class.warning]="controlErrorsList.length"
    [class.error]="errorCode || externalErrorsList.length"
    [dataSource]="items"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [name]="name || control?.name"
    [disabled]="disabled"
    [fields]="fields" 
    [allowCustomValue]="allowCustomValue"
  >
    <!-- Hide 'No records found text' -->
    <ng-template #noRecordsTemplate *ngFor="let noRecordsTemplate of noRecordsTemplates">
      <ng-container *ngTemplateOutlet="noRecordsTemplate"></ng-container>
    </ng-template>
  </ejs-multiselect>

  <medrecord-ui-field-error
    [errors]="controlErrorsList"
    [errorCode]="errorCode"
    [externalErrors]="externalErrorsList"
  ></medrecord-ui-field-error>
</div>
