import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const plannedCareActivityDeviceRequestBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.deviceRequest,
  fieldGroups: [
    {
      name: ({ resource }) => resource?.content?.medicalDevice?.display || 'my_doctor_device_request',
      fields: [
        {
          label: 'my_doctor_status',
          fieldName: 'status',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_start_date',
          fieldName: 'period',
          prepareData: (data) => (data?.start ? MedrecordMoment(data?.start).format('LL') : ''),
        },
        {
          label: 'my_doctor_end_date',
          fieldName: 'period',
          prepareData: (data) => (data?.end ? MedrecordMoment(data?.end).format('LL') : ''),
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'comment',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_identification',
          fieldName: 'medicalDevice',
          prepareData: (data, resources) => {
            const device = resources.find((r) => {
              const [referenceType, referenceId] = (data?.reference ?? '').split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return device?.content?.carrierHrf;
          },
        },
        {
          label: 'my_doctor_type',
          fieldName: 'medicalDevice',
          prepareData: (data, resources) => {
            const device = resources.find((r) => {
              const [referenceType, referenceId] = (data?.reference ?? '').split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return device?.content?.productType?.coding?.[0]?.display;
          },
        },
      ],
    },
  ],
});
