import { Component, Input } from '@angular/core';

@Component({
  selector: 'medrecord-ui-block-column',
  templateUrl: './block-column.component.html',
})
export class BlockColumnComponent {
  @Input() value: string;
  @Input() color: string;
}
