<div class="expandable-container w-100" [class.border-bottom-0]="hideBottomBorder">
  <div class="d-flex align-items-center title-container p-2" [class.active]="active" *ngIf="!hideTitle">
    <h4 class="title mb-0">{{ title | titlecase }}</h4>

    <div class="ml-auto d-flex align-items-center">
      <div class="d-flex ml-3">
        <button
          type="button"
          class="expand-btn px-2 btn btn-sm btn-outline-black text-weight-300"
          (click)="expanded = !expanded"
        >
          <medrecord-svg-chevron-up *ngIf="expanded" icon width="14"></medrecord-svg-chevron-up>
          <medrecord-svg-chevron-down *ngIf="!expanded" icon width="14"></medrecord-svg-chevron-down>
        </button>
      </div>
    </div>
  </div>
  <div [class.hidden]="!expanded">
    <ng-content></ng-content>
  </div>
</div>
