import { createAction, props } from '@ngrx/store';
import { ConditionsLoadPayload } from '../models/interfaces/conditions-load-payload.interface';
import { Condition } from '../models/interfaces/condition.interface';

export const loadConditionsAction = createAction(
  '[Conditions Manager Actions] Load Conditions',
  props<{ payload: ConditionsLoadPayload }>()
);

export const loadConditionsSuccessAction = createAction(
  '[Conditions Manager Actions] Load Conditions Success',
  props<{ conditions: Condition[]; count: number }>()
);

export const loadConditionsFailureAction = createAction(
  '[Conditions Manager Actions] Load Conditions Failure',
  props<{ error: any }>()
);

export const loadConditionAction = createAction('[Conditions Manager Actions] Load Condition', props<{ id: string }>());

export const loadConditionSuccessAction = createAction(
  '[Conditions Manager Actions] Load Condition Success',
  props<{ condition: Condition }>()
);

export const loadCareplanConditionsSuccessAction = createAction(
  '[Allergies Manager Actions] Load Careplan Conditions Success',
  props<{ conditions: Condition[]; count: number }>()
);

export const linkCareplanConditionsSuccessAction = createAction(
  '[Allergies Manager Actions] Link Care Plan Conditions Success',
  props<{ conditions: Condition[] }>()
);

export const addConditionAction = createAction(
  '[Conditions Manager Actions] Add Condition',
  props<{ condition: Condition }>()
);

export const addConditionSuccessAction = createAction(
  '[Conditions Manager Actions] Add Condition Success',
  props<{ condition: Condition }>()
);

export const editConditionAction = createAction(
  '[Conditions Manager Actions] Edit Condition',
  props<{ condition: Condition }>()
);

export const editConditionSuccessAction = createAction(
  '[Conditions Manager Actions] Edit Condition Success',
  props<{ condition: Condition }>()
);

export const clearActiveCondition = createAction('[Conditions Manager Actions] Clear active condition');

export const deleteConditionAction = createAction(
  '[Conditions Manager Actions] Delete Allergy Action',
  props<{ id: string }>()
);

export const deleteConditionSuccessAction = createAction(
  '[Conditions Manager Actions] Delete Allergy Success Action',
  props<{ id: string }>()
);

export const removeCareplanConditionAction = createAction(
  '[Condition Actions] Remove Care Plan Condition',
  props<{ id: string }>()
);
