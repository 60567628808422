export enum ResourceGroup {
  treatmentDirectives = 'group-treatment-directives',
  socialHistory = 'group-social-history',
  medication = 'group-medication',
  vitalSigns = 'group-vital-signs',
  plannedCareActivities = 'group-planned-care-activities',
  substanceUse = 'group-substance-use',
  results = 'group_results',
}

export function isResourceGroup(enumValue: any): boolean {
  return Object.values(ResourceGroup).includes(enumValue as ResourceGroup);
}