<div [class]="isGroupedResource ? 'resource-grouped' : 'mb-3 resource'">
  <div
    [class.title]="!isGroupedResource"
    [class.title-grouped]="isGroupedResource"
    class="pr-3 pl-4 py-3 d-flex align-items-center"
    (click)="isExpanded = !isExpanded"
  >
    <div class="chevron">
      <medrecord-svg-chevron-right
        [type]="isGroupedResource ? 'black' : 'primary'"
        *ngIf="!isExpanded"
        icon
        width="9"
      ></medrecord-svg-chevron-right>
      <medrecord-svg-chevron-down
        [type]="isGroupedResource ? 'black' : 'primary'"
        *ngIf="isExpanded"
        icon
        width="14"
      ></medrecord-svg-chevron-down>
    </div>
    <span
      [class.text-primary]="!isGroupedResource"
      [class.text-black]="isGroupedResource"
      class="text-lg text-weight-600 ml-2"
    >
      {{ title | translate }}
    </span>
  </div>
  <ng-container *ngIf="isExpanded">
    <ng-container *ngIf="mainResources?.length; else noData">
      <ng-container *ngFor="let mainResourceData of mainResources; let index = index">
        <doctor-gui-field-group
          *ngFor="let fieldGroup of block.fieldGroups"
          [fieldGroup]="fieldGroup"
          [mainResourceData]="mainResourceData"
          [allResourcesData]="resource.resources"
          [resourceIndex]="index"
          (getDetailsData)="getDetailsData.emit($event)"
        ></doctor-gui-field-group>
      </ng-container>
    </ng-container>
    <ng-template #noData>
      <div class="row">
        <div class="col-12 my-5 d-flex justify-content-center">{{ 'my_doctor_no_data' | translate }}</div>
      </div>
    </ng-template>
  </ng-container>
</div>
