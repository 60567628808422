import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppointmentService } from './services/appointment.service';
import { QualificationService } from './services/qualification.service';
import { CalendarManagerStateModule } from './state-management/calendar-manager-state.module';

@NgModule({
  imports: [CommonModule, CalendarManagerStateModule],
  providers: [AppointmentService, QualificationService]
})
export class CalendarManagerModule {}
