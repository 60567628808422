import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Resource } from '../models/enums/resource.enum';
import { ResourceBlock } from '../models/interfaces/resource-block.interface';
import { alertBlock } from '../models/resource-blocks/alert';
import { medicationIntoleranceBlock } from '../models/resource-blocks/medication-intolerance';
import { concernBlock } from '../models/resource-blocks/concern';
import { contactPersonBlock } from '../models/resource-blocks/contactPerson';
import { episodeBlock } from '../models/resource-blocks/episode';
import { functionalOrMentalStatusBlock } from '../models/resource-blocks/functional-or-mental-status';
import { patientBlock } from '../models/resource-blocks/patient';
import { practitionerBlock } from '../models/resource-blocks/practitioner';
import { soapBlock } from '../models/resource-blocks/soap';
import { allergyIntoleranceBlock } from '../models/resource-blocks/allergy-intolerance';
import { medicalAidBlock } from '../models/resource-blocks/medical-aid';
import { vaccinationBlock } from '../models/resource-blocks/vaccination';
import { laboratoryTestResultBlock } from '../models/resource-blocks/laboratory-test-result';
import { procedureBlock } from '../models/resource-blocks/procedure';
import { bgz2020ContactPersonBlock } from '../models/resource-blocks/bgz/bgz2020ContactPerson';
import { encounterBlock } from '../models/resource-blocks/encounter';
import { advanceDirectiveBlock } from '../models/resource-blocks/advance-directive';
import { treatmentDirectiveBlock } from '../models/resource-blocks/treatment-directive';
import { livingSituationBlock } from '../models/resource-blocks/living-situation';
import { drugUseBlock } from '../models/resource-blocks/drug-use';
import { alcoholUseBlock } from '../models/resource-blocks/alcohol-use';
import { tobaccoUseBlock } from '../models/resource-blocks/tabacco-use';
import { nutritionAdviceBlock } from '../models/resource-blocks/nutrition-advice';
import { medicationUseBlock } from '../models/resource-blocks/medication-use';
import { medicationAgreementBlock } from '../models/resource-blocks/medication-agreement';
import { administrationAgreementBlock } from '../models/resource-blocks/administration-agreement';
import { bloodPressureBlock } from '../models/resource-blocks/blood-pressure';
import { bodyHeightBlock } from '../models/resource-blocks/body-height';
import { bodyWeightBlock } from '../models/resource-blocks/body-weight';
import { plannedCareActivityProcedureRequestBlock } from '../models/resource-blocks/planned-care-activity-procedure-request';
import { plannedCareActivityImmunizationRecommendationBlock } from '../models/resource-blocks/planned-care-activity-immunization-recommendation';
import { plannedCareActivityAppointmentBlock } from '../models/resource-blocks/planned-care-activity-appointment';
import { plannedCareActivityDeviceRequestBlock } from '../models/resource-blocks/planned-care-activity-device-request';
import { documentReferenceBlock } from '../models/resource-blocks/document-reference';
import { pdfaBlock } from '../models/resource-blocks/pdfa-reference';
import { laboratoryResultBlock } from '../models/resource-blocks/laboratory-result';
import { problemBlock } from '../models/resource-blocks/problem';
import { financeBlock } from '../models/resource-blocks/finance';
import { legalStatusBlock } from '../models/resource-blocks/legal-status';
import { languageProficiencyBlock } from '../models/resource-blocks/language-proficiency';
import { familySituationBlock } from '../models/resource-blocks/family-situation';
import { societyParticipationBlock } from '../models/resource-blocks/society-participation';
import { helpFromOthersBlock } from '../models/resource-blocks/help-from-others';
import { generalMeasurementBlock } from '../models/resource-blocks/general-measurement';
import { textResultBlock } from '../models/resource-blocks/text-result';
import { careTeamBlock } from '../models/resource-blocks/care-team';
import { documentManifestBlock } from '../models/resource-blocks/document-manifest';
import { goalsAndInterventionsBlock } from '../models/resource-blocks/goals-and-interventions';
import { recordsBlock } from '../models/resource-blocks/maternity-care/records';
import { gpPractitionerBlock } from '../models/resource-blocks/gp-practitioner';
import { gpPatientBlock } from '../models/resource-blocks/gp-patient';

@Injectable()
export class MyDoctorResourceMappingService {
  private resourceBlocks = {
    // GP Data
    [Resource.gpEpisode2020]: episodeBlock,
    [Resource.gpMedicationIntolerance2020]: medicationIntoleranceBlock,
    [Resource.gpSoap2020]: soapBlock,
    [Resource.gpPractitioner2020]: gpPractitionerBlock,
    [Resource.gpPatient2020]: gpPatientBlock,
    [Resource.gpEncounter2020]: encounterBlock,
    [Resource.gpAlert2020]: alertBlock, // unused
    [Resource.gpCurrentMedication2020]: medicationAgreementBlock,
    [Resource.gpLaboratoryResults2020]: laboratoryResultBlock,

    // BGZ
    [Resource.bgzTreatmentDirective2020]: treatmentDirectiveBlock,
    [Resource.bgzAdvanceDirective2020]: advanceDirectiveBlock,
    [Resource.bgzContactPerson2020]: bgz2020ContactPersonBlock,
    [Resource.bgzFunctionalOrMentalStatus2020]: functionalOrMentalStatusBlock,
    [Resource.bgzConcern2020]: concernBlock,
    [Resource.bgzLivingSituation2020]: livingSituationBlock,
    [Resource.bgzDrugUse2020]: drugUseBlock,
    [Resource.bgzAlcoholUse2020]: alcoholUseBlock,
    [Resource.bgzTobaccoUse2020]: tobaccoUseBlock,
    [Resource.bgzNutritionAdvice2020]: nutritionAdviceBlock,
    [Resource.bgzAlert2020]: alertBlock,
    [Resource.bgzAllergyIntolerance2020]: allergyIntoleranceBlock,
    [Resource.bgzMedicationUse2020]: medicationUseBlock,
    [Resource.bgzMedicationAgreement2020]: medicationAgreementBlock,
    [Resource.bgzAdministrationAgreement2020]: administrationAgreementBlock,
    [Resource.bgzMedicalAid2020]: medicalAidBlock,
    [Resource.bgzVaccination2020]: vaccinationBlock,
    [Resource.bgzBloodPressure2020]: bloodPressureBlock,
    [Resource.bgzBodyWeight2020]: bodyWeightBlock,
    [Resource.bgzBodyHeight2020]: bodyHeightBlock,
    [Resource.bgzLaboratoryTestResult2020]: laboratoryTestResultBlock,
    [Resource.bgzProcedure2020]: procedureBlock,
    [Resource.bgzEncounter2020]: encounterBlock,
    [Resource.bgzPlannedCareActivityProcedureRequest2020]: plannedCareActivityProcedureRequestBlock,
    [Resource.bgzPlannedCareActivityImmunizationRecommendation2020]: plannedCareActivityImmunizationRecommendationBlock,
    [Resource.bgzPlannedCareActivityAppointment2020]: plannedCareActivityAppointmentBlock,
    [Resource.bgzPlannedCareActivityDeviceRequest2020]: plannedCareActivityDeviceRequestBlock,
    [Resource.bgzHealthProfessional2020]: practitionerBlock,
    [Resource.bgzPatient2020]: patientBlock,

    // PDF
    [Resource.pdfBinaryId]: pdfaBlock,
    [Resource.pdfDocrefId]: documentReferenceBlock,
    [Resource.pdfMetadataDocref]: documentReferenceBlock,
    [Resource.pdfMetadataManifest]: documentManifestBlock,

    // BGLZ
    [Resource.bglzPatient2020]: patientBlock,
    [Resource.bglzTreatmentDirective2020]: treatmentDirectiveBlock,
    [Resource.bglzAdvanceDirective2020]: advanceDirectiveBlock,
    [Resource.bglzContactPerson2020]: contactPersonBlock,
    [Resource.bglzAllergyIntolerance2020]: allergyIntoleranceBlock,
    [Resource.bglzLaboratoryTestResult2020]: laboratoryTestResultBlock,
    [Resource.bglzProcedure2020]: procedureBlock,
    [Resource.bglzProblem2020]: problemBlock,
    [Resource.bglzCaresettingCareteam2020]: careTeamBlock,
    [Resource.bglzGoalsAndInterventions2020]: goalsAndInterventionsBlock,

    // Bg GGZ
    [Resource.ggzPatient2020]: patientBlock,
    [Resource.ggzPayer2020]: financeBlock,
    [Resource.ggzTreatmentDirective2020]: treatmentDirectiveBlock,
    [Resource.ggzAdvanceDirective2020]: advanceDirectiveBlock,
    [Resource.ggzLegalStatus2020]: legalStatusBlock,
    [Resource.ggzContactPerson2020]: bgz2020ContactPersonBlock,
    [Resource.ggzFuncOrMentalStatus2020]: functionalOrMentalStatusBlock,
    [Resource.ggzProblem2020]: problemBlock,
    [Resource.ggzDrugUse2020]: drugUseBlock,
    [Resource.ggzAlcoholUse2020]: alcoholUseBlock,
    [Resource.ggzTobaccoUse2020]: tobaccoUseBlock,
    [Resource.ggzLivingSituation2020]: livingSituationBlock,
    [Resource.ggzFamilySituation2020]: familySituationBlock,
    [Resource.ggzLanguageProficiency2020]: languageProficiencyBlock,
    [Resource.ggzParticipationInSociety2020]: societyParticipationBlock,
    [Resource.ggzHelpFromOthers2020]: helpFromOthersBlock,
    [Resource.ggzLaboratoryTestResult2020]: laboratoryTestResultBlock,
    [Resource.ggzGeneralMeasurement2020]: generalMeasurementBlock,
    [Resource.ggzProcedure2020]: procedureBlock,
    [Resource.ggzTextResult2020]: textResultBlock,
    [Resource.ggzCareTeam2020]: careTeamBlock,

    // Maternity care
    [Resource.maternityCarePatient2020]: patientBlock,
    [Resource.maternityCareRecordConditions2020]: concernBlock,
    [Resource.maternityCareRecordObservations2020]: laboratoryResultBlock,
    [Resource.maternityCareRecordVisits2020]: encounterBlock,
    [Resource.maternityCareRecordVisitsObservations2020]: laboratoryResultBlock,
    [Resource.maternityCareRecordProcedures2020]: procedureBlock,
    [Resource.maternityCareMaternalRecordsActive2020]: recordsBlock,
    [Resource.maternityCareMaternalRecordsFinished2020]: recordsBlock,
    [Resource.maternityCareMaternalRecords2020]: recordsBlock,
  };

  constructor(private translateService: TranslateService) {}

  getResourceBlock(resource: Resource): ResourceBlock {
    return this.resourceBlocks[resource]?.({ translateService: this.translateService });
  }
}
