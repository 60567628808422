import { createAction, props } from '@ngrx/store';
import { IFeedbackPayload } from '../model/IFeedbackPayload';

export const activateFeedbackAction = createAction('[Feedback Actions] Activate Feedback Action');

export const sendFeedbackAction = createAction(
  '[Feedback Actions] Send Feedback Action',
  props<{ payload: IFeedbackPayload }>()
);

export const clearFeedbackAction = createAction('[Feedback Actions] Clear Feedback Action');
