<ejs-dialog [visible]="isModalOpen" [isModal]="true" (overlayClick)="closeModal()" width="40%">
  <ng-template #header>
    <div class="px-2">
      <span>{{ careproviderName }} </span>
      <span> {{ dataserviceName | translate }}</span>
    </div>
  </ng-template>

  <ng-template #content>
    <div class="px-2 mx-0">
      <form [formGroup]="form">
        <medrecord-ui-field-label [text]="'my_doctor_select_transactions' | translate"></medrecord-ui-field-label>
        <div class="d-flex align-items-center justify-content-end">
          <button (click)="setAll(false)" type="button" class="btn btn-outline-black btn-sm mr-3">
            {{ 'common_deselect_all' | translate }}
          </button>
          <button (click)="setAll(true)" type="button" class="btn btn-outline-black btn-sm">
            {{ 'common_select_all' | translate }}
          </button>
        </div>
        <div class="py-1" *ngFor="let item of modalResources">
          <ejs-checkbox
            name="resources"
            [value]="item"
            [label]="item | translate"
            [formControlName]="item"
          ></ejs-checkbox>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #footerTemplate>
    <div class="px-2 d-flex align-items-center justify-content-end">
      <button (click)="closeModal()" type="button" class="btn btn-outline-black mr-3">
        {{ 'common_cancel' | translate }}
      </button>
      <button (click)="baseFormSubmit()" type="button" class="btn btn-secondary">
        {{ 'common_form_submit' | translate }}
      </button>
    </div>
  </ng-template>
</ejs-dialog>
