import { SortingMode } from '@medrecord/core';
import { ConditionsSortingFields } from '../enums/conditions-sorting-fields.enum';

export class ConditionsLoadPayload {
  count?: number;
  mode?: SortingMode;
  sort?: ConditionsSortingFields;
  start?: number;
  carePlanId?: string;
}
