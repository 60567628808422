import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatePrecision } from '@medrecord/services-datetime';
import { TranslateService } from '@ngx-translate/core';
import { ItemModel, MenuEventArgs } from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'medrecord-ui-select-precision',
  templateUrl: './select-precision.component.html',
  styleUrls: ['./select-precision.component.scss'],
})
export class SelectPrecisionComponent {
  readonly DatePrecision = DatePrecision;
  @Input() selectedPrecision: DatePrecision = DatePrecision.day;
  @Input() disabled = false;
  @Output() selectedPrecisionChange = new EventEmitter<DatePrecision>();

  precisions: ItemModel[] = [];

  constructor(private translateService: TranslateService) {
    this.precisions = Object.values(DatePrecision).map((precision) => ({
      id: precision,
      text: this.translateService.instant('common_precision_' + precision.toLowerCase()),
    }));
  }

  selectPrecision(precision: MenuEventArgs): void {
    this.selectedPrecision = precision.item.id as DatePrecision;
    this.selectedPrecisionChange.emit(this.selectedPrecision);
  }
}
