import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromNetworkOrganisations from './network-organisations.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { NetworkOrganisationsEffects } from './network-organisations.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(fromNetworkOrganisations.networkOrganisationsFeatureKey, fromNetworkOrganisations.reducer),
    EffectsModule.forFeature([NetworkOrganisationsEffects]),
    CommonModule
  ]
})
export class NetworkOrganisationsStateModule {}
