import { Component, Input } from '@angular/core';

@Component({
  selector: 'medrecord-auth-stepper',
  templateUrl: './auth-stepper.component.html',
  styleUrls: ['./auth-stepper.component.scss'],
})
export class AuthStepperComponent {
  @Input()
  step: number;
  
  @Input()
  totalSteps: number;
}
