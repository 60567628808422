export enum DurationUnit {
  Nanos = "NANOS",
  Micros = "MICROS",
  Millis = "MILLIS",
  Seconds = "SECONDS",
  Minutes = "MINUTES",
  Hours = "HOURS",
  HalfDays = "HALFDAYS",
  Days = "DAYS",
  Weeks = "WEEKS",
  Months = "MONTHS",
  Years = "YEARS",
  Decades = "DECADES",
  Centuries = "CENTURIES",
  Millennia = "MILLENNIA",
  Eras = "ERAS",
  Forever = "FOREVER",
}
