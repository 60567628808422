import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';
import { MedrecordMoment } from '@medrecord/services-datetime';
import { BlocksMapperHelperUtil } from '../../utils/blocks-mapper-helper.util';

export const gpPatientBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.patient,
  fieldGroups: [
    {
      name: 'bglz-patient-2020',
      fields: [
        {
          label: 'my_doctor_identification',
          fieldName: 'identifiers',
          prepareData: (data) => data?.[0]?.value
        },
        {
          label: 'my_doctor_name',
          fieldName: 'name',
          prepareData: (data, allResources) => {
            const birthDateField = allResources
              .find(r => r.resourceType === ResourceType.patient)
              ?.content?.['birthDate'];
            const dateOfBirth = birthDateField ? MedrecordMoment(birthDateField).format('LL') : null;
            return [
              BlocksMapperHelperUtil.mapName(data, true),
              (dateOfBirth ? `(${dateOfBirth})` : null)
            ].filter(part => part).join(' ');
          }
        },
        {
          label: 'my_doctor_name_use',
          fieldName: 'name',
          prepareData: (data) => data?.assemblyOrder?.coding?.[0]?.display
        },
        {
          label: 'my_doctor_address',
          fieldName: 'addressList',
          prepareData: (data) => [
            [
              data?.[0]?.streetName,
              data?.[0]?.houseNumber
            ].filter(part => part).join(' '),
            [
              data?.[0]?.postalCode,
              data?.[0]?.city
            ].filter(part => part).join(' '),
            data?.[0]?.landGbaCode?.coding?.[0]?.display
          ].filter(part => part).join(', ')
        },
        {
          label: 'my_doctor_address_type',
          fieldName: 'addressList',
          translate: true,
          prepareData: (data) => data?.[0]?.use
        },
        {
          label: 'my_doctor_phone_number',
          fieldName: 'contactList',
          prepareData: (data) => {
            const phone = data?.find((t) => t.system === 'PHONE');
            return phone?.value;
          }
        },
        {
          label: 'my_doctor_phone_type',
          fieldName: 'contactList',
          translate: true,
          prepareData: (data) => {
            const phone = data?.find((t) => t.system === 'PHONE');
            return phone?.use ? 'common_contact_use_' + phone?.use?.toLowerCase() : '';
          }
        },
        {
          label: 'my_doctor_email',
          fieldName: 'contactList',
          prepareData: (data) => {
            const email = data?.find((t) => t.system === 'EMAIL');
            return email?.value;
          }
        },
        {
          label: 'my_doctor_email_type',
          fieldName: 'contactList',
          translate: true,
          prepareData: (data) => {
            const email = data?.find((t) => t.system === 'EMAIL');
            return email?.use ? 'common_contact_use_' + email?.use?.toLowerCase() : '';
          }
        },
        {
          label: 'my_doctor_gender',
          fieldName: 'gender',
          translate: true,
          prepareData: (data) => (data ? 'common_gender_' + data?.toLowerCase() : '')
        },
        {
          label: 'my_doctor_multiple_indicator',
          fieldName: 'multipleBirth',
          translate: true,
          prepareData: (data) => (data ? 'common_yes' : 'common_no')
        }
      ]
    }
  ]
});
