import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-warning',
  templateUrl: './warning.component.svg',
  styleUrls: ['./warning.component.scss'],
})
export class WarningComponent extends MedrecordIcon {
  viewHeight = 21;
  viewWidth = 21;
}
