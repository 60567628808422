<h2
  class="text-weight-600 text-primary text-center"
  [innerHTML]="'two_factor_authentication.title' | translate | safeHtml"
></h2>

<p class="text-lg my-4 text-justify">
  {{ 'two_factor_authentication.info' | translate }}
</p>

<form [formGroup]="form">
  <medrecord-ui-map-field
    controlName="code"
    [attr.id]="isOAuth && 'phone-code-field'"
    [parentForm]="form"
    [fieldType]="mapFieldType.Input"
    [options]="{ mask: '0 - 0 - 0 - 0' }"
    [label]="'common_field_verification_code' | translate"
  >
    <ng-template #rightButton>
      <button
        (click)="resend()"
        [class.btn-rounded]="isOAuth"
        class="btn btn-outline-black text-weight-600 text-lg ml-2"
      >
        {{ 'two_factor_authentication.resend_sms' | translate | uppercase }}
      </button>
    </ng-template>
  </medrecord-ui-map-field>
</form>

<button
  (click)="onNext()"
  [class.btn-rounded]="isOAuth"
  [attr.test-id]="'verify-code-btn'"
  class="btn btn-primary btn-block text-weight-600 mt-4"
>
  {{ 'auth_next_step' | translate | uppercase }}
</button>
