import { Action, createReducer, on } from '@ngrx/store';
import { PaymentPlan } from '../models/interfaces/payment-plan.interface';
import { loadPaymentPlansFailureAction, loadPaymentPlansSuccessAction } from './payment-manager.actions';

export const paymentManagerFeatureKey = 'payment-manager';

export interface PaymentMangerState {
  error: any;
  paymentPlans: PaymentPlan[];
}

export const initialState: PaymentMangerState = {
  error: null,
  paymentPlans: [],
};

export const paymentManagerReducer = createReducer(
  initialState,
  on(
    loadPaymentPlansSuccessAction,
    (state, { paymentPlans }) => ({ ...state, paymentPlans })
  ),
  on(
    loadPaymentPlansFailureAction,
    (state, { error }) => ({ ...state, error })
  ),
  
);

export const reducer = (state: PaymentMangerState, action: Action): PaymentMangerState => {
  return paymentManagerReducer(state, action);
};