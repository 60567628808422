<div
  class="terms-and-conditions"
  [style.height]="unlimitedSpace ? 'unset' : '362px'"
  [style.max-height]="unlimitedSpace ? 'unset' : '362px'"
>
  <h4 *ngIf="!externalHeader">{{ 'privacy_policy_header' | translate }}</h4>

  <p [class.text-sm]="!unlimitedSpace" [class.text-md]="unlimitedSpace">{{ 'privacy_policy_intro' | translate }}</p>

  <div *ngFor="let statement of statements">
    <h5>{{ statement.header }}</h5>
    <p
      [innerHTML]="statement.content | safeHtml"
      [class.text-sm]="!unlimitedSpace"
      [class.text-md]="unlimitedSpace"
    ></p>
  </div>
</div>
