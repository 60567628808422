import { Component } from '@angular/core';
import {
  getActiveTransactionId,
  getParametersModalOpenStatus,
  getResourceParameters, PullTransaction,
  Resource,
  ResourceParameter,
  setParametersModalOpenStatusAction,
  submitParametersModalAction,
  TransactionParameterName
} from '@managers/my-doctor';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'doctor-gui-parameters-modal-container',
  templateUrl: './parameters-modal-container.component.html'
})
export class ParametersModalContainerComponent {
  isModalOpen$: Observable<boolean> = this.store.select(getParametersModalOpenStatus);
  transactionId$: Observable<PullTransaction> = this.store.select(getActiveTransactionId);
  resourceParameters$: Observable<ResourceParameter[]> = this.store.select(getResourceParameters);

  constructor(
    private store: Store
  ) {
  }

  onModalSubmit(payload: { [key in Resource]?: { [name in TransactionParameterName]?: string } }): void {
    this.store.dispatch(submitParametersModalAction({ resourceParameters: payload }));
  }

  setModalClosed(): void {
    this.store.dispatch(setParametersModalOpenStatusAction({ isOpen: false }));
  }
}
