import { createAction, props } from '@ngrx/store';
import { Post, PostResponse, TagResponse } from '../models/interfaces';

export const loadPostsAction = createAction(
  '[News Manager Actions] Load Posts',
  props<{
    title?: string;
    categoryName?: string;
  }>()
);

export const loadPostsSuccessAction = createAction(
  '[News Manager Actions] Load Posts Success',
  props<{ posts: PostResponse }>()
);

export const loadPostsFailureAction = createAction(
  '[News Manager Actions] Load Posts Failure',
  props<{ error: any }>()
);

export const loadCareplanPostsAction = createAction(
  '[News Manager Actions] Load Careplan Posts',
  props<{
    categoryNames: string[];
    healthPlanCategory: string;
  }>()
);

export const loadCareplanPostsSuccessAction = createAction(
  '[News Manager Actions] Load Careplan Posts Success',
  props<{ posts: PostResponse }>()
);

export const loadCareplanPostsFailureAction = createAction(
  '[News Manager Actions] Load Careplan Posts Failure',
  props<{ error: any }>()
);

export const loadCareplanResourcesPostsAction = createAction(
  '[News Manager Actions] Load Careplan Resources Posts',
  props<{
    healthPlanCategory: string;
  }>()
);

export const loadCareplanResourcesPostsSuccessAction = createAction(
  '[News Manager Actions] Load Careplan Resources Posts Success',
  props<{ posts: PostResponse }>()
);

export const loadCareplanResourcesPostsFailureAction = createAction(
  '[News Manager Actions] Load Careplan Resources Posts Failure',
  props<{ error: any }>()
);

export const loadFeaturedPostsAction = createAction('[News Manager Actions] Load Featured Posts');

export const loadFeaturedPostsSuccessAction = createAction(
  '[News Manager Actions] Load Featured Posts Success',
  props<{ featuredPosts: Post[] }>()
);

export const loadFeaturedPostsFailureAction = createAction(
  '[News Manager Actions] Load Featured Posts Failure',
  props<{ error: any }>()
);

export const loadCategoriesAction = createAction('[News Manager Actions] Load Categories');

export const loadCategoriesSuccessAction = createAction(
  '[News Manager Actions] Load Categories Success',
  props<{ categories: TagResponse }>()
);

export const loadCategoriesFailureAction = createAction(
  '[News Manager Actions] Load Categories Failure',
  props<{ error: any }>()
);

export const loadCareplanCategoriesAction = createAction(
  '[News Manager Actions] Load Careplan Categories',
  props<{
    healthPlanCategory: string;
  }>()
);

export const loadCareplanCategoriesSuccessAction = createAction(
  '[News Manager Actions] Load Careplan Categories Success',
  props<{ categories: TagResponse }>()
);

export const loadCareplanCategoriesFailureAction = createAction(
  '[News Manager Actions] Load Careplan Categories Failure',
  props<{ error: any }>()
);

export const loadPostAction = createAction('[News Manager Actions] Load Post', props<{ id: string }>());

export const loadPostSuccessAction = createAction(
  '[News Manager Actions] Load Post Success',
  props<{ post: PostResponse }>()
);

export const loadPostFailureAction = createAction('[News Manager Actions] Load Post Failure', props<{ error: any }>());

export const loadCareplanPostAction = createAction(
  '[News Manager Actions] Load Careplan Post',
  props<{
    id: string;
    healthPlanCategory: string;
  }>()
);

export const loadCareplanPostSuccessAction = createAction(
  '[News Manager Actions] Load Careplan Post Success',
  props<{ post: PostResponse }>()
);

export const loadCareplanPostFailureAction = createAction(
  '[News Manager Actions] Load Careplan Post Failure',
  props<{ error: any }>()
);

export const clearActivePostAction = createAction('[News Manager Actions] Clear Active Post');
