import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FeedbackManagerEffects } from './feedback-manager.effects';
import { feedbackManagerFeatureKey, reducer } from './feedback-manager.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(feedbackManagerFeatureKey, reducer),
    EffectsModule.forFeature([FeedbackManagerEffects]),
  ],
})
export class FeedbackManagerStateModule {}
