<ng-container [formGroup]="parentForm">
  <div class="d-flex flex-wrap">
    <ng-container *ngFor="let field of fields">
      <medrecord-ui-map-field [parentForm]="field.form"
                              [options]="field.options"
                              [label]="field.label"
                              [placeholder]="field.placeholder"
                              [secondaryLabel]="field.secondaryLabel"
                              [fieldType]="field.type"
                              [controlName]="field.controlName"
                              [cssClass]="field.cssClass"
                              [hint]="field.hint"
                              [bindVisibility]="field.bindVisibility(field.form)"
                              class="{{ field.fieldClass }} mb-3"
      ></medrecord-ui-map-field>
    </ng-container>
  </div>
</ng-container>
