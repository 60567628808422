import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { calendarManagerFeatureKey, reducer } from './calendar-manager.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CalendarManagerEffects } from './calendar-manager.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(calendarManagerFeatureKey, reducer),
    EffectsModule.forFeature([ CalendarManagerEffects ])
  ]
})
export class CalendarManagerStateModule {}