import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-chevron-right',
  templateUrl: './chevron-right.component.svg',
})
export class ChevronRightComponent extends MedrecordIcon {
  viewHeight = 14;
  viewWidth = 8;
}
