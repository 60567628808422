import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromUsersManagement from './users-management.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UsersManagementEffects } from './users-management.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(fromUsersManagement.usersManagementFeatureKey, fromUsersManagement.reducer),
    EffectsModule.forFeature([UsersManagementEffects]),
  ]
})
export class UsersManagementStateModule {}
