import { Component, Input } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';
import { Colors } from '@medrecord/services-colors';

@Component({
  selector: 'medrecord-svg-pen-rounded',
  templateUrl: './pen-rounded.component.svg',
})
export class PenRoundedComponent extends MedrecordIcon {
  viewHeight = 24;
  viewWidth = 24;

  @Input() circleBorderType: Colors = 'mystic';

  @Input() circleBgType: Colors = 'white';

  @Input() circleHoverBorderType: Colors;
  @Input() circleHoverBgType: Colors;

  circleColor: string = this.getColorByType(this.circleBorderType);
  bgColor: string = this.getColorByType(this.circleBgType);

  mouseEnter(): void {
    super.mouseEnter();

    if (this.isHoverable) {
      this.circleColor = this.getColorByType(this.getHoverColorType(this.circleBorderType, this.circleHoverBorderType));
      this.bgColor = this.getColorByType(this.getHoverColorType(this.circleBgType, this.circleHoverBgType));
    }
  }

  mouseLeave(): void {
    super.mouseLeave();

    this.circleColor = this.getColorByType(this.circleBorderType);
    this.bgColor = this.getColorByType(this.circleBgType);
  }
}
