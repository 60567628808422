import { GenericMapper } from './generic.mapper';
import { CollapsibleTable, ColumnDefinition } from '../interfaces';
import { Resource, ResourceSubType, ResourceType, LabResultStatus } from '../enums';
import { DatePrecision, EffectiveDate } from '@medrecord/services-datetime';
import { MapperUtil } from './mapper.util';

export class LaboratoryResultsMapper extends GenericMapper<LabResultColumn> {

  protected get title(): string {
    return 'my_doctor_laboratory_result';
  }

  protected get mainKey(): Resource {
    return Resource.gpLaboratoryResults2020;
  }

  protected get mainType(): ResourceType {
    return ResourceType.observation;
  }

  protected get sortBy(): LabResultColumn[] {
    return [LabResultColumn.forComparison];
  }

  protected get groupBy(): LabResultColumn {
    return LabResultColumn.status;
  }

  protected get groupSortOrder(): { [key: string]: number } {
    return {
      [LabResultStatus.final]: 0,
      [LabResultStatus.corrected]: 1,
      [LabResultStatus.pending]: 2,
      [LabResultStatus.preliminary]: 3,
      [LabResultStatus.appended]: 4
    };
  }

  protected get activeGroups(): string[] {
    return [
      LabResultStatus.final,
      LabResultStatus.corrected
    ];
  }

  protected get columns(): ColumnDefinition<LabResultColumn>[] {
    return [
      {
        property: LabResultColumn.name,
        title: 'my_doctor_name',
        width: 11,
        lines: [{
          type: 'translate',
          bold: true
        }]
      },
      {
        property: LabResultColumn.testCode,
        title: 'my_doctor_testCode',
        width: 13,
        lines: [{
          type: 'string'
        }]
      },
      {
        property: LabResultColumn.date,
        title: 'my_doctor_date',
        width: 12,
        lines: [{
          type: 'precisionDate',
          bold: true
        }]
      },
      {
        property: LabResultColumn.results,
        title: 'healthcare_providers_results',
        width: 13,
        lines: [{
          type: 'multiline'
        }]
      },
      {
        property: LabResultColumn.interpretationFlags,
        title: 'my_doctor_interpretation_flags',
        width: 13,
        lines: [{
          type: 'string'
        }]
      },
      {
        property: LabResultColumn.lowerLimit,
        title: 'my_doctor_lower_limit',
        width: 7,
        lines: [{
          type: 'string'
        }]
      },
      {
        property: LabResultColumn.upperLimit,
        title: 'my_doctor_upper_limit',
        width: 6,
        lines: [{
          type: 'string'
        }]
      },
      {
        property: LabResultColumn.healthcareProvider,
        title: 'my_doctor_careprovider',
        width: 24,
        lines: [{
          type: 'string'
        }]
      }
    ];
  }

  protected get collapsibleTables(): CollapsibleTable<LabResultColumn>[] {
    return null;
  }

  protected getAllRows(): { [key in LabResultColumn]?: any[] }[] {
    return this.mainResources.map(ml => { // m = measurement or lab result
      return ml.observationType === ResourceSubType.laboratoryTestResult
        ? this.mapLabResult(ml)
        : this.mapMeasurement(ml);
    });
  }

  private mapLabResult(l: any): { [key in LabResultColumn]?: any[] } {
    const extractedStatus = MapperUtil.codeableConcept(l.resultStatus);
    const interpretationFlag = l.interpretationFlags?.find(flag => flag.system === 'http://hl7.org/fhir/v2/0078');
    return {
      [LabResultColumn.status]: [extractedStatus ? extractedStatus : LabResultStatus.final],
      [LabResultColumn.name]: [MapperUtil.codeableConcept(l.testCode)],
      [LabResultColumn.testCode]: [l.testCode?.coding?.[0]?.code],
      [LabResultColumn.date]: [{ date: l.testDateTime, precision: DatePrecision.day } as EffectiveDate],
      [LabResultColumn.results]: [MapperUtil.mapObservationResults(l, this.translateService)],
      [LabResultColumn.interpretationFlags]: [interpretationFlag?.display || interpretationFlag?.code],
      [LabResultColumn.lowerLimit]: [MapperUtil.quantity(l.lowerLimit)],
      [LabResultColumn.upperLimit]: [MapperUtil.quantity(l.upperLimit)],
      [LabResultColumn.healthcareProvider]: [l.performers?.[0]?.reference?.display || l.performer?.display],
      [LabResultColumn.forComparison]: [l.testDateTime]
    };
  }

  private mapMeasurement(m: any): { [key in LabResultColumn]?: any[] } {
    return {
      [LabResultColumn.status]: [LabResultStatus.final],
      [LabResultColumn.name]: [!m.observationType
        ? ''
        : m.observationType === ResourceSubType.generic
          ? m.observationType
          : 'measurements_' + m.observationType.toLowerCase()],
      [LabResultColumn.testCode]: [m.testCode?.coding?.[0]?.code],
      [LabResultColumn.date]: [m.effective],
      [LabResultColumn.results]: [MapperUtil.mapObservationResults(m, this.translateService)],
      [LabResultColumn.interpretationFlags]: [null],
      [LabResultColumn.lowerLimit]: [null],
      [LabResultColumn.upperLimit]: [null],
      [LabResultColumn.healthcareProvider]: [m.performers?.[0]?.reference?.display || m.performer?.display],
      [LabResultColumn.forComparison]: [m.effective?.date]
    };
  }
}

enum LabResultColumn {
  status = 'status', // grouping
  name = 'name',
  testCode = 'testCode',
  date = 'date',
  results = 'results',
  interpretationFlags = 'interpretationFlags',
  lowerLimit = 'lowerLimit',
  upperLimit = 'upperLimit',
  healthcareProvider = 'healthcareProvider',
  forComparison = 'forComparison'
}
