import { Component, EventEmitter, Input, Output } from '@angular/core';
import { cancelTwofaFlowAction } from '@medrecord/managers-auth';
import { Store } from '@ngrx/store';

@Component({
  selector: 'medrecord-auth-twofa-action-buttons-container',
  templateUrl: './action-buttons-container.component.html',
  styleUrls: ['./action-buttons-container.component.scss']
})
export class ActionButtonsContainerComponent {
  @Input() primaryButtonLabel: string;
  @Input() primaryButtonType = 'button';
  @Input() showPrimaryButton = true;
  @Input() showCancelButton = true;

  @Output() primaryButton: EventEmitter<void> = new EventEmitter();

  constructor(private store: Store) {
  }

  primaryButtonClick(): void {
    this.primaryButton.emit();
  }

  cancelButtonClick(): void {
    this.store.dispatch(cancelTwofaFlowAction());
  }
}
