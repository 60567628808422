import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  loadNetworkOrganisationsAction,
  loadNetworkOrganisationsSuccessAction
} from '../state-management/network-organisations.actions';

@Injectable({
  providedIn: 'root'
})
export class NetworkOrganisationsGuard implements CanActivate {
  constructor(
    private store: Store,
    private actions$: Actions
  ) {}
  
  canActivate(): Observable<boolean> {
    this.store.dispatch(loadNetworkOrganisationsAction({ start: 0, count: 9999 }));
    
    return this.actions$.pipe(
      ofType(loadNetworkOrganisationsSuccessAction),
      switchMap(() => of(true))
    );
  }
}