import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { EnvService } from '@medrecord/core';
import { Observable } from "rxjs";
import { CalendarAppointment } from '../models/interfaces/calendar-appointment.interface';
import { RequestAppointment } from '../models/interfaces/request-appointment.interface';

@Injectable()
export class AppointmentService {

  constructor(
    private httpClient: HttpClient,
    private env: EnvService
  ) { }

  getAllCarePlanAppointments(carePlanId: string): Observable<CalendarAppointment[]> {
    return this.httpClient.get<CalendarAppointment[]>(`${this.env.healthTalkConsultationManager}/api/appointment/careplan?careplanId=${carePlanId}`);
  }

  requestAppointment(request: RequestAppointment): Observable<string> {
    return this.httpClient.post<string>(`${this.env.healthTalkConsultationManager}/api/appointment`, request);
  }
}