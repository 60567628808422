<div class="d-flex align-items-center">
  <div class="back-btn col-3">
    <button ejs-button
            class="e-flat e-primary border-0 text-xl d-flex align-items-center"
            (click)="goBack()"
    >
      <i class="fas fa-chevron-circle-left"></i>
      <span class="text-weight-600 ml-2">
        {{ backTextKey | translate }}
      </span>
    </button>

  </div>

  <h3 class="text-primary text-weight-500 text-center col-9">
    {{ titleKey | translate }}
  </h3>
</div>