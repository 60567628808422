import { APP_INITIALIZER, Provider } from '@angular/core';
import { StorageService } from '@medrecord/tools-storage';
import { Store } from '@ngrx/store';
import { StorageKeys } from '@medrecord/core';
import { selectUser } from '../state-management/users-manager.actions';
import { DOCUMENT } from '@angular/common';

export function selectedUserProviderFactory(storage: StorageService<StorageKeys>, store: Store, document: Document): Function {
  return function (): Promise<void> {
    return new Promise((resolve) => {
      const currentUser = storage
        .getItem<string[]>(StorageKeys.PinnedUsers, [])
        .find((userId) => {
          return document.location.href.includes(userId);
        });
  
      if (currentUser) {
        store.dispatch(
          selectUser({ id: currentUser })
        );
      }
  
      resolve();
    });
  };
}

export function provideSelectedUser(): Provider {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: selectedUserProviderFactory,
      deps: [StorageService, Store, DOCUMENT],
      multi: true
    }
  ];
}