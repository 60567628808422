import { QuestionType } from '../models/enum';
import { Question, EnableWhen, GroupQuestion } from '../models/interfaces';
import { Coding } from '@medrecord/core';

export const isQuestionAnswered = (answerTexted: any, questionType: QuestionType): boolean => {
  const answer = answerTexted?.answer;
  if (questionType === QuestionType.display) {
    return true;
  }

  if (answer === undefined || answer === null) {
    return false;
  }

  switch (questionType) {
    case QuestionType.singleChoice:
      return answer?.length > 0;

    case QuestionType.date:
      return !!answer;

    case QuestionType.string:
      return answer?.length > 0;

    case QuestionType.boolean:
      return typeof answer === 'boolean';

    case QuestionType.decimal: {
      const decimal = parseInt(answer, 10);
      return !isNaN(decimal);
    }
  }

  return true;
};

export const getQuestionCompletionStatus = (question: Question, answers: any) => {
  if (question.type !== QuestionType.group) {
    const answered = question.isEnabled ? isQuestionAnswered(answers[question.id], question.type) : true;

    return {
      allAnswered: answered,
      requiredAnswered: question.required ? answered : true,
    };
  }

  return (question as GroupQuestion).subQuestions
    .filter((subQuestion) => subQuestion.type !== QuestionType.display && subQuestion.isEnabled)
    .map(({ id, type, required }) => ({
      answered: isQuestionAnswered(answers[id], type),
      required,
    }))
    .reduce(
      ({ allAnswered, requiredAnswered }, { answered, required }) => ({
        allAnswered: allAnswered && answered,
        requiredAnswered: required ? requiredAnswered && answered : requiredAnswered,
      }),
      {
        allAnswered: true,
        requiredAnswered: true,
      }
    );
};

export const getInitialEnabledState = (questions: Question[], answers: any) => {
  let questionsWithEnabledState = [...questions];

  let isUpdated = true;
  let maxLoopAllowed = 1000; // arbitrarily set
  while (isUpdated && maxLoopAllowed > 0) {
    isUpdated = false;
    maxLoopAllowed--;

    const flatQuestions = flatQuestionsTree(questionsWithEnabledState);

    questionsWithEnabledState = questionsWithEnabledState.map(question => {
      const questionEnabledState = getQuestionEnabledState(question, flatQuestions, answers);
      if (questionEnabledState.isIsEnabledUpdated) {
        isUpdated = true;
      }
      return questionEnabledState.question;
    });
  }

  if (maxLoopAllowed === 0) {
    console.error(
      'Questions dependency tree is too complex to be handled! Questions that should be visible might be hidden, and vice versa.'
    );
  }

  return questionsWithEnabledState;
};

export const flatQuestionsTree = (questions: Question[]): { [id: string]: boolean } => {
  let flat = {};
  for (const question of questions) {
    flat[question.id] = question.isEnabled;
    if (question.type === QuestionType.group) {
      flat = {
        ...flat,
        ...flatQuestionsTree((question as GroupQuestion).subQuestions),
      };
    }
  }

  return flat;
};

export const getQuestionEnabledState = (question: Question, questions: { [id: string]: boolean }, answers): {isIsEnabledUpdated: boolean; question: Question} => {
  let isUpdated = false;

  if (question.type === QuestionType.group) {
    const subQuestions: Question[] = (question as GroupQuestion).subQuestions.map((subQuestion) => {
      const oldIsEnabledSubQuestion = subQuestion.isEnabled;
      const newIsEnabledSubQuestion = isQuestionEnabled(subQuestion.enableWhen, questions, answers);
      if (oldIsEnabledSubQuestion !== newIsEnabledSubQuestion) {
        isUpdated = true;
      }
      return {
        ...subQuestion,
        isEnabled: newIsEnabledSubQuestion,
      };
    });
    question = {
      ...question,
      ...{
        subQuestions: subQuestions,
      },
    };
  }

  const oldIsEnabled = question.isEnabled;
  const newIsEnabled = isQuestionEnabled(question.enableWhen, questions, answers);
  if (oldIsEnabled !== newIsEnabled) {
    isUpdated = true;
  }

  return {
    question: {
      ...question,
      isEnabled: newIsEnabled,
    },
    isIsEnabledUpdated: isUpdated,
  };
};

export const removeAnswersForDisabledQuestions = (questions: Question[], answers: any) => {
  const cleanedAnswers = { ...answers };

  questions.forEach((question) => {
    if (!question.isEnabled) {
      answers[question.id] = undefined;
    }

    if (question.type === QuestionType.group) {
      (question as GroupQuestion).subQuestions.forEach((subQuestion) => {
        if (!subQuestion.isEnabled) {
          answers[subQuestion.id] = undefined;
        }
      });
    }
  });

  return cleanedAnswers;
};

export const isQuestionEnabled = (questionEnableWhen: EnableWhen[], questions: { [id: string]: boolean }, answers: any) => {
  if (!questionEnableWhen) {
    return true;
  }

  const conditions = [];
  for (const condition of questionEnableWhen) {
    if (questions[condition.linkId] === false) {
      return false;
    }
    const { type } = condition;
    const answerKey = `answer${type.slice(0, 1).toUpperCase()}${type.slice(1).toLowerCase()}`;
    const currentAnswer = answers[condition.linkId]?.answer;

    conditions.push({
      answerStateSatisfied: getAnswerStateSatisfied(condition, answerKey, currentAnswer),
      shouldHaveAnswer: condition.hasAnswer,
      hasAnswer: currentAnswer !== undefined && currentAnswer !== null,
    });
  }

  return conditions.reduce((enabled, condition) => enabled && isConditionSatisfied(condition), true);
};

export const getAnswerStateSatisfied = (condition: EnableWhen, answerKey: string, currentAnswer: any): boolean => {
  if (answerKey !== 'answerChoice') {
    return condition[answerKey] === currentAnswer;
  }

  const { answerCoding } = condition;
  const currentAnswerCoding = (currentAnswer?.[0]?.answerCoding as Partial<Coding>) ?? null;

  return answerCoding.code === currentAnswerCoding?.code;
};

export const isConditionSatisfied = ({ shouldHaveAnswer, hasAnswer, answerStateSatisfied }) => {
  if (shouldHaveAnswer === null) {
    return answerStateSatisfied;
  }

  if (shouldHaveAnswer) {
    if (!hasAnswer) {
      return false;
    }

    return answerStateSatisfied;
  }

  if (hasAnswer) {
    return answerStateSatisfied;
  }
  return true;
};
