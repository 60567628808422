import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-sleeping',
  templateUrl: './sleeping.component.svg',
  styleUrls: [],
})
export class SleepingComponent extends MedrecordIcon {
  viewHeight: number = 24;
  viewWidth: number = 24;
}
