import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { ErrorCode } from '@medrecord/core';

@Component({
  selector: 'medrecord-ui-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class DatepickerComponent implements OnInit {

  @Input() controlName: string;
  @Input() minDate: Date;
  @Input() externalErrors: ValidationErrors = {};
  @Input() errorCode: ErrorCode;
  @Input() label: string;

  control;

  constructor(
    private controlContainer: ControlContainer,
    private parentForm: FormGroupDirective) { }

  ngOnInit() {
    this.control = this.parentForm.form.get(this.controlName);

    if (!this.controlContainer.control.get(this.controlName)) {
      throw new Error(`Control '${this.controlName}' is not found in the parent form group`);
    }
  }

  get controlErrorsList(): string[] {
    return ((this.control?.dirty || this.control?.touched) && this.errors) || [];
  }

  get externalErrorsList(): string[] {
    return ((this.control?.dirty || this.control?.touched) && Object.values((this.externalErrors || {}))) || [];
  }

  get errors(): string[] {
    return Object.values(this.control?.errors || {}) || [];
  }
}