import { conditionsManagerFeatureKey, ConditionsManagerState } from './conditions-manager.reducer';
import { createSelector } from '@ngrx/store';
import { Condition } from '../models/interfaces/condition.interface';

export const getConditionsManagerState: (state: ConditionsManagerState) => any = (state: ConditionsManagerState) =>
  state[conditionsManagerFeatureKey];

export const getConditions = createSelector(
  getConditionsManagerState,
  (state: ConditionsManagerState): Condition[] => state.conditions
);

export const getCareplanConditions = createSelector(
  getConditionsManagerState,
  (state: ConditionsManagerState): Condition[] => state.careplan.conditions
);

export const getConditionsCount = createSelector(
  getConditionsManagerState,
  (state: ConditionsManagerState): number => state.count
);

export const getActiveCondition = createSelector(
  getConditionsManagerState,
  (state: ConditionsManagerState): Condition => state.activeCondition
);
