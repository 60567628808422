import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GenderEnum, User } from '@medrecord/managers-users';
import { DatePrecision } from '@medrecord/services-datetime';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '@medrecord/services-form-validation';
import { MapFieldOptions, MapFieldType } from '@medrecord/ui';
import { mapEnum } from '@medrecord/tools-utils';

@Component({
  selector: 'medsafe-common-profile-form',
  templateUrl: './common-form.component.html',
})
export class CommonFormComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() user: User;
  @Input() isBirthDateRequired:boolean=false;

  readonly mapFieldType = MapFieldType;

  readonly genderOptions: MapFieldOptions = {
    items: mapEnum(GenderEnum, (id) => ({
      id,
      text: this.translateService.instant('profile_gender_' + id.toLowerCase()),
    })),
  };

  readonly dateTimeOptions: MapFieldOptions = {
    maxDate: new Date(),
    precision: DatePrecision.day,
    enablePrecisionChange: false,
  };

  constructor(
    private fb: FormBuilder,
    private formValidatorsService: ValidationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.parentForm.registerControl(
      'birthDate',
      this.fb.control(
        this.user
          ? {
              date: this.user.birthDate,
              precision: DatePrecision.day,
            }
          : null,
        [this.formValidatorsService.required(this.translateService.instant('common_validation_required'))]
      )
    );

    this.parentForm.registerControl('gender', this.fb.control(this.user?.gender || GenderEnum.male));
  }
}
