<medsafe-dialog
  width="80%"
  (onClose)="closeDialog()"
  [visible]="visible$ | async"
  [header]="'feedback_feedback' | translate"
  [useTarget]="false"
>
  <div class="container w-100" *ngIf="visible; else loading">
    <div class="container__canvas" #canvasContainer *ngIf="!isNextStep">
      <div class="form-group">
        <medrecord-ui-field-label [text]="'feedback_drag_to' | translate"></medrecord-ui-field-label>
      </div>
      <medsafe-drawable-canvas
        [bgImage]="canvas.toDataURL()"
        [width]="canvasContainer.clientWidth"
        [height]="(canvas.height * canvasContainer.clientWidth) / this.canvas.width"
      ></medsafe-drawable-canvas>
    </div>

    <div class="w-100" *ngIf="isNextStep">
      <form [formGroup]="form" class="form">
        <div class="col-image">
          <img class="image" width="70%" [src]="form.get('imageDataUrl').value" draggable="false" alt="" />
        </div>
        <div class="col-description">
          <medrecord-ui-textarea
            [rows]="4"
            [label]="'feedback_send_us_please' | translate"
            formControlName="description"
            [placeholder]="'feedback_send_us_please' | translate"
          ></medrecord-ui-textarea>
        </div>
      </form>
    </div>
  </div>

  <hr class="w-100 section-divider" />

  <div class="row">
    <div class="col-12">
      <div class="text-right">
        <button type="button" class="btn btn-outline-black mr-3" (click)="onCancel()">
          {{ (isNextStep ? 'common_back' : 'common_cancel') | translate }}
        </button>
        <button type="button" class="btn btn-secondary" (click)="onNext()">
          {{ (isNextStep ? 'common_submit' : 'common_next') | translate }}
        </button>
      </div>
    </div>
  </div>
</medsafe-dialog>

<ng-template #loading>
  <ng-container>
    <div class="w-100 text-center">
      <i class="fa fa-spinner fa-spin text-xxl"></i>
    </div>
  </ng-container>
</ng-template>
