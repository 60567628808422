import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService, SEARCH, SearchConstants } from '@medrecord/core';
import { ValueSet } from '../models/enums/value-set.enum';

@Injectable({
  providedIn: 'root'
})
export class TerminologyService {
  constructor(
    private httpClient: HttpClient,
    private envService: EnvService,
    @Inject(SEARCH) private searchConstants: SearchConstants
  ) {}
  
  searchByValueSet(
    valueSet: ValueSet, query: string, size: number = this.searchConstants.DEFAULT_SEARCH_SIZE
  ): Observable<any> {
    const params = new HttpParams()
      .append('token', query)
      .append('count', String(size))
      .append('valueSet', valueSet)
    ;
    return this.httpClient.get(this.envService.backend + '/terminology/codeableconcept/search', { params });
  }
}