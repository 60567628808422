import { createAction, props } from '@ngrx/store';
import { SaveModalType } from '../../models/enum';
import { HealthcareProviderResource, Questionnaire } from '../../models/interfaces';
import { featurePrefix } from './feature-prefix';

export const loadQuestionnaire = createAction(
  `${ featurePrefix } Load Questionnaire`,
  props<{
    questionnaireId: string;
    taskId?: string;
    loadAnswers?: boolean;
  }>()
);

export const loadQuestionnaireSuccess = createAction(
  `${ featurePrefix } Load Questionnaire Success`,
  props<{
    questionnaireId: string;
    questionnaireData: HealthcareProviderResource;
    answers: any;
  }>()
);

export const loadQuestionnaireFailure = createAction(
  `${ featurePrefix } Load Questionnaire Failure`,
  props<{
    questionnaireId: string;
    error: any;
  }>()
);

export const saveQuestionnaireTask = createAction(
  `${ featurePrefix } Save Questionnaire Task`,
  props<{
    questionnaireId: string;
    routeSnapshot: any;
  }>()
);


export const saveQuestionnaireTaskSuccess = createAction(
  `${ featurePrefix } Save Questionnaire Task Success`,
  props<{
    taskId: string;
    questionnaireId: string;
  }>()
);

export const autoSaveQuestionnaireTask = createAction(
  `${ featurePrefix } Auto Save Questionnaire Task`,
  props<{
    questionnaireId: string;
    routeSnapshot: any;
  }>()
);

export const autoSaveQuestionnaireTaskSuccess = createAction(
  `${ featurePrefix } Auto Save Questionnaire Task Success`,
  props<{
    taskId: string;
    questionnaireId: string;
  }>()
);

export const saveQuestionnaireTaskFailure = createAction(
  `${ featurePrefix } Save Questionnaire Task Failure`,
  props<{
    taskId: string;
    questionnaireId: string;
    error: any;
  }>()
);


export const questionnaireLoaded = createAction(
  `${ featurePrefix } Questionnaire loaded`,
  props<{
    id: string;
    questionnaire: Questionnaire;
  }>()
);

export const selectQuestion = createAction(
  `${ featurePrefix } Question selected`,
  props<{
    questionId: string;
    questionnaireId: string;
  }>()
);

export const selectPrevQuestion = createAction(
  `${ featurePrefix } Select previous question`,
  props<{
    questionnaireId: string;
  }>()
);

export const selectNextQuestion = createAction(
  `${ featurePrefix } Select next question`,
  props<{
    questionnaireId: string;
  }>()
);

export const saveQuestionAnswer = createAction(
  `${ featurePrefix } Save answer`,
  props<{
    questionnaireId: string;
    routeSnapshot: any;
    questionId: string;
    answer: any;
  }>()
);

export const clearQuestionnaire = createAction(
  `${ featurePrefix } Clear Questionnaire`,
  props<{
    questionnaireId: string;
  }>()
);

export const showSaveModal = createAction(
  `${ featurePrefix } Show Save Modal`,
  props<{
    questionnaireId: string;
    doctor: string,
    title: string,
    scope: string,
    routeSnapshot: any;
    saveModalType: SaveModalType;
  }>()
);

export const setIDKForQuestion = createAction(
  `${ featurePrefix } Set IDK for question`,
  props<{
    questionnaireId: string;
    questionId: string;
    answer: string
  }>()
);

export const saveQuestionnaire = createAction(
  `${ featurePrefix } Save Questionnaire`,
  props<{
    questionnaireId: string;
    scope: string;
    routeSnapshot?: any;
  }>()
);

export const autoSaveQuestionnaire = createAction(
  `${ featurePrefix } Auto Save Questionnaire`,
  props<{
    questionnaireId: string;
    scope: string;
    routeSnapshot?: any;
  }>()
);
