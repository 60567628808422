<h3 class="text-weight-600 text-primary mb-3 text-center mt-5">{{ title | translate }}</h3>

<form [formGroup]="form" (ngSubmit)="onSubmit()" class="text-center">
  <div class="mt-3">
    <medrecord-ui-map-field
      [attr.id]="isOAuth && 'oauth-password-field'"
      [fieldType]="mapFieldType.Input"
      [type]="showPassword ? 'text' : 'password'"
      [label]="'auth_reset_credentials_new_password_label' | translate"
      controlName="newPassword"
      autocomplete="new-password"
      [parentForm]="form"
    >
      <ng-template #leftIcon>
        <i class="fas fa-lock text-black"></i>
      </ng-template>

      <ng-template #rightIcon>
        <i
          class="fas fa-eye text-black cursor-pointer"
          [class.fa-eye]="!showPassword"
          [class.fa-eye-slash]="showPassword"
          (click)="showPassword = !showPassword"
        ></i>
      </ng-template>
    </medrecord-ui-map-field>
  </div>

  <div class="mt-3 pb-3">
    <medrecord-ui-map-field
      [attr.id]="isOAuth && 'oauth-password-repeat-field'"
      [fieldType]="mapFieldType.Input"
      [type]="showRepeatPassword ? 'text' : 'password'"
      [label]="'auth_credentials_repeat_new_password_label' | translate"
      controlName="repeatPassword"
      [parentForm]="form"
    >
      <ng-template #leftIcon>
        <i class="fas fa-lock text-black"></i>
      </ng-template>

      <ng-template #rightIcon>
        <i
          class="fas fa-eye text-black cursor-pointer"
          [class.fa-eye]="!showRepeatPassword"
          [class.fa-eye-slash]="showRepeatPassword"
          (click)="showRepeatPassword = !showRepeatPassword"
        ></i>
      </ng-template>
    </medrecord-ui-map-field>
  </div>

  <button
    [class.btn-rounded]="isOAuth"
    class="btn btn-primary btn-block text-weight-600"
    [disabled]="!form.valid"
    type="submit"
  >
    {{ 'common_form_confirm' | translate }}
  </button>
</form>
