import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@medrecord/core';
import { Observable } from 'rxjs';
import { IWeatherData } from '../models/weather-data.model';

@Injectable()
export class WeatherService {
  constructor(private http: HttpClient, private envService: EnvService) {}

  getCurrentWeather(queryParams): Observable<any> {
    let params = new HttpParams();

    Object.keys(queryParams).forEach((key) => {
      params = params.set(key, queryParams[key]);
    });

    return this.http.get<any>(`${this.envService.weatherApi}current.json?key=${this.envService.weatherApiKey}&`, {
      params,
    });
  }

  getForecastWeather(queryParams): Observable<IWeatherData> {
    let params = new HttpParams();

    Object.keys(queryParams).forEach((key) => {
      params = params.set(key, queryParams[key]);
    });

    return this.http.get<any>(`${this.envService.weatherApi}forecast.json?key=${this.envService.weatherApiKey}&`, {
      params,
    });
  }
}
