import { Component, Input } from '@angular/core';
import { AbstractModalComponent } from '../abstract-modal/abstract-modal.component';
import { CareproviderLog } from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-logs-modal',
  templateUrl: 'logs-modal.component.html',
  styleUrls: ['logs-modal.component.scss']
})
export class LogsModalComponent extends AbstractModalComponent {
  @Input() logs: CareproviderLog[];
}
