import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { loadUsersAction, loadUsersSuccessAction } from '../state-management/users-management.actions';

@Injectable({
  providedIn: 'root'
})
export class UsersManagementGuard implements CanActivate {
  constructor(
    private store: Store,
    private actions$: Actions
  ) {}
  
  canActivate(): Observable<boolean> {
    this.store.dispatch(loadUsersAction({ start: 0, count: 20 }));
    
    return this.actions$.pipe(
      ofType(loadUsersSuccessAction),
      switchMap(() => of(true))
    );
  }
}
