import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { StorageService } from '@medrecord/tools-storage';
import { StorageKeys } from '@medrecord/core';
import { Store } from '@ngrx/store';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import {
  loadUserMetaData,
  loadUserMetadataFailureAction,
  loadUserMetadataSuccessAction,
  loadUserProfileAction,
  loadUsersFailureAction,
  loadUsersSuccessAction,
  selectUser,
} from '../state-management/users-manager.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Permission } from '../models/enums';
import { getSelectedUserMetadata, selectCurrentID } from '../state-management/users-manager.selectors';
import { Role, selectRoles } from '@medrecord/managers-auth';

@Injectable()
export class GetUserMetadataGuard implements CanActivate {
  constructor(private store: Store, private actions: Actions, private storage: StorageService<StorageKeys>) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.storage.getItem(StorageKeys.Auth)?.userId) {
      return of(false);
    }

    return this.store.select(selectCurrentID).pipe(
      withLatestFrom(this.store.select(selectRoles), this.store.select(getSelectedUserMetadata)),
      switchMap(([userId, roles, metadata]) => {
        if (!userId || !metadata?.user || !metadata?.permission) {
          if (roles.includes(Role.Patient) || roles.includes(Role.Buddy)) {
            this.store.dispatch(selectUser({ id: this.storage.getItem(StorageKeys.Auth).userId }));
            this.store.dispatch(
              loadUserMetaData({
                id: this.storage.getItem(StorageKeys.Auth).userId,
                permission: Permission.MainAccount,
              })
            );

            return this.actions.pipe(
              ofType(loadUserMetadataSuccessAction, loadUserMetadataFailureAction),
              switchMap((action) => (action.type === loadUserMetadataFailureAction.type ? of(false) : of(true)))
            );
          }
        }

        if (!roles.includes(Role.Patient) || !roles.includes(Role.Buddy)) {
          if (!userId) this.store.dispatch(selectUser({ id: this.storage.getItem(StorageKeys.Auth).userId }));
          this.store.dispatch(
            loadUserProfileAction({
              id: this.storage.getItem(StorageKeys.Auth).userId,
            })
          );

          return this.actions.pipe(
            ofType(loadUsersSuccessAction, loadUsersFailureAction),
            switchMap((action) => (action.type === loadUsersFailureAction.type ? of(false) : of(true)))
          );
        }

        return of(true);
      })
    );
  }
}
