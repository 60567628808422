import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvService, ObservationType } from '@medrecord/core';
import { ObservationThresholdItem } from '../models/interfaces';

@Injectable()
export class ThresholdsService {
  constructor(private http: HttpClient, private envService: EnvService) {}

  getThresholds(
    userId: string,
    allowedObservationTypes: ObservationType[],
    carePlanId?: string
  ): Observable<{ data: ObservationThresholdItem[]; healthScore: string }> {
    return this.http.get<{ data: ObservationThresholdItem[]; healthScore: string }>(
      `${
        this.envService.backend
      }/patient/${userId}/observation/thresholds?observationTypes=${allowedObservationTypes.join(',')}&carePlanId=${
        carePlanId ?? ''
      }`
    );
  }
}
