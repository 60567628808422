import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const generalMeasurementBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.observation,
  fieldGroups: [
    {
      name: ({ resource }) => {
        const code = resource?.content?.code?.coding?.[0]?.code;
        const title = resource?.content?.code?.coding?.[0]?.display;

        if (!code) return title;
        if (!title) return code;

        return code && title ? `${code} - ${title}` : 'my_doctor_measurement';
      },
      fields: [
        {
          label: 'my_doctor_measurement_value',
          fieldName: 'value',
          prepareData: (data) => data?.value,
        },
        {
          label: 'my_doctor_result_date_time',
          fieldName: 'effective',
          prepareData: (data) => (data?.date ? MedrecordMoment(data.date).format('LL') : ''),
        },
      ],
    },
  ],
});
