import { Immunization } from '../models/interfaces/immunization.interface';
import { Action, createReducer, on } from '@ngrx/store';
import {
  addImmunizationSuccessAction,
  clearActiveImmunization,
  deleteImmunizationSuccessAction,
  editImmunizationSuccessAction,
  loadCareplanImmunizationsSuccessAction,
  loadImmunizationLocationsFailureAction,
  loadImmunizationsFailureAction,
  loadImmunizationsSuccessAction,
  loadImmunizationSuccessAction,
  removeCareplanImmunizationAction,
} from './immunizations-manager.actions';

export const immunizationsManagerFeatureKey = 'immunizations-manager';

export interface ImmunizationsManagerState {
  error: any;
  immunizations: Immunization[];
  count: number;
  activeImmunization: Immunization;
  careplan: {
    immunizations: Immunization[];
    count: number;
  };
}

export const initialState: ImmunizationsManagerState = {
  error: null,
  immunizations: [],
  activeImmunization: null,
  count: 0,
  careplan: {
    immunizations: [],
    count: 0,
  },
};

export const immunizationsManagerReducer = createReducer(
  initialState,
  on(loadImmunizationsSuccessAction, (state, { immunizations, count }) => ({
    ...state,
    immunizations,
    count,
  })),
  on(loadCareplanImmunizationsSuccessAction, (state, { immunizations, count }) => ({
    ...state,
    careplan: {
      immunizations,
      count,
    },
  })),
  on(deleteImmunizationSuccessAction, (state, { id }) => ({
    ...state,
    immunizations: state.immunizations.filter((i) => i.id !== id),
    count: state.count - 1,
    careplan: {
      immunizations: state.careplan.immunizations.filter((i) => i.id !== id),
      count: state.careplan.count - 1,
    },
  })),
  on(addImmunizationSuccessAction, (state, { immunization }) => ({
    ...state,
    activeImmunization: immunization,
    count: state.count + 1,
    immunizations: [immunization, ...state.immunizations],
    careplan: {
      immunizations: [immunization, ...state.careplan.immunizations],
      count: state.careplan.count + 1,
    },
  })),
  on(editImmunizationSuccessAction, (state, { immunization }) => ({
    ...state,
    activeImmunization: immunization,
    immunizations: state.immunizations.map((i) => (i.id === immunization.id ? immunization : i)),
    careplan: {
      ...state.careplan,
      immunizations: state.careplan.immunizations.map((i) => (i.id === immunization.id ? immunization : i)),
    },
  })),
  on(loadImmunizationSuccessAction, (state, { immunization }) => ({
    ...state,
    activeImmunization: immunization,
  })),
  on(clearActiveImmunization, (state) => ({
    ...state,
    activeImmunization: null,
  })),
  on(loadImmunizationsFailureAction, loadImmunizationLocationsFailureAction, (state, { error }) => ({
    ...state,
    error,
  })),
  on(removeCareplanImmunizationAction, (state, { id }) => ({
    ...state,
    careplan: {
      immunizations: state.careplan.immunizations.filter((i) => i.id !== id),
      count: state.careplan.count - 1,
    },
  }))
);

export const reducer = (state: ImmunizationsManagerState, action: Action): ImmunizationsManagerState => {
  return immunizationsManagerReducer(state, action);
};
