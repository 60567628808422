import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MedrecordMoment } from '@medrecord/services-datetime';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  readonly emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  readonly numberPattern = '^[-]{0,1}[0-9.]*$';
  readonly floatNumberPattern = '[+-]?([0-9]*[.,])?[0-9]+';
  readonly numberDosagePattern = /^\d+(([,.]\d+)|(\/\d+))?$/;
  readonly phonePattern = /^\+\d{10,13}$/;
  readonly authCodePattern = /^\d{4}$/;
  readonly passwordPattern = /((?=.*[a-z])(?=.*\d)(?=.*[A-Z])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$)/;
  readonly alphabeticPattern = /^[a-zA-ZçÇğĞıİöÖşŞüÜ]+$/; // in case of turkish characters exist. If not we can use /^[a-zA-Z]+$/;

  pattern(pattern: RegExp, message: string): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      return this.applyErrorMessage(Validators.pattern(pattern)(formControl), message);
    };
  }

  alphabetic(message: string): ValidatorFn {
    return this.pattern(new RegExp(this.alphabeticPattern), message);
  }

  number(message: string): ValidatorFn {
    return this.pattern(new RegExp(this.numberPattern), message);
  }

  min(min: number, message: string): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      return this.applyErrorMessage(Validators.min(min)(formControl), message);
    };
  }

  exactLength(length: number, message: string): ValidatorFn {
    const pattern = '^.{' + length + '}$';
    return this.pattern(new RegExp(pattern), message);
  }

  max(max: number, message: string): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      return this.applyErrorMessage(Validators.max(max)(formControl), message);
    };
  }

  numberDosage(message: string): ValidatorFn {
    return this.pattern(new RegExp(this.numberDosagePattern), message);
  }

  phoneNumber(message: string): ValidatorFn {
    return this.pattern(new RegExp(this.phonePattern), message);
  }

  twoFactorAuthCode(message: string): ValidatorFn {
    return this.pattern(new RegExp(this.authCodePattern), message);
  }

  passwordComplexity(message: string): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      return this.applyErrorMessage(Validators.pattern(new RegExp(this.passwordPattern))(formControl), message);
    };
  }

  float(message: string): ValidatorFn {
    return this.pattern(new RegExp(this.floatNumberPattern), message);
  }

  required(message: string): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      return this.applyErrorMessage(Validators.required(formControl), message);
    };
  }

  email(message: string): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      return this.applyErrorMessage(Validators.pattern(new RegExp(this.emailPattern))(formControl), message);
    };
  }

  maxLength(maxLength: number, message: string): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      return this.applyErrorMessage(Validators.maxLength(maxLength)(formControl), message);
    };
  }

  minLength(minLength: number, message: string): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      return this.applyErrorMessage(Validators.minLength(minLength)(formControl), message);
    };
  }

  requiredTrue(message: string): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      return this.applyErrorMessage(Validators.requiredTrue(formControl), message);
    };
  }

  futureDate(startDateFieldName: string, dueDateFieldName: string, message: string): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const startDate = form.controls[startDateFieldName].value;
      const endDate = form.controls[dueDateFieldName].value;

      if (MedrecordMoment(endDate).isBefore(startDate)) {
        return {
          isBeforeStartDate: message,
        };
      }

      return null;
    };
  }

  pastDate(startDateFieldName: string, dueDateFieldName: string, message: string): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const startDate = form.controls[startDateFieldName].value;
      const endDate = form.controls[dueDateFieldName].value;

      if (MedrecordMoment(endDate).isBefore(startDate)) {
        return {
          isBeforeStartDate: message,
        };
      }

      return null;
    };
  }

  limit(limit: number, message): ValidatorFn {
    return (formControl: AbstractControl): ValidationErrors | null => {
      if (formControl.value.length <= limit) {
        return null;
      }

      return {
        moreThenLimit: message,
      };
    };
  }

  shouldBeEqual(comparingFirstControlKey, comparingSecondControlKey, message): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      if (formGroup.get(comparingFirstControlKey).value === formGroup.get(comparingSecondControlKey).value) {
        const errors = formGroup.get(comparingSecondControlKey).errors;

        if (errors && Object.keys(errors)?.length > 1) {
          formGroup.get(comparingSecondControlKey).setErrors({
            ...errors,
            notEqual: undefined,
          });
        } else {
          formGroup.get(comparingSecondControlKey).setErrors(null);
        }

        return null;
      }

      formGroup.get(comparingSecondControlKey).setErrors({
        notEqual: message,
      });

      console.log(formGroup);

      return {
        notEqual: message,
      };
    };
  }

  applyErrorMessage(validationResult: ValidationErrors | null, message: string): ValidationErrors | null {
    for (const key in validationResult) {
      if (validationResult.hasOwnProperty(key)) {
        validationResult[key] = message;
      }
    }

    return validationResult;
  }
}
