import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import * as UserStatusActions from '../state-management/user-status.actions';
import { getUserStatuses } from '../state-management/user-status.selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[medrecordAppendUserStatus]',
})
export class AppendUserStatusDirective implements OnInit, OnDestroy {
  @Input() userId: string;
  
  private unsubscribe$: Subject<number> = new Subject();
  
  constructor(
    private store: Store,
    private eleRef: ElementRef,
    private renderer: Renderer2
  ) {
  }
  
  ngOnInit(): void {
    this.store.dispatch(UserStatusActions.loadUserStatusAction({ userId: this.userId }));
    
    const status = this.renderer.createElement('div');
    const statusBackground = this.renderer.createElement('div');
    
    this.renderer.appendChild(statusBackground, status);
    
    this.renderer.setStyle(this.eleRef.nativeElement, 'position', 'relative');
    this.renderer.appendChild(this.eleRef.nativeElement, statusBackground);
    
    this.store.select(getUserStatuses)
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((userStatuses) => {
        if (userStatuses[this.userId]) {
          this.renderer.addClass(statusBackground, `status-background`);
          this.renderer.addClass(status, `status-${ userStatuses[this.userId].status }`);
        }
      });
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next(0);
    this.unsubscribe$.complete();
  }
}
