<div class="form-group w-100">
  <div
    [class.warning]="controlErrorsList.length"
    [class.error]="errorCode || externalErrorsList.length"
    class="input-group w-100"
  >
    <ngx-intl-tel-input
      [preferredCountries]="preferredCountries"
      [enableAutoCountrySelect]="true"
      [enablePlaceholder]="true"
      [searchCountryFlag]="true"
      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
      [selectFirstCountry]="false"
      [selectedCountryISO]="CountryISO.Netherlands"
      [maxLength]="'15'"
      [phoneValidation]="true"
      [separateDialCode]="separateDialCode"
      [numberFormat]="PhoneNumberFormat.National"
      (blur)="checkIfTouched()"
      [(ngModel)]="value"
      [id]="id"
      [name]="control?.name"
      [disabled]="disabled"
      #input
    >
    </ngx-intl-tel-input>

    <ng-content></ng-content>
  </div>

  <medrecord-ui-field-error
    [errors]="controlErrorsList"
    [errorCode]="errorCode"
    [externalErrors]="externalErrorsList"
  ></medrecord-ui-field-error>
</div>
