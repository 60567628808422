import { Injectable } from '@angular/core';
import { FormFactory, MapFieldType } from '@medrecord/ui';
import { UserName } from '@medrecord/managers-users';
import { ProfileFormType } from '../typings/enums';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '@medrecord/services-form-validation';

@Injectable()
export class ProfileNameFormFactory extends FormFactory<UserName> {
  fields = [
    {
      name: 'firstName',
      calculate: () => ({
        type: MapFieldType.Input,
        label: this.translateService.instant('common_form_field_first_name'),
        placeholder: this.translateService.instant('common_form_field_first_name'),
        hint: this.translateService.instant('common_form_field_first_name'),
        fieldClass: 'w-50'
      })
    },
    {
      name: 'suffix',
      calculate: () => ({
        type: MapFieldType.Input,
        label: this.translateService.instant('common_form_field_suffix'),
        secondaryLabel: this.translateService.instant('common_optional'),
        placeholder: this.translateService.instant('common_form_field_suffix'),
        hint: this.translateService.instant('common_form_field_suffix'),
        fieldClass: 'w-25 ml-4'
      })
    },
    {
      name: 'lastName',
      calculate: () => ({
        type: MapFieldType.Input,
        label: this.translateService.instant('common_form_field_last_name'),
        placeholder: this.translateService.instant('common_form_field_last_name'),
        hint: this.translateService.instant('common_form_field_last_name'),
        fieldClass: 'w-50'
      })
    },
    {
      name: 'lastNamePrefix',
      calculate: () => ({
        type: MapFieldType.Input,
        label: this.translateService.instant('common_form_field_last_name_prefix'),
        secondaryLabel: this.translateService.instant('common_optional'),
        placeholder: this.translateService.instant('common_form_field_prefix'),
        hint: this.translateService.instant('common_form_field_prefix'),
        fieldClass: 'w-25 mr-4'
      })
    },
    {
      name: 'married',
      calculate: () => ({
        type: MapFieldType.CheckBox,
        label: this.translateService.instant('common_form_field_married'),
        fieldClass: 'w-100 mt-3 mb-3'
      })
    },
    {
      name: 'partnerLastName',
      calculate: () => ({
        type: MapFieldType.Input,
        bindVisibility: (form) => () => !!form.controls['married'].value,
        label: this.translateService.instant('common_form_field_partner_last_name'),
        placeholder: this.translateService.instant('common_form_field_last_name'),
        hint: this.translateService.instant('common_form_field_partner_last_name'),
        fieldClass: 'w-50 mx-2'
      })
    },
    {
      name: 'partnerLastNamePrefix',
      calculate: () => ({
        type: MapFieldType.Input,
        bindVisibility: (form) => () => !!form.controls['married'].value,
        label: this.translateService.instant('common_form_field_partner_last_name_prefix'),
        secondaryLabel: this.translateService.instant('common_optional'),
        placeholder: this.translateService.instant('common_form_field_prefix'),
        hint: this.translateService.instant('common_form_field_partner_last_name_prefix'),
        cssClass: 'mr-2',
        fieldClass: 'w-25'
      })
    }
  ];
  
  forms = {
    [ProfileFormType.User]: (model) => this.fb.group({
      firstName: [model?.firstName, [this.formValidatorsService.required(
        this.translateService.instant("common_validation_required")
      ),this.formValidatorsService.alphabetic("common_onboard_alphabetical")]],
      suffix: [model?.suffix, [this.formValidatorsService.alphabetic("common_onboard_alphabetical")]],
      lastNamePrefix: [model?.lastNamePrefix, [this.formValidatorsService.alphabetic("common_onboard_alphabetical")]],
      lastName: [model?.lastName, [this.formValidatorsService.required(
        this.translateService.instant("common_validation_required")
      ),this.formValidatorsService.alphabetic("common_onboard_alphabetical")]],
      married: [!!model?.partnerLastName, []],
      partnerLastNamePrefix: [model?.partnerLastNamePrefix, [this.formValidatorsService.alphabetic("common_onboard_alphabetical")]],
      partnerLastName: [model?.partnerLastName, [this.formValidatorsService.alphabetic("common_onboard_alphabetical")]],
    })
  };
  
  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private formValidatorsService: ValidationService,
  ) {
    super();
  }
}