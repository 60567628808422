<div
  [ngClass]="link ? 'avatar' : 'default-avatar'"
  [class.large]="large"
  [class.middle]="middle"
  [class.small]="small"
  [class.x-small]="xSmall"
  [class.xx-small]="xxSmall"
  [class.light]="isWhite"
  [class.dark]="!isWhite"
>
  <img *ngIf="link; else emptyAvatar" [attr.alt]="'Avatar'" [attr.src]="link | safeUrl" class="h-100 w-100" />

  <ng-template #emptyAvatar>
    <i
      class="fas fa-user-alt"
      [class.text-secondary]="isWhite"
      [class.text-white]="!isWhite"
      [class.fa-2x]="middle"
      [class.fa-3x]="large"
      [class.fa]="small"
    ></i>
  </ng-template>
</div>
