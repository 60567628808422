import { Component, ContentChildren, Input, Optional, Self, TemplateRef, ViewChild } from '@angular/core';
import { InputType } from '../../../models/types/input.type';
import { NgControl } from '@angular/forms';
import { NgControlComponentBase } from '../../../ui-abstractions/ng-control-component-base.component';
import { InputValue } from '../../../models/types/input-value.type';

@Component({
  selector: 'medrecord-ui-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent extends NgControlComponentBase {
  @ViewChild('input') input;

  @Input() placeholder = '';
  @Input() type: InputType;
  @Input() mask: string;
  @Input() min: number;
  @Input() max: number;
  @Input() pattern: string;
  @Input() step: number;
  @Input() set defaultValue(value: any) {
    this.value = value + '';
  }

  @ContentChildren('rightIcon') rightIcons: TemplateRef<any>[];
  @ContentChildren('leftIcon') leftIcons: TemplateRef<any>[];
  @ContentChildren('rightButton') rightButtons: TemplateRef<any>[];
  @ContentChildren('leftButton') leftButtons: TemplateRef<any>[];

  get hasRightIcons(): boolean {
    return (
      !!this.rightIcons.length ||
      !!this.controlErrorsList.length ||
      !!this.errorCode ||
      !!this.externalErrorsList.length
    );
  }

  constructor(@Self() @Optional() public control: NgControl) {
    super(control);
  }

  checkIfTouched(): void {
    if (this.input.touched) {
      this.onTouched();
    }
  }

  writeValue(value: InputValue): void {
    this.value = typeof value === 'object' ? '' : value;
  }
}
