import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Careprovider, FetchResourceResponse, getActiveCareprovider, getFetchResourceResponse } from '@managers/my-doctor';
import { getSelectedUserPermission, Permission } from '@medrecord/managers-users';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'doctor-gui-medsafe-fetch-resource-response-container',
  templateUrl: './fetch-resource-response-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FetchResourceResponseContainerComponent {
  permission$: Observable<Permission> = this.store.select(getSelectedUserPermission);
  careprovider$: Observable<Careprovider> = this.store.select(getActiveCareprovider);
  resources$: Observable<FetchResourceResponse[]> = this.store.select(getFetchResourceResponse);

  constructor(
    private store: Store,
    private router: Router,
    private _location: Location
  ) {
  }

  goBack(): void {
    this.router.navigate([...this.router.url.split('/').slice(0, -1)]);
  }

  redirect(): void {
    this._location.back();
  }
}
