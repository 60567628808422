export enum CareplanCategories {
  FOOD_FRIEND = 'food-friend',
  ASTHMA = 'asthma',
  CLUB_DIABETES = 'club-diabetes',
  LIFESTYLE_HUB = 'lifestyle-hub',
  WILLEM_TRIP = 'willem-trip',
  DEMICARE = 'demicare',
  SYMPHONY = 'symphony',
  NEW_LIFE = 'new-life',
}
