import { Pipe, PipeTransform } from '@angular/core';
import { ContactUserData } from '@medrecord/managers-users';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'getContactLabel',
})
export class GetContactLabelPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(contact: ContactUserData): unknown {
    return this.getTranslation(contact.use) + ' / ' + this.getTranslation(contact.system);
  }

  getTranslation(value: string): string {
    return value ? this.translateService.instant('profile_' + value.toLowerCase()) : value;
  }
}
