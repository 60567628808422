import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'doctor-gui-careprovider-details-page',
  templateUrl: './careprovider-details-page.component.html',
  styleUrls: ['./careprovider-details-page.component.scss']
})
export class CareproviderDetailsPageComponent {
  @Input() careproviderName: string;
  @Input() dataserviceId: string;
  @Output() backToCareproviders: EventEmitter<void> = new EventEmitter<void>();
  @Output() loadLogs: EventEmitter<void> = new EventEmitter<void>();
  @Output() openDeleteDataModal: EventEmitter<void> = new EventEmitter<void>();

  navigateBackToCareproviders(): void {
    this.backToCareproviders.emit();
  }

  getLogsAndOpenModal(): void {
    this.loadLogs.emit();
  }

  deleteData(): void {
    this.openDeleteDataModal.emit();
  }
}
