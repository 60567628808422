import { Injectable } from '@angular/core';
import { HGraphCircle, HGraphConfig, HGraphModel } from '../models/interfaces';
import { ObservationThresholdItem } from '@managers/measurements';

@Injectable()
export class HGraphUtils {
  hGraphModel: HGraphModel;
  
  private circlesCount: number;
  private config: HGraphConfig;
  
  configure(config: HGraphConfig): void {
    this.config = config;
    this.circlesCount = config.circleClasses?.length;
    this.calculateHGraphModel();
  }
  
  getOffset(item: ObservationThresholdItem): number {
    const maxThreshold = 33;
    const minThreshold = -33;
    const circleOnePercent = (this.hGraphModel.circleMaxValue - this.hGraphModel.circleMinValue) / 100;
    const value = item.score || 0;
    const thresholdsDifference = maxThreshold - minThreshold;
    const innerCircleThreshold = minThreshold - thresholdsDifference;
    const outerCircleThreshold = maxThreshold + thresholdsDifference;
    
    if (value <= innerCircleThreshold) {
      return this.hGraphModel.circleMinValue;
    }
    
    if (value >= outerCircleThreshold) {
      return this.hGraphModel.circleMaxValue;
    }
    
    const inputValueMinCircleThresholdDifference = value - innerCircleThreshold;
    const circlesThresholdsDifferences = outerCircleThreshold - innerCircleThreshold;
    const thresholdOffset = inputValueMinCircleThresholdDifference / circlesThresholdsDifferences * 100;
    
    return thresholdOffset * circleOnePercent + this.hGraphModel.circleMinValue;
  }
  
  private calculateHGraphModel(): void {
    const outerBorderRadius = this.getCircle(this.circlesCount).radius - 30;
    
    this.hGraphModel = {
      circles: Array.from(
        { length: this.circlesCount },
        (_, circleNumber) => this.getCircle(circleNumber)
      ),
      transitionDuration: 500,
      circleMaxValue: 1,
      circleMinValue: 0.39,
      graphDiameter: outerBorderRadius * 2,
      graphSize: this.getCircle(this.circlesCount - 1).radius * 2,
      translate: {
        x: 0,
        y: 0
      },
      centerCoords: {
        x: outerBorderRadius,
        y: outerBorderRadius
      },
      radar: {
        radius: this.config.radarRadius
      }
    };
  }
  
  
  private getCircle(circleNumber: number): HGraphCircle {
    return {
      radius: this.config.initialRadius + circleNumber * (this.config.strokeWidth + this.config.gapSize),
      className: this.config.circleClasses[circleNumber],
      strokeWidth: this.config.strokeWidth
    };
  }
}