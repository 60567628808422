import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService } from '@medrecord/services-form-validation';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { checkPhoneCode, getPhoneCode } from '@medrecord/managers-auth';
import { MapFieldType } from '@medrecord/ui';
import { StorageService } from '@medrecord/tools-storage';
import { StorageKeys } from '@medrecord/core';

@Component({
  selector: 'medrecord-auth-phone-code',
  templateUrl: './phone-code.component.html',
  styleUrls: ['./phone-code.component.scss'],
})
export class PhoneCodeComponent implements OnInit {
  form: FormGroup;
  session: string;

  readonly mapFieldType = MapFieldType;
  readonly isOAuth = this.storage.getItem<boolean>(StorageKeys.isOAuth, false);

  constructor(
    fbBuilder: FormBuilder,
    validationService: ValidationService,
    translateService: TranslateService,

    private storage: StorageService<StorageKeys>,
    private store: Store
  ) {
    this.form = fbBuilder.group({
      code: [
        null,
        [
          validationService.required(translateService.instant('auth_validation_error_required')),
          validationService.pattern(/^[\d]{4}$/, translateService.instant('error_wrong_verification_code')),
        ],
      ],
    });
  }

  ngOnInit(): void {
    this.store.dispatch(getPhoneCode());
  }

  resend(): void {
    this.store.dispatch(getPhoneCode());
  }

  onNext(): void {
    if (this.form.valid) {
      this.store.dispatch(checkPhoneCode({ code: this.form.value.code }));
    }
  }
}
