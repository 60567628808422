import * as healthplansConstants from './healthplans.constants';
import { Provider } from '@angular/core';
import { HEALTHPLANS_MANAGER_CONSTANTS } from '../healthplans-manager.tokens';

export const constants = {
  ...healthplansConstants
};

export type HealthplansManagerConstants = typeof constants;

export const provideHealthplansManagerConstants = (): Provider => [
  {
    provide: HEALTHPLANS_MANAGER_CONSTANTS,
    useValue: constants
  }
];