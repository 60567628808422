import { createAction, props } from '@ngrx/store';
import { BehaviorSubject, ReplaySubject, Subject, Subscription } from 'rxjs';

export const unsubscribeAction = createAction(
  '[SUBSCRIPTIONS] Unsubscribe',
  props<{ subscriptions?: Set<Subscription> }>()
);

export const completeSubjectAction = createAction(
  '[SUBSCRIPTIONS] Complete Subject',
  props<{ subject: BehaviorSubject<any> | Subject<any> | ReplaySubject<any> }>()
);
