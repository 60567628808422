<button class="button btn btn-outline-black font-weight-bold" (click)="openDialog($event)" type="button">
  {{ 'my_doctor_portability_report' | translate | titlecase }}
</button>

<ejs-dialog [visible]="isOpen" [isModal]="true" (overlayClick)="isOpen = false" width="30%">
  <ng-template #header>
    <div class="px-2">
      <span>{{ 'my_doctor_portability_report' | translate }}</span>
    </div>
  </ng-template>

  <ng-template #content>
    <div class="px-2 mx-0">
      <form [formGroup]="form">
        <div class="row">
          <div class="col-12">
            <medrecord-ui-datepicker-precision
              [hint]="'common_start_date' | translate"
              [label]="'common_start_date' | translate"
              [calendarViewDepth]="'Month'"
              [startCalendarView]="'Year'"
              [precision]="datePrecision.day"
              [enablePrecisionChange]="false"
              formControlName="startDate"
            ></medrecord-ui-datepicker-precision>
          </div>

          <div class="col-12 mt-2">
            <medrecord-ui-datepicker-precision
              [hint]="'common_end_date' | translate"
              [label]="'common_end_date' | translate"
              [calendarViewDepth]="'Month'"
              [startCalendarView]="'Year'"
              [precision]="datePrecision.day"
              [enablePrecisionChange]="false"
              formControlName="endDate"
            ></medrecord-ui-datepicker-precision>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #footerTemplate>
    <div class="px-2 d-flex align-items-center justify-content-end">
      <button (click)="formSubmit()" class="btn btn-secondary font-weight-bold">
        {{ 'common_download' | translate }}
      </button>
    </div>
  </ng-template>
</ejs-dialog>
