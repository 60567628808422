import { networkManagerFeatureKey, NetworkManagerState } from './network-manager.reducer';
import { createSelector } from '@ngrx/store';
import { User } from '@medrecord/managers-users';

export const getNetworkManagerState = (state: NetworkManagerState) => state[networkManagerFeatureKey];

export const getUserNetwork = createSelector(
  getNetworkManagerState, (state: NetworkManagerState): User[] => state.users
);

export const getSearchedUsers = createSelector(
  getNetworkManagerState, (state: NetworkManagerState): User[] => state.searchedUsers
);

