import { on } from '@ngrx/store';
import {
  loadResourcesSuccessAction,
  setAllResourceKeysAction,
  setFilteredResourcesKeysAction,
  setResourceDataAction,
  setTabularResourceDataAction
} from '../actions';
import { State } from '../my-doctor-manager.reducer';

export const resourceReducer = [
  on(loadResourcesSuccessAction, (state: State, { responsePayload }) => ({
    ...state,
    loadResourceResponse: responsePayload
  })),

  on(setAllResourceKeysAction, (state: State, { resourceKeys }) => ({
    ...state,
    allResourcesKeys: resourceKeys
  })),

  on(setFilteredResourcesKeysAction, (state: State, { resourceKeys }) => ({
    ...state,
    filteredResourcesKeys: resourceKeys
  })),

  on(setResourceDataAction, (state: State, { resourceData }) => ({
    ...state,
    resourceData: resourceData
  })),

  on(setTabularResourceDataAction, (state: State, { tabularResourceData }) => ({
    ...state,
    tabularResourceData: tabularResourceData
  }))
];
