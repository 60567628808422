import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  loadCurrentDeviceAction,
  loadCurrentDeviceFailureAction,
  loadCurrentDeviceSuccessAction,
  loadDeviceHistoryFailureAction,
  loadDeviceHistorySuccessAction,
  loadDevicesAction,
  loadDevicesActionSuccess,
  loadDevicesFailureAction,
  loadDevicesHistoryAction,
  saveCurrentFeedPackAction,
  saveCurrentFeedPackFailureAction,
  saveCurrentFeedPackSuccessAction,
} from './food-friend-manager.actions';
import { NutriciaService } from '../services/nutricia.service';
import { selectCurrentID } from '@medrecord/managers-users';
import { Store } from '@ngrx/store';
import { addErrorToast, addSuccessToast } from '@medrecord/tools-toast';
import { getErrorToastBodyUtil } from '@medrecord/tools-utils';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FoodFriendManagerEffects {
  @Effect()
  loadDevices$ = this.actions$.pipe(
    ofType(loadDevicesAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([_action]) => {
      return this.nutriciaService.loadDevices().pipe(
        switchMap((response) => {
          return [loadDevicesActionSuccess({ devices: response.devices })];
        }),
        catchError(({ error }) => [loadDevicesFailureAction({ error })])
      );
    })
  );

  @Effect()
  loadCurrentFeedPack$ = this.actions$.pipe(
    ofType(loadCurrentDeviceAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ serial_number }]) => {
      return this.nutriciaService.loadCurrentDevice(serial_number).pipe(
        switchMap((response) => {
          return [loadCurrentDeviceSuccessAction({ currentDevice: response })];
        }),
        catchError(({ error }) => [
          addErrorToast(
            getErrorToastBodyUtil(this.translateService.instant('common_error'), {
              message: this.translateService.instant('Failed to load current feed pack data'),
              ...error,
            })
          ),
          loadCurrentDeviceFailureAction({ error }),
        ])
      );
    })
  );

  @Effect()
  saveCurrentFeedPack$ = this.actions$.pipe(
    ofType(saveCurrentFeedPackAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ payload }]) => {
      return this.nutriciaService.saveCurrentDevice(payload).pipe(
        switchMap((response) => {
          this.store.dispatch(
            addSuccessToast({
              title: 'common_success',
              content: this.translateService.instant('careplans_save_feed_pack_data_success'),
            })
          );
          return [saveCurrentFeedPackSuccessAction({ device: response })];
        }),
        catchError(({ error }) => [
          addErrorToast(
            getErrorToastBodyUtil(this.translateService.instant('common_error'), {
              message: this.translateService.instant('careplans_save_feed_pack_data_failure'),
              ...error,
            })
          ),
          saveCurrentFeedPackFailureAction({ error }),
        ])
      );
    })
  );

  @Effect()
  loadDeviceHistory$ = this.actions$.pipe(
    ofType(loadDevicesHistoryAction),
    withLatestFrom(this.store.select(selectCurrentID)),
    switchMap(([{ deviceId, from, to }]) => {
      return this.nutriciaService.loadDeviceHistory(deviceId, from, to).pipe(
        switchMap((history) => {
          return [loadDeviceHistorySuccessAction({ history, deviceId })];
        }),
        catchError(({ error }) => [
          addErrorToast(
            getErrorToastBodyUtil(this.translateService.instant('common_error'), {
              message: this.translateService.instant('careplans_failed_to_fetch_device_history'),
              ...error,
            })
          ),
          loadDeviceHistoryFailureAction({ error }),
        ])
      );
    })
  );

  constructor(
    private actions$: Actions,
    private nutriciaService: NutriciaService,
    private store: Store,
    private translateService: TranslateService
  ) {}
}
