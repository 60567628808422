import { Component, Inject, Input, OnDestroy } from '@angular/core';
import { MedrecordMoment } from '@medrecord/services-datetime';
import { Store } from '@ngrx/store';
import { getPinnedUsers, hidePinedUserAction, restorePinnedUsers, User, selectUser } from '@medrecord/managers-users';
import { MEDSAFE_ROUTE_NAMES, MedsafeRouteNames } from '@medrecord/routes-medsafe';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'medsafe-selected-users',
  templateUrl: './selected-users.component.html',
  styleUrls: ['./selected-users.component.scss']
})
export class SelectedUsersComponent implements OnDestroy {
  @Input() show: boolean;
  
  currentDate: string = MedrecordMoment().format('LLLL');
  pinnedUsers$: Observable<User[]> = this.store.select(getPinnedUsers);
  
  url: string = this.router.url;
  
  private routeSubscription: Subscription;
  
  constructor(
    private store: Store,
    private router: Router,
    @Inject(MEDSAFE_ROUTE_NAMES) public medsafeRouteNames: MedsafeRouteNames,
  ) {
    this.store.dispatch(restorePinnedUsers());
    
    this.routeSubscription = this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.url = this.router.url;
        }
      });
  }
  
  selectUser(user: User): void {
    this.store.dispatch(
      selectUser({ id: user.id })
    );
  }
  
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
  
  removePinnedUser(id: string): void {
    this.store.dispatch(hidePinedUserAction({ userId: id }));
  }
}
