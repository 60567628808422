<medrecord-auth-twofa-verification
  *ngIf="step === 1"
  [title]="'tfa_enter_recovery_codes'"
  [lostStuffDescription]="'tfa_no_access_recovery_code'"
  (clickHere)="step = 2"
></medrecord-auth-twofa-verification>

<div *ngIf="step === 2" class="d-flex flex-column">
  <p class="w-100 text-center">{{ 'tfa_lost_recovery_codes' | translate }}</p>

  <a
    href="mailto:{{supportEmail}}"
    class="font-weight-bold text-lg cursor-pointer text-center"
  >
    {{ supportEmail }}
  </a>

  <medrecord-auth-twofa-action-buttons-container
    [showPrimaryButton]="false"
  ></medrecord-auth-twofa-action-buttons-container>
</div>
