import { createAction, props } from '@ngrx/store';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { featurePrefix } from './feature-prefix';

export const loadQuestionnairesInUse = createAction(
  `${ featurePrefix } Load Questionnaires In Use`,
  props<{
    pageSettings: PageSettingsModel;
  }>()
);

export const loadQuestionnairesInUseSuccess = createAction(
  `${ featurePrefix } Questionnaires In Use Loaded Successfully`,
  props<{
    questionnairesInUse: any[];
    questionnairesInUseCount: number;
  }>()
);

export const loadQuestionnairesInUseFailure = createAction(
  `${ featurePrefix } Questionnaires In Use Load Failure`,
  props<{
    error: any;
  }>()
);