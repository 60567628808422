import { Condition } from '../models/interfaces/condition.interface';
import { Action, createReducer, on } from '@ngrx/store';
import {
  loadConditionsFailureAction,
  loadConditionsSuccessAction,
  loadConditionSuccessAction,
  addConditionSuccessAction,
  clearActiveCondition,
  deleteConditionSuccessAction,
  editConditionSuccessAction,
  loadCareplanConditionsSuccessAction,
  linkCareplanConditionsSuccessAction,
  removeCareplanConditionAction,
} from './conditions-manager.actions';

export const conditionsManagerFeatureKey = 'conditions-manager';

export interface ConditionsManagerState {
  error: any;
  conditions: Condition[];
  activeCondition: Condition;
  count: number;
  careplan: {
    conditions: Condition[];
    count: number;
  };
}

export const initialState: ConditionsManagerState = {
  error: null,
  conditions: [],
  activeCondition: null,
  count: 0,
  careplan: {
    conditions: [],
    count: 0,
  },
};

export const conditionsManagerReducer = createReducer(
  initialState,
  on(loadConditionsSuccessAction, (state, { conditions, count }) => ({ ...state, conditions, count })),
  on(loadCareplanConditionsSuccessAction, (state, { conditions, count }) => ({
    ...state,
    careplan: { conditions, count },
  })),
  on(loadConditionsFailureAction, (state, { error }) => ({ ...state, error })),
  on(loadConditionSuccessAction, (state, { condition }) => ({ ...state, activeCondition: condition })),
  on(addConditionSuccessAction, (state, { condition }) => ({
    ...state,
    activeCondition: condition,
    count: state.count + 1,
    conditions: [condition, ...state.conditions],
    careplan: {
      conditions: [condition, ...state.careplan.conditions],
      count: state.careplan.count + 1,
    },
  })),
  on(editConditionSuccessAction, (state, { condition }) => ({
    ...state,
    activeCondition: condition,
    conditions: state.conditions.map((con) => (con.id === condition.id ? condition : con)),
    careplan: {
      ...state.careplan,
      conditions: state.careplan.conditions.map((con) => (con.id === condition.id ? condition : con)),
    },
  })),
  on(deleteConditionSuccessAction, (state, { id }) => ({
    ...state,
    conditions: state.conditions.filter((c) => c.id !== id),
    count: state.count - 1,
    careplan: {
      conditions: state.careplan.conditions.filter((c) => c.id !== id),
      count: state.careplan.count - 1,
    },
  })),
  on(clearActiveCondition, (state) => ({ ...state, activeCondition: null })),
  on(linkCareplanConditionsSuccessAction, (state, { conditions }) => ({
    ...state,
    careplan: {
      conditions: [...conditions, ...state.careplan.conditions],
      count: state.careplan.count + conditions.length,
    },
  })),
  on(removeCareplanConditionAction, (state, { id }) => ({
    ...state,
    careplan: {
      conditions: state.careplan.conditions.filter((c) => c.id !== id),
      count: state.careplan.count - 1,
    },
  }))
);

export const reducer = (state: ConditionsManagerState, action: Action): ConditionsManagerState => {
  return conditionsManagerReducer(state, action);
};
