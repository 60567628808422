import { Component, ContentChildren, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { MapFieldType } from '../../../models/enums';
import { MedrecordEvent, MapFieldOptions, SelectFields } from '../../../models/interfaces';
import { FormGroup } from '@angular/forms';
import { InputType } from '../../../models/types/input.type';
import { ErrorCode } from '@medrecord/core';

@Component({
  selector: 'medrecord-ui-map-field',
  templateUrl: './map-field.component.html',
  styleUrls: ['./map-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapFieldComponent {
  @Input() name;
  @Input() autocomplete;
  @Input() defaultValue;

  @Input() label = '';
  @Input() secondaryLabel = '';
  @Input() hint: string;
  @Input() labelContent: TemplateRef<any>;
  @Input() asterix: boolean = false;

  @Input() cssClass: string;
  @Input() fieldCss = '';
  @Input() bindVisibility: () => boolean;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() type: InputType;
  @Input() isRequired: boolean;

  @Input() fieldType: MapFieldType;
  @Input() error: string;
  @Input() errorCode: ErrorCode;
  @Input() options: MapFieldOptions;
  // Used for the multi select and select fields to control options value and lable
  @Input() fields: SelectFields = { value: 'value', text: 'text' };
  // Multiselect options
  @Input() allowCustomValue: boolean = false;
  // Datetime picker options
  @Input() dateFormat: string = 'dd/MM/yyyy';

  @ContentChildren('rightIcon') rightIcons: TemplateRef<any>[];
  @ContentChildren('leftIcon') leftIcons: TemplateRef<any>[];
  @ContentChildren('rightButton') rightButtons: TemplateRef<any>[];
  @ContentChildren('leftButton') leftButtons: TemplateRef<any>[];
  @ContentChildren('noRecordsTemplate') noRecordsTemplates: TemplateRef<any>[];

  @Output() change = new EventEmitter<MedrecordEvent>();

  readonly fieldTypeVariants = MapFieldType;

  @Input()
  parentForm: FormGroup;

  onChange(changeEvent: any): void {
    if (this.options?.onChangeCb) {
      this.options.onChangeCb(changeEvent);
    }
  }

  getEnablePrecisionValue(): boolean {
    return this.options.enablePrecisionChange ?? true;
  }
}
