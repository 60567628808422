import * as testCareproviderConstants from './test-careprovider.constants';
import * as pullTransactionMappings from './pull-transactions-mapping.constants';
import { Provider } from '@angular/core';
import { MY_DOCTOR_MANAGER_CONSTANTS } from '../my-doctor-manager.tokens';
import * as supportedDataServiceIds from './supported-data-service-ids.constants';
import * as windowEvents from './window-events.constants';

export const constants = {
  ...testCareproviderConstants,
  ...pullTransactionMappings,
  ...supportedDataServiceIds,
  ...windowEvents,
};

export type MyDoctorManagerConstants = typeof constants;

export const provideMyDoctorManagerConstants = (): Provider => [
  {
    provide: MY_DOCTOR_MANAGER_CONSTANTS,
    useValue: constants,
  },
];
