import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppendUserStatusDirective } from './directives/append-user-status.directive';
import { UserStatusService } from './services/user-status.service';
import { StoreModule } from '@ngrx/store';
import * as fromUserStatuses from './state-management/user-status.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserStatusEffects } from './state-management/user-status.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromUserStatuses.userStatusFeatureKey, fromUserStatuses.reducer),
    EffectsModule.forFeature([UserStatusEffects]),
  ],
  declarations: [AppendUserStatusDirective],
  providers: [UserStatusService],
  exports: [AppendUserStatusDirective],
})
export class MedrecordDirectivesUserStatusModule {
}
