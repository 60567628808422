import * as questionnaireActions from '../questionnaires.actions';
import { State } from '../questionnaires.reducer';
import { on } from '@ngrx/store';

export const scoresReducers = [

  on(questionnaireActions.loadQuestionnaireScores, (state: State) => ( {
    ...state,
    questionnairesScoresLoadingError: null,
  } )),

  on(questionnaireActions.loadQuestionnaireScoresSuccess, (state: State,
                                                           {
                                                             questionnairesScores,
                                                             questionnairesScoresCount
                                                           }) => ( {
    ...state,
    questionnairesScores,
    questionnairesScoresCount,
  } )),

  on(questionnaireActions.loadQuestionnaireScoresFailure, (state: State, { error }) => ( {
    ...state,
    questionnairesScoresLoadingError: error
  } )),

  on(questionnaireActions.loadQuestionnaireScore, (state: State) => ( {
    ...state,
    questionnaireScoreLoadingError: null,
  } )),

  on(questionnaireActions.loadQuestionnaireScoreSuccess, (state: State, { questionnaireScore }) => ( {
    ...state,
    questionnaireScore,
    questionnaireScoreLoadingError: null,
  } )),

  on(questionnaireActions.loadQuestionnaireScoreFailure, (state: State, { error }) => ( {
    ...state,
    questionnaireScoreLoadingError: error
  } )),
];
