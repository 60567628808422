import { createAction, props } from '@ngrx/store';
import { HealthplansLoadPayload } from '../models/interfaces/healthplans-load-payload.interface';
import { Healthplan } from '../models/interfaces/healthplan.interface';
import { GoalStatus } from '../models/enums/goal-status.enum';
import { Goal } from '../models/interfaces/goal.interface';
import { TasksLoadPayload } from '../models/interfaces/tasks-load-payload.interface';
import { Task } from '../models/interfaces/task.interface';
import { User } from '@medrecord/managers-users';
import { IComment } from '../models/interfaces/comment.interface';

export const loadHealthplansAction = createAction(
  '[Healthplans Actions] Load Healthplans',
  props<{ payload: HealthplansLoadPayload }>()
);

export const loadHealthplansSuccessAction = createAction(
  '[Healthplans Actions] Load Healthplans Success',
  props<{ healthplans: Healthplan[]; count: number }>()
);

export const loadHealthplansFailureAction = createAction(
  '[Healthplans Actions] Load Healthplans Failure',
  props<{ error: any }>()
);

export const loadHealthplanAction = createAction('[Healthplans Actions] Load Healthplan', props<{ id: string }>());

export const loadHealthplanSuccessAction = createAction(
  '[Healthplans Actions] Load Healthplan Success',
  props<{ healthplan: Healthplan }>()
);

export const addHealthplanAction = createAction(
  '[Healthplans Actions] Add Healthplans',
  props<{ healthplan: Healthplan }>()
);

export const addHealthplanSuccessAction = createAction(
  '[Healthplans Actions] Add Healthplans Success',
  props<{ healthplan: Healthplan }>()
);

export const editHealthplanAction = createAction(
  '[Healthplans Actions] Edit Healthplans',
  props<{ healthplan: Healthplan }>()
);

export const editHealthplanSuccessAction = createAction(
  '[Healthplans Actions] Edit Healthplan Success',
  props<{ healthplan: Healthplan }>()
);

export const deleteHealthplanAction = createAction('[Healthplans Actions] Delete Healthplan', props<{ id: string }>());

export const deleteHealthplanSuccessAction = createAction(
  '[Healthplans Actions] Delete Healthplans Success',
  props<{ id: string }>()
);

export const clearActiveHealthplan = createAction('[Healthplans Actions] Clear active healthplan');

export const loadGoalsAction = createAction(
  '[Healthplans Actions] Load Goals',
  props<{ id: string; status: GoalStatus }>()
);

export const loadGoalsSuccessAction = createAction(
  '[Healthplans Actions] Load Goals Success',
  props<{ goals: Goal[] }>()
);

export const loadGoalsFailureAction = createAction('[Healthplans Actions] Load Goals Failure', props<{ error: any }>());

export const loadGoalAction = createAction(
  '[Healthplans Actions] Load Goal',
  props<{ healthplanId: string; id: string }>()
);

export const loadGoalSuccessAction = createAction('[Healthplans Actions] Load Goal Success', props<{ goal: Goal }>());

export const addGoalAction = createAction(
  '[Healthplans Actions] Add Goals',
  props<{ goal: Goal; healthplanId: string; selectedStatus?: GoalStatus }>()
);

export const addGoalSuccessAction = createAction(
  '[Healthplans Actions] Add Goals Success',
  props<{ goal: Goal; selectedStatus?: GoalStatus }>()
);

export const editGoalAction = createAction(
  '[Healthplans Actions] Edit Goals',
  props<{ goal: Goal; healthplanId: string }>()
);

export const editGoalSuccessAction = createAction('[Healthplans Actions] Edit Goal Success', props<{ goal: Goal }>());

export const deleteGoalAction = createAction(
  '[Healthplans Actions] Delete Goal',
  props<{ id: string; healthplanId: string }>()
);

export const deleteGoalSuccessAction = createAction(
  '[Healthplans Actions] Delete Goals Success',
  props<{ id: string }>()
);

export const clearActiveGoal = createAction('[Healthplans Actions] Clear active goal');

export const loadTasksAction = createAction(
  '[Healthplans Actions] Load Tasks',
  props<{ id: string; goalId: string; payload: TasksLoadPayload }>()
);

export const loadTasksSuccessAction = createAction(
  '[Healthplans Actions] Load Tasks Success',
  props<{ tasks: Task[]; goalId: string }>()
);

export const loadTasksFailureAction = createAction('[Healthplans Actions] Load Tasks Failure', props<{ error: any }>());

export const addTaskAction = createAction(
  '[Healthplans Actions] Add Tasks',
  props<{ task: Task; healthplanId: string; goalId: string }>()
);

export const addTaskSuccessAction = createAction(
  '[Healthplans Actions] Add Tasks Success',
  props<{ task: Task; goalId: string }>()
);

export const editTaskAction = createAction(
  '[Healthplans Actions] Edit Tasks',
  props<{ task: Task; healthplanId: string; goalId: string }>()
);

export const editTaskSuccessAction = createAction(
  '[Healthplans Actions] Edit Task Success',
  props<{ task: Task; goalId: string }>()
);

export const loadUserNetworkForHealthplanAction = createAction(
  '[Healthplans Actions] Load User Network for Healthplan'
);

export const loadUserNetworkForHealthplanSuccessAction = createAction(
  '[Healthplans Actions] Load User Network for Healthplan Success',
  props<{ userNetwork: User[]; relatedPeople: User[] }>()
);

export const addUserToHealthplanAction = createAction(
  '[Healthplans Actions] Add User to Healthplan',
  props<{ healthplanId: string; fhirId?: string; userId?: string }>()
);

export const removeUserFromHealthplanAction = createAction(
  '[Healthplans Actions] Remove User from Healthplan',
  props<{ healthplanId: string; fhirId?: string; userId?: string }>()
);

export const addGoalCommentAction = createAction(
  '[Healthplans Actions] Add Goal Comment Action',
  props<{ text: string; healthplanId: string; goalId: string }>()
);

export const addGoalCommentSuccessAction = createAction(
  '[Healthplans Actions] Add Goal Comment Success Action',
  props<{ goalId: string; comment: IComment }>()
);

export const updateGoalCommentAction = createAction(
  '[Healthplans Actions] Update Goal Comment Action',
  props<{ comment: IComment; healthplanId: string; goalId: string }>()
);

export const updateGoalCommentSuccessAction = createAction(
  '[Healthplans Actions] Update Goal Comment Success Action',
  props<{ goalId: string; comment: IComment }>()
);

export const deleteGoalCommentAction = createAction(
  '[Healthplans Actions] Delete Goal Comment Action',
  props<{ commentId: string; healthplanId: string; goalId: string }>()
);

export const deleteGoalCommentSuccessAction = createAction(
  '[Healthplans Actions] Delete Goal Comment Success Action',
  props<{ goalId: string; commentId: string }>()
);

export const loadTaskAction = createAction(
  '[Healthplans Actions] Load Task Action',
  props<{ healthplanId: string; goalId: string; taskId: string }>()
);

export const loadTaskSuccessAction = createAction(
  '[Healthplans Actions] Load Task Success Action',
  props<{ goalId: string; task: Task }>()
);

export const clearActiveTaskAction = createAction('[Healthplans Actions] Clear Active Task Action');

export const addTaskCommentAction = createAction(
  '[Healthplans Actions] Add Task Comment Action',
  props<{ text: string; healthplanId: string; goalId: string; taskId: string }>()
);

export const addTaskCommentSuccessAction = createAction(
  '[Healthplans Actions] Add Task Comment Success Action',
  props<{ goalId: string; comment: IComment; taskId: string }>()
);

export const updateTaskCommentAction = createAction(
  '[Healthplans Actions] Update Task Comment Action',
  props<{ comment: IComment; healthplanId: string; goalId: string; taskId: string }>()
);

export const updateTaskCommentSuccessAction = createAction(
  '[Healthplans Actions] Update Task Comment Success Action',
  props<{ comment: IComment }>()
);

export const deleteTaskCommentAction = createAction(
  '[Healthplans Actions] Delete Task Comment Action',
  props<{ commentId: string; healthplanId: string; goalId: string; taskId: string }>()
);

export const deleteTaskCommentSuccessAction = createAction(
  '[Healthplans Actions] Delete Task Comment Success Action',
  props<{ commentId: string }>()
);
