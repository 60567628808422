import { Component, Input } from '@angular/core';
import { ObservationThresholdItem } from '@managers/measurements';

@Component({
  selector: 'medsafe-measurement-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
  @Input() threshold: ObservationThresholdItem;

  isNumber(value: string): boolean {
    return isNaN(parseFloat(value));
  }

  getOffset(): number {
    const offset = Math.abs(this.threshold.score) * -1 + 100;
    return Math.min(offset, 100);
  }
}
