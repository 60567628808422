import { createAction, props } from '@ngrx/store';
import { CareplanLinkCategories } from '../model/enums/careplan-link-categories.enum';

export const linkCareplanAction = createAction(
  '[Careplans Manager Actions] Link Careplan Action',
  props<{ ids: string[]; category: CareplanLinkCategories; careplanId: string }>()
);

export const linkCareplanSuccessAction = createAction(
  '[Careplans Manager Actions] Link Careplan Success Action',
  props<{ ids: string[]; category: CareplanLinkCategories; careplanId: string }>()
);

export const unlinkCareplanAction = createAction(
  '[Careplans Manager Actions] Unlink Careplan Action',
  props<{ ids: string[]; category: CareplanLinkCategories; careplanId: string }>()
);

export const unlinkCareplanSuccessAction = createAction(
  '[Careplans Manager Actions] Unlink Careplan  Success Action',
  props<{ ids: string[]; category: CareplanLinkCategories; careplanId: string }>()
);
