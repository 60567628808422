import { DialogUtility, Dialog } from '@syncfusion/ej2-angular-popups';

export function MedrecordConfirmationModal(content: string, cb?: Function): Promise<boolean> {
  let confirmationModal: Dialog;
  
  return new Promise<boolean>((resolve: any) => {
    confirmationModal = DialogUtility.confirm({
      content,
      cancelButton: {
        text: 'Close',
        cssClass: 'e-warning e-pill e-outline',
      
        click: () =>  {
          resolve(true);
          confirmationModal.close();
  
          if (!!cb) { cb(); }
        }
      },
      okButton: {
        text: 'Back',
        cssClass: 'e-pill e-outline',
        click: () =>  {
          confirmationModal.close();
          resolve(false);
        }
      }
    });
  });
}