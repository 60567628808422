import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const treatmentDirectiveBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.consent,
  fieldGroups: [
    {
      name: ({ resource }) => resource.content?.treatment?.coding?.[0]?.display || 'my_doctor_treatment',
      fields: [
        {
          label: 'my_doctor_verified',
          fieldName: 'verified',
          translate: true,
          prepareData: (data) => {
            if (data === true) return 'common_yes';
            else if (data === false) return 'common_no';
            return null;
          },
        },
        {
          label: 'my_doctor_verified_with',
          fieldName: 'verifiedWith',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_verification_date',
          fieldName: 'verificationDate',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LL') : ''),
        },
        {
          label: 'my_doctor_treatment_permitted',
          fieldName: 'treatmentPermitted',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_treatment_restrictions',
          fieldName: 'restrictions',
          prepareData: (data) => data?.join('\n'),
        },
        {
          label: 'my_doctor_start_date',
          fieldName: 'period',
          prepareData: (data) => (data?.start ? MedrecordMoment(data?.start).format('LL') : ''),
        },
        {
          label: 'my_doctor_end_date',
          fieldName: 'period',
          prepareData: (data) => (data?.end ? MedrecordMoment(data?.end).format('LL') : ''),
        },
        {
          label: 'my_doctor_explanation',
          fieldName: 'explanation',
          prepareData: (data) => data,
        },
      ],
    },
  ],
});
