import { Directive, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MapField } from '../models/interfaces';
import { FormFactory } from './form.factory';

@Directive()
export abstract class RepeatableForm<Data> implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() value: Data[];

  formType: string;
  fieldName: string;
  initialFormData: Data;

  fields: MapField[][] = [];

  constructor(private formFactory: FormFactory<Data>, private fb: FormBuilder) {}

  get controls(): AbstractControl[] {
    return (this.parentForm.get(this.fieldName) as FormArray).controls;
  }

  ngOnInit(): void {
    this.parentForm.registerControl(this.fieldName, this.fb.array([]));
    this.addInitialFormItems();
  }

  addForm(formValue?: Data): void {
    const control = this.parentForm.get(this.fieldName) as FormArray;
    const { form, fields } = this.formFactory.getFormConfig(this.formType, formValue);

    control.push(form);
    this.fields.push(fields);
  }

  addInitialFormItems(): void {
    if (this.value?.length) {
      let rank = 0;
      this.value.forEach((formValue) =>
        this.addForm({ ...formValue, ...(formValue.hasOwnProperty('rank') ? { ...formValue, rank: ++rank } : {}) })
      );
    }
  }

  removeForm(index): void {
    const control = this.parentForm.get(this.fieldName) as FormArray;

    control.removeAt(index);
    this.fields.splice(index, 1);
  }
}
