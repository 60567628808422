import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-email',
  templateUrl: './email.component.svg',
})
export class EmailComponent extends MedrecordIcon {
  viewHeight = 16;
  viewWidth = 16;
}
