import { Component, Input } from '@angular/core';
import { AddressUserData } from '@medrecord/managers-users';

@Component({
  selector: 'medsafe-addresses-view',
  templateUrl: './addresses-view.component.html',
  styleUrls: ['./addresses-view.component.scss']
})
export class AddressesViewComponent {
  @Input() addresses: AddressUserData[] = [];
}
