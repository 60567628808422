import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const laboratoryResultBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.observation,
  fieldGroups: [
    {
      name: ({ resource }) => resource?.content?.context?.display || 'my_doctor_laboratory_result',
      fields: [
        {
          label: 'my_doctor_start_date',
          fieldName: 'effective',
          prepareData: (data) => (data?.date ? MedrecordMoment(data?.start).format('LLL') : ''),
        },
        {
          label: 'my_doctor_test_date_time',
          fieldName: 'testDateTime',
          prepareData: (data) => (data ? MedrecordMoment(data).format('LLL') : ''),
        },
        {
          label: 'my_doctor_nhg_code',
          fieldName: 'nhgCode',
          prepareData: (data) => data?.display || '',
        },
        {
          label: 'my_doctor_code',
          fieldName: 'code',
          prepareData: (data) => data?.text || `${data?.coding?.[0].code || ''} - ${data?.coding?.[0].display || ''}`,
        },
        {
          label: 'my_doctor_testCode',
          fieldName: 'testCode',
          prepareData: (data) => data?.text || `${data?.coding?.[0].code || ''} - ${data?.coding?.[0].display || ''}`,
        },
        {
          label: 'my_doctor_rate',
          fieldName: 'rate',
          prepareData: (data) => `${data?.value || ''}${data?.unit || ''}`,
        },
        {
          label: 'my_doctor_body_site',
          fieldName: 'bodySite',
          prepareData: (data) => data?.text || '',
        },
        {
          label: 'my_doctor_temperature',
          fieldName: 'temperature',
          prepareData: (data) => `${data?.value || ''} ${data?.unit || ''}`,
        },
        {
          label: 'my_doctor_systolic',
          fieldName: 'systolic',
          prepareData: (data) => `${data?.value || ''} ${data?.unit || ''}`,
        },
        {
          label: 'my_doctor_glucose',
          fieldName: 'glucose',
          prepareData: (data) => `${data?.value || ''} ${data?.unit || ''}`,
        },
        {
          label: 'my_doctor_diastolic',
          fieldName: 'diastolic',
          prepareData: (data) => `${data?.value || ''} ${data?.unit || ''}`,
        },
        {
          label: 'my_doctor_testResult',
          fieldName: 'testResult',
          prepareData: (data) => data?.text || `${data?.coding?.[0]?.code || ''} - ${data?.coding?.[0].display || ''}`,
        },
        {
          label: 'my_doctor_status',
          fieldName: 'status',
          prepareData: (data) => data?.coding?.[0]?.display || `common_status_${data?.toLowerCase()}` || '',
          translate: true,
        },
        {
          label: 'my_doctor_position',
          fieldName: 'position',
          translate: true,
          prepareData: (data) => (data ? 'measurements_blood_pressure_position_' + data?.toLowerCase() : null),
        },
        {
          label: 'my_doctor_cuff_type',
          fieldName: 'cuffType',
          translate: true,
          prepareData: (data) => (data ? 'measurements_blood_pressure_cuff_type_' + data?.toLowerCase() : null),
        },
        {
          label: 'my_doctor_body_site',
          fieldName: 'bodySite',
          translate: true,
          prepareData: (data) => (data ? 'measurements_blood_pressure_body_site_' + data?.toLowerCase() : null),
        },
        {
          label: 'my_doctor_care_provider',
          fieldName: 'performer',
          prepareData: (data) => data?.display,
        },
        {
          label: 'my_doctor_diastolic_endpoint',
          fieldName: 'diastolicEndpoint',
          translate: true,
          prepareData: (data) =>
            data ? 'measurements_blood_pressure_diastolic_endpoint_' + data?.toLowerCase() : null,
        },
        {
          label: 'my_doctor_method',
          fieldName: 'method',
          translate: true,
          prepareData: (data) =>
            data ? data?.text || `${data?.coding?.[0].code || ''} - ${data?.coding?.[0].display || ''}` : null,
        },
        {
          label: 'my_doctor_average_blood_pressure',
          fieldName: 'averageBloodPressure',
          prepareData: (data) => [data?.value, data?.unit].filter(Boolean).join(''),
        },
        {
          label: 'my_doctor_amount',
          fieldName: 'amount',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'comment',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_administration_route',
          fieldName: 'administrationRoute',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'BODY_WEIGHT_title',
          fieldName: 'weight',
          prepareData: (data) => `${data?.value || ''} ${data?.unit}`,
        },
        {
          label: 'BODY_HEIGHT_title',
          fieldName: 'height',
          prepareData: (data) => `${data?.value || ''} ${data?.unit}`,
        },
        {
          label: 'my_doctor_value',
          fieldName: 'value',
          prepareData: (data) => {
            if (data?.coding) return `${data?.coding?.[0].code || ''} - ${data?.coding?.[0].display || ''}`;
            else if (data?.value) return `${data?.value} ${data?.unit || ''}`;
            else return data;
          },
        },
      ],
    },
  ],
});
