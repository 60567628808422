import { Component, Inject, OnInit } from '@angular/core';
import { AUTH_CONSTANTS, AuthConstants } from '../../constants';
import { Observable, of } from 'rxjs';
import { getCustomerInnerLogo } from '@medrecord/services-customer-metadata';
import { Store } from '@ngrx/store';
import { StorageService } from '@medrecord/tools-storage';
import { EnvService, StorageKeys } from '@medrecord/core';
import { AuthRouteParams } from '@medrecord/managers-auth';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'medrecord-auth-auth-wrapper',
  templateUrl: './auth-wrapper.component.html',
  styleUrls: ['./auth-wrapper.component.scss']
})
export class AuthWrapperComponent implements OnInit {
  bgLink: string;
  logo$: Observable<string> = this.store.select(getCustomerInnerLogo);
  referenceLogo$: Observable<string> = of('https://medrecord-logos.s3.eu-north-1.amazonaws.com/aal.png');
  showAuthReferenceText = false;
  enableGermanLanguage = false;
  
  readonly isOAuth = this.storage.getItem<boolean>(StorageKeys.isOAuth, false);
  
  constructor(
    private store: Store,
    private storage: StorageService<StorageKeys>,
    private envService: EnvService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(AUTH_CONSTANTS) private authConstants: AuthConstants,
  ) {}
  
  ngOnInit(): void {
    this.showAuthReferenceText = this.envService.showAuthReferenceText;
    this.enableGermanLanguage = this.envService.enableGermanLanguageAuth;
    this.bgLink = this.authConstants.defaultBgLink;
    this.saveStateParameterToStorage();
  }
  
  private saveStateParameterToStorage(): void {
    const params = (new URL(this.document.location.href)).searchParams;
    const state = params.get(AuthRouteParams.State);
    this.storage.setItem(StorageKeys.State, state);
  }

}
