import { on } from '@ngrx/store';
import { State } from '../questionnaires.reducer';
import {
  loadQuestionnairesInUse,
  loadQuestionnairesInUseFailure,
  loadQuestionnairesInUseSuccess,
} from '../actions/in-use.actions';

export const inUseReducers = [
  on(loadQuestionnairesInUse, (state: State) => ({
    ...state,
    questionnairesInUseLoadingError: null,
  })),

  on(loadQuestionnairesInUseSuccess, (state: State, { questionnairesInUse, questionnairesInUseCount }) => ({
    ...state,
    questionnairesInUse,
    questionnairesInUseCount,
    questionnairesInUseLoadingError: null,
  })),

  on(loadQuestionnairesInUseFailure, (state: State, { error }) => ({
    ...state,
    questionnairesInUseLoadingError: error,
  })),
];
