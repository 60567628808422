import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@medrecord/core';
import { Observable } from 'rxjs';
import { DeviceResponse } from '../models/device-response.model';
import { Device } from '../models/device.model';
@Injectable()
export class NutriciaService {
  constructor(private http: HttpClient, private envService: EnvService) {}

  loadDevices(): Observable<DeviceResponse> {
    return this.http.get<DeviceResponse>(this.envService.backend + '/device/nutricia');
  }

  loadCurrentDevice(serial_number: string): Observable<Device> {
    return this.http.get<Device>(this.envService.backend + '/device/nutricia/' + serial_number + '/latest-status');
  }

  saveCurrentDevice(payload: Device): Observable<Device> {
    return this.http.post<Device>(this.envService.backend + '/device/nutricia/items', payload);
  }

  loadDeviceHistory(deviceId: string, from?: string, to?: string): Observable<Device[]> {
    const params = new HttpParams();
    if (from) params.set('from', from);
    if (to) params.set('to', to);
    return this.http.get<Device[]>(`${this.envService.backend}/device/nutricia/${deviceId}/history`, {
      params: params,
    });
  }
}
