import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-plus',
  templateUrl: './plus.component.svg',
  styleUrls: ['./plus.component.scss'],
})
export class PlusComponent extends MedrecordIcon {
  viewHeight = 16;
  viewWidth = 16;
}
