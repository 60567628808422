import { Action, createAction, props } from '@ngrx/store';
import { ObservationType } from '@medrecord/core';
import { Observation, ObservationPayload, ObservationThresholdItem } from '../models/interfaces';
import { User } from '@medrecord/managers-users';

export const setFocusedObservationTypeAction = createAction(
  '[Measurements Manager Actions] Set Focused Observation Type Action',
  props<{ observationType: ObservationType }>()
);

export const removeFocusedObservationTypeAction = createAction(
  '[Measurements Manager Actions] Remove Focused Observation Type Action'
);

export const setAllowedObservationTypesAction = createAction(
  '[Measurements Manager Actions] Set Allowed Observation Types Action',
  props<{ observationTypes: ObservationType[]; carePlanId?: string }>()
);

export const setAllowedObservationTypesSuccessAction = createAction(
  '[Measurements Manager Actions] Set Allowed Observation Types Success Action'
);

export const loadAllowedObservationTypes = createAction(
  '[Measurements Manager Actions] Load Allowed Observation Types',
  props<{ careplanId?: string; successActions?: Action[]; errorActions?: Action[] }>()
);

export const loadAllowedObservationTypesSuccess = createAction(
  '[Measurements Manager Actions] Load Allowed Observation Types Success',
  props<{ allowedObservationTypes: ObservationType[] }>()
);

export const loadAllowedObservationTypesFailure = createAction(
  '[Measurements Manager Actions] Load Allowed Observation Types Failure',
  props<{ error: any }>()
);

export const loadUserThresholds = createAction(
  '[Measurements Manager Actions] Load User Thresholds',
  props<{ carePlanId?: string; observationTypes?: ObservationType[] }>()
);

export const loadUserThresholdsSuccess = createAction(
  '[Measurements Manager Actions] Load User Thresholds Success',
  props<{ thresholds: ObservationThresholdItem[]; healthScore: string }>()
);

export const loadUserThresholdsFailure = createAction(
  '[Measurements Manager Actions] Load User Thresholds Failure',
  props<{ error: any }>()
);

export const loadUserObservations = createAction(
  '[Measurements Manager Actions] Load User Observations',
  props<{ observationType: ObservationType; careplanId?: string }>()
);

export const loadUserQuantityObservations = createAction(
  '[Measurements Manager Actions] Load User Quantity Observations',
  props<{ observationType: ObservationType; careplanId?: string }>()
);

export const loadUserObservationsSuccess = createAction(
  '[Measurements Manager Actions] Load User Observations Success',
  props<{ observations: Observation[]; observationType?: ObservationType; careplanId?: string }>()
);

export const loadUserObservationsFailure = createAction(
  '[Measurements Manager Actions] Load User Observations Failure',
  props<{ error: any }>()
);

export const removeUserObservations = createAction('[Measurements Manager Actions] Remove User Observations');

export const loadUserNetworkForMeasurementsAction = createAction(
  '[Measurements Manager Actions] Load User Network for Measurements'
);

export const loadUserNetworkForMeasurementsSuccessAction = createAction(
  '[Measurements Manager Actions] Load User Network for Measurements Success',
  props<{ userNetwork: User[] }>()
);

export const createObservationAction = createAction(
  '[Measurements Manager Actions] Create Observation',
  props<{ observationType: ObservationType; payload: any }>()
);

export const createQuantityObservationAction = createAction(
  '[Measurements Manager Actions] Create Quantity Observation',
  props<{ observationType: ObservationType; payload: any }>()
);

export const updateQuantityObservationAction = createAction(
  '[Measurements Manager Actions] Update Quantity Observation',
  props<{ observationType: ObservationType; payload: ObservationPayload }>()
);

export const createObservationSuccessAction = createAction(
  '[Measurements Manager Actions] Create Observation Success',
  props<{ observationType: ObservationType; observation: ObservationThresholdItem }>()
);

export const createObservationFailtureAction = createAction('[Measurements Manager Actions] Update Observation Failed');

export const updateObservationSuccessAction = createAction(
  '[Measurements Manager Actions] Create Observation Success',
  props<{ observationType: ObservationType; observation: ObservationThresholdItem }>()
);

export const updateObservationFailtureAction = createAction('[Measurements Manager Actions] Update Observation Failed');

export const deleteObservationAction = createAction(
  '[Measurements Manager Actions] Delete Observation',
  props<{ observationId: string }>()
);

export const deleteObservationSuccessAction = createAction('[Measurements Manager Actions] Delete Observation Success');

export const setCareplanObservationTypesAction = createAction(
  '[Measurements Manager Actions] Set Careplan Observation Types Action',
  props<{ observationTypes: ObservationType[]; carePlanId: string }>()
);
