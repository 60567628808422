import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ErrorCode } from '@medrecord/core';

@Component({
  selector: 'medrecord-ui-field-error',
  templateUrl: './field-error.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class FieldErrorComponent {
  @Input() errors: string[] = [];
  @Input() errorCode: ErrorCode;
  @Input() externalErrors: string[] = [];
}
