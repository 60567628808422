import { Action, createReducer } from '@ngrx/store';

export const careplansManagerFeatureKey = 'careplans-manager';

export interface CareplansManagerState {}

export const initialState = {};

export const careplansManagerReducer = createReducer(initialState);

export const reducer = (state: CareplansManagerState, action: Action): CareplansManagerState => {
  return careplansManagerReducer(state, action);
};
