import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PaymentPlan } from '../models/interfaces/payment-plan.interface';
import { EnvService } from '@medrecord/core';

@Injectable()
export class PaymentManagerService {
  constructor(
    private http: HttpClient,
    private envService: EnvService
    ) {}

  loadPaymentPlansForUser(userId: string): Observable<PaymentPlan[]> {
    return this.http.get<PaymentPlan[]>(this.envService.backend + '/payment/user/' + userId + '/available');
  }

  createPaymentWithStripe(paymentPlanId: number, userId: string):  Observable<any> {
    return this.http.post(this.envService.backend + '/payment/stripe/' + userId + '/checkout', { paymentPlanId });
  }
}
