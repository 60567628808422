export enum BloodGlucoseTimingEvent {
  BEFORE_MEAL = 'AC',
  MEAL = 'C',
  AFTER_MEAL = 'PC',
  BETWEEN_MEALS = 'IC',
  UPON_WAKING_UP = 'WAKE',
  BEFORE_BREAKFAST = 'ACM',
  BREAKFAST = 'CM',
  AFTER_BREAKFAST = 'PCM',
  BETWEEN_BREAKFAST_AND_LUNCH = 'ICM',
  BEFORE_LUNCH = 'ACD',
  LUNCH = 'CD',
  AFTER_LUNCH = 'PCD',
  BETWEEN_LUNCH_AND_DINNER = 'ICD',
  BEFORE_DINNER = 'ACV',
  DINNER = 'CV',
  AFTER_DINNER = 'PCV',
  BETWEEN_DINNER_AND_THE_HOUR_OF_SLEEP = 'ICV',
  REGULAR_PERIOD_OF_EXTENDED_SLEEP = 'HS',
}
