import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceSubType } from '../enums/resource-sub-type.enum';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const soapBlock: ResourceBlockFunction = ({ translateService }) => ({
  resourceType: ResourceType.composition,
  fieldGroups: [
    {
      name: ({ resource }) => {
        const data = resource;
        const date = MedrecordMoment(data?.content?.date);
        const sections = data?.content?.sections
          ?.reduce((prev, section) => {
            if (prev.find((s) => s.code === 'E')) return [...prev, null];
            const icpc = section?.icpc1Code?.coding?.[0]?.code;
            const display =
              section?.icpc1Code?.coding?.[0]?.display ||
              section?.entries?.find((e) => e?.reference?.split('/')?.[0] !== ResourceType.observation)?.display ||
              section?.entries?.find((e) => e?.reference?.split('/')?.[0] === ResourceType.observation)?.display;

            if (!icpc && !display) return [...prev, null];
            return [
              ...prev,
              {
                code: section?.code?.coding?.[0]?.code,
                display: `${display ?? ''}` + `${icpc ? ` (${icpc})` : ''}`,
              },
            ];
          }, [])
          .map((s) => s?.display)
          .filter(Boolean);

        return `${date.format('LL')}${sections?.length ? ' - ' + sections.join(', ') : ''}`;
      },
      fields: [
        {
          name: 'my_doctor_journal_lines',
          fieldAsSubgroup: true,
          fields: [
            {
              label: '',
              showAsTable: true,
              prepareData: (data, resources) => {
                if (!data?.content?.sections) return null;

                return {
                  columns: [
                    'my_doctor_type',
                    'my_doctor_description',
                    'my_doctor_identification',
                    'my_doctor_care_provider',
                  ],
                  rows:
                    data?.content?.sections?.map((section) => {
                      const allowType = ResourceSubType.gpJournalEntry;
                      const observation = resources.find(
                        (r) =>
                          r.resourceType === ResourceType.observation &&
                          allowType === r.resourceSubType &&
                          section?.entries?.find((e) => e?.reference?.split('/')?.pop() === r.resourceId)
                      );

                      const observationEntry = section?.entries?.find(
                        (e) => e?.reference?.split('/')?.pop() === observation?.resourceId
                      );

                      const code =
                        section?.code?.coding?.[0]?.display || observation?.content?.code?.coding?.[0]?.display || '';
                      const icpc1Code =
                        section?.icpc1Code?.coding?.[0]?.code ||
                        observation?.content?.components?.[0]?.value?.coding?.[0]?.code;
                      const practitionerId = data?.content?.authors?.[0]?.reference?.reference?.split('/')?.pop();
                      const author = [
                        observation?.content?.performers?.[0].reference?.display ||
                          observation?.content?.performers?.[0]?.practitionerRole?.display ||
                          data?.content?.authors?.[0]?.reference?.display ||
                          resources.find(
                            (r) => r.resourceType === ResourceType.practitioner && r.resourceId === practitionerId
                          )?.content?.name?.text,
                        resources.find(
                          (r) => r.resourceType === ResourceType.practitioner && r.resourceId === practitionerId
                        )?.content?.identifiers?.[0]?.value,
                      ]
                        .filter(Boolean)
                        .join(', ');

                      const entry =
                        section?.icpc1Code?.coding?.[0]?.display ||
                        observation?.content?.value ||
                        observationEntry?.display ||
                        section?.entries?.find((e) => e?.reference?.split('/')?.pop() !== observation?.resourceId)
                          ?.display;

                      return [code, entry, icpc1Code, author];
                    }) || [],
                };
              },
            },
          ],
        },
        {
          fieldAsSubgroup: true,
          name: 'my_doctor_extra_information',
          fields: [
            {
              label: 'my_doctor_name',
              fieldName: 'title',
              prepareData: (data) => data,
            },
            {
              label: 'my_doctor_author',
              fieldName: 'authors',
              prepareData: (data, resources) => {
                if (!data) return null;

                return data
                  ?.map((author) => {
                    const practitionerId = author?.reference?.reference?.split('/')?.pop();
                    const practitioner = resources.find(
                      (r) => r.resourceType === ResourceType.practitioner && r.resourceId === practitionerId
                    );

                    return [
                      practitioner?.content?.name?.text || author?.reference?.display,
                      practitioner?.content?.identifiers?.[0].value
                        ? `(${practitioner?.content?.identifiers?.[0].value})`
                        : null,
                    ]
                      .filter(Boolean)
                      .join(' ');
                  })
                  .filter(Boolean)
                  .join(', ');
              },
            },
            {
              label: 'my_doctor_type',
              fieldName: 'encounter',
              prepareData: (data) => data?.display?.split(' ')?.reverse()?.pop(),
            },
            {
              label: 'my_doctor_start_date',
              fieldName: 'encounter',
              prepareData: (data, resources) => {
                if (!data) return null;
                const [referenceType, referenceId] = (data?.reference ?? '').split('/');
                const period = resources.find(
                  (r) =>
                    r.resourceType === ResourceType.encounter &&
                    referenceType === ResourceType.encounter &&
                    referenceId === r.resourceId
                )?.content?.period;
                return period?.start ? MedrecordMoment(period?.start).format('LL') : null;
              },
            },
            {
              label: 'my_doctor_end_date',
              fieldName: 'encounter',
              prepareData: (data, resources) => {
                if (!data) return null;
                const [referenceType, referenceId] = (data?.reference ?? '').split('/');
                const period = resources.find(
                  (r) =>
                    r.resourceType === ResourceType.encounter &&
                    referenceType === ResourceType.encounter &&
                    referenceId === r.resourceId
                )?.content?.period;
                return period?.end ? MedrecordMoment(period?.end).format('LL') : null;
              },
            },
          ],
        },
        {
          name: 'my_doctor_laboratory_results',
          fieldAsSubgroup: true,
          fields: [
            {
              label: '',
              showAsTable: true,
              prepareData: (data, resources) => {
                if (!data?.content?.encounter) return null;

                const encounterRef = data?.content?.encounter?.reference;
                if (!encounterRef) return null;

                const allowType = [ResourceSubType.bloodGlucose, ResourceSubType.laboratoryTestResult];
                const requests = resources.filter(
                  (r) =>
                    r.resourceType === ResourceType.observation &&
                    allowType.includes(r.resourceSubType) &&
                    r?.content?.context?.reference === encounterRef
                );

                if (!requests.length) return null;

                const rows = [];

                const emptyColumns = Array(8).fill(false);

                requests.forEach((request) => {
                  let name;
                  let code;
                  let glucose;
                  let high;
                  let low;
                  let interpretation;
                  let date = MedrecordMoment(request?.content?.effective).format('LL');

                  const practitionerId = data?.content?.authors?.[0]?.reference?.reference?.split('/')?.pop();

                  const author = [
                    data?.authors?.[0]?.practitionerRole?.display,
                    resources.find(
                      (r) => r.resourceType === ResourceType.practitioner && r.resourceId === practitionerId
                    )?.content?.identifiers?.[0]?.value,
                  ]
                    .filter(Boolean)
                    .join(', ');

                  switch (request.resourceSubType) {
                    case ResourceSubType.bloodGlucose:
                      name = request?.content?.code?.coding?.[0]?.display;
                      code = request?.content?.code?.coding?.[0]?.code;
                      glucose = request?.content?.glucose
                        ? `${request.content.glucose.value} ${request.content.glucose.code}`
                        : '';
                      high = request?.content?.referenceRanges?.[0]?.high
                        ? `${request.content.referenceRanges[0].high.value} ${request.content.referenceRanges[0].high.code}`
                        : '';
                      low = request?.content?.referenceRanges?.[0]?.low
                        ? `${request.content.referenceRanges[0].low.value} ${request.content.referenceRanges[0].high.low}`
                        : '';
                      interpretation = request?.content?.interpretation?.coding?.[0]?.code;
                      break;

                    case ResourceSubType.laboratoryTestResult:
                      date = MedrecordMoment(request?.content?.testDateTime).format('LL');
                      name = request?.content?.testCode?.coding?.[0]?.display;
                      code = request?.content?.testCode?.coding?.[0]?.code;
                      glucose = request?.content?.testResult?.coding?.[0]?.display;
                      high = request?.content?.upperLimit;
                      low = request?.content?.lowerLimit;
                      interpretation = request?.content?.interpretationFlags;
                      break;
                    case 'BLOOD_PRESSURE':
                    case 'PULSE_RATE':
                      code = '';
                      break;
                    default:
                      return;
                  }

                  if (!name && !code) return;

                  const newRow = [date, name, code, glucose, low, high, interpretation, author];
                  newRow.forEach((value, index) => {
                    emptyColumns[index] = !(
                      (value ||
                        (typeof value === 'number' && value === 0) ||
                        (typeof value === 'boolean' && value === false)) &&
                      value !== ''
                    );
                  });

                  rows.push(newRow);
                });

                return {
                  columns: [
                    'healthcare_providers_resource_effective',
                    'healthcare_providers_names',
                    'healthcare_providers_resource_testCode',
                    'healthcare_providers_resource_value',
                    'healthcare_providers_resource_lowerLimit',
                    'healthcare_providers_resource_upperLimit',
                    'healthcare_providers_resource_interpretationFlags',
                    'healthcare_providers_provider_name',
                  ].filter((_, i) => !emptyColumns[i]),
                  rows: rows.map((row) => row.filter((_, i) => !emptyColumns[i])),
                };
              },
            },
          ],
        },
        {
          name: 'my_doctor_medication_agreements',
          fieldAsSubgroup: true,
          fields: [
            {
              label: '',
              showAsTable: true,
              prepareData: (data, resources) => {
                if (!data?.content?.encounter) return null;

                const encounterRef = data?.content?.encounter?.reference;
                if (!encounterRef) return null;

                const requests = resources.filter(
                  (r) =>
                    r.resourceType === ResourceType.medicationRequest && r?.content?.context?.reference === encounterRef
                );

                if (!requests.length) return null;

                const rows = requests.map((request) => {
                  const mbh = request?.content?.medicationTreatment?.value;
                  let duration = request?.content?.duration;

                  if (duration) {
                    duration = translateService.instant('healthcare_providers_resource_repeat_period_cyclical_value', {
                      period: duration.value,
                      unit: translateService.instant(`healthcare_providers_resource_duration_unit_${duration.unit}`),
                    });
                  }

                  let agreedMedicineId = request?.content?.agreedMedicine?.reference;

                  if (agreedMedicineId) {
                    agreedMedicineId = agreedMedicineId.split('/').pop();
                    const medication = resources.find(
                      (r) => r.resourceType === ResourceType.medication && r.resourceId === agreedMedicineId
                    );

                    agreedMedicineId = medication?.content?.medicationCode?.coding;

                    if (agreedMedicineId) {
                      const priorityCodes = {
                        'urn:oid:2.16.840.1.113883.2.4.4.8': 'ZI',
                        'urn:oid:2.16.840.1.113883.2.4.4.7': 'HPK',
                        'urn:oid:2.16.840.1.113883.2.4.4.10': 'PRK',
                        'urn:oid:2.16.840.1.113883.2.4.4.1': 'GPK',
                        'urn:oid:1.3.160': 'QTIN',
                      };

                      agreedMedicineId = agreedMedicineId
                        .map((item) => {
                          return `${priorityCodes[item.system] || ''}:${item.code}`;
                        })
                        .join(' ');
                    }
                  }
                  const agreedMedicine = request?.content?.agreedMedicine?.display;
                  const dosage = request?.content?.dosage?.[0]?.text;
                  const route = request?.content?.dosage?.[0]?.route?.coding?.[0]?.display;
                  const practitionerId = data?.content?.authors?.[0]?.reference?.reference?.split('/')?.pop();

                  const author = [
                    data?.authors?.[0]?.practitionerRole?.display,
                    resources.find(
                      (r) => r.resourceType === ResourceType.practitioner && r.resourceId === practitionerId
                    )?.content?.identifiers?.[0]?.value,
                  ]
                    .filter(Boolean)
                    .join(', ');

                  return [mbh, duration, agreedMedicineId, agreedMedicine, dosage, route, '', author];
                });

                return {
                  columns: [
                    'MBH-id',
                    'healthcare_providers_resource_duration2',
                    'Code',
                    'healthcare_providers_resource_medicine',
                    'healthcare_providers_resource_instruction',
                    'healthcare_providers_resource_administrationRoute',
                    'healthcare_providers_resource_reason_prescribe',
                    'healthcare_providers_provider_name',
                  ],
                  rows,
                };
              },
            },
          ],
        },
        {
          name: 'my_doctor_measurements',
          fieldAsSubgroup: true,
          fields: [
            {
              label: '',
              showAsTable: true,
              prepareData: (data, resources) => {
                if (!data?.content?.encounter) return null;

                const encounterRef = data?.content?.encounter?.reference;
                if (!encounterRef) return null;

                const allowType = [
                  ResourceSubType.generic,
                  ResourceSubType.bodyTemperature,
                  ResourceSubType.bloodPressure,
                  ResourceSubType.pulseRate,
                ];
                const requests = resources.filter(
                  (r) =>
                    r.resourceType === ResourceType.observation &&
                    allowType.includes(r.resourceSubType) &&
                    r?.content?.context?.reference === encounterRef
                );

                if (!requests.length) return null;

                const rows = [];

                requests.forEach((req) => {
                  const request = { ...req };
                  let code;
                  let value;
                  const date = MedrecordMoment(
                    request?.content?.effective?.date || data?.content?.encounter?.date
                  ).format('LL');

                  switch (request.resourceSubType) {
                    case ResourceSubType.bodyTemperature:
                      code = 'Temperature (oraal gemeten) (1915)';
                      value = request?.content?.temperature;
                      break;
                    case 'GENERIC':
                      code = request?.content?.nhgCode
                        ? `${
                            request?.content?.nhgCode.display.charAt(0).toUpperCase() +
                            request?.content?.nhgCode.display.slice(1)
                          } (${request?.content?.nhgCode.code})`
                        : '';
                      value = request?.content?.value;

                      if (value && Array.isArray(value.coding)) {
                        value = [...value.coding].pop();
                        value = value.display + `${value.code ? ' (' + value.code + ')' : ''}`;
                      }
                      break;
                    case 'BLOOD_PRESSURE':
                      code = request?.content?.nhgCode
                        ? `${
                            request?.content?.nhgCode.display.charAt(0).toUpperCase() +
                            request?.content?.nhgCode.display.slice(1)
                          } (${request?.content?.nhgCode.code})`
                        : '';

                      value = request?.content?.diastolic;
                      if (!value) value = request?.content?.systolic;

                      break;
                    case 'PULSE_RATE':
                      code = request?.content?.nhgCode
                        ? `${
                            request?.content?.nhgCode.display.charAt(0).toUpperCase() +
                            request?.content?.nhgCode.display.slice(1)
                          } (${request?.content?.nhgCode.code})`
                        : '';
                      value = request?.content?.rate;
                      if (value?.code?.startsWith('/')) value = { ...value, code: 'p' + value.code };

                      break;
                    default:
                      return;
                  }

                  if (!value && !code) {
                    return;
                  }

                  rows.push([
                    date,
                    code,
                    value && (typeof value === 'string' ? value : `${value.value} ${value.code}`),
                  ]);
                });
                return {
                  columns: [
                    'healthcare_providers_resource_effective',
                    'healthcare_providers_resource_description',
                    'healthcare_providers_results',
                  ],
                  rows,
                };
              },
            },
          ],
        },
      ],
    },
  ],
});
