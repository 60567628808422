import { Component, Input, EventEmitter, Output } from '@angular/core';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ChangeEventArgs } from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'medsafe-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input()  set settingsModel(settings: PageSettingsModel) {
    const page = settings.currentPage; // starts from 1
    const total = settings.totalRecordsCount;
    const pageSize = settings.pageSize;

    this.startRange = Math.min(total, ((page - 1) * pageSize + 1)); // when total == 0
    this.endRange = Math.min(total, page * pageSize);
    this.totalPages = settings.pageCount || Math.ceil(total / pageSize);

    this.settings = settings;
  }
  @Input() pageItemsCount: string[] = ['5', '10', '15', '20'];
  @Input() hideWhenOnePage: boolean;

  @Output() change = new EventEmitter<{ settingsModel: PageSettingsModel }>();

  settings: PageSettingsModel = {};
  startRange: number;
  endRange: number;
  totalPages: number;

  showPagination(): boolean {
    return this.totalPages > 1 || !this.hideWhenOnePage;
  }

  changeItemsPerPage(args: ChangeEventArgs): void {
    const pageSize = parseInt(args.itemData.value, 10);
    this.settings = {
      ...this.settings,
      pageSize: pageSize,
      currentPage: 1,
      pageCount: Math.ceil(this.settings.totalRecordsCount / pageSize),
    };
    this.emitChanges();
  }

  nextPage(): void {
    this.selectPage(1);
  }

  prevPage(): void {
    this.selectPage(-1);
  }

  private selectPage(step: number): void {
    this.settings = { ...this.settings, currentPage: this.settings.currentPage + step };
    this.emitChanges();
  }

  private emitChanges(): void {
    this.change.emit({ settingsModel: this.settings });
  }
}
