<ng-container *ngIf="permission$ | async">
  <doctor-gui-careproviders-page
    [careproviders]="careproviders$ | async"
    [careprovidersCount]="count$ | async"
    (careproviderSelected)="careproviderSelected($event)"
    (pagingUpdated)="updatePaging($event)"
    (searchTriggered)="onSearch($event)"
    (careprovidersFavouriteToggled)="toggleFavourite($event)"
  >
  </doctor-gui-careproviders-page>
</ng-container>
