import { createAction, props } from '@ngrx/store';
import { Device } from '../models/device.model';

export const loadDevicesAction = createAction('[FoodFriend Actions] Load Devices');

export const loadDevicesActionSuccess = createAction(
  '[FoodFriend Actions] Load Devices Success',
  props<{ devices: Device[] }>()
);

export const loadDevicesFailureAction = createAction(
  '[FoodFriend Actions] Load Devices Failure',
  props<{ error: Error }>()
);

export const loadCurrentDeviceAction = createAction(
  '[FoodFriend Actions] Load Current Device Action',
  props<{ serial_number: string }>()
);

export const loadCurrentDeviceSuccessAction = createAction(
  '[FoodFriend Actions] Load Current Device Success Action',
  props<{ currentDevice: Device }>()
);

export const loadCurrentDeviceFailureAction = createAction(
  '[FoodFriend Actions] Load Current Device Failure Action',
  props<{ error: Error }>()
);

export const clearCurrentDeviceAction = createAction('[FoodFriend Actions] Clear Current Device Action');

export const saveCurrentFeedPackAction = createAction(
  '[FoodFriend Actions] Save Current Feed Pack Action',
  props<{ serialNumber: string; payload: Device }>()
);

export const saveCurrentFeedPackSuccessAction = createAction(
  '[FoodFriend Actions] Save Current Feed Pack Action Success',
  props<{ device: Device }>()
);

export const saveCurrentFeedPackFailureAction = createAction(
  '[FoodFriend Actions] Save Current Feed Pack Action Failure',
  props<{ error: Error }>()
);

export const loadDevicesHistoryAction = createAction(
  '[FoodFriend Actions] Load Device History Action',
  props<{ deviceId: string; from?: string; to?: string }>()
);

export const loadDeviceHistorySuccessAction = createAction(
  '[FoodFriend Actions] Load Device History Success Action',
  props<{ history: Device[]; deviceId: string }>()
);

export const loadDeviceHistoryFailureAction = createAction(
  '[FoodFriend Actions] Load Device History Failure Action',
  props<{ error: Error }>()
);
