import { GenericMapper } from './generic.mapper';
import { CollapsibleTable, ColumnDefinition } from '../interfaces';
import { LabResultStatus, Resource, ResourceSubType, ResourceType } from '../enums';
import { DatePrecision, EffectiveDate } from '@medrecord/services-datetime';
import { MapperUtil } from './mapper.util';
import { ClinicalStatus } from '@medrecord/core';
import { UsageStatus } from '@managers/medications-manager';

export class JournalMapper extends GenericMapper<JournalColumn> {

  protected get title(): string {
    return 'my_doctor_journal';
  }

  protected get mainKey(): Resource {
    return Resource.gpSoap2020;
  }

  protected get mainType(): ResourceType {
    return ResourceType.composition;
  }

  protected get sortBy(): JournalColumn[] {
    return [
      JournalColumn.endDate,
      JournalColumn.startDate
    ];
  }

  protected get groupBy(): JournalColumn {
    return null;
  }

  protected get groupSortOrder(): { [key: string]: number } {
    return null;
  }

  protected get activeGroups(): string[] {
    return null;
  }

  protected get columns(): ColumnDefinition<JournalColumn>[] {
    return [
      {
        property: JournalColumn.type,
        title: 'my_doctor_type',
        width: 11,
        lines: [{
          type: 'string',
          bold: true
        }, {
          type: 'string'
        }]
      },
      {
        property: JournalColumn.startDate,
        title: 'my_doctor_start_date',
        width: 13,
        lines: [{
          type: 'date'
        }]
      },
      {
        property: JournalColumn.endDate,
        title: 'my_doctor_end_date',
        width: 12,
        lines: [{
          type: 'date'
        }]
      },
      {
        property: JournalColumn.healthcare,
        title: 'my_doctor_careprovider',
        width: 26,
        lines: [{
          type: 'string',
          bold: true
        }, {
          type: 'string'
        }, {
          type: 'string'
        }]
      },
      {
        property: JournalColumn.description,
        title: 'my_doctor_description',
        width: 37,
        lines: [{
          type: 'string'
        }, {
          type: 'string'
        }, {
          type: 'string'
        }]
      }
    ];
  }

  protected get collapsibleTables(): CollapsibleTable<JournalColumn>[] {
    return [
      {
        property: JournalColumn.journalLines,
        title: 'my_doctor_journal_lines',
        columns: [
          {
            property: JournalColumn.journalLinesType,
            title: 'my_doctor_type',
            width: 11,
            lines: [{
              type: 'string'
            }]
          },
          {
            property: JournalColumn.journalLinesDescription,
            title: 'my_doctor_description',
            width: 26,
            lines: [{
              type: 'string'
            }]
          },
          {
            property: JournalColumn.journalLinesHealthcare,
            title: 'my_doctor_careprovider',
            width: 13,
            lines: [{
              type: 'string'
            }]
          },
          {
            property: JournalColumn.journalLinesIcpc,
            title: 'healthcare_providers_icpc',
            width: 50,
            lines: [{
              type: 'string'
            }]
          }
        ]
      },
      {
        property: JournalColumn.labResults,
        title: 'my_doctor_laboratory_result',
        columns: [
          {
            property: JournalColumn.labResultsStatus,
            title: 'my_doctor_status',
            width: 11,
            lines: [{
              type: 'chip'
            }]
          },
          {
            property: JournalColumn.labResultsName,
            title: 'my_doctor_name',
            width: 13,
            lines: [{
              type: 'translate',
              bold: true
            }]
          },
          {
            property: JournalColumn.labResultsDate,
            title: 'my_doctor_date',
            width: 12,
            lines: [{
              type: 'precisionDate'
            }]
          },
          {
            property: JournalColumn.labResultsTestCode,
            title: 'my_doctor_testCode',
            width: 13,
            lines: [{
              type: 'string'
            }]
          },
          {
            property: JournalColumn.labResultsResults,
            title: 'healthcare_providers_results',
            width: 13,
            lines: [{
              type: 'multiline'
            }]
          },
          {
            property: JournalColumn.labResultsLowerLimit,
            title: 'my_doctor_lower_limit',
            width: 7,
            lines: [{
              type: 'string'
            }]
          },
          {
            property: JournalColumn.labResultsUpperLimit,
            title: 'my_doctor_upper_limit',
            width: 6,
            lines: [{
              type: 'string'
            }]
          },
          {
            property: JournalColumn.labResultsInterpretationFlags,
            title: 'my_doctor_interpretation_flags',
            width: 13,
            lines: [{
              type: 'string'
            }]
          },
          {
            property: JournalColumn.labResultsHealthcareProvider,
            title: 'my_doctor_careprovider',
            width: 11,
            lines: [{
              type: 'string'
            }]
          }
        ]
      },
      {
        property: JournalColumn.medications,
        title: 'my_doctor_medication',
        columns: [
          {
            property: JournalColumn.medicationsStatus,
            title: 'my_doctor_status',
            width: 11,
            lines: [{
              type: 'chip'
            }]
          },
          {
            property: JournalColumn.medicationsMedication,
            title: 'my_doctor_medication',
            width: 13,
            lines: [{
              type: 'string'
            }]
          },
          {
            property: JournalColumn.medicationsDate,
            title: 'my_doctor_date',
            width: 12,
            lines: [{
              type: 'range',
              textXs: true
            }, {
              type: 'duration',
              textXs: true
            }]
          },
          {
            property: JournalColumn.medicationsDosage,
            title: 'my_doctor_dosage',
            width: 13,
            lines: [{
              type: 'string'
            }]
          },
          {
            property: JournalColumn.medicationsHealthcareProvider,
            title: 'my_doctor_careprovider',
            width: 13,
            lines: [{
              type: 'string'
            }]
          },
          {
            property: JournalColumn.medicationsAdministrationRoute,
            title: 'my_doctor_administration_route',
            width: 7,
            lines: [{
              type: 'translate',
              translationPrefix: 'medications_route_type_'
            }]
          },
          {
            property: JournalColumn.medicationsCode,
            title: 'my_doctor_code',
            width: 6,
            lines: [{
              type: 'string'
            }]
          },
          {
            property: JournalColumn.medicationsMbhId,
            title: 'healthcare_providers_resource_mbh_identification',
            width: 13,
            lines: [{
              type: 'string'
            }]
          },
          {
            property: JournalColumn.medicationsPrescriptionReason,
            title: 'healthcare_providers_resource_reason_prescribe',
            width: 11,
            lines: [{
              type: 'string'
            }]
          }
        ]
      }
    ];
  }

  protected get noGroups(): boolean {
    return true;
  }

  private medications2020: { [type in ResourceType]?: { [id: string]: any } };

  protected getAllRows(): { [key in JournalColumn]?: any[] }[] {
    const observationsByEncounter = this.getObservationsByEncounter();
    const medicationsByEncounter = this.getMedicationsByEncounter();
    return this.mainResources.map(j => { // j = journal
      const e = this.getReference(ResourceType.encounter, j.encounter);
      return {
        [JournalColumn.type]: [
          MapperUtil.codeableConcept(e?.type),
          e?.identifier?.value
        ],
        [JournalColumn.startDate]: [e?.period?.start],
        [JournalColumn.endDate]: [e?.period?.end],
        [JournalColumn.healthcare]: [
          e?.contactWith?.display,
          this.getReference(ResourceType.practitioner, e?.contactWith)?.identifiers?.[0]?.value,
          e?.location?.display
        ],
        [JournalColumn.description]: [
          e?.problem?.display || e?.deviatingResult,
          e?.origin,
          e?.destination
        ],

        [JournalColumn.journalLines]: this.mapJournalLines(j),

        [JournalColumn.labResults]: this.mapLabResults(j, observationsByEncounter),

        [JournalColumn.medications]: this.mapMedications(j, medicationsByEncounter)
      };
    });
  }

  private getObservationsByEncounter(): { [key: string]: any[] } {
    const labResults2020 = this.extractAllResources(Resource.gpLaboratoryResults2020);
    if (!labResults2020) {
      return {};
    }
    const observations = Object.values(labResults2020[ResourceType.observation]);
    return observations.reduce((prev, curr) => {
      const encounterReference = curr.context?.reference as string;
      if (!encounterReference) {
        return prev;
      }
      if (!prev[encounterReference]) {
        prev[encounterReference] = [];
      }
      prev[encounterReference].push(curr);
      return prev;
    }, {} as { [key: string]: any[] });
  }

  private getMedicationsByEncounter(): { [key: string]: any[] } {
    this.medications2020 = this.extractAllResources(Resource.gpCurrentMedication2020);
    if (!this.medications2020) {
      return {};
    }
    const medication = Object.values(this.medications2020[ResourceType.medicationRequest]);
    return medication.reduce((prev, curr) => {
      const encounterReference = curr.context?.reference as string;
      if (!encounterReference) {
        return prev;
      }
      if (!prev[encounterReference]) {
        prev[encounterReference] = [];
      }
      prev[encounterReference].push(curr);
      return prev;
    }, {} as { [key: string]: any[] });
  }

  private mapJournalLines = (j): { [key in JournalColumn]?: any[] }[] => {
    const observations = j.sections?.map(s => {
      const oRef = s.entries.find(e => e.reference?.split('/')[0] === ResourceType.observation);
      return this.getReference(ResourceType.observation, oRef);
    }).filter(Boolean);
    return observations.map(o => ({
      [JournalColumn.journalLinesType]: [o.code?.coding?.[0]?.code],
      [JournalColumn.journalLinesDescription]: [o.value],
      [JournalColumn.journalLinesHealthcare]: [o.performers?.[0]?.reference?.display],
      [JournalColumn.journalLinesIcpc]: [MapperUtil.codeableConcept(o.components?.[0]?.value)]
    }));
  }

  private mapLabResults = (j, observationsByEncounter): { [key in JournalColumn]?: any[] }[] => {
    const observations = observationsByEncounter[j.encounter?.reference] || [];
    return observations.map(ml => { // m = measurement or lab result
      return ml.observationType === ResourceSubType.laboratoryTestResult
        ? this.mapLabResult(ml)
        : this.mapMeasurement(ml);
    });
  }

  private mapLabResult(l: any): { [key in JournalColumn]?: any[] } {
    const extractedStatus = MapperUtil.codeableConcept(l.resultStatus);
    const interpretationFlag = l.interpretationFlags?.find(flag => flag.system === 'http://hl7.org/fhir/v2/0078');
    return {
      [JournalColumn.labResultsStatus]: [extractedStatus ? extractedStatus : LabResultStatus.final],
      [JournalColumn.labResultsName]: [MapperUtil.codeableConcept(l.testCode)],
      [JournalColumn.labResultsTestCode]: [l.testCode?.coding?.[0]?.code],
      [JournalColumn.labResultsDate]: [{ date: l.testDateTime, precision: DatePrecision.day } as EffectiveDate],
      [JournalColumn.labResultsResults]: [MapperUtil.mapObservationResults(l, this.translateService)],
      [JournalColumn.labResultsInterpretationFlags]: [interpretationFlag?.display || interpretationFlag?.code],
      [JournalColumn.labResultsLowerLimit]: [MapperUtil.quantity(l.lowerLimit)],
      [JournalColumn.labResultsUpperLimit]: [MapperUtil.quantity(l.upperLimit)],
      [JournalColumn.labResultsHealthcareProvider]: [l.performers?.[0]?.reference?.display || l.performer?.display],
      [JournalColumn.labResultsForComparison]: [l.testDateTime]
    };
  }

  private mapMeasurement(m: any): { [key in JournalColumn]?: any[] } {
    return {
      [JournalColumn.labResultsStatus]: [LabResultStatus.final],
      [JournalColumn.labResultsName]: [!m.observationType
        ? ''
        : m.observationType === ResourceSubType.generic
          ? m.observationType
          : 'measurements_' + m.observationType.toLowerCase()],
      [JournalColumn.labResultsTestCode]: [m.testCode?.coding?.[0]?.code],
      [JournalColumn.labResultsDate]: [m.effective],
      [JournalColumn.labResultsResults]: [MapperUtil.mapObservationResults(m, this.translateService)],
      [JournalColumn.labResultsInterpretationFlags]: [null],
      [JournalColumn.labResultsLowerLimit]: [null],
      [JournalColumn.labResultsUpperLimit]: [null],
      [JournalColumn.labResultsHealthcareProvider]: [m.performers?.[0]?.reference?.display || m.performer?.display],
      [JournalColumn.labResultsForComparison]: [m.effective?.date]
    };
  }

  private mapMedications = (j, medicationsByEncounter): { [key in JournalColumn]?: any[] }[] => {
    const medications = medicationsByEncounter[j.encounter?.reference] || [];
    return medications.map(m => ({
      [JournalColumn.medicationsStatus]: [(m.cancelIndicator || m.stopType) ? ClinicalStatus.Inactive : UsageStatus.Active],
      [JournalColumn.medicationsMedication]: [m.agreedMedicine?.display],
      [JournalColumn.medicationsDosage]: [m.dosage?.[0].text],
      [JournalColumn.medicationsDate]: [{
        start: m.periodOfUse?.start,
        end: m.periodOfUse?.end
      }, m.duration ? {
        period: m.duration.value,
        unit: m.duration.unit
      } : null],
      [JournalColumn.medicationsHealthcareProvider]: [m.provider?.display],
      [JournalColumn.medicationsAdministrationRoute]: [MapperUtil.routeFromDutch(MapperUtil.codeableConcept(m.dosage?.[0].route))?.toLowerCase()],
      [JournalColumn.medicationsCode]: [[
        MapperUtil.mapCodeSystem(this.getReference(ResourceType.medication, m.agreedMedicine, this.medications2020).medicationCode.coding[0].system),
        this.getReference(ResourceType.medication, m.agreedMedicine, this.medications2020).medicationCode.coding[0].code
      ].filter(Boolean).join(': ')],
      [JournalColumn.medicationsMbhId]: [m.medicationTreatment?.value],
      [JournalColumn.medicationsPrescriptionReason]: [m.prescriptionReason]
    }));
  }

}

enum JournalColumn {
  type = 'type',
  startDate = 'startDate',
  endDate = 'endDate',
  healthcare = 'healthcare',
  description = 'description',

  journalLines = 'journalLines', // collapsible table

  journalLinesType = 'journalLinesType',
  journalLinesDescription = 'journalLinesDescription',
  journalLinesHealthcare = 'journalLinesHealthcare',
  journalLinesIcpc = 'journalLinesIcpc',

  labResults = 'labResults', // collapsible table

  labResultsStatus = 'labResultsStatus',
  labResultsName = 'labResultsName',
  labResultsTestCode = 'labResultsTestCode',
  labResultsDate = 'labResultsDate',
  labResultsResults = 'labResultsResults',
  labResultsInterpretationFlags = 'labResultsInterpretationFlags',
  labResultsLowerLimit = 'labResultsLowerLimit',
  labResultsUpperLimit = 'labResultsUpperLimit',
  labResultsHealthcareProvider = 'labResultsHealthcareProvider',
  labResultsForComparison = 'labResultsForComparison',

  medications = 'medications', // collapsible table

  medicationsStatus = 'medicationsStatus',
  medicationsMedication = 'medicationsMedication',
  medicationsDosage = 'medicationsDosage',
  medicationsDate = 'medicationsDate',
  medicationsHealthcareProvider = 'medicationsHealthcareProvider',
  medicationsAdministrationRoute = 'medicationsAdministrationRoute',
  medicationsCode = 'medicationsCode',
  medicationsMbhId = 'medicationsMbhId',
  medicationsPrescriptionReason = 'medicationsPrescriptionReason',
  medicationsForComparison = 'medicationsForComparison',
}
