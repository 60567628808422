import { Component, Input } from '@angular/core';

@Component({
  selector: 'medrecord-ui-one-line-column',
  templateUrl: './one-line-block-column.component.html',
  styleUrls: ['./one-line-block-column.component.scss']
})
export class OneLineBlockColumnComponent {
  @Input() value: string;
  @Input() color: string;
}