import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-medmij-verified',
  templateUrl: './medmij-verified.component.svg',
  styleUrls: ['./medmij-verified.component.scss'],
})
export class MedmijVerifiedComponent extends MedrecordIcon {
  viewHeight = 24;
  viewWidth = 24;
}
