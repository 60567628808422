<div class="w-100 my-4 d-flex align-items-center justify-content-between">
  <div class="w-50 d-flex align-items-center">
    <input
      [id]="'serach'"
      (input)="onSearchChange($event.target.value)"
      [value]="searchValue"
      type="text"
      class="e-input-group mr-2"
      (keyup.enter)="onSearch()"
      [placeholder]="'my_doctor_search_location' | translate"
    />
    <button class="button btn btn-secondary font-weight-bold" (click)="onSearch()" type="button">
      {{ 'common_search' | translate | titlecase }}
    </button>
  </div>
  <div>
    <doctor-gui-delete-all-data-button-modal class="mr-3"></doctor-gui-delete-all-data-button-modal>
    <doctor-gui-portability-report-button-modal></doctor-gui-portability-report-button-modal>
  </div>
</div>
<ejs-grid
  #grid
  [rowHeight]="80"
  class="table-card"
  [dataSource]="careproviders"
  [allowPaging]="true"
  [height]="pageSettings.totalRecordsCount ? 'auto' : '50vh'"
>
  <ng-template #pagerTemplate>
    <medsafe-pagination [settingsModel]="pageSettings" (change)="updatePaging($event)" [hideWhenOnePage]="true"></medsafe-pagination>
  </ng-template>
  <e-columns>
    <e-column [minWidth]="'80%'" [maxWidth]="'100%'" field="name" [headerText]="'common_column_name' | translate">
      <ng-template #template let-data>
        <div class="d-flex align-items-center">
          <div class="disable" (click)="toggleFavourite(data)">
            <i
              [class]="data.userScope ? 'fas fa-star' : 'far fa-star'"
              [style.color]="data.userScope ? 'rgba(255, 193, 7, 1)' : 'rgba(108, 117, 125, 1)'"
            ></i>
          </div>
          <div class="d-flex flex-column pl-4 hoverable" (click)="onCareProviderSelect(data)">
            <div class="d-flex align-items-center">
              <a class="nav-cell mr-1" (click)="onCareProviderSelect(data)"> {{ getDisplayName(data) }}</a>
              <medrecord-svg-medmij-verified></medrecord-svg-medmij-verified>
            </div>
            <div class="careprovider-info">
              {{ getContactInformation(data) }}
            </div>
          </div>
        </div>
      </ng-template>
    </e-column>
  </e-columns>
</ejs-grid>
