export enum RouteType {
  orally = 'ORALLY',
  rectally = 'RECTALLY',
  subcutaneously = 'SUBCUTANEOUSLY',
  transdermally = 'TRANSDERMALLY',
  intravenously = 'INTRAVENOUSLY',
  intramuscularly = 'INTRAMUSCULARLY',
  sublingually = 'SUBLINGUALLY',
  bucally = 'BUCALLY',
  vaginally = 'VAGINALLY',
  nasally = 'NASALLY',
  byInhalation = 'BY_INHALATION',
  byNebulization = 'BY_NEBULIZATION',
}
