import { on } from '@ngrx/store';
import {
  setParametersModalOpenStatusAction,
  setResourceParametersAction,
  setResourcesModalOpenStatusAction,
  setTestCareproviderModalOpenStatusAction,
  submitResourcesModalAction
} from '../actions';
import { State } from '../my-doctor-manager.reducer';

export const modalChainReducer = [
  on(setResourcesModalOpenStatusAction, (state: State, { isOpen }) => ({
    ...state,
    resourcesModalOpenStatus: isOpen
  })),

  on(submitResourcesModalAction, (state: State, { resources }) => ({
    ...state,
    modalSelectedResourcesKeys: resources
  })),

  on(setResourceParametersAction, (state: State, { resourceParameters }) => ({
    ...state,
    resourceParameters
  })),

  on(setParametersModalOpenStatusAction, (state: State, { isOpen }) => ({
    ...state,
    parametersModalOpenStatus: isOpen
  })),

  on(setTestCareproviderModalOpenStatusAction, (state: State, { isOpen }) => ({
    ...state,
    testCareproviderModalOpenStatus: isOpen
  }))
];
