import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Resource } from '../models/enums/resource.enum';
import { PullTransactionTabularMapping } from '../models/interfaces/pull-transaction-tabular-mapping.interface';
import {
  CurrentMedicationsMapper,
  EpisodesMapper,
  JournalMapper,
  LaboratoryResultsMapper,
  MedicationIntolerancesMapper
} from '../models/mappers';
import { PullTransaction } from '../models/enums';

@Injectable()
export class PullTransactionTabularMappingService {

  private pullTransactionTabularMappings: Map<PullTransaction, PullTransactionTabularMapping> = new Map([
    [
      PullTransaction.gp2020,
      {
        mappers: {
          [Resource.gpMedicationIntolerance2020]: new MedicationIntolerancesMapper(this.translateService),
          [Resource.gpEpisode2020]: new EpisodesMapper(this.translateService),
          [Resource.gpSoap2020]: new JournalMapper(this.translateService),
          [Resource.gpLaboratoryResults2020]: new LaboratoryResultsMapper(this.translateService),
          [Resource.gpCurrentMedication2020]: new CurrentMedicationsMapper(this.translateService)
        }
      }
    ]
  ]);

  constructor(private translateService: TranslateService) {
  }

  getMappers(transactionId: PullTransaction): PullTransactionTabularMapping {
    return this.pullTransactionTabularMappings.get(transactionId);
  }
}
