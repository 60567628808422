import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  getNetworkOrganisationAction,
  getNetworkOrganisationSuccessAction
} from '../state-management/network-organisations.actions';
import { Actions, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EditOrganisationsGuard implements CanActivate {
  constructor(
    private store: Store,
    private actions$: Actions
  ) {}
  
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.store.dispatch(getNetworkOrganisationAction({ id: route.params.id }));
    return this.actions$.pipe(
      ofType(getNetworkOrganisationSuccessAction),
      switchMap(() => of(true))
    );
  }
}