import { createSelector } from '@ngrx/store';
import { FoodFriendManagerState, foodFriendManagerFeatureKey } from './food-friend-manager.reducer';
import { Device } from '../models/device.model';
import { IDeviceListItem } from '../models/device-list-item.model';

export const getFoodFriendManagerState: (state: FoodFriendManagerState) => any = (state: FoodFriendManagerState) =>
  state[foodFriendManagerFeatureKey];

export const getDevices = createSelector(
  getFoodFriendManagerState,
  (state: FoodFriendManagerState): IDeviceListItem[] => state.devices
);

export const getCurrentDevice = createSelector(
  getFoodFriendManagerState,
  (state: FoodFriendManagerState): Device => state.currentDevice
);

export const _getCurrentFeedPack = createSelector(
  getFoodFriendManagerState,
  (state: FoodFriendManagerState): any => state._currentFeedPack
);

export const getCurrentFeedPack = createSelector(
  getFoodFriendManagerState,
  (state: FoodFriendManagerState): any => state.currentDevice
);

export const getDevicesHistory = createSelector(
  getFoodFriendManagerState,
  (state: FoodFriendManagerState): any => state.history
);

export const getDeviceHistory = (deviceId: string) =>
  createSelector(getFoodFriendManagerState, (state: FoodFriendManagerState): Device[] => state.history[deviceId]);
