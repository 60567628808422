import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { creatWeatherManagerReducer, weatherManagerKey } from './weather-manager.reducer';
import { WeatherManagerEffects } from './weather-manager.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(weatherManagerKey, creatWeatherManagerReducer),
    EffectsModule.forFeature([WeatherManagerEffects]),
  ],
})
export class WeatherManagerStateModule {}
