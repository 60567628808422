import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractModalComponent } from '../abstract-modal/abstract-modal.component';

@Component({
  selector: 'doctor-gui-abstract-form-modal',
  template: '<p>implementation is not needed</p>'
})
export class AbstractFormModalComponent<T> extends AbstractModalComponent { // abstract
  @Output() modalSubmitted: EventEmitter<T> = new EventEmitter<T>();

  form: FormGroup;

  baseFormSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.formSubmit();
    }
  }

  protected formSubmit(): void { // abstract
    // implementation is not needed
    throw new Error('Method not overriden.');
  }
}
