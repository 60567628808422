import { InjectionToken } from '@angular/core';

export enum MedsafeRouteParams {
  id = 'id',
  allergyId = 'allergyId',
  measurementId = 'measurementId',
  observationId = 'observationId',
  immunizationId = 'immunizationId',
  conditionId = 'conditionId',
  medicationId = 'medicationId',
  taskId = 'taskId',
  questionnaireId = 'questionnaireId',
  healthplanStatus = 'healthplanStatus',
  healthplanId = 'healthplanId',
  goalId = 'goalId',
  careproviderId = 'careproviderId',
  serviceId = 'serviceId',
  postId = 'postId',
  careplanId = 'careplanId',
  recordId = 'recordId',
  motivationalMessageId = 'motivationalMessageId',
}

export const getParamByKey = (key: MedsafeRouteParams): string => ':' + key;

export const medsafeRouteNames = {
  Entry: '',
  Base: '',

  Onboarding: {
    Entry: 'onboarding',
    Invitation: 'invitation',
  },

  Dashboard: {
    Entry: 'dashboard',
  },

  Chat: {
    Entry: 'chat',
    Conversation: 'conversation',
    Configuration: 'configuration',
    MotivationalMessage: {
      Details: `${getParamByKey(MedsafeRouteParams.motivationalMessageId)}/motivational-message`,
      Add: 'add/motivational-message',
    },
  },

  News: {
    Entry: 'news',
    Browse: 'browse',
    Post: getParamByKey(MedsafeRouteParams.postId),
  },

  Profile: {
    Entry: 'personal-info',
    Edit: 'edit',
  },

  Settings: {
    Entry: 'settings',
  },

  TermsOfUse: {
    Entry: 'terms-of-use',
  },

  PrivacyPolicy: {
    Entry: 'privacy-policy',
  },

  PersonalInfo: {
    Entry: 'personal-info',
  },

  Healthplans: {
    Entry: 'healthplans',
    Active: 'active',
    Completed: 'completed',
    Add: 'add',
    Edit: 'edit',
    Goals: { Entry: 'goals', Add: 'add', Edit: 'edit', Item: getParamByKey(MedsafeRouteParams.goalId) },
    Item: getParamByKey(MedsafeRouteParams.healthplanId),
    Favorited: 'favorited',
    All: 'all',
    BasicMetabolicData: { Entry: 'basic-metabolic-data' },
    Measurements: { Entry: 'measurements' },
    Overview: { Entry: 'overview' },
  },

  ExternalServices: {
    Entry: 'external-services',
  },

  Network: {
    Entry: 'network',
    List: 'list',
    Patients: 'patients',
    WorkTeam: 'work-team',
    Team: 'team',
    Membership: 'membership',
    User: getParamByKey(MedsafeRouteParams.id),
  },

  MyDoctor: {
    Entry: 'my-doctor',
    Search: 'search',
    All: 'all',
    Favourites: 'favourites',
    Careprovider: 'careprovider',
    Unknown: 'unknown',
    Fetch: 'fetch',
    ServiceId: getParamByKey(MedsafeRouteParams.serviceId),
    CareproviderId: getParamByKey(MedsafeRouteParams.careproviderId),
    RecordId: getParamByKey(MedsafeRouteParams.recordId),
  },

  HealthTalk: {
    Dashboard: 'dashboard',
    Consult: 'consult',
  },

  Measurements: {
    Entry: 'measurements',

    Thresholds: {
      Entry: 'thresholds',
    },

    Settings: {
      Entry: 'settings',
    },

    Measurement: {
      Id: getParamByKey(MedsafeRouteParams.measurementId),
      Add: 'add',
      Edit: 'edit',
      List: 'list',
      Details: 'details',
      ObservationId: getParamByKey(MedsafeRouteParams.observationId),
    },
  },

  BasicMedicalData: {
    Entry: 'basic-medical-data',
    Allergies: {
      List: 'allergies',
      Item: getParamByKey(MedsafeRouteParams.allergyId),
      Add: 'add',
      Edit: 'edit',
    },
    Immunizations: {
      List: 'immunizations',
      Item: getParamByKey(MedsafeRouteParams.immunizationId),
      Add: 'add',
      Edit: 'edit',
    },
    Conditions: {
      List: 'conditions',
      Item: getParamByKey(MedsafeRouteParams.conditionId),
      Add: 'add',
      Edit: 'edit',
    },
    Medications: {
      List: 'medications',
      Item: getParamByKey(MedsafeRouteParams.medicationId),
      Add: 'add',
      Edit: 'edit',
    },
  },

  Questionnaires: {
    Entry: 'questionnaires',
    InUse: {
      Entry: 'in-use',
      Task: getParamByKey(MedsafeRouteParams.taskId),
      Questionnaire: getParamByKey(MedsafeRouteParams.questionnaireId),
    },
    Templates: {
      Entry: 'templates',
      Questionnaire: getParamByKey(MedsafeRouteParams.questionnaireId),
    },
    Scores: {
      Entry: 'scores',
      Task: getParamByKey(MedsafeRouteParams.taskId),
    },
  },

  Careplans: {
    Entry: 'careplans',
    Active: 'active',
    Completed: 'completed',
    Favorited: 'favorited',
    All: 'all',
    Details: {
      Entry: 'details',
      Item: getParamByKey(MedsafeRouteParams.careplanId),
      BasicMedicalData: 'careplan-basic-medical-data',
      Measurements: 'careplan-measurements',
      Weather: 'weather',
      Goals: 'careplan-goals',
      FoodFriend: {
        Entry: 'food-friend',
        Overview: 'overview',
        Used: 'used',
      },
      ClubDiabetes: {
        Entry: 'club-diabetes',
      },
      Asthma: {
        Entry: 'asthma',
      },
      News: {
        Entry: 'news',
      },
      Resources: {
        Entry: 'resources',
      },
      Calendar: {
        Entry: 'calendar',
      },
    },
  },
};

export type MedsafeRouteNames = typeof medsafeRouteNames;

export const MEDSAFE_ROUTE_NAMES = new InjectionToken<MedsafeRouteNames>(
  'Route Names connected by Medsafe` Shell library'
);

export const provideMedsafeRouteNames = (entry = '') => [
  {
    provide: MEDSAFE_ROUTE_NAMES,
    useValue: { ...medsafeRouteNames, Entry: entry },
  },
];
