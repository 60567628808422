import { Allergy } from '@managers/allergies';
import { Action, createReducer, on } from '@ngrx/store';
import {
  addAllergySuccess,
  clearAutocompleteAllergyNames,
  deleteAllergySuccessAction,
  editAllergySuccess,
  linkCareplanAllergiesSuccessAction,
  loadAllergiesFailureAction,
  loadAllergiesSuccessAction,
  loadAllergySuccessAction,
  loadAutocompleteAllergyNamesSuccess,
  loadCareplanAllergiesSuccessAction,
  removeActiveAllergy,
  removeCareplanAllergyAction,
} from './allergies-manager.actions';
import { AllergyName } from '../models/interfaces/allergy-name.interface';

export const allergiesManagerFeatureKey = 'allergies-manager';

export interface AllergiesMangerState {
  error: any;
  allergies: Allergy[];
  activeAllergy: Allergy;
  autocompleteAllergyNames: AllergyName[];
  count: number;
  careplan: {
    allergies: Allergy[];
    count: number;
  };
}

export const initialState: AllergiesMangerState = {
  error: null,
  allergies: [],
  activeAllergy: null,
  autocompleteAllergyNames: [],
  count: 0,
  careplan: {
    allergies: [],
    count: 0,
  },
};

export const allergiesManagerReducer = createReducer(
  initialState,
  on(loadAllergiesSuccessAction, (state, { allergies, count }) => ({ ...state, allergies, count })),
  on(loadCareplanAllergiesSuccessAction, (state, { allergies, count }) => ({
    ...state,
    careplan: { allergies, count },
  })),
  on(loadAllergiesFailureAction, (state, { error }) => ({ ...state, error })),
  on(loadAllergySuccessAction, (state, { allergy }) => ({ ...state, activeAllergy: allergy })),
  on(addAllergySuccess, (state, { allergy }) => ({
    ...state,
    count: state.count + 1,
    allergies: [allergy, ...state.allergies],
    careplan: {
      allergies: [allergy, ...state.careplan.allergies],
      count: state.careplan.count + 1,
    },
  })),
  on(editAllergySuccess, (state, { allergy }) => ({
    ...state,
    allergies: state.allergies.map((a) => (a.id === allergy.id ? allergy : a)),
    careplan: {
      ...state.careplan,
      allergies: state.careplan.allergies.map((a) => (a.id === allergy.id ? allergy : a)),
    },
  })),
  on(deleteAllergySuccessAction, (state, { id }) => ({
    ...state,
    allergies: [...state.allergies.filter((a) => a.id !== id)],
    careplan: {
      allergies: [...state.careplan.allergies.filter((a) => a.id !== id)],
      count: state.careplan.count - 1,
    },
  })),
  on(removeActiveAllergy, (state) => ({
    ...state,
    activeAllergy: null,
  })),
  on(loadAutocompleteAllergyNamesSuccess, (state, { allergyNames }) => ({
    ...state,
    autocompleteAllergyNames: allergyNames,
  })),
  on(clearAutocompleteAllergyNames, (state) => ({ ...state, autocompleteAllergyNames: [] })),
  on(linkCareplanAllergiesSuccessAction, (state, { allergies }) => ({
    ...state,
    careplan: {
      allergies: [...allergies, ...state.careplan.allergies],
      count: state.careplan.count + allergies.length,
    },
  })),
  on(removeCareplanAllergyAction, (state, { id }) => ({
    ...state,
    careplan: {
      allergies: [...state.careplan.allergies.filter((a) => a.id !== id)],
      count: state.careplan.count - 1,
    },
  }))
);

export const reducer = (state: AllergiesMangerState, action: Action): AllergiesMangerState => {
  return allergiesManagerReducer(state, action);
};
