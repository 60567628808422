import { Injectable } from '@angular/core';
import * as TranslationsActions from './translations-management.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { concatMap, filter, mergeMap, switchMap } from 'rxjs/operators';
import { TranslationsManagementService } from '../services/translations-management.service';
import { TranslationsCategory } from '../models';
import { Language } from '@medrecord/core';

@Injectable()
export class TranslationsManagementEffects {
  @Effect()
  loadTranslationsAction$ = this.actions$.pipe(
    ofType(TranslationsActions.loadTranslationsAction),
    mergeMap(({ category, start, language }) =>
      this.translationsService.getTranslationsByCategory(category, start, language)
        .pipe(
          switchMap((items) => ([
            TranslationsActions.setTranslationByCategoryAndLang({
              category,
              language: language || Language.EN,
              pages: items.totalPages,
              total: items.total,
              translations: items.data
            })
          ])
        )
      )
    )
  );
  
  @Effect()
  loadAllTranslationsAction$ = this.actions$.pipe(
    ofType(TranslationsActions.loadAllTranslationsByCategoryAction),
    concatMap(({ category, start }) => {
      const actions = [];
      
      for (const lang in Language) {
        if (Language.hasOwnProperty(lang)) {
          actions.push(
            TranslationsActions.loadTranslationsAction({
              language: Language[lang],
              category,
              start
            })
          );
          
        }
      }
      
      return actions;
    })
  );
  
  @Effect()
  loadTranslationCategoriesAction$ = this.actions$.pipe(
    ofType(TranslationsActions.loadTranslationCategories),
    switchMap(() =>
      this.translationsService.getCategories()
        .pipe(
          filter(categories => !!categories),
          switchMap((categories) => {
            return [
              TranslationsActions.categoriesSuccessLoadingAction({ categories })
            ];
          })
        )
    )
  );
  
  @Effect()
  updateLanguage$ = this.actions$.pipe(
    ofType(TranslationsActions.setLanguageAction),
    switchMap((action) =>
      this.translationsService.getTranslationsByCategory(
        TranslationsCategory.PublicWebsite,
        0,
        action.language
      )
        .pipe(
          filter(categories => !!categories),
          switchMap((items) => {
            return [TranslationsActions.translationsSuccessLoadingAction({
              category: TranslationsCategory.PublicWebsite,
              pages: items.totalPages,
              total: items.total,
              translations: items.data
            })];
          })
        )
    )
  );
  
  @Effect()
  updatePage$ = this.actions$.pipe(
    ofType(TranslationsActions.setLanguageAction),
    switchMap(() =>
      this.translationsService.getCategories()
        .pipe(
          filter(categories => !!categories),
          switchMap((categories) => {
            return [TranslationsActions.categoriesSuccessLoadingAction({ categories })];
          })
        )
    )
  );
  
  @Effect()
  updateTranslationAction$ = this.actions$.pipe(
    ofType(TranslationsActions.updateTranslation),
    switchMap(({ translation },) =>
      this.translationsService.updateTranslation(translation)
        .pipe(
          filter(categories => !!categories),
          switchMap((categories) => {
            return [
              TranslationsActions.categoriesSuccessLoadingAction({ categories })
            ];
          })
        )
    )
  );
  
  constructor(
    private actions$: Actions,
    private translationsService: TranslationsManagementService,
  ) {}
}
