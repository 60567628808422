import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-allergy-hand',
  templateUrl: './allergy-hand.component.svg',
})
export class AllergyHandComponent extends MedrecordIcon {
  viewHeight = 16;
  viewWidth = 16;
}
