import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthWrapperComponent } from './shell/auth-wrapper/auth-wrapper.component';
import { RouterModule } from '@angular/router';
import { provideAuthConstants } from './constants';
import { MedrecordUiModule } from '@medrecord/ui';
import { AuthStepperComponent } from './components/auth-stepper/auth-stepper.component';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@medrecord/core';
import { AuthorizationErrorComponent } from './components/authorization-error/authorization-error.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { ForgotPasswordFormComponent } from './components/password/forgot-password-form/forgot-password-form.component';
import { RestorePasswordFormComponent } from './components/password/restore-password-form/restore-password-form.component';
import { SuccessRestoredComponent } from './components/password/success-restored/success-restored.component';
import { PhoneCodeComponent } from './components/verification/phone/phone-code/phone-code.component';
import { PhoneInitComponent } from './components/verification/phone/phone-init/phone-init.component';
import { PhoneSaveComponent } from './components/verification/phone/phone-save/phone-save.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordSuccessComponent } from './components/password/forgot-password-success/forgot-password-success.component';
import { ConfirmPasswordFormComponent } from './components/shared/confirm-password-form/confirm-password-form.component';
import { ConfirmationInfoComponent } from './components/confirmation-info-component/confirmation-info.component';
import { SetPasswordFormComponent } from './components/invitation/set-password-form/set-password-form.component';
import { SuccessPasswordSetComponent } from './components/invitation/success-password-set/success-password-set.component';
import { SignupFormComponent } from './components/signup-form/signup-form.component';
import { SignupSuccessComponent } from './components/signup-success/signup-success.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { TwofaSetupContainerComponent } from './components/twofa/containers/flows/twofa-setup-container/twofa-setup-container.component';
import { TwofaEnterCodeComponent } from './components/twofa/components/common/twofa-enter-code/twofa-enter-code.component';
import { QrCodeComponent } from './components/twofa/components/common/qr-code/qr-code.component';
import { TwofaVerificationComponent } from './components/twofa/components/flows/twofa-verification/twofa-verification.component';
import { TwofaReconfigureContainerComponent } from './components/twofa/containers/flows/twofa-reconfigure-container/twofa-reconfigure-container.component';
import { QrCodeContainerComponent } from './components/twofa/containers/common/qr-code-container/qr-code-container.component';
import {
  TwofaEnterCodeContainerComponent
} from './components/twofa/containers/common/twofa-enter-code-container/twofa-enter-code-container.component';
import {
  TwofaVerificationContainerComponent
} from './components/twofa/containers/flows/twofa-verification-container/twofa-verification-container.component';
import { ActionButtonsComponent } from './components/twofa/components/common/action-buttons/action-buttons.component';
import {
  TwofaRecoveryContainerComponent
} from './components/twofa/containers/flows/twofa-recovery-container/twofa-recovery-container.component';
import {
  TwofaStepsContainerComponent
} from './components/twofa/containers/common/twofa-steps-container/twofa-steps-container.component';
import { TwofaStepsComponent } from './components/twofa/components/common/twofa-steps/twofa-steps.component';
import { TwofaRecoveryComponent } from './components/twofa/components/flows/twofa-recovery/twofa-recovery.component';
import {
  ActionButtonsContainerComponent
} from './components/twofa/containers/common/action-buttons-container/action-buttons-container.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MedrecordUiModule,
    ReactiveFormsModule,
    FormsModule,
    CoreModule,
    ClipboardModule
  ],
  providers: [
    provideAuthConstants(),
  ],
  declarations: [
    AuthWrapperComponent,
    AuthStepperComponent,
    AuthorizationErrorComponent,
    LoginFormComponent,
    SignupFormComponent,
    SignupSuccessComponent,
    ForgotPasswordFormComponent,
    RestorePasswordFormComponent,
    ConfirmPasswordFormComponent,
    SetPasswordFormComponent,
    ConfirmationInfoComponent,
    SuccessPasswordSetComponent,
    SuccessRestoredComponent,
    PhoneCodeComponent,
    PhoneInitComponent,
    PhoneSaveComponent,
    ForgotPasswordSuccessComponent,
    WelcomeComponent,
    TwofaSetupContainerComponent,
    ActionButtonsContainerComponent,
    ActionButtonsComponent,
    TwofaEnterCodeContainerComponent,
    TwofaEnterCodeComponent,
    QrCodeContainerComponent,
    QrCodeComponent,
    TwofaVerificationContainerComponent,
    TwofaVerificationComponent,
    TwofaReconfigureContainerComponent,
    TwofaRecoveryContainerComponent,
    TwofaRecoveryComponent,
    TwofaStepsContainerComponent,
    TwofaStepsComponent,
  ],
  exports: [
    AuthWrapperComponent,
    AuthStepperComponent,
    AuthorizationErrorComponent,
    LoginFormComponent,
    SignupFormComponent,
    SignupSuccessComponent,
    ForgotPasswordFormComponent,
    RestorePasswordFormComponent,
    ConfirmPasswordFormComponent,
    SetPasswordFormComponent,
    ConfirmationInfoComponent,
    SuccessPasswordSetComponent,
    SuccessRestoredComponent,
    PhoneCodeComponent,
    PhoneInitComponent,
    PhoneSaveComponent,
    WelcomeComponent
  ]
})
export class AuthSharedModule {}
