import { Action, createReducer, on } from '@ngrx/store';
import {
  clearUsersStateAction,
  closeUserAction,
  dragUserAction,
  editUserFailureAction,
  editUserSuccessAction,
  hidePinedUserAction,
  loadUserMetadataFailureAction,
  loadUserMetadataSuccessAction,
  loadUserNetworksFailureAction,
  loadUsersFailureAction,
  loadUsersSuccessAction,
  pinUserFailureAction,
  pinUserSuccessAction,
  selectUser,
  sendChangePhoneCodeFailureAction,
  verifyPhoneFailureAction,
} from './users-manager.actions';
import { Permission } from '../models/enums';
import { UserTabData } from '../models/interfaces';

export const featureUsersManagerKey = 'users-manager';

export interface UsersManagerState {
  selectedUserId: string;
  users: UserTabData[];
  pinnedUsers: string[];
  error: any;
}

export const initialState: UsersManagerState = {
  selectedUserId: null,
  users: [],
  pinnedUsers: [],
  error: null,
};

export const usersManagerReducer = createReducer(
  initialState,
  on(selectUser, (state, { id }) => ({ ...state, selectedUserId: id })),

  on(loadUsersSuccessAction, (state, { user, id }) => {
    const userIndex = state.users.findIndex((u) => u.id === id);
    if (userIndex !== -1) {
      const loadedUsers = [...state.users];
      loadedUsers[userIndex] = { id, user, allergies: [], permission: Permission.MainAccount };
      return { ...state, users: loadedUsers };
    }
    return {
      ...state,
      users: [...state.users, { id, user, allergies: [], permission: Permission.MainAccount }],
    };
  }),
  on(loadUserMetadataSuccessAction, (state, { user, allergies, id, permission }) => {
    const userIndex = state.users.findIndex((u) => u.id === id);
    if (userIndex !== -1) {
      const loadedUsers = [...state.users];
      loadedUsers[userIndex] = { id, user, allergies, permission };
      return { ...state, users: loadedUsers };
    }
    return {
      ...state,
      users: [...state.users, { id, user, allergies, permission }],
    };
  }),
  on(closeUserAction, (state, { index }) => ({
    ...state,
    users: state.users.filter((_, i) => i !== index),
  })),
  on(dragUserAction, (state, { startIndex, endIndex }) => {
    let newUserOrder = [...state.users];
    const user = newUserOrder.splice(startIndex, 1);
    newUserOrder.splice(endIndex, 0, user[0]);

    return {
      ...state,
      users: [...newUserOrder],
    };
  }),

  on(editUserSuccessAction, (state, { user, id }) => {
    const userIndex = state.users.findIndex((u) => u.id === id);
    if (userIndex !== -1) {
      const loadedUsers = [...state.users];
      loadedUsers[userIndex] = { id, user, allergies: [], permission: Permission.MainAccount };
      return { ...state, users: loadedUsers };
    }
    return {
      ...state,
      users: [...state.users, { id, user, allergies: [], permission: Permission.MainAccount }],
    };
  }),
  on(pinUserSuccessAction, (state, { userId }) => ({
    ...state,
    pinnedUsers: [...state.pinnedUsers, userId],
  })),
  on(hidePinedUserAction, (state, { userId }) => ({
    ...state,
    pinnedUsers: state.pinnedUsers.filter((id) => userId !== id),
  })),
  on(clearUsersStateAction, () => ({ ...initialState })),
  on(
    pinUserFailureAction,
    loadUsersFailureAction,
    editUserFailureAction,
    verifyPhoneFailureAction,
    sendChangePhoneCodeFailureAction,
    loadUserMetadataFailureAction,
    loadUserNetworksFailureAction,
    (state, { error }) => ({
      ...state,
      error,
    })
  )
);

export function createUsersManagerReducer(state: UsersManagerState, action: Action): UsersManagerState {
  return usersManagerReducer(state, action);
}
