import { Component, Input } from '@angular/core';
import {
  MyDoctorResourceMappingService,
  MyDoctorTableInterface,
  Resource,
  ResourceData,
  ResourceDataAny,
  ResourceField,
  ResourceResponseData
} from '@managers/my-doctor';
import { TranslateService } from '@ngx-translate/core';
import { TableResourceKey } from '../../../models/table-resource-key.interface';

@Component({
  selector: 'doctor-gui-personal-details',
  templateUrl: './personal-details.component.html'
})
export class PersonalDetailsComponent {
  @Input() set resources(resources: ResourceDataAny[]) {

    // handling only ResourceData because of how resourceKeysOfInterest are defined
    // not supported for now: ResourceDataGroup
    const resourcesOfInterest: ResourceData[] = this.resourceKeysOfInterest
      .map(r => resources.find(resource => (resource as ResourceData).key === r) as ResourceData)
      .filter(item => item);


    this.tableResourceKeys = resourcesOfInterest.map(resource => {
      const block = this.mappingService.getResourceBlock(resource.key);

      // not supported for now: multiple resources
      const mainResource = resource.resources
        .filter(r =>
          r.resourceType === block.resourceType
          || (block.additionalResourceTypes ?? []).includes(r.resourceType)
        )?.[0];

      const blocks = block.fieldGroups.map(b => ({
        title: b.name as string,
        fields: b.fields.map(f => {
          f = (f as ResourceField);
          const value = this.prepareDataForField(f, mainResource, resource.resources);
          return {
            label: f.label,
            value: value && f.translate ? this.translateService.instant(value as string) : value
          };
        }).filter(item => item.value)
      })).filter(item => item.fields.length > 0);

      return {
        blocks
      };
    });
  }

  readonly resourceKeysOfInterest = [
    Resource.gpPatient2020,
    Resource.gpPractitioner2020
  ];

  tableResourceKeys: TableResourceKey[] = [];

  constructor(
    private mappingService: MyDoctorResourceMappingService,
    private translateService: TranslateService
  ) {
  }


  prepareDataForField(field: ResourceField, mainResourceData: ResourceResponseData, allResourcesData: ResourceResponseData[]): string | MyDoctorTableInterface {
    let rawData = field.useResource
      ? allResourcesData.find(r => r.resourceType === field.useResource)
      : mainResourceData;

    if (field.fieldName) {
      rawData = rawData?.content?.[field.fieldName];
    }

    if (rawData === undefined || rawData === null || (typeof rawData === 'string' && rawData === '')) {
      return null;
    }
    return field.prepareData(rawData, allResourcesData, mainResourceData);
  }

}
