import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
  getActiveCareproviderName,
  getActiveDataserviceId,
  loadLogsAction,
  navigateBackToCareprovidersAction,
  setDeleteDataModalOpenStatusAction,
  validateCareproviderLoadedAction
} from '@managers/my-doctor';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { MedsafeRouteParams } from '@medrecord/routes-medsafe';

@Component({
  selector: 'doctor-gui-careprovider-details-page-container',
  templateUrl: './careprovider-details-page-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CareproviderDetailsPageContainerComponent implements OnInit, OnDestroy {
  careproviderName$: Observable<string> = this.store.select(getActiveCareproviderName);
  dataserviceId$: Observable<string> = this.store.select(getActiveDataserviceId);

  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.store.dispatch(validateCareproviderLoadedAction({
          careproviderId: params.get(MedsafeRouteParams.careproviderId),
          dataserviceId: params.get(MedsafeRouteParams.serviceId)
        }));
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  backToCareproviders(): void {
    this.store.dispatch(navigateBackToCareprovidersAction());
  }

  loadLogs(): void {
    this.store.dispatch(loadLogsAction());
  }

  openDeleteDataModal(): void {
    this.store.dispatch(setDeleteDataModalOpenStatusAction({ isOpen: true, isDataservice: true }));
  }
}
