import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserMetadataEffects } from './effects/user-metadata.effects';
import { middlewareManagerFeatureKey, reducer } from './middleware-manager.reducers';
import { UserMetadataService } from '../services/user-metadata.service';

@NgModule({
  imports: [
    StoreModule.forFeature(middlewareManagerFeatureKey, reducer),
    EffectsModule.forFeature([
      UserMetadataEffects
    ])
  ],
  providers: [
    UserMetadataService
  ]
})
export class MiddlewareManagerStateModule {}