import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { resetSignUpData } from '@medrecord/managers-auth';

@Component({
  selector: 'medrecord-auth-signup-success',
  templateUrl: './signup-success.component.html'
})
export class SignupSuccessComponent implements OnInit{
  constructor(private store: Store) {}
  
  ngOnInit(): void {
    this.store.dispatch(resetSignUpData());
  }
}
