import { FormGroup } from '@angular/forms';
import { FormFactoryField, MapField } from '../models/interfaces';
import { Injectable } from '@angular/core';

@Injectable()
/**
 * Form Factory is useful for automatizing form fields rendering and splitting form declarations from component.
 * This will make form components more readable.
 * */
export class FormFactory<FormModel = any> {
  /**
   * Set of fields what cold be met in forms
   * */
  fields: FormFactoryField[];
  
  /**
   * Set of forms what could be rendered by types.
   * */
  forms: { [key: string]: (model?: FormModel) => FormGroup };
  
  /**
   * Returns configuration with selected form and fields set.
   * Fields are sorted in order they are declared in the form definition.
   * */
  getFormConfig(type: string, model?: FormModel): { form: FormGroup, fields: MapField[] } {
    const form = this.forms[type](model);
    const fields = Object.keys(form.value).reduce((fieldsList: MapField[], key) => {
      const fieldsByName = this.fields.filter((field) => field.name === key);
      
      if (fieldsByName.length) {
        fieldsList.push(...fieldsByName.map(({ calculate }) => {
          const { bindVisibility, ...field } = calculate(form);
          
          return ({
            ...field,
            form: form,
            bindVisibility: bindVisibility ? bindVisibility : (parentForm) => () => !!parentForm,
            controlName: key
          });
        }));
      }
      
      return fieldsList;
    }, []);
    
    return {
      form,
      fields
    };
  }
}