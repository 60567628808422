import { Component } from '@angular/core';
import {
  getResourceData,
  getTabularResourceData,
  Resource,
  ResourceDataAny,
  TabularResource
} from '@managers/my-doctor';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'doctor-gui-gp-data-tables-container',
  templateUrl: './gp-data-tables-container.component.html'
})
export class GpDataTablesContainerComponent {
  resourceData$: Observable<ResourceDataAny[]> = this.store.select(getResourceData);
  tabularResourceData$: Observable<{ [key in Resource]?: TabularResource<string> }> = this.store.select(getTabularResourceData);

  constructor(private store: Store) {
  }
}
