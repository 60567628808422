import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-add',
  templateUrl: './add.component.svg',
})
export class AddComponent extends MedrecordIcon {
  viewHeight = 24;
  viewWidth = 24;
}
