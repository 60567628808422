import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'medrecord-auth-twofa-steps',
  templateUrl: './twofa-steps.component.html',
  styleUrls: ['./twofa-steps.component.scss'],
})
export class TwofaStepsComponent {
  @Input() step: number;
  @Input() codes: string[];

  @Output() nextStep: EventEmitter<void> = new EventEmitter();

  constructor(private clipboard: Clipboard) {}

  primaryButtonClick(): void {
    this.nextStep.emit();
  }

  getPrimaryButtonLabel(): string {
    if (this.step === 1) {
      return 'common_begin';
    }
    if (this.step < 5) {
      return 'common_next';
    }
    return 'tfa_continue_medsafe';
  }

  copyClipboard(): void {
    const codesAsString = this.codes.join(', ');
    this.clipboard.copy(codesAsString);
  }
}
