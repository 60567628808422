import { NetworkOrganisation } from '../models/network-organisation.interface';
import { Action, createReducer, on } from '@ngrx/store';
import * as NetworkOrganisationActions from './network-organisations.actions';
import { NetworkOrganisationPractitioner } from '../models/network-organisation-practitioner.interface';
import { NetworkPatient } from '@coaching/doctor-network';

export const networkOrganisationsFeatureKey = 'network-organisations';

export interface NetworkOrganisationsState {
  organisations: NetworkOrganisation[];
  practitioners: NetworkOrganisationPractitioner[];
  error: any;
  selectedOrganisation: NetworkOrganisation;
  allPractitioners: NetworkPatient[];
  loading: boolean;
  actionLoading: boolean;
  currentUserOrganisations: any;
  allOrganisationsCount: number;
}

export const initialState: NetworkOrganisationsState = {
  organisations: [],
  error: undefined,
  selectedOrganisation: {},
  practitioners: [],
  allPractitioners: [],
  loading: false,
  actionLoading: false,
  currentUserOrganisations: {},
  allOrganisationsCount: 0,
};

const usersManagementReducer = createReducer<NetworkOrganisationsState>(
  initialState,
  on(NetworkOrganisationActions.loadNetworkOrganisationsAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(NetworkOrganisationActions.networkOrganisationsActionLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(NetworkOrganisationActions.loadNetworkOrganisationsSuccessAction, (state, { organisations }) => ({
    ...state,
    loading: false,
    organisations,
  })),
  on(NetworkOrganisationActions.networkOrganisationsFailureAction, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(NetworkOrganisationActions.addNetworkOrganisationSuccessAction, (state, { organisation }) => ({
    ...state,
    loading: false,
    organisations: [organisation, ...state.organisations],
  })),
  on(NetworkOrganisationActions.editNetworkOrganisationSuccessAction, (state, { organisation }) => ({
    ...state,
    loading: false,
    organisations: [...state.organisations.map((o) => (o.id === organisation.id ? organisation : o))],
  })),
  on(NetworkOrganisationActions.cleanSelectedOrganisationAction, (state) => ({
    ...state,
    selectedOrganisation: {},
  })),
  on(NetworkOrganisationActions.deleteNetworkOrganisationSuccessAction, (state, { id }) => ({
    ...state,
    organisations: [...state.organisations.filter((o) => o.id !== id)],
  })),
  on(NetworkOrganisationActions.getNetworkOrganisationSuccessAction, (state, { organisation }) => ({
    ...state,
    selectedOrganisation: organisation,
  })),
  on(NetworkOrganisationActions.getPractitionersSuccessAction, (state, { practitioners }) => ({
    ...state,
    practitioners,
    actionLoading: false,
  })),
  on(NetworkOrganisationActions.deletePractitionerSuccessAction, (state, { id }) => ({
    ...state,
    practitioners: [...state.practitioners.filter((p) => p.practitionerId !== id)],
    actionLoading: false,
  })),
  on(NetworkOrganisationActions.addParticipantToOrganisationSuccessAction, (state, { participant }) => ({
    ...state,
    actionLoading: false,
    practitioners: [
      {
        practitionerId: participant.practitionerId,
        practitionerName: participant.practitionerName,
        medicalRoles: participant.medicalRoles,
        role: participant.role,
      },
      ...state.practitioners,
    ],
  })),
  on(NetworkOrganisationActions.updatePractitionerRoleInOrganisationAction, (state) => ({
    ...state,
    actionLoading: true,
  })),
  on(NetworkOrganisationActions.updatePractitionerRoleInOrganisationSuccessAction, (state, { payload }) => ({
    ...state,
    actionLoading: false,
    practitioners: [
      ...state.practitioners.map((p) =>
        p.practitionerId === payload.practitionerId
          ? { ...p, medicalRoles: payload.medicalRoles, role: payload.role }
          : p
      ),
    ],
  })),
  on(NetworkOrganisationActions.bulkUpdatePractitionerRoleInOrganisationAction, (state) => ({
    ...state,
    actionLoading: true,
  })),
  on(NetworkOrganisationActions.bulkUpdatePractitionerRoleInOrganisationSuccessAction, (state, { payload }) => {
    const uniqueIds = [];
    return {
      ...state,
      actionLoading: false,
      practitioners: [...state.practitioners, ...payload].filter((item) => {
        if (!uniqueIds.includes(item.practitionerId)) {
          uniqueIds.push(item.practitionerId);
          return true;
        }
        return false;
      }),
    };
  }),
  on(NetworkOrganisationActions.getAllPractitionersSuccessAction, (state, { practitioners }) => ({
    ...state,
    allPractitioners: practitioners,
    actionLoading: false,
  })),
  on(NetworkOrganisationActions.clearPractitionersResultsAction, (state) => ({
    ...state,
    actionLoading: false,
    allPractitioners: [],
  })),
  on(NetworkOrganisationActions.getCurrentUserOrganisationsSuccess, (state, { organisations }) => ({
    ...state,
    currentUserOrganisations: organisations,
    loading: false,
  })),
  on(NetworkOrganisationActions.loadAllOrganisationsCountActionSuccess, (state, { count }) => ({
    ...state,
    allOrganisationsCount: count,
  }))
);

export function reducer(state: NetworkOrganisationsState, action: Action): NetworkOrganisationsState {
  return usersManagementReducer(state, action);
}
