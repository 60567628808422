import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Colors } from '@medrecord/services-colors';

@Component({
  selector: 'medrecord-label',
  templateUrl: './medrecord-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedrecordLabelComponent {
  @Input() text: string;
  @Input() secondaryText: string;
  @Input() for: string;
  @Input() type: Colors = 'boulder';
  @Input() hint: string;
}
