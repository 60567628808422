<div id="drop-area-{{ dropAreaId }}">
  <div *ngIf="isEmpty" class="mb-3">
    {{ noDataMessage }}
  </div>

  <div class="position-relative">
    <ejs-uploader
      #uploader
      [asyncSettings]="asyncSettings"
      [dropArea]="dropArea"
      [autoUpload]="autoUpload"
      [maxFileSize]="maxSizeInBytes"
      (selected)="onFileSelected($event)"
      (removing)="onFilesRemoving($event)"
      [multiple]="multiple"
      [allowedExtensions]="allowedFileTypes"
    ></ejs-uploader>
  </div>

  <button class="btn btn-primary pl-2" id="browse">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>

    <span *ngIf="!buttonContent">Browse</span>
  </button>
</div>
