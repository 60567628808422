import { MedrecordMoment } from '@medrecord/services-datetime';
import { ResourceType } from '../enums/resource-type.enum';
import { ResourceBlockFunction } from '../interfaces/resource-block-function.interface';

export const laboratoryTestResultBlock: ResourceBlockFunction = () => ({
  resourceType: ResourceType.observation,
  fieldGroups: [
    {
      name: ({ resource }) =>
        (resource?.content?.testDateTime
          ? MedrecordMoment(resource?.content?.testDateTime).format('LLL') + ' - '
          : '') + resource?.content?.testCode?.coding?.[0].display,
      fields: [
        {
          label: 'my_doctor_result',
          fieldName: 'testResult',
          prepareData: (data) => [data?.value, data?.unit].filter(Boolean).join(' '),
        },
        {
          label: 'my_doctor_status',
          fieldName: 'resultStatus',
          prepareData: (data) => data?.coding?.[0]?.display,
        },
        {
          label: 'my_doctor_upper_limit',
          fieldName: 'upperLimit',
          prepareData: (data) => [data?.value, data?.unit].filter(Boolean).join(' '),
        },
        {
          label: 'my_doctor_lower_limit',
          fieldName: 'lowerLimit',
          prepareData: (data) => [data?.value, data?.unit].filter(Boolean).join(' '),
        },
        {
          label: 'my_doctor_interpretation_flags',
          fieldName: 'interpretationFlags',
          prepareData: (data) =>
            data?.coding
              ?.map((c) => c?.display)
              ?.filter(Boolean)
              ?.join(', '),
        },
        {
          label: 'my_doctor_comment',
          fieldName: 'comment',
          prepareData: (data) => data,
        },
        {
          label: 'my_doctor_specimen',
          fieldName: 'specimen',
          prepareData: (data, resources) => {
            const specimen = resources.find((r) => {
              const [referenceType, referenceId] = (data?.reference ?? '').split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return data?.display || specimen?.content?.material?.coding?.[0]?.display;
          },
        },
        {
          label: 'my_doctor_order_date',
          fieldName: 'specimen',
          prepareData: (data, resources) => {
            const specimen = resources.find((r) => {
              const [referenceType, referenceId] = (data?.reference ?? '').split('/');
              return r.resourceType === referenceType && r.resourceId === referenceId;
            });

            return specimen?.content?.orderDate ? MedrecordMoment(specimen?.content?.orderDate).format('LLL') : '';
          },
        },
      ],
    },
  ],
});
