import { Action, createReducer, on } from '@ngrx/store';

import * as UserStatusActions from './user-status.actions';
import { UserStatusEntity } from '../..';

export const userStatusFeatureKey = 'user-status';

export interface UserStatusState {
  userStatuses: {
    [id: string]: UserStatusEntity
  };
  error: string;
}

export const initialState: UserStatusState = {
  userStatuses: {},
  error: null,
};

const userStatusReducer = createReducer(
  initialState,
  on(UserStatusActions.loadUserStatusAction, (state) => ({
    ...state,
    error: null,
  })),
  on(UserStatusActions.loadUserStatusSuccessAction, (state, { userStatus }) => ({
    ...state,
    userStatuses: { ...state.userStatuses, [userStatus.id]: userStatus },
  })),
  on(UserStatusActions.loadUserStatusFailureAction, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: UserStatusState | undefined, action: Action): UserStatusState {
  return userStatusReducer(state, action);
}
