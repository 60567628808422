<ng-container [formGroup]="parentForm">
  <ng-container [formArrayName]="fieldName">
    <h3 class="font-weight-bold">
      {{ 'profile_addresses' | translate }}
    </h3>
    <div *ngFor="let address of controls; let i = index" class="address px-5 py-3 mb-3">
      <div class="address-group-header d-flex align-items-center mb-3">
        <h4 class="font-weight-bold mt-3">
          {{ 'profile_address' | translate }} {{ i + 1 }}
          <a class="text-danger" (click)="removeForm(i)">
            <i class="fa fa-times-circle"></i>
          </a>
        </h4>
      </div>

      <div class="d-flex flex-wrap pl-3 pb-3" [formGroupName]="i">
        <ng-container *ngFor="let field of fields[i]">
          <medrecord-ui-map-field
            [parentForm]="field.form"
            [options]="field.options"
            [label]="field.label"
            [placeholder]="field.label"
            [secondaryLabel]="field.secondaryLabel"
            [fieldType]="field.type"
            [controlName]="field.controlName"
            [cssClass]="field.cssClass"
            [class.mb-3]="field.type === 'INPUT'"
            class="{{ field.fieldClass }}"
            [hint]="field.hint"
          ></medrecord-ui-map-field>
        </ng-container>
      </div>
    </div>
    <button class="btn btn-primary font-weight-bold" (click)="addForm()">
      <i class="fas fa-plus-circle text-xl mr-2"></i>
      {{ 'profile_one_more_address' | translate }}
    </button>
  </ng-container>
</ng-container>
