import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Careprovider, getActiveCareprovider, getActiveDataserviceId } from '@managers/my-doctor';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'doctor-gui-dataservices-tabs-container',
  templateUrl: './dataservices-tabs-container.component.html'
})
export class DataservicesTabsContainerComponent {
  careprovider$: Observable<Careprovider> = this.store.select(getActiveCareprovider);
  activeDataserviceId$: Observable<string> = this.store.select(getActiveDataserviceId);

  constructor(
    private store: Store,
    private router: Router
  ) {
  }

  tabSelected(dataserviceId: string): void {
    const route = this.router.url.split('/');

    route[route.length - 1] = dataserviceId;

    this.router.navigateByUrl(route.join('/'));
  }
}
