import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-pen',
  templateUrl: './pen.component.svg',
})
export class PenComponent extends MedrecordIcon {
  viewHeight = 10;
  viewWidth = 10;
}
