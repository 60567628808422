import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Careprovider, FetchCareproviderRequest, getCareproviderName } from '@managers/my-doctor';
import { GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { PAGING_DEFAULT_COUNT, PAGING_DEFAULT_START_PAGE } from '../../../constants/careproviders-list';
import { AddressUtil } from '../../../utils/address.util';

@Component({
  selector: 'doctor-gui-careproviders-page',
  templateUrl: './careproviders-page.component.html',
  styleUrls: ['./careproviders-page.component.scss']
})
export class CareprovidersPageComponent {
  @ViewChild('grid') grid: GridComponent;

  @Input() careproviders: Careprovider[];

  @Input() set careprovidersCount(count: number) {
    if (count) {
      this.pageSettings.pageCount = Math.ceil(count / this.pageSettings.pageSize);
      this.pageSettings.totalRecordsCount = count;
    }
  }

  @Output() careproviderSelected: EventEmitter<Careprovider> = new EventEmitter<Careprovider>();
  @Output() pagingUpdated: EventEmitter<FetchCareproviderRequest> = new EventEmitter<FetchCareproviderRequest>();
  @Output() searchTriggered: EventEmitter<FetchCareproviderRequest> = new EventEmitter<FetchCareproviderRequest>();
  @Output() careprovidersFavouriteToggled: EventEmitter<{
    careproviderRequestPayload: FetchCareproviderRequest,
    careproviderId: string,
    wasFavourite: boolean
  }> = new EventEmitter<{
    careproviderRequestPayload: FetchCareproviderRequest,
    careproviderId: string,
    wasFavourite: boolean
  }>();

  pageSettings: PageSettingsModel = {
    pageSize: PAGING_DEFAULT_COUNT,
    currentPage: PAGING_DEFAULT_START_PAGE + 1
  };
  searchValue = '';

  updatePaging({ settingsModel }): void {
    this.pageSettings = settingsModel;
    if (this.grid.pageSettings.pageSize !== settingsModel.pageSize) {
      this.grid.pageSettings.pageSize = settingsModel.pageSize;
    }

    this.pagingUpdated.emit(this.prepareCareproviderRequestPayload());
  }

  onSearch(): void {
    this.pageSettings.currentPage = 1;
    this.searchTriggered.emit(this.prepareCareproviderRequestPayload());
  }

  onSearchChange(value: string): void {
    this.searchValue = value;
  }

  onCareProviderSelect(careprovider: Careprovider & { column: any, index: any }): void {
    if (careprovider.column) {
      delete careprovider.column;
    }
    if (careprovider.index) {
      delete careprovider.index;
    }

    this.careproviderSelected.emit(careprovider);
  }

  getDisplayName(careprovider: Careprovider): string {
    return getCareproviderName(careprovider);
  }

  getContactInformation(careprovider: Careprovider): string {
    const email = careprovider?.email?.length ? careprovider?.email : careprovider?.id;
    const address = this.getAddress(careprovider);
    return [email, address].filter(item => item).join(', ');
  }

  /**
   * favourite == userScope
   */
  toggleFavourite(careprovider: Careprovider): void {
    this.careprovidersFavouriteToggled.emit({
      careproviderRequestPayload: this.prepareCareproviderRequestPayload(),
      careproviderId: careprovider.id,
      wasFavourite: careprovider.userScope
    });
  }

  private getAddress(careprovider: Careprovider): string {
    if (!careprovider.addresses?.length) {
      return '';
    }
    const addressObj = careprovider.addresses[0];
    const address = addressObj.address
      ?.split('\r\n')
      .map((v) => v.trim())
      .join(', ');
    if (address?.length) {
      return address + (addressObj.country ? ', ' + addressObj.country : '');
    }

    return AddressUtil.toOneLine(addressObj.streetName,
      addressObj.streetNumber,
      addressObj.postalCode,
      addressObj.city,
      addressObj.country);
  }

  private prepareCareproviderRequestPayload(): FetchCareproviderRequest {
    return {
      count: this.pageSettings.pageSize,
      startPage: this.pageSettings.currentPage - 1,
      search: this.searchValue
    };
  }
}
