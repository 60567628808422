import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { EnvService } from '@medrecord/core';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentModuleGuard implements CanActivate {
  constructor(
    private router: Router,
    private envService: EnvService
  ) {}
  
  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>{
    return this.envService.showPayments
      ? true
      : this.router.parseUrl(`/`);
  }
}