import { Component, Input } from '@angular/core';
import { GenderEnum } from '@medrecord/managers-users';

@Component({
  selector: 'medsafe-gender-view',
  templateUrl: './gender-view.component.html',
})
export class GenderViewComponent {
  @Input() gender: GenderEnum;

  readonly genders = GenderEnum;
}
