import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-arrow-right',
  templateUrl: './arrow-right.component.svg',
  styleUrls: ['./arrow-right.component.scss'],
})
export class ArrowRightComponent extends MedrecordIcon {
  viewHeight = 15;
  viewWidth = 16;
}
