import { Inject, Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  fetchResourcesAction,
  setAfterFetchingResourcesReturnToAction,
  setFetchResourcesRequestPayloadAction,
  setParametersModalOpenStatusAction,
  setResourceParametersAction,
  setTestCareproviderModalOpenStatusAction,
  submitParametersModalAction,
  submitResourcesModalAction,
  submitTestCareproviderModalAction
} from '../actions';
import { getActiveCareproviderId, getActiveTransactionId, getFetchResourcesRequestPayload } from '../selectors';
import { ResourceParameter, ResourceParameterRequest, TransactionParameter } from '../../models/interfaces';
import { MyDoctorManagerService } from '../../services/my-doctor-manager.service';
import { MEDSAFE_ROUTE_NAMES, MedsafeRouteNames } from '@medrecord/routes-medsafe';
import { MY_DOCTOR_MANAGER_CONSTANTS } from '../../my-doctor-manager.tokens';
import { MyDoctorManagerConstants } from '../../constants';

@Injectable()
export class ModalChainEffects {

  @Effect()
  submitResourcesModal$ = this.actions$.pipe(
    ofType(submitResourcesModalAction),
    withLatestFrom(
      this.store.select(getActiveCareproviderId),
      this.store.select(getActiveTransactionId)
    ),
    mergeMap(([mapping, careproviderId, transactionId]) => {
      const resources = this.service.toFlatResources(mapping.resources, transactionId);
      const resourceParameterRequest: ResourceParameterRequest[] = resources.map(key => ({ key }));

      const actions: Action[] = [
        setAfterFetchingResourcesReturnToAction({ detailsRoute: this.medsafeRouteNames.MyDoctor.Fetch }),
        setFetchResourcesRequestPayloadAction({ fetchResourceRequest: { resources: resourceParameterRequest } })
      ];

      const transactionParameters = this.myDoctorConstants.pullTransactionMappings.get(transactionId).parameters;

      const resourceParameters: ResourceParameter[] = !transactionParameters ? [] : resources
        .map(resourceKey => {
          // map every resource key to an object
          // this object contains resource key and all parameters related to it
          const parameters: TransactionParameter[] = transactionParameters
            .filter(param => param.forResourceKeys.includes(resourceKey));
          return {
            key: resourceKey,
            parameters // all parameters which are for this resource key
          };
        })
        // some resource keys might not have parameters related to them
        .filter(({ parameters }) => parameters.length);

      if (resourceParameters.length) {
        actions.push(
          setResourceParametersAction({ resourceParameters }),
          setParametersModalOpenStatusAction({ isOpen: true })
        );
      } else if (this.service.isTest(careproviderId)) {
        actions.push(
          setTestCareproviderModalOpenStatusAction({ isOpen: true })
        );
      } else {
        actions.push(
          fetchResourcesAction()
        );
      }
      return actions;
    })
  );

  @Effect()
  submitParametersModal$ = this.actions$.pipe(
    ofType(submitParametersModalAction),
    withLatestFrom(
      this.store.select(getActiveCareproviderId),
      this.store.select(getFetchResourcesRequestPayload)
    ),
    mergeMap(([{ resourceParameters }, careproviderId, fetchResourceRequest]) => {
      const resourceParameterRequest: ResourceParameterRequest[] = fetchResourceRequest.resources.map(resource => ({
        key: resource.key,
        ...(
          resourceParameters[resource.key]
            ? { parameters: { ...resourceParameters[resource.key] } }
            : {}
        )
      }));

      const actions: Action[] = [
        setFetchResourcesRequestPayloadAction({ fetchResourceRequest: { resources: resourceParameterRequest } })
      ];

      if (this.service.isTest(careproviderId)) {
        actions.push(
          setTestCareproviderModalOpenStatusAction({ isOpen: true })
        );
      } else {
        actions.push(
          fetchResourcesAction()
        );
      }
      return actions;
    })
  );

  @Effect()
  submitTestCareproviderModal$ = this.actions$.pipe(
    ofType(submitTestCareproviderModalAction),
    withLatestFrom(this.store.select(getFetchResourcesRequestPayload)),
    mergeMap(([{ testCareproviderModalData }, fetchResourceRequest]) =>
      [
        setFetchResourcesRequestPayloadAction({
          fetchResourceRequest: {
            resources: [...fetchResourceRequest.resources],
            ...testCareproviderModalData
          }
        }),
        fetchResourcesAction()
      ]
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private service: MyDoctorManagerService,
    @Inject(MY_DOCTOR_MANAGER_CONSTANTS) private myDoctorConstants: MyDoctorManagerConstants,
    @Inject(MEDSAFE_ROUTE_NAMES) private medsafeRouteNames: MedsafeRouteNames
  ) {
  }
}
