import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-pulse-rate',
  templateUrl: './pulse-rate.component.svg',
  styleUrls: [],
})
export class PulseRateComponent extends MedrecordIcon {
  viewHeight: number = 24;
  viewWidth: number = 24;
}
