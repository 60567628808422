import { Component } from '@angular/core';
import { MedrecordIcon } from '../../../base/medrecord-icon';

@Component({
  selector: 'medrecord-waist-height-ratio',
  templateUrl: './waist-height-ratio.component.svg',
  styleUrls: [],
})
export class WaistHeightRatioComponent extends MedrecordIcon {
  viewHeight: number = 24;
  viewWidth: number = 24;
}
