import { Component, Input, OnInit } from '@angular/core';
import { loadRecordAction, MyDoctorResourceMappingService, ResourceBlock, ResourceData } from '@managers/my-doctor';
import { Store } from '@ngrx/store';

@Component({
  selector: 'doctor-gui-resource-container',
  templateUrl: './resource-container.component.html'
})
export class ResourceContainerComponent implements OnInit {
  @Input() resource: ResourceData;
  @Input() isGroupedResource = false;
  @Input() recordId: string;

  block: ResourceBlock;

  constructor(
    private mappingService: MyDoctorResourceMappingService,
    private store: Store
  ) {
  }

  ngOnInit(): void {
    this.block = this.mappingService.getResourceBlock(this.resource.key);
  }

  getDetailsData(id: string): void {
    this.store.dispatch(loadRecordAction({ recordId: id }));
  }
}
