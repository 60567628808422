import { Component, Input } from '@angular/core';

@Component({
  selector: 'medrecord-ui-labeled-column',
  templateUrl: './labeled-column.component.html',
})
export class LabeledColumnComponent {
  @Input() value: string;
  @Input() tooltipText: string;
  @Input() labelType = '';
}
