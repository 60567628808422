import { Component } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';

@Component({
  selector: 'medrecord-svg-eye-crossed',
  templateUrl: './eye-crossed.component.svg',
  styleUrls: ['./eye-crossed.component.scss'],
})
export class EyeCrossedComponent extends MedrecordIcon {
  viewHeight = 19;
  viewWidth = 24;
}
