import { Component, Input } from '@angular/core';
import { CardItem } from '../../../models/interfaces/card-item.interface';

@Component({
  selector: 'doctor-gui-card-info',
  templateUrl: './card-info.component.html',
  styleUrls: ['./card-info.component.scss']
})
export class CardInfoComponent{
  @Input() title: string;
  @Input() items: CardItem[] = [];
}
