<ejs-dropdownlist
  [placeholder]="placeholder"
  [dataSource]="dataSource"
  [valueTemplate]="valueTemplate"
  [itemTemplate]="itemTemplate"
  [value]="value"
  [readonly]="disabled"
  (select)="onSelect($event)"
>
  <ng-template #valueTemplate let-data>
    <div class="w-100 h-100 d-flex align-items-center">
      {{ data?.text | translate }}
    </div>
  </ng-template>
  <ng-template #itemTemplate let-data>
    {{ data?.text | translate }}
  </ng-template>
</ejs-dropdownlist>