import { Component, Input } from '@angular/core';

@Component({
  selector: 'medrecord-ui-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input() isWhite = false;
  @Input() link: string;
  @Input() middle: boolean;
  @Input() large: boolean;
  @Input() small: boolean;
  @Input() xSmall: boolean;
  @Input() xxSmall: boolean;

  defaultLink = 'assets/coaching/default-avatar.png';

  onError(): void {
    this.link = '';
  }
}
