import { Action, createReducer, on } from '@ngrx/store';
import {
  loadUserThresholdsFailure,
  loadUserThresholdsSuccess,
  loadUserObservationsFailure,
  loadUserObservationsSuccess,
  loadAllowedObservationTypesSuccess,
  loadAllowedObservationTypesFailure,
  setFocusedObservationTypeAction,
  removeFocusedObservationTypeAction,
  loadUserNetworkForMeasurementsSuccessAction,
  removeUserObservations,
  createObservationSuccessAction,
  updateObservationSuccessAction,
} from './measurements-manager.actions';
import { Observation, ObservationThresholdItem } from '../models/interfaces';
import { ObservationType } from '@medrecord/core';
import { User } from '@medrecord/managers-users';

export const measurementsManagerFeatureKey = 'measurements-manager';

export interface MeasurementsManagerState {
  allowedObservationTypes: ObservationType[];
  observations: Observation[];
  focusedObservationType: ObservationType;
  thresholds: ObservationThresholdItem[];
  healthScore: string;
  userNetwork: User[];
  error: any;
  careplan: {
    observations?: {
      [key: string]: Observation[];
    };
  };
}

export const initialState: MeasurementsManagerState = {
  allowedObservationTypes: [],
  focusedObservationType: null,
  healthScore: '',
  observations: [],
  thresholds: [],
  userNetwork: [],
  error: null,
  careplan: {
    observations: {},
  },
};

export const measurementsManagerReducer = createReducer(
  initialState,

  on(setFocusedObservationTypeAction, (state, { observationType }) => ({
    ...state,
    focusedObservationType: observationType,
  })),

  on(removeFocusedObservationTypeAction, (state) => ({ ...state, focusedObservationType: null })),

  on(loadUserObservationsSuccess, (state, { observations, careplanId, observationType }) => ({
    ...state,
    observations,
    careplan: careplanId
      ? {
          ...state.careplan,
          observations: {
            ...state.careplan.observations,
            [observationType]: observations,
          },
        }
      : {},
  })),

  on(createObservationSuccessAction, (state, { observation, observationType }) => ({
    ...state,
    careplan: {
      ...(state.careplan || {}),
      [observationType]: [...(state.careplan[observationType] || []), observation],
    },
  })),

  on(updateObservationSuccessAction, (state, { observation, observationType }) => ({
    ...state,
    careplan: {
      ...(state.careplan || {}),
      [observationType]: (state.careplan[observationType] || []).map((o) =>
        o.id === observation.id ? observation : o
      ),
    },
  })),

  on(loadAllowedObservationTypesSuccess, (state, { allowedObservationTypes }) => ({
    ...state,
    allowedObservationTypes,
  })),

  on(loadUserThresholdsSuccess, (state, { thresholds, healthScore }) => ({ ...state, thresholds, healthScore })),

  on(loadUserNetworkForMeasurementsSuccessAction, (state, { userNetwork }) => ({ ...state, userNetwork })),

  on(removeUserObservations, (state) => ({ ...state, observations: [] })),

  on(
    loadUserThresholdsFailure,
    loadUserObservationsFailure,
    loadAllowedObservationTypesFailure,

    (state, { error }) => ({ ...state, error })
  )
);

export const reducer = (state: MeasurementsManagerState, action: Action): MeasurementsManagerState => {
  return measurementsManagerReducer(state, action);
};
