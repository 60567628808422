<h2
  class="text-weight-600 text-primary text-center"
  [innerHTML]="'two_factor_authentication.title' | translate | safeHtml"
></h2>

<p class="text-lg my-4 text-justify">
  {{ 'two_factor_authentication.info' | translate }}
</p>

<p class="text-lg my-4 text-justify">
  {{ 'two_factor_authentication.instruction' | translate }}
</p>

<form [formGroup]="form">
  <medrecord-ui-map-field
    [attr.id]="isOAuth && 'phone-field'"
    controlName="phone"
    [parentForm]="form"
    [fieldType]="mapFieldType.Phone"
  ></medrecord-ui-map-field>
</form>

<button
  (click)="sendCode()"
  [disabled]="!form.valid"
  [class.btn-rounded]="isOAuth"
  class="btn btn-primary btn-block text-weight-600 mt-4"
>
  {{ 'two_factor_authentication.send_code' | translate | uppercase }}
</button>
