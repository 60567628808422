import { createAction, props } from '@ngrx/store';
import { CalendarAppointment } from '../models/interfaces/calendar-appointment.interface';
import { QualificationListItem } from '../models/interfaces/qualification-list-item.interface';
import { RequestAppointment } from '../models/interfaces/request-appointment.interface';

export const loadCarePlanAppointmentsAction = createAction(
  '[Calendar Manager Actions] Load CarePlan Appointments',
  props<{ carePlanId?: string, startDate?: Date, endDate?: Date }>()
);

export const loadCarePlanAppointmentsSuccessAction = createAction(
  '[Calendar Manager Actions] Load CarePlan Appointments Success',
  props<{ appointments: CalendarAppointment[] }>()
);

export const loadQualificationsAction = createAction(
  '[Calendar Manager Actions] Load Qualifications'
);

export const loadQualificationsSuccessAction = createAction(
  '[Calendar Manager Actions] Load Qualifications Success',
  props<{ qualifications: QualificationListItem[] }>()
);

export const requestAppointmentAction = createAction(
  '[Calendar Manager Actions] Request Appointment',
  props<{ requestData: RequestAppointment }>()
);

export const requestAppointmentSuccessAction = createAction(
  '[Calendar Manager Actions] Request Appointment Success'
);

export const failureAction = createAction(
  '[Calendar Manager Actions] Failure action',
  props<{ error: any }>()
);