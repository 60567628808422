import { Component, Input } from '@angular/core';
import { ResourceDataGroup } from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-resource-group',
  templateUrl: './resource-group.component.html',
  styleUrls: ['./resource-group.component.scss']
})
export class ResourceGroupComponent {
  @Input() resourceGroup: ResourceDataGroup;

  isExpanded = false;
}
