<div class="container-fluid vw-100 vh-100">
  <div class="row h-100">
    <div class="d-none col-lg-6 d-lg-flex justify-content-center mt-5 align-items-baseline">
      <img class="background" [attr.src]="bgLink" alt="AUTH_UI_IMAGE_WAS_NOT_LOADED" />
    </div>

    <div class="auth-content container-fluid col-12 col-lg-6">
      <nav class="navbar navbar-lg navbar-light bg-white row d-flex justify-content-end fixed-top">
        <div class="mr-sm-0 pr-2">
          <medrecord-ui-select-language
            [attr.id]="isOAuth && 'oauth-lang'"
            [enableGermanLanguage]="enableGermanLanguage"
          ></medrecord-ui-select-language>
        </div>
      </nav>

      <div class="d-flex align-items-center justify-content-center mt-5 pt-5">
        <div class="container auth-wrapper">
          <div class="text-center logo-container justify-content-center d-lg-block mb-5">
            <img [src]="logo$ | async | safeUrl" alt="logo" height="80" />
          </div>

          <router-outlet></router-outlet>
        </div>
      </div>

      <div
        *ngIf="showAuthReferenceText"
        class="container mt-5 d-flex flex-column flex-md-row justify-content-center align-items-center auth-reference-text"
      >
        <img [src]="referenceLogo$ | async | safeUrl" alt="AUTH_UI_REFERENCE_IMAGE_WAS_NOT_LOADED" />
        <span class="ml-md-2 mt-2 mt-md-0" [innerHTML]="'auth_reference_text' | translate | safeHtml"></span>
      </div>
    </div>
  </div>
</div>
