import { createFeatureSelector, createSelector } from '@ngrx/store';
import { networkOrganisationsFeatureKey, NetworkOrganisationsState } from './network-organisations.reducer';

export const getOrganisationsState = createFeatureSelector<NetworkOrganisationsState>(networkOrganisationsFeatureKey);

export const getNetworkOrganisations = createSelector(
  getOrganisationsState, (state: NetworkOrganisationsState) => state?.organisations || []
);

export const getIsLoading = createSelector(
  getOrganisationsState, (state: NetworkOrganisationsState) => state?.loading
);

export const getIsActionLoading = createSelector(
  getOrganisationsState, (state: NetworkOrganisationsState) => state?.actionLoading
);

export const getSelectedOrganisation = createSelector(
  getOrganisationsState, (state: NetworkOrganisationsState) => state.selectedOrganisation
);

export const getPractitioners = createSelector(
  getOrganisationsState, (state: NetworkOrganisationsState) => state.practitioners
);

export const getAllPractitioners = createSelector(
  getOrganisationsState, (state: NetworkOrganisationsState) => state.allPractitioners
);

export const getOrganisationPractitionersOptions = createSelector(
  getOrganisationsState, (state: NetworkOrganisationsState) => {
   return state.allPractitioners.map(item => {
    const existing = state?.practitioners?.find(({ practitionerId }) => practitionerId === item.userId)
    return !existing ? item : null
   }).filter(Boolean)
  }
);

export const getAllOrganisationPractitioners = createSelector(
  getOrganisationsState, (state: NetworkOrganisationsState) => {
    return state.allPractitioners.map(practitioner => ({
      practitionerId: practitioner.id,
      practitionerName: practitioner.name
    }))
  }
);

export const currentUserOrganisations = createSelector(
  getOrganisationsState, (state: NetworkOrganisationsState) => {
    return state?.organisations?.map(organisation => {
      const userOrganisation = state?.currentUserOrganisations?.find(({ organizationId }) => organizationId === organisation.id)
      return userOrganisation && {
        ...organisation,
        ...userOrganisation
      };
    }).filter(Boolean);
  }
);

export const getAllOrganisationsCount = createSelector(
  getOrganisationsState, (state: NetworkOrganisationsState): number => state.allOrganisationsCount
);