import { Component, OnInit } from '@angular/core';
import { setTwofaFlowAction, TwofaFlow } from '@medrecord/managers-auth';
import { Store } from '@ngrx/store';

@Component({
  selector: 'medrecord-auth-twofa-recovery-container',
  templateUrl: './twofa-recovery-container.component.html'
})
export class TwofaRecoveryContainerComponent implements OnInit {

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(setTwofaFlowAction({ flow: TwofaFlow.Recovery }));
  }

}
