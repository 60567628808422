import { Action, createReducer, on } from '@ngrx/store';
import * as TranslationsActions from './translations-management.actions';
import { TranslationsByLanguage, TranslationsCategory } from '../models';
import { Language } from '@medrecord/core';

export const translationsFeatureKey = 'translations-management';

export interface TranslationsState {
  selectedCategory: TranslationsCategory;
  language: Language;
  categories: TranslationsCategory[];
  error: string;
  translations: TranslationsByLanguage;
}

export const initialState: TranslationsState = {
  selectedCategory: null,
  language: Language.EN,
  categories: [],
  translations: {
    [Language.EN]: {
      [TranslationsCategory.PublicWebsite]: {
        pages: 0,
        total: 0,
      }
    }
  } as TranslationsByLanguage,
  error: null,
};

const translationsManagementReducer = createReducer<TranslationsState>(
  initialState,
  on(
    TranslationsActions.translationsSuccessLoadingAction,
    (state: TranslationsState,
     {
       category,
       translations,
       pages,
       total
     }) => ({
      ...state,
      translations: {
        ...state.translations,
        [state.language]: {
          ...state.translations[state.language],
          
          [category]: { pages, total, translations }
        }
      },
    })
  ),
  
  on(
    TranslationsActions.setTranslationByCategoryAndLang,
    (state: TranslationsState, { language, category, ...payload }) => {
      return ({
        ...state,
        translations: {
          ...state.translations,
          [language]: {
            [category]: payload
          },
        },
      });
    }
  ),
  
  on(
    TranslationsActions.categoriesSuccessLoadingAction,
    (state: TranslationsState, { categories }) => ({
      ...state,
      categories,
      selectedCategory: categories[0]
    })
  ),
  
  on(
    TranslationsActions.setLanguageAction,
    (state: TranslationsState, { language }) => ({
      ...state,
      language
    })
  ),
  
  on(
    TranslationsActions.translationsFailureLoadingAction,
    TranslationsActions.categoriesFailureLoadingAction,
    TranslationsActions.allTranslationsFailureLoadingByCategoryAction,
    (state: TranslationsState, { error }) => ({ ...state, error })
  )
);

export function reducer(state: TranslationsState | undefined, action: Action): TranslationsState {
  return translationsManagementReducer(state, action);
}
