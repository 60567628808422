import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FoodFriendManagerEffects } from './food-friend-manager.effects';
import { foodFriendManagerFeatureKey, reducer } from './food-friend-manager.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(foodFriendManagerFeatureKey, reducer),
    EffectsModule.forFeature([FoodFriendManagerEffects]),
  ],
})
export class FoodFriendManagerStateModule {}
