import { Permission, User } from '@medrecord/managers-users';
import { createAction, props } from '@ngrx/store';

export const loadUserNetworkAction = createAction('[Network Manager Actions] Load User Network');

export const loadUserNetworkSuccessAction = createAction(
  '[Network Manager Actions] Load User Network Success',
  props<{ users: User[] }>()
);

export const loadUserNetworkFailureAction = createAction(
  '[Network Manager Actions] Load User Network Failure',
  props<{ error: any }>()
);

export const searchUsersAction = createAction(
  '[Network Manager Actions] Search Users',
  props<{ searchQuery: string }>()
);

export const searchUsersSuccessAction = createAction(
  '[Network Manager Actions] Search Users Success',
  props<{ users: User[] }>()
);

export const searchUsersFailureAction = createAction(
  '[Network Manager Actions] Search Users Failure',
  props<{ error: any }>()
);

export const addUserToNetworkAction = createAction(
  '[Network Manager Actions] Add User To Network',
  props<{ networkUserId: string; permission: Permission; searchQuery: string }>()
);

export const addUserToNetworkSuccessAction = createAction('[Network Manager Actions] Add User To Network Success');

export const addUserToNetworkFailureAction = createAction(
  '[Network Manager Actions] Add User To Network Failure',
  props<{ error: any }>()
);

export const updateUserInNetworkAction = createAction(
  '[Network Manager Actions] Update User In Network',
  props<{ networkUserId: string; permission: Permission }>()
);

export const updateUserInNetworkSuccessAction = createAction(
  '[Network Manager Actions] Update User In Network Success'
);

export const updateUserInNetworkFailureAction = createAction(
  '[Network Manager Actions] Update User In Network Failure',
  props<{ error: any }>()
);

export const deleteUserFromNetworkAction = createAction(
  '[Network Manager Actions] Delete User From Network',
  props<{ networkUserId: string }>()
);

export const deleteUserFromNetworkSuccessAction = createAction(
  '[Network Manager Actions] Delete User From Network Success'
);

export const deleteUserFromNetworkFailureAction = createAction(
  '[Network Manager Actions] Delete User From Network Failure',
  props<{ error: any }>()
);
