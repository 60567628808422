import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from '@medrecord/core';
import { Healthplan } from '../models/interfaces/healthplan.interface';
import { HealthplansLoadPayload } from '../models/interfaces/healthplans-load-payload.interface';
import { HealthplanStatus } from '../models/enums/healthplan-status.enum';
import { GoalStatus } from '../models/enums/goal-status.enum';
import { Goal } from '../models/interfaces/goal.interface';
import { Task } from '../models/interfaces/task.interface';
import { TasksLoadPayload } from '../models/interfaces/tasks-load-payload.interface';
import { HealthPlanParticipantModel } from '../models/interfaces/healthplan-participant.interface';
import { IComment } from '../models/interfaces/comment.interface';

@Injectable()
export class HealthplansManagerService {
  constructor(private http: HttpClient, private envService: EnvService) {}

  loadHealthplans(userId: string, payload: HealthplansLoadPayload): Observable<Healthplan[]> {
    let params = new HttpParams();

    Object.keys(payload).forEach((key) => {
      params = params.set(key, payload[key]);
    });

    return this.http.get<Healthplan[]>(this.envService.backend + '/patient/' + userId + '/careplan', { params });
  }

  loadHealthPlanParticipants(userId: string, healthPlanId: string): Observable<HealthPlanParticipantModel[]> {
    return this.http.get<HealthPlanParticipantModel[]>(
      this.envService.backend + '/patient/' + userId + '/careplan/' + healthPlanId + '/participant'
    );
  }

  loadHealthplansCount(userId: string, status: HealthplanStatus): Observable<any> {
    let params = new HttpParams();

    params = params.set('status', status);
    return this.http.get<any>(this.envService.backend + '/patient/' + userId + '/careplan/count', { params });
  }

  loadHealthplan(id: string, userId: string): Observable<Healthplan> {
    return this.http.get<Healthplan>(this.envService.backend + '/patient/' + userId + '/careplan/' + id);
  }

  createHealthplan(userId: string, healthplan: Healthplan): Observable<any> {
    return this.http.post(this.envService.backend + '/patient/' + userId + '/careplan', healthplan);
  }

  updateHealthplan(userId: string, healthplanId: string, healthplanForm: Healthplan): Observable<any> {
    return this.http.put(this.envService.backend + '/patient/' + userId + '/careplan/' + healthplanId, healthplanForm);
  }

  deleteHealthplan(id: string, userId: string): Observable<any> {
    return this.http.delete(this.envService.backend + '/patient/' + userId + '/careplan/' + id);
  }

  addUserToHealthplan(userId: string, healthPlanId: string, payload: { fhirId?: string; userId?: string }) {
    return this.http.post(
      this.envService.backend + '/patient/' + userId + '/careplan/' + healthPlanId + '/participant',
      payload
    );
  }

  deleteNonRegisteredUserFromHealthplan(userId: string, healthPlanId: string, deletedUserId: string) {
    return this.http.delete(
      `${this.envService.backend}/patient/${userId}/careplan/${healthPlanId}/participant/fhir/${deletedUserId}`
    );
  }

  deleteNetworkUserFromHealthplan(userId: string, healthPlanId: string, deletedUserId: string) {
    return this.http.delete(
      `${this.envService.backend}/patient/${userId}/careplan/${healthPlanId}/participant/user/${deletedUserId}`
    );
  }

  loadGoals(id: string, userId: string, status: GoalStatus): Observable<Goal[]> {
    let params = new HttpParams();

    params = params.set('status', status);

    return this.http.get<Goal[]>(this.envService.backend + '/patient/' + userId + '/careplan/' + id + '/goal', {
      params,
    });
  }

  loadGoal(id: string, userId: string, healthplanId: string): Observable<Goal> {
    return this.http.get<Goal>(
      this.envService.backend + '/patient/' + userId + '/careplan/' + healthplanId + '/goal/' + id
    );
  }

  createGoal(userId: string, healthplanId: string, goal: Goal): Observable<any> {
    return this.http.post(this.envService.backend + '/patient/' + userId + '/careplan/' + healthplanId + '/goal', goal);
  }

  updateGoal(userId: string, healthplanId: string, goalId: string, goalForm: Goal): Observable<any> {
    return this.http.put(
      this.envService.backend + '/patient/' + userId + '/careplan/' + healthplanId + '/goal/' + goalId,
      goalForm
    );
  }

  deleteGoal(id: string, healthplanId: string, userId: string): Observable<any> {
    return this.http.delete(
      this.envService.backend + '/patient/' + userId + '/careplan/' + healthplanId + '/goal/' + id
    );
  }

  loadTasks(id: string, healthplanId: string, userId: string, payload: TasksLoadPayload): Observable<Task[]> {
    let params = new HttpParams();

    Object.keys(payload).forEach((key) => {
      params = params.set(key, payload[key]);
    });

    return this.http.get<Task[]>(
      this.envService.backend + '/patient/' + userId + '/careplan/' + healthplanId + '/goal/' + id + '/task',
      {
        params,
      }
    );
  }

  createTask(userId: string, healthplanId: string, goalId: string, task: Task): Observable<any> {
    return this.http.post(
      this.envService.backend + '/patient/' + userId + '/careplan/' + healthplanId + '/goal/' + goalId + '/task',
      task
    );
  }

  updateTask(userId: string, healthplanId: string, goalId: string, taskId: string, taskForm: Task): Observable<any> {
    return this.http.put(
      this.envService.backend +
        '/patient/' +
        userId +
        '/careplan/' +
        healthplanId +
        '/goal/' +
        goalId +
        '/task/' +
        taskId,
      taskForm
    );
  }

  createGoalComment(userId: string, healthplanId: string, goalId: string, text: string): Observable<IComment> {
    return this.http.post<IComment>(
      `${this.envService.backend}/patient/${userId}/careplan/${healthplanId}/goal/${goalId}/note`,
      {
        text,
      }
    );
  }

  updateGoalComment(
    userId: string,
    healthplanId: string,
    goalId: string,
    commentId: string,
    text: string
  ): Observable<IComment> {
    return this.http.put<IComment>(
      `${this.envService.backend}/patient/${userId}/careplan/${healthplanId}/goal/${goalId}/note/${commentId}`,
      {
        text,
      }
    );
  }

  deleteGoalComment(userId: string, healthplanId: string, goalId: string, commentId: string): Observable<any> {
    return this.http.delete(
      `${this.envService.backend}/patient/${userId}/careplan/${healthplanId}/goal/${goalId}/note/${commentId}`
    );
  }

  getTask(userId: string, healthplanId: string, goalId: string, taskId: string): Observable<Task> {
    return this.http.get<Task>(
      `${this.envService.backend}/patient/${userId}/careplan/${healthplanId}/goal/${goalId}/task/${taskId}`
    );
  }

  createTaskComment(
    userId: string,
    healthplanId: string,
    goalId: string,
    taskId: string,
    text: string
  ): Observable<IComment> {
    return this.http.post<IComment>(
      `${this.envService.backend}/patient/${userId}/careplan/${healthplanId}/goal/${goalId}/task/${taskId}/note`,
      {
        text,
      }
    );
  }

  updateTaskComment(
    userId: string,
    healthplanId: string,
    goalId: string,
    taskId: string,
    commentId: string,
    text: string
  ): Observable<IComment> {
    return this.http.put<IComment>(
      `${this.envService.backend}/patient/${userId}/careplan/${healthplanId}/goal/${goalId}/task/${taskId}/note/${commentId}`,
      {
        text,
      }
    );
  }

  deleteTaskComment(
    userId: string,
    healthplanId: string,
    goalId: string,
    taskId: string,
    commentId: string
  ): Observable<any> {
    return this.http.delete(
      `${this.envService.backend}/patient/${userId}/careplan/${healthplanId}/goal/${goalId}/task/${taskId}/note/${commentId}`
    );
  }
}
