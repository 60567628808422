import { Component, Input } from '@angular/core';
import { ClinicalStatus, Criticality, Severity, VerificationStatus } from '@medrecord/core';
import { UsageStatus } from '@managers/medications-manager';
import { LabResultStatus } from '@managers/my-doctor';

@Component({
  selector: 'doctor-gui-badge-status',
  templateUrl: './badge-status.component.html'
})
export class BadgeStatusComponent {
  @Input() status: VerificationStatus | Severity | Criticality | LabResultStatus | UsageStatus | ClinicalStatus;
  @Input() translationPrefix: string;

  get class(): string {
    switch (this.status) {
      case VerificationStatus.Confirmed:
      case Severity.Mild:
        return 'badge-success';
      case Severity.Moderate:
        return 'badge-warning';
      case VerificationStatus.Unconfirmed:
      case Severity.Severe:
      case Criticality.High:
        return 'badge-danger';
      case LabResultStatus.final:
      case LabResultStatus.corrected:
      case UsageStatus.Active:
        return 'badge-primary';
      case LabResultStatus.pending:
      case LabResultStatus.preliminary:
      case LabResultStatus.appended:
      case ClinicalStatus.Inactive:
        return 'badge-secondary';
      default:
        return 'badge-default';
    }
  }
}
