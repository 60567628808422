import { healthplansManagerFeatureKey, HealthplansManagerState } from './healthplans-manager.reducer';
import { createSelector } from '@ngrx/store';
import { Healthplan } from '../models/interfaces/healthplan.interface';
import { Goal } from '../models/interfaces/goal.interface';
import { Task } from '../models/interfaces/task.interface';
import { User } from '@medrecord/managers-users';

export const getHealthplansManagerState: (state: HealthplansManagerState) => any = (state: HealthplansManagerState) =>
  state[healthplansManagerFeatureKey];

export const getHealthplans = createSelector(
  getHealthplansManagerState,
  (state: HealthplansManagerState): Healthplan[] => state.healthplans
);

export const getHealthplansCount = createSelector(
  getHealthplansManagerState,
  (state: HealthplansManagerState): number => state.count
);

export const getActiveHealthplan = createSelector(
  getHealthplansManagerState,
  (state: HealthplansManagerState): Healthplan => state.activeHealthplan
);

export const getGoals = createSelector(
  getHealthplansManagerState,
  (state: HealthplansManagerState): Goal[] => state.goals
);

export const getActiveGoal = createSelector(
  getHealthplansManagerState,
  (state: HealthplansManagerState): Goal => state.activeGoal
);

export const getTasks = (goalId: string) =>
  createSelector(getHealthplansManagerState, (state: HealthplansManagerState): Task[] => state.tasks[goalId] || []);

export const getHealthplanUserNetwork = createSelector(
  getHealthplansManagerState,
  (state): User[] => state.userNetwork
);

export const getHealthplanRelatedPeople = createSelector(
  getHealthplansManagerState,
  (state): User[] => state.relatedPeople
);

export const getActiveTask = createSelector(
  getHealthplansManagerState,
  (state: HealthplansManagerState): Task => state.activeTask
);
