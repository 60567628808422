import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Allergy } from '@managers/allergies';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvService } from '@medrecord/core';
import { LoadAllergiesPayload } from '../models/interfaces/load-allergies-payload.interface';

@Injectable()
export class AllergiesManagerService {
  constructor(
    private http: HttpClient,
    private envService: EnvService
    ) {}

  loadAllergies(payload: LoadAllergiesPayload, userId: string): Observable<Allergy[]> {
    let params = new HttpParams();

    Object.keys(payload).forEach((key) => {
      params = params.set(key, payload[key]);
    });

    return this.http.get<Allergy[]>(this.envService.backend + '/patient/' + userId + '/allergy', { params });
  }

  loadAllergiesCount(userId: string): Observable<number> {
    return this.http.get<number>(this.envService.backend + '/patient/' + userId + '/allergy/count');
  }

  loadAllergy(id: string, userId: string): Observable<Allergy> {
    return this.http.get<Allergy>(this.envService.backend + '/patient/' + userId + '/allergy/' + id);
  }

  createAllergy(userId: string, allergy: Allergy): Observable<any> {
    return this.http.post(this.envService.backend + '/patient/' + userId + '/allergy', allergy);
  }

  updateAllergy(userId: string, allergyId: string, allergyForm: Allergy): Observable<any> {
    return this.http.put(this.envService.backend + '/patient/' + userId + '/allergy/' + allergyId, allergyForm);
  }

  deleteAllergy(id: string, userId: string): Observable<any> {
    return this.http.delete(this.envService.backend + '/patient/' + userId + '/allergy/' + id);
  }
}
