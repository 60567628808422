import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MedsafeRouteParams } from '@medrecord/routes-medsafe';
import { validateCareproviderLoadedAction } from '@managers/my-doctor';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'doctor-gui-medsafe-record-container',
  templateUrl: './record-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecordContainerComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.store.dispatch(validateCareproviderLoadedAction({
          careproviderId: params.get(MedsafeRouteParams.careproviderId),
          dataserviceId: params.get(MedsafeRouteParams.serviceId),
          recordId: params.get(MedsafeRouteParams.recordId)
        }));
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goBack(): void {
    this.router.navigate([...this.router.url.split('/').slice(0, -1)]);
  }
}
