import { createAction, props } from '@ngrx/store';
import { ImmunizationsLoadPayload } from '../models/interfaces/immunizations-load-payload.interface';
import { Immunization } from '../models/interfaces/immunization.interface';
import { ImmunizationLocation } from '../models/interfaces/immunization-location.interface';

export const loadImmunizationsAction = createAction(
  '[Immunizations Actions] Load Immunizations',
  props<{ payload: ImmunizationsLoadPayload }>()
);

export const loadImmunizationsSuccessAction = createAction(
  '[Immunizations Actions] Load Immunizations Success',
  props<{ immunizations: Immunization[]; count: number }>()
);

export const loadImmunizationsFailureAction = createAction(
  '[Immunizations Actions] Load Immunizations Failure',
  props<{ error: any }>()
);

export const loadImmunizationAction = createAction(
  '[Immunizations Actions] Load Immunization',
  props<{ id: string }>()
);

export const loadImmunizationSuccessAction = createAction(
  '[Immunizations Actions] Load Immunization Success',
  props<{ immunization: Immunization }>()
);

export const loadImmunizationLocationsAction = createAction('[Immunizations Actions] Load Immunization Locations');

export const loadImmunizationLocationsSuccessAction = createAction(
  '[Immunizations Actions] Load Immunization Locations Success',
  props<{ locations: ImmunizationLocation[] }>()
);

export const loadImmunizationLocationsFailureAction = createAction(
  '[Immunizations Actions] Load Immunization Locations Failure',
  props<{ error: any }>()
);

export const loadCareplanImmunizationsSuccessAction = createAction(
  '[Allergies Manager Actions] Load Care Plan Immunizations Success',
  props<{ immunizations: Immunization[]; count: number }>()
);

export const linkCareplanImmunizationsSuccessAction = createAction(
  '[Allergies Manager Actions] Link Care Plan Immunizations Success',
  props<{ immunizations: Immunization[] }>()
);

export const addImmunizationAction = createAction(
  '[Immunizations Actions] Add Immunizations',
  props<{ immunization: Immunization }>()
);

export const addImmunizationSuccessAction = createAction(
  '[Immunizations Actions] Add Immunizations Success',
  props<{ immunization: Immunization }>()
);

export const editImmunizationAction = createAction(
  '[Immunizations Actions] Edit Immunizations',
  props<{ immunization: Immunization }>()
);

export const editImmunizationSuccessAction = createAction(
  '[Immunizations Actions] Edit Immunization Success',
  props<{ immunization: Immunization }>()
);

export const deleteImmunizationAction = createAction(
  '[Immunizations Actions] Delete Immunization',
  props<{ id: string }>()
);

export const deleteImmunizationSuccessAction = createAction(
  '[Immunizations Actions] Delete Immunizations Success',
  props<{ id: string }>()
);

export const clearActiveImmunization = createAction('[Immunizations Actions] Clear active immunization');

export const removeCareplanImmunizationAction = createAction(
  '[Immunizations Actions] Remove Care Plan Immunization',
  props<{ id: string }>()
);
