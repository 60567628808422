import { myDoctorManagerFeatureKey, State } from '../my-doctor-manager.reducer';
import { createSelector } from '@ngrx/store';
import { PullTransaction } from '../../models/enums';

const getState = (state: State) => state[myDoctorManagerFeatureKey];

export const getActiveDataserviceId = createSelector(
  getState,
  (state: State): string => state.activeDataservice?.id
);

export const getActiveDataserviceName = createSelector(
  getState,
  (state: State): string => state.activeDataservice?.name || state.activeDataservice?.id
);

export const getActiveTransactionId = createSelector(
  getState,
  (state: State): PullTransaction => state.activeTransactionId
);
