import { myDoctorManagerFeatureKey, State } from '../my-doctor-manager.reducer';
import { createSelector } from '@ngrx/store';
import { Careprovider, getCareproviderName } from '../../models/interfaces';

const getState = (state: State) => state[myDoctorManagerFeatureKey];

export const getCareproviders = createSelector(
  getState,
  (state: State): Careprovider[] => state.careproviders
);

export const getCareprovidersCount = createSelector(
  getState,
  (state: State): number => state.careprovidersCount
);

export const getActiveCareprovider = createSelector(
  getState,
  (state: State): Careprovider => state.activeCareprovider
);

export const getActiveCareproviderId = createSelector(
  getState,
  (state: State): string => state.activeCareprovider?.id
);

export const getActiveCareproviderName = createSelector(
  getState,
  (state: State): string => getCareproviderName(state.activeCareprovider)
);

export const getNavigatedToCareproviderDetailsFrom = createSelector(
  getState,
  (state: State): string => state.navigatedToCareproviderDetailsFrom
);

export const getLogsModalOpenStatus = createSelector(
  getState,
  (state: State): boolean => state.logsModalOpenStatus
);
