import { Component, Input } from '@angular/core';
import * as QRCode from 'qrcode';

@Component({
  selector: 'medrecord-auth-qr-code',
  templateUrl: './qr-code.component.html'
})
export class QrCodeComponent {
  @Input() set otpUri(otpUri: string) {
    this.generateQRCode(otpUri);
  }

  qrCodeImgSrc: string;

  private generateQRCode(qrCodeData: string): void {
    QRCode.toDataURL(qrCodeData, (err, url) => {
      if (err) {
        return;
      }
      this.qrCodeImgSrc = url;
    });
  }

}
