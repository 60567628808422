import { MEDMIJ_REDIRECT_URI } from '../constants/local_storage';
import { OUT_CODE, OUT_ERROR, OUT_STATE } from '../constants/redirect-url-params';

export function redirectWithError(uri: string, error: string, code?: string, state?: string): void {
  localStorage.removeItem(MEDMIJ_REDIRECT_URI);

  const url = new URL(uri);
  const params = url.searchParams;
  if (error) {
    params.append(OUT_ERROR, error);
  }
  if (code) {
    params.append(OUT_CODE, code);
  }
  if (state) {
    params.append(OUT_STATE, state);
  }

  window.open(url.toString(), '_parent');
}
