import { measurementsManagerFeatureKey, MeasurementsManagerState } from './measurements-manager.reducer';
import { createSelector } from '@ngrx/store';
import { ObservationType } from '@medrecord/core';
import { Observation, ObservationThresholdItem } from '../models/interfaces';
import { User } from '@medrecord/managers-users';

export const getMeasurementsMangerState = (state: any): MeasurementsManagerState =>
  state[measurementsManagerFeatureKey];

export const selectFocusedObservation = createSelector(
  getMeasurementsMangerState,
  (state) => state.focusedObservationType
);

export const selectObservations = createSelector(getMeasurementsMangerState, (state) => state.observations);

export const selectAllowedObservationTypes = createSelector(
  getMeasurementsMangerState,
  (state) => state.allowedObservationTypes
);

export const selectThresholds = createSelector(getMeasurementsMangerState, (state) => state.thresholds);

export const selectAllowedObservationTypesAndTresholds = createSelector(getMeasurementsMangerState, (state): {
  allowedObservationTypes: ObservationType[];
  thresholds: ObservationThresholdItem[];
} => ({
  allowedObservationTypes: state.allowedObservationTypes,
  thresholds: state.thresholds,
}));

export const selectHealthScore = createSelector(getMeasurementsMangerState, (state) => state.healthScore);

export const getMeasuremntsUserNetwork = createSelector(
  getMeasurementsMangerState,
  (state): User[] => state.userNetwork
);

export const getCareplanMeasurementsObservations = createSelector(
  getMeasurementsMangerState,
  (state): { [key: string]: Observation[] } => state.careplan.observations
);
