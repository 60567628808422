export enum Speciality {
  GENERAL_PRACTITIONER = 'GENERAL_PRACTITIONER',
  THERAPEUTIST = 'THERAPEUTIST',
  INTERNIST = 'INTERNIST',
  PHYSICIAN = 'PHYSICIAN',
  FAMILY_PHYSICIAN = 'FAMILY_PHYSICIAN',
  SURGEON = 'SURGEON',
  NEUROSURGEON = 'NEUROSURGEON',
  COLON_RECTAL_SURGEON = 'COLON_RECTAL_SURGEON',
  PLASTIC_SURGEON = 'PLASTIC_SURGEON',
  CARDIOSURGEON = 'CARDIOSURGEON',
  GENERAL_SURGEON = 'GENERAL_SURGEON',
  OBSTETRICIAN_GYNECOLOGIST = 'OBSTETRICIAN_GYNECOLOGIST',
  PEDIATRICIAN = 'PEDIATRICIAN',
  PSYCHIATRIST = 'PSYCHIATRIST',
  ANESTHESIOLOGIST = 'ANESTHESIOLOGIST',
  TRAUMATOLOGIST = 'TRAUMATOLOGIST',
  ORTHOPEDIST = 'ORTHOPEDIST',
  ALLERGIST = 'ALLERGIST',
  IMMUNOLOGIST = 'IMMUNOLOGIST',
  CARDIOLOGIST = 'CARDIOLOGIST',
  DERMATOLOGIST = 'DERMATOLOGIST',
  ENDOCRINOLOGIST = 'ENDOCRINOLOGIST',
  GASTROENTEROLOGIST = 'GASTROENTEROLOGIST',
  NEPHROLOGIST = 'NEPHROLOGIST',
  NEUROLOGIST = 'NEUROLOGIST',
  PULMONOLOGIST = 'PULMONOLOGIST',
  UROLOGIST = 'UROLOGIST',
  ONCOLOGIST = 'ONCOLOGIST',
  RHEUMATOLOGIST = 'RHEUMATOLOGIST',
  OPHTHALMOLOGIST = 'OPHTHALMOLOGIST',
  OTOLARYNGOLOGIST = 'OTOLARYNGOLOGIST',
  EMERGENCY_M_S = 'EMERGENCY_M_S',
  CRITICAL_CARE_M_S = 'CRITICAL_CARE_M_S',
  GERIATRICIAN = 'GERIATRICIAN',
  HEMATOLOGIST = 'HEMATOLOGIST',
  INFECTIOUS_DISEASE_SPECIALIST = 'INFECTIOUS_DISEASE_SPECIALIST',
  HOSPICE_PALLIATIVE_M_S = 'HOSPICE_PALLIATIVE_M_S',
  SLEEP_M_S = 'SLEEP_M_S',
  SPORTS_M_S = 'SPORTS_M_S',
  MEDICAL_GENETICS = 'MEDICAL_GENETICS',
  PHYSIATRIST = 'PHYSIATRIST',
  PODIATRIST = 'PODIATRIST',
  RADIOLOGIST = 'RADIOLOGIST',
  DIETITIAN = 'DIETITIAN',
  NUTRITION = 'NUTRITION',
  PATHOLOGISTS = 'PATHOLOGISTS',
}