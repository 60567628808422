import { Component, Input } from '@angular/core';
import { MedrecordIcon } from '../../base/medrecord-icon';
import { Colors } from '@medrecord/services-colors';

@Component({
  selector: 'medrecord-svg-cross-rounded',
  templateUrl: './cross-rounded.component.svg',
  styleUrls: ['./cross-rounded.component.scss'],
})
export class CrossRoundedComponent extends MedrecordIcon {
  viewHeight = 24;
  viewWidth = 24;
  
  crossColor: string;
  bgColor: string;
  
  @Input()
  set crossType(color: Colors) {
    this.crossColor = this.getColorByType(color);
  }
  
  @Input()
  set bgType(color: Colors) {
    this.bgColor = this.getColorByType(color);
  }
}
