<section
  *ngIf="roles.includes(role.Doctor)"
  class="section-container dark"
>
  <div class="row section-title">
    {{ 'organisation_assignment' | translate }}
  </div>
  <ng-container [formGroup]="parentForm">
    <ng-container [formArrayName]="organisationsFormGroupKey">
      <div class="row section-content" *ngFor="let organisation of organisationControls; let i = index">
        <div class="col-12" [ngClass]="i !== 0 && 'b-top pt-3'"></div>
        <ng-container [formGroupName]="i">
          <div class="col-3">
            <medrecord-ui-select
              formControlName="organisationId"
              [label]="'organisation' | translate"
              [items]="organisationsOptions"
              name="organisationId"
              [asterix]="true"
            ></medrecord-ui-select>
          </div>
          <div class="col-3">
            <medrecord-multi-select
              formControlName="medicalRoles"
              [label]="'common_column_medical_roles' | translate"
              [items]="medicalRoles"
              [asterix]="true"
            ></medrecord-multi-select>
          </div>
          <div class="col-3">
            <medrecord-ui-select
              formControlName="managementRole"
              [label]="'common_column_role' | translate"
              [items]="managementRoles"
              [asterix]="true"
            ></medrecord-ui-select>
          </div>
          <div class="col-3 d-flex remove-container">
            <button
              *ngIf="organisationControls.length > 0"
              type="button"
              (click)="removeUserOrganisationAssignment(i)"
            >
              {{ 'common_delete' | translate }} <i class="fa fa-trash"></i>
          </button>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</section>
<div
  class="add-more-items mt-2"
  *ngIf="roles.includes(role.Doctor)"
>
  <button
    type="button"
    (click)="addEmptyOrganisationFormItem()"
  >
    <i class="fa fa-plus"></i>
  </button> {{ 'add_another_organisation' | translate }}
</div>