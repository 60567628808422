<ejs-dialog [visible]="isModalOpen" [isModal]="true" (overlayClick)="closeModal()" width="40%">
  <ng-template #header>
    <div class="px-2">
      <span>{{ transactionId | translate }}</span>
    </div>
  </ng-template>

  <ng-template #content>
    <div class="px-2 mx-0">
      <form [formGroup]="form">
        <div class="mb-3">
          <medrecord-ui-input
            id="token"
            formControlName="token"
            [label]="'Token' | translate"
            [placeholder]="'Token' | translate"
          ></medrecord-ui-input>
        </div>
        <div class="mb-3">
          <medrecord-ui-input
            id="userKey"
            formControlName="userKey"
            [label]="'User key' | translate"
            [placeholder]="'User key' | translate"
          ></medrecord-ui-input>
        </div>
        <div class="mb-3">
          <medrecord-ui-input
            id="careProviderUrl"
            formControlName="careProviderUrl"
            [label]="'Careprovider URL' | translate"
            [placeholder]="'Careprovider URL' | translate"
          ></medrecord-ui-input>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #footerTemplate>
    <div class="px-2 d-flex align-items-center justify-content-end">
      <button (click)="closeModal()" type="button" class="btn btn-outline-black mr-3">
        {{ 'common_cancel' | translate }}
      </button>
      <button (click)="baseFormSubmit()" type="button" class="btn btn-secondary">
        {{ 'common_form_submit' | translate }}
      </button>
    </div>
  </ng-template>
</ejs-dialog>
