import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Colors } from '@medrecord/services-colors';

@Directive()
export abstract class MedrecordIcon implements OnInit {
  @Input() hoverType: Colors;
  @Input() width = 20;
  @Input() hoverable = false;
  @Output() click = new EventEmitter();
  
  color: string;
  
  abstract viewHeight: number;
  abstract viewWidth: number;
  
  private _type: Colors = 'primary';
  
  @Input()
  set type(type: Colors) {
    this._type = type;
    this.setFill(type);
  }
  
  get isButton(): boolean {
    return !!this.click.observers.length;
  }
  
  get isHoverable(): boolean {
    return this.hoverable || !!this.hoverType || this.isButton;
  }
  
  get height(): number {
    return this.viewHeight + (this.width - this.viewWidth);
  }
  
  ngOnInit(): void {
    this.setFill(this._type);
  }
  
  mouseEnter(): void {
    if (this.isHoverable) {
      this.setFill(this.getHoverColorType(this._type, this.hoverType));
    }
  }
  
  mouseLeave(): void {
    this.setFill(this._type);
  }
  
  getColorByType(type: Colors): string {
    return `var(--${ type }-color)`;
  }
  
  getHoverColorType(initialType: Colors, hoverType?: Colors): Colors {
    return `${ hoverType ? hoverType : initialType }-hover` as Colors;
  }
  
  private setFill(color: Colors): void {
    this.color = this.getColorByType(color);
  }
}
