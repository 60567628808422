<div>
  <medrecord-ui-field-label
    *ngIf="label"
    [for]="id"
    [hint]="hint"
    [secondaryText]="secondaryLabel"
    [text]="label"
    [type]="labelType"
  ></medrecord-ui-field-label>
  <div class="d-flex">
    <ng-container *ngFor="let button of leftButtons">
      <ng-container *ngTemplateOutlet="button"></ng-container>
    </ng-container>

    <div
      [class.warning]="controlErrorsList.length"
      [class.error]="errorCode || externalErrorsList.length"
      class="input-group"
    >
      <div class="input-group-prepend" *ngIf="leftIcons.length > 0">
        <span class="input-group-text">
          <ng-container *ngFor="let icon of leftIcons">
            <ng-container *ngTemplateOutlet="icon" [attr.height]="18"></ng-container>
          </ng-container>
        </span>
      </div>

      <input
        #input="ngModel"
        (blur)="checkIfTouched()"
        [(ngModel)]="value"
        [id]="id"
        [name]="name || control?.name"
        [placeholder]="(placeholder || label) + (secondaryLabel ? ' (' + secondaryLabel + ')' : '')"
        [type]="type"
        [mask]="mask"
        [min]="min"
        [maxlength]="max"
        [step]="step"
        [pattern]="pattern"
        [showMaskTyped]="true"
        [disabled]="disabled"
        [autocomplete]="autocomplete"
        class="form-control"
      />

      <div *ngIf="hasRightIcons" class="input-group-append">
        <span *ngIf="errorCode || externalErrorsList.length" class="input-group-text">
          <i class="fas fa-exclamation-triangle text-danger"></i>
        </span>

        <span *ngIf="controlErrorsList.length" class="input-group-text">
          <i class="fas fa-exclamation-triangle text-warning"></i>
        </span>

        <span *ngFor="let icon of rightIcons" class="input-group-text">
          <ng-container *ngTemplateOutlet="icon"></ng-container>
        </span>
      </div>
    </div>

    <ng-container *ngFor="let button of rightButtons">
      <ng-container *ngTemplateOutlet="button"></ng-container>
    </ng-container>
  </div>

  <ng-content></ng-content>

  <medrecord-ui-field-error
    [errorCode]="errorCode"
    [errors]="controlErrorsList"
    [externalErrors]="externalErrorsList"
  ></medrecord-ui-field-error>
</div>
