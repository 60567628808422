import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MappingResource } from '@managers/my-doctor';
import { ChipModel, ClickEventArgs, DeleteEventArgs } from '@syncfusion/ej2-angular-buttons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'doctor-gui-resources-chips',
  templateUrl: './resources-chips.component.html',
  styleUrls: ['./resources-chips.component.scss']
})
export class ResourcesChipsComponent {
  @Input() set resourcesKeys(data: { resourceKeys: MappingResource[]; total: number }) {
    this.chips = [{
      text: this.translateService.instant('my_doctor_get_data'),
      value: this.getDataChip,
      cssClass: 'p-1 px-2 text-black get-data-chip'
    }];

    this.chips.push(...data.resourceKeys.map(resource => ({
      text: this.translateService.instant(resource),
      value: resource,
      cssClass: 'p-1 px-2 text-black'
    })));
  }

  @Output() getData: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteResource: EventEmitter<MappingResource> = new EventEmitter<MappingResource>();

  readonly getDataChip = 'getDataChip';
  chips: ChipModel[] = [];

  constructor(private translateService: TranslateService) {
  }

  chipClick(event: ClickEventArgs): void {
    if (this.isGetDataChip(event)) {
      this.getData.emit();
    }
  }

  beforeDelete(event: DeleteEventArgs): void {
    if (!this.isGetDataChip(event)) {
      this.deleteResource.emit((event.data as ChipModel)?.value as MappingResource);
    }
    // disable native delete
    event.cancel = true;
  }

  private isGetDataChip(event: ClickEventArgs | DeleteEventArgs): boolean {
    return (event.data as ChipModel)?.value === this.getDataChip;
  }
}
