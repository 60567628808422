import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { selectUserId } from '@medrecord/managers-auth';
import { selectUser } from '../state-management/users-manager.actions';
import { map, withLatestFrom } from 'rxjs/operators';
import { getSelectedUserTabData } from '../state-management/users-manager.selectors';

@Injectable()
export class CurrentPatientPermissionGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(selectUserId).pipe(
      withLatestFrom(this.store.select(getSelectedUserTabData)),
      map(([userId, selectedUser]) => {
        if (route.routeConfig.data.permissions?.includes(selectedUser?.permission) || !selectedUser) return true;
        this.store.dispatch(selectUser({ id: userId }));
        return false;
      })
    );
  }
}
