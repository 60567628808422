import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'medrecord-repeatable-form',
  templateUrl: './medrecord-repeatable-form.component.html',
})
export class MedrecordRepeatableFormComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() formArrayName;
  @Input() formConfiguration: any = {};
  @Input() formTemplate: TemplateRef<any>;

  @Input() generalTitle: string;
  @Input() repeatedTitle: string;
  @Input() addMoreButtonTitle: string;

  constructor(private fb: FormBuilder) {}

  get items(): FormArray {
    return this.parentForm ? (this.parentForm.get(this.formArrayName) as FormArray) : new FormArray([]);
  }

  get hasRepeatedForms(): boolean {
    return this.items.controls.length > 1;
  }

  ngOnInit(): void {
    this.initFormArray();
    this.addItem();
  }

  repeatedTitleWithIndex(index: number): string {
    return `${this.repeatedTitle} ${index + 1}`;
  }

  initFormArray(): void {
    if (this.parentForm) {
      this.parentForm.addControl(this.formArrayName, this.fb.array([]));
    }
  }

  newItem(): FormGroup {
    return this.fb.group(this.formConfiguration);
  }

  addItem(): void {
    this.items.push(this.newItem());
  }

  removeItem(index: number): void {
    this.items.removeAt(index);
  }
}
