import { Component, Input } from '@angular/core';
import { Careprovider } from '@managers/my-doctor';
import { TableField, TableResourceKey } from '../../../models/table-resource-key.interface';
import { AddressUtil } from '../../../utils/address.util';

@Component({
  selector: 'doctor-gui-careprovider-short-details',
  templateUrl: './careprovider-short-details.component.html',
})
export class CareproviderShortDetailsComponent {
  @Input() set careprovider(careprovider: Careprovider) {
    const fields: TableField[] = [
      {
        label: 'my_doctor_healthcare_provider',
        value: careprovider.displayName ?? careprovider?.id ?? '-',
      },
      {
        label: 'my_doctor_email',
        value: careprovider.email
      },
      {
        label: 'my_doctor_phone_number',
        value: careprovider.phone
      },
      {
        label: 'my_doctor_addresses',
        value: (careprovider?.addresses ?? []).map(
          ({ streetName, streetNumber, postalCode, city, country }) =>
            AddressUtil.toOneLine(streetName, streetNumber, postalCode, city, country)
        )?.[0]
      }
    ];

    this.tableResourceKey = {
      blocks: [{
        title: 'my_doctor_data_fetched_from',
        fields
      }]
    };
  }

  tableResourceKey: TableResourceKey = {
    blocks: []
  };
}
