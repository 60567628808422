import { Component } from '@angular/core';
import {
  getActiveCareproviderId,
  loadTestCareproviderStatusAction, MyDoctorManagerService,
  TestCareproviderFetchStatusType
} from '@managers/my-doctor';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'doctor-gui-test-careprovider-status-buttons-container',
  templateUrl: './test-careprovider-status-buttons-container.component.html'
})
export class TestCareproviderStatusButtonsContainerComponent {
  careproviderId$: Observable<string> = this.store.select(getActiveCareproviderId);

  constructor(
    private store: Store,
    private service: MyDoctorManagerService
  ) {
  }

  isTestCareprovider(careproviderId: string): boolean {
    return this.service.isTest(careproviderId);
  }

  loadStatus(fetchStatusType: TestCareproviderFetchStatusType): void {
    this.store.dispatch(loadTestCareproviderStatusAction({ statusType: fetchStatusType }));
  }
}
